export default `0 HEAD
1 SOUR Heredis MAC2020
2 VERS 2020
2 NAME Heredis MAC
2 CORP Heredis SCOP
3 WWW www.heredis.com
1 DATE 21 DEC 2019
1 GEDC
2 VERS 5.5.1
2 FORM LINEAGE-LINKED
1 CHAR UTF-8
1 _GUID C4E70B83-F6E5-4EF2-B57E-71638C052ABE
1 PLAC
2 FORM Town, Area code, County, Region, Country, Subdivision
0 @56@ INDI
1 NAME Jean-Marie Joseph George Albert/AMBROSELLI/
2 GIVN Jean-Marie Joseph George Albert
2 SURN AMBROSELLI
1 SEX M
1 OCCU Médecin gynécologue accoucheur
1 SIGN NO
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 NOV 2019
3 TIME 7:58
1 FAMS @81@
1 CHR
2 DATE 1952
2 PLAC Paris 08,,,,,
2 _FNA NO
1 BIRT
2 DATE 18 MAY 1952
2 PLAC Paris 08,,,,,20 AVENUE DE FRIEDLAND
2 _FNA NO
1 FAMC @451@
0 @73@ INDI
1 NAME Élisabeth Jacqueline Marie/MISSOFFE/
2 GIVN Élisabeth Jacqueline Marie
2 SURN MISSOFFE
1 SEX F
1 OCCU SANS
1 SIGN NO
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 NOV 2019
3 TIME 7:58
1 FAMS @81@
1 BIRT
2 DATE 23 OCT 1953
2 PLAC Paris 17,,,,,
2 _FNA NO
1 FAMC @1138@
0 @85@ INDI
1 NAME Nicolas Jean Marie/AMBROSELLI/
2 GIVN Nicolas Jean Marie
2 SURN AMBROSELLI
1 SEX M
1 OCCU médecin
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 SEP 2019
3 TIME 17:26
1 FAMS @221@
1 CHR
2 DATE 1976
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
1 BIRT
2 DATE 1 JUL 1976
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @81@
0 @106@ INDI
1 NAME Christophe Jean Marie/AMBROSELLI/
2 GIVN Christophe Jean Marie
2 SURN AMBROSELLI
1 SEX M
1 OCCU ingénieur BTP
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 SEP 2019
3 TIME 17:26
1 FAMS @231@
1 CHR
2 DATE 1978
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
1 BIRT
2 DATE 27 APR 1978
2 PLAC Fécamp,76400,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.757870
4 LONG E0.374570
2 _FNA NO
1 FAMC @81@
0 @125@ INDI
1 NAME Valentine Marie/AMBROSELLI/
2 GIVN Valentine Marie
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 SEP 2019
3 TIME 14:27
1 FAMS @241@
1 CHR
2 DATE 1981
2 _FNA NO
1 BIRT
2 DATE 19 JAN 1981
2 PLAC Fécamp,76400,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.757870
4 LONG E0.374570
2 _FNA NO
1 FAMC @81@
0 @143@ INDI
1 NAME Hélène Marguerite Marie/AMBROSELLI/
2 GIVN Hélène Marguerite Marie
2 SURN AMBROSELLI
1 SEX F
1 OCCU médecin
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 SEP 2019
3 TIME 17:26
1 FAMS @251@
1 CHR
2 DATE 1985
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
1 BIRT
2 DATE 6 JUN 1985
2 PLAC Rouen,76000,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.443130
4 LONG E1.099320
2 _FNA NO
1 FAMC @81@
0 @168@ INDI
1 NAME Arnaud Jean Marie/AMBROSELLI/
2 GIVN Arnaud Jean Marie
2 SURN AMBROSELLI
1 SEX M
1 OCCU ingénieur BTP
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 SEP 2019
3 TIME 17:27
1 FAMS @405@
1 CHR
2 DATE 1987
2 _FNA NO
1 BIRT
2 DATE 25 MAR 1987
2 PLAC Rouen,76000,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.443130
4 LONG E1.099320
2 _FNA NO
1 FAMC @81@
0 @188@ INDI
1 NAME Grégoire Jean Marie/AMBROSELLI/
2 GIVN Grégoire Jean Marie
2 SURN AMBROSELLI
1 SEX M
1 OCCU commerçant
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 SEP 2019
3 TIME 17:27
1 CHR
2 DATE 1991
2 _FNA NO
1 BIRT
2 DATE 1 MAY 1991
2 PLAC Rouen,76000,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.443130
4 LONG E1.099320
2 _FNA NO
1 FAMC @81@
0 @216@ INDI
1 NAME Clémence Josèphe Ségolène/COLLONNIER/
2 GIVN Clémence Josèphe Ségolène
2 SURN COLLONNIER
1 SEX F
1 OCCU médecin
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 DEC 2019
3 TIME 12:32
1 FAMS @221@
1 BIRT
2 DATE 16 MAR 1977
2 PLAC Sainte-Adresse,76310,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.508900
4 LONG E0.084460
2 _FNA NO
1 FAMC @8996@
0 @225@ INDI
1 NAME Soline Sylvie/DUCUING/
2 GIVN Soline Sylvie
2 SURN DUCUING
1 SEX F
1 OCCU infirmière
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 MAR 2018
3 TIME 11:29
1 FAMS @231@
1 BIRT
2 DATE 8 NOV 1978
2 PLAC Villeurbanne,69100,Rhône,Auvergne-Rhône-Alpes,FRANCE,
3 MAP
4 LATI N45.766670
4 LONG E4.883330
2 _FNA NO
1 FAMC @9023@
0 @235@ INDI
1 NAME Cédric Arnaud Marie Joseph/de LA CELLE-LESBRE/
2 GIVN Cédric Arnaud Marie Joseph
2 SURN de LA CELLE-LESBRE
1 SEX M
1 OCCU entrepreneur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 SEP 2019
3 TIME 14:27
1 FAMS @241@
1 BIRT
2 DATE 13 APR 1978
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @9137@
0 @246@ INDI
1 NAME Julien/LEMARCHAND/
2 GIVN Julien
2 SURN LEMARCHAND
1 SEX M
1 OCCU médecin
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 13:37
1 FAMS @251@
1 BIRT
2 DATE 3 DEC 1983
2 _FNA NO
0 @255@ INDI
1 NAME Titouan Compay Grégoire/AMBROSELLI/
2 GIVN Titouan Compay Grégoire
2 SURN AMBROSELLI
1 SEX M
1 OCCU étudiant
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 MAR 2018
3 TIME 9:27
1 BIRT
2 DATE 16 DEC 2002
2 PLAC Vannes,56000,Morbihan,Bretagne,FRANCE,
3 MAP
4 LATI N47.666670
4 LONG W2.750000
2 _FNA NO
1 FAMC @221@
0 @267@ INDI
1 NAME Malo Thibaut Sacha/AMBROSELLI/
2 GIVN Malo Thibaut Sacha
2 SURN AMBROSELLI
1 SEX M
1 OCCU étudiant
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 MAR 2018
3 TIME 9:28
1 BIRT
2 DATE 11 JUN 2004
2 PLAC Vannes,56000,Morbihan,Bretagne,FRANCE,
3 MAP
4 LATI N47.666670
4 LONG W2.750000
2 _FNA NO
1 FAMC @221@
0 @278@ INDI
1 NAME Juliette Francesca Valentine/AMBROSELLI/
2 GIVN Juliette Francesca Valentine
2 SURN AMBROSELLI
1 SEX F
1 OCCU étudiant
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 MAR 2018
3 TIME 9:31
1 CHR
2 DATE 2008
2 PLAC Séné,56860,Morbihan,Bretagne,FRANCE,
3 MAP
4 LATI N47.619000
4 LONG W2.737000
2 _FNA NO
1 BIRT
2 DATE 6 AUG 2008
2 PLAC Vannes,56000,Morbihan,Bretagne,FRANCE,
3 MAP
4 LATI N47.666670
4 LONG W2.750000
2 _FNA NO
1 FAMC @221@
0 @289@ INDI
1 NAME Lazare/AMBROSELLI/
2 GIVN Lazare
2 SURN AMBROSELLI
1 SEX M
1 OCCU étudiant
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 FEB 2018
3 TIME 9:50
1 BIRT
2 DATE 8 NOV 2009
2 PLAC Vannes,56000,Morbihan,Bretagne,FRANCE,
3 MAP
4 LATI N47.666670
4 LONG W2.750000
2 _FNA NO
1 FAMC @221@
0 @300@ INDI
1 NAME Matthieu François Gérard/AMBROSELLI/
2 GIVN Matthieu François Gérard
2 SURN AMBROSELLI
1 SEX M
1 OCCU étudiant
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 SEP 2019
3 TIME 15:53
1 CHR
2 DATE 2003
2 _FNA NO
1 BIRT
2 DATE 8 JUN 2003
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @231@
0 @311@ INDI
1 NAME Thomas Bernard François/AMBROSELLI/
2 GIVN Thomas Bernard François
2 SURN AMBROSELLI
1 SEX M
1 OCCU étudiant
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 MAR 2018
3 TIME 10:12
1 CHR
2 DATE 2005
2 _FNA NO
1 BIRT
2 DATE 13 JUN 2005
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @231@
0 @323@ INDI
1 NAME Célia Valérie Élina/AMBROSELLI/
2 GIVN Célia Valérie Élina
2 SURN AMBROSELLI
1 SEX F
1 OCCU étudiante
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 MAR 2018
3 TIME 10:16
1 CHR
2 DATE 2008
2 _FNA NO
1 BIRT
2 DATE 20 JAN 2008
2 PLAC Nicosie,,,,CHYPRE,
2 _FNA NO
1 FAMC @231@
0 @336@ INDI
1 NAME Baptiste/AMBROSELLI/
2 GIVN Baptiste
2 SURN AMBROSELLI
1 SEX M
1 OCCU étudiant
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 FEB 2018
3 TIME 9:58
1 BIRT
2 DATE 20 MAR 2012
2 PLAC Le Chesnay,78150,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.822200
4 LONG E2.122130
2 _FNA NO
1 FAMC @231@
0 @347@ INDI
1 NAME Antoine/de LA CELLE-LESBRE/
2 GIVN Antoine
2 SURN de LA CELLE-LESBRE
1 SEX M
1 OCCU étudiant
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 FEB 2018
3 TIME 10:32
1 BIRT
2 DATE 26 AUG 2009
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @241@
0 @357@ INDI
1 NAME Alban/de LA CELLE-LESBRE/
2 GIVN Alban
2 SURN de LA CELLE-LESBRE
1 SEX M
1 OCCU étudiant
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 FEB 2018
3 TIME 10:33
1 BIRT
2 DATE 13 AUG 2011
2 PLAC Fécamp,76400,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.757870
4 LONG E0.374570
2 _FNA NO
1 FAMC @241@
0 @367@ INDI
1 NAME Basile/de LA CELLE-LESBRE/
2 GIVN Basile
2 SURN de LA CELLE-LESBRE
1 SEX M
1 OCCU étudiant
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 FEB 2018
3 TIME 10:32
1 BIRT
2 DATE 29 APR 2013
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @241@
0 @377@ INDI
1 NAME Vianney/de LA CELLE-LESBRE/
2 GIVN Vianney
2 SURN de LA CELLE-LESBRE
1 SEX M
1 OCCU étudiant
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 FEB 2018
3 TIME 10:32
1 BIRT
2 DATE 13 OCT 2015
2 _FNA NO
1 FAMC @241@
0 @387@ INDI
1 NAME Samuel/LEMARCHAND/
2 GIVN Samuel
2 SURN LEMARCHAND
1 SEX M
1 OCCU étudiant
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 FEB 2018
3 TIME 10:04
1 BIRT
2 DATE 16 APR 2016
2 PLAC Marseille,13000,Bouches-du-Rhône,Provence-Alpes-Côte d'Azur,FRANCE,
3 MAP
4 LATI N43.296950
4 LONG E5.381070
2 _FNA NO
1 FAMC @251@
0 @399@ INDI
1 NAME Marine/LECLAIR/
2 GIVN Marine
2 SURN LECLAIR
1 SEX F
1 OCCU commerçante
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 JUN 2019
3 TIME 17:46
1 FAMS @405@
1 BIRT
2 DATE 1 AUG 1986
2 _FNA NO
1 FAMC @10430@
0 @412@ INDI
1 NAME Gérard Édouard Joseph Gabriel/AMBROSELLI/
2 GIVN Gérard Édouard Joseph Gabriel
2 SURN AMBROSELLI
1 SEX M
1 OCCU artiste peintre
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2019
3 TIME 8:43
1 FAMS @451@
1 BIRT
2 DATE 30 MAR 1906
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 20 NOV 2000
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
2 CAUS vieillesse
1 NOTE Image source http://rhinetdanube76.free.fr/Historique.htm
2 CONT Notes Roglo :
2 CONT Après des licences de droit et d'histoire à la Sorbonne, puis Saint-Cyr, une école d'officier en 1926, il fut élève de l'Ecole des Beaux-Arts dans l'atelier d'Ernest Laurent.
2 CONT Elève de Maurice Denis et surtout de George Desvallières, son maître et futur beau-père, il devient sociétaire du Salon d'Automne en 1932.
2 CONT Il obtient le Prix de la Villa Velasquez avec L'hommage à Géricault.
2 CONT En 1939, il rencontre le Général de Lattre, qui le nomme aide de camp et le charge d'une nouvelle fonction "d'imagier" de l'Armée d'Alsace. Démobilisé fin juillet 1940, il retrouve de Lattre à Clermont-Ferrand et à Opme.
2 CONT Après l'arrestation du général de Lattre, en novembre 1942, son procès, son emprisonnement et son évasion de la Maison d'Arrêt de Riom, Gérard Ambroselli entre dans la clandestinité, sous le nom de Gabriel Audouin. Il organise, à Paris, avec Suzann
2 CONC e Lalande (Simone de Lattre), le voyage, par l'Espagne, pour rejoindre de Lattre à Alger. Affecté à Alger à la 5ème division blindée, il devient le capitaine Gérard.
2 CONT Lors du débarquement en Provence avec la Première Armée française, il est chargé, de nouveau, de la fonction "d'imagier".
2 CONT Pendant la campagne d'Allemagne de 1945, il reprend son pinceau et conçoit l'insigne pour toute l'armée Rhin et Danube. Il participe, avec les Américains, à la libération des déportés du camp de concentration de Dachau.
2 CONT Il bénéficiera de commandes officielles et exposera notamment à Paris et en Normandie (Pays de Caux) où il aimait peindre.
2 CONT Il enseigna à l'Institut des carrières artistiques et écrivit sous le nom de Gabriel Angerval une rubrique hebdomadaire sur les ventes, dans le Figaro littéraire.
1 FAMC @489@
0 @447@ INDI
1 NAME France Marguerite Marie/LEFEBVRE-DESVALLIERES/
2 GIVN France Marguerite Marie
2 SURN LEFEBVRE-DESVALLIERES
2 NICK DESVALLIERES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 APR 2018
3 TIME 17:11
1 FAMS @451@
1 CHR
2 DATE 1915
2 _FNA NO
1 BIRT
2 DATE 10 SEP 1915
2 PLAC Paris 02,,,,,
2 _FNA NO
1 DEAT
2 DATE 28 JAN 2001
2 PLAC Rouen,76000,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.443130
4 LONG E1.099320
2 _FNA NO
1 BURI
2 DATE 31 JAN 2001
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
1 FAMC @7315@
0 @467@ INDI
1 NAME Léon Édouard Victor Joseph/AMBROSELLI/
2 GIVN Léon Édouard Victor Joseph
2 SURN AMBROSELLI
1 SEX M
1 OCCU docteur en droit
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 DEC 2019
3 TIME 11:02
1 FAMS @489@
1 BIRT
2 DATE 21 JUL 1869
2 PLAC Paris 9,,,,,8 RUE PAPILLON
2 _FNA NO
1 BURI CIMETIERRE DE MONTMARTRE PLACE DE CLICHY
2 DATE OCT 1950
2 PLAC Paris 09,,,,,
2 _FNA NO
2 NOTE Dans tombe   d'edouard AMBROSELLI  CPP 1874 allée Nicolas 3°ligne n°45 cimetière de Montmartre
1 DEAT
2 DATE 22 OCT 1950
3 TIME 1:30
2 PLAC Paris 8,,,,,
2 _FNA NO
2 CAUS 20 avenue de friedland
1 NOTE Peintre, avocat, photographe, bibliophile, érudit latiniste, et directeur de la "Nationale-Vie"
1 FAMC @568@
0 @486@ INDI
1 NAME Germaine Laurence Gligli/THURET/
2 GIVN Germaine Laurence Gligli
2 SURN THURET
2 NICK GLIGLI
1 SEX F
1 OCCU sans
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 DEC 2019
3 TIME 11:02
1 FAMS @489@
1 FAMS @4264@
1 BIRT
2 DATE 10 JAN 1864
2 PLAC Nevers,58000,Nièvre,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N46.989560
4 LONG E3.159000
2 _FNA NO
1 BURI CIMETIERRE DE MONTMARTRE PLACE DE CLICHY
2 DATE NOV 1942
2 PLAC Paris 09,,,,,
2 _FNA NO
2 NOTE Dans tombe   d'edouard AMBROSELLI  CPP 1874 allée Nicolas 3°ligne n°45 cimetière de Montmartre
1 DEAT
2 DATE 1 NOV 1942
3 TIME 23:45
2 PLAC Paris 08,,,,,
2 _FNA NO
1 FAMC @16463@
0 @547@ INDI
1 NAME Edouard François Joseph/AMBROSELLI/
2 GIVN Edouard François Joseph
2 SURN AMBROSELLI
1 SEX M
1 OCCU chanteur directeur de l'agence générale des théâtres
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 20 JUL 2019
3 TIME 13:39
1 FAMS @568@
1 BIRT
2 DATE 10 FEB 1834
2 PLAC Marseille,13000,Bouches-du-Rhône,Provence-Alpes-Côte d'Azur,FRANCE,
3 MAP
4 LATI N43.296950
4 LONG E5.381070
2 _FNA NO
1 BURI CIMETIERRE DE MONTMARTRE PLACE DE CLICHY
2 DATE 1917
2 PLAC Paris 09,,,,,
2 _FNA NO
2 NOTE Dans tombe   d'edouard AMBROSELLI  CPP 1874 allée Nicolas 3°ligne n°45 cimetière de Montmartre
1 DEAT
2 DATE 8 AUG 1917
2 PLAC Croissy-sur-Seine,78290,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.879250
4 LONG E2.138360
2 _FNA NO
2 NOTE Dans tombe   d'edouard AMBROSELLI  CPP 1874 allée Nicolas 3°ligne n°45 cimetière de Montmartre
1 NOTE 	•	Professeur de chant (1868), agent théatral (1869), ténor à la Société des Concerts (1870-1873), correspondant dramatique de l'Opéra en 1873
2 CONT
1 FAMC @2178@
0 @564@ INDI
1 NAME Marie Victoire Félicie/DUPRÉ/
2 GIVN Marie Victoire Félicie
2 SURN DUPRÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 18:12
1 FAMS @568@
1 BIRT
2 DATE 18 MAY 1848
2 PLAC Pointe-à-Pitre,97110,Guadeloupe,Guadeloupe,FRANCE,
3 MAP
4 LATI N16.242200
4 LONG W61.534300
2 _FNA NO
1 BURI CIMETIERRE DE MONTMARTRE PLACE DE CLICHY
2 DATE JAN 1920
2 PLAC Paris 09,,,,,
2 _FNA NO
2 NOTE Dans tombe   d'edouard AMBROSELLI  CPP 1874 allée Nicolas 3°ligne n°45 cimetière de Montmartre
1 DEAT
2 DATE 21 JAN 1920
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @6937@
0 @576@ INDI
1 NAME François Antoine Joseph/AMBROSELLI/
2 GIVN François Antoine Joseph
2 SURN AMBROSELLI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 JUN 2019
3 TIME 12:05
1 FAMS @2178@
1 BIRT
2 DATE 17 DEC 1790
2 PLAC Marseille,13000,Bouches-du-Rhône,Provence-Alpes-Côte d'Azur,FRANCE,
3 MAP
4 LATI N43.296950
4 LONG E5.381070
2 _FNA NO
1 CHR
2 DATE 18 DEC 1790
2 PLAC Marseille,13000,Bouches-du-Rhône,Provence-Alpes-Côte d'Azur,FRANCE,EGLISE SAINT LAURENT
3 MAP
4 LATI N43.296950
4 LONG E5.381070
2 _FNA NO
1 BURI
2 DATE 1867
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,cimetierre de montmartre
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 1 NOV 1867
2 PLAC Marseille,13000,Bouches-du-Rhône,Provence-Alpes-Côte d'Azur,FRANCE,
3 MAP
4 LATI N43.296950
4 LONG E5.381070
2 _FNA NO
2 NOTE Dans tombe   d'edouard AMBROSELLI  CPP 1874 allée Nicolas 3°ligne n°45 cimetière de Montmartre Epitaphes à
3 CONT Mere d'edouard AMBROSELLI  = Therese pauline LACREUSETTE née en 1813 Dcd à Marseille en 1842 dans sa 29' année
3 CONT Pere d'edouard AMBROSELLI = François  Antoine AMBROSELLI  Dcd à Marseille 1/11/1867 dans sa 77° année (né en 1790?)
3 CONT Louise Joséphine Lilia COBY 1/07/1826 _ 22/12/1905
3 CONT 	épouse Charles DUPRE (= Parents de Marie Victoire Félicie DUPRE ?)
3 CONT Catherine AMBROSELLI = tante dcd 26/04/1859 à 66 ans
3 CONT
3 CONT
1 FAMC @2221@
0 @584@ INDI
1 NAME Michel/AMBROSELLI/
2 GIVN Michel
2 SURN AMBROSELLI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 19:49
1 FAMS @2233@
1 FAMS @33416@
1 BIRT
2 DATE 23 SEP 1733
2 PLAC Barrettali,20228,Haute-Corse,Corse,FRANCE,TORRA
3 MAP
4 LATI N42.876900
4 LONG E9.355400
2 _FNA YES
2 NOTE ou 1730 ?
1 DEAT
2 DATE 28 SEP 1816
2 PLAC Barrettali,20228,Haute-Corse,Corse,FRANCE,
3 MAP
4 LATI N42.876900
4 LONG E9.355400
2 _FNA NO
1 FAMC @33394@
0 @669@ INDI
1 NAME Madeleine/AMBROSELLI/
2 GIVN Madeleine
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 MAY 2018
3 TIME 20:06
1 FAMS @682@
1 BIRT
2 DATE 1903
2 _FNA NO
2 NOTE ?
1 DEAT
2 DATE 1980
2 _FNA NO
1 FAMC @489@
0 @680@ INDI
1 NAME Jean/BECHET/
2 GIVN Jean
2 SURN BECHET
1 SEX M
1 OCCU Docteur EN DROIT
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JUN 2019
3 TIME 13:39
1 FAMS @682@
1 BIRT
2 DATE 1898
2 _FNA NO
1 DEAT
2 DATE 1984
2 _FNA NO
1 NOTE Régie des Tabacs au Maroc
1 FAMC @11955@
0 @687@ INDI
1 NAME Marie/AMBROSELLI/
2 GIVN Marie
2 SURN AMBROSELLI
1 SEX F
1 OCCU sans
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 DEC 2018
3 TIME 18:25
1 BIRT
2 DATE 15 SEP 1933
2 PLAC Paris 02,,,,,14 rue SAINT MARC
2 _FNA NO
1 DEAT
2 DATE 29 DEC 2016
2 PLAC Sassetot-le-Mauconduit,76540,Seine-Maritime,Normandie,FRANCE,LES PAQUERETTES
3 MAP
4 LATI N49.805080
4 LONG E0.527190
2 _FNA NO
1 FAMC @451@
0 @703@ INDI
1 NAME Jean-Baptiste Jacques Edouard/AMBROSELLI/
2 GIVN Jean-Baptiste Jacques Edouard
2 SURN AMBROSELLI
1 SEX M
1 OCCU artiste peintre
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 7:27
1 FAMS @962@
1 BIRT
2 DATE 1 DEC 1934
2 PLAC Paris 8,,,,,20 AVENUE DE FRIEDLAND
2 _FNA NO
2 NOTE AVENUE DE FRIEDLAND
1 FAMC @451@
0 @715@ INDI
1 NAME François Daniel Joseph Charles/AMBROSELLI/
2 GIVN François Daniel Joseph Charles
2 SURN AMBROSELLI
2 NICK Boubi
1 SEX M
1 OCCU Architecte
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 DEC 2018
3 TIME 18:16
1 FAMS @978@
1 BIRT
2 DATE 13 AUG 1936
2 PLAC Seine-Port,77240,Seine-et-Marne,Île-de-France,FRANCE,
3 MAP
4 LATI N48.557430
4 LONG E2.553160
2 _FNA NO
1 DEAT
2 DATE 3 NOV 2009
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @451@
0 @726@ INDI
1 NAME Thérèse Marie Sabine Catherine/AMBROSELLI/
2 GIVN Thérèse Marie Sabine Catherine
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 JUL 2019
3 TIME 10:52
1 FAMS @992@
1 CHR
2 DATE 1939
2 _FNA NO
1 BIRT
2 DATE 7 FEB 1939
2 PLAC Seine-Port,77240,Seine-et-Marne,Île-de-France,FRANCE,
3 MAP
4 LATI N48.557430
4 LONG E2.553160
2 _FNA NO
1 FAMC @451@
0 @742@ INDI
1 NAME Sabine Odile Marie Madeleine/AMBROSELLI/
2 GIVN Sabine Odile Marie Madeleine
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 7:35
1 FAMS @1002@
1 CHR
2 DATE 1940
2 _FNA NO
1 BIRT
2 DATE 22 JUL 1940
2 PLAC Saint-Bonnet-Briance,87260,Haute-Vienne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.707840
4 LONG E1.476070
2 _FNA NO
1 FAMC @451@
0 @773@ INDI
1 NAME Philippe Martial Joseph/AMBROSELLI/
2 GIVN Philippe Martial Joseph
2 SURN AMBROSELLI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 SEP 2019
3 TIME 11:37
1 FAMS @1007@
1 BIRT
2 DATE 13 JAN 1942
2 PLAC Saint-Genest-sur-Roselle,87260,Haute-Vienne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.702180
4 LONG E1.426600
2 _FNA NO
1 BURI
2 DATE FEB 2006
2 PLAC Sainte-Geneviève,50760,Manche,Normandie,FRANCE,
3 MAP
4 LATI N49.657710
4 LONG W1.311160
2 _FNA NO
1 DEAT
2 DATE 16 FEB 2006
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @451@
0 @800@ INDI
1 NAME Claire Germaine Marie-France/AMBROSELLI/
2 GIVN Claire Germaine Marie-France
2 SURN AMBROSELLI
1 SEX F
1 OCCU médecin
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 DEC 2018
3 TIME 18:35
1 BIRT
2 DATE 30 AUG 1943
2 PLAC Saint-Genest-sur-Roselle,87260,Haute-Vienne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.702180
4 LONG E1.426600
2 _FNA NO
1 FAMC @451@
0 @822@ INDI
1 NAME Catherine Marie Françoise Bernadette/AMBROSELLI/
2 GIVN Catherine Marie Françoise Bernadette
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 6:42
1 FAMS @1019@
1 BIRT
2 DATE 1 MAY 1947
2 PLAC Paris 8,,,,,
2 _FNA NO
1 FAMC @451@
0 @845@ INDI
1 NAME Elisabeth Marie Caroline/AMBROSELLI/
2 GIVN Elisabeth Marie Caroline
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2019
3 TIME 8:44
1 FAMS @1028@
1 BIRT
2 DATE 13 NOV 1948
2 PLAC Paris 8,,,,,20 AVENUE DE FRIEDLAND
2 _FNA NO
1 CHR
2 DATE DEC 1948
2 _FNA NO
1 FAMC @451@
0 @864@ INDI
1 NAME Gabriel Vincent Marie/AMBROSELLI/
2 GIVN Gabriel Vincent Marie
2 SURN AMBROSELLI
1 SEX M
1 OCCU restaurateur d'objets d'art
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2019
3 TIME 8:53
1 FAMS @1038@
1 CHR
2 DATE 1949
2 _FNA NO
1 BIRT
2 DATE 15 DEC 1949
2 _FNA NO
1 FAMC @451@
0 @883@ INDI
1 NAME Laurence Marie Louise/AMBROSELLI/
2 GIVN Laurence Marie Louise
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2019
3 TIME 8:52
1 FAMS @1060@
1 CHR
2 DATE 1951
2 _FNA NO
1 BIRT
2 DATE 17 FEB 1951
2 PLAC Paris 8,,,,,
2 _FNA NO
1 FAMC @451@
0 @902@ INDI
1 NAME Marie "Bernadette" Hélène/AMBROSELLI/
2 GIVN Marie "Bernadette" Hélène
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2019
3 TIME 8:48
1 FAMS @1046@
1 CHR
2 DATE 1953
2 _FNA NO
1 BIRT
2 DATE 15 AUG 1953
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
1 FAMC @451@
0 @919@ INDI
1 NAME Colette Marie "Isabelle"/AMBROSELLI/
2 GIVN Colette Marie "Isabelle"
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2019
3 TIME 8:45
1 FAMS @1069@
1 CHR
2 DATE 1954
2 _FNA NO
1 BIRT
2 DATE 30 SEP 1954
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
2 NOTE VILLA MARIE-THERESE
1 FAMC @451@
0 @938@ INDI
1 NAME Pierre-Paul Joseph Antoine/AMBROSELLI/
2 GIVN Pierre-Paul Joseph Antoine
2 SURN AMBROSELLI
1 SEX M
1 OCCU sculpteur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 JUN 2019
3 TIME 10:04
1 FAMS @1078@
1 FAMS @23908@
1 CHR
2 DATE 1957
2 _FNA NO
1 BIRT
2 DATE 27 MAY 1957
2 PLAC Paris 8,,,,,
2 _FNA NO
1 FAMC @451@
0 @959@ INDI
1 NAME Elisabeth Jeanne Marie/GENET/
2 GIVN Elisabeth Jeanne Marie
2 SURN GENET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 7:27
1 FAMS @962@
1 BIRT
2 DATE 4 JUL 1939
2 _FNA NO
1 FAMC @23518@
0 @976@ INDI
1 NAME Flavie Marie Dominique/ISORNI/
2 GIVN Flavie Marie Dominique
2 SURN ISORNI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 DEC 2018
3 TIME 18:17
1 FAMS @978@
1 BIRT
2 DATE 22 JAN 1942
2 PLAC Boulogne-Billancourt,92100,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.833330
4 LONG E2.250000
2 _FNA NO
1 FAMC @7697@
0 @990@ INDI
1 NAME Bruno François Bernard Marie Joseph/de BAYSER/
2 GIVN Bruno François Bernard Marie Joseph
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 JUL 2019
3 TIME 10:52
1 FAMS @992@
1 BIRT
2 DATE 22 MAY 1941
2 _FNA NO
1 FAMC @11575@
0 @1000@ INDI
1 NAME David Charles Dominic/BAIRD-SMITH/
2 GIVN David Charles Dominic
2 SURN BAIRD-SMITH
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 7:35
1 FAMS @1002@
1 BIRT
2 DATE 15 JUL 1938
2 PLAC Glasgow,,,,ECOSSE,
3 MAP
4 LATI N55.865150
4 LONG W4.257630
2 _FNA NO
0 @1005@ INDI
1 NAME Isabelle/PALUEL-MARMONT/
2 GIVN Isabelle
2 SURN PALUEL-MARMONT
1 SEX F
1 OCCU Architecte Décoratrice
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 SEP 2019
3 TIME 11:37
1 FAMS @1007@
1 BIRT
2 DATE 30 NOV 1945
2 _FNA NO
1 DEAT
2 DATE 24 MAY 2014
2 PLAC Paris 02,,,,,
2 _FNA NO
1 BURI ND DES VICTOIRES
2 DATE 28 MAY 2014
3 TIME 10:30
2 PLAC Paris 02,,Seine,Ile de France,FRANCE,
2 _FNA NO
2 NOTE MESSE
1 BURI
2 DATE 28 MAY 2014
3 TIME 16:00
2 PLAC Sainte-Geneviève,50760,Manche,Normandie,FRANCE,
3 MAP
4 LATI N49.657710
4 LONG W1.311160
2 _FNA NO
1 FAMC @1232@
0 @1017@ INDI
1 NAME Xavier Alain Nicolas/de BAYSER/
2 GIVN Xavier Alain Nicolas
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 6:42
1 FAMS @1019@
1 BIRT
2 DATE 12 JUL 1946
2 _FNA NO
1 FAMC @11575@
0 @1026@ INDI
1 NAME Marc Bernard/SEYDOUX/
2 GIVN Marc Bernard
2 SURN SEYDOUX
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 JUL 2019
3 TIME 15:39
1 FAMS @1028@
1 BIRT
2 DATE 23 SEP 1936
2 PLAC Neuilly-sur-Seine,92200,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883330
4 LONG E2.266670
2 _FNA NO
1 BURI
2 DATE JAN 2011
2 PLAC Ancretteville-sur-Mer,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.788420
4 LONG E0.508420
2 _FNA NO
1 DEAT
2 DATE 6 JAN 2011
2 _FNA NO
0 @1036@ INDI
1 NAME Anne-Olivia/HAICAULT de la REGONTAIS/
2 GIVN Anne-Olivia
2 SURN HAICAULT de la REGONTAIS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 6:52
1 FAMS @1038@
1 BIRT
2 DATE 24 MAR 1955
2 _FNA NO
1 FAMC @23579@
0 @1044@ INDI
1 NAME Pierre-Marie François/DUMONT/
2 GIVN Pierre-Marie François
2 SURN DUMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 APR 2019
3 TIME 16:57
1 FAMS @1046@
1 BIRT
2 DATE 16 OCT 1948
2 _FNA NO
1 FAMC @30851@
0 @1058@ INDI
1 NAME Christian Jean Claude Louis/PREVOT/
2 GIVN Christian Jean Claude Louis
2 SURN PREVOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 6:55
1 FAMS @1060@
1 BIRT
2 DATE 4 AUG 1951
2 _FNA NO
1 DEAT
2 DATE 9 JUL 2018
3 TIME 15:00
2 PLAC Hyères,83400,Var,Provence-Alpes-Côte d'Azur,FRANCE,
3 MAP
4 LATI N43.119540
4 LONG E6.138970
2 _FNA NO
1 BURI
2 DATE 16 JUL 2018
3 TIME 10:00
2 PLAC Toulon,83000,Var,Provence-Alpes-Côte d'Azur,FRANCE,
3 MAP
4 LATI N43.116670
4 LONG E5.933330
2 _FNA NO
0 @1067@ INDI
1 NAME Bertrand Raymond André/ACKER/
2 GIVN Bertrand Raymond André
2 SURN ACKER
1 SEX M
1 OCCU BANQUIER
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 7:14
1 FAMS @1069@
1 BIRT
2 DATE 24 OCT 1949
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
2 NOTE AVENUE DE L’OBSERVATOIRE
1 FAMC @23597@
0 @1076@ INDI
1 NAME Marie-Dominique Paulette Rolande/THUILLIER/
2 GIVN Marie-Dominique Paulette Rolande
2 SURN THUILLIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 7:25
1 FAMS @1078@
1 BIRT
2 DATE 3 MAR 1967
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
0 @1090@ INDI
1 NAME Hélène Marie Françoise Odette/de MITRY/
2 GIVN Hélène Marie Françoise Odette
2 SURN de MITRY
1 SEX F
1 OCCU sénateur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:01
1 FAMS @1138@
1 BIRT
2 DATE 15 JUN 1927
2 PLAC Paris 09,,,,,
2 _FNA NO
1 DEAT
2 DATE 22 JAN 2015
2 PLAC Paris 16,,,,,
2 _FNA NO
1 BURI
2 DATE 27 JAN 2015
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
2 NOTE MESSE A ND D’AUTEUIL
1 FAMC @11467@
0 @1121@ INDI
1 NAME François Henri Marie/MISSOFFE/
2 GIVN François Henri Marie
2 SURN MISSOFFE
1 SEX M
1 OCCU député ministre
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:01
1 FAMS @1138@
1 BIRT
2 DATE 13 OCT 1919
2 PLAC Toulon,83000,Var,Provence-Alpes-Côte d'Azur,FRANCE,
3 MAP
4 LATI N43.116670
4 LONG E5.933330
2 _FNA NO
1 DEAT
2 DATE 28 AUG 2003
2 PLAC Rouen,76000,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.443130
4 LONG E1.099320
2 _FNA NO
1 BURI
2 DATE 31 AUG 2003
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
1 FAMC @4812@
0 @1147@ INDI
1 NAME Françoise Marie Thérèse/MISSOFFE/
2 GIVN Françoise Marie Thérèse
2 SURN MISSOFFE
1 SEX F
1 OCCU député
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 16:56
1 FAMS @1166@
1 BIRT
2 DATE 12 DEC 1948
2 PLAC Moyeuvre-Grande,57250,Moselle,Grand Est,FRANCE,
3 MAP
4 LATI N49.251650
4 LONG E6.047540
2 _FNA NO
1 FAMC @1138@
0 @1162@ INDI
1 NAME Guy Édouard Louis Marie/de PANAFIEU/
2 GIVN Guy Édouard Louis Marie
2 SURN de PANAFIEU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 17:06
1 FAMS @1166@
1 BIRT
2 DATE 5 APR 1943
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @19359@
0 @1169@ INDI
1 NAME Thierry/de PANAFIEU/
2 GIVN Thierry
2 SURN de PANAFIEU
1 SEX M
1 OCCU financier
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 10:57
1 FAMS @10473@
1 BIRT
2 DATE 9 JUN 1971
2 PLAC Paris 16,,,,,
2 _FNA NO
1 FAMC @1166@
0 @1177@ INDI
1 NAME Charlotte/de PANAFIEU/
2 GIVN Charlotte
2 SURN de PANAFIEU
1 SEX F
1 OCCU Avocate
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 11:02
1 FAMS @10511@
1 BIRT
2 DATE 15 DEC 1972
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @1166@
0 @1184@ INDI
1 NAME Marc/de PANAFIEU/
2 GIVN Marc
2 SURN de PANAFIEU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 MAY 2018
3 TIME 20:02
1 FAMS @10534@
1 BIRT
2 DATE 26 APR 1977
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @1166@
0 @1191@ INDI
1 NAME Claire/de PANAFIEU/
2 GIVN Claire
2 SURN de PANAFIEU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 7 DEC 2018
3 TIME 18:31
1 FAMS @10563@
1 BIRT
2 DATE 20 SEP 1990
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @1166@
0 @1223@ INDI
1 NAME Roger/PALUEL-MARMONT/
2 GIVN Roger
2 SURN PALUEL-MARMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 SEP 2019
3 TIME 18:29
1 FAMS @1232@
1 BIRT
2 DATE 1918
2 _FNA NO
0 @1230@ INDI
1 NAME Annick/LEBON/
2 GIVN Annick
2 SURN LEBON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 SEP 2019
3 TIME 18:29
1 FAMS @1232@
1 BIRT
2 DATE 2 MAY 1917
2 _FNA NO
1 DEAT
2 DATE 10 AUG 1996
2 _FNA NO
1 FAMC @31861@
0 @1241@ INDI
1 NAME Anne-Claire/AMBROSELLI/
2 GIVN Anne-Claire
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 7:56
1 FAMS @1289@
1 BIRT
2 DATE 27 SEP 1965
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @962@
0 @1252@ INDI
1 NAME Céline/AMBROSELLI/
2 GIVN Céline
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 APR 2018
3 TIME 22:28
1 FAMS @1328@
1 CHR
2 DATE 1967
2 _FNA NO
1 BIRT
2 DATE 25 MAY 1967
2 PLAC Paris 17,,,,,
2 _FNA NO
1 FAMC @962@
0 @1262@ INDI
1 NAME Thomas/AMBROSELLI/
2 GIVN Thomas
2 SURN AMBROSELLI
1 SEX M
1 OCCU Architecte
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 8:23
1 BIRT
2 DATE 14 MAY 1971
2 PLAC Boulogne-Billancourt,92100,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.833330
4 LONG E2.250000
2 _FNA NO
2 NOTE BELVEDERE
1 FAMC @962@
0 @1273@ INDI
1 NAME Colomba/AMBROSELLI/
2 GIVN Colomba
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 8:23
1 FAMS @1350@
1 BIRT
2 DATE 7 JUL 1972
2 PLAC Boulogne-Billancourt,92100,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.833330
4 LONG E2.250000
2 _FNA NO
2 NOTE BELVEDERE
1 FAMC @962@
0 @1285@ INDI
1 NAME Paul/TRONCHON/
2 GIVN Paul
2 SURN TRONCHON
1 SEX M
1 OCCU Ingénieur Agronome
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 7:57
1 FAMS @1289@
1 BIRT
2 DATE 1 DEC 1962
2 PLAC Saint-Étienne,42100,Loire,Auvergne-Rhône-Alpes,FRANCE,
3 MAP
4 LATI N45.433330
4 LONG E4.400000
2 _FNA NO
0 @1291@ INDI
1 NAME Jean/TRONCHON/
2 GIVN Jean
2 SURN TRONCHON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 15:24
1 BIRT
2 DATE 7 AUG 1993
2 PLAC Sèvres,92310,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.822920
4 LONG E2.217570
2 _FNA NO
1 FAMC @1289@
0 @1299@ INDI
1 NAME Clément/TRONCHON/
2 GIVN Clément
2 SURN TRONCHON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 17:24
1 BIRT
2 DATE 15 JUN 1995
2 _FNA NO
1 FAMC @1289@
0 @1307@ INDI
1 NAME Léo/TRONCHON/
2 GIVN Léo
2 SURN TRONCHON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 17:25
1 BIRT
2 DATE 13 FEB 1998
2 _FNA NO
1 FAMC @1289@
0 @1315@ INDI
1 NAME Timothée/TRONCHON/
2 GIVN Timothée
2 SURN TRONCHON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 17:25
1 BIRT
2 DATE 1 APR 2006
2 _FNA NO
1 FAMC @1289@
0 @1324@ INDI
1 NAME Arnaud/DEBUSSCHE/
2 GIVN Arnaud
2 SURN DEBUSSCHE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 APR 2018
3 TIME 22:28
1 FAMS @1328@
1 BIRT
2 DATE 15 OCT 1965
2 _FNA NO
0 @1331@ INDI
1 NAME Hortense/DEBUSSCHE/
2 GIVN Hortense
2 SURN DEBUSSCHE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 17:33
1 BIRT
2 DATE 6 NOV 2003
2 _FNA NO
1 FAMC @1328@
0 @1338@ INDI
1 NAME Louis/DEBUSSCHE/
2 GIVN Louis
2 SURN DEBUSSCHE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 18:18
1 BIRT
2 DATE 4 APR 2006
2 _FNA NO
1 FAMC @1328@
0 @1348@ INDI
1 NAME Christophe/MARCOUX/
2 GIVN Christophe
2 SURN MARCOUX
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 17:43
1 FAMS @1350@
0 @1351@ INDI
1 NAME Leïla/MARCOUX/
2 GIVN Leïla
2 SURN MARCOUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 JAN 2019
3 TIME 17:29
1 BIRT
2 DATE 23 DEC 2013
3 TIME 23:19
2 _FNA NO
1 FAMC @1350@
0 @1354@ INDI
1 NAME /MARCOUX/
2 GIVN
2 SURN MARCOUX
1 SEX U
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 17:43
1 FAMC @1350@
0 @1358@ INDI
1 NAME Antonella Anne Marie Dominique/AMBROSELLI/
2 GIVN Antonella Anne Marie Dominique
2 SURN AMBROSELLI
1 SEX F
1 OCCU Infirmière
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 DEC 2018
3 TIME 18:15
1 FAMS @1373@
1 FAMS @1382@
1 BIRT
2 DATE 3 MAR 1965
2 PLAC Boulogne-Billancourt,92100,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.833330
4 LONG E2.250000
2 _FNA NO
1 FAMC @978@
0 @1369@ INDI
1 NAME Ludwig/AMBROSELLI/
2 GIVN Ludwig
2 SURN AMBROSELLI
1 SEX M
1 _FIL NATURAL_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 17:47
1 BIRT
2 DATE 15 APR 1987
2 _FNA NO
1 FAMC @1373@
0 @1378@ INDI
1 NAME Jean-Pierre/MONCADA/
2 GIVN Jean-Pierre
2 SURN MONCADA
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 DEC 2018
3 TIME 18:15
1 FAMS @1382@
1 BIRT
2 DATE 10 OCT 1954
2 _FNA NO
0 @1385@ INDI
1 NAME Siegfried/MONCADA/
2 GIVN Siegfried
2 SURN MONCADA
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 17:49
1 BIRT
2 DATE 7 JAN 1995
2 _FNA NO
1 FAMC @1382@
0 @1393@ INDI
1 NAME Hendrik/MONCADA/
2 GIVN Hendrik
2 SURN MONCADA
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 17:50
1 BIRT
2 DATE 9 DEC 2000
2 _FNA NO
1 FAMC @1382@
0 @1401@ INDI
1 NAME Lucia/AMBROSELLI/
2 GIVN Lucia
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 DEC 2018
3 TIME 18:19
1 FAMS @1433@
1 BIRT
2 DATE 27 OCT 1968
2 _FNA NO
1 FAMC @978@
0 @1412@ INDI
1 NAME David Francesco/DINTZER/
2 GIVN David Francesco
2 SURN DINTZER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 18:27
1 BIRT
2 DATE 6 JUL 1986
2 _FNA NO
1 FAMC @1433@
0 @1424@ INDI
1 NAME Marianne/DINTZER/
2 GIVN Marianne
2 SURN DINTZER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 18:01
1 BIRT
2 DATE 12 OCT 1987
2 _FNA NO
1 FAMC @1433@
0 @1432@ INDI
1 NAME Frédéric Marcel Henri/DINTZER/
2 GIVN Frédéric Marcel Henri
2 SURN DINTZER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 DEC 2018
3 TIME 18:19
1 FAMS @1433@
1 BIRT
2 DATE 18 OCT 1960
2 _FNA NO
0 @1438@ INDI
1 NAME Raphaël/DINTZER/
2 GIVN Raphaël
2 SURN DINTZER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 18:01
1 BIRT
2 DATE 9 DEC 1989
2 _FNA NO
1 FAMC @1433@
0 @1445@ INDI
1 NAME Francesco/AMBROSELLI/
2 GIVN Francesco
2 SURN AMBROSELLI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 18:04
1 BIRT
2 DATE 1970
2 _FNA NO
1 DEAT
2 DATE 1970
2 _FNA NO
1 FAMC @978@
0 @1459@ INDI
1 NAME Frantz Benoît Georges/AMBROSELLI/
2 GIVN Frantz Benoît Georges
2 SURN AMBROSELLI
1 SEX M
1 OCCU AGENT IMMOBILIER
1 _FIL RECOGNIZED_CHILD
1 CHAN
2 DATE 8 MAY 2019
3 TIME 7:52
1 FAMS @1641@
1 BIRT
2 DATE 23 AUG 1976
2 PLAC Boulogne-Billancourt,92100,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.833330
4 LONG E2.250000
2 _FNA NO
1 FAMC @978@
0 @1478@ INDI
1 NAME Pauline/AMBROSELLI/
2 GIVN Pauline
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 DEC 2019
3 TIME 19:17
1 FAMS @36809@
1 BIRT
2 DATE 22 OCT 1986
2 _FNA NO
1 FAMC @1078@
0 @1488@ INDI
1 NAME Jean-Roch/AMBROSELLI/
2 GIVN Jean-Roch
2 SURN AMBROSELLI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 JUN 2019
3 TIME 18:59
1 FAMS @32140@
1 BIRT
2 DATE 24 SEP 1987
2 _FNA NO
1 FAMC @1078@
0 @1498@ INDI
1 NAME Dominique/AMBROSELLI/
2 GIVN Dominique
2 SURN AMBROSELLI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 19:14
1 BIRT
2 DATE 4 OCT 1991
2 _FNA NO
1 FAMC @1078@
0 @1515@ INDI
1 NAME Anaïs/ACKER/
2 GIVN Anaïs
2 SURN ACKER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 22:12
1 FAMS @1574@
1 BIRT
2 DATE 6 JAN 1982
2 PLAC Boulogne-Billancourt,92100,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.833330
4 LONG E2.250000
2 _FNA NO
1 FAMC @1069@
0 @1524@ INDI
1 NAME Clémence Marie/ACKER/
2 GIVN Clémence Marie
2 SURN ACKER
1 SEX F
1 OCCU pianiste
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JUL 2018
3 TIME 7:34
1 FAMS @1579@
1 CHR
2 DATE 1983
2 _FNA NO
1 BIRT
2 DATE 29 APR 1983
2 PLAC Boulogne-Billancourt,92100,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.833330
4 LONG E2.250000
2 _FNA NO
1 FAMC @1069@
0 @1533@ INDI
1 NAME François/ACKER/
2 GIVN François
2 SURN ACKER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 AUG 2019
3 TIME 18:14
1 FAMS @1588@
1 BIRT
2 DATE 7 MAY 1985
2 _FNA NO
1 FAMC @1069@
0 @1543@ INDI
1 NAME Martin/ACKER/
2 GIVN Martin
2 SURN ACKER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 18:45
1 BIRT
2 DATE 12 FEB 1987
2 _FNA NO
1 FAMC @1069@
0 @1552@ INDI
1 NAME Jean/ACKER/
2 GIVN Jean
2 SURN ACKER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 AUG 2019
3 TIME 8:53
1 FAMS @32151@
1 BIRT
2 DATE 19 MAY 1989
2 PLAC Suresnes,92150,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.871430
4 LONG E2.229290
2 _FNA NO
2 NOTE HOPITAL FOCH
1 FAMC @1069@
0 @1561@ INDI
1 NAME Bruno/ACKER/
2 GIVN Bruno
2 SURN ACKER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 15:14
1 BIRT
2 DATE 6 OCT 1990
2 PLAC Suresnes,92150,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.871430
4 LONG E2.229290
2 _FNA NO
2 NOTE HOPITAL FOCH
1 FAMC @1069@
0 @1572@ INDI
1 NAME Servan/LE CONTE/
2 GIVN Servan
2 SURN LE CONTE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 22:12
1 FAMS @1574@
0 @1577@ INDI
1 NAME Thomas/BOUTTE/
2 GIVN Thomas
2 SURN BOUTTE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JUL 2018
3 TIME 7:34
1 FAMS @1579@
0 @1584@ INDI
1 NAME Aïda/GARCIA-PISONERO/
2 GIVN Aïda
2 SURN GARCIA-PISONERO
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 AUG 2019
3 TIME 18:14
1 FAMS @1588@
1 BIRT
2 DATE 5 MAR 1985
2 _FNA NO
0 @1590@ INDI
1 NAME Titouan/LE CONTE/
2 GIVN Titouan
2 SURN LE CONTE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 22:15
1 BIRT
2 DATE 28 JUN 2012
2 _FNA NO
1 CHR
2 DATE 25 AUG 2012
2 _FNA NO
1 FAMC @1574@
0 @1598@ INDI
1 NAME Camille/LE CONTE/
2 GIVN Camille
2 SURN LE CONTE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 22:13
1 BIRT
2 DATE 23 DEC 2013
2 _FNA NO
1 FAMC @1574@
0 @1606@ INDI
1 NAME Brune/LE CONTE/
2 GIVN Brune
2 SURN LE CONTE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 22:12
1 BIRT
2 DATE 31 DEC 2015
2 PLAC Lyon,69000,Rhône,Auvergne-Rhône-Alpes,FRANCE,
3 MAP
4 LATI N45.748460
4 LONG E4.846710
2 _FNA NO
1 CHR
2 DATE 20 FEB 2016
2 _FNA NO
1 FAMC @1574@
0 @1614@ INDI
1 NAME Ludivine/BOUTTE/
2 GIVN Ludivine
2 SURN BOUTTE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 18:58
1 BIRT
2 DATE 1 SEP 2010
2 _FNA NO
1 FAMC @1579@
0 @1621@ INDI
1 NAME Léon/BOUTTE/
2 GIVN Léon
2 SURN BOUTTE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 22:10
1 BIRT
2 DATE 24 JUL 2012
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 CHR
2 DATE 28 OCT 2012
2 PLAC Boulogne-Billancourt,92100,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.833330
4 LONG E2.250000
2 _FNA NO
1 FAMC @1579@
0 @1629@ INDI
1 NAME Pénélope Céleste Marie/BOUTTE/
2 GIVN Pénélope Céleste Marie
2 SURN BOUTTE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 22:15
1 BIRT
2 DATE 21 FEB 2014
2 _FNA NO
1 CHR
2 DATE 17 MAY 2014
2 _FNA NO
1 FAMC @1579@
0 @1639@ INDI
1 NAME Carmela/TANCREDI/
2 GIVN Carmela
2 SURN TANCREDI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 MAY 2019
3 TIME 7:52
1 FAMS @1641@
0 @1642@ INDI
1 NAME Flavie/AMBROSELLI/
2 GIVN Flavie
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 19:07
1 BIRT
2 DATE 2008
2 _FNA NO
1 FAMC @1641@
0 @1650@ INDI
1 NAME Pierre/AMBROSELLI/
2 GIVN Pierre
2 SURN AMBROSELLI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 19:08
1 BIRT
2 DATE 27 DEC 2011
2 _FNA NO
1 FAMC @1641@
0 @1661@ INDI
1 NAME Clothilde/de BAYSER/
2 GIVN Clothilde
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 7:40
1 FAMS @2113@
1 BIRT
2 DATE 17 SEP 1963
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @992@
0 @1672@ INDI
1 NAME Patrick/de BAYSER/
2 GIVN Patrick
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 18:45
1 FAMS @2072@
1 FAMS @2104@
1 BIRT
2 DATE 17 DEC 1964
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @992@
0 @1683@ INDI
1 NAME Blandine/de BAYSER/
2 GIVN Blandine
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 NOV 2019
3 TIME 18:03
1 FAMS @2024@
1 BIRT
2 DATE 6 MAR 1966
2 _FNA NO
1 FAMC @992@
0 @1693@ INDI
1 NAME Marc/de BAYSER/
2 GIVN Marc
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 20:19
1 FAMS @1986@
1 BIRT
2 DATE 15 FEB 1967
2 _FNA NO
1 FAMC @992@
0 @1704@ INDI
1 NAME Agnès/de BAYSER/
2 GIVN Agnès
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 20:13
1 FAMS @1949@
1 BIRT
2 DATE 23 FEB 1970
2 _FNA NO
1 FAMC @992@
0 @1715@ INDI
1 NAME Louis/de BAYSER/
2 GIVN Louis
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 20:08
1 FAMS @1901@
1 BIRT
2 DATE 7 JUN 1972
2 _FNA NO
1 FAMC @992@
0 @1725@ INDI
1 NAME François-Régis/de BAYSER/
2 GIVN François-Régis
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 8:28
1 FAMS @1877@
1 BIRT
2 DATE 10 OCT 1973
2 _FNA NO
1 FAMC @992@
0 @1736@ INDI
1 NAME Matthieu/de BAYSER/
2 GIVN Matthieu
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 19:56
1 FAMS @1844@
1 BIRT
2 DATE 17 JUN 1975
2 _FNA NO
1 FAMC @992@
0 @1746@ INDI
1 NAME Marine/de BAYSER/
2 GIVN Marine
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 JUN 2019
3 TIME 18:45
1 FAMS @1806@
1 BIRT
2 DATE 5 APR 1979
2 _FNA NO
1 FAMC @992@
0 @1756@ INDI
1 NAME Vincent/de BAYSER/
2 GIVN Vincent
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 19:44
1 BIRT
2 DATE 15 OCT 1980
2 _FNA NO
1 FAMC @992@
0 @1767@ INDI
1 NAME Augustin/de BAYSER/
2 GIVN Augustin
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 18:38
1 FAMS @1781@
1 BIRT
2 DATE 3 NOV 1982
2 _FNA NO
1 FAMC @992@
0 @1779@ INDI
1 NAME Anne-Ghislaine/X/
2 GIVN Anne-Ghislaine
2 SURN X
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 18:38
1 FAMS @1781@
0 @1784@ INDI
1 NAME Siméon/de BAYSER/
2 GIVN Siméon
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 19:46
1 BIRT
2 DATE 1 AUG 2011
2 _FNA NO
1 FAMC @1781@
0 @1792@ INDI
1 NAME Héloïse/de BAYSER/
2 GIVN Héloïse
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 19:47
1 BIRT
2 DATE 15 SEP 2013
2 _FNA NO
1 FAMC @1781@
0 @1802@ INDI
1 NAME Benjamin/HERTOGHE/
2 GIVN Benjamin
2 SURN HERTOGHE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 JUN 2019
3 TIME 18:45
1 FAMS @1806@
1 BIRT
2 DATE 6 OCT 1977
2 _FNA NO
0 @1809@ INDI
1 NAME Julie/HERTOGHE/
2 GIVN Julie
2 SURN HERTOGHE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 19:49
1 BIRT
2 DATE 20 NOV 2005
2 _FNA NO
1 FAMC @1806@
0 @1817@ INDI
1 NAME Rose/HERTOGHE/
2 GIVN Rose
2 SURN HERTOGHE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 19:49
1 BIRT
2 DATE 22 AUG 2007
2 _FNA NO
1 FAMC @1806@
0 @1824@ INDI
1 NAME Samuel/HERTOGHE/
2 GIVN Samuel
2 SURN HERTOGHE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 19:50
1 BIRT
2 DATE 23 DEC 2009
2 _FNA NO
1 FAMC @1806@
0 @1832@ INDI
1 NAME Laura/HERTOGHE/
2 GIVN Laura
2 SURN HERTOGHE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 19:51
1 BIRT
2 DATE 12 MAY 2011
2 _FNA NO
1 FAMC @1806@
0 @1840@ INDI
1 NAME Caroline/BRIAN/
2 GIVN Caroline
2 SURN BRIAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 19:56
1 FAMS @1844@
1 BIRT
2 DATE 12 AUG 1976
2 _FNA NO
0 @1847@ INDI
1 NAME Alexandre/de BAYSER/
2 GIVN Alexandre
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 19:55
1 BIRT
2 DATE 1 NOV 2005
2 _FNA NO
1 FAMC @1844@
0 @1854@ INDI
1 NAME Charlotte/de BAYSER/
2 GIVN Charlotte
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 19:55
1 BIRT
2 DATE 2006
2 _FNA NO
1 FAMC @1844@
0 @1862@ INDI
1 NAME Benoît/de BAYSER/
2 GIVN Benoît
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 19:56
1 BIRT
2 DATE 2011
2 _FNA NO
1 FAMC @1844@
0 @1870@ INDI
1 NAME Marie/VANNIER/
2 GIVN Marie
2 SURN VANNIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 8:28
1 FAMS @1877@
1 BIRT
2 DATE 18 MAR 1975
2 _FNA NO
1 DEAT
2 DATE 5 MAR 2017
2 _FNA NO
0 @1880@ INDI
1 NAME Hugues/de BAYSER/
2 GIVN Hugues
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 20:03
1 BIRT
2 DATE 28 DEC 2007
2 _FNA NO
1 FAMC @1877@
0 @1888@ INDI
1 NAME Esther/de BAYSER/
2 GIVN Esther
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 20:04
1 BIRT
2 DATE 21 APR 2011
2 _FNA NO
1 FAMC @1877@
0 @1897@ INDI
1 NAME Emmanuelle/MONTERO/
2 GIVN Emmanuelle
2 SURN MONTERO
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 20:08
1 FAMS @1901@
1 BIRT
2 DATE 15 FEB 1974
2 _FNA NO
0 @1904@ INDI
1 NAME Rémy/de BAYSER/
2 GIVN Rémy
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 18:41
1 BIRT
2 DATE 30 MAR 1999
2 _FNA NO
1 FAMC @1901@
0 @1912@ INDI
1 NAME Blanche/de BAYSER/
2 GIVN Blanche
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 20:07
1 BIRT
2 DATE 6 MAR 2001
2 _FNA NO
1 FAMC @1901@
0 @1920@ INDI
1 NAME Flore/de BAYSER/
2 GIVN Flore
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 20:07
1 BIRT
2 DATE 20 JUL 2004
2 _FNA NO
1 FAMC @1901@
0 @1928@ INDI
1 NAME Inès/de BAYSER/
2 GIVN Inès
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 20:08
1 BIRT
2 DATE SEP 2006
2 _FNA NO
1 FAMC @1901@
0 @1936@ INDI
1 NAME Edgar/de BAYSER/
2 GIVN Edgar
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 20:08
1 BIRT
2 DATE 2010
2 _FNA NO
1 FAMC @1901@
0 @1945@ INDI
1 NAME Laurent/BIVILLE/
2 GIVN Laurent
2 SURN BIVILLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 20:13
1 FAMS @1949@
1 BIRT
2 DATE 7 JUN 1964
2 _FNA NO
0 @1952@ INDI
1 NAME Étienne/BIVILLE/
2 GIVN Étienne
2 SURN BIVILLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 20:11
1 BIRT
2 DATE 23 NOV 1996
2 _FNA NO
1 FAMC @1949@
0 @1959@ INDI
1 NAME Thomas/BIVILLE/
2 GIVN Thomas
2 SURN BIVILLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 20:12
1 BIRT
2 DATE 21 MAR 1998
2 _FNA NO
1 FAMC @1949@
0 @1967@ INDI
1 NAME Pierre/BIVILLE/
2 GIVN Pierre
2 SURN BIVILLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 20:12
1 BIRT
2 DATE 26 FEB 2000
2 _FNA NO
1 FAMC @1949@
0 @1974@ INDI
1 NAME Jean/BIVILLE/
2 GIVN Jean
2 SURN BIVILLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 20:13
1 BIRT
2 DATE 9 APR 2006
2 _FNA NO
1 FAMC @1949@
0 @1982@ INDI
1 NAME Anne-Claire/BOUNIOL/
2 GIVN Anne-Claire
2 SURN BOUNIOL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 20:19
1 FAMS @1986@
1 BIRT
2 DATE 4 JUN 1968
2 _FNA NO
0 @1989@ INDI
1 NAME Mathilde/de BAYSER/
2 GIVN Mathilde
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 20:15
1 BIRT
2 DATE 3 DEC 1994
2 _FNA NO
1 FAMC @1986@
0 @1996@ INDI
1 NAME Antoine/de BAYSER/
2 GIVN Antoine
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 20:16
1 BIRT
2 DATE 17 OCT 1996
2 _FNA NO
1 FAMC @1986@
0 @2003@ INDI
1 NAME Clémence/de BAYSER/
2 GIVN Clémence
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 20:17
1 BIRT
2 DATE 23 NOV 1999
2 _FNA NO
1 FAMC @1986@
0 @2011@ INDI
1 NAME Aurore/de BAYSER/
2 GIVN Aurore
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 20:19
1 BIRT
2 DATE 23 AUG 2003
2 _FNA NO
1 FAMC @1986@
0 @2020@ INDI
1 NAME Jérôme/MANDIN/
2 GIVN Jérôme
2 SURN MANDIN
1 SEX M
1 OCCU officier de marine
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 NOV 2019
3 TIME 18:03
1 FAMS @2024@
1 BIRT
2 DATE 16 NOV 1963
2 _FNA NO
1 NOTE ANCIEN COMMANDANT du centre de formation des forces spéciales de la Marine
2 CONT PEGASUS LEADERSHIP= mission de conseil opérationnel
2 CONT
2 CONT
0 @2029@ INDI
1 NAME Valentin/MANDIN/
2 GIVN Valentin
2 SURN MANDIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 22:10
1 BIRT
2 DATE 18 MAY 1995
2 _FNA NO
1 FAMC @2024@
0 @2036@ INDI
1 NAME Juliette/MANDIN/
2 GIVN Juliette
2 SURN MANDIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 22:11
1 BIRT
2 DATE 17 FEB 1997
2 _FNA NO
1 FAMC @2024@
0 @2044@ INDI
1 NAME Agathe/MANDIN/
2 GIVN Agathe
2 SURN MANDIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 22:11
1 BIRT
2 DATE 7 FEB 1999
2 _FNA NO
1 FAMC @2024@
0 @2052@ INDI
1 NAME Romain/MANDIN/
2 GIVN Romain
2 SURN MANDIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 8:07
1 BIRT
2 DATE 3 APR 2003
2 _FNA NO
2 NOTE JUMEAU
1 FAMC @2024@
0 @2060@ INDI
1 NAME Tanguy/MANDIN/
2 GIVN Tanguy
2 SURN MANDIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 8:07
1 BIRT
2 DATE 3 APR 2003
2 _FNA NO
2 NOTE JUMEAU
1 FAMC @2024@
0 @2068@ INDI
1 NAME Catherine/WONNER/
2 GIVN Catherine
2 SURN WONNER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 22:16
1 FAMS @2072@
1 BIRT
2 DATE 16 NOV 1958
2 _FNA NO
0 @2076@ INDI
1 NAME Chloé Nina/de BAYSER/
2 GIVN Chloé Nina
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 22:15
1 BIRT
2 DATE 14 JAN 1999
2 _FNA NO
1 FAMC @2072@
0 @2087@ INDI
1 NAME Élisa/de BAYSER/
2 GIVN Élisa
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 22:15
1 BIRT
2 DATE 13 SEP 2001
2 _FNA NO
1 FAMC @2072@
0 @2095@ INDI
1 NAME Noémie/de BAYSER/
2 GIVN Noémie
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 22:16
1 BIRT
2 DATE 19 NOV 2003
2 _FNA NO
1 FAMC @2072@
0 @2103@ INDI
1 NAME Alice/X/
2 GIVN Alice
2 SURN X
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 18:45
1 FAMS @2104@
0 @2105@ INDI
1 NAME Paul/de BAYSER/
2 GIVN Paul
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 18:46
1 BIRT
2 DATE JUL 2017
2 _FNA NO
1 FAMC @2104@
0 @2109@ INDI
1 NAME Nicolas/MEURIOT/
2 GIVN Nicolas
2 SURN MEURIOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 7:40
1 FAMS @2113@
1 BIRT
2 DATE 2 AUG 1952
2 _FNA NO
0 @2116@ INDI
1 NAME Audrey/MEURIOT/
2 GIVN Audrey
2 SURN MEURIOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 14:28
1 FAMS @2150@
1 BIRT
2 DATE 6 NOV 1985
2 _FNA NO
1 FAMC @2113@
0 @2123@ INDI
1 NAME Jeanne/MEURIOT/
2 GIVN Jeanne
2 SURN MEURIOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2018
3 TIME 8:58
1 FAMS @22948@
1 BIRT
2 DATE 10 FEB 1989
2 _FNA NO
1 FAMC @2113@
0 @2130@ INDI
1 NAME Louise/MEURIOT/
2 GIVN Louise
2 SURN MEURIOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 22:24
1 BIRT
2 DATE 17 OCT 1990
2 _FNA NO
1 FAMC @2113@
0 @2138@ INDI
1 NAME Théophile/MEURIOT/
2 GIVN Théophile
2 SURN MEURIOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 FEB 2018
3 TIME 22:25
1 BIRT
2 DATE 28 APR 1995
2 _FNA NO
1 FAMC @2113@
0 @2148@ INDI
1 NAME Steve/BOUYER/
2 GIVN Steve
2 SURN BOUYER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 14:28
1 FAMS @2150@
0 @2175@ INDI
1 NAME Thérésine Térésina Pauline/LACREUSETTE/
2 GIVN Thérésine Térésina Pauline
2 SURN LACREUSETTE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 JUN 2019
3 TIME 12:08
1 FAMS @2178@
1 BIRT
2 DATE 24 JUL 1813
2 PLAC Marseille,13000,Bouches-du-Rhône,Provence-Alpes-Côte d'Azur,FRANCE,
3 MAP
4 LATI N43.296950
4 LONG E5.381070
2 _FNA NO
1 DEAT
2 DATE 1842
2 PLAC Marseille,13000,Bouches-du-Rhône,Provence-Alpes-Côte d'Azur,FRANCE,
3 MAP
4 LATI N43.296950
4 LONG E5.381070
2 _FNA NO
2 NOTE Dans tombe   d'edouard AMBROSELLI  CPP 1874 allée Nicolas 3°ligne n°45 cimetière de Montmartre Epitaphes à
3 CONT Mere d'edouard AMBROSELLI  = Therese pauline LACREUSETTE née en 1813 Dcd à Marseille en 1842 dans sa 29' année
1 FAMC @8539@
0 @2190@ INDI
1 NAME Marie-Thérèse Joséphine Louise/AMBROSELLI/
2 GIVN Marie-Thérèse Joséphine Louise
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2019
3 TIME 11:32
1 FAMS @2208@
1 BIRT
2 DATE 25 MAR 1876
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 1963
2 _FNA NO
1 FAMC @568@
0 @2206@ INDI
1 NAME André Paul/CURY/
2 GIVN André Paul
2 SURN CURY
1 SEX M
1 OCCU Enseignant
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2019
3 TIME 11:32
1 FAMS @2208@
1 BIRT
2 DATE 4 AUG 1869
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 1937
2 _FNA NO
1 FAMC @34372@
0 @2209@ INDI
1 NAME Antoine/AMBROSELLI/
2 GIVN Antoine
2 SURN AMBROSELLI
1 SEX M
1 OCCU marin
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 JUN 2019
3 TIME 10:08
1 FAMS @2221@
1 BIRT
2 DATE 1770
2 PLAC Barrettali,20228,Haute-Corse,Corse,FRANCE,
3 MAP
4 LATI N42.876900
4 LONG E9.355400
2 _FNA NO
1 FAMC @2233@
0 @2218@ INDI
1 NAME Agnès Marie/FILLASTRE/
2 GIVN Agnès Marie
2 SURN FILLASTRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 JUN 2019
3 TIME 10:08
1 FAMS @2221@
1 BIRT
2 DATE 1769
2 _FNA NO
1 DEAT
2 DATE 1829
2 _FNA NO
1 NOTE ou FIASTRE?
1 FAMC @23039@
0 @2231@ INDI
1 NAME Marie/LUCIONI/
2 GIVN Marie
2 SURN LUCIONI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 19:56
1 FAMS @2233@
1 BIRT
2 DATE 1740
2 _FNA YES
2 NOTE OU 1738?
1 DEAT
2 DATE 14 DEC 1812
2 PLAC Barrettali,20228,Haute-Corse,Corse,FRANCE,
3 MAP
4 LATI N42.876900
4 LONG E9.355400
2 _FNA NO
1 NOTE ou LICCIONI?
0 @2237@ INDI
1 NAME Anne-Marie/CURY/
2 GIVN Anne-Marie
2 SURN CURY
2 NICK MICAU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 8:02
1 FAMS @11386@
1 BIRT
2 DATE 1907
2 _FNA NO
1 DEAT
2 DATE 1991
2 _FNA NO
1 FAMC @2208@
0 @2242@ INDI
1 NAME Pierre/CURY/
2 GIVN Pierre
2 SURN CURY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 9:06
1 FAMC @2208@
0 @2247@ INDI
1 NAME Paul/CURY/
2 GIVN Paul
2 SURN CURY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 8:10
1 FAMS @35365@
1 BIRT
2 DATE 1899
2 _FNA NO
1 DEAT
2 DATE 1978
2 _FNA NO
1 FAMC @2208@
0 @2264@ INDI
1 NAME Monique/BECHET/
2 GIVN Monique
2 SURN BECHET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 DEC 2019
3 TIME 8:58
1 FAMS @11216@
1 BIRT
2 DATE 8 JUL 1926
2 _FNA NO
1 DEAT
2 DATE 10 DEC 2019
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 BURI
2 DATE 13 DEC 2019
3 TIME 16:30
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
1 FAMC @682@
0 @2269@ INDI
1 NAME Jacqueline/BECHET/
2 GIVN Jacqueline
2 SURN BECHET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 9:29
1 FAMS @5108@
1 BIRT
2 DATE 9 JUN 1928
2 _FNA NO
1 FAMC @682@
0 @2274@ INDI
1 NAME Marie-France/BECHET/
2 GIVN Marie-France
2 SURN BECHET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 JUN 2019
3 TIME 14:21
1 FAMS @11230@
1 FAMS @11249@
1 FAMC @682@
0 @2279@ INDI
1 NAME Geneviève Marie Cécile/AMBROSELLI/
2 GIVN Geneviève Marie Cécile
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 18:03
1 FAMS @2297@
1 BIRT
2 DATE 2 MAR 1899
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 17 AUG 1973
2 PLAC Vernon,27200,Eure,Normandie,FRANCE,
3 MAP
4 LATI N49.092920
4 LONG E1.463320
2 _FNA NO
2 NOTE suite AVP sur A13
1 FAMC @489@
0 @2295@ INDI
1 NAME Jacques/HERBILLON/
2 GIVN Jacques
2 SURN HERBILLON
1 SEX M
1 OCCU ingénieur agronome
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 18:03
1 FAMS @2297@
1 BIRT
2 DATE 27 MAY 1898
2 PLAC Nucourt,95420,Val-d'Oise,Île-de-France,FRANCE,
3 MAP
4 LATI N49.158880
4 LONG E1.853360
2 _FNA NO
1 DEAT
2 DATE 23 MAY 1953
2 PLAC Alicante,,,,,
3 MAP
4 LATI N38.345170
4 LONG W0.481490
2 _FNA NO
1 FAMC @16945@
0 @2298@ INDI
1 NAME Bernadette/HERBILLON/
2 GIVN Bernadette
2 SURN HERBILLON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 18:30
1 FAMS @16956@
1 BIRT
2 DATE 1922
2 _FNA NO
1 DEAT
2 DATE 1 NOV 2016
2 _FNA NO
1 FAMC @2297@
0 @2305@ INDI
1 NAME Dominique/HERBILLON/
2 GIVN Dominique
2 SURN HERBILLON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 18:12
1 FAMC @2297@
0 @2310@ INDI
1 NAME Bruno Max/HERBILLON/
2 GIVN Bruno Max
2 SURN HERBILLON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:24
1 BIRT
2 DATE 26 FEB 1923
2 PLAC Casablanca,,,,,
3 MAP
4 LATI N33.592780
4 LONG W7.619160
2 _FNA NO
1 DEAT
2 DATE 9 JAN 2017
2 _FNA NO
1 BURI
2 DATE 14 JAN 2017
2 PLAC Thouars,79100,Deux-Sèvres,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.976020
4 LONG W0.215070
2 _FNA NO
2 NOTE L'enterrement de Bruno aura lieu samedi 14/01/17 à 15h à l'église ND du Cottage, rue Paul Bert, à Thouars
3 CONT
1 FAMC @2297@
0 @2324@ INDI
1 NAME Anne/HERBILLON/
2 GIVN Anne
2 SURN HERBILLON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 9:23
1 FAMC @2297@
0 @2333@ INDI
1 NAME John Raphaël/BAIRD-SMITH/
2 GIVN John Raphaël
2 SURN BAIRD-SMITH
2 NICK néné
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 7:48
1 FAMS @2705@
1 BIRT
2 DATE 21 OCT 1963
2 PLAC Pereira,,,,COLOMBIE,
2 _FNA NO
1 FAMC @1002@
0 @2347@ INDI
1 NAME Jessica/BAIRD-SMITH/
2 GIVN Jessica
2 SURN BAIRD-SMITH
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 7:49
1 FAMS @2661@
1 BIRT
2 DATE 21 JAN 1965
2 PLAC Glasgow,,,,ECOSSE,
3 MAP
4 LATI N55.865150
4 LONG W4.257630
2 _FNA NO
1 FAMC @1002@
0 @2358@ INDI
1 NAME Veronica/BAIRD-SMITH/
2 GIVN Veronica
2 SURN BAIRD-SMITH
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:20
1 FAMS @2632@
1 BIRT
2 DATE 27 AUG 1966
2 _FNA NO
1 FAMC @1002@
0 @2369@ INDI
1 NAME Anselm/BAIRD-SMITH/
2 GIVN Anselm
2 SURN BAIRD-SMITH
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:17
1 FAMS @2603@
1 BIRT
2 DATE 21 NOV 1967
2 _FNA NO
1 FAMC @1002@
0 @2380@ INDI
1 NAME Georgina/BAIRD-SMITH/
2 GIVN Georgina
2 SURN BAIRD-SMITH
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:13
1 FAMS @2571@
1 BIRT
2 DATE 5 MAY 1971
2 _FNA NO
1 FAMC @1002@
0 @2391@ INDI
1 NAME Mary/BAIRD-SMITH/
2 GIVN Mary
2 SURN BAIRD-SMITH
2 NICK Baba
1 SEX F
1 OCCU Artiste Peintre
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 8:26
1 FAMS @2538@
1 BIRT
2 DATE 24 SEP 1972
2 _FNA NO
1 FAMC @1002@
0 @2402@ INDI
1 NAME Ann/BAIRD-SMITH/
2 GIVN Ann
2 SURN BAIRD-SMITH
2 NICK Nénette
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 8:49
1 FAMS @2506@
1 FAMS @2524@
1 BIRT
2 DATE 22 FEB 1978
2 _FNA NO
1 FAMC @1002@
0 @2413@ INDI
1 NAME Dominic/BAIRD-SMITH/
2 GIVN Dominic
2 SURN BAIRD-SMITH
2 NICK domdom
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 8:52
1 FAMS @2481@
1 BIRT
2 DATE 13 JAN 1980
2 _FNA NO
1 FAMC @1002@
0 @2424@ INDI
1 NAME Elizabeth/BAIRD-SMITH/
2 GIVN Elizabeth
2 SURN BAIRD-SMITH
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 9:49
1 FAMS @2452@
1 BIRT
2 DATE 4 JUL 1982
2 _FNA NO
1 FAMC @1002@
0 @2435@ INDI
1 NAME You-Ann/BAIRD-SMITH/
2 GIVN You-Ann
2 SURN BAIRD-SMITH
1 SEX M
1 _FIL ADOPTED_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 18:28
1 BIRT
2 DATE 10 JAN 1965
2 PLAC Cambodge,,,,,
2 _FNA NO
1 FAMC @1002@
0 @2450@ INDI
1 NAME Tom Thomas/CHALLENGER/
2 GIVN Tom Thomas
2 SURN CHALLENGER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 9:49
1 FAMS @2452@
0 @2454@ INDI
1 NAME Emile Walter Gérard/CHALLENGER/
2 GIVN Emile Walter Gérard
2 SURN CHALLENGER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 9:48
1 BIRT
2 DATE 11 NOV 2013
2 _FNA NO
1 FAMC @2452@
0 @2462@ INDI
1 NAME Germell/CHALLENGER/
2 GIVN Germell
2 SURN CHALLENGER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 15:13
1 BIRT
2 DATE 10 AUG 2016
2 _FNA NO
1 FAMC @2452@
0 @2479@ INDI
1 NAME Marion/GRANCHER/
2 GIVN Marion
2 SURN GRANCHER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:02
1 FAMS @2481@
0 @2488@ INDI
1 NAME Angus/BAIRD-SMITH/
2 GIVN Angus
2 SURN BAIRD-SMITH
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 APR 2018
3 TIME 18:38
1 BIRT
2 DATE 27 SEP 2010
2 _FNA NO
1 FAMC @2481@
0 @2496@ INDI
1 NAME Neil/BAIRD-SMITH/
2 GIVN Neil
2 SURN BAIRD-SMITH
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 SEP 2019
3 TIME 8:55
1 BIRT
2 DATE 13 MAY 2015
2 _FNA NO
1 FAMC @2481@
0 @2504@ INDI
1 NAME Christophe/MARCHAND/
2 GIVN Christophe
2 SURN MARCHAND
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:05
1 FAMS @2506@
0 @2508@ INDI
1 NAME Nils/MARCHAND/
2 GIVN Nils
2 SURN MARCHAND
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:04
1 BIRT
2 DATE JUN 2006
2 _FNA NO
1 FAMC @2506@
0 @2516@ INDI
1 NAME Séléna/MARCHAND/
2 GIVN Séléna
2 SURN MARCHAND
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:05
1 BIRT
2 DATE OCT 2007
2 _FNA NO
1 FAMC @2506@
0 @2523@ INDI
1 NAME y/X/
2 GIVN y
2 SURN X
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:07
1 FAMS @2524@
0 @2526@ INDI
1 NAME Joe/BAIRD-SMITH/
2 GIVN Joe
2 SURN BAIRD-SMITH
1 SEX F
1 _FIL NATURAL_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:07
1 BIRT
2 DATE JUL 2016
2 _FNA NO
1 FAMC @2524@
0 @2534@ INDI
1 NAME Aziz/DIENE/
2 GIVN Aziz
2 SURN DIENE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:10
1 FAMS @2538@
1 BIRT
2 DATE 9 MAR 1956
2 _FNA NO
0 @2540@ INDI
1 NAME Mamadou/DIENE/
2 GIVN Mamadou
2 SURN DIENE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:08
1 BIRT
2 DATE 14 FEB 2001
2 _FNA NO
1 FAMC @2538@
0 @2548@ INDI
1 NAME Anselm/DIENE/
2 GIVN Anselm
2 SURN DIENE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 12:57
1 BIRT
2 DATE 2007
2 _FNA NO
1 FAMC @2538@
0 @2556@ INDI
1 NAME Rimagita/BAIRD-SMITH/
2 GIVN Rimagita
2 SURN BAIRD-SMITH
1 SEX F
1 _FIL NATURAL_CHILD
1 CHAN
2 DATE 16 SEP 2019
3 TIME 8:55
1 BIRT
2 DATE JUL 2015
2 _FNA NO
1 FAMC @2538@
0 @2567@ INDI
1 NAME Jonathan/AASGAARD/
2 GIVN Jonathan
2 SURN AASGAARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:13
1 FAMS @2571@
1 BIRT
2 DATE 20 OCT 1974
2 _FNA NO
0 @2574@ INDI
1 NAME Thélonius/AASGAARD/
2 GIVN Thélonius
2 SURN AASGAARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2019
3 TIME 6:59
1 BIRT
2 DATE 2 MAY 2002
2 PLAC Liverpool,,,,,
2 _FNA NO
1 FAMC @2571@
0 @2582@ INDI
1 NAME Ismaël/AASGAARD/
2 GIVN Ismaël
2 SURN AASGAARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:13
1 BIRT
2 DATE 7 JUL 2004
2 _FNA NO
1 FAMC @2571@
0 @2590@ INDI
1 NAME Olga/AASGAARD/
2 GIVN Olga
2 SURN AASGAARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:13
1 BIRT
2 DATE 26 JUL 2007
2 _FNA NO
1 FAMC @2571@
0 @2599@ INDI
1 NAME Virginie/BLOUIN/
2 GIVN Virginie
2 SURN BLOUIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:17
1 FAMS @2603@
1 BIRT
2 DATE 18 JUN 1967
2 _FNA NO
0 @2605@ INDI
1 NAME Paul/BAIRD-SMITH/
2 GIVN Paul
2 SURN BAIRD-SMITH
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:16
1 BIRT
2 DATE 15 AUG 1996
2 _FNA NO
1 FAMC @2603@
0 @2613@ INDI
1 NAME William/BAIRD-SMITH/
2 GIVN William
2 SURN BAIRD-SMITH
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:16
1 BIRT
2 DATE 21 JAN 1998
2 _FNA NO
1 FAMC @2603@
0 @2620@ INDI
1 NAME Julie/BAIRD-SMITH/
2 GIVN Julie
2 SURN BAIRD-SMITH
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:17
1 BIRT
2 DATE 10 AUG 1999
2 _FNA NO
1 FAMC @2603@
0 @2628@ INDI
1 NAME Marc/POUTIGNAT/
2 GIVN Marc
2 SURN POUTIGNAT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 7:59
1 FAMS @2632@
1 BIRT
2 DATE 15 DEC 1960
2 _FNA NO
1 FAMC @8429@
0 @2634@ INDI
1 NAME Raphaël/POUTIGNAT/
2 GIVN Raphaël
2 SURN POUTIGNAT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:19
1 BIRT
2 DATE 4 MAY 1989
2 _FNA NO
1 FAMC @2632@
0 @2642@ INDI
1 NAME Élie/POUTIGNAT/
2 GIVN Élie
2 SURN POUTIGNAT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:19
1 BIRT
2 DATE 19 AUG 1990
2 _FNA NO
1 FAMC @2632@
0 @2649@ INDI
1 NAME Samuel/POUTIGNAT/
2 GIVN Samuel
2 SURN POUTIGNAT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:20
1 BIRT
2 DATE 25 JUL 1992
2 _FNA NO
1 FAMC @2632@
0 @2657@ INDI
1 NAME Éric/MEURIOT/
2 GIVN Éric
2 SURN MEURIOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 7:51
1 FAMS @2661@
1 BIRT
2 DATE 18 JUL 1954
2 PLAC Tanger,,,,MAROC,
2 _FNA NO
0 @2663@ INDI
1 NAME Thomas/MEURIOT/
2 GIVN Thomas
2 SURN MEURIOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 15:20
1 BIRT
2 DATE 10 JUL 1985
2 PLAC Nantes,44000,Loire-Atlantique,Pays de la Loire,FRANCE,
3 MAP
4 LATI N47.217250
4 LONG W1.553360
2 _FNA NO
2 NOTE ainé des arrières-PE de GAFA
1 FAMC @2661@
0 @2670@ INDI
1 NAME Claire/MEURIOT/
2 GIVN Claire
2 SURN MEURIOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 15:20
1 BIRT
2 DATE 9 APR 1988
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @2661@
0 @2678@ INDI
1 NAME Laetitia/MEURIOT/
2 GIVN Laetitia
2 SURN MEURIOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 MAY 2019
3 TIME 18:27
1 FAMS @22968@
1 BIRT
2 DATE 16 OCT 1990
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @2661@
0 @2686@ INDI
1 NAME Ophélie/MEURIOT/
2 GIVN Ophélie
2 SURN MEURIOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:23
1 BIRT
2 DATE 26 APR 1996
2 _FNA NO
1 FAMC @2661@
0 @2693@ INDI
1 NAME Matthieu/MEURIOT/
2 GIVN Matthieu
2 SURN MEURIOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:23
1 BIRT
2 DATE 5 NOV 2000
2 _FNA NO
1 FAMC @2661@
0 @2701@ INDI
1 NAME Marie/STEPHANI/
2 GIVN Marie
2 SURN STEPHANI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 7:47
1 FAMS @2705@
1 BIRT
2 DATE 18 JAN 1969
2 _FNA NO
0 @2709@ INDI
1 NAME Susan/BAIRD-SMITH/
2 GIVN Susan
2 SURN BAIRD-SMITH
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:25
1 BIRT
2 DATE 4 JAN 1991
2 _FNA NO
1 FAMC @2705@
0 @2716@ INDI
1 NAME David/BAIRD-SMITH/
2 GIVN David
2 SURN BAIRD-SMITH
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:26
1 BIRT
2 DATE 19 JUN 1993
2 _FNA NO
1 FAMC @2705@
0 @2723@ INDI
1 NAME Colomba/BAIRD-SMITH/
2 GIVN Colomba
2 SURN BAIRD-SMITH
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:26
1 BIRT
2 DATE 11 JUL 1999
2 _FNA NO
1 FAMC @2705@
0 @2730@ INDI
1 NAME Laetitia Anne Marie Dominique/AMBROSELLI/
2 GIVN Laetitia Anne Marie Dominique
2 SURN AMBROSELLI
1 SEX F
1 OCCU Psychologue
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 JUN 2019
3 TIME 19:17
1 FAMS @2926@
1 BIRT
2 DATE 10 OCT 1970
2 PLAC Paris 17,,,,,
2 _FNA NO
1 FAMC @1007@
0 @2740@ INDI
1 NAME Christel Marie Monique Geneviève/AMBROSELLI/
2 GIVN Christel Marie Monique Geneviève
2 SURN AMBROSELLI
1 SEX F
1 OCCU Journaliste
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 DEC 2018
3 TIME 18:29
1 FAMS @2899@
1 BIRT
2 DATE 27 JUL 1973
2 PLAC Boulogne-Billancourt,92100,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.833330
4 LONG E2.250000
2 _FNA NO
1 FAMC @1007@
0 @2750@ INDI
1 NAME Constance Marie Louise/AMBROSELLI/
2 GIVN Constance Marie Louise
2 SURN AMBROSELLI
1 SEX F
1 OCCU avocate
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 DEC 2018
3 TIME 18:33
1 FAMS @2870@
1 BIRT
2 DATE 24 JUL 1974
2 PLAC Boulogne-Billancourt,92100,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.833330
4 LONG E2.250000
2 _FNA NO
1 FAMC @1007@
0 @2761@ INDI
1 NAME Étienne Daniel Joseph Alfred Gérard/AMBROSELLI/
2 GIVN Étienne Daniel Joseph Alfred Gérard
2 SURN AMBROSELLI
1 SEX M
1 OCCU avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 DEC 2018
3 TIME 18:35
1 FAMS @2855@
1 BIRT
2 DATE 19 MAR 1976
2 PLAC Boulogne-Billancourt,92100,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.833330
4 LONG E2.250000
2 _FNA NO
1 FAMC @1007@
0 @2772@ INDI
1 NAME Michaël Jean Théophane Joseph George/AMBROSELLI/
2 GIVN Michaël Jean Théophane Joseph George
2 SURN AMBROSELLI
1 SEX M
1 OCCU Ingénieur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 DEC 2018
3 TIME 18:38
1 FAMS @2828@
1 BIRT
2 DATE 12 OCT 1979
2 PLAC Boulogne-Billancourt,92100,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.833330
4 LONG E2.250000
2 _FNA NO
1 FAMC @1007@
0 @2782@ INDI
1 NAME Clarisse France Marie Madeleine/AMBROSELLI/
2 GIVN Clarisse France Marie Madeleine
2 SURN AMBROSELLI
1 SEX F
1 OCCU musicienne
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 DEC 2018
3 TIME 18:45
1 FAMS @2796@
1 CHR
2 DATE 1983
2 _FNA NO
1 BIRT
2 DATE 2 AUG 1983
2 PLAC Boulogne-Billancourt,92100,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.833330
4 LONG E2.250000
2 _FNA NO
1 FAMC @1007@
0 @2794@ INDI
1 NAME Nicolas Pierre-Marie Jean-Jacques/DESLANDES/
2 GIVN Nicolas Pierre-Marie Jean-Jacques
2 SURN DESLANDES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 DEC 2018
3 TIME 18:45
1 FAMS @2796@
0 @2799@ INDI
1 NAME Noah/AMBROSELLI/
2 GIVN Noah
2 SURN AMBROSELLI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:51
1 BIRT
2 DATE 11 FEB 2015
2 PLAC Australie,,,,,
2 _FNA NO
1 FAMC @2796@
0 @2809@ INDI
1 NAME Elsa Christiane Isabelle Yindi/AMBROSELLI/
2 GIVN Elsa Christiane Isabelle Yindi
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:51
1 BIRT
2 DATE 21 MAR 2017
2 PLAC Australie,,,,,
2 _FNA NO
1 FAMC @2796@
0 @2826@ INDI
1 NAME Juliette Marie Hélène/HAMY/
2 GIVN Juliette Marie Hélène
2 SURN HAMY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 DEC 2018
3 TIME 18:38
1 FAMS @2828@
0 @2830@ INDI
1 NAME Agathe/AMBROSELLI/
2 GIVN Agathe
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:53
1 BIRT
2 DATE 1 AUG 2007
2 _FNA NO
1 FAMC @2828@
0 @2837@ INDI
1 NAME Samuel/AMBROSELLI/
2 GIVN Samuel
2 SURN AMBROSELLI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:54
1 BIRT
2 DATE SEP 2009
2 _FNA NO
1 FAMC @2828@
0 @2844@ INDI
1 NAME Olga/AMBROSELLI/
2 GIVN Olga
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:56
1 BIRT
2 DATE 7 DEC 2012
2 _FNA NO
1 FAMC @2828@
0 @2853@ INDI
1 NAME Nathalie/VILLENEUVE/
2 GIVN Nathalie
2 SURN VILLENEUVE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 DEC 2018
3 TIME 18:35
1 FAMS @2855@
0 @2856@ INDI
1 NAME Joseph Chléophas René Édouard/AMBROSELLI-VILLENEUVE/
2 GIVN Joseph Chléophas René Édouard
2 SURN AMBROSELLI-VILLENEUVE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 18:57
1 BIRT
2 DATE 14 MAR 2008
3 TIME 4:23
2 _FNA NO
1 FAMC @2855@
0 @2866@ INDI
1 NAME Gustavo Eduardo/BENITO/
2 GIVN Gustavo Eduardo
2 SURN BENITO
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 DEC 2018
3 TIME 18:33
1 FAMS @2870@
1 BIRT
2 DATE 25 MAY 1969
2 _FNA NO
0 @2872@ INDI
1 NAME Giacomo/BENITO/
2 GIVN Giacomo
2 SURN BENITO
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:59
1 BIRT
2 DATE 30 MAY 2003
2 _FNA NO
1 FAMC @2870@
0 @2880@ INDI
1 NAME Philéas/BENITO/
2 GIVN Philéas
2 SURN BENITO
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 10:59
1 BIRT
2 DATE APR 2006
2 _FNA NO
1 FAMC @2870@
0 @2887@ INDI
1 NAME Emile/BENITO/
2 GIVN Emile
2 SURN BENITO
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 11:00
1 BIRT
2 DATE MAR 2010
2 _FNA NO
1 FAMC @2870@
0 @2895@ INDI
1 NAME Benoît/SURUN/
2 GIVN Benoît
2 SURN SURUN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 DEC 2018
3 TIME 18:27
1 FAMS @2899@
1 BIRT
2 DATE 10 SEP 1970
2 _FNA NO
0 @2902@ INDI
1 NAME Anouck Élia/SURUN/
2 GIVN Anouck Élia
2 SURN SURUN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 11:01
1 BIRT
2 DATE 2 MAR 2005
2 _FNA NO
1 FAMC @2899@
0 @2913@ INDI
1 NAME Otis/SURUN/
2 GIVN Otis
2 SURN SURUN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 19:00
1 BIRT
2 DATE 21 AUG 2007
2 _FNA NO
1 FAMC @2899@
0 @2922@ INDI
1 NAME Sylvain Marie Bruno/CHARIGNON/
2 GIVN Sylvain Marie Bruno
2 SURN CHARIGNON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 JUN 2019
3 TIME 19:17
1 FAMS @2926@
1 BIRT
2 DATE 11 MAR 1971
2 _FNA NO
0 @2932@ INDI
1 NAME Emmanuel Douramane/de BAYSER/
2 GIVN Emmanuel Douramane
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 19:42
1 BIRT
2 DATE 11 JUL 1972
2 _FNA NO
1 FAMC @1019@
0 @2943@ INDI
1 NAME Jean-Irénée/de BAYSER/
2 GIVN Jean-Irénée
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 MAR 2018
3 TIME 10:15
1 FAMS @3067@
1 BIRT
2 DATE 6 MAY 1976
2 _FNA NO
1 FAMC @1019@
0 @2954@ INDI
1 NAME Marie-Ange/de BAYSER/
2 GIVN Marie-Ange
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 19:42
1 FAMS @3031@
1 BIRT
2 DATE 18 FEB 1978
2 _FNA NO
1 FAMC @1019@
0 @2965@ INDI
1 NAME Priscilia/de BAYSER/
2 GIVN Priscilia
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 MAR 2018
3 TIME 7:36
1 FAMS @3011@
1 BIRT
2 DATE 25 OCT 1980
2 _FNA NO
1 FAMC @1019@
0 @2976@ INDI
1 NAME Paul-Etienne/de BAYSER/
2 GIVN Paul-Etienne
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 17:00
1 FAMS @2990@
1 BIRT
2 DATE 27 APR 1982
2 _FNA NO
1 FAMC @1019@
0 @2988@ INDI
1 NAME Dorien/PEETERS/
2 GIVN Dorien
2 SURN PEETERS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 17:00
1 FAMS @2990@
0 @2993@ INDI
1 NAME Marie Rose Catherine/de BAYSER/
2 GIVN Marie Rose Catherine
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 19:44
1 BIRT
2 DATE 13 OCT 2015
2 PLAC New York,,,,,
3 MAP
4 LATI N40.714270
4 LONG W74.005970
2 _FNA NO
1 FAMC @2990@
0 @3000@ INDI
1 NAME Rose Charlotte Karin/de BAYSER/
2 GIVN Rose Charlotte Karin
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 MAR 2018
3 TIME 7:37
1 BIRT
2 DATE 20 JAN 2018
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @2990@
0 @3009@ INDI
1 NAME Howard/HORNUS/
2 GIVN Howard
2 SURN HORNUS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 11:20
1 FAMS @3011@
0 @3014@ INDI
1 NAME Sébastien/HORNUS/
2 GIVN Sébastien
2 SURN HORNUS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 11:19
1 BIRT
2 DATE 2010
2 _FNA NO
1 FAMC @3011@
0 @3021@ INDI
1 NAME Charles/HORNUS/
2 GIVN Charles
2 SURN HORNUS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 11:20
1 BIRT
2 DATE AUG 2013
2 _FNA NO
1 FAMC @3011@
0 @3029@ INDI
1 NAME Guy/PIERSON de BRABOIS/
2 GIVN Guy
2 SURN PIERSON de BRABOIS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 19:42
1 FAMS @3031@
0 @3034@ INDI
1 NAME Gabriella/de BRABOIS/
2 GIVN Gabriella
2 SURN de BRABOIS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 11:22
1 BIRT
2 DATE 30 APR 2011
2 _FNA NO
1 FAMC @3031@
0 @3043@ INDI
1 NAME Clara/de BRABOIS/
2 GIVN Clara
2 SURN de BRABOIS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 19:20
1 BIRT
2 DATE 17 FEB 2014
2 PLAC Zürich,8000,Zurich,Zurich,SUISSE,
3 MAP
4 LATI N47.366670
4 LONG E8.550000
2 _FNA NO
2 NOTE JUMELLE
1 FAMC @3031@
0 @3051@ INDI
1 NAME Olympia/de BRABOIS/
2 GIVN Olympia
2 SURN de BRABOIS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 19:20
1 BIRT
2 DATE 17 FEB 2014
2 PLAC Zürich,8000,Zurich,Zurich,SUISSE,
3 MAP
4 LATI N47.366670
4 LONG E8.550000
2 _FNA NO
2 NOTE JUMELLE
1 FAMC @3031@
0 @3062@ INDI
1 NAME Cécile/JANIN/
2 GIVN Cécile
2 SURN JANIN
1 SEX F
1 OCCU ingénieur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 11:26
1 FAMS @3067@
1 BIRT
2 DATE 11 MAY 1979
2 _FNA NO
0 @3069@ INDI
1 NAME Clément/de BAYSER/
2 GIVN Clément
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 11:25
1 BIRT
2 DATE 10 JAN 2003
2 _FNA NO
1 FAMC @3067@
0 @3077@ INDI
1 NAME Élise/de BAYSER/
2 GIVN Élise
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 11:26
1 BIRT
2 DATE 12 AUG 2005
2 _FNA NO
1 FAMC @3067@
0 @3085@ INDI
1 NAME Élodie/de BAYSER/
2 GIVN Élodie
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 11:26
1 BIRT
2 DATE 12 AUG 2009
2 _FNA NO
1 FAMC @3067@
0 @3103@ INDI
1 NAME Raphaël/SEYDOUX/
2 GIVN Raphaël
2 SURN SEYDOUX
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 8:08
1 FAMS @3358@
1 BIRT
2 DATE 23 APR 1970
2 PLAC Paris 17,,,,,
2 _FNA NO
1 FAMC @1028@
0 @3113@ INDI
1 NAME Anne-France/SEYDOUX/
2 GIVN Anne-France
2 SURN SEYDOUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 8:18
1 FAMS @3320@
1 BIRT
2 DATE 2 NOV 1971
2 PLAC Paris 17,,,,,
2 _FNA NO
2 NOTE CLINIQUE SAINTE THERESE
1 FAMC @1028@
0 @3123@ INDI
1 NAME Violaine/SEYDOUX/
2 GIVN Violaine
2 SURN SEYDOUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 8:46
1 FAMS @3265@
1 BIRT
2 DATE 20 JAN 1977
2 PLAC Boulogne-Billancourt,92100,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.833330
4 LONG E2.250000
2 _FNA NO
2 NOTE BELVEDERE
1 FAMC @1028@
0 @3132@ INDI
1 NAME Jean-Baptiste/SEYDOUX/
2 GIVN Jean-Baptiste
2 SURN SEYDOUX
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:21
1 FAMS @3235@
1 FAMS @35203@
1 BIRT
2 DATE 7 NOV 1978
2 PLAC Saint-Renan,29290,Finistère,Bretagne,FRANCE,
3 MAP
4 LATI N48.433330
4 LONG W4.616670
2 _FNA NO
1 FAMC @1028@
0 @3142@ INDI
1 NAME Marie-Alice/SEYDOUX/
2 GIVN Marie-Alice
2 SURN SEYDOUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 OCT 2018
3 TIME 8:05
1 FAMS @3198@
1 BIRT
2 DATE 19 MAY 1980
2 PLAC Saint-Renan,29290,Finistère,Bretagne,FRANCE,
3 MAP
4 LATI N48.433330
4 LONG W4.616670
2 _FNA NO
1 FAMC @1028@
0 @3151@ INDI
1 NAME Flore/SEYDOUX/
2 GIVN Flore
2 SURN SEYDOUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 9:01
1 BIRT
2 DATE 23 SEP 1983
2 PLAC Boulogne-Billancourt,92100,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.833330
4 LONG E2.250000
2 _FNA NO
1 FAMC @1028@
0 @3160@ INDI
1 NAME Agathe/SEYDOUX/
2 GIVN Agathe
2 SURN SEYDOUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 11:44
1 FAMS @3175@
1 BIRT
2 DATE 1 AUG 1986
2 _FNA NO
1 FAMC @1028@
0 @3171@ INDI
1 NAME Séverin/HATT/
2 GIVN Séverin
2 SURN HATT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 11:44
1 FAMS @3175@
1 BIRT
2 DATE 5 JUL 1978
2 _FNA NO
0 @3178@ INDI
1 NAME Gaston Marc Félicien/HATT/
2 GIVN Gaston Marc Félicien
2 SURN HATT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 19:28
1 BIRT
2 DATE 2 APR 2011
2 PLAC Winterthur,8400,Zurich,Zurich,SUISSE,
3 MAP
4 LATI N47.500000
4 LONG E8.750000
2 _FNA NO
1 FAMC @3175@
0 @3186@ INDI
1 NAME Lila-Rose Marie France/HATT/
2 GIVN Lila-Rose Marie France
2 SURN HATT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 19:14
1 BIRT
2 DATE 13 SEP 2012
2 PLAC Fécamp,76400,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.757870
4 LONG E0.374570
2 _FNA NO
1 FAMC @3175@
0 @3194@ INDI
1 NAME Benoît/LOISEAU/
2 GIVN Benoît
2 SURN LOISEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 OCT 2018
3 TIME 8:05
1 FAMS @3198@
1 BIRT
2 DATE 14 APR 1977
2 _FNA NO
0 @3201@ INDI
1 NAME Félicité/LOISEAU/
2 GIVN Félicité
2 SURN LOISEAU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 19:10
1 BIRT
2 DATE 23 OCT 2007
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @3198@
0 @3209@ INDI
1 NAME Matthias/LOISEAU/
2 GIVN Matthias
2 SURN LOISEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 11:47
1 BIRT
2 DATE 20 MAY 2009
2 _FNA NO
1 FAMC @3198@
0 @3217@ INDI
1 NAME Rosalie/LOISEAU/
2 GIVN Rosalie
2 SURN LOISEAU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 19:06
1 BIRT
2 DATE 18 JAN 2012
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @3198@
0 @3224@ INDI
1 NAME François/LOISEAU/
2 GIVN François
2 SURN LOISEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 11:47
1 BIRT
2 DATE 10 SEP 2015
2 _FNA NO
1 FAMC @3198@
0 @3231@ INDI
1 NAME Julie/de BAYSER/
2 GIVN Julie
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 NOV 2018
3 TIME 15:29
1 FAMS @3235@
1 BIRT
2 DATE 20 FEB 1979
2 _FNA NO
0 @3237@ INDI
1 NAME Colombe Pauline Marie Camille/SEYDOUX/
2 GIVN Colombe Pauline Marie Camille
2 SURN SEYDOUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 19:26
1 BIRT
2 DATE 14 JUL 2000
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 CHR
2 DATE 27 AUG 2000
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
1 FAMC @3235@
0 @3245@ INDI
1 NAME Gabrielle/SEYDOUX/
2 GIVN Gabrielle
2 SURN SEYDOUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 11:50
1 BIRT
2 DATE 27 SEP 2004
2 _FNA NO
1 FAMC @3235@
0 @3253@ INDI
1 NAME Faustine/SEYDOUX/
2 GIVN Faustine
2 SURN SEYDOUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 11:50
1 BIRT
2 DATE 11 AUG 2009
2 _FNA NO
1 FAMC @3235@
0 @3261@ INDI
1 NAME Romain/LELEU/
2 GIVN Romain
2 SURN LELEU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 11:53
1 FAMS @3265@
1 BIRT
2 DATE 13 SEP 1973
2 _FNA NO
0 @3267@ INDI
1 NAME Laetitia/LELEU/
2 GIVN Laetitia
2 SURN LELEU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 11:51
1 BIRT
2 DATE 31 AUG 2005
2 _FNA NO
1 FAMC @3265@
0 @3275@ INDI
1 NAME Théodore/LELEU/
2 GIVN Théodore
2 SURN LELEU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 19:27
1 BIRT
2 DATE 20 JAN 2007
2 PLAC Pau,64000,Pyrénées-Atlantiques,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N43.300000
4 LONG W0.366670
2 _FNA NO
1 FAMC @3265@
0 @3283@ INDI
1 NAME Syméon Marie Baptiste/LELEU/
2 GIVN Syméon Marie Baptiste
2 SURN LELEU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 19:30
1 BIRT
2 DATE 14 JUL 2008
2 PLAC Pau,64000,Pyrénées-Atlantiques,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N43.300000
4 LONG W0.366670
2 _FNA NO
1 FAMC @3265@
0 @3290@ INDI
1 NAME Marie/LELEU/
2 GIVN Marie
2 SURN LELEU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 8:08
1 BIRT
2 DATE 14 JAN 2011
2 _FNA NO
2 NOTE JUMELLE
1 FAMC @3265@
0 @3298@ INDI
1 NAME Gladis/LELEU/
2 GIVN Gladis
2 SURN LELEU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 8:08
1 BIRT
2 DATE 14 JAN 2011
2 _FNA NO
2 NOTE JUMELLE
1 FAMC @3265@
0 @3306@ INDI
1 NAME Auguste/LELEU/
2 GIVN Auguste
2 SURN LELEU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 19:24
1 BIRT
2 DATE 21 APR 2015
2 PLAC Pau,64000,Pyrénées-Atlantiques,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N43.300000
4 LONG W0.366670
2 _FNA NO
1 FAMC @3265@
0 @3316@ INDI
1 NAME Etienne/CHASSAING/
2 GIVN Etienne
2 SURN CHASSAING
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 11:56
1 FAMS @3320@
1 BIRT
2 DATE 6 NOV 1964
2 _FNA NO
0 @3322@ INDI
1 NAME Élie/CHASSAING/
2 GIVN Élie
2 SURN CHASSAING
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 11:55
1 BIRT
2 DATE 10 APR 2006
2 _FNA NO
1 FAMC @3320@
0 @3329@ INDI
1 NAME Valentin/CHASSAING/
2 GIVN Valentin
2 SURN CHASSAING
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 11:55
1 BIRT
2 DATE 5 JUL 2007
2 _FNA NO
1 FAMC @3320@
0 @3337@ INDI
1 NAME Adèle/CHASSAING/
2 GIVN Adèle
2 SURN CHASSAING
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 11:56
1 BIRT
2 DATE 12 JUN 2010
2 _FNA NO
1 FAMC @3320@
0 @3345@ INDI
1 NAME Anatole/CHASSAING/
2 GIVN Anatole
2 SURN CHASSAING
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 11:56
1 BIRT
2 DATE 4 JAN 2012
2 _FNA NO
1 FAMC @3320@
0 @3354@ INDI
1 NAME Qun/WANG/
2 GIVN Qun
2 SURN WANG
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 11:58
1 FAMS @3358@
1 BIRT
2 DATE 22 OCT 1968
2 _FNA NO
0 @3361@ INDI
1 NAME Jean Xian/SEYDOUX/
2 GIVN Jean Xian
2 SURN SEYDOUX
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 FEB 2018
3 TIME 11:58
1 BIRT
2 DATE 21 JAN 1999
2 _FNA NO
1 FAMC @3358@
0 @3376@ INDI
1 NAME Florence-Anne/AMBROSELLI/
2 GIVN Florence-Anne
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 19:08
1 FAMS @3637@
1 BIRT
2 DATE 8 MAR 1976
2 PLAC Strasbourg,67000,Bas-Rhin,Grand Est,FRANCE,
3 MAP
4 LATI N48.583420
4 LONG E7.742960
2 _FNA NO
1 FAMC @1038@
0 @3387@ INDI
1 NAME Marie-Marthe/AMBROSELLI/
2 GIVN Marie-Marthe
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2018
3 TIME 9:14
1 BIRT
2 DATE 22 JAN 1977
2 _FNA NO
1 DEAT
2 DATE 22 JAN 1977
2 _FNA NO
1 FAMC @1038@
0 @3399@ INDI
1 NAME Edouard/AMBROSELLI/
2 GIVN Edouard
2 SURN AMBROSELLI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 JUL 2019
3 TIME 10:57
1 FAMS @3621@
1 BIRT
2 DATE 16 JUN 1978
2 _FNA NO
2 NOTE Edouard et Marie DUPRE faisaient salon musical et littéraire avec les illustres artistes parisiens musiciens de la fin du 19°siècle que sa fonction de directeur de l'agence générale des théâtres parisiens lui permettait de rencontrer quotidiennemen
3 CONC t.
3 CONT
1 FAMC @1038@
0 @3409@ INDI
1 NAME Benoit Matthias/AMBROSELLI/
2 GIVN Benoit Matthias
2 SURN AMBROSELLI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 JUL 2019
3 TIME 10:56
1 FAMS @3581@
1 BIRT
2 DATE 25 JUL 1980
2 _FNA NO
1 FAMC @1038@
0 @3422@ INDI
1 NAME Jacinthe/AMBROSELLI/
2 GIVN Jacinthe
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2019
3 TIME 15:10
1 FAMS @3541@
1 BIRT
2 DATE 4 SEP 1981
2 _FNA NO
1 FAMC @1038@
0 @3432@ INDI
1 NAME Marie-Aimée/AMBROSELLI/
2 GIVN Marie-Aimée
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 FEB 2018
3 TIME 20:03
1 FAMS @3513@
1 BIRT
2 DATE 14 MAY 1983
2 _FNA NO
1 FAMC @1038@
0 @3442@ INDI
1 NAME Myriam/AMBROSELLI/
2 GIVN Myriam
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 SEP 2019
3 TIME 18:49
1 FAMS @3489@
1 BIRT
2 DATE 11 SEP 1986
2 _FNA NO
1 FAMC @1038@
0 @3452@ INDI
1 NAME Maximilien/AMBROSELLI/
2 GIVN Maximilien
2 SURN AMBROSELLI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 FEB 2018
3 TIME 19:57
1 FAMS @3483@
1 BIRT
2 DATE 9 OCT 1988
2 _FNA NO
1 FAMC @1038@
0 @3461@ INDI
1 NAME Matthias/AMBROSELLI/
2 GIVN Matthias
2 SURN AMBROSELLI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 FEB 2018
3 TIME 7:34
1 BIRT
2 DATE 20 MAY 1991
2 _FNA NO
1 FAMC @1038@
0 @3472@ INDI
1 NAME François-Joseph/AMBROSELLI/
2 GIVN François-Joseph
2 SURN AMBROSELLI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 19:19
1 BIRT
2 DATE 21 JUL 1994
2 _FNA NO
1 FAMC @1038@
0 @3482@ INDI
1 NAME Marie-Anne/ALEXANDRE/
2 GIVN Marie-Anne
2 SURN ALEXANDRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 FEB 2018
3 TIME 19:57
1 FAMS @3483@
0 @3487@ INDI
1 NAME Misael/CISNEROS-GARNICA/
2 GIVN Misael
2 SURN CISNEROS-GARNICA
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 SEP 2019
3 TIME 18:49
1 FAMS @3489@
0 @3494@ INDI
1 NAME Étienne/CISNEROS-GARNICA/
2 GIVN Étienne
2 SURN CISNEROS-GARNICA
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 FEB 2018
3 TIME 19:58
1 BIRT
2 DATE 10 JUL 2013
2 _FNA NO
1 FAMC @3489@
0 @3501@ INDI
1 NAME Inès/CISNEROS-GARNICA/
2 GIVN Inès
2 SURN CISNEROS-GARNICA
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 FEB 2018
3 TIME 19:59
1 BIRT
2 DATE 18 AUG 2015
2 _FNA NO
1 FAMC @3489@
0 @3511@ INDI
1 NAME Jérôme/DUPHIL/
2 GIVN Jérôme
2 SURN DUPHIL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 FEB 2018
3 TIME 20:03
1 FAMS @3513@
0 @3515@ INDI
1 NAME Louis/DUPHIL/
2 GIVN Louis
2 SURN DUPHIL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 FEB 2018
3 TIME 20:02
1 BIRT
2 DATE 30 AUG 2011
2 _FNA NO
1 FAMC @3513@
0 @3522@ INDI
1 NAME Matthieu/DUPHIL/
2 GIVN Matthieu
2 SURN DUPHIL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 FEB 2018
3 TIME 20:03
1 DEAT
2 DATE 8 SEP 2012
2 _FNA NO
1 FAMC @3513@
0 @3529@ INDI
1 NAME Joseph/DUPHIL/
2 GIVN Joseph
2 SURN DUPHIL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 FEB 2018
3 TIME 20:03
1 BIRT
2 DATE JUN 2014
2 _FNA NO
1 FAMC @3513@
0 @3537@ INDI
1 NAME Élie/de FROIDEFOND des FARGES/
2 GIVN Élie
2 SURN de FROIDEFOND des FARGES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2019
3 TIME 15:10
1 FAMS @3541@
1 BIRT
2 DATE 17 DEC 1978
2 _FNA NO
0 @3543@ INDI
1 NAME Syméon/de FROIDEFOND des FARGES/
2 GIVN Syméon
2 SURN de FROIDEFOND des FARGES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 FEB 2018
3 TIME 20:06
1 BIRT
2 DATE 22 JUL 2004
2 _FNA NO
1 FAMC @3541@
0 @3551@ INDI
1 NAME Jean-Bosco/de FROIDEFOND des FARGES/
2 GIVN Jean-Bosco
2 SURN de FROIDEFOND des FARGES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 JUL 2019
3 TIME 8:06
1 BIRT
2 DATE 1 AUG 2006
2 _FNA NO
1 FAMC @3541@
0 @3559@ INDI
1 NAME Philomène/de FROIDEFOND des FARGES/
2 GIVN Philomène
2 SURN de FROIDEFOND des FARGES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 FEB 2018
3 TIME 20:07
1 BIRT
2 DATE 18 APR 2009
2 _FNA NO
1 FAMC @3541@
0 @3567@ INDI
1 NAME Gaspard/de FROIDEFOND des FARGES/
2 GIVN Gaspard
2 SURN de FROIDEFOND des FARGES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 FEB 2018
3 TIME 20:07
1 BIRT
2 DATE DEC 2011
2 _FNA NO
1 FAMC @3541@
0 @3579@ INDI
1 NAME Marie-Laure/COIGNET/
2 GIVN Marie-Laure
2 SURN COIGNET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 JUL 2019
3 TIME 10:57
1 FAMS @3581@
0 @3583@ INDI
1 NAME Augustin/AMBROSELLI/
2 GIVN Augustin
2 SURN AMBROSELLI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 FEB 2018
3 TIME 20:09
1 BIRT
2 DATE 10 NOV 2007
2 _FNA NO
1 FAMC @3581@
0 @3592@ INDI
1 NAME Arthur/AMBROSELLI/
2 GIVN Arthur
2 SURN AMBROSELLI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 FEB 2018
3 TIME 20:10
1 BIRT
2 DATE 13 MAY 2009
2 _FNA NO
1 FAMC @3581@
0 @3600@ INDI
1 NAME Alexandre/AMBROSELLI/
2 GIVN Alexandre
2 SURN AMBROSELLI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 FEB 2018
3 TIME 20:10
1 BIRT
2 DATE SEP 2011
2 _FNA NO
1 FAMC @3581@
0 @3609@ INDI
1 NAME Pia/AMBROSELLI/
2 GIVN Pia
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 FEB 2018
3 TIME 20:12
1 BIRT
2 DATE 2 MAY 2015
2 _FNA NO
1 FAMC @3581@
0 @3619@ INDI
1 NAME Joséphine/BRAULT/
2 GIVN Joséphine
2 SURN BRAULT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 JUL 2019
3 TIME 10:57
1 FAMS @3621@
0 @3624@ INDI
1 NAME Alma/AMBROSELLI/
2 GIVN Alma
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:14
1 BIRT
2 DATE 21 JUL 2014
2 _FNA NO
1 FAMC @3621@
0 @3633@ INDI
1 NAME Samuel Bernard Louis/GUERIN/
2 GIVN Samuel Bernard Louis
2 SURN GUERIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 19:08
1 FAMS @3637@
1 BIRT
2 DATE 8 NOV 1976
2 _FNA NO
1 FAMC @23625@
0 @3640@ INDI
1 NAME Théophane/GUERIN/
2 GIVN Théophane
2 SURN GUERIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:20
1 BIRT
2 DATE 7 NOV 1998
2 _FNA NO
1 FAMC @3637@
0 @3648@ INDI
1 NAME Marie-Lys/GUERIN/
2 GIVN Marie-Lys
2 SURN GUERIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:20
1 BIRT
2 DATE 8 MAY 2000
2 _FNA NO
1 FAMC @3637@
0 @3656@ INDI
1 NAME Amaury/GUERIN/
2 GIVN Amaury
2 SURN GUERIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:21
1 BIRT
2 DATE 27 OCT 2001
2 _FNA NO
1 FAMC @3637@
0 @3664@ INDI
1 NAME Rose-Aimée/GUERIN/
2 GIVN Rose-Aimée
2 SURN GUERIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:24
1 BIRT
2 DATE 30 SEP 2003
2 _FNA NO
1 FAMC @3637@
0 @3673@ INDI
1 NAME Joseph Emmanuel/GUERIN/
2 GIVN Joseph Emmanuel
2 SURN GUERIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:24
1 BIRT
2 DATE 27 OCT 2005
2 _FNA NO
1 FAMC @3637@
0 @3684@ INDI
1 NAME Raphaël-Ange/GUERIN/
2 GIVN Raphaël-Ange
2 SURN GUERIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 14:11
1 BIRT
2 DATE 7 MAR 2007
2 _FNA NO
1 FAMC @3637@
0 @3691@ INDI
1 NAME Daniel/GUERIN/
2 GIVN Daniel
2 SURN GUERIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:27
1 BIRT
2 DATE 15 NOV 2006
2 _FNA NO
1 FAMC @3637@
0 @3699@ INDI
1 NAME Azélie/GUERIN/
2 GIVN Azélie
2 SURN GUERIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:27
1 BIRT
2 DATE 13 DEC 2010
2 _FNA NO
1 FAMC @3637@
0 @3706@ INDI
1 NAME Jean-Baptiste/GUERIN/
2 GIVN Jean-Baptiste
2 SURN GUERIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:27
1 BIRT
2 DATE 22 AUG 2012
2 _FNA NO
1 FAMC @3637@
0 @3717@ INDI
1 NAME Imelda/PREVOT/
2 GIVN Imelda
2 SURN PREVOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 8:02
1 FAMS @3921@
1 BIRT
2 DATE 23 JUL 1977
2 _FNA NO
1 FAMC @1060@
0 @3727@ INDI
1 NAME Marie-Laetitia/PREVOT/
2 GIVN Marie-Laetitia
2 SURN PREVOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 OCT 2018
3 TIME 7:51
1 BIRT
2 DATE 13 AUG 1978
2 _FNA NO
1 FAMC @1060@
0 @3739@ INDI
1 NAME Albéric/PREVOT/
2 GIVN Albéric
2 SURN PREVOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:37
1 BIRT
2 DATE 3 AUG 1980
2 _FNA NO
1 FAMC @1060@
0 @3751@ INDI
1 NAME Teresa/PREVOT/
2 GIVN Teresa
2 SURN PREVOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:55
1 FAMS @3893@
1 BIRT
2 DATE 20 SEP 1981
2 _FNA NO
1 FAMC @1060@
0 @3761@ INDI
1 NAME Angéla/PREVOT/
2 GIVN Angéla
2 SURN PREVOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 OCT 2018
3 TIME 7:55
1 FAMS @3870@
1 BIRT
2 DATE 7 MAR 1984
2 _FNA NO
1 FAMC @1060@
0 @3771@ INDI
1 NAME Amédée Joseph/PREVOT/
2 GIVN Amédée Joseph
2 SURN PREVOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 11:48
1 FAMS @3858@
1 BIRT
2 DATE 12 APR 1986
2 _FNA NO
1 FAMC @1060@
0 @3781@ INDI
1 NAME Anne-Odilia/PREVOT/
2 GIVN Anne-Odilia
2 SURN PREVOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:47
1 FAMS @3826@
1 BIRT
2 DATE 27 FEB 1988
2 _FNA NO
1 FAMC @1060@
0 @3791@ INDI
1 NAME Anastasia/PREVOT/
2 GIVN Anastasia
2 SURN PREVOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:42
1 BIRT
2 DATE 10 APR 1990
2 _FNA NO
1 FAMC @1060@
0 @3801@ INDI
1 NAME Emma/PREVOT/
2 GIVN Emma
2 SURN PREVOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:43
1 BIRT
2 DATE 20 OCT 1991
2 _FNA NO
1 DEAT
2 DATE 10 JAN 1992
2 _FNA NO
1 FAMC @1060@
0 @3814@ INDI
1 NAME Bonaventure/PREVOT/
2 GIVN Bonaventure
2 SURN PREVOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:43
1 BIRT
2 DATE 1 APR 1993
2 _FNA NO
1 FAMC @1060@
0 @3824@ INDI
1 NAME Benoît/AUTRIC/
2 GIVN Benoît
2 SURN AUTRIC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:47
1 FAMS @3826@
0 @3829@ INDI
1 NAME Jade/AUTRIC/
2 GIVN Jade
2 SURN AUTRIC
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:45
1 BIRT
2 DATE 15 JUN 2012
2 _FNA NO
1 FAMC @3826@
0 @3837@ INDI
1 NAME Annaëlle/AUTRIC/
2 GIVN Annaëlle
2 SURN AUTRIC
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:46
1 BIRT
2 DATE 2013
2 _FNA NO
1 FAMC @3826@
0 @3845@ INDI
1 NAME Zélie/AUTRIC/
2 GIVN Zélie
2 SURN AUTRIC
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:47
1 BIRT
2 DATE 9 DEC 2016
2 _FNA NO
1 FAMC @3826@
0 @3854@ INDI
1 NAME Laure/PUYMERAS/
2 GIVN Laure
2 SURN PUYMERAS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 11:48
1 FAMS @3858@
1 BIRT
2 DATE 15 OCT 1986
2 _FNA NO
0 @3859@ INDI
1 NAME Jeanne/PREVOT/
2 GIVN Jeanne
2 SURN PREVOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:50
1 BIRT
2 DATE 3 JUL 2017
2 _FNA NO
1 FAMC @3858@
0 @3868@ INDI
1 NAME Gabi/HANI/
2 GIVN Gabi
2 SURN HANI
1 SEX M
1 OCCU Restaurateur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 OCT 2018
3 TIME 7:55
1 FAMS @3870@
0 @3873@ INDI
1 NAME Kiriel/HANI/
2 GIVN Kiriel
2 SURN HANI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:52
1 BIRT
2 DATE 6 APR 2013
2 _FNA NO
1 FAMC @3870@
0 @3880@ INDI
1 NAME Théophane/HANI/
2 GIVN Théophane
2 SURN HANI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:53
1 BIRT
2 DATE 12 JUN 2014
2 _FNA NO
1 FAMC @3870@
0 @3889@ INDI
1 NAME Gilles/AGAR/
2 GIVN Gilles
2 SURN AGAR
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:55
1 FAMS @3893@
1 BIRT
2 DATE 22 AUG 1975
2 _FNA NO
0 @3894@ INDI
1 NAME François/AGAR/
2 GIVN François
2 SURN AGAR
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:54
1 BIRT
2 DATE 25 FEB 2004
2 _FNA NO
1 FAMC @3893@
0 @3902@ INDI
1 NAME Lydie/AGAR/
2 GIVN Lydie
2 SURN AGAR
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:55
1 BIRT
2 DATE APR 2009
2 _FNA NO
1 FAMC @3893@
0 @3909@ INDI
1 NAME Augustin/AGAR/
2 GIVN Augustin
2 SURN AGAR
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:55
1 BIRT
2 DATE 2011
2 _FNA NO
1 FAMC @3893@
0 @3917@ INDI
1 NAME Emmanuel/BAUDOIN/
2 GIVN Emmanuel
2 SURN BAUDOIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 8:02
1 FAMS @3921@
1 BIRT
2 DATE 29 DEC 1977
2 _FNA NO
0 @3924@ INDI
1 NAME Isayah/BAUDOIN/
2 GIVN Isayah
2 SURN BAUDOIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:58
1 BIRT
2 DATE 5 SEP 2004
2 _FNA NO
1 FAMC @3921@
0 @3932@ INDI
1 NAME Linaelle/BAUDOIN/
2 GIVN Linaelle
2 SURN BAUDOIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 7:59
1 BIRT
2 DATE 21 MAY 2007
2 _FNA NO
1 FAMC @3921@
0 @3940@ INDI
1 NAME Ezéchiel/BAUDOIN/
2 GIVN Ezéchiel
2 SURN BAUDOIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 8:00
1 BIRT
2 DATE APR 2010
2 _FNA NO
1 FAMC @3921@
0 @3949@ INDI
1 NAME Jérémie Abel/BAUDOIN/
2 GIVN Jérémie Abel
2 SURN BAUDOIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 19:37
1 BIRT
2 DATE 27 JUL 2012
2 _FNA NO
1 FAMC @3921@
0 @3960@ INDI
1 NAME Marguerite-Marie/DUMONT/
2 GIVN Marguerite-Marie
2 SURN DUMONT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 8:22
1 FAMS @4215@
1 BIRT
2 DATE 12 APR 1972
2 _FNA NO
1 FAMC @1046@
0 @3970@ INDI
1 NAME Pierre-Louis/DUMONT/
2 GIVN Pierre-Louis
2 SURN DUMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 21:52
1 FAMS @4175@
1 BIRT
2 DATE 26 JUL 1974
2 _FNA NO
1 FAMC @1046@
0 @3979@ INDI
1 NAME Joseph/DUMONT/
2 GIVN Joseph
2 SURN DUMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 20:02
1 FAMS @4155@
1 BIRT
2 DATE 15 JUN 1975
2 _FNA NO
1 FAMC @1046@
0 @3989@ INDI
1 NAME Jean-Eudes/DUMONT/
2 GIVN Jean-Eudes
2 SURN DUMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 19:14
1 BIRT
2 DATE 11 APR 1979
2 _FNA NO
1 FAMC @1046@
0 @3999@ INDI
1 NAME Ambroise/DUMONT/
2 GIVN Ambroise
2 SURN DUMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:30
1 FAMS @4135@
1 FAMS @33471@
1 BIRT
2 DATE 1 APR 1980
2 _FNA NO
1 FAMC @1046@
0 @4008@ INDI
1 NAME Jérôme/DUMONT/
2 GIVN Jérôme
2 SURN DUMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 19:55
1 FAMS @4121@
1 BIRT
2 DATE 25 SEP 1981
2 _FNA NO
1 FAMC @1046@
0 @4017@ INDI
1 NAME Charles/DUMONT/
2 GIVN Charles
2 SURN DUMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 19:50
1 FAMS @4102@
1 BIRT
2 DATE 26 JUN 1984
2 _FNA NO
1 FAMC @1046@
0 @4026@ INDI
1 NAME Jean-Marie/DUMONT/
2 GIVN Jean-Marie
2 SURN DUMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 19:48
1 FAMS @4081@
1 BIRT
2 DATE 14 NOV 1986
2 _FNA NO
1 FAMC @1046@
0 @4036@ INDI
1 NAME Marie-Sixtine/DUMONT/
2 GIVN Marie-Sixtine
2 SURN DUMONT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 13:06
1 BIRT
2 DATE 29 MAY 1988
2 _FNA NO
1 FAMC @1046@
0 @4046@ INDI
1 NAME Marc-Antoine/DUMONT/
2 GIVN Marc-Antoine
2 SURN DUMONT
2 NICK Marcus
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 APR 2019
3 TIME 16:54
1 FAMS @30837@
1 BIRT
2 DATE 26 JUL 1991
2 _FNA NO
1 FAMC @1046@
0 @4055@ INDI
1 NAME Augustin/DUMONT/
2 GIVN Augustin
2 SURN DUMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 13:07
1 BIRT
2 DATE 19 MAY 1993
2 _FNA NO
1 FAMC @1046@
0 @4065@ INDI
1 NAME Jean-Chrysostome/DUMONT/
2 GIVN Jean-Chrysostome
2 SURN DUMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 19:14
1 BIRT
2 DATE 9 APR 1997
2 _FNA NO
1 FAMC @1046@
0 @4077@ INDI
1 NAME Aurélie/MORIN/
2 GIVN Aurélie
2 SURN MORIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 19:48
1 FAMS @4081@
1 BIRT
2 DATE 1981
2 _FNA NO
0 @4083@ INDI
1 NAME Hortense/DUMONT/
2 GIVN Hortense
2 SURN DUMONT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 21:55
1 BIRT
2 DATE 17 NOV 2012
3 TIME 7:59
2 PLAC Mende,48000,Lozère,Occitanie,FRANCE,
3 MAP
4 LATI N44.516670
4 LONG E3.500000
2 _FNA NO
1 FAMC @4081@
0 @4091@ INDI
1 NAME Marceau/DUMONT/
2 GIVN Marceau
2 SURN DUMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 19:48
1 BIRT
2 DATE MAY 2015
2 _FNA NO
1 FAMC @4081@
0 @4098@ INDI
1 NAME Victoire/de BAYSER/
2 GIVN Victoire
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:32
1 FAMS @4102@
1 BIRT
2 DATE 1988
2 _FNA NO
1 FAMC @7895@
0 @4104@ INDI
1 NAME Alexandre/DUMONT/
2 GIVN Alexandre
2 SURN DUMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 19:50
1 BIRT
2 DATE JUN 2012
2 _FNA NO
1 FAMC @4102@
0 @4111@ INDI
1 NAME Romain/DUMONT/
2 GIVN Romain
2 SURN DUMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 19:50
1 BIRT
2 DATE 2015
2 _FNA NO
1 FAMC @4102@
0 @4119@ INDI
1 NAME Virginie/FAUGERAS/
2 GIVN Virginie
2 SURN FAUGERAS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 19:55
1 FAMS @4121@
0 @4123@ INDI
1 NAME Gabrielle/DUMONT/
2 GIVN Gabrielle
2 SURN DUMONT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 19:55
1 BIRT
2 DATE 1 MAY 2013
2 _FNA NO
1 FAMC @4121@
0 @4131@ INDI
1 NAME Lucie/de BAYSER/
2 GIVN Lucie
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:30
1 FAMS @4135@
1 BIRT
2 DATE 12 SEP 1980
2 _FNA NO
1 FAMC @7895@
0 @4138@ INDI
1 NAME Appoline/DUMONT/
2 GIVN Appoline
2 SURN DUMONT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 19:58
1 BIRT
2 DATE 26 APR 2003
2 _FNA NO
1 FAMC @4135@
0 @4145@ INDI
1 NAME Gustave/DUMONT/
2 GIVN Gustave
2 SURN DUMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 19:59
1 BIRT
2 DATE 2008
2 _FNA NO
1 FAMC @4135@
0 @4153@ INDI
1 NAME Karine/X/
2 GIVN Karine
2 SURN X
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 20:02
1 FAMS @4155@
0 @4156@ INDI
1 NAME Raphaël/DUMONT/
2 GIVN Raphaël
2 SURN DUMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 20:01
1 BIRT
2 DATE MAY 2014
2 _FNA NO
1 FAMC @4155@
0 @4163@ INDI
1 NAME Louis/DUMONT/
2 GIVN Louis
2 SURN DUMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 20:02
1 BIRT
2 DATE DEC 2016
2 _FNA NO
1 FAMC @4155@
0 @4171@ INDI
1 NAME Juliette/RAYNAUD/
2 GIVN Juliette
2 SURN RAYNAUD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 21:52
1 FAMS @4175@
1 BIRT
2 DATE 7 OCT 1976
2 _FNA NO
0 @4178@ INDI
1 NAME Octave/DUMONT/
2 GIVN Octave
2 SURN DUMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 20:04
1 BIRT
2 DATE 19 APR 2005
2 _FNA NO
1 FAMC @4175@
0 @4186@ INDI
1 NAME Aurèle/DUMONT/
2 GIVN Aurèle
2 SURN DUMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 20:05
1 BIRT
2 DATE 16 JUL 2007
2 _FNA NO
1 FAMC @4175@
0 @4193@ INDI
1 NAME Élise/DUMONT/
2 GIVN Élise
2 SURN DUMONT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 20:05
1 BIRT
2 DATE 2009
2 _FNA NO
1 FAMC @4175@
0 @4201@ INDI
1 NAME Isaure/DUMONT/
2 GIVN Isaure
2 SURN DUMONT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 20:06
1 BIRT
2 DATE 14 NOV 2012
2 _FNA NO
1 FAMC @4175@
0 @4209@ INDI
1 NAME Michel/METAIREAU/
2 GIVN Michel
2 SURN METAIREAU
1 SEX M
1 OCCU Artiste PEINTRE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 8:22
1 FAMS @4215@
1 BIRT
2 DATE 29 SEP 1955
2 _FNA NO
0 @4217@ INDI
1 NAME Maximilien/METAIREAU/
2 GIVN Maximilien
2 SURN METAIREAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 20:09
1 BIRT
2 DATE 2 JUL 1995
2 _FNA NO
1 FAMC @4215@
0 @4225@ INDI
1 NAME Barthélémy/METAIREAU/
2 GIVN Barthélémy
2 SURN METAIREAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 20:10
1 BIRT
2 DATE 24 AUG 1996
2 _FNA NO
1 FAMC @4215@
0 @4232@ INDI
1 NAME Gaspard/METAIREAU/
2 GIVN Gaspard
2 SURN METAIREAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 20:10
1 BIRT
2 DATE 17 AUG 2000
2 _FNA NO
1 FAMC @4215@
0 @4239@ INDI
1 NAME Augustin Barthélémy Gaspard/METAIREAU/
2 GIVN Augustin Barthélémy Gaspard
2 SURN METAIREAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 21:56
1 BIRT
2 DATE 3 AUG 2002
2 PLAC Hyères,83400,Var,Provence-Alpes-Côte d'Azur,FRANCE,
3 MAP
4 LATI N43.119540
4 LONG E6.138970
2 _FNA NO
1 FAMC @4215@
0 @4246@ INDI
1 NAME Élie/METAIREAU/
2 GIVN Élie
2 SURN METAIREAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 FEB 2018
3 TIME 20:13
1 BIRT
2 DATE 13 JUN 2011
2 _FNA NO
1 FAMC @4215@
0 @4254@ INDI
1 NAME Céleste Rose Marie Olivia/METAIREAU/
2 GIVN Céleste Rose Marie Olivia
2 SURN METAIREAU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 21:57
1 BIRT
2 DATE 13 FEB 2014
2 PLAC Manosque,04100,Alpes-de-Haute-Provence,Provence-Alpes-Côte d'Azur,FRANCE,
3 MAP
4 LATI N43.828830
4 LONG E5.786880
2 _FNA NO
1 FAMC @4215@
0 @4263@ INDI
1 NAME Giovanni Emilio/ACCURSI/
2 GIVN Giovanni Emilio
2 SURN ACCURSI
1 SEX M
1 OCCU Éditeur de Musique
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 NOV 2019
3 TIME 9:31
1 FAMS @4264@
1 BIRT
2 DATE 1867
2 _FNA NO
1 DEAT
2 DATE 2 MAY 1893
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
2 CAUS FIEVRE TYPHOIDE
1 NOTE ACCURSI  et  AMBROSELLI  étaient 2 amis
2 CONT 		Profession Selon acte de naissance de Simonne, il exerçait comme employé de librairie. En 1891, Paris, Île-de-France Profession Selon son acte de décès, il exerçait comme éditeur de musique. En 1893, Paris, Île-de-France
1 FAMC @16548@
0 @4266@ INDI
1 NAME Simone Emilienne Hélène/ACCURSI/
2 GIVN Simone Emilienne Hélène
2 SURN ACCURSI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 AUG 2019
3 TIME 7:43
1 FAMS @6993@
1 BIRT
2 DATE 10 FEB 1891
2 PLAC Paris 09,,,,,19 RUE DE CLICHY
2 _FNA NO
1 DEAT
2 DATE 8 DEC 1966
2 PLAC Paris 17,,,,,38 BD DE COURCELLES
2 _FNA NO
1 BURI
2 DATE 12 DEC 1966
2 PLAC Paris 17,,,,,SAINT CHARLES DE MONCEAU
2 _FNA NO
1 FAMC @4264@
0 @4272@ INDI
1 NAME Daniel Louis/ACCURSI/
2 GIVN Daniel Louis
2 SURN ACCURSI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 DEC 2018
3 TIME 22:07
1 FAMS @7007@
1 BIRT
2 DATE 13 APR 1893
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 1 SEP 1986
2 PLAC Clichy,92110,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.900180
4 LONG E2.309520
2 _FNA NO
1 FAMC @4264@
0 @4279@ INDI
1 NAME Chantal Marie Madeleine/MISSOFFE/
2 GIVN Chantal Marie Madeleine
2 SURN MISSOFFE
1 SEX F
1 OCCU avocate
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:03
1 FAMS @4719@
1 BIRT
2 DATE 5 OCT 1950
2 PLAC Moyeuvre-Grande,57250,Moselle,Grand Est,FRANCE,
3 MAP
4 LATI N49.251650
4 LONG E6.047540
2 _FNA NO
1 FAMC @1138@
0 @4292@ INDI
1 NAME Patricia Marie Colette/MISSOFFE/
2 GIVN Patricia Marie Colette
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 11:12
1 FAMS @4626@
1 BIRT
2 DATE 20 OCT 1952
2 PLAC Paris 17,,,,,
2 _FNA NO
1 FAMC @1138@
0 @4305@ INDI
1 NAME Olivier/MISSOFFE/
2 GIVN Olivier
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:02
1 FAMS @4529@
1 FAMS @4571@
1 BIRT
2 DATE 24 JUL 1956
2 PLAC Paris 17,,,,,
2 _FNA NO
1 FAMC @1138@
0 @4317@ INDI
1 NAME Isabelle Marie/MISSOFFE/
2 GIVN Isabelle Marie
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 11:16
1 FAMS @4433@
1 BIRT
2 DATE 6 JUN 1959
2 PLAC Neuilly-sur-Seine,92200,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883330
4 LONG E2.266670
2 _FNA NO
1 FAMC @1138@
0 @4330@ INDI
1 NAME Brigitte Patricia Jeanne/MISSOFFE/
2 GIVN Brigitte Patricia Jeanne
2 SURN MISSOFFE
1 SEX F
1 OCCU Coach
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 11:18
1 FAMS @4397@
1 BIRT
2 DATE 2 JAN 1963
2 PLAC Paris 17,,,,,
2 _FNA NO
1 FAMC @1138@
0 @4343@ INDI
1 NAME Alain Olivier Marie/MISSOFFE/
2 GIVN Alain Olivier Marie
2 SURN MISSOFFE
1 SEX M
1 OCCU Chef d'entreprise
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 10:00
1 FAMS @4360@
1 BIRT
2 DATE 6 OCT 1966
2 PLAC Paris 17,,,,,
2 _FNA NO
1 FAMC @1138@
0 @4356@ INDI
1 NAME Joséphine/SEILLIERE de LABORDE/
2 GIVN Joséphine
2 SURN SEILLIERE de LABORDE
1 SEX F
1 OCCU Infirmière PUERICULTRICE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 10:00
1 FAMS @4360@
1 BIRT
2 DATE 24 SEP 1973
2 _FNA NO
1 FAMC @14330@
0 @4362@ INDI
1 NAME François/MISSOFFE/
2 GIVN François
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 FEB 2018
3 TIME 18:37
1 BIRT
2 DATE 2 MAY 1998
2 _FNA NO
1 FAMC @4360@
0 @4369@ INDI
1 NAME Mathilde/MISSOFFE/
2 GIVN Mathilde
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 FEB 2018
3 TIME 18:37
1 BIRT
2 DATE 14 OCT 1999
2 _FNA NO
1 FAMC @4360@
0 @4377@ INDI
1 NAME Edmond/MISSOFFE/
2 GIVN Edmond
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 FEB 2018
3 TIME 18:38
1 BIRT
2 DATE 30 DEC 2002
2 _FNA NO
1 FAMC @4360@
0 @4384@ INDI
1 NAME Henri/MISSOFFE/
2 GIVN Henri
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 FEB 2018
3 TIME 18:39
1 BIRT
2 DATE 11 JUL 2007
2 _FNA NO
1 FAMC @4360@
0 @4393@ INDI
1 NAME Armand/de VILLOUTREYS de BRIGNAC/
2 GIVN Armand
2 SURN de VILLOUTREYS de BRIGNAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 MAY 2018
3 TIME 20:35
1 FAMS @4397@
1 BIRT
2 DATE 25 APR 1960
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @19607@
0 @4400@ INDI
1 NAME Adrien/de VILLOUTREYS de BRIGNAC/
2 GIVN Adrien
2 SURN de VILLOUTREYS de BRIGNAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2019
3 TIME 10:29
1 BIRT
2 DATE 15 APR 1988
2 PLAC Paris 01,,,,,
2 _FNA NO
1 FAMC @4397@
0 @4407@ INDI
1 NAME Marine/de VILLOUTREYS de BRIGNAC/
2 GIVN Marine
2 SURN de VILLOUTREYS de BRIGNAC
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2019
3 TIME 10:29
1 BIRT
2 DATE 6 APR 1990
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @4397@
0 @4414@ INDI
1 NAME Cédric/de VILLOUTREYS de BRIGNAC/
2 GIVN Cédric
2 SURN de VILLOUTREYS de BRIGNAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2019
3 TIME 10:29
1 BIRT
2 DATE 8 APR 1993
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @4397@
0 @4421@ INDI
1 NAME Camille/de VILLOUTREYS de BRIGNAC/
2 GIVN Camille
2 SURN de VILLOUTREYS de BRIGNAC
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2019
3 TIME 10:29
1 BIRT
2 DATE 13 MAY 1996
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @4397@
0 @4429@ INDI
1 NAME Dominique Vital Marcel/CHAVANNE/
2 GIVN Dominique Vital Marcel
2 SURN CHAVANNE
1 SEX M
1 OCCU Architecte
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 MAY 2018
3 TIME 20:27
1 FAMS @4433@
1 BIRT
2 DATE 20 JUN 1956
2 PLAC Paris 15,,,,,
2 _FNA NO
1 FAMC @19578@
0 @4435@ INDI
1 NAME Mathieu/CHAVANNE/
2 GIVN Mathieu
2 SURN CHAVANNE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 FEB 2018
3 TIME 19:02
1 FAMS @4502@
1 BIRT
2 DATE 20 MAR 1983
2 _FNA NO
1 FAMC @4433@
0 @4443@ INDI
1 NAME Lorraine/CHAVANNE/
2 GIVN Lorraine
2 SURN CHAVANNE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 13:03
1 FAMS @4480@
1 BIRT
2 DATE 8 MAY 1985
2 _FNA NO
1 FAMC @4433@
0 @4451@ INDI
1 NAME Ghislain/CHAVANNE/
2 GIVN Ghislain
2 SURN CHAVANNE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 14:02
1 FAMS @11334@
1 BIRT
2 DATE 9 NOV 1989
2 _FNA NO
1 FAMC @4433@
0 @4458@ INDI
1 NAME Laetitia Lorraine Isabelle/CHAVANNE/
2 GIVN Laetitia Lorraine Isabelle
2 SURN CHAVANNE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 11:24
1 BIRT
2 DATE 26 FEB 1993
2 PLAC Paris 15,,,,,
2 _FNA NO
1 FAMC @4433@
0 @4466@ INDI
1 NAME Alice Philippine Laetitia/CHAVANNE/
2 GIVN Alice Philippine Laetitia
2 SURN CHAVANNE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 11:25
1 BIRT
2 DATE 28 OCT 1998
2 PLAC Paris 15,,,,,
2 _FNA NO
1 FAMC @4433@
0 @4476@ INDI
1 NAME Thibaut/GRANIER/
2 GIVN Thibaut
2 SURN GRANIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 13:03
1 FAMS @4480@
1 BIRT
2 DATE 6 JUL 1982
2 _FNA NO
0 @4482@ INDI
1 NAME Marceau/GRANIER/
2 GIVN Marceau
2 SURN GRANIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 FEB 2018
3 TIME 18:58
1 BIRT
2 DATE 1 MAY 2013
2 _FNA NO
1 FAMC @4480@
0 @4490@ INDI
1 NAME Roxane/GRANIER/
2 GIVN Roxane
2 SURN GRANIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 11:07
1 BIRT
2 DATE 15 JUL 2015
2 PLAC Genève,1200,Genève,Région Lémanique,SUISSE,
3 MAP
4 LATI N46.202220
4 LONG E6.145690
2 _FNA NO
1 FAMC @4480@
0 @4498@ INDI
1 NAME Agathe/LIZEE/
2 GIVN Agathe
2 SURN LIZEE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 FEB 2018
3 TIME 19:02
1 FAMS @4502@
1 BIRT
2 DATE 29 JUN 1984
2 _FNA NO
0 @4506@ INDI
1 NAME Zacharie/CHAVANNE/
2 GIVN Zacharie
2 SURN CHAVANNE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 FEB 2018
3 TIME 19:01
1 BIRT
2 DATE 22 MAY 2015
2 _FNA NO
1 FAMC @4502@
0 @4514@ INDI
1 NAME Tigrane/CHAVANNE/
2 GIVN Tigrane
2 SURN CHAVANNE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 10:23
1 BIRT
2 DATE 27 FEB 2017
2 _FNA NO
1 FAMC @4502@
0 @4525@ INDI
1 NAME Ségolène Marie Claude/ALTMAYER/
2 GIVN Ségolène Marie Claude
2 SURN ALTMAYER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 JUL 2018
3 TIME 11:37
1 FAMS @4529@
1 BIRT
2 DATE 8 SEP 1957
2 _FNA NO
1 FAMC @19562@
0 @4531@ INDI
1 NAME Antoine/MISSOFFE/
2 GIVN Antoine
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 MAR 2018
3 TIME 22:00
1 FAMS @4586@
1 BIRT
2 DATE 10 FEB 1983
2 _FNA NO
1 FAMC @4529@
0 @4539@ INDI
1 NAME Olivia/MISSOFFE/
2 GIVN Olivia
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 12:57
1 FAMS @4603@
1 BIRT
2 DATE 20 APR 1985
2 _FNA NO
1 FAMC @4529@
0 @4547@ INDI
1 NAME Natacha/MISSOFFE/
2 GIVN Natacha
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 JUL 2018
3 TIME 11:37
1 FAMS @6887@
1 BIRT
2 DATE 12 DEC 1989
2 _FNA NO
1 FAMC @4529@
0 @4555@ INDI
1 NAME Flora/MISSOFFE/
2 GIVN Flora
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 FEB 2018
3 TIME 19:20
1 BIRT
2 DATE 27 JUL 1992
2 _FNA NO
1 FAMC @4529@
0 @4568@ INDI
1 NAME Audrey Myriam/DIALLO/
2 GIVN Audrey Myriam
2 SURN DIALLO
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:02
1 FAMS @4571@
0 @4574@ INDI
1 NAME Olympe/MISSOFFE/
2 GIVN Olympe
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 10:01
1 BIRT
2 DATE 14 AUG 2017
2 _FNA NO
1 FAMC @4571@
0 @4584@ INDI
1 NAME Delphine/CHEVREUX/
2 GIVN Delphine
2 SURN CHEVREUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 MAR 2018
3 TIME 22:00
1 FAMS @4586@
1 FAMC @11209@
0 @4589@ INDI
1 NAME Romane/MISSOFFE/
2 GIVN Romane
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 12:54
1 BIRT
2 DATE 5 FEB 2016
2 _FNA NO
1 FAMC @4586@
0 @4599@ INDI
1 NAME Pierre-Elie/de PIBRAC/
2 GIVN Pierre-Elie
2 SURN de PIBRAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 12:57
1 FAMS @4603@
1 BIRT
2 DATE 20 JUN 1983
2 _FNA NO
0 @4605@ INDI
1 NAME Alma/de PIBRAC/
2 GIVN Alma
2 SURN de PIBRAC
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 12:56
1 BIRT
2 DATE 13 JUN 2014
2 _FNA NO
1 FAMC @4603@
0 @4613@ INDI
1 NAME Hugo/de PIBRAC/
2 GIVN Hugo
2 SURN de PIBRAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 12:57
1 BIRT
2 DATE 14 MAR 2016
2 _FNA NO
1 FAMC @4603@
0 @4622@ INDI
1 NAME Hervé/LOUBERT/
2 GIVN Hervé
2 SURN LOUBERT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 13:02
1 FAMS @4626@
1 BIRT
2 DATE 8 OCT 1952
2 _FNA NO
0 @4632@ INDI
1 NAME Thomas/LOUBERT/
2 GIVN Thomas
2 SURN LOUBERT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 10:59
1 FAMS @4674@
1 BIRT
2 DATE 22 JAN 1981
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @4626@
0 @4641@ INDI
1 NAME Nathalie/LOUBERT/
2 GIVN Nathalie
2 SURN LOUBERT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 11:09
1 FAMS @4688@
1 BIRT
2 DATE 28 OCT 1982
2 PLAC Sao Paulo,,,,,
2 _FNA NO
1 FAMC @4626@
0 @4650@ INDI
1 NAME Florence/LOUBERT/
2 GIVN Florence
2 SURN LOUBERT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 MAY 2018
3 TIME 20:11
1 BIRT
2 DATE 3 DEC 1984
2 PLAC Besançon,25000,Doubs,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.248780
4 LONG E6.018150
2 _FNA NO
1 FAMC @4626@
0 @4659@ INDI
1 NAME Agathe/LOUBERT/
2 GIVN Agathe
2 SURN LOUBERT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 OCT 2018
3 TIME 8:21
1 FAMS @19768@
1 BIRT
2 DATE 8 JUL 1988
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @4626@
0 @4670@ INDI
1 NAME Manon/LALOGE/
2 GIVN Manon
2 SURN LALOGE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 10:59
1 FAMS @4674@
1 BIRT
2 DATE 28 AUG 1981
2 _FNA NO
0 @4676@ INDI
1 NAME Théo/LOUBERT-LALOGE/
2 GIVN Théo
2 SURN LOUBERT-LALOGE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 12:03
1 BIRT
2 DATE 24 NOV 2011
2 _FNA NO
1 FAMC @4674@
0 @4684@ INDI
1 NAME Nicolas/MORET/
2 GIVN Nicolas
2 SURN MORET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 11:09
1 FAMS @4688@
1 BIRT
2 DATE 12 MAR 1980
2 _FNA NO
0 @4690@ INDI
1 NAME Gabrielle/MORET-LOUBERT/
2 GIVN Gabrielle
2 SURN MORET-LOUBERT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 13:06
1 BIRT
2 DATE 23 MAR 2013
2 _FNA NO
1 FAMC @4688@
0 @4697@ INDI
1 NAME Laura/MORET-LOUBERT/
2 GIVN Laura
2 SURN MORET-LOUBERT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 13:07
1 BIRT
2 DATE 7 FEB 2015
2 _FNA NO
1 FAMC @4688@
0 @4713@ INDI
1 NAME Remi Christophe/DORVAL/
2 GIVN Remi Christophe
2 SURN DORVAL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:03
1 FAMS @4719@
1 BIRT
2 DATE 10 MAR 1951
2 _FNA NO
0 @4721@ INDI
1 NAME Pauline/DORVAL/
2 GIVN Pauline
2 SURN DORVAL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 18:43
1 FAMS @4769@
1 BIRT
2 DATE 10 MAY 1983
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @4719@
0 @4732@ INDI
1 NAME Guillaume/DORVAL/
2 GIVN Guillaume
2 SURN DORVAL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 NOV 2018
3 TIME 18:32
1 FAMS @10571@
1 BIRT
2 DATE 12 APR 1985
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @4719@
0 @4743@ INDI
1 NAME Renaud/DORVAL/
2 GIVN Renaud
2 SURN DORVAL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 MAY 2018
3 TIME 20:07
1 BIRT
2 DATE 3 MAR 1989
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @4719@
0 @4753@ INDI
1 NAME Édouard/DORVAL/
2 GIVN Édouard
2 SURN DORVAL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 MAY 2018
3 TIME 20:07
1 BIRT
2 DATE 18 AUG 1992
2 PLAC Rouen,76000,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.443130
4 LONG E1.099320
2 _FNA NO
1 FAMC @4719@
0 @4765@ INDI
1 NAME Cyrille/ESPRIT BOUHIER de L'ECLUSE/
2 GIVN Cyrille
2 SURN ESPRIT BOUHIER de L'ECLUSE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 19:55
1 FAMS @4769@
1 BIRT
2 DATE 25 MAR 1981
2 _FNA NO
1 FAMC @35467@
0 @4771@ INDI
1 NAME Arthur/ESPRIT BOUHIER de L'ECLUSE/
2 GIVN Arthur
2 SURN ESPRIT BOUHIER de L'ECLUSE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 18:43
1 BIRT
2 DATE 15 AUG 2013
2 PLAC Reims,51100,Marne,Grand Est,FRANCE,
3 MAP
4 LATI N49.250000
4 LONG E4.033330
2 _FNA NO
1 FAMC @4769@
0 @4778@ INDI
1 NAME Grégoire/ESPRIT BOUHIER de L'ECLUSE/
2 GIVN Grégoire
2 SURN ESPRIT BOUHIER de L'ECLUSE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 18:43
1 BIRT
2 DATE 10 JAN 2015
2 PLAC Reims,51100,Marne,Grand Est,FRANCE,
3 MAP
4 LATI N49.250000
4 LONG E4.033330
2 _FNA NO
1 FAMC @4769@
0 @4785@ INDI
1 NAME Paul/ESPRIT BOUHIER de L'ECLUSE/
2 GIVN Paul
2 SURN ESPRIT BOUHIER de L'ECLUSE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 18:43
1 BIRT
2 DATE 13 NOV 2017
2 PLAC Bordeaux,33000,Gironde,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N44.840440
4 LONG W0.580500
2 _FNA NO
1 FAMC @4769@
0 @4792@ INDI
1 NAME Jacques/MISSOFFE/
2 GIVN Jacques
2 SURN MISSOFFE
1 SEX M
1 OCCU AMIRAL
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 20 JUL 2019
3 TIME 13:36
1 FAMS @4812@
1 CHR
2 DATE 1893
2 _FNA NO
1 BIRT
2 DATE 26 MAY 1893
2 PLAC Brest,29200,Finistère,Bretagne,FRANCE,
3 MAP
4 LATI N48.400000
4 LONG W4.483330
2 _FNA NO
1 BURI
2 DATE 1982
2 PLAC Le Conquet,29217,Finistère,Bretagne,FRANCE,
3 MAP
4 LATI N48.360530
4 LONG W4.770860
2 _FNA NO
1 DEAT
2 DATE 26 JUN 1982
2 PLAC Paris 07,,,,,
2 _FNA NO
1 FAMC @6123@
0 @4805@ INDI
1 NAME Simone/LORENZ DIT TARDIEU/
2 GIVN Simone
2 SURN LORENZ DIT TARDIEU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 20 JUL 2019
3 TIME 13:36
1 FAMS @4812@
1 BIRT
2 DATE 16 NOV 1892
2 _FNA NO
1 DEAT
2 DATE 18 SEP 1967
2 _FNA NO
1 NOTE amie de Gilberete au cours de Mme CONTAL près du parc Monceau
1 FAMC @12427@
0 @4818@ INDI
1 NAME Jean-Pierre/MISSOFFE/
2 GIVN Jean-Pierre
2 SURN MISSOFFE
1 SEX M
1 OCCU ASSUREUR Artiste Peintre
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 18:47
1 FAMS @5728@
1 BIRT
2 DATE 11 FEB 1918
2 _FNA NO
1 DEAT
2 DATE 1 JAN 2010
2 _FNA NO
1 FAMC @4812@
0 @4829@ INDI
1 NAME Bernard/MISSOFFE/
2 GIVN Bernard
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 21:14
1 FAMS @5640@
1 BIRT
2 DATE 4 SEP 1921
2 _FNA NO
1 FAMC @4812@
0 @4836@ INDI
1 NAME Dominique/MISSOFFE/
2 GIVN Dominique
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 18:04
1 BIRT
2 DATE 6 APR 1923
2 PLAC Mayence,,,,ALLEMAGNE,
2 _FNA NO
1 DEAT
2 DATE 11 AUG 1944
2 PLAC Ancinnes,72610,Sarthe,Pays de la Loire,FRANCE,
3 MAP
4 LATI N48.367080
4 LONG E0.176700
2 _FNA NO
2 CAUS mort au combat
1 FAMC @4812@
0 @4847@ INDI
1 NAME Philippe/MISSOFFE/
2 GIVN Philippe
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JUN 2019
3 TIME 13:38
1 FAMS @5108@
1 BIRT
2 DATE 31 AUG 1925
2 _FNA NO
1 DEAT
2 DATE 28 SEP 1971
2 PLAC Garches,92380,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.842260
4 LONG E2.182320
2 _FNA NO
1 FAMC @4812@
0 @4857@ INDI
1 NAME Benoît/MISSOFFE/
2 GIVN Benoît
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 APR 2018
3 TIME 22:27
1 BIRT
2 DATE 11 JUN 1929
2 PLAC Brest,29200,Finistère,Bretagne,FRANCE,
3 MAP
4 LATI N48.400000
4 LONG W4.483330
2 _FNA NO
1 DEAT
2 DATE 18 NOV 1929
2 PLAC Brest,29200,Finistère,Bretagne,FRANCE,
3 MAP
4 LATI N48.400000
4 LONG W4.483330
2 _FNA NO
2 CAUS MENINGITE
1 FAMC @4812@
0 @4867@ INDI
1 NAME Michel/MISSOFFE/
2 GIVN Michel
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 10:43
1 FAMS @5009@
1 FAMS @13523@
1 BIRT
2 DATE 19 SEP 1930
2 _FNA NO
1 FAMC @4812@
0 @4874@ INDI
1 NAME Jacqueline/MISSOFFE/
2 GIVN Jacqueline
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 20 JUL 2019
3 TIME 13:35
1 FAMS @4893@
1 BIRT
2 DATE 24 JUN 1934
2 _FNA NO
1 DEAT
2 DATE 2016
2 _FNA NO
1 FAMC @4812@
0 @4886@ INDI
1 NAME Federico/CARACCIOLO DI SAN VITO/
2 GIVN Federico
2 SURN CARACCIOLO DI SAN VITO
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 9:15
1 FAMS @4893@
1 BIRT
2 DATE 13 JAN 1922
2 PLAC Naples,,,,,
2 _FNA NO
1 DEAT
2 DATE 17 JUN 1981
2 PLAC Turin,,,,,
2 _FNA NO
0 @4896@ INDI
1 NAME Caterina/CARACCIOLO DI SAN VITO/
2 GIVN Caterina
2 SURN CARACCIOLO DI SAN VITO
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 19:00
1 FAMS @4985@
1 BIRT
2 DATE 15 JUN 1971
2 _FNA NO
1 FAMC @4893@
0 @4904@ INDI
1 NAME Lorenza/CARACCIOLO DI SAN VITO/
2 GIVN Lorenza
2 SURN CARACCIOLO DI SAN VITO
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 10:31
1 FAMS @4956@
1 FAMS @4970@
1 BIRT
2 DATE 8 AUG 1963
2 _FNA NO
1 FAMC @4893@
0 @4912@ INDI
1 NAME Nicola/CARACCIOLO DI SAN VITO/
2 GIVN Nicola
2 SURN CARACCIOLO DI SAN VITO
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 18:47
1 BIRT
2 DATE 1 JUL 1962
2 _FNA NO
1 FAMC @4893@
0 @4920@ INDI
1 NAME Emmanuele/CARACCIOLO DI SAN VITO/
2 GIVN Emmanuele
2 SURN CARACCIOLO DI SAN VITO
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 10:45
1 FAMS @4933@
1 FAMS @4941@
1 BIRT
2 DATE 16 SEP 1960
2 _FNA NO
1 FAMC @4893@
0 @4929@ INDI
1 NAME Sabina/CREPAZ/
2 GIVN Sabina
2 SURN CREPAZ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 18:49
1 FAMS @4933@
1 BIRT
2 DATE 26 MAY 1965
2 _FNA NO
0 @4937@ INDI
1 NAME Alessia/GIUSTI/
2 GIVN Alessia
2 SURN GIUSTI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 10:45
1 FAMS @4941@
1 BIRT
2 DATE 1 JUL 1967
2 _FNA NO
0 @4943@ INDI
1 NAME Ascanio/CARACCIOLO DI SAN VITO/
2 GIVN Ascanio
2 SURN CARACCIOLO DI SAN VITO
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 10:46
1 BIRT
2 DATE 22 DEC 2007
2 _FNA NO
1 FAMC @4941@
0 @4952@ INDI
1 NAME Stefano/FREGA/
2 GIVN Stefano
2 SURN FREGA
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 9:37
1 FAMS @4956@
1 BIRT
2 DATE 17 JAN 1958
2 _FNA NO
0 @4958@ INDI
1 NAME Giacomo/FREGA/
2 GIVN Giacomo
2 SURN FREGA
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 18:54
1 BIRT
2 DATE 26 APR 1987
2 _FNA NO
1 FAMC @4956@
0 @4966@ INDI
1 NAME Nicola/ALBERTI/
2 GIVN Nicola
2 SURN ALBERTI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 10:31
1 FAMS @4970@
1 BIRT
2 DATE 15 APR 1962
2 _FNA NO
0 @4972@ INDI
1 NAME Giordano/ALBERTI/
2 GIVN Giordano
2 SURN ALBERTI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 18:55
1 BIRT
2 DATE 28 MAR 2004
2 _FNA NO
1 FAMC @4970@
0 @4981@ INDI
1 NAME Andréa/PIEGAIA/
2 GIVN Andréa
2 SURN PIEGAIA
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 19:00
1 FAMS @4985@
1 BIRT
2 DATE 10 NOV 1957
2 _FNA NO
0 @4988@ INDI
1 NAME Domenico/PIEGAIA/
2 GIVN Domenico
2 SURN PIEGAIA
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 19:00
1 BIRT
2 DATE 12 MAR 2000
2 _FNA NO
1 FAMC @4985@
0 @4996@ INDI
1 NAME Mafalda/PIEGAIA/
2 GIVN Mafalda
2 SURN PIEGAIA
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 19:00
1 BIRT
2 DATE 15 DEC 2002
2 _FNA NO
1 FAMC @4985@
0 @5005@ INDI
1 NAME Jacqueline/PICTET/
2 GIVN Jacqueline
2 SURN PICTET
1 SEX F
1 OCCU Journaliste
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 10:43
1 FAMS @5009@
1 BIRT
2 DATE 31 JAN 1948
2 _FNA NO
1 FAMC @11863@
0 @5011@ INDI
1 NAME Sébastien/MISSOFFE/
2 GIVN Sébastien
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 10:42
1 FAMS @5082@
1 BIRT
2 DATE 8 JAN 1974
2 _FNA NO
1 FAMC @5009@
0 @5018@ INDI
1 NAME Alexandre/MISSOFFE/
2 GIVN Alexandre
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 10:43
1 FAMS @5057@
1 BIRT
2 DATE 5 AUG 1975
2 _FNA NO
1 FAMC @5009@
0 @5024@ INDI
1 NAME Aurélia/MISSOFFE/
2 GIVN Aurélia
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 10:42
1 FAMS @5035@
1 BIRT
2 DATE 17 APR 1979
2 _FNA NO
1 FAMC @5009@
0 @5031@ INDI
1 NAME Antoine/DENIS/
2 GIVN Antoine
2 SURN DENIS
1 SEX M
1 OCCU producteur cinéma
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 20:25
1 FAMS @5035@
1 BIRT
2 DATE 13 JAN 1976
2 _FNA NO
0 @5038@ INDI
1 NAME Saskia/DENIS/
2 GIVN Saskia
2 SURN DENIS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 19:44
1 BIRT
2 DATE 1 APR 2009
2 _FNA NO
1 FAMC @5035@
0 @5045@ INDI
1 NAME Gaspard/DENIS/
2 GIVN Gaspard
2 SURN DENIS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 19:45
1 BIRT
2 DATE 1 JAN 2013
2 _FNA NO
1 FAMC @5035@
0 @5053@ INDI
1 NAME Marianne/DESSERIERES/
2 GIVN Marianne
2 SURN DESSERIERES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 19:47
1 FAMS @5057@
1 BIRT
2 DATE 2 AUG 1978
2 _FNA NO
0 @5060@ INDI
1 NAME Sacha/MISSOFFE/
2 GIVN Sacha
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 19:47
1 BIRT
2 DATE 9 NOV 2007
2 _FNA NO
1 FAMC @5057@
0 @5068@ INDI
1 NAME Tristan/MISSOFFE/
2 GIVN Tristan
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 19:47
1 BIRT
2 DATE 12 AUG 2010
2 _FNA NO
1 FAMC @5057@
0 @5078@ INDI
1 NAME Christina/VERVITSIOTI/
2 GIVN Christina
2 SURN VERVITSIOTI
1 SEX F
1 OCCU Photographe
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 20:35
1 FAMS @5082@
1 BIRT
2 DATE 29 SEP 1974
2 _FNA NO
0 @5085@ INDI
1 NAME Émilien/MISSOFFE/
2 GIVN Émilien
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 20:15
1 BIRT
2 DATE 6 MAY 2005
2 _FNA NO
1 FAMC @5082@
0 @5093@ INDI
1 NAME Alexis/MISSOFFE/
2 GIVN Alexis
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 20:16
1 BIRT
2 DATE 20 JUN 2007
2 _FNA NO
1 FAMC @5082@
0 @5101@ INDI
1 NAME Tatiana/MISSOFFE/
2 GIVN Tatiana
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 20:16
1 BIRT
2 DATE 12 MAY 2010
2 _FNA NO
1 FAMC @5082@
0 @5111@ INDI
1 NAME Véronique/MISSOFFE/
2 GIVN Véronique
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 20:21
1 FAMS @5510@
1 BIRT
2 DATE 16 FEB 1949
2 _FNA NO
1 FAMC @5108@
0 @5118@ INDI
1 NAME Jacques/MISSOFFE/
2 GIVN Jacques
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 11:35
1 FAMS @5457@
1 BIRT
2 DATE 3 JUN 1950
2 _FNA NO
1 FAMC @5108@
0 @5125@ INDI
1 NAME Sabine/MISSOFFE/
2 GIVN Sabine
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 9:58
1 FAMS @5286@
1 FAMS @5307@
1 FAMS @5318@
1 BIRT
2 DATE 30 AUG 1952
2 _FNA NO
1 FAMC @5108@
0 @5133@ INDI
1 NAME Jean-Philippe/MISSOFFE/
2 GIVN Jean-Philippe
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 20:41
1 FAMS @5214@
1 BIRT
2 DATE 10 DEC 1955
2 _FNA NO
1 FAMC @5108@
0 @5140@ INDI
1 NAME Annick/MISSOFFE/
2 GIVN Annick
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 20:23
1 BIRT
2 DATE 21 APR 1960
2 _FNA NO
1 DEAT
2 DATE 1 MAY 2013
2 _FNA NO
1 FAMC @5108@
0 @5150@ INDI
1 NAME Éric/MISSOFFE/
2 GIVN Éric
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 20:30
1 FAMS @5199@
1 BIRT
2 DATE 2 JUN 1961
2 _FNA NO
1 FAMC @5108@
0 @5158@ INDI
1 NAME Valérie/MISSOFFE/
2 GIVN Valérie
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 20:28
1 FAMS @5170@
1 BIRT
2 DATE 8 APR 1964
2 _FNA NO
1 FAMC @5108@
0 @5166@ INDI
1 NAME Olivier/DOUSSET/
2 GIVN Olivier
2 SURN DOUSSET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 20:43
1 FAMS @5170@
1 BIRT
2 DATE 19 MAR 1959
2 _FNA NO
0 @5172@ INDI
1 NAME Thibaut/DOUSSET/
2 GIVN Thibaut
2 SURN DOUSSET
1 SEX M
1 OCCU HEC
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 9:47
1 BIRT
2 DATE 19 AUG 1993
2 _FNA NO
1 FAMC @5170@
0 @5180@ INDI
1 NAME Ariane/DOUSSET/
2 GIVN Ariane
2 SURN DOUSSET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 20:28
1 BIRT
2 DATE 10 APR 1995
2 _FNA NO
1 FAMC @5170@
0 @5187@ INDI
1 NAME Clara/DOUSSET/
2 GIVN Clara
2 SURN DOUSSET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 20:28
1 BIRT
2 DATE 12 NOV 1998
2 _FNA NO
1 FAMC @5170@
0 @5195@ INDI
1 NAME Véronique/CLERO/
2 GIVN Véronique
2 SURN CLERO
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 20:30
1 FAMS @5199@
1 BIRT
2 DATE 3 SEP 1959
2 _FNA NO
0 @5201@ INDI
1 NAME Arthur/CLERO MISSOFFE/
2 GIVN Arthur
2 SURN CLERO MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 8:28
1 BIRT
2 DATE 23 DEC 1990
2 _FNA NO
1 FAMC @5199@
0 @5210@ INDI
1 NAME Aude/de LA BRUNIERE/
2 GIVN Aude
2 SURN de LA BRUNIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 20:33
1 FAMS @5214@
1 BIRT
2 DATE 23 JUN 1957
2 _FNA NO
0 @5216@ INDI
1 NAME Céline/MISSOFFE/
2 GIVN Céline
2 SURN MISSOFFE
1 SEX F
1 OCCU journaliste
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 10:55
1 FAMS @5264@
1 BIRT
2 DATE 9 NOV 1983
2 _FNA NO
1 FAMC @5214@
0 @5224@ INDI
1 NAME Albane/MISSOFFE/
2 GIVN Albane
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 10:53
1 FAMS @5243@
1 BIRT
2 DATE 13 JUN 1986
2 _FNA NO
1 FAMC @5214@
0 @5231@ INDI
1 NAME Grégoire/MISSOFFE/
2 GIVN Grégoire
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 12:59
1 FAMS @10850@
1 BIRT
2 DATE 10 JAN 1989
2 _FNA NO
1 FAMC @5214@
0 @5241@ INDI
1 NAME Antonin/HUBERT/
2 GIVN Antonin
2 SURN HUBERT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 10:53
1 FAMS @5243@
0 @5245@ INDI
1 NAME Léon/HUBERT/
2 GIVN Léon
2 SURN HUBERT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 20:35
1 BIRT
2 DATE 24 AUG 2009
2 _FNA NO
1 FAMC @5243@
0 @5253@ INDI
1 NAME Florine/HUBERT/
2 GIVN Florine
2 SURN HUBERT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 20:36
1 BIRT
2 DATE 9 AUG 2011
2 _FNA NO
1 FAMC @5243@
0 @5262@ INDI
1 NAME Sigor/IBANEZ/
2 GIVN Sigor
2 SURN IBANEZ
1 SEX M
1 OCCU journaliste
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 10:55
1 FAMS @5264@
0 @5266@ INDI
1 NAME Anouck/IBANEZ/
2 GIVN Anouck
2 SURN IBANEZ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 8:15
1 BIRT
2 DATE 24 DEC 2011
2 _FNA NO
1 FAMC @5264@
0 @5273@ INDI
1 NAME Camille/IBANEZ/
2 GIVN Camille
2 SURN IBANEZ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 8:15
1 BIRT
2 DATE 24 DEC 2011
2 _FNA NO
1 FAMC @5264@
0 @5282@ INDI
1 NAME Peter/BOND/
2 GIVN Peter
2 SURN BOND
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 20:43
1 FAMS @5286@
1 BIRT
2 DATE 3 APR 1952
2 _FNA NO
0 @5289@ INDI
1 NAME Vanessa/BOND/
2 GIVN Vanessa
2 SURN BOND
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 11:07
1 FAMS @5345@
1 BIRT
2 DATE 17 FEB 1980
2 _FNA NO
1 FAMC @5286@
0 @5296@ INDI
1 NAME Alice/BOND/
2 GIVN Alice
2 SURN BOND
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 12:50
1 FAMS @5330@
1 BIRT
2 DATE 27 JAN 1982
2 _FNA NO
1 FAMC @5286@
0 @5303@ INDI
1 NAME William/MISSOFFE/
2 GIVN William
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 20:44
1 BIRT
2 DATE 29 JUL 1985
2 _FNA NO
1 FAMC @5307@
0 @5311@ INDI
1 NAME Paul/GORTER/
2 GIVN Paul
2 SURN GORTER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 9:58
1 FAMS @5318@
1 BIRT
2 DATE 15 MAR 1959
2 _FNA NO
1 DEAT
2 DATE 10 NOV 2002
2 _FNA NO
0 @5320@ INDI
1 NAME James/GORTER MISSOFFE/
2 GIVN James
2 SURN GORTER MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 8:26
1 BIRT
2 DATE 26 JAN 1996
2 _FNA NO
1 FAMC @5318@
0 @5328@ INDI
1 NAME Thibaut/de SAINT SIMON/
2 GIVN Thibaut
2 SURN de SAINT SIMON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 12:50
1 FAMS @5330@
0 @5333@ INDI
1 NAME Charlie/de SAINT SIMON/
2 GIVN Charlie
2 SURN de SAINT SIMON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 FEB 2018
3 TIME 20:50
1 BIRT
2 DATE 25 FEB 2014
2 _FNA NO
1 FAMC @5330@
0 @5341@ INDI
1 NAME Grégoire/CARTILLIER/
2 GIVN Grégoire
2 SURN CARTILLIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 11:07
1 FAMS @5345@
1 BIRT
2 DATE 11 NOV 1974
2 _FNA NO
0 @5348@ INDI
1 NAME Salomé/CARTILLIER-BOND/
2 GIVN Salomé
2 SURN CARTILLIER-BOND
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 11:06
1 BIRT
2 DATE 15 JAN 2009
2 _FNA NO
1 FAMC @5345@
0 @5356@ INDI
1 NAME Gabin/CARTILLIER-BOND/
2 GIVN Gabin
2 SURN CARTILLIER-BOND
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 11:06
1 BIRT
2 DATE 15 JAN 2009
2 _FNA NO
1 FAMC @5345@
0 @5363@ INDI
1 NAME Zélie/CARTILLIER-BOND/
2 GIVN Zélie
2 SURN CARTILLIER-BOND
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 11:05
1 BIRT
2 DATE 7 JAN 2013
2 _FNA NO
1 FAMC @5345@
0 @5453@ INDI
1 NAME Caroline/CHATIN/
2 GIVN Caroline
2 SURN CHATIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 19:14
1 FAMS @5457@
1 BIRT
2 DATE 3 JAN 1958
2 _FNA NO
1 FAMC @34193@
0 @5459@ INDI
1 NAME Philippe/MISSOFFE/
2 GIVN Philippe
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 20:30
1 FAMS @5486@
1 BIRT
2 DATE 6 NOV 1980
2 _FNA NO
1 FAMC @5457@
0 @5466@ INDI
1 NAME Nicolas/MISSOFFE/
2 GIVN Nicolas
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 11:38
1 FAMS @5499@
1 BIRT
2 DATE 28 JUN 1982
2 _FNA NO
1 FAMC @5457@
0 @5473@ INDI
1 NAME Augustin/MISSOFFE/
2 GIVN Augustin
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 11:57
1 FAMS @34808@
1 BIRT
2 DATE 14 NOV 1985
2 _FNA NO
1 FAMC @5457@
0 @5482@ INDI
1 NAME Maylis/de LA JUGIE/
2 GIVN Maylis
2 SURN de LA JUGIE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 20:30
1 FAMS @5486@
1 BIRT
2 DATE 5 MAY 1984
2 _FNA NO
0 @5489@ INDI
1 NAME Alcibiade/MISSOFFE/
2 GIVN Alcibiade
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 11:36
1 BIRT
2 DATE 3 JUL 2012
2 _FNA NO
1 FAMC @5486@
0 @5497@ INDI
1 NAME Thomas/VIREY/
2 GIVN Thomas
2 SURN VIREY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 11:08
1 FAMS @5499@
1 BIRT
2 DATE 15 JUN 1984
2 _FNA NO
0 @5503@ INDI
1 NAME Robert/SAINT OLIVE/
2 GIVN Robert
2 SURN SAINT OLIVE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 20:06
1 FAMS @5510@
1 BIRT
2 DATE 14 NOV 1944
2 _FNA NO
1 DEAT
2 DATE 27 NOV 2009
2 _FNA NO
0 @5512@ INDI
1 NAME Isabelle/SAINT OLIVE/
2 GIVN Isabelle
2 SURN SAINT OLIVE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 11:59
1 FAMS @5612@
1 BIRT
2 DATE 6 SEP 1969
2 _FNA NO
1 FAMC @5510@
0 @5520@ INDI
1 NAME Stéphanie/SAINT OLIVE/
2 GIVN Stéphanie
2 SURN SAINT OLIVE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 11:55
1 FAMS @5583@
1 BIRT
2 DATE 26 JAN 1973
2 _FNA NO
1 FAMC @5510@
0 @5527@ INDI
1 NAME Antoine/SAINT OLIVE/
2 GIVN Antoine
2 SURN SAINT OLIVE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 11:50
1 FAMS @5546@
1 BIRT
2 DATE 30 SEP 1974
2 _FNA NO
1 FAMC @5510@
0 @5534@ INDI
1 NAME Mathieu/SAINT OLIVE/
2 GIVN Mathieu
2 SURN SAINT OLIVE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 13:07
1 FAMS @10862@
1 BIRT
2 DATE 31 AUG 1990
2 _FNA NO
1 FAMC @5510@
0 @5542@ INDI
1 NAME Florence/CUNY/
2 GIVN Florence
2 SURN CUNY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 11:50
1 FAMS @5546@
1 BIRT
2 DATE 7 JUN 1974
2 _FNA NO
0 @5548@ INDI
1 NAME Soline/SAINT OLIVE/
2 GIVN Soline
2 SURN SAINT OLIVE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 11:49
1 BIRT
2 DATE 6 SEP 2001
2 _FNA NO
1 FAMC @5546@
0 @5555@ INDI
1 NAME Emile/SAINT OLIVE/
2 GIVN Emile
2 SURN SAINT OLIVE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 11:49
1 BIRT
2 DATE 6 MAR 2004
2 _FNA NO
1 FAMC @5546@
0 @5562@ INDI
1 NAME Clémence/SAINT OLIVE/
2 GIVN Clémence
2 SURN SAINT OLIVE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 11:49
1 BIRT
2 DATE 24 NOV 2006
2 _FNA NO
1 FAMC @5546@
0 @5569@ INDI
1 NAME François/SAINT OLIVE/
2 GIVN François
2 SURN SAINT OLIVE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 11:50
1 BIRT
2 DATE 28 JAN 2009
2 _FNA NO
1 FAMC @5546@
0 @5579@ INDI
1 NAME Anthony/GUERARD/
2 GIVN Anthony
2 SURN GUERARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 11:55
1 FAMS @5583@
1 BIRT
2 DATE 8 JAN 1970
2 _FNA NO
0 @5585@ INDI
1 NAME Théo/GUERARD/
2 GIVN Théo
2 SURN GUERARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 10:05
1 BIRT
2 DATE 9 AUG 2001
2 _FNA NO
2 NOTE naissance très prématurée
1 FAMC @5583@
0 @5592@ INDI
1 NAME Juliette/GUERARD/
2 GIVN Juliette
2 SURN GUERARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 11:54
1 BIRT
2 DATE 13 FEB 2004
2 _FNA NO
1 FAMC @5583@
0 @5600@ INDI
1 NAME Jules/GUERARD/
2 GIVN Jules
2 SURN GUERARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 11:55
1 BIRT
2 DATE 22 MAY 2007
2 _FNA NO
1 FAMC @5583@
0 @5608@ INDI
1 NAME Alban/de LA SELLE/
2 GIVN Alban
2 SURN de LA SELLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 11:59
1 FAMS @5612@
1 BIRT
2 DATE 2 MAY 1966
2 _FNA NO
0 @5614@ INDI
1 NAME Manon/de LA SELLE/
2 GIVN Manon
2 SURN de LA SELLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 11:57
1 BIRT
2 DATE 27 SEP 1994
2 _FNA NO
1 FAMC @5612@
0 @5621@ INDI
1 NAME Tristan/de LA SELLE/
2 GIVN Tristan
2 SURN de LA SELLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 11:58
1 BIRT
2 DATE 17 MAR 1997
2 _FNA NO
1 FAMC @5612@
0 @5628@ INDI
1 NAME Brune/de LA SELLE/
2 GIVN Brune
2 SURN de LA SELLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 11:59
1 BIRT
2 DATE 13 DEC 2001
2 _FNA NO
1 FAMC @5612@
0 @5636@ INDI
1 NAME Claude/TRON/
2 GIVN Claude
2 SURN TRON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 20:36
1 FAMS @5640@
1 BIRT
2 DATE 15 NOV 1932
2 _FNA NO
1 FAMC @11984@
0 @5643@ INDI
1 NAME Jean-Benoit/MISSOFFE/
2 GIVN Jean-Benoit
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 20:11
1 FAMS @5700@
1 BIRT
2 DATE 30 JUL 1954
2 _FNA NO
1 FAMC @5640@
0 @5651@ INDI
1 NAME Sophie/MISSOFFE/
2 GIVN Sophie
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 21:15
1 FAMS @5670@
1 BIRT
2 DATE 17 FEB 1958
2 _FNA NO
1 FAMC @5640@
0 @5658@ INDI
1 NAME Emmanuel/MISSOFFE/
2 GIVN Emmanuel
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 12:05
1 BIRT
2 DATE 25 NOV 1969
2 _FNA NO
1 FAMC @5640@
0 @5666@ INDI
1 NAME Marc/LAMBRON/
2 GIVN Marc
2 SURN LAMBRON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 21:15
1 FAMS @5670@
1 BIRT
2 DATE 4 FEB 1957
2 _FNA NO
0 @5672@ INDI
1 NAME Mathieu/LAMBRON/
2 GIVN Mathieu
2 SURN LAMBRON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 20:38
1 FAMS @34283@
1 BIRT
2 DATE 30 SEP 1985
2 _FNA NO
1 FAMC @5670@
0 @5679@ INDI
1 NAME Juliette/LAMBRON/
2 GIVN Juliette
2 SURN LAMBRON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 12:09
1 BIRT
2 DATE 26 AUG 1988
2 _FNA NO
1 FAMC @5670@
0 @5686@ INDI
1 NAME Pauline/LAMBRON/
2 GIVN Pauline
2 SURN LAMBRON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 12:10
1 BIRT
2 DATE 5 APR 1993
2 _FNA NO
1 FAMC @5670@
0 @5696@ INDI
1 NAME Marie-Jeanne/MOREAU/
2 GIVN Marie-Jeanne
2 SURN MOREAU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 20:11
1 FAMS @5700@
1 BIRT
2 DATE 9 APR 1958
2 _FNA NO
0 @5702@ INDI
1 NAME Adrien/MISSOFFE/
2 GIVN Adrien
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 12:13
1 BIRT
2 DATE 11 SEP 1987
2 _FNA NO
1 FAMC @5700@
0 @5709@ INDI
1 NAME Laurent/MISSOFFE/
2 GIVN Laurent
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 12:13
1 BIRT
2 DATE 7 SEP 1990
2 _FNA NO
1 FAMC @5700@
0 @5716@ INDI
1 NAME Clara/MISSOFFE/
2 GIVN Clara
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 12:13
1 BIRT
2 DATE 24 SEP 1991
2 _FNA NO
1 FAMC @5700@
0 @5724@ INDI
1 NAME Anne/TYREL de POIX/
2 GIVN Anne
2 SURN TYREL de POIX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 8:34
1 FAMS @5728@
1 BIRT
2 DATE 6 MAY 1921
2 PLAC Rueil-Malmaison,92500,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.876500
4 LONG E2.189670
2 _FNA NO
1 DEAT
2 DATE 1988
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @12252@
0 @5731@ INDI
1 NAME Jean-Dominique/MISSOFFE/
2 GIVN Jean-Dominique
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 13:30
1 FAMS @5774@
1 FAMS @5810@
1 BIRT
2 DATE 31 DEC 1948
2 _FNA NO
1 FAMC @5728@
0 @5738@ INDI
1 NAME Marguerite/MISSOFFE/
2 GIVN Marguerite
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 9:39
1 FAMS @5876@
1 BIRT
2 DATE 5 APR 1950
2 _FNA NO
1 FAMC @5728@
0 @5745@ INDI
1 NAME Marie-Lys/MISSOFFE/
2 GIVN Marie-Lys
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 13:47
1 FAMS @5942@
1 BIRT
2 DATE 15 APR 1951
2 _FNA NO
1 FAMC @5728@
0 @5753@ INDI
1 NAME Christine/MISSOFFE/
2 GIVN Christine
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 20:28
1 FAMS @6036@
1 BIRT
2 DATE 11 MAR 1953
2 _FNA NO
1 FAMC @5728@
0 @5761@ INDI
1 NAME Noëlle/MISSOFFE/
2 GIVN Noëlle
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 9:13
1 FAMS @10890@
1 BIRT
2 DATE 31 DEC 1958
2 _FNA NO
1 FAMC @5728@
0 @5770@ INDI
1 NAME Élisabeth/GUIZARD/
2 GIVN Élisabeth
2 SURN GUIZARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 13:27
1 FAMS @5774@
1 BIRT
2 DATE 10 JUL 1952
2 _FNA NO
0 @5776@ INDI
1 NAME Alexis/MISSOFFE/
2 GIVN Alexis
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 19:43
1 FAMS @5796@
1 BIRT
2 DATE 17 APR 1976
2 _FNA NO
1 FAMC @5774@
0 @5783@ INDI
1 NAME Cédric/MISSOFFE/
2 GIVN Cédric
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 19:46
1 FAMS @5803@
1 BIRT
2 DATE 11 FEB 1978
2 _FNA NO
1 FAMC @5774@
0 @5792@ INDI
1 NAME Marie-Sophie/DONON/
2 GIVN Marie-Sophie
2 SURN DONON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 19:43
1 FAMS @5796@
1 BIRT
2 DATE 19 SEP 1977
2 _FNA NO
0 @5799@ INDI
1 NAME Anne/MECHIN/
2 GIVN Anne
2 SURN MECHIN
1 SEX F
1 OCCU Enseignante
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 19:46
1 FAMS @5803@
1 BIRT
2 DATE 9 MAR 1979
2 _FNA NO
0 @5806@ INDI
1 NAME Florence/MELLINI/
2 GIVN Florence
2 SURN MELLINI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 13:30
1 FAMS @5810@
1 BIRT
2 DATE 1 JUL 1954
2 _FNA NO
0 @5813@ INDI
1 NAME Quentin/MISSOFFE/
2 GIVN Quentin
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 19:41
1 BIRT
2 DATE 30 JUL 1988
2 _FNA NO
1 FAMC @5810@
0 @5820@ INDI
1 NAME Gaspard/MISSOFFE/
2 GIVN Gaspard
2 SURN MISSOFFE
1 SEX M
1 OCCU Officier de marine
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 19:42
1 BIRT
2 DATE 22 FEB 1990
2 _FNA NO
1 FAMC @5810@
0 @5828@ INDI
1 NAME Prune/MISSOFFE/
2 GIVN Prune
2 SURN MISSOFFE
1 SEX F
1 OCCU avocate
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 19:39
1 FAMS @34219@
1 BIRT
2 DATE 30 MAY 1991
2 _FNA NO
1 FAMC @5810@
0 @5835@ INDI
1 NAME Victoire/MISSOFFE/
2 GIVN Victoire
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 13:31
1 BIRT
2 DATE 14 JAN 2003
2 _FNA NO
1 FAMC @5796@
0 @5843@ INDI
1 NAME Bertille/MISSOFFE/
2 GIVN Bertille
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 13:32
1 BIRT
2 DATE 6 OCT 2004
2 _FNA NO
1 FAMC @5796@
0 @5850@ INDI
1 NAME Timothée/MISSOFFE/
2 GIVN Timothée
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 13:32
1 BIRT
2 DATE 4 AUG 2007
2 _FNA NO
1 FAMC @5796@
0 @5858@ INDI
1 NAME Amicie/MISSOFFE/
2 GIVN Amicie
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 10:58
1 BIRT
2 DATE 20 AUG 2011
2 _FNA NO
1 FAMC @5796@
0 @5866@ INDI
1 NAME Cyprien/MISSOFFE/
2 GIVN Cyprien
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 10:58
1 BIRT
2 DATE 20 AUG 2011
2 _FNA NO
1 FAMC @5796@
0 @5874@ INDI
1 NAME Hubert/ROLLINDE de BEAUMONT/
2 GIVN Hubert
2 SURN ROLLINDE de BEAUMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 9:39
1 FAMS @5876@
1 BIRT
2 DATE 22 SEP 1946
2 _FNA NO
0 @5879@ INDI
1 NAME Cyril/ROLLINDE de BEAUMONT/
2 GIVN Cyril
2 SURN ROLLINDE de BEAUMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 9:40
1 FAMS @5920@
1 BIRT
2 DATE 30 JUL 1972
2 _FNA NO
1 FAMC @5876@
0 @5885@ INDI
1 NAME Emmanuel/ROLLINDE de BEAUMONT/
2 GIVN Emmanuel
2 SURN ROLLINDE de BEAUMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 9:41
1 FAMS @5896@
1 FAMS @7271@
1 BIRT
2 DATE 6 JUL 1974
2 _FNA NO
1 FAMC @5876@
0 @5892@ INDI
1 NAME Élisabeth/MORETTI/
2 GIVN Élisabeth
2 SURN MORETTI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 9:41
1 FAMS @5896@
1 BIRT
2 DATE 6 SEP 1975
2 _FNA NO
0 @5899@ INDI
1 NAME Maëlys/ROLLINDE de BEAUMONT/
2 GIVN Maëlys
2 SURN ROLLINDE de BEAUMONT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 9:42
1 BIRT
2 DATE 16 JAN 2001
2 _FNA NO
1 FAMC @5896@
0 @5907@ INDI
1 NAME Ondine/ROLLINDE de BEAUMONT/
2 GIVN Ondine
2 SURN ROLLINDE de BEAUMONT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 10:26
1 BIRT
2 DATE 31 MAR 2003
2 _FNA NO
1 FAMC @5896@
0 @5916@ INDI
1 NAME Tamara/CWIKULA/
2 GIVN Tamara
2 SURN CWIKULA
1 SEX F
1 OCCU Enseignante
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 9:40
1 FAMS @5920@
1 BIRT
2 DATE 11 SEP 1972
2 _FNA NO
0 @5922@ INDI
1 NAME Natacha/ROLLINDE de BEAUMONT/
2 GIVN Natacha
2 SURN ROLLINDE de BEAUMONT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 9:42
1 FAMS @34250@
1 BIRT
2 DATE 23 SEP 1994
2 _FNA NO
1 FAMC @5920@
0 @5930@ INDI
1 NAME Chloé/ROLLINDE de BEAUMONT/
2 GIVN Chloé
2 SURN ROLLINDE de BEAUMONT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 9:42
1 BIRT
2 DATE 1 MAR 1994
2 _FNA NO
1 FAMC @5920@
0 @5938@ INDI
1 NAME Thierry/RENAULT/
2 GIVN Thierry
2 SURN RENAULT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 13:47
1 FAMS @5942@
1 BIRT
2 DATE 4 SEP 1950
2 _FNA NO
0 @5945@ INDI
1 NAME Fabrice/RENAULT/
2 GIVN Fabrice
2 SURN RENAULT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 13:57
1 FAMS @6011@
1 BIRT
2 DATE 7 SEP 1973
2 _FNA NO
1 FAMC @5942@
0 @5952@ INDI
1 NAME Sébastien/RENAULT/
2 GIVN Sébastien
2 SURN RENAULT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 13:51
1 FAMS @5978@
1 BIRT
2 DATE 5 AUG 1975
2 _FNA NO
1 FAMC @5942@
0 @5959@ INDI
1 NAME Virginie/RENAULT/
2 GIVN Virginie
2 SURN RENAULT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 13:47
1 BIRT
2 DATE 19 AUG 1981
2 _FNA NO
1 FAMC @5942@
0 @5966@ INDI
1 NAME Marie/RENAULT/
2 GIVN Marie
2 SURN RENAULT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 13:47
1 BIRT
2 DATE 6 MAY 1989
2 _FNA NO
1 FAMC @5942@
0 @5974@ INDI
1 NAME Virginie/MICHONNET/
2 GIVN Virginie
2 SURN MICHONNET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 13:51
1 FAMS @5978@
1 BIRT
2 DATE 24 DEC 1980
2 _FNA NO
0 @5980@ INDI
1 NAME Augustin/RENAULT/
2 GIVN Augustin
2 SURN RENAULT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 13:49
1 BIRT
2 DATE 10 JUN 2006
2 _FNA NO
1 FAMC @5978@
0 @5988@ INDI
1 NAME Elliott/RENAULT/
2 GIVN Elliott
2 SURN RENAULT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 13:50
1 BIRT
2 DATE 28 APR 2009
2 _FNA NO
1 FAMC @5978@
0 @5996@ INDI
1 NAME Lucile/RENAULT/
2 GIVN Lucile
2 SURN RENAULT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 11:02
1 BIRT
2 DATE 13 OCT 2012
2 _FNA NO
1 FAMC @5978@
0 @6007@ INDI
1 NAME Béatrice/TOURVIELLE/
2 GIVN Béatrice
2 SURN TOURVIELLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 13:57
1 FAMS @6011@
1 BIRT
2 DATE 1 AUG 1975
2 _FNA NO
0 @6014@ INDI
1 NAME Anne-Liv/RENAULT/
2 GIVN Anne-Liv
2 SURN RENAULT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 13:56
1 BIRT
2 DATE 8 JUL 2005
2 _FNA NO
1 FAMC @6011@
0 @6021@ INDI
1 NAME Céleste/RENAULT/
2 GIVN Céleste
2 SURN RENAULT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 13:57
1 BIRT
2 DATE 20 AUG 2009
2 _FNA NO
1 FAMC @6011@
0 @6029@ INDI
1 NAME Jacques/GERMERIE/
2 GIVN Jacques
2 SURN GERMERIE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 APR 2019
3 TIME 9:22
1 FAMS @6036@
1 BIRT
2 DATE 18 MAR 1951
2 _FNA NO
1 DEAT
2 DATE 20 FEB 2013
2 _FNA NO
1 BURI
2 DATE 26 FEB 2013
2 PLAC Saint-Pierre-des-Nids,53370,Mayenne,Pays de la Loire,FRANCE,
3 MAP
4 LATI N48.398260
4 LONG W0.099840
2 _FNA NO
1 FAMC @30805@
0 @6039@ INDI
1 NAME Émeline/GERMERIE/
2 GIVN Émeline
2 SURN GERMERIE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 20:37
1 FAMS @6087@
1 BIRT
2 DATE 3 JUN 1977
2 _FNA NO
1 FAMC @6036@
0 @6046@ INDI
1 NAME Anne/GERMERIE/
2 GIVN Anne
2 SURN GERMERIE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 20:04
1 FAMS @6065@
1 BIRT
2 DATE 12 APR 1979
2 _FNA NO
1 FAMC @6036@
0 @6053@ INDI
1 NAME Romain/GERMERIE/
2 GIVN Romain
2 SURN GERMERIE
1 SEX M
1 OCCU Musicien
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 11:55
1 FAMS @7284@
1 BIRT
2 DATE 1 APR 1982
2 _FNA NO
1 FAMC @6036@
0 @6061@ INDI
1 NAME Alexandre/PERRIN/
2 GIVN Alexandre
2 SURN PERRIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 14:04
1 FAMS @6065@
1 BIRT
2 DATE 30 DEC 1975
2 _FNA NO
0 @6068@ INDI
1 NAME Chléophée/PERRIN/
2 GIVN Chléophée
2 SURN PERRIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 14:04
1 BIRT
2 DATE 30 SEP 2005
2 _FNA NO
1 FAMC @6065@
0 @6075@ INDI
1 NAME Ambroise/PERRIN/
2 GIVN Ambroise
2 SURN PERRIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 14:04
1 BIRT
2 DATE 17 JUL 2008
2 _FNA NO
1 FAMC @6065@
0 @6083@ INDI
1 NAME Olivier/MASSET/
2 GIVN Olivier
2 SURN MASSET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 20:37
1 FAMS @6087@
1 BIRT
2 DATE 31 JAN 1977
2 _FNA NO
0 @6089@ INDI
1 NAME Joséphine/MASSET/
2 GIVN Joséphine
2 SURN MASSET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 14:06
1 BIRT
2 DATE 2 OCT 2009
2 _FNA NO
1 FAMC @6087@
0 @6096@ INDI
1 NAME Valentin/MASSET/
2 GIVN Valentin
2 SURN MASSET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 14:07
1 BIRT
2 DATE 31 JUL 2011
2 _FNA NO
1 FAMC @6087@
0 @6103@ INDI
1 NAME Edmond Alphonse/MISSOFFE/
2 GIVN Edmond Alphonse
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 11:53
1 FAMS @6123@
1 BIRT
2 DATE 5 JUN 1858
2 PLAC Le Chay,17600,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.639190
4 LONG W0.894250
2 _FNA NO
1 CHR
2 DATE 6 JUN 1858
2 PLAC Le Chay,17600,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.639190
4 LONG W0.894250
2 _FNA NO
1 DEAT
2 DATE 3 MAR 1937
2 _FNA NO
1 FAMC @6146@
0 @6114@ INDI
1 NAME Marguerite/BEVIERE/
2 GIVN Marguerite
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 18:51
1 FAMS @6123@
1 CHR
2 DATE 1859
2 PLAC Charleville-Mézières,08000,Ardennes,Grand Est,FRANCE,
3 MAP
4 LATI N49.766670
4 LONG E4.716670
2 _FNA NO
1 BIRT
2 DATE 27 OCT 1859
2 PLAC Charleville-Mézières,08000,Ardennes,Grand Est,FRANCE,
3 MAP
4 LATI N49.766670
4 LONG E4.716670
2 _FNA NO
2 NOTE née à Mézières  ancienne commune française, située dans le département des Ardennes en région Grand Est.
3 CONT Avant les fusions successives, la commune a été la préfecture du département des Ardennes. Après avoir absorbé Le Theux le 1er janvier 1965, elle fusionne avec les communes de Charleville, Étion, Mohon et Montcy-Saint-Pierre, le 1er octobre 1966, p
3 CONC our former la commune de Charleville-Mézières, qui devint la nouvelle préfecture des Ardennes.
1 DEAT
2 DATE 10 FEB 1953
2 _FNA NO
1 FAMC @10736@
0 @6128@ INDI
1 NAME François-Lubin/MISSOFFE/
2 GIVN François-Lubin
2 SURN MISSOFFE
1 SEX M
1 OCCU instituteur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 19:31
1 FAMS @6146@
1 BIRT
2 DATE 14 SEP 1827
2 PLAC La Rochelle,17000,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.166670
4 LONG W1.150000
2 _FNA NO
2 NOTE déposé au tour de l’hospice civil de La ROCHELLE
1 CHR HOSPICE
2 DATE 15 SEP 1827
2 PLAC La Rochelle,17000,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.166670
4 LONG W1.150000
2 _FNA NO
1 DEAT
2 DATE 5 DEC 1879
3 TIME 6:00
2 PLAC Nuaillé-sur-Boutonne,17470,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.013040
4 LONG W0.435040
2 _FNA NO
1 FAMC @8821@
0 @6139@ INDI
1 NAME Joséphine Agathe/PAILLÉ/
2 GIVN Joséphine Agathe
2 SURN PAILLÉ
1 SEX F
1 OCCU lingère
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 18:51
1 FAMS @6146@
1 BIRT
2 DATE 7 SEP 1830
2 PLAC Saint-Loup,17380,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.983330
4 LONG W0.616670
2 _FNA YES
1 DEAT
2 DATE 13 JUL 1864
2 PLAC Nuaillé-sur-Boutonne,17470,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.013040
4 LONG W0.435040
2 _FNA NO
2 CAUS PNEUMONIE
1 FAMC @8718@
0 @6150@ INDI
1 NAME Noémie/MISSOFFE/
2 GIVN Noémie
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 19:08
1 FAMS @6165@
1 BIRT
2 DATE 25 JAN 1860
2 PLAC Mazeray,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.908220
4 LONG W0.562830
2 _FNA NO
1 CHR
2 DATE 26 JAN 1860
2 PLAC Mazeray,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.908220
4 LONG W0.562830
2 _FNA NO
1 DEAT
2 DATE 18 JAN 1942
2 _FNA NO
1 FAMC @6146@
0 @6161@ INDI
1 NAME Jean-Baptiste François/GANDELIN/
2 GIVN Jean-Baptiste François
2 SURN GANDELIN
1 SEX M
1 OCCU Instituteur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 12:35
1 FAMS @6165@
1 BIRT
2 DATE 25 JUN 1857
2 PLAC Coivert,17330,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.070020
4 LONG W0.458490
2 _FNA NO
1 DEAT
2 DATE 10 MAY 1932
2 PLAC Nuaillé-sur-Boutonne,17470,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.013040
4 LONG W0.435040
2 _FNA NO
1 FAMC @8800@
0 @6168@ INDI
1 NAME Jean/MISSOFFE/
2 GIVN Jean
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 11:57
1 FAMS @6248@
1 BIRT
2 DATE 16 AUG 1885
2 PLAC Brest,29200,Finistère,Bretagne,FRANCE,
3 MAP
4 LATI N48.400000
4 LONG W4.483330
2 _FNA NO
1 DEAT
2 DATE 22 FEB 1915
2 _FNA NO
2 CAUS mort au combat
2 NOTE MISSOFFE J-v- Disparu En 1914, à France› Corps d’affectation : TROUPES INFANTERIE - 106° REGIMENT
3 CONT Grade : lieutenant
3 CONT Informations complémentaires : Lieu d'affectation: Châlons/Marne,
3 CONT Affectation/Fonction: 24° brigade ; 12° division ; 6° corps
3 CONT Date d'affectation: 01/10/1908
1 FAMC @6123@
0 @6178@ INDI
1 NAME Michel François Marie/MISSOFFE/
2 GIVN Michel François Marie
2 SURN MISSOFFE
1 SEX M
1 OCCU Avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 12:03
1 FAMS @6240@
1 BIRT
2 DATE 8 JUN 1887
2 PLAC Brest,29200,Finistère,Bretagne,FRANCE,
3 MAP
4 LATI N48.400000
4 LONG W4.483330
2 _FNA NO
1 BURI
2 DATE 1974
2 PLAC Loigny-la-Bataille,28140,Eure-et-Loir,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N48.123240
4 LONG E1.733950
2 _FNA NO
1 DEAT
2 DATE 24 MAR 1974
2 _FNA NO
1 FAMC @6123@
0 @6188@ INDI
1 NAME Olga Marie/MISSOFFE/
2 GIVN Olga Marie
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 12:03
1 BIRT
2 DATE 1889
2 PLAC Brest,29200,Finistère,Bretagne,FRANCE,
3 MAP
4 LATI N48.400000
4 LONG W4.483330
2 _FNA NO
1 DEAT
2 DATE 1890
2 PLAC Brest,29200,Finistère,Bretagne,FRANCE,
3 MAP
4 LATI N48.400000
4 LONG W4.483330
2 _FNA NO
1 FAMC @6123@
0 @6199@ INDI
1 NAME Gilberte/MISSOFFE/
2 GIVN Gilberte
2 SURN MISSOFFE
2 NICK tante gil
1 SEX F
1 OCCU Infirmière
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 12:07
1 FAMS @6229@
1 BIRT
2 DATE 29 NOV 1895
2 PLAC Issy-les-Moulineaux,92130,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.821040
4 LONG E2.277180
2 _FNA NO
1 BURI LOCHRIST
2 DATE 1990
2 PLAC Le Conquet,29217,Finistère,Bretagne,FRANCE,
3 MAP
4 LATI N48.360530
4 LONG W4.770860
2 _FNA NO
1 DEAT
2 DATE 17 APR 1990
2 PLAC Paris 16,,,,,
2 _FNA NO
1 FAMC @6123@
0 @6209@ INDI
1 NAME François Marie/MISSOFFE/
2 GIVN François Marie
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 12:12
1 CHR
2 DATE 1897
2 _FNA NO
1 BIRT
2 DATE 28 OCT 1897
2 PLAC Issy-les-Moulineaux,92130,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.821040
4 LONG E2.277180
2 _FNA NO
1 DEAT
2 DATE 25 JUL 1918
2 PLAC Bouilly,51390,Marne,Grand Est,FRANCE,
3 MAP
4 LATI N49.199050
4 LONG E3.888810
2 _FNA NO
2 CAUS mort au combat
2 NOTE SOUS-LIEUTENANT AU 61°BCP
3 CONT BOIS DE COURNAS lieu-dit « les dix hommées »
1 FAMC @6123@
0 @6221@ INDI
1 NAME Hippolyte Michel Ernest-Marie/TABURET/
2 GIVN Hippolyte Michel Ernest-Marie
2 SURN TABURET
2 NICK Pol
1 SEX M
1 OCCU Médecin
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 12:07
1 FAMS @6229@
1 BIRT
2 DATE 17 OCT 1888
2 _FNA NO
1 DEAT
2 DATE 28 SEP 1950
2 _FNA NO
1 NOTE Gilberte était maraine de guerre de Pol
1 FAMC @12341@
0 @6233@ INDI
1 NAME Lilian/de SONIS/
2 GIVN Lilian
2 SURN de SONIS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 11:58
1 FAMS @6240@
1 BIRT
2 DATE 4 SEP 1900
2 _FNA NO
1 BURI
2 DATE 1987
2 PLAC Loigny-la-Bataille,28140,Eure-et-Loir,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N48.123240
4 LONG E1.733950
2 _FNA NO
1 DEAT
2 DATE 29 OCT 1987
2 _FNA NO
1 FAMC @12492@
0 @6244@ INDI
1 NAME Yvonne Pauline Henriette/COLLENET/
2 GIVN Yvonne Pauline Henriette
2 SURN COLLENET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 11:55
1 FAMS @6248@
1 BIRT
2 DATE 15 JUL 1891
2 _FNA NO
1 DEAT
2 DATE 26 NOV 1933
2 _FNA NO
1 FAMC @12674@
0 @6251@ INDI
1 NAME Maurice/GANDELIN/
2 GIVN Maurice
2 SURN GANDELIN
1 SEX M
1 OCCU Percepteur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 12:47
1 FAMS @6345@
1 BIRT
2 DATE 10 OCT 1886
2 PLAC Villeneuve-la-Comtesse,17330,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.097830
4 LONG W0.504280
2 _FNA NO
1 DEAT
2 DATE 23 JAN 1938
2 PLAC Poitiers,86000,Vienne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.583330
4 LONG E0.333330
2 _FNA NO
2 NOTE dixit simone
1 FAMC @6165@
0 @6262@ INDI
1 NAME René/GANDELIN/
2 GIVN René
2 SURN GANDELIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 13:05
1 FAMS @6334@
1 FAMS @13634@
1 BIRT
2 DATE 25 AUG 1889
2 PLAC Saint-Jean-d'Angély,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.950000
4 LONG W0.516670
2 _FNA NO
1 DEAT
2 DATE 31 MAY 1965
2 PLAC Antibes,06160,Alpes-Maritimes,Provence-Alpes-Côte d'Azur,FRANCE,
3 MAP
4 LATI N43.562410
4 LONG E7.127770
2 _FNA NO
1 FAMC @6165@
0 @6272@ INDI
1 NAME André/GANDELIN/
2 GIVN André
2 SURN GANDELIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 16:03
1 FAMS @6313@
1 FAMS @6323@
1 FAMS @13679@
1 BIRT
2 DATE 25 AUG 1892
2 PLAC Saint-Jean-d'Angély,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.950000
4 LONG W0.516670
2 _FNA NO
1 DEAT
2 DATE 25 FEB 1933
2 PLAC Madagascar,,,,,
2 _FNA NO
1 FAMC @6165@
0 @6283@ INDI
1 NAME Georges/GANDELIN/
2 GIVN Georges
2 SURN GANDELIN
1 SEX M
1 OCCU Intendant UNIVERSITAIRE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 16:19
1 FAMS @6301@
1 FAMS @6309@
1 BIRT
2 DATE 14 MAY 1897
2 PLAC Saint-Jean-d'Angély,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.950000
4 LONG W0.516670
2 _FNA NO
1 DEAT
2 DATE 14 OCT 1980
2 PLAC Linac,46270,Lot,Occitanie,FRANCE,
3 MAP
4 LATI N44.665950
4 LONG E2.114540
2 _FNA NO
1 FAMC @6165@
0 @6294@ INDI
1 NAME Maria Vincenta/DUBOUE/
2 GIVN Maria Vincenta
2 SURN DUBOUE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 16:13
1 FAMS @6301@
1 BIRT
2 DATE 19 SEP 1893
2 PLAC Pampelune,,,,,
2 _FNA NO
1 DEAT
2 DATE 14 AUG 1973
2 PLAC Saint-Jean-de-Luz,64500,Pyrénées-Atlantiques,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N43.389080
4 LONG W1.658100
2 _FNA NO
1 FAMC @13717@
0 @6305@ INDI
1 NAME Suzanne/VERHILLE/
2 GIVN Suzanne
2 SURN VERHILLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 16:19
1 FAMS @6309@
1 BIRT
2 DATE 4 APR 1909
2 PLAC Fontainebleau,77300,Seine-et-Marne,Île-de-France,FRANCE,
3 MAP
4 LATI N48.400000
4 LONG E2.700000
2 _FNA NO
1 NOTE POLYTECHNICIEN
1 FAMC @13753@
0 @6311@ INDI
1 NAME Germaine/LANDRÉ/
2 GIVN Germaine
2 SURN LANDRÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 15:57
1 FAMS @6313@
0 @6316@ INDI
1 NAME Marie-Gabrielle/POMMIES/
2 GIVN Marie-Gabrielle
2 SURN POMMIES
2 NICK ODETTE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 16:03
1 FAMS @6323@
1 BIRT
2 DATE 9 SEP 1893
2 PLAC Bordeaux,33000,Gironde,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N44.840440
4 LONG W0.580500
2 _FNA NO
1 DEAT
2 DATE OCT 1928
2 PLAC Tananarive,,,,,
2 _FNA NO
0 @6327@ INDI
1 NAME Mireille/NEUVILLE/
2 GIVN Mireille
2 SURN NEUVILLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 13:05
1 FAMS @6334@
1 BIRT
2 DATE 19 JUN 1894
2 PLAC Miramont-de-Guyenne,47800,Lot-et-Garonne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N44.601570
4 LONG E0.362470
2 _FNA NO
1 DEAT
2 DATE 11 MAY 1986
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @13649@
0 @6338@ INDI
1 NAME Linda Antoinette Marie/PRUNIERES/
2 GIVN Linda Antoinette Marie
2 SURN PRUNIERES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 12:51
1 FAMS @6345@
1 BIRT
2 DATE 6 DEC 1889
2 PLAC Le Caire,,,,,
3 MAP
4 LATI N44.368870
4 LONG E6.059760
2 _FNA NO
1 DEAT
2 DATE 10 SEP 1982
2 _FNA NO
1 FAMC @13581@
0 @6350@ INDI
1 NAME Yvonne/MISSOFFE/
2 GIVN Yvonne
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 21:39
1 BIRT
2 DATE 6 JUN 1911
2 _FNA NO
1 DEAT
2 DATE 1 JAN 1920
2 _FNA NO
1 FAMC @6248@
0 @6360@ INDI
1 NAME Jean/MISSOFFE/
2 GIVN Jean
2 SURN MISSOFFE
1 SEX M
1 OCCU militaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 18:32
1 FAMS @6397@
1 BIRT
2 DATE 30 JUN 1912
2 _FNA NO
1 DEAT
2 DATE 9 JUL 1952
2 PLAC Phuc-Nac,,,,,indochine
2 _FNA NO
2 CAUS mort pour la France
1 FAMC @6248@
0 @6371@ INDI
1 NAME Serge/MISSOFFE/
2 GIVN Serge
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 7:23
1 FAMS @6389@
1 BIRT
2 DATE 31 MAY 1915
2 PLAC Époisses,21460,Côte-d'Or,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.506660
4 LONG E4.174280
2 _FNA NO
1 DEAT
2 DATE 1 NOV 1993
2 _FNA NO
1 FAMC @6248@
0 @6382@ INDI
1 NAME Simone/JACQUIN/
2 GIVN Simone
2 SURN JACQUIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 7:23
1 FAMS @6389@
1 BIRT
2 DATE 3 JAN 1910
2 PLAC Hanoi,,,,,
2 _FNA NO
1 DEAT
2 DATE 23 JUL 2002
2 _FNA NO
1 FAMC @12316@
0 @6393@ INDI
1 NAME Michèle/LESBRE/
2 GIVN Michèle
2 SURN LESBRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 7:18
1 FAMS @6397@
1 FAMS @12283@
1 BIRT
2 DATE 18 JAN 1921
2 _FNA NO
1 DEAT
2 DATE 12 FEB 2009
2 PLAC Boulogne-Billancourt,92100,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.833330
4 LONG E2.250000
2 _FNA NO
1 FAMC @12272@
0 @6399@ INDI
1 NAME Jean/MISSOFFE/
2 GIVN Jean
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 10:40
1 FAMS @6478@
1 BIRT
2 DATE 7 JUL 1947
2 _FNA NO
1 FAMC @6397@
0 @6406@ INDI
1 NAME Catherine/MISSOFFE/
2 GIVN Catherine
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 20:14
1 FAMS @6418@
1 BIRT
2 DATE 11 MAY 1950
2 _FNA NO
1 FAMC @6397@
0 @6414@ INDI
1 NAME Gilles/TRAPENARD/
2 GIVN Gilles
2 SURN TRAPENARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 20:14
1 FAMS @6418@
1 BIRT
2 DATE 28 JAN 1950
2 _FNA NO
0 @6420@ INDI
1 NAME Thomas/TRAPENARD/
2 GIVN Thomas
2 SURN TRAPENARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 20:23
1 FAMS @6462@
1 BIRT
2 DATE 1 OCT 1973
2 _FNA NO
1 FAMC @6418@
0 @6427@ INDI
1 NAME Vincent/TRAPENARD/
2 GIVN Vincent
2 SURN TRAPENARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 8:41
1 FAMS @6455@
1 FAMS @10964@
1 BIRT
2 DATE 6 MAY 1976
2 _FNA NO
1 FAMC @6418@
0 @6434@ INDI
1 NAME Augustin/TRAPENARD/
2 GIVN Augustin
2 SURN TRAPENARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 21:51
1 BIRT
2 DATE 3 APR 1979
2 _FNA NO
1 FAMC @6418@
0 @6442@ INDI
1 NAME Constance/TRAPENARD/
2 GIVN Constance
2 SURN TRAPENARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 8:50
1 FAMS @7217@
1 BIRT
2 DATE 14 JUL 1983
2 _FNA NO
1 FAMC @6418@
0 @6451@ INDI
1 NAME Katherine/DESBAILLETS/
2 GIVN Katherine
2 SURN DESBAILLETS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 8:35
1 FAMS @6455@
1 BIRT
2 DATE 12 SEP 1983
2 _FNA NO
0 @6458@ INDI
1 NAME Violaine/BAYON de NOYER/
2 GIVN Violaine
2 SURN BAYON de NOYER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 20:23
1 FAMS @6462@
1 BIRT
2 DATE 18 JUN 1979
2 _FNA NO
0 @6465@ INDI
1 NAME Max/TRAPENARD/
2 GIVN Max
2 SURN TRAPENARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 21:56
1 BIRT
2 DATE 3 DEC 2003
2 _FNA NO
1 FAMC @6462@
0 @6474@ INDI
1 NAME Nicole/REEDER/
2 GIVN Nicole
2 SURN REEDER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:00
1 FAMS @6478@
1 BIRT
2 DATE 18 DEC 1950
2 _FNA NO
0 @6481@ INDI
1 NAME Alexia/MISSOFFE/
2 GIVN Alexia
2 SURN MISSOFFE
1 SEX F
1 OCCU recherche médicale
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 10:46
1 BIRT
2 DATE 18 AUG 1982
2 _FNA NO
1 FAMC @6478@
0 @6488@ INDI
1 NAME Claire/MISSOFFE/
2 GIVN Claire
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 8:09
1 FAMS @7188@
1 BIRT
2 DATE 16 JUN 1984
2 _FNA NO
1 FAMC @6478@
0 @6496@ INDI
1 NAME Victoria/MISSOFFE/
2 GIVN Victoria
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 8:56
1 FAMS @7199@
1 BIRT
2 DATE 6 JAN 1986
2 _FNA NO
1 FAMC @6478@
0 @6503@ INDI
1 NAME Christiane/MISSOFFE/
2 GIVN Christiane
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 8:00
1 FAMS @34737@
1 BIRT
2 DATE 27 JAN 1940
2 _FNA NO
1 FAMC @6389@
0 @6511@ INDI
1 NAME Danielle/MISSOFFE/
2 GIVN Danielle
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 AUG 2018
3 TIME 15:36
1 FAMS @6750@
1 BIRT
2 DATE 26 JUL 1941
2 _FNA NO
1 FAMC @6389@
0 @6519@ INDI
1 NAME Yves/MISSOFFE/
2 GIVN Yves
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 10:49
1 FAMS @6661@
1 BIRT
2 DATE 25 NOV 1942
2 _FNA NO
1 FAMC @6389@
0 @6526@ INDI
1 NAME Dominique/MISSOFFE/
2 GIVN Dominique
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:16
1 FAMS @6586@
1 BIRT
2 DATE 8 JAN 1945
2 _FNA NO
1 FAMC @6389@
0 @6533@ INDI
1 NAME Patrick/MISSOFFE/
2 GIVN Patrick
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:12
1 FAMS @6554@
1 BIRT
2 DATE 20 JUL 1946
2 _FNA NO
1 FAMC @6389@
0 @6540@ INDI
1 NAME Anne/MISSOFFE/
2 GIVN Anne
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:08
1 BIRT
2 DATE 2 DEC 1948
2 _FNA NO
1 FAMC @6389@
0 @6550@ INDI
1 NAME Marie-Paule/HAUDY/
2 GIVN Marie-Paule
2 SURN HAUDY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:12
1 FAMS @6554@
1 BIRT
2 DATE 17 JUN 1949
2 _FNA NO
0 @6556@ INDI
1 NAME Jérémie/MISSOFFE/
2 GIVN Jérémie
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:11
1 BIRT
2 DATE 8 SEP 1986
2 _FNA NO
1 DEAT
2 DATE 8 SEP 1986
2 _FNA NO
1 FAMC @6554@
0 @6566@ INDI
1 NAME Benjamin/MISSOFFE/
2 GIVN Benjamin
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:11
1 BIRT
2 DATE 4 MAR 1988
2 _FNA NO
1 FAMC @6554@
0 @6574@ INDI
1 NAME Florian/MISSOFFE/
2 GIVN Florian
2 SURN MISSOFFE
1 SEX M
1 OCCU cuisinier
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 9:07
1 BIRT
2 DATE 3 JAN 1991
2 _FNA NO
1 FAMC @6554@
0 @6582@ INDI
1 NAME Sophie/BERTHON/
2 GIVN Sophie
2 SURN BERTHON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:16
1 FAMS @6586@
1 BIRT
2 DATE 9 SEP 1949
2 _FNA NO
0 @6588@ INDI
1 NAME Natacha/MISSOFFE/
2 GIVN Natacha
2 SURN MISSOFFE
1 SEX F
1 OCCU ED DISTRIBUTION FILMS
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 9:21
1 FAMS @7253@
1 BIRT
2 DATE 13 MAR 1984
2 _FNA NO
1 FAMC @6586@
0 @6595@ INDI
1 NAME David/MISSOFFE/
2 GIVN David
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 8:46
1 FAMS @6638@
1 BIRT
2 DATE 28 MAY 1978
2 _FNA NO
1 FAMC @6586@
0 @6602@ INDI
1 NAME Mathieu/MISSOFFE/
2 GIVN Mathieu
2 SURN MISSOFFE
1 SEX M
1 OCCU Scénariste TéLévision
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 9:16
1 FAMS @6615@
1 BIRT
2 DATE 27 JUL 1976
2 _FNA NO
1 FAMC @6586@
0 @6611@ INDI
1 NAME Carine/LAURU/
2 GIVN Carine
2 SURN LAURU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:18
1 FAMS @6615@
1 BIRT
2 DATE 29 JUL 1975
2 _FNA NO
0 @6618@ INDI
1 NAME Marcus/MISSOFFE/
2 GIVN Marcus
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:18
1 BIRT
2 DATE 5 JUL 2006
2 _FNA NO
1 FAMC @6615@
0 @6626@ INDI
1 NAME Lila/MISSOFFE/
2 GIVN Lila
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 9:17
1 BIRT
2 DATE 19 MAY 2009
2 _FNA NO
1 FAMC @6615@
0 @6634@ INDI
1 NAME Stéphanie/TOUTLEMONDE/
2 GIVN Stéphanie
2 SURN TOUTLEMONDE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:21
1 FAMS @6638@
1 BIRT
2 DATE 24 JAN 1978
2 _FNA NO
0 @6641@ INDI
1 NAME Mia/MISSOFFE/
2 GIVN Mia
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:20
1 BIRT
2 DATE 13 SEP 2009
2 _FNA NO
1 FAMC @6638@
0 @6649@ INDI
1 NAME Sasha/MISSOFFE/
2 GIVN Sasha
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:21
1 BIRT
2 DATE 2 APR 2012
2 _FNA NO
1 FAMC @6638@
0 @6657@ INDI
1 NAME Christine/GUIRAN/
2 GIVN Christine
2 SURN GUIRAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:25
1 FAMS @6661@
1 BIRT
2 DATE 9 JUL 1946
2 _FNA NO
0 @6663@ INDI
1 NAME Antoine/MISSOFFE/
2 GIVN Antoine
2 SURN MISSOFFE
1 SEX M
1 OCCU Avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 18:36
1 FAMS @6729@
1 BIRT
2 DATE 30 JUL 1975
2 _FNA NO
1 FAMC @6661@
0 @6670@ INDI
1 NAME Raphaël/MISSOFFE/
2 GIVN Raphaël
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:29
1 FAMS @6692@
1 BIRT
2 DATE 10 MAR 1978
2 _FNA NO
1 FAMC @6661@
0 @6677@ INDI
1 NAME Xavier/MISSOFFE/
2 GIVN Xavier
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:25
1 BIRT
2 DATE 16 AUG 1982
2 _FNA NO
1 DEAT
2 DATE 18 MAY 1994
2 _FNA NO
1 FAMC @6661@
0 @6688@ INDI
1 NAME Marie-Ange/HEURTEAU/
2 GIVN Marie-Ange
2 SURN HEURTEAU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:29
1 FAMS @6692@
1 BIRT
2 DATE 2 SEP 1981
2 _FNA NO
0 @6694@ INDI
1 NAME Valentin/MISSOFFE/
2 GIVN Valentin
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:27
1 BIRT
2 DATE 22 DEC 2006
2 _FNA NO
1 FAMC @6692@
0 @6701@ INDI
1 NAME Paul/MISSOFFE/
2 GIVN Paul
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:27
1 BIRT
2 DATE 13 FEB 2008
2 _FNA NO
1 FAMC @6692@
0 @6709@ INDI
1 NAME Margaux/MISSOFFE/
2 GIVN Margaux
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:29
1 BIRT
2 DATE 21 NOV 2009
2 _FNA NO
1 FAMC @6692@
0 @6717@ INDI
1 NAME Clémentine/MISSOFFE/
2 GIVN Clémentine
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:29
1 BIRT
2 DATE 16 OCT 2012
2 _FNA NO
1 FAMC @6692@
0 @6725@ INDI
1 NAME Marine/LELIEVRE/
2 GIVN Marine
2 SURN LELIEVRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 9:10
1 FAMS @6729@
1 BIRT
2 DATE 10 JUN 1978
2 _FNA NO
0 @6731@ INDI
1 NAME Chloé/MISSOFFE/
2 GIVN Chloé
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:32
1 BIRT
2 DATE 27 NOV 2007
2 _FNA NO
1 FAMC @6729@
0 @6738@ INDI
1 NAME Camille/MISSOFFE/
2 GIVN Camille
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:33
1 BIRT
2 DATE 2 JUL 2012
2 _FNA NO
1 FAMC @6729@
0 @6746@ INDI
1 NAME François/de LAMBERTERIE/
2 GIVN François
2 SURN de LAMBERTERIE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 8:06
1 FAMS @6750@
1 BIRT
2 DATE 1 MAR 1938
2 _FNA NO
1 NOTE famille originaire du village de La Chapelle-Montmoreau en Dordogne?
1 FAMC @19721@
0 @6752@ INDI
1 NAME Chloé/de LAMBERTERIE/
2 GIVN Chloé
2 SURN de LAMBERTERIE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:41
1 FAMS @6785@
1 BIRT
2 DATE 8 JUL 1977
2 _FNA NO
1 FAMC @6750@
0 @6759@ INDI
1 NAME Alexandre/de LAMBERTERIE/
2 GIVN Alexandre
2 SURN de LAMBERTERIE
1 SEX M
1 OCCU DIRECTEUR ARTISTIQUE UBISOFT
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 8:26
1 FAMS @6814@
1 BIRT
2 DATE 16 MAR 1969
2 _FNA NO
1 DEAT
2 DATE 14 OCT 2015
2 PLAC Montréal,,Montréal,Québec,CANADA,
3 MAP
4 LATI N45.508840
4 LONG W73.587810
2 _FNA NO
2 CAUS suicide
2 NOTE Avis de décès
3 CONT Florence Girod de Lamberterie, son épouse, François et Juliette, ses enfants,
3 CONT M. et Mme François de Lamberterie, ses parents,
3 CONT Caroline et Bruno Grossi, Olivia et Jean-Marc Piaton, Chloé et Emmanuel Gagnez, ses soeurs et beaux-frères, ses dix neveux et nièces
3 CONT ont la très grande tristesse de vous faire part du décès de
3 CONT Alexandre de LAMBERTERIE
3 CONT survenu à Montréal, le 14 octobre 2015, à l'âge de 46 ans.
3 CONT Une messe sera célébrée le jeudi 12 novembre, à 14 h 30, en l'église de Saint-François-de-Molitor, Paris (16 e ).
3 CONT Avis paru le 31 octobre 2015, Paris (75)
1 FAMC @6750@
0 @6766@ INDI
1 NAME Olivia/de LAMBERTERIE/
2 GIVN Olivia
2 SURN de LAMBERTERIE
1 SEX F
1 OCCU Journaliste
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 AUG 2018
3 TIME 14:54
1 FAMS @6827@
1 FAMS @19687@
1 BIRT
2 DATE 15 JUN 1966
2 _FNA NO
1 NOTE journaliste et critique littéraire française, rédactrice en chef adjointe au magazine Elle responsable du service livres et chroniqueuse à Télématin depuis le 4 mars 2005.
2 CONT
1 FAMC @6750@
0 @6773@ INDI
1 NAME Caroline/de LAMBERTERIE/
2 GIVN Caroline
2 SURN de LAMBERTERIE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 19:20
1 FAMS @6850@
1 BIRT
2 DATE 30 MAY 1963
2 _FNA NO
1 FAMC @6750@
0 @6781@ INDI
1 NAME Emmanuel/GAGNEZ/
2 GIVN Emmanuel
2 SURN GAGNEZ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:41
1 FAMS @6785@
1 BIRT
2 DATE 27 SEP 1976
2 _FNA NO
0 @6787@ INDI
1 NAME Félicie/GAGNEZ/
2 GIVN Félicie
2 SURN GAGNEZ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:40
1 BIRT
2 DATE 19 OCT 2006
2 _FNA NO
1 FAMC @6785@
0 @6795@ INDI
1 NAME Violette/GAGNEZ/
2 GIVN Violette
2 SURN GAGNEZ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:41
1 BIRT
2 DATE 19 OCT 2008
2 _FNA NO
1 FAMC @6785@
0 @6802@ INDI
1 NAME Hippolyte/GAGNEZ/
2 GIVN Hippolyte
2 SURN GAGNEZ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:41
1 BIRT
2 DATE 2 MAY 2011
2 _FNA NO
1 FAMC @6785@
0 @6810@ INDI
1 NAME Florence/GIROD/
2 GIVN Florence
2 SURN GIROD
1 SEX F
1 OCCU Publicitaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 9:04
1 FAMS @6814@
1 BIRT
2 DATE 21 MAR 1966
2 _FNA NO
0 @6816@ INDI
1 NAME Juliette/de LAMBERTERIE/
2 GIVN Juliette
2 SURN de LAMBERTERIE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:44
1 BIRT
2 DATE 23 AUG 1999
2 _FNA NO
1 FAMC @6814@
0 @6825@ INDI
1 NAME Jean-Marc/PIATON/
2 GIVN Jean-Marc
2 SURN PIATON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 8:24
1 FAMS @6827@
1 BIRT
2 DATE 6 MAR 1954
2 _FNA NO
0 @6830@ INDI
1 NAME César/PIATON/
2 GIVN César
2 SURN PIATON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:47
1 BIRT
2 DATE 4 AUG 2005
2 _FNA NO
1 FAMC @6827@
0 @6838@ INDI
1 NAME Balthazar/PIATON/
2 GIVN Balthazar
2 SURN PIATON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:49
1 BIRT
2 DATE 26 JUL 2008
2 _FNA NO
1 FAMC @6827@
0 @6846@ INDI
1 NAME Bruno/GROSSI/
2 GIVN Bruno
2 SURN GROSSI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:53
1 FAMS @6850@
1 BIRT
2 DATE 1 JUN 1958
2 _FNA NO
0 @6852@ INDI
1 NAME Colomba/GROSSI/
2 GIVN Colomba
2 SURN GROSSI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:51
1 BIRT
2 DATE 17 AUG 1991
2 _FNA NO
1 FAMC @6850@
0 @6860@ INDI
1 NAME Paloma/GROSSI/
2 GIVN Paloma
2 SURN GROSSI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:51
1 BIRT
2 DATE 2 DEC 1993
2 _FNA NO
1 FAMC @6850@
0 @6868@ INDI
1 NAME Bianca/GROSSI/
2 GIVN Bianca
2 SURN GROSSI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:52
1 BIRT
2 DATE 4 SEP 1998
2 _FNA NO
1 FAMC @6850@
0 @6876@ INDI
1 NAME Lorenzo/GROSSI/
2 GIVN Lorenzo
2 SURN GROSSI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 MAR 2018
3 TIME 22:53
1 BIRT
2 DATE 17 MAR 2002
2 _FNA NO
1 FAMC @6850@
0 @6885@ INDI
1 NAME Pierre-Antoine/ADAM/
2 GIVN Pierre-Antoine
2 SURN ADAM
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 JUL 2018
3 TIME 11:37
1 FAMS @6887@
1 FAMC @9192@
0 @6913@ INDI
1 NAME Charles Jean François/DUPRÉ/
2 GIVN Charles Jean François
2 SURN DUPRÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 12:54
1 FAMS @6937@
1 BIRT
2 DATE 1821
2 _FNA NO
1 DEAT
2 DATE 1868
2 _FNA NO
1 NOTE Dans tombe   d'edouard AMBROSELLI  CPP 1874 allée Nicolas 3°ligne n°45 cimetière de Montmartre Epitaphes à  Louise Joséphine Lilia COBY 1/07/1826 _ 22/12/1905 	épouse Charles DUPRE (= Parents de Marie Victoire Félicie DUPRE ?)
0 @6922@ INDI
1 NAME Louise Joséphine Lilia/COBY/
2 GIVN Louise Joséphine Lilia
2 SURN COBY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 14:53
1 FAMS @6937@
1 BIRT
2 DATE 1 JUL 1826
2 _FNA NO
1 BURI
2 DATE 1905
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,cimetierre de montmartre
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
2 NOTE Dans tombe   d'edouard AMBROSELLI  CPP 1874 allée Nicolas 3°ligne n°45 cimetière de Montmartre Epitaphes à
3 CONT Catherine AMBROSELLI = tante dcd 26/04/1859 à 66 ans
1 DEAT
2 DATE 22 DEC 1905
2 _FNA NO
1 FAMC @23103@
0 @6991@ INDI
1 NAME Paul Lucien Charles/SEGURET/
2 GIVN Paul Lucien Charles
2 SURN SEGURET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 AUG 2019
3 TIME 7:43
1 FAMS @6993@
1 BIRT
2 DATE 18 MAY 1883
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 29 NOV 1943
2 _FNA NO
1 FAMC @16500@
0 @6995@ INDI
1 NAME Albert/SEGURET/
2 GIVN Albert
2 SURN SEGURET
1 SEX M
1 OCCU manufacturier
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 MAY 2018
3 TIME 19:50
1 FAMS @16500@
1 BIRT
2 DATE 1848
2 _FNA NO
1 DEAT
2 DATE 1933
2 _FNA NO
0 @7005@ INDI
1 NAME Suzanne Lucie/ETIENNE de GRAMMONT/
2 GIVN Suzanne Lucie
2 SURN ETIENNE de GRAMMONT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 DEC 2018
3 TIME 22:07
1 FAMS @7007@
1 BIRT
2 DATE 1892
2 _FNA NO
1 DEAT
2 DATE 1993
2 _FNA NO
0 @7009@ INDI
1 NAME Claude/ACCURSI/
2 GIVN Claude
2 SURN ACCURSI
1 SEX M
1 OCCU cinéaste
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 DEC 2018
3 TIME 2:05
1 FAMS @7022@
1 BIRT
2 DATE 1920
2 _FNA NO
1 DEAT
2 DATE 1988
2 _FNA NO
1 FAMC @7007@
0 @7015@ INDI
1 NAME Huguette/ACCURSI/
2 GIVN Huguette
2 SURN ACCURSI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 DEC 2018
3 TIME 22:08
1 FAMS @7136@
1 FAMC @7007@
0 @7020@ INDI
1 NAME Hélène/X/
2 GIVN Hélène
2 SURN X
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 MAR 2018
3 TIME 17:30
1 FAMS @7022@
0 @7023@ INDI
1 NAME Daniel/ACCURSI/
2 GIVN Daniel
2 SURN ACCURSI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 MAR 2018
3 TIME 17:29
1 FAMC @7022@
0 @7029@ INDI
1 NAME Daphnée/ACCURSI/
2 GIVN Daphnée
2 SURN ACCURSI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 MAR 2018
3 TIME 17:30
1 FAMC @7022@
0 @7035@ INDI
1 NAME Françoise Cecette/SEGURET/
2 GIVN Françoise Cecette
2 SURN SEGURET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 MAR 2018
3 TIME 17:37
1 FAMS @7056@
1 FAMC @6993@
0 @7042@ INDI
1 NAME Christian/SEGURET/
2 GIVN Christian
2 SURN SEGURET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 DEC 2018
3 TIME 21:55
1 FAMS @25157@
1 FAMC @6993@
0 @7047@ INDI
1 NAME Jean Léon Henri/SEGURET/
2 GIVN Jean Léon Henri
2 SURN SEGURET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 8:49
1 FAMS @7111@
1 BIRT
2 DATE 21 JUN 1913
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @6993@
0 @7054@ INDI
1 NAME Jean-Louis/SABATIER/
2 GIVN Jean-Louis
2 SURN SABATIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 MAR 2018
3 TIME 17:37
1 FAMS @7056@
0 @7057@ INDI
1 NAME Dominique/SABATIER/
2 GIVN Dominique
2 SURN SABATIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 MAR 2018
3 TIME 17:41
1 BIRT
2 DATE 1946
2 _FNA NO
1 FAMC @7056@
0 @7063@ INDI
1 NAME Michel/SABATIER/
2 GIVN Michel
2 SURN SABATIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 AUG 2019
3 TIME 8:08
1 FAMS @7101@
1 BIRT
2 DATE 1948
2 _FNA NO
1 FAMC @7056@
0 @7069@ INDI
1 NAME Christine/SABATIER/
2 GIVN Christine
2 SURN SABATIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 MAR 2018
3 TIME 17:39
1 BIRT
2 DATE 1950
2 _FNA NO
1 FAMC @7056@
0 @7075@ INDI
1 NAME Nathalie/SABATIER/
2 GIVN Nathalie
2 SURN SABATIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 DEC 2018
3 TIME 22:06
1 FAMS @7107@
1 BIRT
2 DATE 1954
2 _FNA NO
1 FAMC @7056@
0 @7081@ INDI
1 NAME Christophe/SABATIER/
2 GIVN Christophe
2 SURN SABATIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 MAR 2018
3 TIME 17:38
1 BIRT
2 DATE 1952
2 _FNA NO
1 FAMC @7056@
0 @7099@ INDI
1 NAME Nicole Marie Françoise/TORCHON/
2 GIVN Nicole Marie Françoise
2 SURN TORCHON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 AUG 2019
3 TIME 8:24
1 FAMS @7101@
1 FAMC @33354@
0 @7106@ INDI
1 NAME Patrice/DOAT/
2 GIVN Patrice
2 SURN DOAT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 DEC 2018
3 TIME 22:06
1 FAMS @7107@
0 @7109@ INDI
1 NAME Gisèle/POUTIGNAT/
2 GIVN Gisèle
2 SURN POUTIGNAT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 8:53
1 FAMS @7111@
1 BIRT
2 DATE 4 MAR 1914
2 PLAC Ambert,63600,Puy-de-Dôme,Auvergne-Rhône-Alpes,FRANCE,
3 MAP
4 LATI N45.550000
4 LONG E3.750000
2 _FNA NO
1 DEAT
2 DATE 19 AUG 1983
2 PLAC Cébazat,63118,Puy-de-Dôme,Auvergne-Rhône-Alpes,FRANCE,
3 MAP
4 LATI N45.834050
4 LONG E3.100480
2 _FNA NO
1 FAMC @16737@
0 @7113@ INDI
1 NAME Joël Didier/SEGURET/
2 GIVN Joël Didier
2 SURN SEGURET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 8:33
1 FAMS @16660@
1 BIRT
2 DATE 8 JAN 1942
2 PLAC Ambert,63600,Puy-de-Dôme,Auvergne-Rhône-Alpes,FRANCE,
3 MAP
4 LATI N45.550000
4 LONG E3.750000
2 _FNA NO
1 FAMC @7111@
0 @7118@ INDI
1 NAME Tanguy Didier Paul/SEGURET/
2 GIVN Tanguy Didier Paul
2 SURN SEGURET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 8:47
1 FAMS @16684@
1 BIRT
2 DATE 22 JAN 1945
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @7111@
0 @7123@ INDI
1 NAME Perrine Didier Élisabeth/SEGURET/
2 GIVN Perrine Didier Élisabeth
2 SURN SEGURET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 8:46
1 BIRT
2 DATE 9 AUG 1943
2 PLAC Ambert,63600,Puy-de-Dôme,Auvergne-Rhône-Alpes,FRANCE,
3 MAP
4 LATI N45.550000
4 LONG E3.750000
2 _FNA NO
1 FAMC @7111@
0 @7127@ INDI
1 NAME Domitille Didier Paule/SEGURET/
2 GIVN Domitille Didier Paule
2 SURN SEGURET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 8:48
1 FAMS @16598@
1 BIRT
2 DATE 15 DEC 1950
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @7111@
0 @7134@ INDI
1 NAME Jean-Pierre/COLMANT/
2 GIVN Jean-Pierre
2 SURN COLMANT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 DEC 2018
3 TIME 22:08
1 FAMS @7136@
0 @7138@ INDI
1 NAME Wilfrid/COLMANT/
2 GIVN Wilfrid
2 SURN COLMANT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 17:51
1 BIRT
2 DATE 1948
2 _FNA NO
1 FAMC @7136@
0 @7144@ INDI
1 NAME Corinne/COLMANT/
2 GIVN Corinne
2 SURN COLMANT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 17:51
1 BIRT
2 DATE 1950
2 _FNA NO
1 FAMC @7136@
0 @7149@ INDI
1 NAME Hugues/COLMANT/
2 GIVN Hugues
2 SURN COLMANT
1 SEX M
1 OCCU banquier
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 MAR 2018
3 TIME 18:11
1 BIRT
2 DATE 1955
2 _FNA NO
1 FAMC @7136@
0 @7156@ INDI
1 NAME Cyrille/COLMANT/
2 GIVN Cyrille
2 SURN COLMANT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 MAR 2018
3 TIME 18:11
1 BIRT
2 DATE 1957
2 _FNA NO
1 FAMC @7136@
0 @7170@ INDI
1 NAME Alix/LEMARCHAND/
2 GIVN Alix
2 SURN LEMARCHAND
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 7:19
1 BIRT
2 DATE 7 MAR 2018
3 TIME 2:15
2 PLAC Marseille,13000,Bouches-du-Rhône,Provence-Alpes-Côte d'Azur,FRANCE,
3 MAP
4 LATI N43.296950
4 LONG E5.381070
2 _FNA NO
1 FAMC @251@
0 @7186@ INDI
1 NAME Monji/ELIATHIME/
2 GIVN Monji
2 SURN ELIATHIME
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 8:09
1 FAMS @7188@
0 @7190@ INDI
1 NAME Bahia/ELIATHIME/
2 GIVN Bahia
2 SURN ELIATHIME
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 9:25
1 BIRT
2 DATE 6 JUN 2014
2 _FNA NO
1 FAMC @7188@
0 @7197@ INDI
1 NAME Matthieu/GILLET/
2 GIVN Matthieu
2 SURN GILLET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 8:56
1 FAMS @7199@
0 @7200@ INDI
1 NAME Gabrielle/GILLET/
2 GIVN Gabrielle
2 SURN GILLET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 9:28
1 BIRT
2 DATE 8 APR 2015
2 _FNA NO
1 FAMC @7199@
0 @7208@ INDI
1 NAME Zoé/TRAPENARD/
2 GIVN Zoé
2 SURN TRAPENARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 8:45
1 BIRT
2 DATE 23 AUG 2014
2 _FNA NO
1 FAMC @6462@
0 @7215@ INDI
1 NAME Tanguy/BLUM/
2 GIVN Tanguy
2 SURN BLUM
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 8:50
1 FAMS @7217@
1 BIRT
2 DATE 12 MAY 1983
2 _FNA NO
0 @7219@ INDI
1 NAME Nino/BLUM/
2 GIVN Nino
2 SURN BLUM
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 9:36
1 BIRT
2 DATE 21 SEP 2013
2 _FNA NO
1 FAMC @7217@
0 @7234@ INDI
1 NAME François/de LAMBERTERIE/
2 GIVN François
2 SURN de LAMBERTERIE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 9:04
1 FAMC @6814@
0 @7239@ INDI
1 NAME Charlotte/MISSOFFE/
2 GIVN Charlotte
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 9:10
1 BIRT
2 DATE 20 JUN 2014
2 _FNA NO
1 FAMC @6729@
0 @7251@ INDI
1 NAME Mathias/XY/
2 GIVN Mathias
2 SURN XY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 9:19
1 FAMS @7253@
0 @7255@ INDI
1 NAME Benjamin/MISSOFFE/
2 GIVN Benjamin
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 10:34
1 BIRT
2 DATE 31 DEC 2004
2 _FNA NO
1 FAMC @5803@
0 @7258@ INDI
1 NAME Zoé/MISSOFFE/
2 GIVN Zoé
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 10:47
1 BIRT
2 DATE 20 APR 2008
2 _FNA NO
1 FAMC @5803@
0 @7269@ INDI
1 NAME Pascale/XX/
2 GIVN Pascale
2 SURN XX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 9:41
1 FAMS @7271@
0 @7274@ INDI
1 NAME Félix/MASSET/
2 GIVN Félix
2 SURN MASSET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 9:40
1 BIRT
2 DATE 4 OCT 2014
2 _FNA NO
1 FAMC @6087@
0 @7282@ INDI
1 NAME Marilyne/MOUKOURI/
2 GIVN Marilyne
2 SURN MOUKOURI
1 SEX F
1 OCCU Styliste
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 11:55
1 FAMS @7284@
0 @7294@ INDI
1 NAME George Olivier Gabriel Victor/LEFEBVRE-DESVALLIERES/
2 GIVN George Olivier Gabriel Victor
2 SURN LEFEBVRE-DESVALLIERES
2 NICK DESVALLIERES
1 SEX M
1 OCCU Artiste Peintre
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 AUG 2019
3 TIME 7:49
1 FAMS @7315@
1 BIRT
2 DATE 14 MAR 1861
2 PLAC Paris 02,75002,Seine,Ile de France,FRANCE,
2 _FNA NO
2 NOTE Propriétaire unique de 14STM après décès de sa mère liquidation-partage le 3+7/07/1906 par Me THERET et HUGUENOT avec soulte de 108043,50 Francs payable en 20 ans
1 DEAT
2 DATE 4 OCT 1950
2 _FNA NO
1 FAMC @7341@
0 @7311@ INDI
1 NAME Désirée Marie "Marguerite"/LEFEBVRE/
2 GIVN Désirée Marie "Marguerite"
2 SURN LEFEBVRE
1 SEX F
1 OCCU Musicienne
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 AUG 2019
3 TIME 13:06
1 FAMS @7315@
1 BIRT
2 DATE 7 JUN 1870
3 TIME 21:00
2 PLAC Paris 9,,,,,24 RUE DU MONT THABOR
2 _FNA NO
1 DEAT
2 DATE 27 APR 1955
2 _FNA NO
1 FAMC @33728@
0 @7321@ INDI
1 NAME Jules-Elie/DELAUNAY/
2 GIVN Jules-Elie
2 SURN DELAUNAY
1 SEX M
1 OCCU Artiste Peintre
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 12:33
0 @7333@ INDI
1 NAME Louis "Emile"/LEFEBVRE-DESVALLIERES/
2 GIVN Louis "Emile"
2 SURN LEFEBVRE-DESVALLIERES
2 NICK DESVALLIERES
1 SEX M
1 OCCU administrateur en chef des Messageries maritimes
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 18:27
1 FAMS @7341@
1 BIRT
2 DATE 13 MAY 1822
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 15 NOV 1918
2 _FNA NO
1 FAMC @7479@
0 @7339@ INDI
1 NAME Marie Élisa/LEGOUVÉ/
2 GIVN Marie Élisa
2 SURN LEGOUVÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 18:27
1 FAMS @7341@
1 BIRT
2 DATE 1835
2 _FNA NO
2 NOTE  Marie, née en 1835, sauvée dans son enfance par le docteur Hahnemann, qui figure sur le monument de Washington élevé en hommage
1 DEAT
2 DATE 17 JAN 1906
2 PLAC Paris 02,,,,,
2 _FNA NO
2 CAUS 14 RUE SAINT MARC
1 FAMC @7403@
0 @7345@ INDI
1 NAME Ernest Joseph Wilfrid/LEGOUVÉ/
2 GIVN Ernest Joseph Wilfrid
2 SURN LEGOUVÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 18:24
1 FAMS @7403@
1 BIRT
2 DATE 1807
2 PLAC Paris 02,75002,Seine,Ile de France,FRANCE,14 rue SAINT MARC
2 _FNA NO
2 NOTE la maison où il était né ; il l’avait reçue de son père, qui lui-même la tenait de sa mère.
3 CONT Il a deux ans à la mort de sa mère et cinq ans à peine lorsque son père meurt·à son tour, qui avait pris soin de le confier à son ami Bouilly lors de la dernière soirée organisée rue Saint-Marc. Au moment du décès de Gabriel Legouvé, c'est toute l'
3 CONC Académie qui veut apporter son soutien à son fils. Mais pour l'heure, Ernest est recueilli par ses grands-parents maternels et sa tante Lucile, qui joue un rôle important dans son éducation.
3 CONT
1 DEAT
2 DATE 13 MAR 1903
2 PLAC Paris 02,75002,Seine,Ile de France,FRANCE,
2 _FNA NO
2 CAUS 14 RUE SAINT MARC
2 NOTE ou le 13? Il meurt dans la maison où il est né, 14 rue Saint-Marc ; ses obsèques eurent lieu en l'église Notre-Dame-des-Victoires où la cérémonie religieuse était présidée par l'évêque de Tarbes, François-Xavier Schoepfer (1843-1927), un ami de la
3 CONC famille. Après la cérémonie, le cercueil a été placé sur un fourgon qui l'a transporté à Seine-Port où il s'était fait construire un caveau de famille. (Le Petit Parisien, 17 mars 1903).
3 CONT
1 NOTE Académie française en 1855, au fauteuil 30 de Lancelot
2 CONT Ernest Le Gouvé (1807-1903), poète, romancier, conférencier, est surtout connu pour ses drames Adrienne Lecouvreur (1849) et Médée (1854), ainsi que ses comédies Les Contes de la reine de Navarre (1850) et Bataille de dames (1851). Reçu à l'Académi
2 CONC e française en 1855, au fauteuil de Lancelot, il a laissé des mémoires Soixante ans de souvenirs (1886-1887) qui sont un témoignage important sur les grands contemporains de son époque.
2 CONT
1 FAMC @8145@
0 @7355@ INDI
1 NAME Sabine/LEFEBVRE-DESVALLIERES/
2 GIVN Sabine
2 SURN LEFEBVRE-DESVALLIERES
2 NICK DESVALLIERES
1 SEX F
1 OCCU broderie d’art
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 AUG 2019
3 TIME 13:35
1 CHR
2 DATE 1891
2 _FNA NO
2 NOTE Sabine filleule de Gustave Moreau
1 BIRT
2 DATE 22 FEB 1891
2 PLAC Paris 02,75002,Seine,Ile de France,FRANCE,14 rue SAINT MARC
2 _FNA NO
1 DEAT
2 DATE 16 FEB 1935
2 PLAC Mazamet,81200,Tarn,Occitanie,FRANCE,
3 MAP
4 LATI N43.490010
4 LONG E2.373040
2 _FNA NO
1 FAMC @7315@
0 @7361@ INDI
1 NAME Richard George/LEFEBVRE-DESVALLIERES/
2 GIVN Richard George
2 SURN LEFEBVRE-DESVALLIERES
2 NICK DESVALLIERES
1 SEX M
1 OCCU Ferronnier d'art
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 NOV 2018
3 TIME 15:15
1 FAMS @7638@
1 BIRT
2 DATE 4 JAN 1893
2 _FNA NO
1 DEAT
2 DATE 1962
2 _FNA NO
1 FAMC @7315@
0 @7366@ INDI
1 NAME Daniel/LEFEBVRE-DESVALLIERES/
2 GIVN Daniel
2 SURN LEFEBVRE-DESVALLIERES
2 NICK DESVALLIERES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 NOV 2018
3 TIME 15:20
1 BIRT
2 DATE 12 MAY 1897
2 _FNA NO
1 DEAT
2 DATE 19 MAR 1915
2 PLAC Reichackerkopf,,,Vosges,,
2 _FNA NO
2 CAUS GUERRE
2 NOTE Le 19 mars, remonté du Sudel, Daniel est tué dans l’attaque du grand Reichackerkopf.
1 NOTE Munier, sous-lieutenant. Adjudant-chef de la 2" compagnie du 6' BTCA,il est le photographe qui a réalisé les clichés de Daniel" et George au départ  de Nice (cf. G,D, et la G,G, fig. 32) et la plupart de ceux du bataillon, avant son évacuation.
2 CONT  il est blessé au pied droit par une bombe, le 1'" juin 1915. Nommé chevalier de la Légion d'honneur le  30 octobre 1915 (JMO),
1 FAMC @7315@
0 @7372@ INDI
1 NAME Marie-Madeleine Renée/LEFEBVRE-DESVALLIERES/
2 GIVN Marie-Madeleine Renée
2 SURN LEFEBVRE-DESVALLIERES
2 NICK DESVALLIERES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 15:19
1 CHR
2 DATE 1908
2 _FNA NO
1 BIRT
2 DATE 30 MAR 1908
2 PLAC Paris 02,,,,,
2 _FNA NO
1 DEAT
2 DATE 2001
2 _FNA NO
1 FAMC @7315@
0 @7377@ INDI
1 NAME Monique Georgina Claire/LEFEBVRE-DESVALLIERES/
2 GIVN Monique Georgina Claire
2 SURN LEFEBVRE-DESVALLIERES
2 NICK DESVALLIERES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 APR 2018
3 TIME 17:05
1 FAMS @7697@
1 BIRT
2 DATE 4 MAR 1911
2 PLAC Paris 02,,,,,
2 _FNA NO
1 DEAT
2 DATE 20 JUL 1979
2 PLAC Espagne,,,,,
2 _FNA NO
2 CAUS accident auto
1 FAMC @7315@
0 @7401@ INDI
1 NAME Georgina/de COURBON-MACKENSIE/
2 GIVN Georgina
2 SURN de COURBON-MACKENSIE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 18:24
1 FAMS @7403@
1 BIRT
2 DATE 1804
2 PLAC Lisbonne,,,,,
2 _FNA NO
1 DEAT
2 DATE 1856
2 _FNA NO
1 FAMC @9318@
0 @7406@ INDI
1 NAME Gabriel Marie Jean-Baptiste/LEGOUVÉ/
2 GIVN Gabriel Marie Jean-Baptiste
2 SURN LEGOUVÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 7:50
1 FAMS @8145@
1 BIRT
2 DATE 23 JUN 1764
2 _FNA NO
2 NOTE enfant d'une famille d'imprimeurs de Montbrison, avocat brillant et dramaturge à ses heures qui termine sa carrière comme conseiller-secrétaire du roi.
3 CONT
1 DEAT
2 DATE 31 AUG 1812
2 PLAC Montmartre,,,,,
2 _FNA NO
2 NOTE OU 30?
3 CONT demeurant à Montmartre près Paris
3 CONT Grièvement blessé par une chute, il n'en mourut que deux ans après. Mort le 30 août 1812?
1 BURI
2 DATE 1 SEP 1812
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 NOTE 1803= fauteuil n°4 du duc de Nivernais
1 FAMC @8114@
0 @7426@ INDI
1 NAME Georgina Jeanne Élisabeth/LEFEBVRE-DESVALLIERES/
2 GIVN Georgina Jeanne Élisabeth
2 SURN LEFEBVRE-DESVALLIERES
2 NICK DESVALLIERES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 16:41
1 FAMS @7437@
1 BIRT
2 DATE 28 MAY 1867
2 _FNA NO
1 DEAT
2 DATE 1936
2 _FNA NO
1 FAMC @7341@
0 @7433@ INDI
1 NAME Emile/PALADILHE/
2 GIVN Emile
2 SURN PALADILHE
1 SEX M
1 OCCU Musicien
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 8:42
1 FAMS @7437@
1 BIRT
2 DATE 3 JUN 1844
2 PLAC Montpellier,34000,Hérault,Occitanie,FRANCE,
3 MAP
4 LATI N43.610920
4 LONG E3.877230
2 _FNA NO
1 DEAT
2 DATE 6 JAN 1926
2 _FNA NO
1 NOTE Musicien, compositeur, il remporte le prix de Rome à 16 ans et rencontre Gounod à la Villa Médicis, qui lui présentera Ernest" Legouvé, le grand-père de  Desvallières,   devient professeur d'harmonie de sa fille, Marie Desvallières ,la mère de Geor
2 CONC gina"  qu'il épouse en 1887
2 CONT Notes individuelles
2 CONT http://www.musimem.com/paladilhe.htm Plus jeune Premier Grand Prix de Rome, trois ans après Bizet et un an avant Théodore Dubois, Emile Paladilhe est né le 3 juin 1844 dans cette antique cité de Montpellier où sa célèbre faculté de médecine accueil
2 CONC lit le futur médecin de l'hôpital du Pont-du-Rhône à Lyon, Rabelais. Son père, docteur en médecine, occupait son temps à des recherches en laboratoire sur la faune marine et avait quelques goûts pour la musique ; il jouait de la flûte. La musique é
2 CONC tait à l'honneur dans cette ville qui avait vu naître, en 1811, Aristide Cavaillé-Coll, fils et petit-fils d'organiers, le dernier du nom et le plus illustre qui sera l'auteur d'instruments de renommée comme ceux de St-Sulpice, Notre-Dame et la Mad
2 CONC eleine. De plus, à cette époque, en 1848, un formidable événement avait attiré sur la ville l'attention des savants : la découverte d'un manuscrit de chant liturgique datant du XI° siècle, par l'organiste de St-Eustache et de Notre-Dame, Félix Danj
2 CONC ou. C'est le fameux "Antiphonaire" qui comporte des mélodies grégoriennes notées en neumes et en lettres.
2 CONT Tout jeune, Paladilhe fut confié pour son éducation au R.P. Dom Sébastien Boixet, organiste de la cathédrale de Montpellier. Lorsqu'il avait bien travaillé, ce prêtre l'emmenait avec lui à la tribune et lui permettait parfois de jouer quelques note
2 CONC s en lui prodiguant des conseils. Etant fort doué pour les études, très rapidement notre jeune garçon, alors âgé d'une dizaine d'années, dépassa son maître qui ne put que conseiller à Paladilhe père d'envoyer son rejeton à Paris, afin de lui permet
2 CONC tre de poursuivre ses études. Après quelques hésitations, il fut décidé que toute la famille irait dans la capitale (père, mère et les deux sœurs) car il était hors de question d'envoyer un gamin de 10 ans seul à Paris !
2 CONT La famille Paladilhe s'installa dans le quartier de Montmartre, à proximité de l'église Saint-Pierre. Inscrit au Conservatoire de Paris, notre jeune homme, accompagné tous les matins par son père, qui lui-même assistait aux cours et prenait des not
2 CONC es, fit de brillantes études. Il eut pour notamment pour professeurs Marmontel pour le piano , Benoist pour l'orgue (le bon "père Benoist") et Halévy, le maître de Gounod, Bizet et Saint-Saëns, pour le contrepoint, la fugue et la composition. Enfan
2 CONC t prodige au piano, Paladilhe étonnait par sa technique parfaite et son jeu si plein de charme. Sa mémoire infaillible l'aidait sans aucun doute dans ses rapides progrès et lui permettait d'emmagasiner beaucoup plus rapidement que les autres tout c
2 CONC e que ses illustres professeurs lui enseignaient. A l'âge de 15 ans, il donnait des concerts salle Henri-Herz, rue de la Victoire , qui emportaient un vif succès. Virtuose hors pair, il était aussi un fort bon compositeur, et à 16 ans, en 1860, il
2 CONC obtint à la fois le prix d'orgue au Conservatoire, et le Premier Grand Prix de Rome, avec sa cantate Ivan IV, devant Adolphe Deslandres et Isidore Legouix.
2 CONT Paladilhe resta trois années à Rome, à la Villa Médicis. Il avait pour compagnons de séjour d'illustres artistes tels que Bizet, les peintres Jean-Jacques Henner et Robert Delaunay, les sculpteurs Jean-Baptiste Carpeaux, Alexandre Falguière et Henr
2 CONC i Chapu, et enfin, Ernest Guiraud, le futur professeur d'harmonie et de composition au Conservatoire de Paris qui exerça une grande influence sur ses élèves, parmi lesquels figure Debussy. Quelle admirable promotion! Paladilhe, esprit curieux et pa
2 CONC ssionné par l'Art sous toutes ses formes vécut ainsi à Rome dans ce milieu on ne peut plus favorable au développement d'une grande sensibilité artistique. Il travailla alors avec beaucoup d'ardeur et dès son retour à Paris il rencontra le succès av
2 CONC ec la représentation, le 14 janvier 1869 à l'Odéon, d'un acte en vers à deux personnages Le Passant, sur un texte de Coppée. Le public fut enthousiasmé, d'autant plus que l'une des deux interprètes n'était autre que Sarah Bernhardt. Trois ans plus
2 CONC tard cette œuvre était donnée à l'Opéra-Comique et la très célèbre Mandolinata, composée alors qu'il était à Rome, devenait la sérénade du Passant chantée par Mme Galli-Marié.
2 CONT Puis ce furent des opéras-comiques: L'Amour Africain (sur une légende de Mérimée, 1875), Suzanne (poème de Lockroy et Cormon, 1879), et Diana (poème de Morand et Régnier, 1885). Ce dernier fit l’objet une critique défavorable mais Paladilhe prit ra
2 CONC pidement sa revanche avec l'opéra Patrie (sur un livret de Sardou et Gallet, 1886) qui eut un énorme succès, et dont le thème avait déjà inspiré Verdi et Bizet. C'est à cette occasion que se manifesta la profonde amitié qui le liera toute sa vie à
2 CONC Gounod, qu'il avait connu à Rome en 1860, et au littérateur Ernest Le Gouvé (1807-1903). Ceux-ci avaient en effet usé de tout leurs poids et influence pour convaincre Sardou de laisser mettre son œuvre en musique. Widor raconte qu'en 1860, lors d'u
2 CONC n séjour à Rome à la Villa Médicis, alors qu'il passait, Gounod entendit, semblant venir de la chambre qu'il avait habitée autrefois une voix chanter l’Aria de son Faust dans le jardin de Marguerite "O nature, c'est là que tu la fis si belle..." Il
2 CONC  s'élança alors, franchit les trois étages, ouvrit brusquement la porte et sauta au cou du chanteur stupéfait : c'était Paladilhe!
2 CONT Le succès de Patrie lui valut son entrée à l'Institut des Beaux-Arts, le 2 juillet 1892, au fauteuil de Guiraud.
2 CONT Vers 1875, sur recommandation de Gounod, Paladilhe devenait le professeur d'harmonie de Mme Desvallières, la propre fille d’Ernest Le Gouvé, membre de l'Académie française, lui-même fils d'un Académicien. Une dizaine d’années plus tard, vers 1889,
2 CONC il épousait Georgina, la fille de son élève qui lui donnera une fille, morte enfant, et un fils, Jean Paladilhe. Par ce mariage, il rentrait dans une illustre famille d'artistes, puisque non seulement les Le Gouvé étaient des gens de lettres, mais
2 CONC son beau-frère n'était autre que le peintre Georges Desvallières.
2 CONT Autographe d'Émile Paladilhe, alors domicilié dans l'immeuble de sa belle-famille, 14 rue Saint-Marc à Paris. Vers 1890. ( coll. Dominique Chailley ) A cette époque, les Le Gouvé habitaient 14 rue St-Marc, dans le deuxième arrondissement parisien,
2 CONC non loin d’ailleurs de la célèbre cantatrice et tragédienne Mme Malibran. Cela faisait deux siècles que cette famille résidait ainsi cette maison située sur la rive droite de la Seine. Depuis bien longtemps, déjà au temps de Gabriel Le Gouvé, les a
2 CONC rtistes se donnaient rendez-vous ici. C’est ainsi qu’on pouvait y croiser Casimir Delavigne, Népomucène Lemercier, Joseph Chénier, Melle Contat, Talma et les artistes des Théâtres de la Nation et de Feydeau... Houdon n'avait-il pas réalisé un super
2 CONC be bronze de son ami le poète Gabriel Le Gouvé, auteur de pièces de théâtre à succès sous la Révolution et le Directoire, qui trônait sur la cheminée du salon? Au temps de Le Gouvé fils ce lieu devint aussi musical et, pendant plus d'un demi-siècle
2 CONC  de grands musiciens le fréquentèrent : Rossini, Meyerbeer, Berlioz, Liszt, Chopin, Halévy, Gounod, Bizet, Saint-Saëns.
2 CONT Ce salon était fort célèbre à Paris et les samedis de la rue St-Marc permettaient aux écrivains et artistes en vogue de venir applaudir les plus illustres chanteurs et instrumentistes. Paladilhe ne pouvait rêver d'une belle-famille aussi parfaite p
2 CONC our l'idéal d'un artiste, d'autant plus qu'il y était choyé et très aimé.
2 CONT Emile Paladilhe en juin 1909, dans la cour du château de Compiègne. ( Musica, juillet 1909 ) Méridional, son indolence était fort trompeuse, car en réalité c’était un homme d'une grande nature, profondément émotif et incapable de bassesse. Il ne su
2 CONC pportait pas la jalousie et fuyait les gens envieux. Fragile dans ses émotions, fidèle dans ses amitiés, il éprouvait de grandes joies à pouvoir parfois, une fois l'été venu, retourner dans son midi où il pouvait rencontrer ses amis, les poètes de
2 CONC langue provençale Théodore Aubanel et Frédéric Mistral. Même en vacances, Paladilhe aimait à se retrouver avec des gens au cœur d'artiste. Tout comme son âme, Paladilhe a un art limpide, ce qui n'empêche nullement la profondeur et l'originalité.
2 CONT Après avoir donné son succès Patrie, il composait de nouveaux opéras : Vanina et Dalila, une symphonie, de nombreuses suites de mélodies, des morceaux pour piano, une quantité de musique sacrée (des motets et des messes) , ainsi qu'un magnifique or
2 CONC atorio, Les Saintes-Marie de la Mer, sur un poème de Louis Gallet, à la demande de l'évêque de Montpellier le Cardinal de Cabrières. Cette œuvre fut donnée en première audition dans l'église St-Denis de Montpellier, en avril 1892, sous la direction
2 CONC  de François Borne. Divisée en quatre parties : Au sépulcre, A Jérusalem, En pleine mer, et En Provence, elle sera donnée par la suite à Paris, notamment par la Société des concerts. Widor nous précise en 1926: " Superbe est le chœur où les voix de
2 CONC s apôtres Jean et Pierre, alternent avec le peuple; émouvante la prière de Marie-Madeleine accompagnée de Marie Jacobé et de Marie Salomé. Le tableau maritime en tête de la troisième partie devrait être au répertoire de nos concerts. "
2 CONT Admirateur de Palestrina, Vittoria et des polyphonistes de la Renaissance, Paladilhe avait acquis à Rome cette sûreté de la technique vocale nécessaire à ses œuvres chantées. Sa Messe de St François d’Assise fut donnée en première audition à l’égli
2 CONC se de St-Eustache le 27 novembre 1896. Dans sa Messe de Pentecôte, le Gloria touche profondément les cœurs par l'appel aux sentiments et l'émotion ressentie à cet instant est très forte. Cependant son art est spontané, son inspiration est profonde
2 CONC et ses mélodies sont expressives.
2 CONT Homme de théâtre, musicien d'église, Paladilhe savait parfaitement adapter ses moyens d'expression à ces deux genres si distants et si proches à la fois. Premier prix d'orgue au Conservatoire, il a toujours regretté de n'avoir pas été organiste dan
2 CONC s une grande église, car il avait toutes les dispositions nécessaires et l'éducation religieuse voulue pour devenir un habile organiste au même titre qu'un Widor, Gigout ou Vierne. Personne ne sait vraiment pourquoi il n'a pu devenir ce musicien d'
2 CONC église, où il aurait été, sans nul doute, un chef d'école avec cet instrument qu'est l'orgue qui est "le seul dont le son, s'il a un commencement, peut n'avoir pas de fin" (Widor), d'autant plus qu'il connaissait mieux que quinconce l’œuvre pour or
2 CONC gue de Bach dont cet instrument est l'expression.
2 CONT Le 6 janvier 1926, alors qu'il terminait un motet sur le texte Tu es Petrus pour Notre-Dame-des-Victoires, sa tête s'affaissa sur la partition: il était mort!
2 CONT Quel dommage, quelle injustice, quelle honte que nous ayons totalement oublié ce musicien au point même d’être ignoré de la plupart des dictionnaires de musique. Si nous pouvions écouter ne serait-ce que son admirable et émouvant Stabat mater, comp
2 CONC osé lors du décès de sa fille en pleine jeunesse, assurément Paladilhe deviendrait un compositeur reconnu de tous; mais qui oserait, de nos jours, donner une œuvre d'un musicien tombé dans l'oubli et dont le public ignore jusqu'au nom ? Celui-là se
2 CONC  grandirait à son tour !   Denis HAVARD de la MONTAGNE
1 FAMC @24052@
0 @7450@ INDI
1 NAME Maurice Ernest George/LEFEBVRE-DESVALLIERES/
2 GIVN Maurice Ernest George
2 SURN LEFEBVRE-DESVALLIERES
2 NICK DESVALLIERES
1 SEX M
1 OCCU auteur dramatique
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 19:22
1 FAMS @7594@
1 BIRT
2 DATE 3 OCT 1857
2 PLAC Paris 02,75002,Seine,Ile de France,FRANCE,
2 _FNA NO
1 DEAT
2 DATE 23 MAR 1926
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 NOTE Œuvres
2 CONT 	•	Le premier bal (1879)
2 CONT 	•	Amis d'enfance (1879)
2 CONT 	•	On demande un ministre ! (1881)
2 CONT 	•	Prête-moi ta femme ! (1884)
2 CONT 	•	Les Fiancés de Loches (1888)
2 CONT 	•	L'Affaire Édouard (1889)
2 CONT 	•	C'est une femme du monde ! (1890)
2 CONT 	•	Le Mariage de Barillon, vaudeville en 3 actes (1890)
2 CONT 	•	Le Ruban (1894)
2 CONT 	•	L'Hôtel du libre échange (1894)
2 CONT 	•	Le truc de Séraphin (1901)
2 CONT 	•	Seine-Port et ses vieilles maisons (1920)
2 CONT 	•	Champignol malgré lui (1959)
1 FAMC @7341@
0 @7459@ INDI
1 NAME Pierre Sulpice/LEFEBVRE-DESVALLIERES/
2 GIVN Pierre Sulpice
2 SURN LEFEBVRE-DESVALLIERES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 19:24
1 FAMS @7479@
1 BIRT
2 DATE 1790
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 1863
2 PLAC Ville-d'Avray,92410,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.823580
4 LONG E2.193110
2 _FNA NO
1 FAMC @7576@
0 @7476@ INDI
1 NAME Louise Jeanne/BARTHELEMY de CAYLUS/
2 GIVN Louise Jeanne
2 SURN BARTHELEMY de CAYLUS
2 NICK CALLUS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 19:35
1 FAMS @7479@
1 BIRT
2 DATE 1800
2 PLAC Montpellier,34000,Hérault,Occitanie,FRANCE,
3 MAP
4 LATI N43.610920
4 LONG E3.877230
2 _FNA NO
1 DEAT
2 DATE 1862
2 _FNA YES
2 NOTE ou 1863?
3 CONT ou 1861?Décès Le 03 février 1861, Montrouveau , Loir-et-Cher archives cote 5MI298/R68 p586?
3 CONT www.culture41.fr
1 NOTE CALLUS OU CAILUS?
1 FAMC @37096@
0 @7492@ INDI
1 NAME Clémentine Marie/DESVALLIERES/
2 GIVN Clémentine Marie
2 SURN DESVALLIERES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 7:59
1 FAMS @9406@
1 BIRT
2 DATE 1819
2 _FNA NO
1 DEAT
2 DATE 1896
2 _FNA NO
1 FAMC @7479@
0 @7502@ INDI
1 NAME Louis/DESVALLIERES/
2 GIVN Louis
2 SURN DESVALLIERES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 13:58
1 BIRT
2 DATE 1824
2 _FNA NO
1 DEAT
2 DATE 1827
2 _FNA NO
1 FAMC @7479@
0 @7513@ INDI
1 NAME Ernest Louis "Prosper"/LEFEBVRE-DESVALLIERES/
2 GIVN Ernest Louis "Prosper"
2 SURN LEFEBVRE-DESVALLIERES
2 NICK DESVALLIERES
1 SEX M
1 OCCU Inspecteur général des monuments historiques
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 19:32
1 FAMS @9549@
1 BIRT
2 DATE 30 JUN 1827
2 PLAC Ville-d'Avray,92410,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.823580
4 LONG E2.193110
2 _FNA NO
1 DEAT
2 DATE 15 DEC 1913
2 PLAC Seine-Port,77240,Seine-et-Marne,Île-de-France,FRANCE,
3 MAP
4 LATI N48.557430
4 LONG E2.553160
2 _FNA NO
1 FAMC @7479@
0 @7534@ INDI
1 NAME Aline/DESVALLIERES/
2 GIVN Aline
2 SURN DESVALLIERES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:00
1 BIRT
2 DATE 1832
2 _FNA NO
1 DEAT
2 DATE 1834
2 _FNA NO
1 FAMC @7479@
0 @7544@ INDI
1 NAME Anatole Marie/DESVALLIERES/
2 GIVN Anatole Marie
2 SURN DESVALLIERES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 20:23
1 FAMS @9586@
1 BIRT
2 DATE 1837
2 _FNA NO
2 NOTE OU 1825?
1 FAMC @7479@
0 @7555@ INDI
1 NAME Claude François/DESVALLIERES/
2 GIVN Claude François
2 SURN DESVALLIERES
2 NICK Lefebvre-Desvallières
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 7:55
1 FAMS @7576@
1 BIRT
2 DATE 1747
2 PLAC Nice,06000,Alpes-Maritimes,Provence-Alpes-Côte d'Azur,FRANCE,
3 MAP
4 LATI N43.703130
4 LONG E7.266080
2 _FNA NO
1 DEAT
2 DATE 1828
2 PLAC Ville-d'Avray,92410,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.823580
4 LONG E2.193110
2 _FNA NO
2 NOTE OU 1800?
1 NOTE ou des VALLIERES
2 CONT Commissaire-priseur,
2 CONT En 1780 grâce à son mariage, succède à son beau-père dans la charge de Directeur des "Messageries Royales" (service public des diligences)
2 CONT Le 19 Thermidor An 6 (6 Aout 1798) il transforme les "Messageries Royales" en "Messageries Nationales".
2 CONT Famille de bourgeois de Paris.
2 CONT Acquisition de la particule récente.
2 CONT
2 CONT Il y a deux versions :
2 CONT
2 CONT
2 CONT
0 @7572@ INDI
1 NAME Madeleine/de FOURQUIER/
2 GIVN Madeleine
2 SURN de FOURQUIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 20 DEC 2019
3 TIME 19:12
1 FAMS @7576@
1 BIRT
2 DATE 1760
2 _FNA NO
0 @7583@ INDI
1 NAME Lucie Clémentine Amélie/BERNAGE/
2 GIVN Lucie Clémentine Amélie
2 SURN BERNAGE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 19:22
1 FAMS @7594@
1 BIRT
2 DATE 1861
2 _FNA NO
1 DEAT
2 DATE 1919
2 _FNA NO
0 @7631@ INDI
1 NAME Antoinette/SAINT-MARC GIRARDIN/
2 GIVN Antoinette
2 SURN SAINT-MARC GIRARDIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 APR 2018
3 TIME 17:04
1 FAMS @7638@
1 BIRT
2 DATE 4 FEB 1900
2 PLAC Paris 18,,,,,
2 _FNA NO
1 DEAT
2 DATE 1996
2 _FNA NO
1 FAMC @10079@
0 @7650@ INDI
1 NAME Louisette/LEFEBVRE-DESVALLIERES/
2 GIVN Louisette
2 SURN LEFEBVRE-DESVALLIERES
2 NICK DESVALLIERES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 6:10
1 FAMS @7722@
1 FAMC @7638@
0 @7656@ INDI
1 NAME Brigitte/LEFEBVRE-DESVALLIERES/
2 GIVN Brigitte
2 SURN LEFEBVRE-DESVALLIERES
2 NICK DESVALLIERES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 17:38
1 FAMS @7738@
1 NOTE Marguerite Lecoeur, nièce de ladite Marguerite Desvallieres;
2 CONT Avait le Beau et fin col en dentelle de L'impératrice Joséphine (attribué à).  offert par Hortense à Euphémie
2 CONT =elle l'avait offert en 1978 à Bertrand et Brigitte lssaverdens
2 CONT vendu en 2018 en SdV de Bordeaux
1 FAMC @7638@
0 @7662@ INDI
1 NAME Domitille Marie Madeleine/LEFEBVRE-DESVALLIERES/
2 GIVN Domitille Marie Madeleine
2 SURN LEFEBVRE-DESVALLIERES
2 NICK DESVALLIERES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 DEC 2018
3 TIME 13:00
1 FAMS @7731@
1 BIRT
2 DATE 12 SEP 1924
2 PLAC Paris 02,,,,,14 rue SAINT MARC
2 _FNA NO
1 FAMC @7638@
0 @7669@ INDI
1 NAME Marc-Olivier/LEFEBVRE-DESVALLIERES/
2 GIVN Marc-Olivier
2 SURN LEFEBVRE-DESVALLIERES
2 NICK DESVALLIERES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 6:11
1 FAMC @7638@
0 @7675@ INDI
1 NAME Barthélémy Jean-Marie/LEFEBVRE-DESVALLIERES/
2 GIVN Barthélémy Jean-Marie
2 SURN LEFEBVRE-DESVALLIERES
2 NICK DESVALLIERES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 DEC 2018
3 TIME 18:12
1 FAMS @7727@
1 BIRT
2 DATE 23 AUG 1929
2 PLAC Paris 02,,,,,14 rue SAINT MARC
2 _FNA NO
1 DEAT
2 DATE 26 APR 2016
2 PLAC Seine-Port,77240,Seine-et-Marne,Île-de-France,FRANCE,
3 MAP
4 LATI N48.557430
4 LONG E2.553160
2 _FNA NO
1 BURI
2 DATE 4 MAY 2016
2 PLAC Seine-Port,77240,Seine-et-Marne,Île-de-France,FRANCE,
3 MAP
4 LATI N48.557430
4 LONG E2.553160
2 _FNA NO
1 FAMC @7638@
0 @7690@ INDI
1 NAME Pierre Noël Antoine/ISORNI/
2 GIVN Pierre Noël Antoine
2 SURN ISORNI
1 SEX M
1 OCCU Artiste Peintre
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 FEB 2019
3 TIME 12:21
1 FAMS @7697@
1 BIRT
2 DATE 26 JUN 1910
2 PLAC Paris 07,,,,,
2 _FNA NO
1 DEAT
2 DATE 1998
2 _FNA NO
1 FAMC @30726@
0 @7700@ INDI
1 NAME Benito Benoit Philippe Antoine/ISORNI/
2 GIVN Benito Benoit Philippe Antoine
2 SURN ISORNI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 12:15
1 BIRT
2 DATE 9 DEC 1934
2 _FNA NO
1 DEAT
2 DATE 23 APR 1987
2 _FNA NO
1 FAMC @7697@
0 @7708@ INDI
1 NAME Théodore Claude Marie/ISORNI/
2 GIVN Théodore Claude Marie
2 SURN ISORNI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 DEC 2018
3 TIME 18:17
1 BIRT
2 DATE 6 OCT 1945
2 PLAC Boulogne-Billancourt,92100,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.833330
4 LONG E2.250000
2 _FNA NO
1 FAMC @7697@
0 @7713@ INDI
1 NAME Flore/ISORNI/
2 GIVN Flore
2 SURN ISORNI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 DEC 2018
3 TIME 18:22
1 FAMS @8374@
1 BIRT
2 DATE 3 JAN 1948
2 PLAC Paris 02,,,,,14 rue SAINT MARC
2 _FNA NO
1 FAMC @7697@
0 @7720@ INDI
1 NAME Jean-Jacques/LAURENT/
2 GIVN Jean-Jacques
2 SURN LAURENT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 6:10
1 FAMS @7722@
0 @7725@ INDI
1 NAME Solange Jeanne Isabelle Marie/LACAN/
2 GIVN Solange Jeanne Isabelle Marie
2 SURN LACAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 DEC 2018
3 TIME 18:14
1 FAMS @7727@
1 BIRT
2 DATE 16 SEP 1929
2 PLAC Arthon,36330,Indre,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N46.693610
4 LONG E1.698570
2 _FNA NO
0 @7729@ INDI
1 NAME Éric Paul Marie Nicolas/ROUSSELLE de la PERRIERE/
2 GIVN Éric Paul Marie Nicolas
2 SURN ROUSSELLE de la PERRIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 DEC 2018
3 TIME 13:00
1 FAMS @7731@
0 @7733@ INDI
1 NAME Bertrand Henri Antoine Marie/ISSAVERDENS/
2 GIVN Bertrand Henri Antoine Marie
2 SURN ISSAVERDENS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 17:41
1 FAMS @7738@
1 BIRT
2 DATE 1914
2 _FNA NO
1 DEAT
2 DATE 2 DEC 2008
2 PLAC Seine-Port,77240,Seine-et-Marne,Île-de-France,FRANCE,
3 MAP
4 LATI N48.557430
4 LONG E2.553160
2 _FNA NO
0 @7739@ INDI
1 NAME Raphaël/ISSAVERDENS/
2 GIVN Raphaël
2 SURN ISSAVERDENS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:31
1 FAMS @7767@
1 FAMC @7738@
0 @7747@ INDI
1 NAME Cyril/ISSAVERDENS/
2 GIVN Cyril
2 SURN ISSAVERDENS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 9:22
1 FAMS @7783@
1 FAMC @7738@
0 @7756@ INDI
1 NAME Axel/ISSAVERDENS/
2 GIVN Axel
2 SURN ISSAVERDENS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:34
1 FAMS @7799@
1 FAMC @7738@
0 @7765@ INDI
1 NAME Colette/FARSA/
2 GIVN Colette
2 SURN FARSA
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:31
1 FAMS @7767@
0 @7769@ INDI
1 NAME Bénédicte/ISSAVERDENS/
2 GIVN Bénédicte
2 SURN ISSAVERDENS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:31
1 FAMC @7767@
0 @7774@ INDI
1 NAME Clémentine/ISSAVERDENS/
2 GIVN Clémentine
2 SURN ISSAVERDENS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:31
1 FAMC @7767@
0 @7781@ INDI
1 NAME Midori/KAKUBARI/
2 GIVN Midori
2 SURN KAKUBARI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 9:21
1 FAMS @7783@
0 @7785@ INDI
1 NAME Akiko/KAKUBARI/
2 GIVN Akiko
2 SURN KAKUBARI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:32
1 FAMC @7783@
0 @7791@ INDI
1 NAME Keiko/KAKUBARI/
2 GIVN Keiko
2 SURN KAKUBARI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:32
1 FAMC @7783@
0 @7797@ INDI
1 NAME Dominique/DAYRAS/
2 GIVN Dominique
2 SURN DAYRAS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:34
1 FAMS @7799@
0 @7801@ INDI
1 NAME Grégory/ISSAVERDENS/
2 GIVN Grégory
2 SURN ISSAVERDENS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:34
1 FAMC @7799@
0 @7806@ INDI
1 NAME Mathieu/ISSAVERDENS/
2 GIVN Mathieu
2 SURN ISSAVERDENS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:34
1 FAMC @7799@
0 @7811@ INDI
1 NAME Benjamin/LAURENT/
2 GIVN Benjamin
2 SURN LAURENT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:42
1 FAMS @7875@
1 BIRT
2 DATE 1947
2 _FNA NO
1 FAMC @7722@
0 @7819@ INDI
1 NAME Marie-Amélie/LAURENT/
2 GIVN Marie-Amélie
2 SURN LAURENT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:32
1 FAMS @7895@
1 BIRT
2 DATE 1948
2 _FNA NO
1 FAMC @7722@
0 @7826@ INDI
1 NAME Hortense/LAURENT/
2 GIVN Hortense
2 SURN LAURENT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 MAR 2018
3 TIME 10:44
1 FAMS @7956@
1 BIRT
2 DATE 1949
2 _FNA NO
1 FAMC @7722@
0 @7833@ INDI
1 NAME Ségolène/LAURENT/
2 GIVN Ségolène
2 SURN LAURENT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:50
1 FAMS @7960@
1 BIRT
2 DATE 1951
2 _FNA NO
1 FAMC @7722@
0 @7841@ INDI
1 NAME François-Théodore/LAURENT/
2 GIVN François-Théodore
2 SURN LAURENT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:54
1 FAMS @7966@
1 BIRT
2 DATE 1952
2 _FNA NO
1 FAMC @7722@
0 @7849@ INDI
1 NAME Jean-Clément/LAURENT/
2 GIVN Jean-Clément
2 SURN LAURENT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:55
1 FAMS @7976@
1 BIRT
2 DATE 1953
2 _FNA NO
1 FAMC @7722@
0 @7856@ INDI
1 NAME Alexis/LAURENT/
2 GIVN Alexis
2 SURN LAURENT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:39
1 BIRT
2 DATE 1954
2 _FNA NO
1 FAMC @7722@
0 @7864@ INDI
1 NAME Adélaïde/LAURENT/
2 GIVN Adélaïde
2 SURN LAURENT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:56
1 FAMS @7991@
1 BIRT
2 DATE 1957
2 _FNA NO
1 FAMC @7722@
0 @7873@ INDI
1 NAME Andrée/BRUSSEL/
2 GIVN Andrée
2 SURN BRUSSEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:42
1 FAMS @7875@
0 @7877@ INDI
1 NAME Achille/LAURENT/
2 GIVN Achille
2 SURN LAURENT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:41
1 FAMC @7875@
0 @7883@ INDI
1 NAME François-Aimé/LAURENT/
2 GIVN François-Aimé
2 SURN LAURENT
1 SEX U
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:41
1 FAMC @7875@
0 @7888@ INDI
1 NAME Philomène/LAURENT/
2 GIVN Philomène
2 SURN LAURENT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:42
1 FAMC @7875@
0 @7893@ INDI
1 NAME Pierre/de BAYSER/
2 GIVN Pierre
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:32
1 FAMS @7895@
1 BIRT
2 DATE 1943
2 _FNA NO
1 FAMC @11575@
0 @7898@ INDI
1 NAME Diane/de BAYSER/
2 GIVN Diane
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:13
1 FAMS @35105@
1 FAMC @7895@
0 @7903@ INDI
1 NAME Nicolas/de BAYSER/
2 GIVN Nicolas
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:14
1 FAMS @35119@
1 FAMC @7895@
0 @7909@ INDI
1 NAME Jean-Thomas/de BAYSER/
2 GIVN Jean-Thomas
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 18:38
1 FAMC @7895@
0 @7914@ INDI
1 NAME Rose/de BAYSER/
2 GIVN Rose
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:16
1 FAMS @35144@
1 FAMC @7895@
0 @7919@ INDI
1 NAME Julie/de BAYSER/
2 GIVN Julie
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:21
1 FAMS @35203@
1 FAMC @7895@
0 @7930@ INDI
1 NAME Angèle/de BAYSER/
2 GIVN Angèle
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:18
1 FAMS @35162@
1 FAMC @7895@
0 @7936@ INDI
1 NAME Yvonne-Valentine/de BAYSER/
2 GIVN Yvonne-Valentine
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:19
1 FAMS @35187@
1 FAMC @7895@
0 @7941@ INDI
1 NAME Jean-Gabriel/de BAYSER/
2 GIVN Jean-Gabriel
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:33
1 FAMC @7895@
0 @7946@ INDI
1 NAME Hubert/de BAYSER/
2 GIVN Hubert
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:33
1 FAMC @7895@
0 @7952@ INDI
1 NAME Philippe/ANDRÉ/
2 GIVN Philippe
2 SURN ANDRÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 MAR 2018
3 TIME 10:44
1 FAMS @7956@
1 DEAT
2 DATE 20 AUG 2013
2 PLAC La Baule-Escoublac,44500,Loire-Atlantique,Pays de la Loire,FRANCE,
3 MAP
4 LATI N47.292210
4 LONG W2.363950
2 _FNA NO
0 @7958@ INDI
1 NAME Michel/ROUILLEAULT/
2 GIVN Michel
2 SURN ROUILLEAULT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:50
1 FAMS @7960@
0 @7964@ INDI
1 NAME Michelle/FRELET/
2 GIVN Michelle
2 SURN FRELET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:54
1 FAMS @7966@
0 @7967@ INDI
1 NAME Sabine/LAURENT/
2 GIVN Sabine
2 SURN LAURENT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:54
1 FAMC @7966@
0 @7974@ INDI
1 NAME Nathalie/GUESNIÉ/
2 GIVN Nathalie
2 SURN GUESNIÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:55
1 FAMS @7976@
0 @7977@ INDI
1 NAME Raphaël/LAURENT/
2 GIVN Raphaël
2 SURN LAURENT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:55
1 FAMC @7976@
0 @7982@ INDI
1 NAME Victoire/LAURENT/
2 GIVN Victoire
2 SURN LAURENT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:55
1 FAMC @7976@
0 @7989@ INDI
1 NAME Luc/MANCHON/
2 GIVN Luc
2 SURN MANCHON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 MAR 2018
3 TIME 14:56
1 FAMS @7991@
0 @8001@ INDI
1 NAME Marie-Jeanne/PALADILHE/
2 GIVN Marie-Jeanne
2 SURN PALADILHE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 15:12
1 BIRT
2 DATE 1888
2 _FNA NO
1 CHR
2 DATE 1888
2 _FNA NO
1 DEAT
2 DATE 1901
2 _FNA NO
2 NOTE Emile compose pour ce décès un STABAT MATER poignant
1 FAMC @7437@
0 @8011@ INDI
1 NAME Jean/PALADILHE/
2 GIVN Jean
2 SURN PALADILHE
1 SEX M
1 OCCU Conservateur du musée Gustave Moreau
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 9:24
1 FAMS @8027@
1 BIRT
2 DATE 1890
2 _FNA NO
1 DEAT
2 DATE 11 FEB 1990
2 _FNA NO
2 NOTE Jean Paladilhe, mort centenaire le 11 février 1990, fut durant près de 60 ans conservateur du Musée Gustave Moreau, à Paris XIX°. Il est le père de Françoise, décédée le 27 février 1992, membre de la chorale de Trajan Popesco et de l'Association El
3 CONC isabeth Havard de la Montagne, Gabriel et Dominique.
1 FAMC @7437@
0 @8023@ INDI
1 NAME Adrienne Marie Armande/LELIEPVRE/
2 GIVN Adrienne Marie Armande
2 SURN LELIEPVRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 9:33
1 FAMS @8027@
1 BIRT
2 DATE 5 JAN 1881
2 PLAC Paris 06,,,,,73 rue notre-dame des champs
2 _FNA NO
1 DEAT
2 DATE 19 APR 1961
2 PLAC Paris 07,,,,,212 bd saint germain
2 _FNA NO
1 NOTE 	•	Naissance: AD 75 Paris VI° vue 10/31 acte n°53
2 CONT 	•	Union: AD 75 Paris VII° vue 14/26 acte n°186
2 CONT 	•	Famille: patber33
2 CONT 	•	Décès: AD 75 Paris VII° vue 14/31 acte n°422
1 FAMC @23981@
0 @8029@ INDI
1 NAME Françoise/PALADILHE/
2 GIVN Françoise
2 SURN PALADILHE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 8:37
1 BIRT
2 DATE 6 JUN 1916
2 PLAC Paris 02,,,,,
2 _FNA NO
1 DEAT
2 DATE 27 FEB 1992
2 _FNA NO
1 FAMC @8027@
0 @8034@ INDI
1 NAME Gabriel/PALADILHE/
2 GIVN Gabriel
2 SURN PALADILHE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 8:38
1 BIRT
2 DATE 1918
2 _FNA NO
1 DEAT
2 DATE 2005
2 _FNA NO
1 FAMC @8027@
0 @8040@ INDI
1 NAME Dominique/PALADILHE/
2 GIVN Dominique
2 SURN PALADILHE
1 SEX M
1 OCCU Illustrateur musical, Historien
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 DEC 2018
3 TIME 12:52
1 FAMS @8057@
1 BIRT
2 DATE 25 OCT 1921
2 PLAC Paris 07,,,,,
2 _FNA NO
1 DEAT
2 DATE 31 OCT 2015
2 PLAC Paris 16,,,,,
2 _FNA NO
1 BURI
2 DATE 6 NOV 2015
2 PLAC Seine-Port,77240,Seine-et-Marne,Île-de-France,FRANCE,
3 MAP
4 LATI N48.557430
4 LONG E2.553160
2 _FNA NO
1 NOTE Dominique. Ecrivain, est notamment l'auteur d'ouvrages à caractère historique : Les très riches heures de Bourgogne, Les Papes en Avignon, Chevaliers de légendes, A pied vers Compostelle, Les grandes heures cathares...
1 FAMC @8027@
0 @8055@ INDI
1 NAME Françoise Pierrette Henriette/LAGRANGE/
2 GIVN Françoise Pierrette Henriette
2 SURN LAGRANGE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 7:52
1 FAMS @8057@
1 DEAT
2 DATE 2012
2 _FNA NO
1 FAMC @23940@
0 @8105@ INDI
1 NAME Claire Françoise/MOTTE/
2 GIVN Claire Françoise
2 SURN MOTTE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2018
3 TIME 8:31
1 FAMS @8114@
1 FAMS @8264@
1 BIRT
2 DATE ABT 1740
2 _FNA NO
2 NOTE ?
1 DEAT
2 DATE 19 DEC 1803
2 PLAC Paris 02,75002,Seine,Ile de France,FRANCE,
2 _FNA NO
2 CAUS 12 PLUVIOSE AN 12
2 NOTE « acte de notoriété dréssé 12 PLUVIOSE AN 12 à défaut d’inventaire après le décès de la dite dame par Me THIRION notaire à PARIS »
1 NOTE La mère du poète descendait de Nicolas-René Quarente Carmeline, chirurgien ordinaire du prince de Condé, et dont il est question dans les lettres de Racine à son fils
1 FAMC @10252@
0 @8112@ INDI
1 NAME Jean-Baptiste/LEGOUVÉ/
2 GIVN Jean-Baptiste
2 SURN LEGOUVÉ
1 SEX M
1 OCCU Avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 NOV 2018
3 TIME 19:19
1 FAMS @8114@
1 BIRT
2 DATE 1722
2 PLAC Montbrison,26770,Drôme,Auvergne-Rhône-Alpes,FRANCE,
3 MAP
4 LATI N44.441480
4 LONG E5.023710
2 _FNA NO
2 NOTE ou 42600?
3 CONT premier Parisien de la famille, né à Montbrison, second enfant de treize frères et sœurs, avait pour père un maître imprimeur et marchand libraire, né lui-même à Lyon. Mais la ville austère, industrieuse et calme ne pouvait pas être le berceau des
3 CONC Legouvé. La famille faisait simplement son tour de France, et c’est ici que mes pressentiments se sont vérifiés. L’aïeul de toute cette lignée, le chef du nom, François, époux de dame Thérèse de Cabannes, n’a pas vu le jour à Montbrison, ni à Lyon,
3 CONC  encore moins à Paris : il était de Mont-de-Marsan
3 CONT
1 DEAT
2 DATE 1782
2 _FNA NO
1 NOTE avocat au Parlement de Paris sous Louis XV, orateur de grand renom, et qui s’était même essayé dans la tragédie en vers, — il n’en fit qu’une, Attilie, imprimée deux fois et jouée une.
1 FAMC @10225@
0 @8138@ INDI
1 NAME Adélaïde Élisabeth/SAUVAN/
2 GIVN Adélaïde Élisabeth
2 SURN SAUVAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 8:29
1 FAMS @8145@
1 FAMS @19871@
1 FAMS @19878@
1 BIRT
2 DATE 1776
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 7 SEP 1809
2 PLAC Paris 02,75002,Seine,Ile de France,FRANCE,14 rue SAINT MARC
2 _FNA NO
2 NOTE Gabriel Legouvé qui pleura sa mort comme pleurent les poètes, en vers :
3 CONT Ici manque, hélas! mon Adèle, Cette femme adorable unie à mes destins; J'ai perdu sans retour ce trésor que je tins De votre bonté paternelle, Et qui me fut plus cher émané de vos mains. Je pleurerai ses vertus et ses charmes Tant que mes tristes y
3 CONC eux pourront verser des larmes,
3 CONT  Où chaque souvenir rend ses droits plus certains.
3 CONT
1 BURI
2 PLAC Seine-Port,77240,Seine-et-Marne,Île-de-France,FRANCE,
3 MAP
4 LATI N48.557430
4 LONG E2.553160
2 _FNA NO
2 NOTE ?
1 NOTE Adèle Sauvan est épouse, mère et arrière-arrière grand-mère d’académiciens français: Gabriel Legouvé (élu en 1803), Ernest Legouvé (élu en 1855) et Louis Pasteur Vallery-Radot (élu en 1944). Elle est en outre épouse et arrière-arrière grand-mère de
2 CONC  membres de l’Académie de médecine : Jean-Joseph II Süe et Louis Pasteur Vallery- Radot.
2 CONT
1 FAMC @19955@
0 @8150@ INDI
1 NAME Gabriel Victor George/LEGOUVÉ/
2 GIVN Gabriel Victor George
2 SURN LEGOUVÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 MAR 2018
3 TIME 14:02
1 BIRT
2 DATE 19 OCT 1837
2 _FNA NO
1 DEAT
2 DATE 22 JAN 1850
2 _FNA YES
2 NOTE OU 1856?
1 FAMC @7403@
0 @8262@ INDI
1 NAME Armand Bernard Honoré/BROUSSE/
2 GIVN Armand Bernard Honoré
2 SURN BROUSSE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 MAR 2018
3 TIME 19:16
1 FAMS @8264@
1 FAMC @10270@
0 @8356@ INDI
1 NAME César/FRANCK/
2 GIVN César
2 SURN FRANCK
1 SEX M
1 OCCU musicien
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 6:18
0 @8365@ INDI
1 NAME Gustave/MOREAU/
2 GIVN Gustave
2 SURN MOREAU
1 SEX M
1 OCCU Artiste Peintre
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 AUG 2019
3 TIME 7:41
1 BIRT
2 DATE 1826
2 _FNA NO
2 NOTE Peintre, il rencontre Desvallières en 1878 et devient son maître alors qu'il étudie aussi avec Jules-Élie Delaunay (1828-1891), qui le lui a présenté, À la mort de ce dernier, le 8 septembre1891, Moreau lui succède comme professeur à l'École des be
3 CONC aux-arts et ses élèves deviennent des proches de Desvallières, malgré la différence d'âge: Henri Matisse (1869-1954), Georges Rouault" René Piot" et tous les autres, avec lesquels il mènera l'aventure du Salon d'automne, En 1889, Moreau est le témo
3 CONC in de George à son mariage et, en IB91. il devient le parrain de sa première fille Sabine", Des liens étroits tant professionnels que privés demeurent jusqu'à la mort du maître
3 CONT Toute l'œuvre décorative, profane ou religieuse de l'élève reste empreinte des apports de cette interprétation de la peinture selon Gustave Moreau. Desvallières, son légataire universel et exécuteur testamentaire, jouera un grand rôle dans l'organi
3 CONC sation et la pérennité du musée Gustave-Moreau. avec Henri Rupp' (jusqu'en 1918) et Georges Rouault", conservateur de 1902 à 1928. date à laquelle il le remplace après sa démission, jusqu'en 1950
1 DEAT
2 DATE 18 APR 1898
2 PLAC Paris 09,,,,,
2 _FNA NO
2 NOTE Rupp, Henri (1837-1918).  . Élève, ami et légataire universel de Gustave Moreau·, il est un proche de la famille Desvallières. Après la  mort de Moreau, il a dressé l'inventaire en présence de Desvallières, exécuteur testamentaire, son meilleur con
3 CONC seiller et ami, Leur amitié, soudée autour de la défense de leur cher maître et dans la réalisation de son musée, rue de La Rochefoucauld, à Paris, s'étend à chaque enfant, dont il devient
3 CONT le confident, Intime, il a sa chambre à Seine-Port.
3 CONT
0 @8373@ INDI
1 NAME José/SOLIS CARO/
2 GIVN José
2 SURN SOLIS CARO
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 DEC 2018
3 TIME 18:22
1 FAMS @8374@
0 @8422@ INDI
1 NAME Jean-Loup/POUTIGNAT/
2 GIVN Jean-Loup
2 SURN POUTIGNAT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 8:59
1 FAMS @8429@
1 BIRT
2 DATE 1915
2 _FNA NO
1 FAMC @16737@
0 @8427@ INDI
1 NAME Camille/BOIREL/
2 GIVN Camille
2 SURN BOIREL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 8:59
1 FAMS @8429@
0 @8527@ INDI
1 NAME François-Joseph/LACREUSETTE/
2 GIVN François-Joseph
2 SURN LACREUSETTE
1 SEX M
1 OCCU coiffeur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 8:54
1 FAMS @8539@
1 DEAT
2 DATE 1868
2 _FNA NO
0 @8536@ INDI
1 NAME Marianne Cornélie/CHABAL/
2 GIVN Marianne Cornélie
2 SURN CHABAL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 8:51
1 FAMS @8539@
1 DEAT
2 DATE 1868
2 _FNA NO
0 @8546@ INDI
1 NAME L/BONNARD/
2 GIVN L
2 SURN BONNARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 10:18
0 @8604@ INDI
1 NAME Joseph Henry/MESTADIER/
2 GIVN Joseph Henry
2 SURN MESTADIER
1 SEX M
1 OCCU Prêtre
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 19:51
1 FAMS @8821@
1 BIRT
2 DATE 5 NOV 1803
3 TIME 23:00
2 PLAC Le Pin,17210,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.316670
4 LONG W0.283330
2 _FNA NO
2 NOTE SAINT DENIS DU PIN
1 BURI
2 DATE 1884
2 PLAC Nuaillé-sur-Boutonne,17470,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.013040
4 LONG W0.435040
2 _FNA NO
2 NOTE dans le caveau avec lubin et joséphine
1 DEAT
2 DATE 2 APR 1884
3 TIME 1:00
2 PLAC Nuaillé-sur-Boutonne,17470,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.013040
4 LONG W0.435040
2 _FNA NO
2 NOTE bureau enregistrement d’AULNAY tables 3Q946
3 CONT déclaration de succession 01/09/1884 n° 302
3 CONT RMD 3Q1298= testament
1 FAMC @8628@
0 @8616@ INDI
1 NAME Hélie Joseph/MESTADIER/
2 GIVN Hélie Joseph
2 SURN MESTADIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:07
1 FAMS @8628@
1 FAMS @13049@
1 BIRT
2 DATE 15 OCT 1767
2 PLAC Saint-Denis-du-Pin,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.983330
4 LONG W0.516670
2 _FNA NO
1 FAMC @13165@
0 @8625@ INDI
1 NAME Anne Rosalie Sophie/BIENVILLE de MESCINET/
2 GIVN Anne Rosalie Sophie
2 SURN BIENVILLE de MESCINET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 18:45
1 FAMS @8628@
1 BIRT
2 DATE 1778
2 _FNA NO
1 DEAT
2 DATE 1856
2 _FNA NO
1 FAMC @13099@
0 @8637@ INDI
1 NAME Michel Jean Joseph/MESTADIER/
2 GIVN Michel Jean Joseph
2 SURN MESTADIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 18:15
1 BIRT
2 DATE 28 SEP 1801
2 PLAC Saint-Denis-du-Pin,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.983330
4 LONG W0.516670
2 _FNA NO
1 FAMC @8628@
0 @8670@ INDI
1 NAME Louise/GOIZIN/
2 GIVN Louise
2 SURN GOIZIN
1 SEX F
1 _FIL ADOPTED_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 18:34
1 FAMS @8680@
1 BIRT
2 DATE 6 JUL 1800
2 PLAC Ardillières,17290,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.058780
4 LONG W0.892720
2 _FNA NO
1 DEAT
2 DATE 12 MAY 1878
2 PLAC Migré,17330,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.074000
4 LONG W0.557390
2 _FNA NO
1 FAMC @11648@
0 @8675@ INDI
1 NAME Louis/BUFFETEAU/
2 GIVN Louis
2 SURN BUFFETEAU
1 SEX M
1 OCCU cultivateur
1 _FIL ADOPTED_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 18:34
1 FAMS @8680@
1 BIRT
2 DATE 30 NOV 1800
2 PLAC Migré,17330,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.074000
4 LONG W0.557390
2 _FNA NO
1 DEAT
2 DATE 7 JUN 1887
2 PLAC Migré,17330,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.074000
4 LONG W0.557390
2 _FNA NO
2 NOTE adopte lubin en 1835
1 NOTE et sacristain de père en fils
1 FAMC @11629@
0 @8699@ INDI
1 NAME Jean/PAILLÉ/
2 GIVN Jean
2 SURN PAILLÉ
1 SEX M
1 OCCU cultivateur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:40
1 FAMS @8718@
1 BIRT
2 DATE 1798
2 PLAC Nuaillé-sur-Boutonne,17470,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.013040
4 LONG W0.435040
2 _FNA NO
2 NOTE ou 1799?
1 DEAT
2 DATE 11 FEB 1878
2 PLAC Nuaillé-sur-Boutonne,17470,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.013040
4 LONG W0.435040
2 _FNA NO
1 FAMC @13369@
0 @8710@ INDI
1 NAME Marie Madeleine Agathe/DOIGNON/
2 GIVN Marie Madeleine Agathe
2 SURN DOIGNON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:57
1 FAMS @8718@
1 BIRT
2 DATE 3 JAN 1799
2 PLAC Migré,17330,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.074000
4 LONG W0.557390
2 _FNA NO
1 DEAT
2 DATE 26 SEP 1882
2 PLAC Nuaillé-sur-Boutonne,17470,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.013040
4 LONG W0.435040
2 _FNA NO
1 FAMC @13480@
0 @8726@ INDI
1 NAME Auguste/COMBEAU/
2 GIVN Auguste
2 SURN COMBEAU
1 SEX M
1 OCCU sabotier
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 19:53
1 BIRT
2 DATE CAL 1827
2 _FNA NO
0 @8734@ INDI
1 NAME Joseph/MOREAU/
2 GIVN Joseph
2 SURN MOREAU
1 SEX M
1 OCCU instituteur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 19:54
1 BIRT
2 DATE CAL 1824
2 _FNA NO
0 @8740@ INDI
1 NAME Jean/PAILLE/
2 GIVN Jean
2 SURN PAILLE
1 SEX M
1 OCCU cultivateur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 19:30
1 BIRT
2 DATE CAL 1828
2 _FNA NO
0 @8746@ INDI
1 NAME François/PAILLE/
2 GIVN François
2 SURN PAILLE
1 SEX M
1 OCCU cultivateur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 19:57
1 BIRT
2 DATE CAL 1804
2 _FNA NO
0 @8759@ INDI
1 NAME Jean-Louis/PAIN/
2 GIVN Jean-Louis
2 SURN PAIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 19:59
0 @8763@ INDI
1 NAME Amélie/GABORIAUD/
2 GIVN Amélie
2 SURN GABORIAUD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 20:00
0 @8772@ INDI
1 NAME Eugène/DELAUNAY/
2 GIVN Eugène
2 SURN DELAUNAY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 12:22
1 NOTE
2 CONT
0 @8776@ INDI
1 NAME Amélie/DELAUNAY/
2 GIVN Amélie
2 SURN DELAUNAY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 20:03
0 @8787@ INDI
1 NAME Jean/GANDELIN/
2 GIVN Jean
2 SURN GANDELIN
1 SEX M
1 OCCU Tonnelier
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 12:29
1 FAMS @8800@
1 BIRT
2 DATE 1803
2 _FNA NO
1 BURI CAVEAU DES MISSOFFE
2 PLAC Nuaillé-sur-Boutonne,17470,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.013040
4 LONG W0.435040
2 _FNA NO
0 @8796@ INDI
1 NAME Madeleine/LANTIER/
2 GIVN Madeleine
2 SURN LANTIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 12:29
1 FAMS @8800@
1 BIRT
2 DATE 1808
2 _FNA NO
1 BURI CAVEAU DES MISSOFFE
2 PLAC Nuaillé-sur-Boutonne,17470,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.013040
4 LONG W0.435040
2 _FNA NO
0 @8810@ INDI
1 NAME Louise Laure/DEMISSY/
2 GIVN Louise Laure
2 SURN DEMISSY
1 SEX F
1 _FIL NATURAL_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 20:33
1 FAMS @8821@
1 BIRT
2 DATE 1804
2 PLAC La Rochelle,17000,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.166670
4 LONG W1.150000
2 _FNA NO
1 DEAT
2 DATE 1871
2 PLAC La Rochelle,17000,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.166670
4 LONG W1.150000
2 _FNA NO
1 FAMC @8858@
0 @8825@ INDI
1 NAME Samuel Pierre David Joseph/DEMISSY/
2 GIVN Samuel Pierre David Joseph
2 SURN DEMISSY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 20:44
1 FAMS @8858@
1 BIRT
2 DATE 1755
2 PLAC La Rochelle,17000,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.166670
4 LONG W1.150000
2 _FNA NO
1 DEAT
2 DATE 3 OCT 1820
2 PLAC La Rochelle,17000,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.166670
4 LONG W1.150000
2 _FNA NO
1 FAMC @8880@
0 @8854@ INDI
1 NAME Marie Louise Ester/LIEGE/
2 GIVN Marie Louise Ester
2 SURN LIEGE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 20:44
1 FAMS @8858@
0 @8864@ INDI
1 NAME Samuel Pierre/DEMISSY/
2 GIVN Samuel Pierre
2 SURN DEMISSY
1 SEX M
1 OCCU Négociant
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 19:41
1 FAMS @8880@
1 BIRT
2 DATE 1755
2 PLAC La Rochelle,17000,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.166670
4 LONG W1.150000
2 _FNA NO
1 DEAT
2 DATE 1820
2 PLAC La Rochelle,17000,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.166670
4 LONG W1.150000
2 _FNA NO
0 @8877@ INDI
1 NAME Marie Anne/FRAIGNEAU/
2 GIVN Marie Anne
2 SURN FRAIGNEAU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 20:35
1 FAMS @8880@
0 @8887@ INDI
1 NAME Louis Benjamin/FLEURIAU de BELLEVUE/
2 GIVN Louis Benjamin
2 SURN FLEURIAU de BELLEVUE
1 SEX M
1 OCCU MEMBRE DU CONSEIL GENERAL
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 20:37
1 BIRT
2 DATE CAL 1761
2 _FNA NO
0 @8897@ INDI
1 NAME Pierre François/GIGAUX/
2 GIVN Pierre François
2 SURN GIGAUX
1 SEX M
1 OCCU commissaire du roi près de la monnaie
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 20:39
1 BIRT
2 DATE CAL 1772
2 _FNA NO
0 @8907@ INDI
1 NAME Amélie/DEMISSY/
2 GIVN Amélie
2 SURN DEMISSY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 20:42
1 FAMS @8924@
1 FAMC @8858@
0 @8918@ INDI
1 NAME André Jacques Élisabeth/LAFONT/
2 GIVN André Jacques Élisabeth
2 SURN LAFONT
1 SEX M
1 OCCU maréchal de camp
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 MAR 2018
3 TIME 20:42
1 FAMS @8924@
0 @8933@ INDI
1 NAME Laetitia/de LA CELLE-LESBRE/
2 GIVN Laetitia
2 SURN de LA CELLE-LESBRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 20:04
1 BIRT
2 DATE 15 MAR 2018
3 TIME 0:28
2 PLAC Rouen,76000,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.443130
4 LONG E1.099320
2 _FNA NO
1 CHR
2 DATE 10 JUN 2018
2 PLAC Saint-Martin-de-Boscherville,76840,Seine-Maritime,Normandie,FRANCE,ABBATIALE SAINT GEORGES
3 MAP
4 LATI N49.443770
4 LONG E0.962710
2 _FNA NO
1 FAMC @241@
0 @8945@ INDI
1 NAME Noé/MORET-LOUBERT/
2 GIVN Noé
2 SURN MORET-LOUBERT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 NOV 2018
3 TIME 18:35
1 BIRT
2 DATE 14 MAR 2018
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @4688@
0 @8979@ INDI
1 NAME Romain/COLLONNIER/
2 GIVN Romain
2 SURN COLLONNIER
1 SEX M
1 OCCU Clerc
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 MAR 2018
3 TIME 9:31
0 @8984@ INDI
1 NAME Bernard/COLLONNIER/
2 GIVN Bernard
2 SURN COLLONNIER
1 SEX M
1 OCCU Médecin
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 MAR 2018
3 TIME 9:42
1 FAMS @8996@
0 @8992@ INDI
1 NAME Maguelone/CHABBERT/
2 GIVN Maguelone
2 SURN CHABBERT
1 SEX F
1 OCCU Enseignante
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 MAR 2018
3 TIME 9:42
1 FAMS @8996@
0 @8999@ INDI
1 NAME Romain/COLLONNIER/
2 GIVN Romain
2 SURN COLLONNIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JUL 2019
3 TIME 9:05
1 FAMS @32281@
1 BIRT
2 DATE 1975
2 _FNA NO
1 FAMC @8996@
0 @9004@ INDI
1 NAME Thibaut/COLLONNIER/
2 GIVN Thibaut
2 SURN COLLONNIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JUL 2019
3 TIME 8:58
1 FAMS @32257@
1 BIRT
2 DATE 1979
2 _FNA NO
1 FAMC @8996@
0 @9016@ INDI
1 NAME Stéphane/DUCUING/
2 GIVN Stéphane
2 SURN DUCUING
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 MAR 2018
3 TIME 10:05
1 FAMS @9023@
0 @9021@ INDI
1 NAME Véronique/GIRAUD/
2 GIVN Véronique
2 SURN GIRAUD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 MAR 2018
3 TIME 10:05
1 FAMS @9023@
0 @9026@ INDI
1 NAME Nathalie/DUCUING/
2 GIVN Nathalie
2 SURN DUCUING
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 MAR 2018
3 TIME 9:54
1 FAMC @9023@
0 @9031@ INDI
1 NAME Valérie/DUCUING/
2 GIVN Valérie
2 SURN DUCUING
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 MAR 2018
3 TIME 10:15
1 FAMC @9023@
0 @9036@ INDI
1 NAME Sophie/DUCUING/
2 GIVN Sophie
2 SURN DUCUING
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 MAR 2018
3 TIME 9:54
1 FAMC @9023@
0 @9041@ INDI
1 NAME Guillaume/DUCUING/
2 GIVN Guillaume
2 SURN DUCUING
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 MAR 2018
3 TIME 10:08
1 FAMC @9023@
0 @9094@ INDI
1 NAME Camille/GUILLEN/
2 GIVN Camille
2 SURN GUILLEN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 MAR 2018
3 TIME 10:12
0 @9114@ INDI
1 NAME Alain/ROUSSELIN/
2 GIVN Alain
2 SURN ROUSSELIN
1 SEX M
1 OCCU Ingénieur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 MAR 2018
3 TIME 10:19
0 @9119@ INDI
1 NAME Isabelle/MARTEL/
2 GIVN Isabelle
2 SURN MARTEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 MAR 2018
3 TIME 6:41
1 FAMS @10178@
0 @9126@ INDI
1 NAME Xavier Pierre Marie/de LA CELLE-LESBRE/
2 GIVN Xavier Pierre Marie
2 SURN de LA CELLE-LESBRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 SEP 2019
3 TIME 11:59
1 FAMS @9137@
1 BIRT
2 DATE 1 DEC 1948
2 PLAC Blois,41000,Loir-et-Cher,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N47.583330
4 LONG E1.333330
2 _FNA NO
1 FAMC @32665@
0 @9135@ INDI
1 NAME Laurence/de SEZE/
2 GIVN Laurence
2 SURN de SEZE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 SEP 2019
3 TIME 11:59
1 FAMS @9137@
1 BIRT
2 DATE 1949
2 _FNA NO
1 DEAT
2 DATE 22 JUL 2009
2 _FNA NO
1 FAMC @32706@
0 @9154@ INDI
1 NAME Pascale/CORDIER/
2 GIVN Pascale
2 SURN CORDIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 MAR 2018
3 TIME 10:34
0 @9167@ INDI
1 NAME Lidwine/SILIE/
2 GIVN Lidwine
2 SURN SILIE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 MAR 2018
3 TIME 10:37
0 @9183@ INDI
1 NAME Pierre/ADAM/
2 GIVN Pierre
2 SURN ADAM
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 JUL 2018
3 TIME 11:34
1 FAMS @9192@
1 FAMC @9199@
0 @9190@ INDI
1 NAME Sophie/DIETSCHY/
2 GIVN Sophie
2 SURN DIETSCHY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 JUL 2018
3 TIME 11:34
1 FAMS @9192@
1 FAMC @11325@
0 @9196@ INDI
1 NAME Pierre Harel/ADAM/
2 GIVN Pierre Harel
2 SURN ADAM
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 MAR 2018
3 TIME 14:21
1 FAMS @9199@
0 @9208@ INDI
1 NAME John/MACKENSIE/
2 GIVN John
2 SURN MACKENSIE
1 SEX M
1 OCCU COLONEL
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 7:34
1 FAMS @9318@
1 DEAT
2 DATE 15 JAN 1809
2 PLAC La Corogne,,,,,
2 _FNA NO
2 CAUS BATAILLE NAPOLEON
1 NOTE colonel écossais John Mackenzie, qui a combattu les troupes napoléoniennes en Espagne où il est mort
0 @9303@ INDI
1 NAME André/PIOT/
2 GIVN André
2 SURN PIOT
1 SEX M
1 OCCU POETE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 MAR 2018
3 TIME 21:26
1 BIRT
2 DATE 1894
2 _FNA NO
1 DEAT
2 DATE 1974
2 _FNA NO
0 @9316@ INDI
1 NAME Sophie/de LA ROCHE-COURBON/
2 GIVN Sophie
2 SURN de LA ROCHE-COURBON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 7:36
1 FAMS @9318@
1 FAMS @9324@
0 @9322@ INDI
1 NAME ?/THELLIER/
2 GIVN ?
2 SURN THELLIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 7:34
1 FAMS @9324@
0 @9325@ INDI
1 NAME Flavie/THELLIER/
2 GIVN Flavie
2 SURN THELLIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 MAR 2018
3 TIME 12:36
1 FAMC @9324@
0 @9331@ INDI
1 NAME Esthelle/THELLIER/
2 GIVN Esthelle
2 SURN THELLIER
1 SEX U
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 MAR 2018
3 TIME 12:36
1 FAMC @9324@
0 @9341@ INDI
1 NAME Jean-Baptiste/SAUVAN/
2 GIVN Jean-Baptiste
2 SURN SAUVAN
1 SEX M
1 OCCU intendant
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2018
3 TIME 11:32
1 FAMS @19955@
1 BIRT
2 DATE 1736
2 PLAC Méailles,04240,Alpes-de-Haute-Provence,Provence-Alpes-Côte d'Azur,FRANCE,
3 MAP
4 LATI N44.022680
4 LONG E6.628820
2 _FNA NO
1 DEAT
2 DATE 1818
2 _FNA NO
1 NOTE Jean-Baptiste Sauvan, « contrôleur du mobilier des châteaux du duc d’Orléans, tant à Paris que dans les provinces »
2 CONT intendant du duc d'Orléans en 1789, doit affronter le tribunal révolutionnaire tandis que sa fille Adèle (1776-1809) se fiance au chef des girondins Vergniaud.
2 CONT Origine de la fortune de Jean-Baptiste Sauvan = C. Vatel, avocat à la cour de Paris, auteur de Vergniaud, manuscrits, lettres et Papiers, 1873, J-B Dumoulin éditeur,  dresse de Sauvan un portrait qui montre sa très rapide ascension sociale.
2 CONT Jean-Baptiste Sauvan est parti de la condition la plus humble, et est parvenu par son intelligence à une fortune considérable. Il avait d'abord été maître d'hôtel du duc de Chartres à Mousseaux, puis directeur des menus. Sa femme était mercière et
2 CONC marchande de soie rue Saint-Honoré près le palais Royal. A la mort du duc d'Orléans, en 1785, il devint contrôleur de la bouche au Palais Royal, et en 1790, le duc d'Orléans à son retour d'Angleterre le nomma "contrôleur du mobilier de ses châteaux
2 CONC , tant à Paris que dans ses maisons de campagne". Indépendamment de ses emplois lucratifs, Sauvan avait amassé une fortune qui était encore en 1794 de 350 000 Fr. ; il avait, suivant ses contemporains, l'esprit de la spéculation et avait su conquér
2 CONC ir la confiance de son maître « qui ne trouvait rien de bien que ce qui était fait par lui ».
2 CONT Cette faveur devait lui attirer des persécutions équivalentes, au moment où l'ère de la popularité cessa pour le parti orléaniste avec la défection de Dumouriez.
2 CONT
2 CONT
0 @9347@ INDI
1 NAME Lucile/SAUVAN/
2 GIVN Lucile
2 SURN SAUVAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 NOV 2018
3 TIME 18:52
1 BIRT
2 DATE 23 MAR 1784
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 10 JAN 1867
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 BURI
2 DATE 15 JAN 1867
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,PERE LACHAISE
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
2 NOTE A ses funérailles, au cimetière du père Lachaise, en janvier 1867, défilèrent toutes les élèves des écoles publiques de la capitale. chemin Labédoyère, 16e division près du rond Casimir Perrier, au pied du mur où se trouve la sépulture Dalloz
3 CONT
1 NOTE sœur d'Adèle, Lucile (1784-1867), devient, sous le règne de Napoléon III, la première inspectrice des écoles de Paris
2 CONT « Lucile Sauvan ... appartenait à une famille honorable, qui avait traversé la corruption du XVIIIe siècle, sans être atteinte dans la pureté de ses mœurs et dans sa probité antique. Son père, qui jouissait d'une très belle fortune pour ce temps-là
2 CONC , était l'homme de confiance d'une famille princière, bientôt réduite comme beaucoup d'autres d'un moindre rang, à prendre le chemin de l'exil... Lucile Sauvan ne fit donc qu'entrevoir la situation presque opulente de ses parents, dont elle était l
2 CONC a septième enfant... Elle avait gardé, jusque dans l'âge le plus avancé, un culte pieux et tendre pour ses premières impressions d'enfant, et dans ses retours vers le passé, elle aimait rappeler le souvenir des joies intimes de la famille.
2 CONT Il nous a été donné d'en contempler l'image. Greuze, qui y était reçu à titre d'ami, en a fait le sujet d'un tableau d'une vérité charmante.
2 CONT
1 FAMC @19955@
0 @9377@ INDI
1 NAME Joseph Emile/LEFEBVRE/
2 GIVN Joseph Emile
2 SURN LEFEBVRE
1 SEX M
1 OCCU Employé de commerce
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 OCT 2019
3 TIME 8:45
1 FAMS @33728@
1 BIRT
2 DATE 10 APR 1838
2 PLAC Paris 02,75002,Seine,Ile de France,FRANCE,
2 _FNA NO
1 DEAT
2 DATE 1897
2 _FNA NO
1 FAMC @33802@
0 @9382@ INDI
1 NAME Claire Émilie/LEFEBVRE/
2 GIVN Claire Émilie
2 SURN LEFEBVRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 AUG 2019
3 TIME 13:32
1 FAMS @9390@
1 BIRT
2 DATE 28 MAR 1873
3 TIME 2:00
2 PLAC Paris 09,,,,,26 RUE PIGALLE
2 _FNA NO
1 DEAT
2 DATE 25 JAN 1940
2 PLAC Paris 15,,,,,60 RUE VIOLET
2 _FNA NO
1 FAMC @33728@
0 @9388@ INDI
1 NAME Jacques Lucien/REMOND/
2 GIVN Jacques Lucien
2 SURN REMOND
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 OCT 2019
3 TIME 8:46
1 FAMS @9390@
1 BIRT
2 DATE 1874
2 _FNA YES
1 NOTE travaille à la réglementation des réseaux de chemins de fer, dans le nord
0 @9399@ INDI
1 NAME Ernest-Louis/FIDIERE des PRINVEAUX/
2 GIVN Ernest-Louis
2 SURN FIDIERE des PRINVEAUX
1 SEX M
1 OCCU Juge d'instruction
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 7:59
1 FAMS @9406@
1 BIRT
2 DATE 1812
2 _FNA NO
2 NOTE OU 1818?
1 DEAT
2 DATE 1888
2 _FNA NO
0 @9407@ INDI
1 NAME Marie/FIDIERE des PRINVEAUX/
2 GIVN Marie
2 SURN FIDIERE des PRINVEAUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 MAR 2018
3 TIME 18:14
1 BIRT
2 DATE 1839
2 _FNA NO
1 DEAT
2 DATE 1839
2 _FNA NO
1 FAMC @9406@
0 @9417@ INDI
1 NAME Aline/FIDIERE des PRINVEAUX/
2 GIVN Aline
2 SURN FIDIERE des PRINVEAUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 MAR 2018
3 TIME 18:31
1 FAMS @9515@
1 BIRT
2 DATE 1840
2 _FNA NO
1 DEAT
2 DATE 1898
2 _FNA NO
1 FAMC @9406@
0 @9428@ INDI
1 NAME Gaston/FIDIERE des PRINVEAUX/
2 GIVN Gaston
2 SURN FIDIERE des PRINVEAUX
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 MAR 2018
3 TIME 18:26
1 FAMS @9487@
1 BIRT
2 DATE 1845
2 _FNA NO
1 DEAT
2 DATE 1910
2 _FNA NO
1 FAMC @9406@
0 @9438@ INDI
1 NAME Octave Marie/FIDIERE des PRINVEAUX/
2 GIVN Octave Marie
2 SURN FIDIERE des PRINVEAUX
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 8:51
1 FAMS @9453@
1 BIRT
2 DATE 3 MAY 1855
2 PLAC Versailles,78000,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.800000
4 LONG E2.133330
2 _FNA NO
1 DEAT
2 DATE 2 MAY 1904
2 PLAC Pau,64000,Pyrénées-Atlantiques,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N43.300000
4 LONG W0.366670
2 _FNA NO
1 NOTE études de droit, et s'était adonné un temps  la peinture. Au moment de son mariage, Octave était attaché de 4e classe à la conservation du musée du Luxembourg, critique d'art, et fit la monographies du sculpteur Chapu. Il contribua à l'enrichisseme
2 CONC nt du Musée de Saint-Nazaire
1 FAMC @9406@
0 @9449@ INDI
1 NAME Jeanne Louise Eulalie/BORD/
2 GIVN Jeanne Louise Eulalie
2 SURN BORD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 8:56
1 FAMS @9453@
1 BIRT
2 DATE 5 FEB 1864
2 PLAC Boulogne-sur-Mer,62200,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.716670
4 LONG E1.616670
2 _FNA NO
1 DEAT
2 DATE 22 JAN 1960
2 PLAC Paris 07,,,,,122 RUE DE GRENELLE
2 _FNA NO
1 FAMC @31476@
0 @9454@ INDI
1 NAME Bertrand/FIDIERE des PRINVEAUX/
2 GIVN Bertrand
2 SURN FIDIERE des PRINVEAUX
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 8:52
1 BIRT
2 DATE 1885
2 _FNA NO
1 NOTE Bertrand, dont on ne sait rien, probablement mort jeune
1 FAMC @9453@
0 @9461@ INDI
1 NAME Anne Marie/FIDIERE des PRINVEAUX/
2 GIVN Anne Marie
2 SURN FIDIERE des PRINVEAUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 8:54
1 FAMS @9471@
1 BIRT
2 DATE 1886
2 _FNA NO
1 FAMC @9453@
0 @9469@ INDI
1 NAME André/BOURDET/
2 GIVN André
2 SURN BOURDET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 8:54
1 FAMS @9471@
1 NOTE conseiller général du Loiret,
0 @9472@ INDI
1 NAME François/BOURDET/
2 GIVN François
2 SURN BOURDET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 8:55
1 BIRT
2 DATE 1915
2 _FNA NO
1 NOTE François Bourdet n'eut pas d'enfant
1 FAMC @9471@
0 @9480@ INDI
1 NAME Juliette/EYNAUD/
2 GIVN Juliette
2 SURN EYNAUD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 MAR 2018
3 TIME 18:26
1 FAMS @9487@
1 BIRT
2 DATE 1852
2 _FNA NO
1 DEAT
2 DATE 1921
2 _FNA NO
0 @9488@ INDI
1 NAME Laurence/FIDIERE des PRINVEAUX/
2 GIVN Laurence
2 SURN FIDIERE des PRINVEAUX
2 NICK LOLE
1 SEX F
1 OCCU infirmière
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 14:58
1 BIRT
2 DATE 1876
2 _FNA NO
2 NOTE tante LOLE a légué sa maison de l’Enclos à France LD
1 DEAT
2 DATE 1956
2 _FNA NO
1 FAMC @9487@
0 @9499@ INDI
1 NAME Michel/FIDIERE des PRINVEAUX/
2 GIVN Michel
2 SURN FIDIERE des PRINVEAUX
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 MAR 2018
3 TIME 18:26
1 FAMC @9487@
0 @9508@ INDI
1 NAME Victor/HART/
2 GIVN Victor
2 SURN HART
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 MAR 2018
3 TIME 18:31
1 FAMS @9515@
1 BIRT
2 DATE 1827
2 _FNA NO
1 DEAT
2 DATE 1892
2 _FNA NO
0 @9516@ INDI
1 NAME André/HART/
2 GIVN André
2 SURN HART
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 MAR 2018
3 TIME 18:30
1 BIRT
2 DATE 1871
2 _FNA NO
1 DEAT
2 DATE 1951
2 _FNA NO
1 FAMC @9515@
0 @9526@ INDI
1 NAME Roger/HART/
2 GIVN Roger
2 SURN HART
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 MAR 2018
3 TIME 18:31
1 BIRT
2 DATE 1872
2 _FNA NO
1 DEAT
2 DATE 1969
2 _FNA NO
1 FAMC @9515@
0 @9536@ INDI
1 NAME Noémie Clémentine/HART/
2 GIVN Noémie Clémentine
2 SURN HART
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 19:37
1 FAMS @9686@
1 BIRT
2 DATE 1873
2 _FNA NO
1 DEAT
2 DATE 1927
2 _FNA NO
1 FAMC @9515@
0 @9547@ INDI
1 NAME Alice/MAC SWINEY/
2 GIVN Alice
2 SURN MAC SWINEY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 17:12
1 FAMS @9549@
1 BIRT
2 DATE 1837
2 PLAC Dublin,,,,,
2 _FNA NO
2 NOTE OU 1849?
1 FAMC @31588@
0 @9551@ INDI
1 NAME Pierre/DES VALLIERES/
2 GIVN Pierre
2 SURN DES VALLIERES
1 SEX M
1 OCCU officier
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 19:36
1 FAMS @9686@
1 BIRT
2 DATE 1868
2 _FNA NO
1 DEAT
2 DATE 28 MAY 1918
2 PLAC Soissons,02200,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.381670
4 LONG E3.323610
2 _FNA NO
2 CAUS BATAILLE DE PICARDIE
2 NOTE fin d’après-midi arrive près de JUVIGNY en voiture= tué par tir de mitrailleuse
1 NOTE GENERAL
1 FAMC @9549@
0 @9565@ INDI
1 NAME Marcel-Clément Maurice/DES VALLIERES/
2 GIVN Marcel-Clément Maurice
2 SURN DES VALLIERES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 19:34
1 BIRT
2 DATE 1870
2 _FNA NO
1 DEAT
2 DATE 1871
2 _FNA NO
1 FAMC @9549@
0 @9579@ INDI
1 NAME Luisa/de BARBERIA/
2 GIVN Luisa
2 SURN de BARBERIA
2 NICK tita
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 20:24
1 FAMS @9586@
1 BIRT
2 DATE 1842
2 _FNA NO
2 NOTE OU 1835?
1 DEAT
2 DATE 1917
2 _FNA NO
0 @9589@ INDI
1 NAME Dolorès/DES VALLIERES/
2 GIVN Dolorès
2 SURN DES VALLIERES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 8:58
1 FAMS @9627@
1 BIRT
2 DATE 1860
2 _FNA NO
1 DEAT
2 DATE 1918
2 _FNA NO
1 FAMC @9586@
0 @9600@ INDI
1 NAME Jeanne/DES VALLIERES/
2 GIVN Jeanne
2 SURN DES VALLIERES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 19:26
1 FAMS @9656@
1 BIRT
2 DATE 1863
2 _FNA NO
2 NOTE OU 1860?
1 DEAT
2 DATE 1924
2 _FNA NO
1 FAMC @9586@
0 @9607@ INDI
1 NAME Raoul/DES VALLIERES/
2 GIVN Raoul
2 SURN DES VALLIERES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 19:27
1 FAMS @9679@
1 BIRT
2 DATE 1860
2 _FNA NO
1 FAMC @9586@
0 @9613@ INDI
1 NAME Germaine/DES VALLIERES/
2 GIVN Germaine
2 SURN DES VALLIERES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 19:26
1 BIRT
2 DATE 1865
2 _FNA NO
1 FAMC @9586@
0 @9620@ INDI
1 NAME Gustave Jean Baptiste/BORD/
2 GIVN Gustave Jean Baptiste
2 SURN BORD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 9:02
1 FAMS @9627@
1 BIRT
2 DATE 26 JAN 1852
2 PLAC Limoges,87000,Haute-Vienne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.831530
4 LONG E1.257800
2 _FNA NO
1 DEAT
2 DATE 21 APR 1934
2 PLAC Saint-Malo,35400,Ille-et-Vilaine,Bretagne,FRANCE,
3 MAP
4 LATI N48.650000
4 LONG W2.016670
2 _FNA NO
1 NOTE érudit, passionné de mathématique, de musique qui devient historien spécialisé sur la période révolutionnaire
2 CONT Royaliste légitimiste, il fut un intime et représentant du comte de Chambord, et intime de la demi-sœur de ce prince, Louise-Charlotte-Marie de Bourbon (1809-1891),Il fut l'un des cinq membres du Parti monarchiste autorisés à assister aux obsèques
2 CONC du comte de Chambord en 1883
1 FAMC @31476@
0 @9628@ INDI
1 NAME Georges/BORD/
2 GIVN Georges
2 SURN BORD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 7:02
1 BIRT
2 DATE 1881
2 _FNA NO
1 DEAT
2 DATE 1941
2 _FNA NO
1 FAMC @9627@
0 @9638@ INDI
1 NAME Jacques/BORD/
2 GIVN Jacques
2 SURN BORD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 7:03
1 BIRT
2 DATE 1882
2 _FNA NO
1 DEAT
2 DATE 1971
2 _FNA NO
1 FAMC @9627@
0 @9654@ INDI
1 NAME Albert/BLONDEL/
2 GIVN Albert
2 SURN BLONDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 19:28
1 FAMS @9656@
1 BIRT
2 DATE 1849
2 _FNA NO
0 @9657@ INDI
1 NAME Madeleine/BLONDEL/
2 GIVN Madeleine
2 SURN BLONDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 7:23
1 FAMC @9656@
0 @9662@ INDI
1 NAME Geneviève/BLONDEL/
2 GIVN Geneviève
2 SURN BLONDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 7:23
1 FAMC @9656@
0 @9667@ INDI
1 NAME Yvonne/BLONDEL/
2 GIVN Yvonne
2 SURN BLONDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 7:23
1 FAMC @9656@
0 @9672@ INDI
1 NAME Antoinette/BLONDEL/
2 GIVN Antoinette
2 SURN BLONDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 7:23
1 FAMC @9656@
0 @9677@ INDI
1 NAME Jacques/DES VALLIERES/
2 GIVN Jacques
2 SURN DES VALLIERES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 7:24
1 FAMC @9679@
0 @9682@ INDI
1 NAME Marie/DES VALLIERES/
2 GIVN Marie
2 SURN DES VALLIERES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 7:24
1 FAMC @9679@
0 @9687@ INDI
1 NAME Jean/DES VALLIERES/
2 GIVN Jean
2 SURN DES VALLIERES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 20:01
1 FAMS @9745@
1 BIRT
2 DATE 5 APR 1895
2 PLAC Lyon,69000,Rhône,Auvergne-Rhône-Alpes,FRANCE,
3 MAP
4 LATI N45.748460
4 LONG E4.846710
2 _FNA NO
1 DEAT
2 DATE 20 SEP 1970
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 NOTE Militaire, Aviateur, Prefet et Ecrivain
2 CONT Officier pilote de chasse durant la guerre de 14-18. Auteur de nombreux ouvrages oubliés aujourd'hui et dont certains pourtant firent le régal de nos pères : Kavalier Scharnhorst; Spartakus-Parade; Les éléphants
1 FAMC @9686@
0 @9698@ INDI
1 NAME Marthe/LEFEBVRE des VALLIERES/
2 GIVN Marthe
2 SURN LEFEBVRE des VALLIERES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 19:48
1 FAMS @9736@
1 BIRT
2 DATE 1899
2 PLAC Paris 08,,,,,
2 _FNA NO
1 DEAT
2 DATE 1992
2 PLAC Montréal,,Montréal,Québec,CANADA,
3 MAP
4 LATI N45.508840
4 LONG W73.587810
2 _FNA NO
2 NOTE OU 1972?
1 FAMC @9686@
0 @9708@ INDI
1 NAME René/LEFEBVRE des VALLIERES/
2 GIVN René
2 SURN LEFEBVRE des VALLIERES
1 SEX M
1 OCCU Ingénieur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 20:06
1 FAMS @9727@
1 BIRT
2 DATE 23 JUN 1900
2 PLAC Tours,37000,Indre-et-Loire,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N47.383330
4 LONG E0.683330
2 _FNA NO
1 DEAT
2 DATE 25 NOV 1950
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 NOTE Ingénieur en Chef de la Cie des Eaux
1 FAMC @9686@
0 @9720@ INDI
1 NAME Yvette/NOUET du TAILLY/
2 GIVN Yvette
2 SURN NOUET du TAILLY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 19:43
1 FAMS @9727@
1 BIRT
2 DATE 1906
2 _FNA NO
1 DEAT
2 DATE 1990
2 _FNA NO
0 @9729@ INDI
1 NAME Henri Urbain Charles Marie/de CORTA/
2 GIVN Henri Urbain Charles Marie
2 SURN de CORTA
1 SEX M
1 OCCU Militaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 19:56
1 FAMS @9736@
1 BIRT
2 DATE 16 JUL 1879
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 5 SEP 1932
2 PLAC Yen-Bay,,,,,
2 _FNA NO
2 NOTE Décédé le 5 septembre 1932 (lundi) - Yen-Bay, Tonjin, Indochine, à l'âge de 53 ans
3 CONT Lieutenant-Colonel (Légion étrangère), campagne d'Indochine, Croix de gerre 14-18
0 @9738@ INDI
1 NAME Anne-Marie/de TERRIS/
2 GIVN Anne-Marie
2 SURN de TERRIS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 19:40
1 FAMS @9745@
1 BIRT
2 DATE 1895
2 _FNA NO
1 DEAT
2 DATE 1968
2 _FNA NO
0 @9764@ INDI
1 NAME Leon/BLOY/
2 GIVN Leon
2 SURN BLOY
1 SEX M
1 OCCU Écrivain
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 10:13
1 BIRT
2 DATE 1846
2 _FNA NO
1 DEAT
2 DATE 3 NOV 1917
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @9787@ INDI
1 NAME Georges/DIETERLE/
2 GIVN Georges
2 SURN DIETERLE
1 SEX M
1 OCCU Peintre Architecte
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 11:07
1 FAMS @9803@
1 BIRT
2 DATE 1844
2 _FNA NO
1 DEAT
2 DATE 1937
2 _FNA NO
0 @9801@ INDI
1 NAME Jeanne/?/
2 GIVN Jeanne
2 SURN ?
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 11:07
1 FAMS @9803@
0 @9804@ INDI
1 NAME Yvonne/DIETERLE/
2 GIVN Yvonne
2 SURN DIETERLE
1 SEX F
1 OCCU Sculptrice
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 11:15
1 FAMS @9839@
1 FAMC @9803@
0 @9811@ INDI
1 NAME Alice/DIETERLE/
2 GIVN Alice
2 SURN DIETERLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 11:12
1 FAMS @9824@
1 FAMC @9803@
0 @9820@ INDI
1 NAME Raymond/JOIN/
2 GIVN Raymond
2 SURN JOIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 14:47
1 FAMS @9824@
1 DEAT
2 DATE 31 OCT 1918
2 _FNA NO
2 CAUS grippe espagnole
2 NOTE grippe espagnole
0 @9825@ INDI
1 NAME Raymond/JOIN/
2 GIVN Raymond
2 SURN JOIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 11:12
1 FAMC @9824@
0 @9831@ INDI
1 NAME Jean-Pierre/LAURENS/
2 GIVN Jean-Pierre
2 SURN LAURENS
1 SEX M
1 OCCU Peintre
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 11:15
1 FAMS @9839@
1 BIRT
2 DATE 1875
2 _FNA NO
1 DEAT
2 DATE 1932
2 _FNA NO
0 @9843@ INDI
1 NAME Jacques/ROUCHÉ/
2 GIVN Jacques
2 SURN ROUCHÉ
1 SEX M
1 OCCU DIRECTEUR OPERA PARIS
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 15:39
1 BIRT
2 DATE 1862
2 _FNA NO
1 DEAT
2 DATE 1957
2 _FNA NO
1 NOTE Brillant polytechnicien et grand amateur d'art,
2 CONT il rencontre Desvallières en 1905 et devient son mécène et ami, Témoin du rôle important du vice-président du Salon d'automne, il lui demande de tenir la rubrique de critique d'art de La Grande Revue, qu'il reprend en 1907, ce que Desvallières fera
2 CONC  jusqu'en 1913. Il lui commande parallèlement le décor du salon de musique de son hôtel particulier de la rue d'Otrémont, à Paris, teinté de fauvisme (1907-1908). Nommé directeur du Théâtre des Arts en 1910, il le sollicite encore pour renouveler d
2 CONC écors et costumes. Directeur de l'Opéra de Paris (1913- 1945), il lui commande un témoignage sur la guerre pour sa chapelle de Saint-Privat dans le Gard, que Desvallières, de retour du front, réalisera entre 1919 et 1925. Cf. G.D. et le S.A., p, 32
2 CONC , 133-142, et G.D.
2 CONT
0 @9853@ INDI
1 NAME Henri Odon/SAINT-MARC GIRARDIN/
2 GIVN Henri Odon
2 SURN SAINT-MARC GIRARDIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 17:54
1 FAMS @10079@
1 BIRT
2 DATE 23 MAR 1876
2 _FNA NO
1 DEAT
2 DATE 1962
2 _FNA NO
1 FAMC @9871@
0 @9858@ INDI
1 NAME Barthélémy/SAINT-MARC GIRARDIN/
2 GIVN Barthélémy
2 SURN SAINT-MARC GIRARDIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 15:51
1 FAMS @9871@
1 FAMC @10056@
0 @9864@ INDI
1 NAME Odette/GUÉNEAU de MUSSY/
2 GIVN Odette
2 SURN GUÉNEAU de MUSSY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 15:51
1 FAMS @9871@
1 BIRT
2 DATE 1854
2 _FNA NO
1 DEAT
2 DATE 1933
2 _FNA NO
1 FAMC @9875@
0 @9874@ INDI
1 NAME ?/GUÉNEAU de MUSSY/
2 GIVN ?
2 SURN GUÉNEAU de MUSSY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 11:52
1 FAMS @9875@
0 @9878@ INDI
1 NAME Philippe/GUÉNEAU de MUSSY/
2 GIVN Philippe
2 SURN GUÉNEAU de MUSSY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 11:54
1 FAMS @9894@
1 BIRT
2 DATE 1853
2 _FNA NO
1 DEAT
2 DATE 1934
2 _FNA NO
1 FAMC @9875@
0 @9887@ INDI
1 NAME François/GUÉNEAU de MUSSY/
2 GIVN François
2 SURN GUÉNEAU de MUSSY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 DEC 2019
3 TIME 17:19
1 BIRT
2 DATE 14 OCT 1883
2 _FNA NO
1 DEAT
2 DATE 1916
2 _FNA NO
2 CAUS GUERRE
1 FAMC @9894@
0 @9903@ INDI
1 NAME Marguerite/HURÉ/
2 GIVN Marguerite
2 SURN HURÉ
1 SEX F
1 OCCU vitrier
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 13:47
0 @9946@ INDI
1 NAME Henri/CHARLIER/
2 GIVN Henri
2 SURN CHARLIER
1 SEX M
1 OCCU Sculpteur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 14:34
1 BIRT
2 DATE 1883
2 _FNA NO
1 DEAT
2 DATE 1975
2 _FNA NO
0 @9983@ INDI
1 NAME René-Xavier/PRINET/
2 GIVN René-Xavier
2 SURN PRINET
1 SEX M
1 OCCU Peintre
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 NOV 2018
3 TIME 16:16
1 FAMS @10000@
1 BIRT
2 DATE 1861
2 _FNA NO
1 DEAT
2 DATE 1946
2 _FNA NO
1 NOTE Grands amis des Desvallières, avec lesquels ils ont voyagé dans leur jeunesse, René est le parrain de Marie-Madeleine, Ils passent souvent les vacances d'été ensemble au Double-Six, la propriété de Cabourg des Prinet. Daniel" s'y rend pour peindre
2 CONC avec René. Sa mort les atteint au cœur, Jeanne soigne les blessés pendant la guerre,
0 @9997@ INDI
1 NAME Jeanne/?/
2 GIVN Jeanne
2 SURN ?
1 SEX F
1 OCCU Infirmière
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 15:22
1 FAMS @10000@
0 @10006@ INDI
1 NAME Marguerite/REMOND/
2 GIVN Marguerite
2 SURN REMOND
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 15:25
1 FAMC @9390@
0 @10011@ INDI
1 NAME Jean-Jacques/REMOND/
2 GIVN Jean-Jacques
2 SURN REMOND
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 15:26
1 FAMC @9390@
0 @10018@ INDI
1 NAME Georges/ROUAULT/
2 GIVN Georges
2 SURN ROUAULT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 15:35
1 BIRT
2 DATE 1871
2 _FNA NO
1 DEAT
2 DATE 1958
2 _FNA NO
1 NOTE Élève brillant de Gustave Moreau", il noue, à la mort de celui-ci une amitié avec Desvallières basée sur l'admiration du maître et par la suite sur leur foi commune cimentée par Léon Bloy'. De dix ans  son cadet, il trouve en George un confident, u
2 CONC n père qui le soutient. En témoigne une importante correspondance autour de 1900. Tous deux réalisent, avec H, Rupp", l'ouverture du musée Gustave-Moreau en 1902. Rouault en sera le conservateur jusqu'en 1928. A l'été 1903, il entraîne son ami dans
2 CONC  la préparation d'un nouveau projet: le Salon d'automne, Cf. aix et le S,A" fig, 24.
0 @10040@ INDI
1 NAME Henri/RUPP/
2 GIVN Henri
2 SURN RUPP
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 15:46
1 BIRT
2 DATE 1837
2 _FNA NO
1 DEAT
2 DATE 18 OCT 1918
2 _FNA NO
1 NOTE  Élève, ami et légataire universel de Gustave Moreau·, il est un proche de la famille Desvallières. Après la  mort de Moreau, il a dressé l'inventaire en présence de Desvallières, exécuteur testamentaire, son meilleur conseiller et ami, Leur amitié
2 CONC , soudée autour de la défense de leur cher maître et dans la réalisation de son musée, rue de La Rochefoucauld, à Paris, s'étend à chaque enfant, dont il devient  le confident, Intime, il a sa chambre à Seine-Port.
2 CONT
0 @10053@ INDI
1 NAME Marc/GIRARDIN DIT SAINT-MARC GIRARDIN/
2 GIVN Marc
2 SURN GIRARDIN DIT SAINT-MARC GIRARDIN
1 SEX M
1 OCCU écrivain
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 15:49
1 FAMS @10056@
0 @10059@ INDI
1 NAME Louise/SAINT-MARC GIRARDIN/
2 GIVN Louise
2 SURN SAINT-MARC GIRARDIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 15:54
1 BIRT
2 DATE 1882
2 _FNA NO
1 DEAT
2 DATE 1981
2 _FNA NO
1 NOTE Petite-fille de l'académicien Marc Girardin, dit Saint-Marc Girardin, ami d’Errnest" Legouvé, et fille de Barthélémy et d'Odette (née Guéneau de Mussy') Saint-Marc Girardin. Amie de Sabine'. Religieuse
2 CONT de saint Vincent de Paul,
1 FAMC @9871@
0 @10077@ INDI
1 NAME Louise/LEMAIGRE-DUBREUIL/
2 GIVN Louise
2 SURN LEMAIGRE-DUBREUIL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 17:54
1 FAMS @10079@
1 FAMC @31653@
0 @10085@ INDI
1 NAME Jules/SCHEURER/
2 GIVN Jules
2 SURN SCHEURER
1 SEX M
1 OCCU industriel
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 16:29
1 FAMS @10101@
1 BIRT
2 DATE 1852
2 _FNA NO
1 DEAT
2 DATE 1942
2 _FNA NO
1 NOTE Industriels à Thann, ils habitent à Bitschwiller-lesThann et reçoivent George lorsqu'il descend au repos dans la vallée de la Thur,
2 CONT Leur fils Daniel est mort dans la Marne, le 14 mars 1915,
2 CONT et leur fils Pierre,  le 28 avril 1915, à l'Hartmannswillerkopf.
2 CONT Sénateur de 1920 à 1927, il inaugure en 1922 le monument aux morts de Bitschwiller-les-Thann, qu'il a conçu et fait aménager en 1920,
2 CONT
0 @10099@ INDI
1 NAME Marie-Anne/?/
2 GIVN Marie-Anne
2 SURN ?
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 16:27
1 FAMS @10101@
0 @10105@ INDI
1 NAME Lucien/SIMON/
2 GIVN Lucien
2 SURN SIMON
1 SEX M
1 OCCU Peintre
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 11:28
1 FAMS @11012@
1 BIRT
2 DATE 1861
2 _FNA NO
1 DEAT
2 DATE 1945
2 _FNA NO
1 NOTE Peintre que George a rencontré pendant son volontariat à Orléans (cf. G.D, et le S.A., p.18-19). Avec sa femme, Jeanne, soeur du peintre breton André Dauchez, ils ont cinq enfants: Paul, Bernard, Lucienne, Charlotte, et Pauline. Ils voyagent avec l
2 CONC eurs chers amis Desvallières, qui viennent aussi passer des vacances en famille à Bénodet, L'ainé Paul (1892-1979), peintre et sculpteur animalier de la génération de Richard", a combattu dans la Marne. Fait prisonnier en septembre 1914, il ne revi
2 CONC endra des camps qu'en décembre 1918, Ils apprécient l'oeuvre  de George et acquerront Les Tireurs à l’arc (1895), qu'ils cèderont à l'État en 1951- actuellement dans les collections du musée d'Orsay.
0 @10118@ INDI
1 NAME Charles/VIRAUT/
2 GIVN Charles
2 SURN VIRAUT
1 SEX M
1 OCCU Avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 AUG 2019
3 TIME 17:45
1 FAMS @10137@
1 BIRT
2 DATE 1862
2 _FNA NO
1 DEAT
2 DATE 1948
2 _FNA NO
1 NOTE Avocat à la Cour d'appel de Paris, amis et collectionneurs de Desvallières. Suzanne Henry-Lepaute", par sa naissance est une grande amie de Marguerite·, Charles était très lié au capitaine Dubarle', mort en juin 1915,
2 CONT Ils ont trois enfants :
2 CONT Madelaine" (1892-1980),
2 CONT Raymond", pilote mort  au combat en 1916,
2 CONT et Daniel (1898-1975), Ce dernier a loué la Maison Broquette, héritage de Desvallières à Seine-Port, dans les années 1920.
2 CONT
0 @10130@ INDI
1 NAME Suzanne/HENRY-LEPAUTE/
2 GIVN Suzanne
2 SURN HENRY-LEPAUTE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 AUG 2019
3 TIME 17:45
1 FAMS @10137@
1 BIRT
2 DATE 10 MAY 1870
2 PLAC Le Mesnil-le-Roi,78600,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.938250
4 LONG E2.125540
2 _FNA NO
1 DEAT
2 DATE 27 OCT 1908
2 PLAC Brunoy,91800,Essonne,Île-de-France,FRANCE,
3 MAP
4 LATI N48.694200
4 LONG E2.492230
2 _FNA NO
1 NOTE par sa naissance est une grande amie de Marguerite·LEFEBVRE
1 FAMC @33980@
0 @10140@ INDI
1 NAME Madelaine/VIRAUT/
2 GIVN Madelaine
2 SURN VIRAUT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 16:51
1 BIRT
2 DATE 1892
2 _FNA NO
1 DEAT
2 DATE 1948
2 _FNA NO
1 NOTE Elle est une grande amie de Sabine
1 FAMC @10137@
0 @10150@ INDI
1 NAME Raymond/VIRAUT/
2 GIVN Raymond
2 SURN VIRAUT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 16:52
1 BIRT
2 DATE 1895
2 _FNA NO
1 DEAT
2 DATE 1916
2 _FNA NO
2 CAUS mort au combat
1 NOTE étudiant, engagé volontaire, sous-lieutenant, pilote aviateur à l'escadrille M.F. 63. Il est tué en 1916 dans  un combat aérien, à Récicourt (Meuse).
2 CONT
1 FAMC @10137@
0 @10160@ INDI
1 NAME Daniel/VIRAUT/
2 GIVN Daniel
2 SURN VIRAUT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAR 2018
3 TIME 16:53
1 BIRT
2 DATE 1898
2 _FNA NO
1 DEAT
2 DATE 1975
2 _FNA NO
1 FAMC @10137@
0 @10176@ INDI
1 NAME Bruno/de MONTPLANET/
2 GIVN Bruno
2 SURN de MONTPLANET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 MAR 2018
3 TIME 6:41
1 FAMS @10178@
0 @10200@ INDI
1 NAME Hector/BERLIOZ/
2 GIVN Hector
2 SURN BERLIOZ
1 SEX M
1 OCCU Musicien
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 MAR 2018
3 TIME 19:04
0 @10217@ INDI
1 NAME Pierre/LEGOUVÉ/
2 GIVN Pierre
2 SURN LEGOUVÉ
1 SEX M
1 OCCU imprimeur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2018
3 TIME 8:25
1 FAMS @10225@
1 BIRT
2 PLAC Lyon,69000,Rhône,Auvergne-Rhône-Alpes,FRANCE,
3 MAP
4 LATI N45.748460
4 LONG E4.846710
2 _FNA NO
1 NOTE maître imprimeur et marchand libraire, né à Lyon.
2 CONT  L’aïeul de toute cette lignée, le chef du nom, François, époux de dame Thérèse de Cabannes, n’a pas vu le jour à Montbrison, ni à Lyon, encore moins à Paris : il était de Mont-de-Marsan
1 FAMC @19851@
0 @10223@ INDI
1 NAME Antoinette/DELALOY/
2 GIVN Antoinette
2 SURN DELALOY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 MAR 2018
3 TIME 18:13
1 FAMS @10225@
0 @10242@ INDI
1 NAME Pierre/MOTTE/
2 GIVN Pierre
2 SURN MOTTE
1 SEX M
1 OCCU Procureur de la cour
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 MAR 2018
3 TIME 19:03
1 FAMS @10252@
0 @10249@ INDI
1 NAME Nicole Louise/LOREAU/
2 GIVN Nicole Louise
2 SURN LOREAU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 MAR 2018
3 TIME 19:01
1 FAMS @10252@
0 @10260@ INDI
1 NAME Gérard/BROUSSE/
2 GIVN Gérard
2 SURN BROUSSE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 MAR 2018
3 TIME 19:17
1 FAMS @10270@
0 @10268@ INDI
1 NAME Françoise/VIGNAULT/
2 GIVN Françoise
2 SURN VIGNAULT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 MAR 2018
3 TIME 19:17
1 FAMS @10270@
0 @10304@ INDI
1 NAME Gabin/de BAYSER/
2 GIVN Gabin
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 14:26
1 BIRT
2 DATE SEP 2017
2 _FNA NO
1 FAMC @1781@
0 @10391@ INDI
1 NAME Véra/DUMONT/
2 GIVN Véra
2 SURN DUMONT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 21:52
1 BIRT
2 DATE JUN 2017
2 _FNA NO
1 FAMC @4175@
0 @10421@ INDI
1 NAME Arnaud/LECLAIR/
2 GIVN Arnaud
2 SURN LECLAIR
1 SEX M
1 OCCU Chirurgien
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 JUN 2019
3 TIME 18:27
1 FAMS @10430@
0 @10428@ INDI
1 NAME Véronique/VINCENT/
2 GIVN Véronique
2 SURN VINCENT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 JUN 2019
3 TIME 18:27
1 FAMS @10430@
0 @10434@ INDI
1 NAME Paola/LE CONTE/
2 GIVN Paola
2 SURN LE CONTE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 MAR 2018
3 TIME 22:13
1 BIRT
2 DATE 30 AUG 2017
2 PLAC Lyon,69000,Rhône,Auvergne-Rhône-Alpes,FRANCE,
3 MAP
4 LATI N45.748460
4 LONG E4.846710
2 _FNA NO
1 FAMC @1574@
0 @10469@ INDI
1 NAME Laure/FULLER/
2 GIVN Laure
2 SURN FULLER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 MAY 2018
3 TIME 19:23
1 FAMS @10473@
1 BIRT
2 DATE 13 SEP 1973
2 _FNA NO
1 FAMC @19461@
0 @10476@ INDI
1 NAME Arthur Grégory/de PANAFIEU/
2 GIVN Arthur Grégory
2 SURN de PANAFIEU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 DEC 2019
3 TIME 18:02
1 BIRT
2 DATE 4 SEP 1998
2 PLAC Londres,,,,,
2 _FNA NO
1 FAMC @10473@
0 @10483@ INDI
1 NAME Clémence/de PANAFIEU/
2 GIVN Clémence
2 SURN de PANAFIEU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 MAY 2018
3 TIME 19:25
1 BIRT
2 DATE 29 MAR 2001
2 PLAC Londres,,,,,
2 _FNA NO
1 FAMC @10473@
0 @10490@ INDI
1 NAME Inès/de PANAFIEU/
2 GIVN Inès
2 SURN de PANAFIEU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 10:57
1 BIRT
2 DATE 13 DEC 2006
2 PLAC Londres,,,,,
2 _FNA NO
1 FAMC @10473@
0 @10498@ INDI
1 NAME Nine/de PANAFIEU/
2 GIVN Nine
2 SURN de PANAFIEU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 10:08
1 BIRT
2 DATE 12 MAR 2009
2 _FNA NO
1 FAMC @10473@
0 @10507@ INDI
1 NAME Alfonso/TASSO de SAXE-COBOURG ET BRAGANCE/
2 GIVN Alfonso
2 SURN TASSO de SAXE-COBOURG ET BRAGANCE
1 SEX M
1 TITL BARON TAXIS VON BORDOGNA UND VALNIGRA
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 MAY 2018
3 TIME 19:31
1 FAMS @10511@
1 BIRT
2 DATE 30 JAN 1970
2 PLAC Francfort,,,,,
2 _FNA NO
1 FAMC @10604@
0 @10513@ INDI
1 NAME Pia/TASSO de SAXE-COBOURG ET BRAGANCE/
2 GIVN Pia
2 SURN TASSO de SAXE-COBOURG ET BRAGANCE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 MAY 2018
3 TIME 19:55
1 BIRT
2 DATE 3 SEP 2004
2 PLAC Milan,,,,,
2 _FNA NO
1 FAMC @10511@
0 @10521@ INDI
1 NAME Taddeo/TASSO de SAXE-COBOURG ET BRAGANCE/
2 GIVN Taddeo
2 SURN TASSO de SAXE-COBOURG ET BRAGANCE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 10:26
1 BIRT
2 DATE 5 DEC 2011
2 _FNA NO
1 FAMC @10511@
0 @10530@ INDI
1 NAME Alexandra/CANTACUSENE/
2 GIVN Alexandra
2 SURN CANTACUSENE
1 SEX F
1 TITL PRINCESSE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 MAY 2018
3 TIME 20:02
1 FAMS @10534@
1 BIRT
2 DATE 13 JUN 1976
2 PLAC Dakar,,,,,
2 _FNA NO
1 FAMC @19518@
0 @10537@ INDI
1 NAME Maxime/de PANAFIEU/
2 GIVN Maxime
2 SURN de PANAFIEU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 MAY 2018
3 TIME 20:02
1 BIRT
2 DATE 22 MAY 2006
2 PLAC Dubaï,,,,,
2 _FNA NO
1 FAMC @10534@
0 @10545@ INDI
1 NAME Hadrien/de PANAFIEU/
2 GIVN Hadrien
2 SURN de PANAFIEU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 10:14
1 BIRT
2 DATE 27 SEP 2008
2 _FNA NO
1 FAMC @10534@
0 @10552@ INDI
1 NAME Alexis/de PANAFIEU/
2 GIVN Alexis
2 SURN de PANAFIEU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 10:14
1 BIRT
2 DATE 6 MAR 2011
2 _FNA NO
1 FAMC @10534@
0 @10561@ INDI
1 NAME François-Marie/de CHILLAZ/
2 GIVN François-Marie
2 SURN de CHILLAZ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 7 DEC 2018
3 TIME 18:31
1 FAMS @10563@
0 @10567@ INDI
1 NAME Ombeline/de BOISSIEU/
2 GIVN Ombeline
2 SURN de BOISSIEU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 NOV 2018
3 TIME 18:32
1 FAMS @10571@
1 BIRT
2 DATE 22 SEP 1986
2 _FNA NO
0 @10574@ INDI
1 NAME Apolline/DORVAL/
2 GIVN Apolline
2 SURN DORVAL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 10:19
1 BIRT
2 DATE 11 OCT 2013
2 _FNA NO
1 FAMC @10571@
0 @10581@ INDI
1 NAME Blanche/DORVAL/
2 GIVN Blanche
2 SURN DORVAL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 10:19
1 BIRT
2 DATE 7 JUL 2015
2 _FNA NO
1 FAMC @10571@
0 @10591@ INDI
1 NAME Carlos/TASSO de SAXE-COBOURG ET BRAGANCE/
2 GIVN Carlos
2 SURN TASSO de SAXE-COBOURG ET BRAGANCE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 MAY 2018
3 TIME 19:50
1 FAMS @10604@
1 BIRT
2 DATE 1931
2 PLAC Gmunden,,,,,
3 MAP
4 LATI N47.918870
4 LONG E13.797540
2 _FNA NO
1 NOTE né Karl Eduard TAXIS VON BORDOGNA UND VALNIGRA
2 CONT nom modifié en 1946 par ascendances féminines
1 FAMC @19499@
0 @10602@ INDI
1 NAME Walburga/ERZHERZOGYN d'AUTRICHE/
2 GIVN Walburga
2 SURN ERZHERZOGYN d'AUTRICHE
1 SEX F
1 TITL PRINCESSE IMPERIALE ET ARCHIDUCHESSE D'AUTRICHEE DE HONGRIE PRINCESSE ROYAL
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 MAY 2018
3 TIME 19:29
1 FAMS @10604@
0 @10685@ INDI
1 NAME Simone/GANDELIN/
2 GIVN Simone
2 SURN GANDELIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 19:19
1 BIRT
2 DATE 1917
2 _FNA NO
1 FAMC @6345@
0 @10691@ INDI
1 NAME Denyse/GANDELIN/
2 GIVN Denyse
2 SURN GANDELIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 19:16
1 FAMS @10705@
1 BIRT
2 DATE 1921
2 _FNA NO
1 FAMC @6345@
0 @10703@ INDI
1 NAME ?/SARZANA/
2 GIVN ?
2 SURN SARZANA
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 19:16
1 FAMS @10705@
0 @10706@ INDI
1 NAME Jean/SARZANA/
2 GIVN Jean
2 SURN SARZANA
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 MAR 2018
3 TIME 19:19
1 BIRT
2 DATE 1951
2 _FNA NO
1 FAMC @10705@
0 @10728@ INDI
1 NAME Victor François Marie/BEVIERE/
2 GIVN Victor François Marie
2 SURN BEVIERE
1 SEX M
1 OCCU CONSERVATEUR DES HYPOTHEQUES
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 18:44
1 FAMS @10736@
1 FAMS @25555@
1 BIRT
2 DATE 7 MAY 1831
3 TIME 9:00
2 PLAC Tourcoing,59200,Nord,Hauts-de-France,FRANCE,rue des anges
3 MAP
4 LATI N50.723910
4 LONG E3.161170
2 _FNA NO
1 DEAT
2 DATE 4 FEB 1905
2 PLAC Nice,06000,Alpes-Maritimes,Provence-Alpes-Côte d'Azur,FRANCE,villa francinelli
3 MAP
4 LATI N43.703130
4 LONG E7.266080
2 _FNA NO
2 NOTE
1 NOTE descend 2 fois des GOSSUIN=
2 CONT par Marie thérèse Constance GOSSUIN sa grand-mère
2 CONT par Marie Philippine Josèphe GARCIA de PARÈDES son arrière-grand-mère
1 FAMC @12798@
0 @10734@ INDI
1 NAME Louise-Marie/FOURNIER-BOURDIER/
2 GIVN Louise-Marie
2 SURN FOURNIER-BOURDIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 16:20
1 FAMS @10736@
1 BIRT
2 DATE 4 NOV 1838
2 _FNA NO
1 DEAT
2 DATE 19 JUN 1876
2 PLAC Châlons-en-Champagne,51000,Marne,Grand Est,FRANCE,
3 MAP
4 LATI N48.953930
4 LONG E4.367240
2 _FNA NO
2 NOTE à naissance de 3°fille
1 FAMC @12771@
0 @10740@ INDI
1 NAME Charles Philippe/FOURNIER-BOURDIER/
2 GIVN Charles Philippe
2 SURN FOURNIER-BOURDIER
1 SEX M
1 OCCU avocat notaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 16:15
1 FAMS @12771@
1 BIRT
2 PLAC Mézières-sur-Couesnon,35140,Ille-et-Vilaine,Bretagne,FRANCE,
3 MAP
4 LATI N48.295050
4 LONG W1.433120
2 _FNA NO
1 NOTE MAIRE DE MEZIERES
1 FAMC @25377@
0 @10750@ INDI
1 NAME Pierre-Auguste/ROY de LOULAY/
2 GIVN Pierre-Auguste
2 SURN ROY de LOULAY
1 SEX M
1 OCCU Député
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 19:21
1 BIRT
2 DATE 1818
2 _FNA NO
1 DEAT
2 DATE 1896
2 _FNA NO
0 @10755@ INDI
1 NAME Louis/ROY de LOULAY/
2 GIVN Louis
2 SURN ROY de LOULAY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 19:20
1 FAMS @11744@
0 @10784@ INDI
1 NAME Marthe/BEVIERE/
2 GIVN Marthe
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 16:20
1 BIRT
2 DATE 8 DEC 1867
2 _FNA NO
1 FAMC @10736@
0 @10789@ INDI
1 NAME Gilberte/BEVIERE/
2 GIVN Gilberte
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 16:20
1 BIRT
2 DATE 16 JUN 1876
2 PLAC Châlons-en-Champagne,51000,Marne,Grand Est,FRANCE,
3 MAP
4 LATI N48.953930
4 LONG E4.367240
2 _FNA NO
1 FAMC @10736@
0 @10840@ INDI
1 NAME Isaure/IBANEZ/
2 GIVN Isaure
2 SURN IBANEZ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 8:18
1 BIRT
2 DATE 5 OCT 2015
2 _FNA NO
1 FAMC @5264@
0 @10848@ INDI
1 NAME Pauline/BUFFON/
2 GIVN Pauline
2 SURN BUFFON
1 SEX F
1 OCCU avocate
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 20:08
1 FAMS @10850@
0 @10852@ INDI
1 NAME Élie/HUBERT/
2 GIVN Élie
2 SURN HUBERT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 8:20
1 BIRT
2 DATE 19 MAY 2015
2 _FNA NO
1 FAMC @5243@
0 @10860@ INDI
1 NAME Victoria/ROUX/
2 GIVN Victoria
2 SURN ROUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 13:07
1 FAMS @10862@
0 @10864@ INDI
1 NAME Olympe/SAINT OLIVE/
2 GIVN Olympe
2 SURN SAINT OLIVE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 8:24
1 BIRT
2 DATE 1 JUL 2017
2 _FNA NO
1 FAMC @10862@
0 @10878@ INDI
1 NAME Mathis/GERMERIE/
2 GIVN Mathis
2 SURN GERMERIE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 9:07
1 BIRT
2 DATE 17 NOV 2015
2 _FNA NO
1 FAMC @7284@
0 @10886@ INDI
1 NAME Jean/ECHIFFRE/
2 GIVN Jean
2 SURN ECHIFFRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 9:13
1 FAMS @10890@
1 BIRT
2 DATE 13 FEB 1956
2 _FNA NO
0 @10893@ INDI
1 NAME Capucine/ECHIFFRE/
2 GIVN Capucine
2 SURN ECHIFFRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 9:12
1 BIRT
2 DATE 8 MAY 1982
2 _FNA NO
1 FAMC @10890@
0 @10900@ INDI
1 NAME Adrien/ECHIFFRE/
2 GIVN Adrien
2 SURN ECHIFFRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 9:15
1 FAMS @10920@
1 BIRT
2 DATE 23 MAY 1984
2 _FNA NO
1 FAMC @10890@
0 @10908@ INDI
1 NAME Myrtille/ECHIFFRE/
2 GIVN Myrtille
2 SURN ECHIFFRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 9:13
1 BIRT
2 DATE 12 MAY 1987
2 _FNA NO
1 FAMC @10890@
0 @10916@ INDI
1 NAME Hélène/COUOT/
2 GIVN Hélène
2 SURN COUOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 9:15
1 FAMS @10920@
1 BIRT
2 DATE 25 JAN 1977
2 _FNA NO
0 @10923@ INDI
1 NAME Néo/ECHIFFRE/
2 GIVN Néo
2 SURN ECHIFFRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 9:15
1 BIRT
2 DATE 11 JUN 2012
2 _FNA NO
1 FAMC @10920@
0 @10930@ INDI
1 NAME Thomas/ECHIFFRE/
2 GIVN Thomas
2 SURN ECHIFFRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 9:15
1 BIRT
2 DATE 23 DEC 2016
2 _FNA NO
1 FAMC @10920@
0 @10940@ INDI
1 NAME Inès/ELIATHIME/
2 GIVN Inès
2 SURN ELIATHIME
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 9:26
1 BIRT
2 DATE 5 JUN 2017
2 _FNA NO
1 FAMC @7188@
0 @10950@ INDI
1 NAME Robin/GILLET/
2 GIVN Robin
2 SURN GILLET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 9:27
1 BIRT
2 DATE 28 APR 2017
2 _FNA NO
1 FAMC @7199@
0 @10960@ INDI
1 NAME Fanny/PRAT/
2 GIVN Fanny
2 SURN PRAT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 8:41
1 FAMS @10964@
1 BIRT
2 DATE 17 MAY 1980
2 _FNA NO
0 @10965@ INDI
1 NAME Madeleine/TRAPENARD/
2 GIVN Madeleine
2 SURN TRAPENARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 8:39
1 BIRT
2 DATE 7 MAY 2016
2 _FNA NO
1 FAMC @10964@
0 @10977@ INDI
1 NAME Hector/BLUM/
2 GIVN Hector
2 SURN BLUM
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 9:35
1 BIRT
2 DATE 15 OCT 2016
2 _FNA NO
1 FAMC @7217@
0 @10994@ INDI
1 NAME Édouard/MICHELIN/
2 GIVN Édouard
2 SURN MICHELIN
1 SEX M
1 OCCU MANUFACTURIER
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 11:18
1 BIRT
2 DATE 1859
2 _FNA NO
1 DEAT
2 DATE 1940
2 _FNA NO
0 @11010@ INDI
1 NAME Jeanne/DAUCHEZ/
2 GIVN Jeanne
2 SURN DAUCHEZ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 11:28
1 FAMS @11012@
0 @11017@ INDI
1 NAME Isabelle/ROUSSELLE de la PERRIERE/
2 GIVN Isabelle
2 SURN ROUSSELLE de la PERRIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 11:48
1 FAMS @11025@
1 FAMC @7731@
0 @11023@ INDI
1 NAME ?/GOICHON/
2 GIVN ?
2 SURN GOICHON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 11:48
1 FAMS @11025@
0 @11026@ INDI
1 NAME Alexis/GOICHON/
2 GIVN Alexis
2 SURN GOICHON
1 SEX M
1 OCCU ingénieur médical
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 11:56
1 FAMS @11037@
1 FAMC @11025@
0 @11033@ INDI
1 NAME Caroline/BENAY-KERSCAVEN/
2 GIVN Caroline
2 SURN BENAY-KERSCAVEN
1 SEX F
1 OCCU bibliothécaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 11:56
1 FAMS @11037@
1 BIRT
2 DATE 4 JAN 1984
2 _FNA NO
0 @11038@ INDI
1 NAME François-Marie/GOICHON/
2 GIVN François-Marie
2 SURN GOICHON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 11:51
1 BIRT
2 DATE 24 JUN 2014
2 _FNA NO
1 FAMC @11037@
0 @11094@ INDI
1 NAME /SOLIS CARO/
2 GIVN
2 SURN SOLIS CARO
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 12:20
1 FAMC @8374@
0 @11097@ INDI
1 NAME /SOLIS CARO/
2 GIVN
2 SURN SOLIS CARO
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 MAR 2018
3 TIME 12:20
1 FAMC @8374@
0 @11184@ INDI
1 NAME Catherine/AMBROSELLI/
2 GIVN Catherine
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 JUN 2019
3 TIME 12:07
1 BIRT
2 DATE 1793
2 _FNA NO
1 DEAT
2 DATE 26 APR 1859
2 _FNA NO
1 BURI
2 DATE 30 APR 1859
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,cimetierre de montmartre
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
2 NOTE Dans tombe chapelle  d'edouard AMBROSELLI  CPP 1874 allée Nicolas 3°ligne n°45 cimetière de Montmartre Epitaphes à
3 CONT Catherine AMBROSELLI = tante dcd 26/04/1859 à 66 ans
1 NOTE Dans tombe   d'edouard AMBROSELLI  CPP 1874 allée Nicolas 3°ligne n°45 cimetière de Montmartre Epitaphes à
2 CONT Catherine AMBROSELLI = tante dcd 26/04/1859 à 66 ans
2 CONT
2 CONT
1 FAMC @2221@
0 @11207@ INDI
1 NAME Bruno/CHEVREUX/
2 GIVN Bruno
2 SURN CHEVREUX
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 MAR 2018
3 TIME 20:16
1 FAMS @11209@
0 @11213@ INDI
1 NAME Pierre "Robert"/BROUILLAUD/
2 GIVN Pierre "Robert"
2 SURN BROUILLAUD
1 SEX M
1 OCCU Avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 20:02
1 FAMS @11216@
1 BIRT
2 DATE 10 SEP 1924
2 PLAC Bordeaux,33000,Gironde,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N44.840440
4 LONG W0.580500
2 _FNA NO
1 DEAT
2 DATE 20 FEB 2006
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @11217@ INDI
1 NAME Stéphane/BROUILLAUD/
2 GIVN Stéphane
2 SURN BROUILLAUD
1 SEX M
1 OCCU enseignant
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 DEC 2019
3 TIME 14:59
1 FAMS @11371@
1 BIRT
2 DATE 27 JAN 1963
2 PLAC Talence,33400,Gironde,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N44.804770
4 LONG W0.595430
2 _FNA NO
1 FAMC @11216@
0 @11222@ INDI
1 NAME Jean-Pierre/BROUILLAUD/
2 GIVN Jean-Pierre
2 SURN BROUILLAUD
1 SEX M
1 OCCU Professeur de droit
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 NOV 2018
3 TIME 8:24
1 FAMS @11353@
1 BIRT
2 DATE 17 JAN 1969
2 PLAC Talence,33400,Gironde,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N44.804770
4 LONG W0.595430
2 _FNA NO
1 FAMC @11216@
0 @11228@ INDI
1 NAME Dominique/JULLIEN/
2 GIVN Dominique
2 SURN JULLIEN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:42
1 FAMS @11230@
1 BIRT
2 DATE 17 JAN 1930
2 _FNA NO
1 DEAT
2 DATE 14 JAN 1991
2 _FNA NO
0 @11231@ INDI
1 NAME Claire/JULLIEN/
2 GIVN Claire
2 SURN JULLIEN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 MAR 2018
3 TIME 16:52
1 FAMS @11382@
1 FAMC @11230@
0 @11237@ INDI
1 NAME Frédérique/JULLIEN/
2 GIVN Frédérique
2 SURN JULLIEN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 MAR 2018
3 TIME 20:25
1 FAMC @11230@
0 @11242@ INDI
1 NAME Pascale/JULLIEN/
2 GIVN Pascale
2 SURN JULLIEN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 MAR 2018
3 TIME 20:29
1 FAMS @11253@
1 FAMC @11230@
0 @11247@ INDI
1 NAME Henri/LECONTE/
2 GIVN Henri
2 SURN LECONTE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JUN 2019
3 TIME 13:35
1 FAMS @11249@
1 FAMS @30965@
1 BIRT
2 DATE 29 MAR 1927
2 _FNA NO
1 DEAT
2 DATE 29 MAY 2019
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
2 NOTE Marie-France 2/06/19  : Henri Lecomte est décédé à la clinique Jeanne Garnier cette dernière semaine+ses obsèques auront lieu le mercredi 5 Juin à 11h30 au crématorium du Père Lachaise
1 BURI
2 DATE 5 JUN 2019
2 PLAC Paris 11,,,,,PERE LACHAISE
2 _FNA NO
0 @11251@ INDI
1 NAME Pierre/BARON/
2 GIVN Pierre
2 SURN BARON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 MAR 2018
3 TIME 20:29
1 FAMS @11253@
0 @11254@ INDI
1 NAME Flore/BARON/
2 GIVN Flore
2 SURN BARON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 MAR 2018
3 TIME 20:36
1 FAMS @11267@
1 BIRT
2 DATE 1980
2 _FNA NO
1 DEAT
2 DATE 26 AUG 2013
2 PLAC Montpellier,34000,Hérault,Occitanie,FRANCE,
3 MAP
4 LATI N43.610920
4 LONG E3.877230
2 _FNA NO
1 BURI CIMETIERRE SAINT LAZARE
2 DATE 30 AUG 2013
2 PLAC Montpellier,34000,Hérault,Occitanie,FRANCE,
3 MAP
4 LATI N43.610920
4 LONG E3.877230
2 _FNA NO
1 FAMC @11253@
0 @11265@ INDI
1 NAME Sébastien/NOBLECOURT/
2 GIVN Sébastien
2 SURN NOBLECOURT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 MAR 2018
3 TIME 20:32
1 FAMS @11267@
1 FAMC @11287@
0 @11269@ INDI
1 NAME Thaïs/NOBLECOURT/
2 GIVN Thaïs
2 SURN NOBLECOURT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 MAR 2018
3 TIME 20:30
1 FAMC @11267@
0 @11275@ INDI
1 NAME Mateo/NOBLECOURT/
2 GIVN Mateo
2 SURN NOBLECOURT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 MAR 2018
3 TIME 20:31
1 FAMC @11267@
0 @11280@ INDI
1 NAME Michel/NOBLECOURT/
2 GIVN Michel
2 SURN NOBLECOURT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 MAR 2018
3 TIME 20:32
1 FAMS @11287@
0 @11285@ INDI
1 NAME Laurence/?/
2 GIVN Laurence
2 SURN ?
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 MAR 2018
3 TIME 20:32
1 FAMS @11287@
0 @11300@ INDI
1 NAME Édouard/BECHET/
2 GIVN Édouard
2 SURN BECHET
1 SEX M
1 OCCU SECRETAIRE GENERAL DE L'ASSISTANCE PUBLIQUE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 17:59
1 FAMS @11955@
1 FAMC @11909@
0 @11308@ INDI
1 NAME Louis/BARTHOU/
2 GIVN Louis
2 SURN BARTHOU
1 SEX M
1 OCCU ACADEMIE FRANCAISE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 MAR 2018
3 TIME 22:08
0 @11312@ INDI
1 NAME Henri/CAPITANT/
2 GIVN Henri
2 SURN CAPITANT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 MAR 2018
3 TIME 22:08
0 @11323@ INDI
1 NAME Michel/DIETSCHY/
2 GIVN Michel
2 SURN DIETSCHY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 MAR 2018
3 TIME 7:27
1 FAMS @11325@
0 @11332@ INDI
1 NAME Raphaëlle/de CHEFDEBIEN-ÇAGARRIGA/
2 GIVN Raphaëlle
2 SURN de CHEFDEBIEN-ÇAGARRIGA
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 14:02
1 FAMS @11334@
1 FAMC @11338@
0 @11336@ INDI
1 NAME Hubert/de CHEFDEBIEN-ÇAGARRIGA/
2 GIVN Hubert
2 SURN de CHEFDEBIEN-ÇAGARRIGA
1 SEX M
1 TITL baron
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAY 2018
3 TIME 21:00
1 FAMS @11338@
0 @11346@ INDI
1 NAME Koen/PEETERS/
2 GIVN Koen
2 SURN PEETERS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 MAR 2018
3 TIME 7:37
0 @11351@ INDI
1 NAME Natacha/SAUPHANOR/
2 GIVN Natacha
2 SURN SAUPHANOR
1 SEX F
1 OCCU Professeur Agrégé De Droit
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:57
1 FAMS @11353@
0 @11355@ INDI
1 NAME Soliman/BROUILLAUD/
2 GIVN Soliman
2 SURN BROUILLAUD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 MAR 2018
3 TIME 16:44
1 FAMC @11353@
0 @11360@ INDI
1 NAME Jeanite/BROUILLAUD/
2 GIVN Jeanite
2 SURN BROUILLAUD
1 SEX F
1 _FIL ADOPTED_CHILD
1 CHAN
2 DATE 11 DEC 2019
3 TIME 15:02
1 FAMC @11353@
0 @11368@ INDI
1 NAME Catherine/?/
2 GIVN Catherine
2 SURN ?
1 SEX F
1 OCCU enseignante
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 DEC 2019
3 TIME 14:59
1 FAMS @11371@
0 @11372@ INDI
1 NAME Aurélien/BROUILLAUD/
2 GIVN Aurélien
2 SURN BROUILLAUD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 DEC 2019
3 TIME 15:00
1 FAMC @11371@
0 @11377@ INDI
1 NAME Ombeline/BROUILLAUD/
2 GIVN Ombeline
2 SURN BROUILLAUD
1 SEX F
1 OCCU Professeur de Maths
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 DEC 2019
3 TIME 15:01
1 FAMC @11371@
0 @11381@ INDI
1 NAME /GUILLON/
2 GIVN
2 SURN GUILLON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 MAR 2018
3 TIME 16:52
1 FAMS @11382@
0 @11385@ INDI
1 NAME Robert/LABAT/
2 GIVN Robert
2 SURN LABAT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 8:15
1 FAMS @11386@
1 BIRT
2 DATE 1898
2 _FNA NO
1 DEAT
2 DATE 1947
2 _FNA NO
1 FAMC @35392@
0 @11387@ INDI
1 NAME Jean-Dominique/LABAT/
2 GIVN Jean-Dominique
2 SURN LABAT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 MAR 2018
3 TIME 17:16
1 FAMC @11386@
0 @11392@ INDI
1 NAME Roland/LABAT/
2 GIVN Roland
2 SURN LABAT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 MAR 2018
3 TIME 17:20
1 FAMS @11403@
1 FAMC @11386@
0 @11397@ INDI
1 NAME Marie-Claude/LABAT/
2 GIVN Marie-Claude
2 SURN LABAT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 NOV 2019
3 TIME 20:09
1 FAMS @11414@
1 BIRT
2 DATE 1930
2 _FNA NO
1 DEAT
2 DATE 4 FEB 2012
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 BURI
2 DATE 9 FEB 2012
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
1 FAMC @11386@
0 @11401@ INDI
1 NAME Françoise/?/
2 GIVN Françoise
2 SURN ?
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 MAR 2018
3 TIME 17:20
1 FAMS @11403@
0 @11404@ INDI
1 NAME Jean/LABAT/
2 GIVN Jean
2 SURN LABAT
1 SEX M
1 OCCU chirurgien
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 MAR 2018
3 TIME 17:20
1 FAMC @11403@
0 @11412@ INDI
1 NAME Luc/ARSENE-HENRY/
2 GIVN Luc
2 SURN ARSENE-HENRY
1 SEX M
1 OCCU Architecte
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 NOV 2019
3 TIME 19:28
1 FAMS @11414@
1 BIRT
2 DATE 1923
2 _FNA NO
1 DEAT
2 DATE 1998
2 _FNA NO
1 FAMC @35659@
0 @11415@ INDI
1 NAME Véronique/ARSENE-HENRY/
2 GIVN Véronique
2 SURN ARSENE-HENRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 NOV 2019
3 TIME 19:19
1 BIRT
2 DATE 1954
2 _FNA NO
1 FAMC @11414@
0 @11421@ INDI
1 NAME Patrice/ARSENE-HENRY/
2 GIVN Patrice
2 SURN ARSENE-HENRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 NOV 2019
3 TIME 19:19
1 BIRT
2 DATE 1956
2 _FNA NO
1 CHR
2 _FNA NO
1 FAMC @11414@
0 @11427@ INDI
1 NAME Franck "Toy"/ARSENE-HENRY/
2 GIVN Franck "Toy"
2 SURN ARSENE-HENRY
2 NICK Toy
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 NOV 2019
3 TIME 19:20
1 BIRT
2 DATE 1958
2 _FNA NO
1 FAMC @11414@
0 @11433@ INDI
1 NAME Pascal/ARSENE-HENRY/
2 GIVN Pascal
2 SURN ARSENE-HENRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 MAR 2018
3 TIME 17:27
1 FAMS @11444@
1 FAMC @11414@
0 @11438@ INDI
1 NAME Sylvie/ARSENE-HENRY/
2 GIVN Sylvie
2 SURN ARSENE-HENRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 NOV 2019
3 TIME 19:27
1 FAMC @11414@
0 @11442@ INDI
1 NAME Véronique/de PALMAS/
2 GIVN Véronique
2 SURN de PALMAS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 MAR 2018
3 TIME 17:27
1 FAMS @11444@
0 @11446@ INDI
1 NAME Arthur/ARSENE-HENRY/
2 GIVN Arthur
2 SURN ARSENE-HENRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 NOV 2019
3 TIME 19:08
1 BIRT
2 DATE 2006
2 _FNA NO
1 FAMC @11444@
0 @11451@ INDI
1 NAME Emmanuel/de MITRY/
2 GIVN Emmanuel
2 SURN de MITRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 20 JUL 2019
3 TIME 11:31
1 FAMS @11467@
1 BIRT
2 DATE 1892
2 PLAC Nancy,54000,Meurthe-et-Moselle,Grand Est,FRANCE,
3 MAP
4 LATI N48.683330
4 LONG E6.200000
2 _FNA NO
1 DEAT
2 DATE 1983
2 PLAC Paris 09,,,,,12 RUE DE CLICHY
2 _FNA NO
1 BURI
2 DATE 1983
2 PLAC Florange,57190,Moselle,Grand Est,FRANCE,
3 MAP
4 LATI N49.323730
4 LONG E6.121200
2 _FNA NO
1 FAMC @12203@
0 @11460@ INDI
1 NAME Marguerite/de WENDEL/
2 GIVN Marguerite
2 SURN de WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 20 JUL 2019
3 TIME 11:31
1 FAMS @11467@
1 BIRT
2 DATE 1907
2 _FNA NO
1 DEAT
2 DATE 1976
2 PLAC Paris 09,,,,,
2 _FNA NO
1 FAMC @12153@
0 @11474@ INDI
1 NAME Yolande/de MITRY/
2 GIVN Yolande
2 SURN de MITRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 DEC 2018
3 TIME 11:41
1 FAMS @11537@
1 BIRT
2 DATE 1929
2 PLAC Paris 09,,,,,
2 _FNA NO
1 FAMC @11467@
0 @11482@ INDI
1 NAME François/de MITRY/
2 GIVN François
2 SURN de MITRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:45
1 BIRT
2 DATE 1931
2 _FNA NO
1 DEAT
2 DATE 1949
2 _FNA NO
2 CAUS NOYADE
1 FAMC @11467@
0 @11489@ INDI
1 NAME Odette/de MITRY/
2 GIVN Odette
2 SURN de MITRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 13:47
1 FAMS @14810@
1 BIRT
2 DATE 1930
2 _FNA NO
1 FAMC @11467@
0 @11496@ INDI
1 NAME Jacqueline/de MITRY/
2 GIVN Jacqueline
2 SURN de MITRY
1 SEX F
1 OCCU Médecin PSYCHIATRE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 10:22
1 FAMS @13821@
1 BIRT
2 DATE 1936
2 _FNA NO
1 FAMC @11467@
0 @11508@ INDI
1 NAME Henri/de MITRY/
2 GIVN Henri
2 SURN de MITRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 20 JUL 2019
3 TIME 11:26
1 FAMS @14880@
1 FAMS @14891@
1 BIRT
2 DATE 1940
2 PLAC Saint-Rémy-lès-Chevreuse,78470,Yvelines,Île-de-France,FRANCE,VAUGIEN
3 MAP
4 LATI N48.707080
4 LONG E2.076920
2 _FNA NO
1 FAMC @11467@
0 @11515@ INDI
1 NAME Nicole/de MITRY/
2 GIVN Nicole
2 SURN de MITRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 DEC 2018
3 TIME 11:23
1 FAMS @14921@
1 BIRT
2 DATE 1945
2 _FNA NO
1 DEAT
2 DATE 7 JUN 2018
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @11467@
0 @11522@ INDI
1 NAME Odile/de MITRY/
2 GIVN Odile
2 SURN de MITRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:32
1 FAMS @14901@
1 FAMS @19081@
1 BIRT
2 DATE 1942
2 _FNA NO
1 FAMC @11467@
0 @11531@ INDI
1 NAME Cyriac/d'IRUMBERRY de SALABERRY/
2 GIVN Cyriac
2 SURN d'IRUMBERRY de SALABERRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:35
1 FAMS @14901@
1 BIRT
2 DATE 15 MAY 1939
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @19103@
0 @11535@ INDI
1 NAME Maurice/LOMBARD de BUFFIERES de RAMBUTEAU/
2 GIVN Maurice
2 SURN LOMBARD de BUFFIERES de RAMBUTEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 DEC 2018
3 TIME 11:41
1 FAMS @11537@
1 BIRT
2 DATE 1929
2 _FNA NO
1 DEAT
2 DATE 1992
2 _FNA NO
1 FAMC @18835@
0 @11538@ INDI
1 NAME Emmanuel/de RAMBUTEAU/
2 GIVN Emmanuel
2 SURN de RAMBUTEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 12:41
1 FAMS @14737@
1 BIRT
2 DATE 1954
2 _FNA NO
1 FAMC @11537@
0 @11545@ INDI
1 NAME François/de RAMBUTEAU/
2 GIVN François
2 SURN de RAMBUTEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:36
1 FAMS @14756@
1 BIRT
2 DATE 1956
2 _FNA NO
1 FAMC @11537@
0 @11552@ INDI
1 NAME Patrice/de RAMBUTEAU/
2 GIVN Patrice
2 SURN de RAMBUTEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:38
1 FAMS @14783@
1 BIRT
2 DATE 1960
2 _FNA NO
1 FAMC @11537@
0 @11559@ INDI
1 NAME Lorraine/de RAMBUTEAU/
2 GIVN Lorraine
2 SURN de RAMBUTEAU
1 SEX F
1 OCCU Religieuse
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 12:43
1 BIRT
2 DATE 1964
2 _FNA NO
1 FAMC @11537@
0 @11566@ INDI
1 NAME Patrick Henri Gustave/de BAYSER/
2 GIVN Patrick Henri Gustave
2 SURN de BAYSER
1 SEX M
1 OCCU Négociant TABLEAUX DESSINS
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 OCT 2019
3 TIME 16:57
1 FAMS @11575@
1 BIRT
2 DATE 10 AUG 1901
2 PLAC Herblay,95220,Val-d'Oise,Île-de-France,FRANCE,
3 MAP
4 LATI N48.989940
4 LONG E2.169900
2 _FNA NO
1 BURI
2 DATE 1956
2 PLAC Herblay,95220,Val-d'Oise,Île-de-France,FRANCE,
3 MAP
4 LATI N48.989940
4 LONG E2.169900
2 _FNA NO
1 DEAT
2 DATE 9 DEC 1956
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @31975@
0 @11573@ INDI
1 NAME Rose-Anne Gabrielle Émilie Marie Joseph/WIBAUX/
2 GIVN Rose-Anne Gabrielle Émilie Marie Joseph
2 SURN WIBAUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 OCT 2019
3 TIME 16:55
1 FAMS @11575@
1 BIRT
2 DATE 20 JAN 1908
2 PLAC Roubaix,59100,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.694210
4 LONG E3.174560
2 _FNA NO
1 BURI
2 DATE JAN 1993
2 PLAC Herblay,95220,Val-d'Oise,Île-de-France,FRANCE,
3 MAP
4 LATI N48.989940
4 LONG E2.169900
2 _FNA NO
1 DEAT
2 DATE 11 JAN 1993
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
2 NOTE ou 1992?
1 FAMC @31944@
0 @11597@ INDI
1 NAME /JOSSIER/
2 GIVN
2 SURN JOSSIER
1 SEX M
1 OCCU Prêtre
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 18:17
0 @11601@ INDI
1 NAME Mme/COCHINETTE/
2 GIVN Mme
2 SURN COCHINETTE
1 SEX M
1 OCCU journalière
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 18:18
0 @11615@ INDI
1 NAME Jean/BUFFETEAU/
2 GIVN Jean
2 SURN BUFFETEAU
1 SEX M
1 OCCU Cultivateur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 18:29
1 FAMS @11629@
1 DEAT
2 DATE 11 FEB 1844
2 PLAC Migré,17330,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.074000
4 LONG W0.557390
2 _FNA NO
1 BIRT
2 PLAC Migré,17330,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.074000
4 LONG W0.557390
2 _FNA NO
1 NOTE et sacristain
0 @11627@ INDI
1 NAME Marie/ENGIBEAU/
2 GIVN Marie
2 SURN ENGIBEAU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 18:25
1 FAMS @11629@
0 @11636@ INDI
1 NAME Jacques/GOIZIN/
2 GIVN Jacques
2 SURN GOIZIN
1 SEX M
1 OCCU Cultivateur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 18:28
1 FAMS @11648@
0 @11644@ INDI
1 NAME Louise/TIERCE/
2 GIVN Louise
2 SURN TIERCE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 18:28
1 FAMS @11648@
1 BIRT
2 PLAC Ardillières,17290,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.058780
4 LONG W0.892720
2 _FNA NO
0 @11656@ INDI
1 NAME Jean/BUFFETEAU/
2 GIVN Jean
2 SURN BUFFETEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 18:31
1 BIRT
2 DATE 24 APR 1824
2 _FNA NO
1 DEAT
2 DATE 25 APR 1824
2 _FNA NO
1 FAMC @8680@
0 @11667@ INDI
1 NAME Marie/BUFFETEAU/
2 GIVN Marie
2 SURN BUFFETEAU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 18:32
1 BIRT
2 DATE 15 JUL 1825
2 _FNA NO
1 DEAT
2 DATE 6 OCT 1826
2 _FNA NO
1 FAMC @8680@
0 @11677@ INDI
1 NAME Louis/BUFFETEAU/
2 GIVN Louis
2 SURN BUFFETEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 18:33
1 BIRT
2 DATE 10 OCT 1829
2 _FNA NO
1 DEAT
2 DATE 2 FEB 1831
2 _FNA NO
1 FAMC @8680@
0 @11687@ INDI
1 NAME Louise/BUFFETEAU/
2 GIVN Louise
2 SURN BUFFETEAU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 19:28
1 FAMS @11709@
1 BIRT
2 DATE 30 NOV 1831
2 _FNA NO
1 FAMC @8680@
0 @11703@ INDI
1 NAME François/MAIRE/
2 GIVN François
2 SURN MAIRE
1 SEX M
1 OCCU Cordonnier
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 18:59
1 FAMS @11709@
1 BIRT
2 PLAC Lozay,17330,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.042020
4 LONG W0.546740
2 _FNA NO
0 @11737@ INDI
1 NAME Anna/SWIATOPOLK CZETWERTYNSKA/
2 GIVN Anna
2 SURN SWIATOPOLK CZETWERTYNSKA
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 19:20
1 FAMS @11744@
1 BIRT
2 DATE 1840
2 _FNA NO
1 DEAT
2 DATE 1921
2 _FNA NO
0 @11759@ INDI
1 NAME Pierre/LAROCHE/
2 GIVN Pierre
2 SURN LAROCHE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 19:31
0 @11775@ INDI
1 NAME Victor Joseph/MESTADIER/
2 GIVN Victor Joseph
2 SURN MESTADIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 18:18
1 BIRT
2 DATE 27 APR 1806
2 PLAC Saint-Denis-du-Pin,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.983330
4 LONG W0.516670
2 _FNA NO
1 FAMC @8628@
0 @11785@ INDI
1 NAME Joseph Augustin/MESTADIER/
2 GIVN Joseph Augustin
2 SURN MESTADIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 18:14
1 BIRT
2 DATE 20 APR 1798
2 PLAC Saint-Jean-d'Angély,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.950000
4 LONG W0.516670
2 _FNA NO
1 DEAT
2 DATE APR 1811
2 PLAC Saint-Denis-du-Pin,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.983330
4 LONG W0.516670
2 _FNA NO
1 BURI
2 DATE 7 APR 1811
2 PLAC Saint-Denis-du-Pin,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.983330
4 LONG W0.516670
2 _FNA NO
1 FAMC @8628@
0 @11795@ INDI
1 NAME Marie Rosalie Désirée Hermance/MESTADIER/
2 GIVN Marie Rosalie Désirée Hermance
2 SURN MESTADIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 18:10
1 FAMS @12932@
1 BIRT
2 DATE 16 JUL 1799
2 _FNA NO
1 DEAT
2 DATE 28 JUN 1874
2 PLAC Saint-Denis-du-Pin,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.983330
4 LONG W0.516670
2 _FNA NO
1 FAMC @8628@
0 @11801@ INDI
1 NAME Joseph Eugène/MESTADIER/
2 GIVN Joseph Eugène
2 SURN MESTADIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 18:22
1 BIRT
2 DATE 6 DEC 1807
2 PLAC Saint-Denis-du-Pin,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.983330
4 LONG W0.516670
2 _FNA NO
1 FAMC @8628@
0 @11807@ INDI
1 NAME Rosalie Pauline/MESTADIER/
2 GIVN Rosalie Pauline
2 SURN MESTADIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 18:22
1 BIRT
2 DATE 14 JUN 1811
2 PLAC Saint-Denis-du-Pin,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.983330
4 LONG W0.516670
2 _FNA NO
1 DEAT
2 DATE 1898
2 PLAC Saint-Denis-du-Pin,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.983330
4 LONG W0.516670
2 _FNA NO
1 FAMC @8628@
0 @11813@ INDI
1 NAME Joseph Martial Paulin/MESTADIER/
2 GIVN Joseph Martial Paulin
2 SURN MESTADIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 18:39
1 FAMS @13020@
1 BIRT
2 DATE 15 SEP 1813
2 PLAC Saint-Denis-du-Pin,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.983330
4 LONG W0.516670
2 _FNA NO
1 FAMC @8628@
0 @11819@ INDI
1 NAME Rosalie Victorine/MESTADIER/
2 GIVN Rosalie Victorine
2 SURN MESTADIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 18:53
1 BIRT
2 DATE 26 APR 1815
2 PLAC Saint-Denis-du-Pin,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.983330
4 LONG W0.516670
2 _FNA NO
1 FAMC @8628@
0 @11840@ INDI
1 NAME Michel Alexandre/de MESCHINET/
2 GIVN Michel Alexandre
2 SURN de MESCHINET
1 SEX M
1 OCCU Prêtre
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:02
1 BIRT
2 DATE 1809
2 PLAC Antezant-la-Chapelle,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.982710
4 LONG W0.455850
2 _FNA NO
1 DEAT
2 DATE 27 NOV 1878
2 PLAC Montlieu-la-Garde,17210,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.250000
4 LONG W0.250000
2 _FNA NO
1 FAMC @13112@
0 @11857@ INDI
1 NAME Hubert/PICTET/
2 GIVN Hubert
2 SURN PICTET
1 SEX M
1 OCCU Écrivain
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 20:15
1 FAMS @11863@
1 FAMC @11879@
0 @11861@ INDI
1 NAME Claude/MERENDA/
2 GIVN Claude
2 SURN MERENDA
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 20:14
1 FAMS @11863@
1 FAMC @11892@
0 @11866@ INDI
1 NAME Richard/PICTET/
2 GIVN Richard
2 SURN PICTET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 20:03
1 FAMS @11879@
1 BIRT
2 DATE 1887
2 _FNA NO
1 DEAT
2 DATE 1975
2 _FNA NO
0 @11876@ INDI
1 NAME /de POURTALES/
2 GIVN
2 SURN de POURTALES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 20:05
1 FAMS @11879@
1 BIRT
2 DATE 1893
2 _FNA NO
1 DEAT
2 DATE 1918
2 _FNA NO
1 FAMC @11883@
0 @11881@ INDI
1 NAME Henri/BARBEY/
2 GIVN Henri
2 SURN BARBEY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 20:06
1 FAMS @11883@
1 FAMC @11886@
0 @11885@ INDI
1 NAME /BARBEY/
2 GIVN
2 SURN BARBEY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 20:06
1 FAMS @11886@
0 @11888@ INDI
1 NAME William/BARBEY/
2 GIVN William
2 SURN BARBEY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 20:06
1 FAMC @11886@
0 @11891@ INDI
1 NAME /MERENDA/
2 GIVN
2 SURN MERENDA
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 20:08
1 FAMS @11892@
0 @11894@ INDI
1 NAME Nicole/MERENDA/
2 GIVN Nicole
2 SURN MERENDA
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 20:09
1 FAMS @11907@
1 FAMC @11892@
0 @11898@ INDI
1 NAME Hervé/ALPHAND/
2 GIVN Hervé
2 SURN ALPHAND
1 SEX M
1 OCCU Ambassadeur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 20:09
1 FAMS @11907@
1 BIRT
2 DATE 1907
2 _FNA NO
1 DEAT
2 DATE 1994
2 _FNA NO
0 @11908@ INDI
1 NAME /BECHET/
2 GIVN
2 SURN BECHET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 20:21
1 FAMS @11909@
1 FAMC @11920@
0 @11912@ INDI
1 NAME Jean-Baptiste/BECHET/
2 GIVN Jean-Baptiste
2 SURN BECHET
1 SEX M
1 OCCU Commissaire DU GOUVERNEMENT
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 20:19
1 FAMS @11920@
1 BIRT
2 DATE 1759
2 PLAC Besançon,25000,Doubs,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.248780
4 LONG E6.018150
2 _FNA NO
1 DEAT
2 DATE 1828
2 _FNA NO
0 @11922@ INDI
1 NAME Eugène/BECHET/
2 GIVN Eugène
2 SURN BECHET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 20:26
1 FAMS @11931@
1 FAMS @11952@
1 FAMC @11909@
0 @11927@ INDI
1 NAME Nadouba/?/
2 GIVN Nadouba
2 SURN ?
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 20:25
1 FAMS @11931@
1 BIRT
2 PLAC Soudan,,,,,
2 _FNA NO
0 @11932@ INDI
1 NAME XX/BECHET/
2 GIVN XX
2 SURN BECHET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 20:24
1 BIRT
2 PLAC Soudan,,,,,
2 _FNA NO
1 FAMC @11931@
0 @11936@ INDI
1 NAME XX/BECHET/
2 GIVN XX
2 SURN BECHET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 20:24
1 BIRT
2 PLAC Soudan,,,,,
2 _FNA NO
1 FAMC @11931@
0 @11940@ INDI
1 NAME Xy/BECHET/
2 GIVN Xy
2 SURN BECHET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 20:25
1 BIRT
2 PLAC Soudan,,,,,
2 _FNA NO
1 FAMC @11931@
0 @11948@ INDI
1 NAME Xxx/?/
2 GIVN Xxx
2 SURN ?
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 20:26
1 FAMS @11952@
1 BIRT
2 PLAC Algerie,,,,,
2 _FNA NO
0 @11954@ INDI
1 NAME /COURTIN/
2 GIVN
2 SURN COURTIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 17:59
1 FAMS @11955@
1 FAMC @11968@
0 @11957@ INDI
1 NAME Louis Michel/COURTIN/
2 GIVN Louis Michel
2 SURN COURTIN
1 SEX M
1 OCCU Officier de Marine
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 20:30
1 FAMS @11968@
1 BIRT
2 DATE 1783
2 _FNA NO
1 DEAT
2 DATE 1874
2 _FNA NO
0 @11975@ INDI
1 NAME Ludovic/TRON/
2 GIVN Ludovic
2 SURN TRON
1 SEX M
1 OCCU Inspecteur général des finances
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 6:26
1 FAMS @11984@
1 BIRT
2 DATE 1904
2 _FNA NO
1 DEAT
2 DATE 24 OCT 1968
2 PLAC Embrun,05200,Hautes-Alpes,Provence-Alpes-Côte d'Azur,FRANCE,
3 MAP
4 LATI N44.563870
4 LONG E6.495260
2 _FNA NO
1 FAMC @12031@
0 @11982@ INDI
1 NAME Janina/MOTTET/
2 GIVN Janina
2 SURN MOTTET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 6:23
1 FAMS @11984@
1 FAMC @12070@
0 @11988@ INDI
1 NAME Jean-Michel/TRON/
2 GIVN Jean-Michel
2 SURN TRON
1 SEX M
1 OCCU avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 20:37
1 FAMC @11984@
0 @11994@ INDI
1 NAME Marie-Dominique/TRON/
2 GIVN Marie-Dominique
2 SURN TRON
1 SEX F
1 OCCU ingénieur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 20:37
1 FAMC @11984@
0 @12000@ INDI
1 NAME François/TRON/
2 GIVN François
2 SURN TRON
1 SEX M
1 OCCU médecin
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 20:38
1 FAMC @11984@
0 @12013@ INDI
1 NAME André/TRON/
2 GIVN André
2 SURN TRON
1 SEX M
1 OCCU DOUANIER
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 20:42
1 FAMS @12031@
1 BIRT
2 DATE 1861
2 _FNA NO
1 DEAT
2 DATE 1923
2 _FNA NO
0 @12024@ INDI
1 NAME Catherine/MARTIN/
2 GIVN Catherine
2 SURN MARTIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 MAR 2018
3 TIME 20:42
1 FAMS @12031@
1 BIRT
2 DATE 1871
2 _FNA NO
1 DEAT
2 DATE 1958
2 _FNA NO
0 @12038@ INDI
1 NAME Jean Joseph Marie Gabriel/de LATTRE de TASSIGNY/
2 GIVN Jean Joseph Marie Gabriel
2 SURN de LATTRE de TASSIGNY
1 SEX M
1 OCCU Maréchal DE FRANCE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2019
3 TIME 11:48
1 FAMS @12100@
1 BIRT
2 DATE 2 FEB 1889
2 PLAC Mouilleron-en-Pareds,85390,Vendée,Pays de la Loire,FRANCE,
3 MAP
4 LATI N46.676300
4 LONG W0.849400
2 _FNA NO
1 DEAT
2 DATE 11 JAN 1952
2 PLAC Neuilly-sur-Seine,92200,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883330
4 LONG E2.266670
2 _FNA NO
2 CAUS CANCER
2 NOTE mort pour la France
1 BURI
2 DATE 20 JAN 1952
2 PLAC Mouilleron-en-Pareds,85390,Vendée,Pays de la Loire,FRANCE,
3 MAP
4 LATI N46.676300
4 LONG W0.849400
2 _FNA NO
2 NOTE Nommé Maréchal à titre posthume, le 15 janvier 1952,le Président Hériot proclame le 19 janvier que les funérailles nationales auront lieu le 20 janvier 1952.
1 NOTE General de la 1ere Armée (1944-1945).
2 CONT Compagnon de la Libération. Grand Croix de la Légion d'honneur. Grand Croix d'Orange-Nassau (Pays-Bas). Grand Croix de Léopold (Belgique) et de Saint Olav (Norvège). Croix de guerre 14-18 (8 citations). Croix de guerre 39-45. Croix de guerre des T.
2 CONC O.E (3 citations). Croix de guerre belge et de Tchécoslovaquie. Médaille des évadés. Médaille militaire. Military Cross (Royaume-Uni). Médaille de Souvorov (URSS), ... ...
2 CONT
2 CONT Né d'une vieille famille aristocratique des Flandres françaises, et frère cadet d'Anne-Marie, il reçoit une éducation de qualité au collège Saint Joseph de Poitiers.
2 CONT
2 CONT
2 CONT De 1898 à 1904, Jean prépare l'École navale et Saint-Cyr où il est reçu en 1908. Il effectue ses classes au 29e Dragons à Provins. Il est élève de Saint-Cyr de 1909 à 1911, dans la promotion « Mauritanie » dont il sort 4e de promotion. Il entre en
2 CONC 1911 à l'école de cavalerie de Saumur.
2 CONT
2 CONT 12e Dragons à Pont-à-Mousson puis sur le front. Il est blessé une première fois le 11 août 1914 par un éclat d'obus au cours d'une reconnaissance. Le 14 septembre, il est blessé d'un coup de lance d'un Uhlan en chargeant à la tête de son peloton de
2 CONC  dragons. Affaibli par sa blessure, obligé de sa cacher dans Pont-à-Mousson occupée par les Allemands, il est sauvé de la capture par un officier du 5e régiment de hussards en mission de reconnaissance, le sous-lieutenant Schmeltz.
2 CONT
2 CONT Il est ensuite capitaine du 93e Régiment d'Infanterie et termine la guerre avec 4 blessures et 8 citations.
2 CONT
2 CONT Il est ensuite affecté au 49e Régiment d'Infanterie de 1919 à 1921 à Bayonne. En 1921 il est envoyé au Maroc dans le 3e bureau et dans l'état-major de la région de Taza jusqu'en 1926. De 1927 à 1929 il suit les cours de l'école de guerre avec la 49
2 CONC e promotion. Il se marie avec Simone Calary de Lamazière en 1927, et ils auront un fils, Bernard, en 1928. En 1929 il devient chef de bataillon au 5e Régiment d'Infanterie à Coulommiers. En 1932 il est promu à l'état-major de l'armée puis à celui d
2 CONC u général Maxime Weygand, vice-président du Conseil Supérieur de la Guerre, au grade de lieutenant-colonel. En 1935 il devient colonel, commandant le 151e Régiment d'Infanterie à Metz. Entre 1937 et 1938 il suit des cours au centre des hautes étude
2 CONC s militaires et devient en 1938 chef d'état-major du gouverneur de Strasbourg.
2 CONT
2 CONT Promu général de brigade le 23 mars 1939 il est chef d'état-major de la Ve armée le 2 septembre 1939. Le 1er janvier 1940 il prend le commandement de la 14e Division d'Infanterie qu'il commande pendant les affrontements avec la Wehrmacht à Rethel,
2 CONC où sa division résiste héroïquement, jusqu'à la Champagne et l'Yonne, et conserve miraculeusement sa cohésion militaire au milieu du chaos de la débâcle. De juillet 1940 à septembre 1941, il est adjoint au général commandant la 13e région militaire
2 CONC  à Clermont-Ferrand puis devient général de division commandant des troupes de Tunisie jusqu'à la fin 1941. Par la suite il commande la 16e Division à Montpellier et est promu général de corps d'armée.
2 CONT
2 CONT Lorsque la zone libre est envahie par les troupes allemandes il refuse l'ordre de ne pas combattre et est arrêté. Il est condamné à 10 ans de prison par le tribunal d'État de la section de Lyon le 9 janvier 1943.
2 CONT
2 CONT Parvenant à s'évader de la prison de Riom le 3 septembre 1943 il rejoint Londres puis Alger où il arrive le 20 décembre 1943 après avoir été promu au rang de général d'armée le 11 novembre 1943 par le général de Gaulle. En décembre 1943 il commande
2 CONC  l'Armée B, qui devient la Première Armée française. Il débarque en Provence le 16 août 1944, prend Toulon et Marseille, remonte la vallée du Rhône, puis le Rhin, libère l'Alsace, et entre en Allemagne jusqu'au Danube. Il représenta la France à la
2 CONC signature de l'armistice du 8 mai 1945 à Berlin au quartier général du Maréchal Joukov.
2 CONT
2 CONT Entre décembre 1945 et mars 1947, il est inspecteur général et chef d'état-major général de l'armée. En mars 1947 il est inspecteur général de l'armée, puis inspecteur général des forces armées. D'octobre 1948 à décembre 1950, il est commandant en
2 CONC chef des armées de l'Europe occidentale à Fontainebleau.
2 CONT
2 CONT Il devint haut-commissaire et commandant en chef en Indochine et commandant en chef en Extrême-Orient (1950-1952) et met sur pied une armée nationale vietnamienne. Épuisé par le surmenage auquel il s'est astreint tout au long de sa carrière et que
2 CONC n'a pas arrangé sa blessure reçue en 1914, très affecté par la mort de son fils Bernard, tué au cours de la campagne d'Indochine, et atteint d'un cancer de la hanche, il meurt à Paris le 11 janvier 1952 des suites d'une opération. Il est élevé à la
2 CONC  dignité de maréchal de France, à titre posthume, lors de ses funérailles le 15 janvier 1952. Il est inhumé dans son village natal de Mouilleron-en-Pareds.
2 CONT
0 @12052@ INDI
1 NAME Jean-Baptiste/MOTTET/
2 GIVN Jean-Baptiste
2 SURN MOTTET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 6:23
1 FAMS @12070@
1 BIRT
2 DATE 1885
2 _FNA NO
1 DEAT
2 DATE 1960
2 _FNA NO
0 @12063@ INDI
1 NAME Lucie/VIOU/
2 GIVN Lucie
2 SURN VIOU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 6:23
1 FAMS @12070@
1 BIRT
2 DATE 1889
2 _FNA NO
1 DEAT
2 DATE 1960
2 _FNA NO
0 @12092@ INDI
1 NAME Charles/BOUSQUET/
2 GIVN Charles
2 SURN BOUSQUET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 6:33
0 @12096@ INDI
1 NAME Simone/CALARY de LAMAZIERE/
2 GIVN Simone
2 SURN CALARY de LAMAZIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2019
3 TIME 11:48
1 FAMS @12100@
1 BIRT
2 DATE 7 NOV 1906
2 _FNA NO
1 DEAT
2 DATE 3 JUN 2003
2 PLAC Paris 05,,,,,val de grâce
2 _FNA NO
0 @12102@ INDI
1 NAME Bernard Jean Marie Michel/de LATTRE de TASSIGNY/
2 GIVN Bernard Jean Marie Michel
2 SURN de LATTRE de TASSIGNY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2019
3 TIME 11:22
1 BIRT
2 DATE 11 FEB 1928
2 _FNA NO
1 DEAT
2 DATE 30 MAY 1951
2 _FNA NO
2 CAUS mort pour la France
1 FAMC @12100@
0 @12131@ INDI
1 NAME Albert/de BOUCHEMAN/
2 GIVN Albert
2 SURN de BOUCHEMAN
1 SEX M
1 OCCU Militaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 6:39
0 @12138@ INDI
1 NAME François/de WENDEL/
2 GIVN François
2 SURN de WENDEL
1 SEX M
1 OCCU REGENT BANQUE DE FRANCE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 7 APR 2018
3 TIME 13:54
1 FAMS @12153@
1 BIRT
2 DATE 1874
2 _FNA NO
1 DEAT
2 DATE 1949
2 _FNA NO
1 FAMC @13892@
0 @12151@ INDI
1 NAME Odette/HUMANN/
2 GIVN Odette
2 SURN HUMANN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 7 APR 2018
3 TIME 13:54
1 FAMS @12153@
1 BIRT
2 DATE 1884
2 _FNA NO
1 DEAT
2 DATE 1954
2 _FNA NO
1 FAMC @12158@
0 @12156@ INDI
1 NAME ?/HUMANN/
2 GIVN ?
2 SURN HUMANN
1 SEX M
1 OCCU AMIRAL
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 6:46
1 FAMS @12158@
1 FAMC @12164@
0 @12161@ INDI
1 NAME Jules/HUMANN/
2 GIVN Jules
2 SURN HUMANN
1 SEX M
1 OCCU MAIRE DE STRASBOURG
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 6:47
1 FAMS @12164@
1 FAMC @12170@
0 @12167@ INDI
1 NAME Jean Georges/HUMANN/
2 GIVN Jean Georges
2 SURN HUMANN
1 SEX M
1 OCCU ministre des finances de LOUIS-PHILIPPE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 6:47
1 FAMS @12170@
0 @12174@ INDI
1 NAME Marie-Thérèse/de MITRY/
2 GIVN Marie-Thérèse
2 SURN de MITRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 10:04
1 FAMS @12186@
1 BIRT
2 DATE 1933
2 PLAC Paris 09,,,,,
2 _FNA NO
1 FAMC @11467@
0 @12182@ INDI
1 NAME Jean/FRANÇOIS-PONCET/
2 GIVN Jean
2 SURN FRANÇOIS-PONCET
1 SEX M
1 OCCU Diplomate
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 DEC 2018
3 TIME 11:16
1 FAMS @12186@
1 BIRT
2 DATE 8 DEC 1928
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 18 JUL 2012
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 NOTE MINISTRE DES AFFAIRES ETRANGERES DE VGE
1 FAMC @18912@
0 @12190@ INDI
1 NAME Valéry/GISCARD d'ESTAING/
2 GIVN Valéry
2 SURN GISCARD d'ESTAING
1 SEX M
1 OCCU PRESIDENT DE LA REPUBLIQUE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 6:52
0 @12194@ INDI
1 NAME Georges/de MITRY/
2 GIVN Georges
2 SURN de MITRY
1 SEX M
1 OCCU Militaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 7:39
1 FAMS @12203@
1 BIRT
2 DATE 1856
2 PLAC Leménil-Mitry,54740,Meurthe-et-Moselle,Grand Est,FRANCE,
3 MAP
4 LATI N48.453560
4 LONG E6.252390
2 _FNA NO
1 DEAT
2 DATE 1901
2 PLAC Zürich,8000,Zurich,Zurich,SUISSE,
3 MAP
4 LATI N47.366670
4 LONG E8.550000
2 _FNA NO
2 CAUS CHUTE DE CHEVAL
1 FAMC @18578@
0 @12201@ INDI
1 NAME Marie-Thérèse/de GARGAN/
2 GIVN Marie-Thérèse
2 SURN de GARGAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 7:34
1 FAMS @12203@
1 BIRT
2 DATE 1860
2 PLAC Metz,57000,Moselle,Grand Est,FRANCE,
3 MAP
4 LATI N49.119110
4 LONG E6.172690
2 _FNA NO
1 DEAT
2 DATE 1914
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 BURI
2 DATE 1914
2 PLAC Leménil-Mitry,54740,Meurthe-et-Moselle,Grand Est,FRANCE,
3 MAP
4 LATI N48.453560
4 LONG E6.252390
2 _FNA NO
1 FAMC @14966@
0 @12206@ INDI
1 NAME Charles-Joseph/de GARGAN/
2 GIVN Charles-Joseph
2 SURN de GARGAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 12:27
1 FAMS @12208@
1 FAMS @14966@
1 BIRT
2 DATE 1831
2 _FNA NO
1 DEAT
2 DATE 1920
2 _FNA NO
1 FAMC @15605@
0 @12211@ INDI
1 NAME Jeanne/de GARGAN/
2 GIVN Jeanne
2 SURN de GARGAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 17:50
1 BIRT
2 DATE 1864
2 _FNA NO
1 DEAT
2 DATE 1949
2 _FNA NO
1 FAMC @12208@
0 @12215@ INDI
1 NAME Thérèse/de GARGAN/
2 GIVN Thérèse
2 SURN de GARGAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 17:52
1 FAMS @12226@
1 BIRT
2 DATE 1903
2 _FNA NO
1 DEAT
2 DATE 1996
2 _FNA NO
1 FAMC @17612@
0 @12221@ INDI
1 NAME Philippe/LECLERC de HAUTECLOQUE/
2 GIVN Philippe
2 SURN LECLERC de HAUTECLOQUE
1 SEX M
1 OCCU Maréchal DE FRANCE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 17:51
1 FAMS @12226@
1 BIRT
2 DATE 1902
2 _FNA NO
1 DEAT
2 DATE 1947
2 _FNA NO
0 @12237@ INDI
1 NAME Henri/TYREL de POIX/
2 GIVN Henri
2 SURN TYREL de POIX
1 SEX M
1 OCCU Industriel
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 8:30
1 FAMS @12252@
1 BIRT
2 DATE 1883
2 _FNA NO
1 DEAT
2 DATE 1962
2 _FNA NO
1 FAMC @18759@
0 @12249@ INDI
1 NAME Marie-Thérèse/de FOUCAULT/
2 GIVN Marie-Thérèse
2 SURN de FOUCAULT
1 SEX F
1 OCCU Infirmière
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 7:12
1 FAMS @12252@
0 @12255@ INDI
1 NAME Pierre/TYREL de POIX/
2 GIVN Pierre
2 SURN TYREL de POIX
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 7:14
1 FAMS @12262@
1 NOTE CREATEUR DE MPO
1 FAMC @12252@
0 @12260@ INDI
1 NAME Monique/?/
2 GIVN Monique
2 SURN ?
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 7:13
1 FAMS @12262@
0 @12264@ INDI
1 NAME Philippe/LESBRE/
2 GIVN Philippe
2 SURN LESBRE
1 SEX M
1 OCCU Médecin
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 7:19
1 FAMS @12272@
1 NOTE CHERCHEUR A PASTEUR
1 FAMC @12295@
0 @12270@ INDI
1 NAME Marie/GIRALT/
2 GIVN Marie
2 SURN GIRALT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 7:16
1 FAMS @12272@
0 @12276@ INDI
1 NAME Jacques/AUBRY/
2 GIVN Jacques
2 SURN AUBRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 7:17
1 FAMS @12283@
1 BIRT
2 DATE 1917
2 _FNA NO
1 DEAT
2 DATE 1995
2 _FNA NO
0 @12291@ INDI
1 NAME François-Xavier/LESBRE/
2 GIVN François-Xavier
2 SURN LESBRE
1 SEX M
1 OCCU Vétérinaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 7:19
1 FAMS @12295@
0 @12300@ INDI
1 NAME Léon Alphonse/JACQUIN/
2 GIVN Léon Alphonse
2 SURN JACQUIN
1 SEX M
1 OCCU Militaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 7:25
1 FAMS @12316@
1 DEAT
2 DATE MAY 1943
2 PLAC Nice,06000,Alpes-Maritimes,Provence-Alpes-Côte d'Azur,FRANCE,
3 MAP
4 LATI N43.703130
4 LONG E7.266080
2 _FNA NO
0 @12313@ INDI
1 NAME Louise Espérance/SELLIER/
2 GIVN Louise Espérance
2 SURN SELLIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 7:23
1 FAMS @12316@
0 @12321@ INDI
1 NAME Ernest Victor/TABURET/
2 GIVN Ernest Victor
2 SURN TABURET
1 SEX M
1 OCCU Médecin
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 7:31
1 FAMS @12341@
1 BIRT
2 DATE 1846
2 _FNA NO
1 DEAT
2 DATE 1923
2 _FNA NO
1 FAMC @12363@
0 @12339@ INDI
1 NAME Marie-Zélie/LAPORTE/
2 GIVN Marie-Zélie
2 SURN LAPORTE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 7:28
1 FAMS @12341@
0 @12344@ INDI
1 NAME François Marie Joseph/TABURET/
2 GIVN François Marie Joseph
2 SURN TABURET
1 SEX M
1 OCCU médecin
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 15:36
1 FAMS @12363@
1 BIRT
2 DATE 6 JUL 1806
2 PLAC Saint-Renan,29290,Finistère,Bretagne,FRANCE,
3 MAP
4 LATI N48.433330
4 LONG W4.616670
2 _FNA NO
1 DEAT
2 DATE 17 DEC 1861
2 PLAC Lanrivoaré,29290,Finistère,Bretagne,FRANCE,
3 MAP
4 LATI N48.466670
4 LONG W3.633330
2 _FNA NO
1 NOTE Père: Etienne Pierre Julien Taburet
2 CONT Mère: Marie Isabelle Taburet (née Fyot)
2 CONT Femme: Virginie Marie Jeanne Taburet (née Laporte)
2 CONT Enfant: Jules Taburet, Hippolyte Alphonse Marie Taburet, Mathilde Ambroisine Marie (née Taburet), Ernest Victor Marie Taburet
2 CONT Frères et sœurs: Ambroise Joseph Marie Taburet, Denis Ange Marie Taburet, Alexandrine Louise Marie Antoinette (née Taburet), Etienne Louis Marie Taburet, Angélique Marie Judith (née Taburet), Marie Ambroisine Françoise (née Taburet), Jean Baptiste
2 CONC Marie Taburet, Hippolyte Pierre Marie Taburet, Victor Marie Morice Taburet
2 CONT
2 CONT
2 CONT
2 CONT
2 CONT
2 CONT
2 CONT
2 CONT
1 FAMC @12383@
0 @12356@ INDI
1 NAME Virginie Marie Jeanne/LAPORTE/
2 GIVN Virginie Marie Jeanne
2 SURN LAPORTE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 15:33
1 FAMS @12363@
1 BIRT
2 DATE 1818
2 _FNA NO
1 DEAT
2 DATE 1863
2 _FNA NO
0 @12367@ INDI
1 NAME Étienne Pierre Julien/TABURET/
2 GIVN Étienne Pierre Julien
2 SURN TABURET
1 SEX M
1 OCCU Percepteur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 15:33
1 FAMS @12383@
1 BIRT
2 DATE 1771
2 _FNA NO
1 DEAT
2 DATE 1838
2 _FNA NO
1 FAMC @12393@
0 @12380@ INDI
1 NAME Marie Isabelle/FYOT de KEROUANEN/
2 GIVN Marie Isabelle
2 SURN FYOT de KEROUANEN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 15:33
1 FAMS @12383@
1 BIRT
2 DATE 1772
2 _FNA NO
1 DEAT
2 DATE 1852
2 _FNA NO
0 @12386@ INDI
1 NAME Étienne/TABURET/
2 GIVN Étienne
2 SURN TABURET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 7:36
1 FAMS @12393@
1 BIRT
2 DATE 1748
2 _FNA NO
1 DEAT
2 DATE 1789
2 PLAC Ploumoguer,29810,Finistère,Bretagne,FRANCE,
3 MAP
4 LATI N48.400000
4 LONG W4.716670
2 _FNA NO
0 @12398@ INDI
1 NAME Lucien/LORENZ DIT TARDIEU/
2 GIVN Lucien
2 SURN LORENZ DIT TARDIEU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 APR 2018
3 TIME 21:56
1 FAMS @12427@
1 BIRT
2 DATE 1870
2 PLAC Cherchell,,,,,
2 _FNA NO
1 NOTE ne s’occupa jamais de sa famille qu’il abandonna peu de temps après la naissance de Simone et disparut sans plus donner aucun signe de vie
1 FAMC @12412@
0 @12405@ INDI
1 NAME Lucien/LORENZ DIT TARDIEU/
2 GIVN Lucien
2 SURN LORENZ DIT TARDIEU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 9:44
1 FAMS @12412@
0 @12410@ INDI
1 NAME Caroline/TARDIEU/
2 GIVN Caroline
2 SURN TARDIEU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 9:44
1 FAMS @12412@
0 @12416@ INDI
1 NAME Marguerite Charlotte/CARRÉ/
2 GIVN Marguerite Charlotte
2 SURN CARRÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 9:49
1 FAMS @12427@
1 FAMS @12440@
1 BIRT
2 DATE 1872
2 _FNA NO
1 DEAT
2 DATE 1900
2 _FNA NO
1 BURI CIMETIERRE PERE LACHAISE
2 DATE 1900
2 PLAC Paris 12,,,,,
2 _FNA NO
1 FAMC @12460@
0 @12438@ INDI
1 NAME J/SUPERVIELLE/
2 GIVN J
2 SURN SUPERVIELLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 9:47
1 FAMS @12440@
0 @12451@ INDI
1 NAME Henri/CARRÉ/
2 GIVN Henri
2 SURN CARRÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 9:51
1 FAMS @12460@
0 @12458@ INDI
1 NAME Laure/SIROUX/
2 GIVN Laure
2 SURN SIROUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 9:51
1 FAMS @12460@
0 @12467@ INDI
1 NAME François/de SONIS/
2 GIVN François
2 SURN de SONIS
1 SEX M
1 OCCU Militaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 10:14
1 FAMS @12492@
1 BIRT
2 DATE 1867
2 _FNA NO
1 DEAT
2 DATE 1942
2 _FNA NO
1 FAMC @12621@
0 @12481@ INDI
1 NAME Maria Pavlovna/STOLYPINE/
2 GIVN Maria Pavlovna
2 SURN STOLYPINE
2 NICK DUCHESSE MARIE DE MONTELFI
1 SEX F
1 OCCU Écrivain
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 10:05
1 FAMS @12492@
1 FAMS @12555@
1 BIRT
2 DATE 1867
2 _FNA NO
1 CHR
2 DATE 1867
2 PLAC Saint Petersbourg,,,,,
2 _FNA NO
1 DEAT
2 DATE 1942
2 _FNA NO
1 FAMC @12583@
0 @12510@ INDI
1 NAME Alexandre/TSAR/
2 GIVN Alexandre
2 SURN TSAR
2 NSFX II
1 SEX M
1 OCCU TSAR
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 18:40
0 @12514@ INDI
1 NAME Duchesse/d'EDIMBOURG/
2 GIVN Duchesse
2 SURN d'EDIMBOURG
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 13:27
0 @12519@ INDI
1 NAME Anne-Marie/de SONIS/
2 GIVN Anne-Marie
2 SURN de SONIS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 10:01
1 BIRT
2 DATE 1894
2 _FNA NO
1 DEAT
2 DATE 1944
2 _FNA NO
1 FAMC @12492@
0 @12530@ INDI
1 NAME Olga/de SONIS/
2 GIVN Olga
2 SURN de SONIS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 10:01
1 BIRT
2 DATE 1898
2 _FNA NO
1 DEAT
2 DATE 1988
2 _FNA NO
1 FAMC @12492@
0 @12543@ INDI
1 NAME Paul Adolphe/TROUARD RIOLLE/
2 GIVN Paul Adolphe
2 SURN TROUARD RIOLLE
1 SEX M
1 OCCU Avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 10:03
1 FAMS @12555@
1 BIRT
2 DATE 1857
2 _FNA NO
1 DEAT
2 DATE 1922
2 _FNA NO
0 @12557@ INDI
1 NAME Pavel/STOLYPINE/
2 GIVN Pavel
2 SURN STOLYPINE
1 SEX M
1 OCCU Conseiller
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 10:05
1 FAMS @12583@
1 BIRT
2 DATE 1843
2 _FNA NO
1 DEAT
2 DATE 1899
2 _FNA NO
0 @12572@ INDI
1 NAME Elisabeth Karlovna/PILAR von PILHAU/
2 GIVN Elisabeth Karlovna
2 SURN PILAR von PILHAU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 10:05
1 FAMS @12583@
1 BIRT
2 DATE 1852
2 _FNA NO
1 DEAT
2 DATE 1939
2 _FNA NO
0 @12588@ INDI
1 NAME Gaston/de SONIS/
2 GIVN Gaston
2 SURN de SONIS
1 SEX M
1 OCCU Général
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 10:14
1 FAMS @12621@
1 BIRT
2 DATE 1825
2 PLAC Pointe-à-Pitre,97110,Guadeloupe,Guadeloupe,FRANCE,
3 MAP
4 LATI N16.242200
4 LONG W61.534300
2 _FNA NO
1 DEAT
2 DATE 1887
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 BURI
2 DATE 1887
2 PLAC Loigny-la-Bataille,28140,Eure-et-Loir,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N48.123240
4 LONG E1.733950
2 _FNA NO
0 @12614@ INDI
1 NAME Anaïs/ROGER/
2 GIVN Anaïs
2 SURN ROGER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 10:14
1 FAMS @12621@
1 BIRT
2 DATE 1831
2 PLAC Castres,02680,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.803610
4 LONG E3.239090
2 _FNA NO
1 DEAT
2 DATE 1927
2 _FNA NO
0 @12630@ INDI
1 NAME Joseph Ernest Léon/COLLENET/
2 GIVN Joseph Ernest Léon
2 SURN COLLENET
1 SEX M
1 OCCU Intendant Général Militaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 18:52
1 FAMS @12674@
1 BIRT
2 DATE 21 APR 1856
2 PLAC Lille,59000,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.632970
4 LONG E3.058580
2 _FNA NO
1 DEAT
2 DATE 25 MAR 1935
2 PLAC Dijon,21000,Côte-d'Or,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.316670
4 LONG E5.016670
2 _FNA NO
0 @12659@ INDI
1 NAME Marcelle Charlotte Eugénie/BOURÉE/
2 GIVN Marcelle Charlotte Eugénie
2 SURN BOURÉE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 18:55
1 FAMS @12674@
1 BIRT
2 DATE 1868
2 _FNA NO
1 DEAT
2 DATE 1929
2 _FNA NO
1 FAMC @12698@
0 @12681@ INDI
1 NAME Hippolyte/BOURÉE/
2 GIVN Hippolyte
2 SURN BOURÉE
1 SEX M
1 OCCU ColonEL
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 18:55
1 FAMS @12698@
1 BIRT
2 DATE 1822
2 _FNA NO
1 DEAT
2 DATE 1898
2 _FNA NO
0 @12694@ INDI
1 NAME Laure/CHEVREY-RAMEAU/
2 GIVN Laure
2 SURN CHEVREY-RAMEAU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 18:56
1 FAMS @12698@
1 DEAT
2 DATE 1927
2 _FNA NO
1 FAMC @12714@
0 @12703@ INDI
1 NAME Charles Victor/CHEVREY-RAMEAU/
2 GIVN Charles Victor
2 SURN CHEVREY-RAMEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 31 MAR 2018
3 TIME 18:57
1 FAMS @12714@
1 BIRT
2 DATE 1809
2 _FNA NO
1 DEAT
2 DATE 1887
2 _FNA NO
0 @12730@ INDI
1 NAME Thomas Marie Joseph/GOUSSET/
2 GIVN Thomas Marie Joseph
2 SURN GOUSSET
1 SEX M
1 OCCU CARDINAL ARCHEVEQUE DE REIMS
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 18:48
1 BIRT
2 DATE 1792
2 _FNA NO
1 DEAT
2 DATE 1866
2 _FNA NO
0 @12756@ INDI
1 NAME Christian/BEVIERE/
2 GIVN Christian
2 SURN BEVIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 7 JAN 2019
3 TIME 11:46
1 BIRT
2 DATE 21 JUN 1887
2 PLAC Amiens,80000,Somme,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.900000
4 LONG E2.300000
2 _FNA NO
1 DEAT
2 DATE 18 DEC 1914
2 PLAC Poperinghe,,,,,
2 _FNA NO
2 CAUS mort pour la France
2 NOTE blessé 30/11/1914 meurt suite à ses blessures
3 CONT
1 FAMC @25555@
0 @12769@ INDI
1 NAME Sidonie/AMSTEIN/
2 GIVN Sidonie
2 SURN AMSTEIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 16:15
1 FAMS @12771@
1 BIRT
2 DATE 1809
2 _FNA NO
1 DEAT
2 DATE 1870
2 _FNA NO
1 FAMC @25403@
0 @12782@ INDI
1 NAME Hyacinthe Dieudonné Philippe/BEVIERE/
2 GIVN Hyacinthe Dieudonné Philippe
2 SURN BEVIERE
1 SEX M
1 OCCU Contrôleur des contributions indirectes
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 16:37
1 FAMS @12798@
1 BIRT
2 DATE 10 NOV 1786
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
2 NOTE 6° ENFANT DE BEAUSANGE
1 DEAT
2 DATE 24 SEP 1870
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,grande-rue
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @12816@
0 @12796@ INDI
1 NAME Alexandrine Louise Pascale/DELESALLE/
2 GIVN Alexandrine Louise Pascale
2 SURN DELESALLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 16:39
1 FAMS @12798@
1 BIRT
2 DATE 1798
2 PLAC Grammont,,,,ROYAUME des PAYS-BAS,
2 _FNA NO
1 FAMC @25589@
0 @12802@ INDI
1 NAME Beausange Aimé Pierre-Marie/BEVIERE/
2 GIVN Beausange Aimé Pierre-Marie
2 SURN BEVIERE
1 SEX M
1 OCCU avocat
1 TITL SEIGNEUR DE SAINT FLEURY
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 15:24
1 FAMS @12816@
1 BIRT
2 DATE 15 AUG 1754
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 DEAT
2 DATE 29 MAY 1847
3 TIME 22:00
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,RUE CRETIN
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @12834@
0 @12809@ INDI
1 NAME Constance Marie-Thérèse/GOSSUIN/
2 GIVN Constance Marie-Thérèse
2 SURN GOSSUIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 15:20
1 FAMS @12816@
1 BIRT
2 DATE 24 JUL 1756
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 DEAT
2 DATE 1836
2 _FNA NO
1 FAMC @25854@
0 @12820@ INDI
1 NAME Pierre Marie Joseph/BEVIERE/
2 GIVN Pierre Marie Joseph
2 SURN BEVIERE
1 SEX M
1 OCCU Avocat
1 TITL SEIGNEUR DE SAINT FLEURY
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 13:48
1 FAMS @12834@
1 BIRT
2 DATE 20 JAN 1722
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 DEAT
2 DATE 4 JAN 1778
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @26146@
0 @12832@ INDI
1 NAME Marie Philippine Josèphe/GARCIA de PARÈDES/
2 GIVN Marie Philippine Josèphe
2 SURN GARCIA de PARÈDES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 10:44
1 FAMS @12834@
1 BIRT
2 DATE 10 MAY 1712
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 DEAT
2 DATE 19 MAR 1794
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
2 NOTE 29 ventôse an II
1 FAMC @12845@
0 @12837@ INDI
1 NAME Charles/GARCIA de PARÈDES/
2 GIVN Charles
2 SURN GARCIA de PARÈDES
1 SEX M
1 OCCU Conseiller DU ROI D'ESPAGNE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 16:32
1 FAMS @12845@
1 BIRT
2 DATE 1646
2 _FNA NO
1 FAMC @29075@
0 @12843@ INDI
1 NAME Jeanne/GOSSUIN/
2 GIVN Jeanne
2 SURN GOSSUIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 11:22
1 FAMS @12845@
1 BIRT
2 DATE 1667
2 _FNA NO
1 DEAT
2 DATE 3 SEP 1731
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @26206@
0 @12849@ INDI
1 NAME Sébastian/GARCIA de PARÈDES/
2 GIVN Sébastian
2 SURN GARCIA de PARÈDES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 14:17
1 DEAT
2 DATE 1653
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
0 @12854@ INDI
1 NAME Charles Louis/GOSSUIN/
2 GIVN Charles Louis
2 SURN GOSSUIN
1 SEX M
1 OCCU avocat
1 TITL SIEUR D'ANGRÉAU
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 12:55
1 FAMS @25907@
1 BIRT
2 DATE 4 JAN 1694
2 PLAC Étroeungt,59219,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.050000
4 LONG E3.933330
2 _FNA NO
1 DEAT
2 DATE 6 JUL 1766
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @26880@
0 @12859@ INDI
1 NAME Louis-Joseph Antimon/GOSSUIN/
2 GIVN Louis-Joseph Antimon
2 SURN GOSSUIN
1 SEX M
1 OCCU avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 14:05
1 FAMS @25854@
1 BIRT
2 DATE 5 MAR 1730
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 DEAT
2 DATE 24 JUL 1759
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @25907@
0 @12928@ INDI
1 NAME Jean-Mathias/de MESCHINET/
2 GIVN Jean-Mathias
2 SURN de MESCHINET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 18:14
1 FAMS @12932@
1 BIRT
2 DATE 7 JAN 1800
2 PLAC Saint-Denis-du-Pin,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.983330
4 LONG W0.516670
2 _FNA NO
1 FAMC @12950@
0 @12935@ INDI
1 NAME François Alexandre/de MESCHINET/
2 GIVN François Alexandre
2 SURN de MESCHINET
1 SEX M
1 OCCU Maire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:00
1 FAMS @12950@
1 FAMS @13112@
1 BIRT
2 PLAC Antezant-la-Chapelle,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.982710
4 LONG W0.455850
2 _FNA NO
0 @12947@ INDI
1 NAME Catherine Élisabeth/RICHARD/
2 GIVN Catherine Élisabeth
2 SURN RICHARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 18:14
1 FAMS @12950@
0 @13015@ INDI
1 NAME Marie Anne Clémentine Louise/ESTACHON/
2 GIVN Marie Anne Clémentine Louise
2 SURN ESTACHON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 18:26
1 FAMS @13020@
0 @13043@ INDI
1 NAME Marthe Thérèse/ALLENET de RIBEMONT/
2 GIVN Marthe Thérèse
2 SURN ALLENET de RIBEMONT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 18:40
1 FAMS @13049@
1 DEAT
2 DATE 1794
2 _FNA NO
0 @13052@ INDI
1 NAME Pierre Joseph Urbain/MESTADIER/
2 GIVN Pierre Joseph Urbain
2 SURN MESTADIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 18:38
1 BIRT
2 DATE 25 DEC 1793
2 _FNA NO
1 FAMC @13049@
0 @13086@ INDI
1 NAME Michel/BIENVILLE de MESCINET/
2 GIVN Michel
2 SURN BIENVILLE de MESCINET
1 SEX M
1 OCCU Militaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 18:55
1 FAMS @13099@
1 DEAT
2 DATE 1782
2 PLAC Nancras,17600,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.745340
4 LONG W0.881370
2 _FNA NO
0 @13097@ INDI
1 NAME Marie-Anne/de ROUZERAIS/
2 GIVN Marie-Anne
2 SURN de ROUZERAIS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 18:55
1 FAMS @13099@
0 @13105@ INDI
1 NAME Marie-Anne/BIENVILLE de MESCINET/
2 GIVN Marie-Anne
2 SURN BIENVILLE de MESCINET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:00
1 FAMS @13112@
1 BIRT
2 DATE 11 JUN 1780
2 PLAC Saint Pierre de Faveau,,,,,
2 _FNA NO
1 FAMC @13099@
0 @13114@ INDI
1 NAME Marie-Céleste/BIENVILLE de MESCINET/
2 GIVN Marie-Céleste
2 SURN BIENVILLE de MESCINET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 18:56
1 FAMS @13127@
1 BIRT
2 DATE 1777
2 _FNA NO
1 FAMC @13099@
0 @13124@ INDI
1 NAME Offroy Dominique/GIRON/
2 GIVN Offroy Dominique
2 SURN GIRON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 18:56
1 FAMS @13127@
0 @13155@ INDI
1 NAME Jean-Joseph/MESTADIER/
2 GIVN Jean-Joseph
2 SURN MESTADIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:12
1 FAMS @13165@
1 FAMC @13194@
0 @13161@ INDI
1 NAME Marie-Anne/GIRON/
2 GIVN Marie-Anne
2 SURN GIRON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:10
1 FAMS @13165@
1 BIRT
2 DATE 1731
2 _FNA NO
1 DEAT
2 DATE 9 AUG 1808
2 PLAC Saint-Denis-du-Pin,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.983330
4 LONG W0.516670
2 _FNA NO
1 FAMC @13181@
0 @13173@ INDI
1 NAME Offroy/GIRON/
2 GIVN Offroy
2 SURN GIRON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:10
1 FAMS @13181@
0 @13179@ INDI
1 NAME Marie/MOULIN/
2 GIVN Marie
2 SURN MOULIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:10
1 FAMS @13181@
0 @13185@ INDI
1 NAME Louis-Joseph/MESTADIER/
2 GIVN Louis-Joseph
2 SURN MESTADIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:15
1 FAMS @13194@
1 FAMC @13209@
0 @13192@ INDI
1 NAME Marie-Blanche/CHOTARD/
2 GIVN Marie-Blanche
2 SURN CHOTARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:12
1 FAMS @13194@
0 @13198@ INDI
1 NAME Joseph/MESTADIER/
2 GIVN Joseph
2 SURN MESTADIER
1 SEX M
1 OCCU Procureur ROYAL
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:19
1 FAMS @13209@
1 FAMC @13239@
0 @13205@ INDI
1 NAME Hélène/de BONNEGENS/
2 GIVN Hélène
2 SURN de BONNEGENS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:17
1 FAMS @13209@
1 BIRT
2 PLAC Saint-Jean-d'Angély,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.950000
4 LONG W0.516670
2 _FNA NO
1 FAMC @13222@
0 @13212@ INDI
1 NAME Sébastien/de BONNEGENS/
2 GIVN Sébastien
2 SURN de BONNEGENS
1 SEX M
1 OCCU Notaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:16
1 FAMS @13222@
0 @13220@ INDI
1 NAME Hélène/REGNAULT/
2 GIVN Hélène
2 SURN REGNAULT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:16
1 FAMS @13222@
0 @13226@ INDI
1 NAME Pierre/MESTADIER/
2 GIVN Pierre
2 SURN MESTADIER
1 SEX M
1 OCCU Procureur ROYAL
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:21
1 FAMS @13239@
1 BIRT
2 PLAC Saint-Jean-d'Angély,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.950000
4 LONG W0.516670
2 _FNA NO
1 FAMC @13251@
0 @13237@ INDI
1 NAME Magdelaine/LAMBERT/
2 GIVN Magdelaine
2 SURN LAMBERT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:20
1 FAMS @13239@
0 @13243@ INDI
1 NAME Pierre/MESTADIER/
2 GIVN Pierre
2 SURN MESTADIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:21
1 FAMS @13251@
0 @13249@ INDI
1 NAME Catherine/GRIFFON/
2 GIVN Catherine
2 SURN GRIFFON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:21
1 FAMS @13251@
0 @13254@ INDI
1 NAME Marie Magdelaine Adélaïde/PAILLÉ/
2 GIVN Marie Magdelaine Adélaïde
2 SURN PAILLÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:27
1 BIRT
2 DATE 4 JUL 1826
2 PLAC Nuaillé-sur-Boutonne,17470,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.013040
4 LONG W0.435040
2 _FNA NO
1 DEAT
2 DATE 31 DEC 1845
2 PLAC Nuaillé-sur-Boutonne,17470,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.013040
4 LONG W0.435040
2 _FNA NO
1 FAMC @8718@
0 @13276@ INDI
1 NAME Jean/PAILLÉ/
2 GIVN Jean
2 SURN PAILLÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:37
1 FAMS @13288@
1 BIRT
2 DATE 17 JAN 1828
2 PLAC Nuaillé-sur-Boutonne,17470,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.013040
4 LONG W0.435040
2 _FNA NO
1 FAMC @8718@
0 @13286@ INDI
1 NAME Madeleine/GILLARD/
2 GIVN Madeleine
2 SURN GILLARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:37
1 FAMS @13288@
0 @13295@ INDI
1 NAME Joséphine/PAILLÉ/
2 GIVN Joséphine
2 SURN PAILLÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:29
1 BIRT
2 DATE 30 SEP 1856
2 _FNA NO
1 DEAT
2 DATE 12 DEC 1869
2 _FNA NO
1 FAMC @13288@
0 @13306@ INDI
1 NAME Laetitia Alexandrine/PAILLÉ/
2 GIVN Laetitia Alexandrine
2 SURN PAILLÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:33
1 FAMS @13324@
1 BIRT
2 DATE 6 JUN 1859
2 _FNA NO
1 DEAT
2 DATE 1931
2 _FNA NO
1 FAMC @13288@
0 @13322@ INDI
1 NAME Albert/BERLIN/
2 GIVN Albert
2 SURN BERLIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:35
1 FAMS @13324@
1 FAMC @13333@
0 @13326@ INDI
1 NAME Louis/BERLIN/
2 GIVN Louis
2 SURN BERLIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:35
1 FAMS @13333@
0 @13331@ INDI
1 NAME Clarisse/GOIZIN/
2 GIVN Clarisse
2 SURN GOIZIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:35
1 FAMS @13333@
0 @13336@ INDI
1 NAME Jules/PAILLÉ/
2 GIVN Jules
2 SURN PAILLÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:36
1 BIRT
2 DATE 8 SEP 1861
2 _FNA NO
1 FAMC @13288@
0 @13344@ INDI
1 NAME Adeline/PAILLÉ/
2 GIVN Adeline
2 SURN PAILLÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:37
1 BIRT
2 DATE 23 MAY 1864
2 _FNA NO
1 FAMC @13288@
0 @13351@ INDI
1 NAME André/PAILLÉ/
2 GIVN André
2 SURN PAILLÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:49
1 FAMS @13369@
1 BIRT
2 DATE 1769
2 PLAC Saint-Loup,17380,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.983330
4 LONG W0.616670
2 _FNA NO
1 DEAT
2 DATE 30 SEP 1838
2 PLAC Saint-Loup,17380,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.983330
4 LONG W0.616670
2 _FNA NO
1 FAMC @13437@
0 @13362@ INDI
1 NAME Marguerite/LAROCHE/
2 GIVN Marguerite
2 SURN LAROCHE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:42
1 FAMS @13369@
1 BIRT
2 DATE 1767
2 PLAC La Vergne,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.965110
4 LONG W0.564390
2 _FNA NO
1 DEAT
2 DATE 1827
2 PLAC Saint-Loup,17380,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.983330
4 LONG W0.616670
2 _FNA NO
1 FAMC @13389@
0 @13372@ INDI
1 NAME Étienne/LAROCHE/
2 GIVN Étienne
2 SURN LAROCHE
1 SEX M
1 OCCU Agriculteur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:45
1 FAMS @13389@
1 DEAT
2 DATE 1809
2 _FNA NO
1 FAMC @13402@
0 @13382@ INDI
1 NAME Marie/RAYÉ/
2 GIVN Marie
2 SURN RAYÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:46
1 FAMS @13389@
1 DEAT
2 DATE 1811
2 _FNA NO
1 BIRT
2 PLAC La Vergne,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.965110
4 LONG W0.564390
2 _FNA NO
1 FAMC @13418@
0 @13392@ INDI
1 NAME Barthélémy/LAROCHE/
2 GIVN Barthélémy
2 SURN LAROCHE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:45
1 FAMS @13402@
1 DEAT
2 DATE 1764
2 _FNA NO
0 @13400@ INDI
1 NAME Jeanne/CHEVALIER/
2 GIVN Jeanne
2 SURN CHEVALIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:45
1 FAMS @13402@
0 @13405@ INDI
1 NAME Jacques/RAYÉ/
2 GIVN Jacques
2 SURN RAYÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:46
1 FAMS @13418@
1 BIRT
2 DATE 1690
2 _FNA NO
1 DEAT
2 DATE 1774
2 _FNA NO
0 @13416@ INDI
1 NAME Marie/ROUSSEAUX/
2 GIVN Marie
2 SURN ROUSSEAUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:46
1 FAMS @13418@
0 @13422@ INDI
1 NAME Pierre/PAILLÉ/
2 GIVN Pierre
2 SURN PAILLÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:51
1 FAMS @13437@
1 BIRT
2 DATE 1736
2 PLAC Saint-Loup,17380,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.983330
4 LONG W0.616670
2 _FNA NO
1 FAMC @13452@
0 @13430@ INDI
1 NAME Marie/GRILLIAUX/
2 GIVN Marie
2 SURN GRILLIAUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:49
1 FAMS @13437@
1 BIRT
2 DATE 1743
2 _FNA NO
1 DEAT
2 DATE 1769
2 _FNA NO
0 @13440@ INDI
1 NAME Louis/PAILLÉ/
2 GIVN Louis
2 SURN PAILLÉ
1 SEX M
1 OCCU Laboureur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:51
1 FAMS @13452@
0 @13448@ INDI
1 NAME Suzanne/AUGIER/
2 GIVN Suzanne
2 SURN AUGIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:52
1 FAMS @13452@
1 DEAT
2 DATE 1736
2 _FNA NO
1 FAMC @13460@
0 @13455@ INDI
1 NAME René/AUGIER/
2 GIVN René
2 SURN AUGIER
1 SEX M
1 OCCU Cultivateur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:52
1 FAMS @13460@
1 DEAT
2 DATE 1761
2 _FNA NO
0 @13466@ INDI
1 NAME Pierre/DOIGNON/
2 GIVN Pierre
2 SURN DOIGNON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:58
1 FAMS @13480@
1 BIRT
2 DATE 1776
2 PLAC Nuaillé-sur-Boutonne,17470,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.013040
4 LONG W0.435040
2 _FNA NO
1 DEAT
2 DATE 23 MAY 1842
2 PLAC Nuaillé-sur-Boutonne,17470,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.013040
4 LONG W0.435040
2 _FNA NO
1 FAMC @13493@
0 @13478@ INDI
1 NAME Catherine/LAROCHE/
2 GIVN Catherine
2 SURN LAROCHE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 20:02
1 FAMS @13480@
1 BIRT
2 DATE 1774
2 PLAC Nuaillé-sur-Boutonne,17470,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.013040
4 LONG W0.435040
2 _FNA NO
1 DEAT
2 DATE 27 NOV 1836
2 PLAC Nuaillé-sur-Boutonne,17470,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.013040
4 LONG W0.435040
2 _FNA NO
1 FAMC @13511@
0 @13485@ INDI
1 NAME Pierre/DOIGNON/
2 GIVN Pierre
2 SURN DOIGNON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:58
1 FAMS @13493@
0 @13491@ INDI
1 NAME Marie/IZAMBARD/
2 GIVN Marie
2 SURN IZAMBARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 19:58
1 FAMS @13493@
0 @13504@ INDI
1 NAME Pierre/LAROCHE/
2 GIVN Pierre
2 SURN LAROCHE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 20:02
1 FAMS @13511@
0 @13509@ INDI
1 NAME Marie-Anne/?/
2 GIVN Marie-Anne
2 SURN ?
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 20:02
1 FAMS @13511@
0 @13517@ INDI
1 NAME Myriam/FEUNE de COLOMBI/
2 GIVN Myriam
2 SURN FEUNE de COLOMBI
1 SEX F
1 OCCU Actrice
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 APR 2018
3 TIME 20:13
1 FAMS @13523@
1 BIRT
2 DATE 23 FEB 1940
2 PLAC La Carneille,61100,Orne,Normandie,FRANCE,
3 MAP
4 LATI N48.783330
4 LONG W0.450000
2 _FNA NO
0 @13547@ INDI
1 NAME /BAILLARGUET/
2 GIVN
2 SURN BAILLARGUET
1 SEX U
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 12:33
0 @13566@ INDI
1 NAME Abel Casimir/PRUNIERES/
2 GIVN Abel Casimir
2 SURN PRUNIERES
1 SEX M
1 OCCU MAGISTRAT
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 12:51
1 FAMS @13581@
0 @13577@ INDI
1 NAME Palme Marie Hortense/PIETRI/
2 GIVN Palme Marie Hortense
2 SURN PIETRI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 12:51
1 FAMS @13581@
1 FAMC @13590@
0 @13588@ INDI
1 NAME ?fils/PIETRI/
2 GIVN ?fils
2 SURN PIETRI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 12:52
1 FAMS @13590@
1 FAMC @13598@
0 @13596@ INDI
1 NAME ?pere/PIETRI/
2 GIVN ?pere
2 SURN PIETRI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 12:53
1 FAMS @13598@
0 @13603@ INDI
1 NAME ?oncle/PIETRI/
2 GIVN ?oncle
2 SURN PIETRI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 12:53
1 FAMS @13611@
1 FAMC @13598@
0 @13608@ INDI
1 NAME François/PIETRI/
2 GIVN François
2 SURN PIETRI
1 SEX M
1 OCCU Député MINISTRE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 12:53
1 FAMC @13611@
0 @13623@ INDI
1 NAME Marthe Stéphanie/POYET/
2 GIVN Marthe Stéphanie
2 SURN POYET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 13:02
1 FAMS @13634@
1 BIRT
2 DATE 1900
2 _FNA NO
1 DEAT
2 DATE 1920
2 _FNA NO
0 @13640@ INDI
1 NAME Pierre-Alphonse/NEUVILLE/
2 GIVN Pierre-Alphonse
2 SURN NEUVILLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 13:05
1 FAMS @13649@
0 @13646@ INDI
1 NAME Nathalie Marie/GACHE/
2 GIVN Nathalie Marie
2 SURN GACHE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 13:05
1 FAMS @13649@
0 @13653@ INDI
1 NAME Jacques/GANDELIN/
2 GIVN Jacques
2 SURN GANDELIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 13:05
1 BIRT
2 DATE 1923
2 _FNA NO
1 FAMC @6334@
0 @13660@ INDI
1 NAME Monique/GANDELIN/
2 GIVN Monique
2 SURN GANDELIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 13:05
1 BIRT
2 DATE 1934
2 _FNA NO
1 FAMC @6334@
0 @13677@ INDI
1 NAME ?/?/
2 GIVN ?
2 SURN ?
1 SEX F
1 OCCU ANGLAISE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 16:02
1 FAMS @13679@
0 @13681@ INDI
1 NAME André/GANDELIN/
2 GIVN André
2 SURN GANDELIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 16:03
1 BIRT
2 DATE 1924
2 _FNA NO
1 FAMC @6323@
0 @13709@ INDI
1 NAME Luciano/DUBOUE/
2 GIVN Luciano
2 SURN DUBOUE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 16:12
1 FAMS @13717@
0 @13716@ INDI
1 NAME /LOPEZ de GOICOCHEA/
2 GIVN
2 SURN LOPEZ de GOICOCHEA
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 16:12
1 FAMS @13717@
0 @13720@ INDI
1 NAME Jean/GANDELIN/
2 GIVN Jean
2 SURN GANDELIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 16:12
1 BIRT
2 DATE 1922
2 _FNA NO
1 DEAT
2 DATE 1953
2 _FNA NO
1 FAMC @6301@
0 @13732@ INDI
1 NAME Francine/GANDELIN/
2 GIVN Francine
2 SURN GANDELIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 16:13
1 BIRT
2 DATE 1923
2 _FNA NO
1 FAMC @6301@
0 @13744@ INDI
1 NAME Charles/VERHILLE/
2 GIVN Charles
2 SURN VERHILLE
1 SEX M
1 OCCU COLONEL
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 16:20
1 FAMS @13753@
1 FAMC @13779@
0 @13751@ INDI
1 NAME Germaine/GRUSON/
2 GIVN Germaine
2 SURN GRUSON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 16:18
1 FAMS @13753@
0 @13756@ INDI
1 NAME Michel/GANDELIN/
2 GIVN Michel
2 SURN GANDELIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 16:18
1 BIRT
2 DATE 1947
2 _FNA NO
1 FAMC @6309@
0 @13764@ INDI
1 NAME Gilbert/GANDELIN/
2 GIVN Gilbert
2 SURN GANDELIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 16:19
1 BIRT
2 DATE 1954
2 _FNA NO
1 FAMC @6309@
0 @13771@ INDI
1 NAME /VERHILLE/
2 GIVN
2 SURN VERHILLE
1 SEX M
1 OCCU Capitaine
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 16:20
1 FAMS @13779@
0 @13777@ INDI
1 NAME Marie/HACKENIS/
2 GIVN Marie
2 SURN HACKENIS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 16:20
1 FAMS @13779@
0 @13785@ INDI
1 NAME Antoine Jean Paul/PANZANI/
2 GIVN Antoine Jean Paul
2 SURN PANZANI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 17:08
0 @13792@ INDI
1 NAME Annie/CHEVALIER/
2 GIVN Annie
2 SURN CHEVALIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 APR 2018
3 TIME 17:08
0 @13820@ INDI
1 NAME Bernard/DUPRÉ/
2 GIVN Bernard
2 SURN DUPRÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 12:47
1 FAMS @13821@
1 BIRT
2 DATE 1927
2 _FNA NO
0 @13823@ INDI
1 NAME Francois-Henry/DUPRÉ/
2 GIVN Francois-Henry
2 SURN DUPRÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 12:48
1 FAMS @17707@
1 BIRT
2 DATE 1964
2 _FNA NO
1 FAMC @13821@
0 @13828@ INDI
1 NAME Jacques/FRANÇOIS-PONCET/
2 GIVN Jacques
2 SURN FRANÇOIS-PONCET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:47
1 FAMS @14830@
1 BIRT
2 DATE 1962
2 _FNA NO
1 FAMC @12186@
0 @13837@ INDI
1 NAME Fani/BUHAYAR-MAVROMICHALIS/
2 GIVN Fani
2 SURN BUHAYAR-MAVROMICHALIS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 13:54
1 FAMS @17726@
1 BIRT
2 DATE 1971
2 _FNA NO
1 FAMC @14810@
0 @13842@ INDI
1 NAME Florence/FRANÇOIS-PONCET/
2 GIVN Florence
2 SURN FRANÇOIS-PONCET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 13:46
1 FAMS @17720@
1 BIRT
2 DATE 1967
2 _FNA NO
1 FAMC @12186@
0 @13845@ INDI
1 NAME Philippe/FRANÇOIS-PONCET/
2 GIVN Philippe
2 SURN FRANÇOIS-PONCET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:49
1 FAMS @14846@
1 BIRT
2 DATE 1960
2 _FNA NO
1 FAMC @12186@
0 @13856@ INDI
1 NAME Odile/de WENDEL/
2 GIVN Odile
2 SURN de WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 17:33
1 FAMS @13906@
1 BIRT
2 DATE 1908
2 _FNA NO
1 DEAT
2 DATE 28 MAY 1994
2 PLAC Ermenouville,76740,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.798100
4 LONG E0.787040
2 _FNA NO
1 FAMC @12153@
0 @13863@ INDI
1 NAME Henri/de WENDEL/
2 GIVN Henri
2 SURN de WENDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:53
1 FAMS @14042@
1 BIRT
2 DATE 1913
2 _FNA NO
1 DEAT
2 DATE 1982
2 _FNA NO
1 FAMC @12153@
0 @13873@ INDI
1 NAME Henri Paul François/de WENDEL/
2 GIVN Henri Paul François
2 SURN de WENDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 10:37
1 FAMS @13892@
1 BIRT
2 DATE 1844
2 _FNA NO
1 DEAT
2 DATE 1906
2 _FNA NO
1 FAMC @15394@
0 @13885@ INDI
1 NAME Berthe Henriette Hélène/de VAULSERRE/
2 GIVN Berthe Henriette Hélène
2 SURN de VAULSERRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 10:48
1 FAMS @13892@
1 BIRT
2 DATE 1849
2 _FNA NO
1 DEAT
2 DATE 1918
2 _FNA NO
1 FAMC @18974@
0 @13898@ INDI
1 NAME Geoffroy Marc René Marie Joseph/de MONTALEMBERT de CERS/
2 GIVN Geoffroy Marc René Marie Joseph
2 SURN de MONTALEMBERT de CERS
1 SEX M
1 OCCU Sénateur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2019
3 TIME 10:18
1 FAMS @13906@
1 FAMS @16263@
1 BIRT
2 DATE 10 OCT 1898
3 TIME 7:00
2 PLAC Annappes,,,,,
2 _FNA NO
1 DEAT
2 DATE 2 MAR 1993
2 PLAC Neuilly-sur-Seine,92200,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883330
4 LONG E2.266670
2 _FNA NO
1 BURI
2 DATE 6 MAR 1993
2 PLAC Ermenouville,76740,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.798100
4 LONG E0.787040
2 _FNA NO
1 FAMC @32327@
0 @13907@ INDI
1 NAME Marguerite Berthe Marie Odette/de MONTALEMBERT/
2 GIVN Marguerite Berthe Marie Odette
2 SURN de MONTALEMBERT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 9:51
1 FAMS @13929@
1 BIRT
2 DATE 12 NOV 1931
2 PLAC Villeneuve-d'Ascq,59491,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.616690
4 LONG E3.166640
2 _FNA NO
1 DEAT
2 DATE 8 JAN 2015
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @13906@
0 @13914@ INDI
1 NAME François-Xavier/de MONTALEMBERT/
2 GIVN François-Xavier
2 SURN de MONTALEMBERT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 7 APR 2018
3 TIME 13:29
1 BIRT
2 DATE 1946
2 _FNA NO
1 DEAT
2 DATE 1957
2 _FNA NO
1 FAMC @13906@
0 @13925@ INDI
1 NAME Urbain Louis Germain François/de LA ROCHEFOUCAULD/
2 GIVN Urbain Louis Germain François
2 SURN de LA ROCHEFOUCAULD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:23
1 FAMS @13929@
1 BIRT
2 DATE 15 FEB 1924
2 PLAC Saulzais-le-Potier,18360,Cher,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N46.599240
4 LONG E2.496380
2 _FNA NO
1 DEAT
2 DATE 8 MAR 1990
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
2 CAUS SCLEROSE EN PLAQUE
1 FAMC @27752@
0 @13930@ INDI
1 NAME Bruno/de LA ROCHEFOUCAULD/
2 GIVN Bruno
2 SURN de LA ROCHEFOUCAULD
1 SEX M
1 OCCU Médecin
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:48
1 FAMS @13963@
1 BIRT
2 DATE 1 JUL 1952
2 _FNA NO
1 FAMC @13929@
0 @13938@ INDI
1 NAME Chantal/de LA ROCHEFOUCAULD/
2 GIVN Chantal
2 SURN de LA ROCHEFOUCAULD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:52
1 FAMS @13997@
1 BIRT
2 DATE 1955
2 _FNA NO
1 FAMC @13929@
0 @13945@ INDI
1 NAME Laurence/de LA ROCHEFOUCAULD/
2 GIVN Laurence
2 SURN de LA ROCHEFOUCAULD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 18:44
1 FAMS @14030@
1 BIRT
2 DATE 1958
2 _FNA NO
1 FAMC @13929@
0 @13953@ INDI
1 NAME Jacquelin/de LA ROCHEFOUCAULD/
2 GIVN Jacquelin
2 SURN de LA ROCHEFOUCAULD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 9:57
1 FAMS @18236@
1 BIRT
2 DATE 1963
2 _FNA NO
1 FAMC @13929@
0 @13961@ INDI
1 NAME Laura/de LUPPÉ/
2 GIVN Laura
2 SURN de LUPPÉ
1 SEX F
1 TITL COMTE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 8:29
1 FAMS @13963@
1 BIRT
2 DATE 1954
2 _FNA NO
1 FAMC @27434@
0 @13965@ INDI
1 NAME Mathieu/de LA ROCHEFOUCAULD/
2 GIVN Mathieu
2 SURN de LA ROCHEFOUCAULD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:12
1 FAMS @27883@
1 BIRT
2 DATE 18 MAY 1976
2 _FNA NO
1 FAMC @13963@
0 @13972@ INDI
1 NAME Claire/de LA ROCHEFOUCAULD/
2 GIVN Claire
2 SURN de LA ROCHEFOUCAULD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:13
1 FAMS @27888@
1 BIRT
2 DATE 1979
2 _FNA NO
1 FAMC @13963@
0 @13979@ INDI
1 NAME Jean/de LA ROCHEFOUCAULD/
2 GIVN Jean
2 SURN de LA ROCHEFOUCAULD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 7 APR 2018
3 TIME 13:35
1 BIRT
2 DATE 1983
2 _FNA NO
1 FAMC @13963@
0 @13986@ INDI
1 NAME Valérie/de LA ROCHEFOUCAULD/
2 GIVN Valérie
2 SURN de LA ROCHEFOUCAULD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 7 APR 2018
3 TIME 13:35
1 BIRT
2 DATE 1986
2 _FNA NO
1 FAMC @13963@
0 @13995@ INDI
1 NAME Pierre Gilbert Antoine Bonaventure François de Paule/RITTER de ZAHONI/
2 GIVN Pierre Gilbert Antoine Bonaventure François de Paule
2 SURN RITTER de ZAHONI
2 NICK Piero
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:52
1 FAMS @13997@
1 BIRT
2 DATE 14 JUL 1945
2 PLAC Florence,,,,,
2 _FNA NO
1 NOTE
1 FAMC @28027@
0 @13998@ INDI
1 NAME Marguerite/RITTER de ZAHONI/
2 GIVN Marguerite
2 SURN RITTER de ZAHONI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:54
1 FAMS @28134@
1 BIRT
2 DATE 1976
2 _FNA NO
1 FAMC @13997@
0 @14005@ INDI
1 NAME Béatrice/RITTER de ZAHONI/
2 GIVN Béatrice
2 SURN RITTER de ZAHONI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:37
1 BIRT
2 DATE 1978
2 _FNA NO
1 FAMC @13997@
0 @14012@ INDI
1 NAME François/RITTER de ZAHONI/
2 GIVN François
2 SURN RITTER de ZAHONI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:37
1 BIRT
2 DATE 1981
2 _FNA NO
1 FAMC @13997@
0 @14019@ INDI
1 NAME Sophie/RITTER de ZAHONI/
2 GIVN Sophie
2 SURN RITTER de ZAHONI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:37
1 BIRT
2 DATE 1986
2 _FNA NO
1 FAMC @13997@
0 @14028@ INDI
1 NAME Jean-Christophe Guy/MAURIAC/
2 GIVN Jean-Christophe Guy
2 SURN MAURIAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 18:44
1 FAMS @14030@
1 BIRT
2 DATE 1953
2 _FNA NO
1 FAMC @28172@
0 @14031@ INDI
1 NAME Thomas/MAURIAC/
2 GIVN Thomas
2 SURN MAURIAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 7 APR 2018
3 TIME 13:40
1 BIRT
2 DATE 1989
2 _FNA NO
1 FAMC @14030@
0 @14040@ INDI
1 NAME Galliane/HAUDRY de SOUCY/
2 GIVN Galliane
2 SURN HAUDRY de SOUCY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:54
1 FAMS @14042@
1 BIRT
2 DATE 1924
2 _FNA NO
1 DEAT
2 DATE 1993
2 _FNA NO
0 @14043@ INDI
1 NAME François/de WENDEL/
2 GIVN François
2 SURN de WENDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:55
1 FAMS @14068@
1 BIRT
2 DATE 1949
2 _FNA NO
1 FAMC @14042@
0 @14050@ INDI
1 NAME Florence/de WENDEL/
2 GIVN Florence
2 SURN de WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:06
1 FAMS @14108@
1 BIRT
2 DATE 1952
2 _FNA NO
1 FAMC @14042@
0 @14058@ INDI
1 NAME Humbert/de WENDEL/
2 GIVN Humbert
2 SURN de WENDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:08
1 FAMS @14146@
1 BIRT
2 DATE 1956
2 _FNA NO
1 FAMC @14042@
0 @14066@ INDI
1 NAME Christel/TAILLANDIER/
2 GIVN Christel
2 SURN TAILLANDIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:55
1 FAMS @14068@
1 BIRT
2 DATE 1949
2 _FNA NO
0 @14069@ INDI
1 NAME Ségolène/de WENDEL/
2 GIVN Ségolène
2 SURN de WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:56
1 FAMS @18256@
1 BIRT
2 DATE 1971
2 _FNA NO
1 FAMC @14068@
0 @14076@ INDI
1 NAME Gabrielle/de WENDEL/
2 GIVN Gabrielle
2 SURN de WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:57
1 FAMS @18260@
1 BIRT
2 DATE 1974
2 _FNA NO
1 FAMC @14068@
0 @14084@ INDI
1 NAME Jean-Martin/de WENDEL/
2 GIVN Jean-Martin
2 SURN de WENDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:59
1 FAMS @18268@
1 BIRT
2 DATE 1978
2 _FNA NO
1 FAMC @14068@
0 @14091@ INDI
1 NAME Jeanne/de WENDEL/
2 GIVN Jeanne
2 SURN de WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:00
1 FAMS @18274@
1 BIRT
2 DATE 1981
2 _FNA NO
1 FAMC @14068@
0 @14098@ INDI
1 NAME Madeleine/de WENDEL/
2 GIVN Madeleine
2 SURN de WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 7 APR 2018
3 TIME 13:46
1 BIRT
2 DATE 1990
2 _FNA NO
1 FAMC @14068@
0 @14106@ INDI
1 NAME Renaud/ABORD de CHATILLON/
2 GIVN Renaud
2 SURN ABORD de CHATILLON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:06
1 FAMS @14108@
0 @14109@ INDI
1 NAME Henri/ABORD de CHATILLON/
2 GIVN Henri
2 SURN ABORD de CHATILLON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:02
1 FAMS @18283@
1 BIRT
2 DATE 1973
2 PLAC Boston,,,,,
2 _FNA NO
1 FAMC @14108@
0 @14117@ INDI
1 NAME Anne-Laure/ABORD de CHATILLON/
2 GIVN Anne-Laure
2 SURN ABORD de CHATILLON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:03
1 FAMS @18291@
1 BIRT
2 DATE 1976
2 _FNA NO
1 FAMC @14108@
0 @14124@ INDI
1 NAME Caroline/ABORD de CHATILLON/
2 GIVN Caroline
2 SURN ABORD de CHATILLON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:05
1 FAMS @18299@
1 BIRT
2 DATE 1976
2 _FNA NO
1 FAMC @14108@
0 @14135@ INDI
1 NAME Charles-Antoine/ABORD de CHATILLON/
2 GIVN Charles-Antoine
2 SURN ABORD de CHATILLON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 7 APR 2018
3 TIME 13:50
1 BIRT
2 DATE 1984
2 _FNA NO
1 FAMC @14108@
0 @14144@ INDI
1 NAME Marie-Luc/DULIERE/
2 GIVN Marie-Luc
2 SURN DULIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:08
1 FAMS @14146@
1 BIRT
2 DATE 1955
2 _FNA NO
0 @14148@ INDI
1 NAME Oriane/de WENDEL/
2 GIVN Oriane
2 SURN de WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 7 APR 2018
3 TIME 13:52
1 BIRT
2 DATE 1985
2 _FNA NO
1 FAMC @14146@
0 @14155@ INDI
1 NAME Galliane/de WENDEL/
2 GIVN Galliane
2 SURN de WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 7 APR 2018
3 TIME 13:52
1 BIRT
2 DATE 1986
2 _FNA NO
1 FAMC @14146@
0 @14162@ INDI
1 NAME Isabelle/de WENDEL/
2 GIVN Isabelle
2 SURN de WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 7 APR 2018
3 TIME 13:54
1 BIRT
2 DATE 1920
2 _FNA NO
1 DEAT
2 DATE 1961
2 _FNA NO
1 FAMC @12153@
0 @14172@ INDI
1 NAME Humbert/de WENDEL/
2 GIVN Humbert
2 SURN de WENDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 7 APR 2018
3 TIME 13:56
1 BIRT
2 DATE 1876
2 _FNA NO
1 DEAT
2 DATE 1954
2 _FNA NO
1 FAMC @13892@
0 @14182@ INDI
1 NAME Maurice/de WENDEL/
2 GIVN Maurice
2 SURN de WENDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 7 APR 2018
3 TIME 14:01
1 FAMS @14200@
1 BIRT
2 DATE 1879
2 _FNA NO
1 DEAT
2 DATE 1961
2 _FNA NO
1 FAMC @13892@
0 @14193@ INDI
1 NAME Andrée/DES MONSTIERS MERINVILLE/
2 GIVN Andrée
2 SURN DES MONSTIERS MERINVILLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 7 APR 2018
3 TIME 14:01
1 FAMS @14200@
1 BIRT
2 DATE 1886
2 _FNA NO
1 DEAT
2 DATE 1980
2 _FNA NO
0 @14201@ INDI
1 NAME Renée/de WENDEL/
2 GIVN Renée
2 SURN de WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:18
1 FAMS @14236@
1 BIRT
2 DATE 1907
2 _FNA NO
1 DEAT
2 DATE 2002
2 _FNA NO
1 FAMC @14200@
0 @14208@ INDI
1 NAME Ségolène/de WENDEL/
2 GIVN Ségolène
2 SURN de WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 7 APR 2018
3 TIME 14:00
1 BIRT
2 DATE 1908
2 _FNA NO
1 DEAT
2 DATE 1981
2 _FNA NO
1 FAMC @14200@
0 @14219@ INDI
1 NAME Berthilde/de WENDEL/
2 GIVN Berthilde
2 SURN de WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:11
1 FAMS @14540@
1 BIRT
2 DATE 1917
2 _FNA NO
1 FAMC @14200@
0 @14227@ INDI
1 NAME France-Victoire/de WENDEL/
2 GIVN France-Victoire
2 SURN de WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:37
1 FAMS @14571@
1 BIRT
2 DATE 1918
2 _FNA NO
1 DEAT
2 DATE 1999
2 _FNA NO
1 FAMC @14200@
0 @14234@ INDI
1 NAME Jean/SEILLIERE/
2 GIVN Jean
2 SURN SEILLIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:19
1 FAMS @14236@
1 BIRT
2 DATE 1907
2 _FNA NO
1 DEAT
2 DATE 1995
2 _FNA NO
0 @14237@ INDI
1 NAME Anne/SEILLIERE/
2 GIVN Anne
2 SURN SEILLIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 7:49
1 FAMS @14275@
1 BIRT
2 DATE 1927
2 _FNA NO
1 FAMC @14236@
0 @14245@ INDI
1 NAME Aimée/SEILLIERE/
2 GIVN Aimée
2 SURN SEILLIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 7:56
1 FAMS @14390@
1 BIRT
2 DATE 1927
2 _FNA NO
1 FAMC @14236@
0 @14252@ INDI
1 NAME Andrée/SEILLIERE/
2 GIVN Andrée
2 SURN SEILLIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:20
1 FAMS @14373@
1 BIRT
2 DATE 1936
2 _FNA NO
1 FAMC @14236@
0 @14260@ INDI
1 NAME Ernest-Antoine/SEILLIERE/
2 GIVN Ernest-Antoine
2 SURN SEILLIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:23
1 FAMS @14330@
1 BIRT
2 DATE 1937
2 _FNA NO
1 FAMC @14236@
0 @14268@ INDI
1 NAME Jean-Michel/de LATTRE/
2 GIVN Jean-Michel
2 SURN de LATTRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 7:49
1 FAMS @14275@
1 BIRT
2 DATE 1909
2 _FNA NO
1 DEAT
2 DATE 1972
2 _FNA NO
0 @14276@ INDI
1 NAME Julie/de LATTRE/
2 GIVN Julie
2 SURN de LATTRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:46
1 FAMS @14309@
1 FAMS @18365@
1 BIRT
2 DATE 1955
2 _FNA NO
1 FAMC @14275@
0 @14283@ INDI
1 NAME Marie/de LATTRE/
2 GIVN Marie
2 SURN de LATTRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:30
1 FAMS @18371@
1 BIRT
2 DATE 1959
2 _FNA NO
1 FAMC @14275@
0 @14291@ INDI
1 NAME Émilie/de LATTRE/
2 GIVN Émilie
2 SURN de LATTRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:31
1 FAMS @18381@
1 FAMS @18388@
1 BIRT
2 DATE 1962
2 _FNA NO
1 FAMC @14275@
0 @14299@ INDI
1 NAME Juliette/de GONET/
2 GIVN Juliette
2 SURN de GONET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:42
1 BIRT
2 DATE 1980
2 _FNA NO
1 FAMC @18365@
0 @14307@ INDI
1 NAME Jean/SAINT-BRIS/
2 GIVN Jean
2 SURN SAINT-BRIS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:46
1 FAMS @14309@
1 BIRT
2 DATE 1947
2 _FNA NO
1 DEAT
2 DATE 2004
2 _FNA NO
0 @14311@ INDI
1 NAME Éloi/SAINT-BRIS/
2 GIVN Éloi
2 SURN SAINT-BRIS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 7:44
1 BIRT
2 DATE 1989
2 _FNA NO
1 FAMC @14309@
0 @14318@ INDI
1 NAME Violette/SAINT-BRIS/
2 GIVN Violette
2 SURN SAINT-BRIS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 7:44
1 BIRT
2 DATE 1990
2 _FNA NO
1 FAMC @14309@
0 @14328@ INDI
1 NAME Antoinette/BARBEY/
2 GIVN Antoinette
2 SURN BARBEY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:23
1 FAMS @14330@
1 BIRT
2 DATE 1950
2 _FNA NO
0 @14331@ INDI
1 NAME Valentine/SEILLIERE/
2 GIVN Valentine
2 SURN SEILLIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:24
1 FAMS @18349@
1 BIRT
2 DATE 1972
2 _FNA NO
1 FAMC @14330@
0 @14349@ INDI
1 NAME Jean-Baptiste/SEILLIERE/
2 GIVN Jean-Baptiste
2 SURN SEILLIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 8:48
1 FAMS @21780@
1 BIRT
2 DATE 1977
2 _FNA NO
1 FAMC @14330@
0 @14356@ INDI
1 NAME Noémie/SEILLIERE/
2 GIVN Noémie
2 SURN SEILLIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:25
1 FAMS @18355@
1 BIRT
2 DATE 1981
2 _FNA NO
1 FAMC @14330@
0 @14363@ INDI
1 NAME Alexandre/SEILLIERE/
2 GIVN Alexandre
2 SURN SEILLIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 7:50
1 BIRT
2 DATE 1989
2 _FNA NO
1 FAMC @14330@
0 @14371@ INDI
1 NAME Yves/JAIGU/
2 GIVN Yves
2 SURN JAIGU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:20
1 FAMS @14373@
1 BIRT
2 DATE 1924
2 _FNA NO
0 @14375@ INDI
1 NAME Charles-Marie/JAIGU/
2 GIVN Charles-Marie
2 SURN JAIGU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:21
1 FAMS @18339@
1 BIRT
2 DATE 1968
2 _FNA NO
1 FAMC @14373@
0 @14383@ INDI
1 NAME Louis/BALSAN/
2 GIVN Louis
2 SURN BALSAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 7:56
1 FAMS @14390@
1 BIRT
2 DATE 1911
2 _FNA NO
1 DEAT
2 DATE 1982
2 _FNA NO
0 @14392@ INDI
1 NAME France-Aimée/BALSAN/
2 GIVN France-Aimée
2 SURN BALSAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 7:57
1 FAMS @14439@
1 BIRT
2 DATE 1948
2 _FNA NO
1 FAMC @14390@
0 @14399@ INDI
1 NAME Nathalie/BALSAN/
2 GIVN Nathalie
2 SURN BALSAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 7:59
1 FAMS @14446@
1 BIRT
2 DATE 1949
2 _FNA NO
1 FAMC @14390@
0 @14406@ INDI
1 NAME Charles/BALSAN/
2 GIVN Charles
2 SURN BALSAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:02
1 FAMS @14459@
1 BIRT
2 DATE 1951
2 _FNA NO
1 FAMC @14390@
0 @14413@ INDI
1 NAME Humbert/BALSAN/
2 GIVN Humbert
2 SURN BALSAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:03
1 FAMS @14493@
1 BIRT
2 DATE 1954
2 _FNA NO
1 FAMC @14390@
0 @14421@ INDI
1 NAME Marie-Osmonde/BALSAN/
2 GIVN Marie-Osmonde
2 SURN BALSAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:05
1 FAMS @14511@
1 BIRT
2 DATE 1960
2 _FNA NO
1 FAMC @14390@
0 @14428@ INDI
1 NAME Jean/BALSAN/
2 GIVN Jean
2 SURN BALSAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:06
1 FAMS @14536@
1 BIRT
2 DATE 1964
2 _FNA NO
1 FAMC @14390@
0 @14437@ INDI
1 NAME Nguyen/HUU GIAO/
2 GIVN Nguyen
2 SURN HUU GIAO
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 NOV 2019
3 TIME 8:56
1 FAMS @14439@
1 NOTE In Hue, Nguyen Huu Giao president of the Student gie Committee, said ky's NEW YORK Ja- nation suli would be ihe "best I cob K..."
2 CONT Publication: St. Joseph, Buchanan County, MO, USA
2 CONT Date: 20 avr 1966
0 @14442@ INDI
1 NAME Corentin/de TREGOMAIN/
2 GIVN Corentin
2 SURN de TREGOMAIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 7:58
1 BIRT
2 DATE 1972
2 _FNA NO
1 FAMC @14446@
0 @14449@ INDI
1 NAME Victor/BALSAN/
2 GIVN Victor
2 SURN BALSAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 7:59
1 BIRT
2 DATE 1984
2 _FNA NO
1 FAMC @14446@
0 @14457@ INDI
1 NAME Sibylle/DARBLAY/
2 GIVN Sibylle
2 SURN DARBLAY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:02
1 FAMS @14459@
0 @14460@ INDI
1 NAME Charlotte/BALSAN/
2 GIVN Charlotte
2 SURN BALSAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:00
1 BIRT
2 DATE 1973
2 _FNA NO
1 FAMC @14459@
0 @14467@ INDI
1 NAME Augustin/BALSAN/
2 GIVN Augustin
2 SURN BALSAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:00
1 BIRT
2 DATE 1976
2 _FNA NO
1 FAMC @14459@
0 @14474@ INDI
1 NAME Martin/BALSAN/
2 GIVN Martin
2 SURN BALSAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:01
1 BIRT
2 DATE 1980
2 _FNA NO
1 FAMC @14459@
0 @14482@ INDI
1 NAME Marie-Margaux/BALSAN/
2 GIVN Marie-Margaux
2 SURN BALSAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:02
1 BIRT
2 DATE 1985
2 _FNA NO
1 FAMC @14459@
0 @14491@ INDI
1 NAME Donna/MAC LOAD/
2 GIVN Donna
2 SURN MAC LOAD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:03
1 FAMS @14493@
0 @14494@ INDI
1 NAME Louise/BALSAN/
2 GIVN Louise
2 SURN BALSAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:03
1 BIRT
2 DATE 1985
2 _FNA NO
1 FAMC @14493@
0 @14501@ INDI
1 NAME Camille/BALSAN/
2 GIVN Camille
2 SURN BALSAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:03
1 BIRT
2 DATE 1988
2 _FNA NO
1 FAMC @14493@
0 @14509@ INDI
1 NAME Olivier/COSTA de BEAUREGARD/
2 GIVN Olivier
2 SURN COSTA de BEAUREGARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:05
1 FAMS @14511@
0 @14512@ INDI
1 NAME Marguerite/COSTA de BEAUREGARD/
2 GIVN Marguerite
2 SURN COSTA de BEAUREGARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:05
1 BIRT
2 DATE 1986
2 _FNA NO
1 FAMC @14511@
0 @14519@ INDI
1 NAME Anne/COSTA de BEAUREGARD/
2 GIVN Anne
2 SURN COSTA de BEAUREGARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:05
1 BIRT
2 DATE 1988
2 _FNA NO
1 FAMC @14511@
0 @14526@ INDI
1 NAME Louis/COSTA de BEAUREGARD/
2 GIVN Louis
2 SURN COSTA de BEAUREGARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:05
1 BIRT
2 DATE 1990
2 _FNA NO
1 FAMC @14511@
0 @14534@ INDI
1 NAME Amélie/PORTEU de la MORANDIERE/
2 GIVN Amélie
2 SURN PORTEU de la MORANDIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:06
1 FAMS @14536@
0 @14538@ INDI
1 NAME Philippe/de MONTREMY/
2 GIVN Philippe
2 SURN de MONTREMY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:11
1 FAMS @14540@
0 @14541@ INDI
1 NAME Véronique/de MONTREMY/
2 GIVN Véronique
2 SURN de MONTREMY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:20
1 FAMS @14647@
1 BIRT
2 DATE 1946
2 _FNA NO
1 FAMC @14540@
0 @14548@ INDI
1 NAME Anne/de MONTREMY/
2 GIVN Anne
2 SURN de MONTREMY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:22
1 FAMS @14665@
1 BIRT
2 DATE 1947
2 _FNA NO
1 FAMC @14540@
0 @14555@ INDI
1 NAME Henry/de MONTREMY/
2 GIVN Henry
2 SURN de MONTREMY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:23
1 FAMS @14698@
1 FAMC @14540@
0 @14561@ INDI
1 NAME Jean-Maurice/de MONTREMY/
2 GIVN Jean-Maurice
2 SURN de MONTREMY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:35
1 FAMS @18393@
1 BIRT
2 DATE 1952
2 _FNA NO
1 FAMC @14540@
0 @14569@ INDI
1 NAME Pierre/CELIER/
2 GIVN Pierre
2 SURN CELIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:37
1 FAMS @14571@
1 BIRT
2 DATE 1917
2 _FNA NO
0 @14572@ INDI
1 NAME Nicolas/CELIER/
2 GIVN Nicolas
2 SURN CELIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:15
1 FAMS @14589@
1 BIRT
2 DATE 1943
2 _FNA NO
1 FAMC @14571@
0 @14579@ INDI
1 NAME Priscilia/CELIER/
2 GIVN Priscilia
2 SURN CELIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:38
1 FAMS @14622@
1 BIRT
2 DATE 1952
2 _FNA NO
1 FAMC @14571@
0 @14587@ INDI
1 NAME Odile/MACÉ de GASTINES/
2 GIVN Odile
2 SURN MACÉ de GASTINES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:15
1 FAMS @14589@
0 @14590@ INDI
1 NAME Alexandre/CELIER/
2 GIVN Alexandre
2 SURN CELIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:14
1 BIRT
2 DATE 1969
2 _FNA NO
1 FAMC @14589@
0 @14597@ INDI
1 NAME Clément/CELIER/
2 GIVN Clément
2 SURN CELIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:15
1 BIRT
2 DATE 1971
2 _FNA NO
1 FAMC @14589@
0 @14604@ INDI
1 NAME Félicité/CELIER/
2 GIVN Félicité
2 SURN CELIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:15
1 BIRT
2 DATE 1975
2 _FNA NO
1 FAMC @14589@
0 @14611@ INDI
1 NAME Espérance/CELIER/
2 GIVN Espérance
2 SURN CELIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:15
1 BIRT
2 DATE 1978
2 _FNA NO
1 FAMC @14589@
0 @14620@ INDI
1 NAME Jean-Charles/de MOUSTIER/
2 GIVN Jean-Charles
2 SURN de MOUSTIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:38
1 FAMS @14622@
1 BIRT
2 DATE 1952
2 _FNA NO
1 DEAT
2 DATE 1994
2 _FNA NO
0 @14623@ INDI
1 NAME Victoire/de MOUSTIER/
2 GIVN Victoire
2 SURN de MOUSTIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:17
1 BIRT
2 DATE 1984
2 _FNA NO
1 FAMC @14622@
0 @14630@ INDI
1 NAME Esther/de MOUSTIER/
2 GIVN Esther
2 SURN de MOUSTIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:17
1 BIRT
2 DATE 1985
2 _FNA NO
1 FAMC @14622@
0 @14637@ INDI
1 NAME Élie/de MOUSTIER/
2 GIVN Élie
2 SURN de MOUSTIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:18
1 BIRT
2 DATE 1987
2 _FNA NO
1 FAMC @14622@
0 @14645@ INDI
1 NAME Xavier/GOUPY/
2 GIVN Xavier
2 SURN GOUPY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:20
1 FAMS @14647@
0 @14648@ INDI
1 NAME Anne-Claire/GOUPY/
2 GIVN Anne-Claire
2 SURN GOUPY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:20
1 BIRT
2 DATE 1979
2 _FNA NO
1 FAMC @14647@
0 @14655@ INDI
1 NAME Berthilde/GOUPY/
2 GIVN Berthilde
2 SURN GOUPY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:20
1 BIRT
2 DATE 1983
2 _FNA NO
1 FAMC @14647@
0 @14663@ INDI
1 NAME Jean/de CHAMPS de SAINT-LEGER/
2 GIVN Jean
2 SURN de CHAMPS de SAINT-LEGER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:22
1 FAMS @14665@
0 @14666@ INDI
1 NAME Emmanuelle/de CHAMPS de SAINT-LEGER/
2 GIVN Emmanuelle
2 SURN de CHAMPS de SAINT-LEGER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:21
1 BIRT
2 DATE 1974
2 _FNA NO
1 FAMC @14665@
0 @14673@ INDI
1 NAME Laure/de CHAMPS de SAINT-LEGER/
2 GIVN Laure
2 SURN de CHAMPS de SAINT-LEGER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:22
1 BIRT
2 DATE 1976
2 _FNA NO
1 FAMC @14665@
0 @14680@ INDI
1 NAME Pascale/de CHAMPS de SAINT-LEGER/
2 GIVN Pascale
2 SURN de CHAMPS de SAINT-LEGER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:22
1 BIRT
2 DATE 1980
2 _FNA NO
1 FAMC @14665@
0 @14687@ INDI
1 NAME Marc/de CHAMPS de SAINT-LEGER/
2 GIVN Marc
2 SURN de CHAMPS de SAINT-LEGER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:22
1 BIRT
2 DATE 1984
2 _FNA NO
1 FAMC @14665@
0 @14696@ INDI
1 NAME Évelyne/GONTHIER/
2 GIVN Évelyne
2 SURN GONTHIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:23
1 FAMS @14698@
0 @14699@ INDI
1 NAME Benoît/de MONTREMY/
2 GIVN Benoît
2 SURN de MONTREMY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:35
1 BIRT
2 DATE 1977
2 _FNA NO
1 FAMC @18393@
0 @14706@ INDI
1 NAME Antoine/de MONTREMY/
2 GIVN Antoine
2 SURN de MONTREMY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:35
1 BIRT
2 DATE 1979
2 _FNA NO
1 FAMC @18393@
0 @14713@ INDI
1 NAME Anne-Sophie/de MONTREMY/
2 GIVN Anne-Sophie
2 SURN de MONTREMY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:35
1 BIRT
2 DATE 1981
2 _FNA NO
1 FAMC @18393@
0 @14719@ INDI
1 NAME Marie/de MONTREMY/
2 GIVN Marie
2 SURN de MONTREMY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:35
1 BIRT
2 DATE 1983
2 _FNA NO
1 FAMC @18393@
0 @14725@ INDI
1 NAME François/de MONTREMY/
2 GIVN François
2 SURN de MONTREMY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:35
1 BIRT
2 DATE 1986
2 _FNA NO
1 FAMC @18393@
0 @14735@ INDI
1 NAME Valérie-Charlotte/DOLBOIS/
2 GIVN Valérie-Charlotte
2 SURN DOLBOIS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 12:41
1 FAMS @14737@
0 @14738@ INDI
1 NAME Paul/de RAMBUTEAU/
2 GIVN Paul
2 SURN de RAMBUTEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:34
1 BIRT
2 DATE 1985
2 _FNA NO
1 FAMC @14737@
0 @14745@ INDI
1 NAME Martin/de RAMBUTEAU/
2 GIVN Martin
2 SURN de RAMBUTEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:34
1 BIRT
2 DATE 1988
2 _FNA NO
1 FAMC @14737@
0 @14754@ INDI
1 NAME Isabelle/PÉNIN de la RAUDIÈRE/
2 GIVN Isabelle
2 SURN PÉNIN de la RAUDIÈRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:36
1 FAMS @14756@
0 @14757@ INDI
1 NAME Joseph/de RAMBUTEAU/
2 GIVN Joseph
2 SURN de RAMBUTEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:36
1 BIRT
2 DATE 1987
2 _FNA NO
1 FAMC @14756@
0 @14764@ INDI
1 NAME Benoît/de RAMBUTEAU/
2 GIVN Benoît
2 SURN de RAMBUTEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:36
1 BIRT
2 DATE 1989
2 _FNA NO
1 FAMC @14756@
0 @14772@ INDI
1 NAME Aymar/de RAMBUTEAU/
2 GIVN Aymar
2 SURN de RAMBUTEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:37
1 BIRT
2 DATE 1957
2 _FNA NO
1 FAMC @11537@
0 @14781@ INDI
1 NAME Anne/de COSSÉ BRISSAC/
2 GIVN Anne
2 SURN de COSSÉ BRISSAC
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:38
1 FAMS @14783@
0 @14785@ INDI
1 NAME Laurent/de RAMBUTEAU/
2 GIVN Laurent
2 SURN de RAMBUTEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:40
1 FAMS @14795@
1 BIRT
2 DATE 1965
2 _FNA NO
1 FAMC @11537@
0 @14793@ INDI
1 NAME Alice/de JERPHANION/
2 GIVN Alice
2 SURN de JERPHANION
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:40
1 FAMS @14795@
0 @14796@ INDI
1 NAME Hélène/de RAMBUTEAU/
2 GIVN Hélène
2 SURN de RAMBUTEAU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:40
1 BIRT
2 DATE 1990
2 _FNA NO
1 FAMC @14795@
0 @14806@ INDI
1 NAME Petros/BUHAYAR-MAVROMICHALIS/
2 GIVN Petros
2 SURN BUHAYAR-MAVROMICHALIS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 13:56
1 FAMS @14810@
1 BIRT
2 DATE 1931
2 _FNA NO
1 DEAT
2 DATE 1986
2 _FNA NO
0 @14828@ INDI
1 NAME Véronique/de SAINT-PHALLE/
2 GIVN Véronique
2 SURN de SAINT-PHALLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:47
1 FAMS @14830@
0 @14831@ INDI
1 NAME Pauline/FRANÇOIS-PONCET/
2 GIVN Pauline
2 SURN FRANÇOIS-PONCET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:47
1 BIRT
2 DATE 1990
2 _FNA NO
1 FAMC @14830@
0 @14844@ INDI
1 NAME Raphaëlle/PAMARD/
2 GIVN Raphaëlle
2 SURN PAMARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 13:44
1 FAMS @14846@
1 BIRT
2 DATE 1962
2 _FNA NO
0 @14847@ INDI
1 NAME André/FRANÇOIS-PONCET/
2 GIVN André
2 SURN FRANÇOIS-PONCET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 8:49
1 BIRT
2 DATE 1987
2 _FNA NO
1 FAMC @14846@
0 @14858@ INDI
1 NAME Madeleine/de MITRY/
2 GIVN Madeleine
2 SURN de MITRY
2 NICK MANON
1 SEX F
1 OCCU Médecin
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 10:20
1 FAMS @14870@
1 BIRT
2 DATE 1934
2 _FNA NO
1 FAMC @11467@
0 @14868@ INDI
1 NAME Boris/FALALA LUMI/
2 GIVN Boris
2 SURN FALALA LUMI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 12:45
1 FAMS @14870@
1 BIRT
2 DATE 1930
2 _FNA NO
1 DEAT
2 DATE 1998
2 _FNA NO
0 @14878@ INDI
1 NAME Marguerite/DES CARS/
2 GIVN Marguerite
2 SURN DES CARS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:21
1 FAMS @14880@
1 BIRT
2 DATE 1941
2 PLAC Le Mans,72000,Sarthe,Pays de la Loire,FRANCE,
3 MAP
4 LATI N48.000000
4 LONG E0.200000
2 _FNA NO
1 FAMC @19015@
0 @14881@ INDI
1 NAME François/de MITRY/
2 GIVN François
2 SURN de MITRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:27
1 FAMS @17751@
1 BIRT
2 DATE 1966
2 _FNA NO
1 FAMC @14880@
0 @14889@ INDI
1 NAME Catherine/COLLOT/
2 GIVN Catherine
2 SURN COLLOT
1 SEX F
1 OCCU Artiste Peintre
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:30
1 FAMS @14891@
1 BIRT
2 DATE 1945
2 _FNA NO
1 FAMC @19075@
0 @14893@ INDI
1 NAME Jean-Hyacinthe/de MITRY/
2 GIVN Jean-Hyacinthe
2 SURN de MITRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 7 DEC 2019
3 TIME 17:55
1 FAMS @17758@
1 BIRT
2 DATE 1978
2 _FNA NO
1 FAMC @14891@
0 @14902@ INDI
1 NAME Oriane/d'IRUMBERRY de SALABERRY/
2 GIVN Oriane
2 SURN d'IRUMBERRY de SALABERRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 9:52
1 BIRT
2 DATE 1982
2 _FNA NO
1 FAMC @14901@
0 @14909@ INDI
1 NAME Édouard/d'IRUMBERRY de SALABERRY/
2 GIVN Édouard
2 SURN d'IRUMBERRY de SALABERRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 9:52
1 BIRT
2 DATE 1984
2 _FNA NO
1 FAMC @14901@
0 @14919@ INDI
1 NAME François-Pascal/de STRASCHNOV/
2 GIVN François-Pascal
2 SURN de STRASCHNOV
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 14:06
1 FAMS @14921@
1 BIRT
2 DATE 1946
2 _FNA NO
0 @14922@ INDI
1 NAME Emmanuel/de STRASCHNOV/
2 GIVN Emmanuel
2 SURN de STRASCHNOV
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 APR 2019
3 TIME 16:45
1 FAMS @22918@
1 FAMS @30831@
1 BIRT
2 DATE 1983
2 _FNA NO
1 FAMC @14921@
0 @14929@ INDI
1 NAME Michaël/de STRASCHNOV/
2 GIVN Michaël
2 SURN de STRASCHNOV
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 DEC 2018
3 TIME 11:25
1 FAMS @22922@
1 BIRT
2 DATE 1986
2 _FNA NO
1 FAMC @14921@
0 @14937@ INDI
1 NAME Églantine/de STRASCHNOV/
2 GIVN Églantine
2 SURN de STRASCHNOV
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 MAY 2019
3 TIME 12:45
1 FAMS @30928@
1 BIRT
2 DATE 1989
2 _FNA NO
1 FAMC @14921@
0 @14959@ INDI
1 NAME Émilie Marie Madeleine/PESCATORE/
2 GIVN Émilie Marie Madeleine
2 SURN PESCATORE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 12:27
1 FAMS @14966@
1 BIRT
2 DATE 1840
2 _FNA NO
1 DEAT
2 DATE 1913
2 _FNA NO
0 @14977@ INDI
1 NAME Auguste/de GARGAN/
2 GIVN Auguste
2 SURN de GARGAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 17:51
1 FAMS @17612@
1 BIRT
2 DATE 1861
2 _FNA NO
1 DEAT
2 DATE 1902
2 _FNA NO
1 FAMC @14966@
0 @14987@ INDI
1 NAME Marguerite/de GARGAN/
2 GIVN Marguerite
2 SURN de GARGAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:02
1 FAMS @17621@
1 BIRT
2 DATE 1862
2 _FNA NO
1 DEAT
2 DATE 1948
2 _FNA NO
1 FAMC @14966@
0 @14997@ INDI
1 NAME Louis/de GARGAN/
2 GIVN Louis
2 SURN de GARGAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:12
1 FAMS @17630@
1 BIRT
2 DATE 1869
2 _FNA NO
1 DEAT
2 DATE 1923
2 _FNA NO
1 FAMC @14966@
0 @15008@ INDI
1 NAME Eulalie/de GARGAN/
2 GIVN Eulalie
2 SURN de GARGAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 NOV 2019
3 TIME 7:52
1 FAMS @17639@
1 BIRT
2 DATE 1877
2 _FNA NO
1 DEAT
2 DATE 1957
2 _FNA NO
1 FAMC @14966@
0 @15018@ INDI
1 NAME Charles/de GARGAN/
2 GIVN Charles
2 SURN de GARGAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:35
1 FAMS @17648@
1 BIRT
2 DATE 1880
2 _FNA NO
1 DEAT
2 DATE 1973
2 _FNA NO
1 FAMC @14966@
0 @15028@ INDI
1 NAME Madeleine/de MITRY/
2 GIVN Madeleine
2 SURN de MITRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 7:53
1 FAMS @15113@
1 BIRT
2 DATE 1884
2 PLAC Versailles,78000,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.800000
4 LONG E2.133330
2 _FNA NO
1 DEAT
2 DATE 1971
2 _FNA NO
1 FAMC @12203@
0 @15038@ INDI
1 NAME Jacques/de MITRY/
2 GIVN Jacques
2 SURN de MITRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 8:05
1 BIRT
2 DATE 1886
2 PLAC Versailles,78000,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.800000
4 LONG E2.133330
2 _FNA NO
1 DEAT
2 DATE 26 MAY 1916
2 PLAC Bar-le-Duc,55000,Meuse,Grand Est,FRANCE,
3 MAP
4 LATI N48.783330
4 LONG E5.166670
2 _FNA NO
2 CAUS mort au combat
2 NOTE TYPHOIDE CONTRACTÉE A VERDUN
1 FAMC @12203@
0 @15048@ INDI
1 NAME Françoise/de MITRY/
2 GIVN Françoise
2 SURN de MITRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 8:07
1 FAMS @15103@
1 BIRT
2 DATE 1887
2 PLAC Charleville-Mézières,08000,Ardennes,Grand Est,FRANCE,
3 MAP
4 LATI N49.766670
4 LONG E4.716670
2 _FNA NO
1 DEAT
2 DATE 1915
2 PLAC Epiry,58800,Nièvre,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.183330
4 LONG E3.716670
2 _FNA NO
1 FAMC @12203@
0 @15058@ INDI
1 NAME Anne/de MITRY/
2 GIVN Anne
2 SURN de MITRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 8:00
1 FAMS @15087@
1 FAMS @18684@
1 BIRT
2 DATE 1889
2 PLAC Charleville-Mézières,08000,Ardennes,Grand Est,FRANCE,
3 MAP
4 LATI N49.766670
4 LONG E4.716670
2 _FNA NO
1 DEAT
2 DATE 1963
2 _FNA NO
1 FAMC @12203@
0 @15068@ INDI
1 NAME Nicol/de MITRY/
2 GIVN Nicol
2 SURN de MITRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 DEC 2018
3 TIME 11:51
1 BIRT
2 DATE 1894
2 PLAC Nancy,54000,Meurthe-et-Moselle,Grand Est,FRANCE,
3 MAP
4 LATI N48.683330
4 LONG E6.200000
2 _FNA NO
1 DEAT
2 DATE 6 OCT 1915
2 PLAC Souain-Perthes-lès-Hurlus,51600,Marne,Grand Est,FRANCE,
3 MAP
4 LATI N49.183630
4 LONG E4.543410
2 _FNA NO
2 CAUS mort au combat
2 NOTE DISPARU
3 CONT Informations : Morts pour la France
3 CONT Informations : Croix de guerre Saint-Cyrien, sous -lieutenant d'Infanterie Tué le 6 octobre 1915. Citation ou Remarque Frère du précédent
3 CONT Sources : Tableau d'Honneur
1 FAMC @12203@
0 @15080@ INDI
1 NAME Édouard/de DARTEIN/
2 GIVN Édouard
2 SURN de DARTEIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 7:58
1 FAMS @15087@
1 BIRT
2 DATE 1886
2 PLAC Bourges,18000,Cher,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N47.083330
4 LONG E2.400000
2 _FNA NO
1 DEAT
2 DATE 1925
2 PLAC Strasbourg,67000,Bas-Rhin,Grand Est,FRANCE,
3 MAP
4 LATI N48.583420
4 LONG E7.742960
2 _FNA NO
2 CAUS BLESSURE DE GUERRE
0 @15088@ INDI
1 NAME Françoise/de DARTEIN/
2 GIVN Françoise
2 SURN de DARTEIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 8:53
1 FAMS @16231@
1 FAMS @18775@
1 FAMS @18783@
1 BIRT
2 DATE 1916
2 _FNA NO
1 FAMC @15087@
0 @15096@ INDI
1 NAME Louis/CARRELET de LOISY/
2 GIVN Louis
2 SURN CARRELET de LOISY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 8:09
1 FAMS @15103@
1 BIRT
2 DATE 1886
2 _FNA NO
1 DEAT
2 DATE 12 SEP 1918
2 PLAC Xammes,54470,Meurthe-et-Moselle,Grand Est,FRANCE,
3 MAP
4 LATI N48.974480
4 LONG E5.854730
2 _FNA NO
2 CAUS mort au combat
2 NOTE COMBAT AERIEN
0 @15106@ INDI
1 NAME Max/de LUSSAC/
2 GIVN Max
2 SURN de LUSSAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 7:54
1 FAMS @15113@
1 BIRT DEPORTE
2 DATE 1879
2 PLAC Neuengamme,,,,,CAMP
2 _FNA NO
1 DEAT
2 DATE 1944
2 _FNA NO
2 CAUS mort au combat
0 @15115@ INDI
1 NAME Georges/de LUSSAC/
2 GIVN Georges
2 SURN de LUSSAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 8:21
1 FAMS @15150@
1 BIRT
2 DATE 1914
2 _FNA NO
1 DEAT
2 DATE 1956
2 _FNA NO
1 FAMC @15113@
0 @15125@ INDI
1 NAME Antonin/de LUSSAC/
2 GIVN Antonin
2 SURN de LUSSAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 10:22
1 FAMS @15154@
1 BIRT
2 DATE 1922
2 _FNA NO
1 DEAT
2 DATE 1977
2 _FNA NO
1 FAMC @15113@
0 @15135@ INDI
1 NAME Max/de LUSSAC/
2 GIVN Max
2 SURN de LUSSAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 10:26
1 FAMS @15179@
1 BIRT
2 DATE 1925
2 _FNA NO
1 DEAT
2 DATE 1987
2 _FNA NO
1 FAMC @15113@
0 @15146@ INDI
1 NAME Bénédicte/TYREL de POIX/
2 GIVN Bénédicte
2 SURN TYREL de POIX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 8:25
1 FAMS @15150@
1 BIRT
2 DATE 1916
2 PLAC Rueil-Malmaison,92500,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.876500
4 LONG E2.189670
2 _FNA NO
1 DEAT
2 DATE 1970
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @18743@
0 @15152@ INDI
1 NAME Jeanne/BUROT de CARCOUËT/
2 GIVN Jeanne
2 SURN BUROT de CARCOUËT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 10:22
1 FAMS @15154@
0 @15155@ INDI
1 NAME Hervé/de LUSSAC/
2 GIVN Hervé
2 SURN de LUSSAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:47
1 FAMS @15347@
1 BIRT
2 DATE 1947
2 _FNA NO
1 FAMC @15154@
0 @15162@ INDI
1 NAME Jeanne/de LUSSAC/
2 GIVN Jeanne
2 SURN de LUSSAC
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 10:37
1 FAMS @15329@
1 BIRT
2 DATE 1948
2 _FNA NO
1 FAMC @15154@
0 @15169@ INDI
1 NAME Christine/de LUSSAC/
2 GIVN Christine
2 SURN de LUSSAC
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 10:35
1 FAMS @15289@
1 BIRT
2 DATE 1952
2 _FNA NO
1 FAMC @15154@
0 @15177@ INDI
1 NAME Claude/PESLE/
2 GIVN Claude
2 SURN PESLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 14:21
1 FAMS @15179@
1 BIRT
2 DATE 1932
2 _FNA NO
0 @15180@ INDI
1 NAME Sophie/de LUSSAC/
2 GIVN Sophie
2 SURN de LUSSAC
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 10:32
1 FAMS @15264@
1 BIRT
2 DATE 1955
2 _FNA NO
1 FAMC @15179@
0 @15188@ INDI
1 NAME Max-Antoine/de LUSSAC/
2 GIVN Max-Antoine
2 SURN de LUSSAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 10:30
1 FAMS @15239@
1 BIRT
2 DATE 1956
2 _FNA NO
1 FAMC @15179@
0 @15196@ INDI
1 NAME Régis/de LUSSAC/
2 GIVN Régis
2 SURN de LUSSAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 10:24
1 BIRT
2 DATE 1957
2 _FNA NO
1 FAMC @15179@
0 @15203@ INDI
1 NAME Guillaume/de LUSSAC/
2 GIVN Guillaume
2 SURN de LUSSAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 14:59
1 FAMS @15220@
1 BIRT
2 DATE 1961
2 _FNA NO
1 FAMC @15179@
0 @15210@ INDI
1 NAME Christophe/de LUSSAC/
2 GIVN Christophe
2 SURN de LUSSAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 15:03
1 FAMS @17788@
1 BIRT
2 DATE 1969
2 _FNA NO
1 FAMC @15179@
0 @15218@ INDI
1 NAME Constance/de BOISSARD de SENARPONT/
2 GIVN Constance
2 SURN de BOISSARD de SENARPONT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 14:59
1 FAMS @15220@
0 @15222@ INDI
1 NAME Tancrède/de LUSSAC/
2 GIVN Tancrède
2 SURN de LUSSAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 10:28
1 BIRT
2 DATE 1987
2 _FNA NO
1 FAMC @15220@
0 @15229@ INDI
1 NAME Jean/de LUSSAC/
2 GIVN Jean
2 SURN de LUSSAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 10:28
1 BIRT
2 DATE 1989
2 _FNA NO
1 FAMC @15220@
0 @15237@ INDI
1 NAME Camille/de LA CHAPELLE/
2 GIVN Camille
2 SURN de LA CHAPELLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 10:30
1 FAMS @15239@
0 @15240@ INDI
1 NAME Hector/de LUSSAC/
2 GIVN Hector
2 SURN de LUSSAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 14:48
1 FAMS @19298@
1 BIRT
2 DATE 1982
2 _FNA NO
1 FAMC @15239@
0 @15247@ INDI
1 NAME Maximilien/de LUSSAC/
2 GIVN Maximilien
2 SURN de LUSSAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 10:30
1 BIRT
2 DATE 1983
2 _FNA NO
1 FAMC @15239@
0 @15254@ INDI
1 NAME Malo/de LUSSAC/
2 GIVN Malo
2 SURN de LUSSAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 10:30
1 BIRT
2 DATE 1987
2 _FNA NO
1 FAMC @15239@
0 @15262@ INDI
1 NAME Patrick/de MONTJOYE/
2 GIVN Patrick
2 SURN de MONTJOYE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 14:56
1 FAMS @15264@
0 @15265@ INDI
1 NAME Hugues/de MONTJOYE/
2 GIVN Hugues
2 SURN de MONTJOYE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 14:56
1 BIRT
2 DATE 1982
2 _FNA NO
1 FAMC @15264@
0 @15272@ INDI
1 NAME Julie/de MONTJOYE/
2 GIVN Julie
2 SURN de MONTJOYE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 14:56
1 BIRT
2 DATE 1984
2 _FNA NO
1 FAMC @15264@
0 @15279@ INDI
1 NAME Agnès/de MONTJOYE/
2 GIVN Agnès
2 SURN de MONTJOYE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 14:56
1 BIRT
2 DATE 1986
2 _FNA NO
1 FAMC @15264@
0 @15287@ INDI
1 NAME Henri/GRUTER/
2 GIVN Henri
2 SURN GRUTER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 14:11
1 FAMS @15289@
1 BIRT
2 DATE 1951
2 _FNA NO
1 DEAT
2 DATE 2006
2 _FNA NO
0 @15290@ INDI
1 NAME Stéphanie/GRUTER/
2 GIVN Stéphanie
2 SURN GRUTER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 14:43
1 FAMS @19269@
1 BIRT
2 DATE 1976
2 _FNA NO
1 FAMC @15289@
0 @15297@ INDI
1 NAME Benjamin/GRUTER/
2 GIVN Benjamin
2 SURN GRUTER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 10:33
1 BIRT
2 DATE 1985
2 _FNA NO
1 FAMC @15289@
0 @15304@ INDI
1 NAME Sébastien/GRUTER/
2 GIVN Sébastien
2 SURN GRUTER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 10:34
1 BIRT
2 DATE 1980
2 _FNA NO
1 FAMC @15289@
0 @15311@ INDI
1 NAME Dominique/GRUTER/
2 GIVN Dominique
2 SURN GRUTER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 10:35
1 BIRT
2 DATE 1977
2 _FNA NO
1 FAMC @15289@
0 @15319@ INDI
1 NAME Stanislas/GRUTER/
2 GIVN Stanislas
2 SURN GRUTER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 10:35
1 BIRT
2 DATE 1987
2 _FNA NO
1 FAMC @15289@
0 @15327@ INDI
1 NAME Pascal/BARBET-MASSIN/
2 GIVN Pascal
2 SURN BARBET-MASSIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 14:11
1 FAMS @15329@
1 BIRT
2 DATE 1943
2 _FNA NO
1 DEAT
2 DATE 2005
2 _FNA NO
0 @15330@ INDI
1 NAME Grégoire/BARBET-MASSIN/
2 GIVN Grégoire
2 SURN BARBET-MASSIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 10:36
1 BIRT
2 DATE 1979
2 _FNA NO
1 FAMC @15329@
0 @15337@ INDI
1 NAME Pauline/BARBET-MASSIN/
2 GIVN Pauline
2 SURN BARBET-MASSIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 10:37
1 BIRT
2 DATE 1980
2 _FNA NO
1 FAMC @15329@
0 @15345@ INDI
1 NAME Alix/d'HARDIVILLIERS/
2 GIVN Alix
2 SURN d'HARDIVILLIERS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:49
1 FAMS @15347@
1 BIRT
2 DATE 1950
2 _FNA NO
1 FAMC @19158@
0 @15350@ INDI
1 NAME Bérengère/de LUSSAC/
2 GIVN Bérengère
2 SURN de LUSSAC
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:53
1 FAMS @19166@
1 BIRT
2 DATE 1972
2 _FNA NO
1 FAMC @15347@
0 @15358@ INDI
1 NAME Sybille/de LUSSAC/
2 GIVN Sybille
2 SURN de LUSSAC
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:55
1 FAMS @19213@
1 BIRT
2 DATE 1973
2 _FNA NO
1 FAMC @15347@
0 @15365@ INDI
1 NAME Ségolène/de LUSSAC/
2 GIVN Ségolène
2 SURN de LUSSAC
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:58
1 FAMS @19234@
1 BIRT
2 DATE 1976
2 _FNA NO
1 FAMC @15347@
0 @15373@ INDI
1 NAME Thibaud/de LUSSAC/
2 GIVN Thibaud
2 SURN de LUSSAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 10:39
1 BIRT
2 DATE 1984
2 _FNA NO
1 FAMC @15347@
0 @15380@ INDI
1 NAME Charles/de WENDEL/
2 GIVN Charles
2 SURN de WENDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 11:25
1 FAMS @15394@
1 BIRT
2 DATE 1809
2 _FNA NO
1 DEAT
2 DATE 1870
2 _FNA NO
1 NOTE CHARLES 2
1 FAMC @15447@
0 @15391@ INDI
1 NAME Jeanne-Marthe/de PECHPEYROU COMMINGES de GUITAUT/
2 GIVN Jeanne-Marthe
2 SURN de PECHPEYROU COMMINGES de GUITAUT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 11:25
1 FAMS @15394@
1 BIRT
2 DATE 1825
2 _FNA NO
1 DEAT
2 DATE 1908
2 _FNA NO
0 @15396@ INDI
1 NAME Robert Adrien Charles/de WENDEL/
2 GIVN Robert Adrien Charles
2 SURN de WENDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2019
3 TIME 10:14
1 FAMS @15432@
1 BIRT
2 DATE 1847
2 _FNA NO
1 DEAT
2 DATE 1903
2 _FNA NO
1 FAMC @15394@
0 @15405@ INDI
1 NAME Caroline/de WENDEL/
2 GIVN Caroline
2 SURN de WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 17:52
1 FAMS @15422@
1 BIRT
2 DATE 1851
2 _FNA NO
1 DEAT
2 DATE 1939
2 _FNA NO
1 FAMC @15394@
0 @15415@ INDI
1 NAME Pierre/de MONTAIGU/
2 GIVN Pierre
2 SURN de MONTAIGU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 17:52
1 FAMS @15422@
1 BIRT
2 DATE 1844
2 _FNA NO
1 DEAT
2 DATE 1927
2 _FNA NO
0 @15425@ INDI
1 NAME Consuelo/MANUEL de GRAMEDO/
2 GIVN Consuelo
2 SURN MANUEL de GRAMEDO
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2019
3 TIME 10:14
1 FAMS @15432@
1 BIRT
2 DATE 1850
2 _FNA NO
1 DEAT
2 DATE 1917
2 _FNA NO
0 @15433@ INDI
1 NAME François/de WENDEL/
2 GIVN François
2 SURN de WENDEL
1 SEX M
1 REFN 1W
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 APR 2018
3 TIME 12:03
1 FAMS @15447@
1 BIRT
2 DATE 1778
2 _FNA NO
1 DEAT
2 DATE 1825
2 _FNA NO
1 NOTE arrière-arrière-grand-père commun de marguerite de wendel et d’emmanuel de mitry
1 FAMC @15704@
0 @15444@ INDI
1 NAME Joséphine/de FISCHER de DICOURT/
2 GIVN Joséphine
2 SURN de FISCHER de DICOURT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 APR 2018
3 TIME 8:54
1 FAMS @15447@
1 BIRT
2 DATE 1784
2 _FNA NO
1 DEAT
2 DATE 1872
2 _FNA NO
1 NOTE arrière-arrière-arrière-grand-mère commune de marguerite de wendel et d’emmanuel de mitry
1 FAMC @16188@
0 @15450@ INDI
1 NAME Marguerite-Josephine/de WENDEL/
2 GIVN Marguerite-Josephine
2 SURN de WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 11:07
1 FAMS @15605@
1 BIRT
2 DATE 1804
2 _FNA NO
1 DEAT
2 DATE 1851
2 _FNA NO
1 FAMC @15447@
0 @15461@ INDI
1 NAME Victor-François/de WENDEL/
2 GIVN Victor-François
2 SURN de WENDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 18:12
1 FAMS @15542@
1 BIRT
2 DATE 1807
2 _FNA NO
1 DEAT
2 DATE 1850
2 _FNA NO
1 FAMC @15447@
0 @15471@ INDI
1 NAME Anne-Caroline/de WENDEL/
2 GIVN Anne-Caroline
2 SURN de WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 APR 2018
3 TIME 6:31
1 FAMS @15488@
1 BIRT
2 DATE 1812
2 _FNA NO
1 DEAT
2 DATE 1837
2 _FNA NO
1 FAMC @15447@
0 @15481@ INDI
1 NAME Jean Maurice/DU COETLOSQUET/
2 GIVN Jean Maurice
2 SURN DU COETLOSQUET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 APR 2018
3 TIME 6:31
1 FAMS @15488@
1 BIRT
2 DATE 1808
2 _FNA NO
1 DEAT
2 DATE 1893
2 _FNA NO
0 @15489@ INDI
1 NAME Maurice/DU COETLOSQUET/
2 GIVN Maurice
2 SURN DU COETLOSQUET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 NOV 2019
3 TIME 12:13
1 FAMS @15515@
1 BIRT
2 DATE 1836
2 _FNA NO
1 DEAT
2 DATE 18 MAR 1904
2 PLAC Rambervillers,88700,Vosges,Grand Est,FRANCE,
3 MAP
4 LATI N48.342400
4 LONG E6.635800
2 _FNA NO
1 FAMC @15488@
0 @15500@ INDI
1 NAME Marie Adèle Renée/DEGUERRE/
2 GIVN Marie Adèle Renée
2 SURN DEGUERRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 18:06
1 FAMS @15515@
1 BIRT
2 DATE 1852
2 _FNA NO
1 DEAT
2 DATE 1931
2 _FNA NO
0 @15516@ INDI
1 NAME Caroline/DU COETLOSQUET/
2 GIVN Caroline
2 SURN DU COETLOSQUET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 18:06
1 BIRT
2 DATE 1875
2 _FNA NO
1 DEAT
2 DATE 1906
2 _FNA NO
1 FAMC @15515@
0 @15531@ INDI
1 NAME Marie-Charlotte Octavie/de ROSIERES/
2 GIVN Marie-Charlotte Octavie
2 SURN de ROSIERES
2 NICK PAULINE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 18:12
1 FAMS @15542@
1 BIRT
2 DATE 1810
2 _FNA NO
1 DEAT
2 DATE 1890
2 _FNA NO
0 @15545@ INDI
1 NAME Pauline Marie Joséphine Charlotte/de WENDEL/
2 GIVN Pauline Marie Joséphine Charlotte
2 SURN de WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 11:37
1 FAMS @15597@
1 BIRT
2 DATE 1832
2 _FNA NO
1 DEAT
2 DATE 1915
2 _FNA NO
1 FAMC @15542@
0 @15556@ INDI
1 NAME Marguerite Marie Joséphine Octavie/de WENDEL/
2 GIVN Marguerite Marie Joséphine Octavie
2 SURN de WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 11:38
1 BIRT
2 DATE 1833
2 _FNA NO
1 DEAT
2 DATE 1857
2 _FNA NO
1 FAMC @15542@
0 @15567@ INDI
1 NAME Françoise Marie Charlotte/de WENDEL/
2 GIVN Françoise Marie Charlotte
2 SURN de WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 11:39
1 BIRT
2 DATE 1835
2 _FNA NO
1 DEAT
2 DATE 1847
2 _FNA NO
1 FAMC @15542@
0 @15578@ INDI
1 NAME Charles Vincent François/de WENDEL/
2 GIVN Charles Vincent François
2 SURN de WENDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 12:02
1 BIRT
2 DATE 1838
2 _FNA NO
1 DEAT
2 DATE 1857
2 _FNA NO
1 FAMC @15542@
0 @15590@ INDI
1 NAME Albert/de CUREL/
2 GIVN Albert
2 SURN de CUREL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 DEC 2019
3 TIME 17:42
1 FAMS @15597@
1 BIRT
2 DATE 1827
2 _FNA NO
1 DEAT
2 DATE 22 MAR 1908
2 _FNA NO
0 @15598@ INDI
1 NAME Théodore Charles Joseph/de GARGAN/
2 GIVN Théodore Charles Joseph
2 SURN de GARGAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 11:07
1 FAMS @15605@
1 BIRT
2 DATE 1791
2 _FNA NO
1 DEAT
2 DATE 1853
2 _FNA NO
0 @15606@ INDI
1 NAME Théodore François Marie/de GARGAN/
2 GIVN Théodore François Marie
2 SURN de GARGAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 11:08
1 FAMS @15673@
1 BIRT
2 DATE 1827
2 _FNA NO
1 DEAT
2 DATE 1889
2 _FNA NO
1 FAMC @15605@
0 @15617@ INDI
1 NAME Marie-Joséphine/de GARGAN/
2 GIVN Marie-Joséphine
2 SURN de GARGAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 17:31
1 FAMS @15678@
1 BIRT
2 DATE 1828
2 _FNA NO
1 DEAT
2 DATE 1892
2 _FNA NO
1 FAMC @15605@
0 @15628@ INDI
1 NAME François-Auguste/de GARGAN/
2 GIVN François-Auguste
2 SURN de GARGAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 17:26
1 BIRT
2 DATE 1829
2 PLAC Hayange,57700,Moselle,Grand Est,FRANCE,
3 MAP
4 LATI N49.334280
4 LONG E6.052680
2 _FNA NO
1 DEAT
2 DATE 26 NOV 1853
2 PLAC Hayange,57700,Moselle,Grand Est,FRANCE,
3 MAP
4 LATI N49.334280
4 LONG E6.052680
2 _FNA NO
2 CAUS CHUTE DE CHEVAL
1 FAMC @15605@
0 @15643@ INDI
1 NAME Marie-Paul/de GARGAN/
2 GIVN Marie-Paul
2 SURN de GARGAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 18:19
1 BIRT
2 DATE 1832
2 _FNA NO
1 DEAT
2 DATE 1885
2 _FNA NO
1 FAMC @15605@
0 @15654@ INDI
1 NAME Jeanne-Marie/de GARGAN/
2 GIVN Jeanne-Marie
2 SURN de GARGAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 18:20
1 BIRT
2 DATE 1838
2 _FNA NO
1 DEAT
2 DATE 1839
2 _FNA NO
1 FAMC @15605@
0 @15666@ INDI
1 NAME Hortense-Alice/ESPIVENT de la VILLESBOISNET/
2 GIVN Hortense-Alice
2 SURN ESPIVENT de la VILLESBOISNET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 11:08
1 FAMS @15673@
1 BIRT
2 DATE 1838
2 _FNA NO
1 DEAT
2 DATE 1903
2 _FNA NO
0 @15675@ INDI
1 NAME Édouard/de L'ESPEE/
2 GIVN Édouard
2 SURN de L'ESPEE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 17:31
1 FAMS @15678@
1 BIRT
2 DATE 1820
2 _FNA NO
1 DEAT
2 DATE 1855
2 _FNA NO
0 @15686@ INDI
1 NAME François-Ignace/de WENDEL de HAYANGE/
2 GIVN François-Ignace
2 SURN de WENDEL de HAYANGE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 APR 2018
3 TIME 6:06
1 FAMS @15704@
1 BIRT
2 DATE 1741
2 _FNA NO
1 DEAT
2 DATE 1795
2 _FNA NO
1 FAMC @15752@
0 @15697@ INDI
1 NAME Cécile Françoise/DU TERTRE/
2 GIVN Cécile Françoise
2 SURN DU TERTRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 APR 2018
3 TIME 6:06
1 FAMS @15704@
1 BIRT
2 DATE 1752
2 _FNA NO
1 DEAT
2 DATE 1783
2 _FNA NO
0 @15707@ INDI
1 NAME Charles-Antoine/de WENDEL/
2 GIVN Charles-Antoine
2 SURN de WENDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 18:38
1 BIRT
2 DATE 1774
2 _FNA NO
1 DEAT
2 DATE 1832
2 _FNA NO
1 FAMC @15704@
0 @15718@ INDI
1 NAME Antoine-Louis/de WENDEL/
2 GIVN Antoine-Louis
2 SURN de WENDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 18:38
1 FAMS @15733@
1 BIRT
2 DATE 1776
2 _FNA NO
1 DEAT
2 DATE 1822
2 _FNA NO
1 FAMC @15704@
0 @15731@ INDI
1 NAME Clémentine/de CATHEY/
2 GIVN Clémentine
2 SURN de CATHEY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 18:38
1 FAMS @15733@
0 @15734@ INDI
1 NAME Charles/WENDEL de HAYANGE/
2 GIVN Charles
2 SURN WENDEL de HAYANGE
1 SEX M
1 TITL seigneur de hayange et de veymerange
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 18:55
1 FAMS @15752@
1 BIRT
2 DATE 1708
2 _FNA NO
1 DEAT
2 DATE 1784
2 _FNA NO
1 FAMC @15837@
0 @15745@ INDI
1 NAME Marguerite/d'HAUSEN/
2 GIVN Marguerite
2 SURN d'HAUSEN
2 NICK MADAME DE HAYANGE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 18:53
1 FAMS @15752@
1 BIRT
2 DATE 1720
2 _FNA NO
1 DEAT
2 DATE 1802
2 _FNA NO
0 @15757@ INDI
1 NAME Jean-Martin/WENDEL/
2 GIVN Jean-Martin
2 SURN WENDEL
1 SEX M
1 OCCU Forgeron
1 TITL seigneur de hayange et de veymerange
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 APR 2018
3 TIME 11:59
1 FAMS @15837@
1 BIRT
2 DATE 1665
2 _FNA NO
1 DEAT
2 DATE 1737
2 _FNA NO
1 NOTE Fondateur des forges 1704
1 FAMC @15859@
0 @15769@ INDI
1 NAME Marguerite/WENDEL de HAYANGE/
2 GIVN Marguerite
2 SURN WENDEL de HAYANGE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 18:49
1 BIRT
2 DATE 1740
2 _FNA NO
1 DEAT
2 DATE 1740
2 _FNA NO
1 FAMC @15752@
0 @15779@ INDI
1 NAME Louise/WENDEL de HAYANGE/
2 GIVN Louise
2 SURN WENDEL de HAYANGE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 JUN 2019
3 TIME 13:18
1 FAMS @16131@
1 BIRT
2 DATE 1742
2 _FNA NO
1 DEAT
2 DATE 1800
2 _FNA NO
1 FAMC @15752@
0 @15791@ INDI
1 NAME Reine/WENDEL de HAYANGE/
2 GIVN Reine
2 SURN WENDEL de HAYANGE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 APR 2018
3 TIME 8:22
1 FAMS @16135@
1 BIRT
2 DATE 1743
2 _FNA NO
1 DEAT
2 DATE 1825
2 _FNA NO
1 FAMC @15752@
0 @15801@ INDI
1 NAME François/WENDEL de HAYANGE/
2 GIVN François
2 SURN WENDEL de HAYANGE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 18:51
1 BIRT
2 DATE 1744
2 _FNA NO
1 DEAT
2 DATE 1745
2 _FNA NO
1 FAMC @15752@
0 @15811@ INDI
1 NAME Benoît/WENDEL de HAYANGE/
2 GIVN Benoît
2 SURN WENDEL de HAYANGE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 APR 2018
3 TIME 8:24
1 FAMS @16139@
1 BIRT
2 DATE 1745
2 _FNA NO
1 FAMC @15752@
0 @15818@ INDI
1 NAME Catherine/WENDEL de HAYANGE/
2 GIVN Catherine
2 SURN WENDEL de HAYANGE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 APR 2018
3 TIME 6:08
1 FAMS @16380@
1 BIRT
2 DATE 1746
2 _FNA NO
1 DEAT
2 DATE 1812
2 _FNA NO
1 FAMC @15752@
0 @15830@ INDI
1 NAME Anne-Marguerite/MEYER/
2 GIVN Anne-Marguerite
2 SURN MEYER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:50
1 FAMS @15837@
1 BIRT
2 DATE 1682
2 _FNA NO
1 DEAT
2 DATE 1740
2 _FNA NO
0 @15840@ INDI
1 NAME Christian/de WENDEL/
2 GIVN Christian
2 SURN de WENDEL
1 SEX M
1 TITL seigneur de longlaville
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:16
1 FAMS @15853@
1 FAMS @15859@
1 BIRT
2 DATE 1628
2 _FNA NO
1 DEAT
2 DATE 1708
2 _FNA NO
1 FAMC @15877@
0 @15851@ INDI
1 NAME Agnès/JACOBS/
2 GIVN Agnès
2 SURN JACOBS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 18:59
1 FAMS @15853@
0 @15855@ INDI
1 NAME Claire/SAURFELD/
2 GIVN Claire
2 SURN SAURFELD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:16
1 FAMS @15859@
1 DEAT
2 DATE 1712
2 _FNA NO
0 @15865@ INDI
1 NAME Jean-Georges/de WENDEL/
2 GIVN Jean-Georges
2 SURN de WENDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:06
1 FAMS @15877@
1 BIRT
2 DATE 1605
2 _FNA NO
1 DEAT
2 DATE 1695
2 _FNA NO
1 FAMC @15890@
0 @15876@ INDI
1 NAME /de HAMMERSTEIN/
2 GIVN
2 SURN de HAMMERSTEIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:04
1 FAMS @15877@
0 @15880@ INDI
1 NAME Jean/de WENDEL/
2 GIVN Jean
2 SURN de WENDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:06
1 FAMS @15890@
1 BIRT
2 PLAC Bruges,,,,,
3 MAP
4 LATI N44.879810
4 LONG W0.612190
2 _FNA NO
0 @15888@ INDI
1 NAME Marie/de WANDEREN/
2 GIVN Marie
2 SURN de WANDEREN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:06
1 FAMS @15890@
0 @15893@ INDI
1 NAME Marguerite/WENDEL/
2 GIVN Marguerite
2 SURN WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:11
1 BIRT
2 DATE 1661
2 _FNA NO
1 DEAT
2 DATE 1712
2 _FNA NO
1 FAMC @15859@
0 @15903@ INDI
1 NAME François/WENDEL/
2 GIVN François
2 SURN WENDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:12
1 BIRT
2 DATE 1667
2 _FNA NO
1 DEAT
2 DATE 1742
2 _FNA NO
1 FAMC @15859@
0 @15914@ INDI
1 NAME Jean-Baptiste/WENDEL de MENSKIRCH/
2 GIVN Jean-Baptiste
2 SURN WENDEL de MENSKIRCH
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:20
1 FAMS @15971@
1 BIRT
2 DATE 1669
2 _FNA NO
1 DEAT
2 DATE 1734
2 _FNA NO
1 FAMC @15859@
0 @15925@ INDI
1 NAME Isaïe/de WENDEL/
2 GIVN Isaïe
2 SURN de WENDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:14
1 BIRT
2 DATE 1672
2 _FNA NO
1 FAMC @15859@
0 @15932@ INDI
1 NAME Suzanne/WENDEL/
2 GIVN Suzanne
2 SURN WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:19
1 FAMS @15967@
1 FAMC @15859@
0 @15938@ INDI
1 NAME Marie-Catherine/WENDEL/
2 GIVN Marie-Catherine
2 SURN WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:19
1 FAMS @15963@
1 BIRT
2 DATE 1682
2 _FNA NO
1 FAMC @15859@
0 @15945@ INDI
1 NAME Madeleine/WENDEL/
2 GIVN Madeleine
2 SURN WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:17
1 FAMS @15952@
1 FAMC @15859@
0 @15951@ INDI
1 NAME /COLMANN/
2 GIVN
2 SURN COLMANN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:17
1 FAMS @15952@
0 @15956@ INDI
1 NAME Arnould/NEYS/
2 GIVN Arnould
2 SURN NEYS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:19
1 FAMS @15963@
1 BIRT
2 DATE 1679
2 _FNA NO
1 DEAT
2 DATE 1710
2 _FNA NO
0 @15965@ INDI
1 NAME François/WARNIER/
2 GIVN François
2 SURN WARNIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:19
1 FAMS @15967@
0 @15969@ INDI
1 NAME Madeleine/HUMBERT/
2 GIVN Madeleine
2 SURN HUMBERT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:20
1 FAMS @15971@
0 @15973@ INDI
1 NAME Jean/WENDEL/
2 GIVN Jean
2 SURN WENDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:23
1 BIRT
2 DATE 1701
2 _FNA NO
1 DEAT
2 DATE 1702
2 _FNA NO
1 FAMC @15837@
0 @15984@ INDI
1 NAME Jean-François/WENDEL/
2 GIVN Jean-François
2 SURN WENDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:23
1 BIRT
2 DATE 1703
2 _FNA NO
1 DEAT
2 DATE 1718
2 _FNA NO
1 FAMC @15837@
0 @15995@ INDI
1 NAME Anne-Jeanne/WENDEL/
2 GIVN Anne-Jeanne
2 SURN WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 APR 2018
3 TIME 6:36
1 FAMS @16224@
1 BIRT
2 DATE 1704
2 _FNA NO
1 DEAT
2 DATE 1791
2 _FNA NO
1 FAMC @15837@
0 @16006@ INDI
1 NAME Reine-Françoise/WENDEL/
2 GIVN Reine-Françoise
2 SURN WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 APR 2018
3 TIME 6:36
1 FAMS @16399@
1 BIRT
2 DATE 1706
2 _FNA NO
1 DEAT
2 DATE 1787
2 _FNA NO
1 FAMC @15837@
0 @16016@ INDI
1 NAME Marie-Catherine/WENDEL/
2 GIVN Marie-Catherine
2 SURN WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:26
1 BIRT
2 DATE 1709
2 _FNA NO
1 FAMC @15837@
0 @16023@ INDI
1 NAME Marie/WENDEL/
2 GIVN Marie
2 SURN WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:27
1 BIRT
2 DATE 1711
2 _FNA NO
1 DEAT
2 DATE 1729
2 _FNA NO
1 FAMC @15837@
0 @16033@ INDI
1 NAME Anne-Marguerite/WENDEL/
2 GIVN Anne-Marguerite
2 SURN WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:27
1 BIRT
2 DATE 1712
2 _FNA NO
1 FAMC @15837@
0 @16041@ INDI
1 NAME Jean-Antoine/WENDEL/
2 GIVN Jean-Antoine
2 SURN WENDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:28
1 BIRT
2 DATE 1713
2 _FNA NO
1 DEAT
2 DATE 1729
2 _FNA NO
1 FAMC @15837@
0 @16051@ INDI
1 NAME Nicolas/WENDEL/
2 GIVN Nicolas
2 SURN WENDEL
1 SEX M
1 OCCU Abbé DE WERNEVILLER
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:29
1 BIRT
2 DATE 1715
2 _FNA NO
1 FAMC @15837@
0 @16061@ INDI
1 NAME Marie-Agnes/WENDEL/
2 GIVN Marie-Agnes
2 SURN WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 APR 2018
3 TIME 6:09
1 FAMS @16385@
1 BIRT
2 DATE 1716
2 _FNA NO
1 DEAT
2 DATE 1788
2 _FNA NO
1 FAMC @15837@
0 @16071@ INDI
1 NAME Martin Henri François/WENDEL/
2 GIVN Martin Henri François
2 SURN WENDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:31
1 BIRT
2 DATE 1717
2 _FNA NO
1 DEAT
2 DATE 1739
2 _FNA NO
1 FAMC @15837@
0 @16091@ INDI
1 NAME Jeanne/WENDEL/
2 GIVN Jeanne
2 SURN WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:32
1 BIRT
2 DATE 1719
2 _FNA NO
1 DEAT
2 DATE 1739
2 _FNA NO
1 FAMC @15837@
0 @16102@ INDI
1 NAME Jean-François Christophe/WENDEL/
2 GIVN Jean-François Christophe
2 SURN WENDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:50
1 BIRT
2 DATE 1720
2 _FNA NO
1 FAMC @15837@
0 @16113@ INDI
1 NAME Jean-François/WENDEL de LONGLAVILLE/
2 GIVN Jean-François
2 SURN WENDEL de LONGLAVILLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 APR 2018
3 TIME 19:50
1 BIRT
2 DATE 1721
2 _FNA NO
1 DEAT
2 DATE 1806
2 _FNA NO
1 FAMC @15837@
0 @16124@ INDI
1 NAME Alexandre/de BALTHASAR de GACHEO/
2 GIVN Alexandre
2 SURN de BALTHASAR de GACHEO
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 JUN 2019
3 TIME 13:18
1 FAMS @16131@
1 BIRT
2 DATE 1736
2 _FNA NO
1 DEAT
2 DATE 1808
2 _FNA NO
0 @16133@ INDI
1 NAME /de LA GACE d'ELSEAU/
2 GIVN
2 SURN de LA GACE d'ELSEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 APR 2018
3 TIME 8:22
1 FAMS @16135@
1 BIRT
2 DATE 1736
2 _FNA NO
0 @16137@ INDI
1 NAME Marie-Anne/MARIAGE/
2 GIVN Marie-Anne
2 SURN MARIAGE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 APR 2018
3 TIME 8:24
1 FAMS @16139@
0 @16140@ INDI
1 NAME Louis/WENDEL de HAYANGE/
2 GIVN Louis
2 SURN WENDEL de HAYANGE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 APR 2018
3 TIME 8:26
1 FAMS @16153@
1 BIRT
2 DATE 1791
2 _FNA NO
1 DEAT
2 DATE 1833
2 _FNA NO
1 FAMC @16139@
0 @16151@ INDI
1 NAME Jeanne/VERDIÉ/
2 GIVN Jeanne
2 SURN VERDIÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 APR 2018
3 TIME 8:26
1 FAMS @16153@
0 @16155@ INDI
1 NAME Louis-Eugène/WENDEL de HAYANGE/
2 GIVN Louis-Eugène
2 SURN WENDEL de HAYANGE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 APR 2018
3 TIME 8:26
1 BIRT
2 DATE 1827
2 _FNA NO
1 DEAT
2 DATE 1841
2 _FNA NO
1 FAMC @16153@
0 @16167@ INDI
1 NAME Pierre-Alexandre/de FISCHER de DICOURT/
2 GIVN Pierre-Alexandre
2 SURN de FISCHER de DICOURT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 APR 2018
3 TIME 8:34
1 FAMS @16188@
1 BIRT
2 DATE 1755
2 _FNA NO
0 @16177@ INDI
1 NAME Anne-Reine Eléonore/de LA CHÈZE/
2 GIVN Anne-Reine Eléonore
2 SURN de LA CHÈZE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 APR 2018
3 TIME 8:38
1 FAMS @16188@
1 BIRT
2 DATE 1754
2 _FNA NO
1 DEAT
2 DATE 1819
2 _FNA NO
1 FAMC @16206@
0 @16192@ INDI
1 NAME Jean-Baptiste/de LA CHÈZE/
2 GIVN Jean-Baptiste
2 SURN de LA CHÈZE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 APR 2018
3 TIME 6:02
1 FAMS @16206@
1 BIRT
2 DATE 1702
2 _FNA NO
1 DEAT
2 DATE 1757
2 _FNA NO
1 NOTE ou de LA CHAISE?
0 @16204@ INDI
1 NAME Anne-Marguerite/BRANDEBOURG de LEOVILLÉ/
2 GIVN Anne-Marguerite
2 SURN BRANDEBOURG de LEOVILLÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 APR 2018
3 TIME 6:01
1 FAMS @16206@
1 BIRT
2 DATE 1724
2 _FNA NO
1 DEAT
2 DATE 1812
2 _FNA NO
1 FAMC @16224@
0 @16212@ INDI
1 NAME Pierre-Thomas/BRANDEBOURG/
2 GIVN Pierre-Thomas
2 SURN BRANDEBOURG
1 SEX M
1 TITL seigneur de LEOVILLÉ
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 APR 2018
3 TIME 6:36
1 FAMS @16224@
1 BIRT
2 DATE 1696
2 _FNA NO
1 DEAT
2 DATE 1739
2 _FNA NO
0 @16229@ INDI
1 NAME Bernard/BARBIER/
2 GIVN Bernard
2 SURN BARBIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 8:53
1 FAMS @16231@
1 BIRT
2 DATE 1907
2 _FNA NO
0 @16232@ INDI
1 NAME Anne/BARBIER/
2 GIVN Anne
2 SURN BARBIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 16:53
1 FAMS @16241@
1 BIRT
2 DATE 1956
2 _FNA NO
1 FAMC @16231@
0 @16240@ INDI
1 NAME Pierre/BOSCAL de REALS de MORNAC/
2 GIVN Pierre
2 SURN BOSCAL de REALS de MORNAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 16:53
1 FAMS @16241@
0 @16242@ INDI
1 NAME Édouard/BOSCAL de REALS de MORNAC/
2 GIVN Édouard
2 SURN BOSCAL de REALS de MORNAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 16:52
1 BIRT
2 DATE 1981
2 _FNA NO
1 FAMC @16241@
0 @16248@ INDI
1 NAME Guillaume/BOSCAL de REALS de MORNAC/
2 GIVN Guillaume
2 SURN BOSCAL de REALS de MORNAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 16:52
1 BIRT
2 DATE 1982
2 _FNA NO
1 FAMC @16241@
0 @16254@ INDI
1 NAME Charles/BOSCAL de REALS de MORNAC/
2 GIVN Charles
2 SURN BOSCAL de REALS de MORNAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 16:52
1 BIRT
2 DATE 1985
2 _FNA NO
1 FAMC @16241@
0 @16261@ INDI
1 NAME Jeanne-Marie/de MAILLÉ de la TOUR LANDRY/
2 GIVN Jeanne-Marie
2 SURN de MAILLÉ de la TOUR LANDRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 17:30
1 FAMS @16263@
1 BIRT
2 DATE 1903
2 _FNA NO
1 DEAT
2 DATE 7 NOV 1926
2 _FNA NO
1 FAMC @17585@
0 @16264@ INDI
1 NAME Valentine/de MONTALEMBERT/
2 GIVN Valentine
2 SURN de MONTALEMBERT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:55
1 FAMS @16272@
1 BIRT
2 DATE 1926
2 _FNA NO
1 FAMC @16263@
0 @16270@ INDI
1 NAME Guy/de PENNART/
2 GIVN Guy
2 SURN de PENNART
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:55
1 FAMS @16272@
1 BIRT
2 DATE 1923
2 _FNA NO
0 @16327@ INDI
1 NAME Aglaé Marguerite/BLEUET du MARAIS/
2 GIVN Aglaé Marguerite
2 SURN BLEUET du MARAIS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 APR 2018
3 TIME 16:56
0 @16345@ INDI
1 NAME Reine Jeanne Françoise/BRANDEBOURG/
2 GIVN Reine Jeanne Françoise
2 SURN BRANDEBOURG
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 APR 2018
3 TIME 6:03
1 FAMS @16373@
1 BIRT
2 DATE 1727
2 _FNA NO
1 DEAT
2 DATE 1793
2 _FNA NO
1 FAMC @16224@
0 @16370@ INDI
1 NAME /de BAUDINET de COURCELLES/
2 GIVN
2 SURN de BAUDINET de COURCELLES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 APR 2018
3 TIME 6:03
1 FAMS @16373@
1 BIRT
2 DATE 1727
2 _FNA NO
1 DEAT
2 DATE 1794
2 _FNA NO
0 @16377@ INDI
1 NAME /de LA COTTIERE/
2 GIVN
2 SURN de LA COTTIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 APR 2018
3 TIME 6:08
1 FAMS @16380@
1 BIRT
2 DATE 1741
2 _FNA NO
1 DEAT
2 DATE 1816
2 _FNA NO
0 @16382@ INDI
1 NAME /DES ROBERT/
2 GIVN
2 SURN DES ROBERT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 APR 2018
3 TIME 6:09
1 FAMS @16385@
1 BIRT
2 DATE 1705
2 _FNA NO
1 DEAT
2 DATE 1777
2 _FNA NO
0 @16393@ INDI
1 NAME Gabriel François/PALTEAU/
2 GIVN Gabriel François
2 SURN PALTEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 APR 2018
3 TIME 6:36
1 FAMS @16399@
1 DEAT
2 DATE 1760
2 _FNA NO
0 @16434@ INDI
1 NAME Michel/ACCURSI/
2 GIVN Michel
2 SURN ACCURSI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 DEC 2018
3 TIME 22:11
1 FAMS @25196@
1 FAMC @7007@
0 @16453@ INDI
1 NAME Jean-Louis/THURET/
2 GIVN Jean-Louis
2 SURN THURET
1 SEX M
1 OCCU Notaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 11:46
1 FAMS @16463@
1 BIRT
2 DATE 15 APR 1826
3 TIME 23:00
2 PLAC Crespières,78121,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883170
4 LONG E1.921510
2 _FNA NO
1 DEAT
2 DATE 1 JAN 1894
3 TIME 22:30
2 PLAC Paris 17,,,,,26 RUE BRUNEL
2 _FNA NO
2 NOTE PARIS 1894 , Décès , 17 V4E 10129
1 NOTE Notaire à Paris/Notaire à Nevers
1 FAMC @23275@
0 @16461@ INDI
1 NAME Émilie "Emilienne"/BITARD/
2 GIVN Émilie "Emilienne"
2 SURN BITARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 11:46
1 FAMS @16463@
1 BIRT
2 DATE 1 AUG 1836
2 PLAC La Charité-sur-Loire,58400,Nièvre,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.183330
4 LONG E3.016670
2 _FNA NO
1 DEAT
2 DATE 9 DEC 1908
3 TIME 18:00
2 PLAC Paris 08,,,,,
2 _FNA NO
1 FAMC @23241@
0 @16493@ INDI
1 NAME Amélie/LAPORTE/
2 GIVN Amélie
2 SURN LAPORTE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 MAY 2018
3 TIME 19:49
1 FAMS @16500@
1 BIRT
2 DATE 1852
2 _FNA NO
1 DEAT
2 DATE 1938
2 _FNA NO
0 @16521@ INDI
1 NAME Romeo Michel Virgile/ACCURSI/
2 GIVN Romeo Michel Virgile
2 SURN ACCURSI
1 SEX M
1 OCCU CHEF D'ORCHESTRE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 DEC 2018
3 TIME 22:13
1 FAMS @16548@
1 BIRT
2 DATE 1836
2 _FNA NO
1 DEAT
2 DATE 1916
2 _FNA NO
1 FAMC @25092@
0 @16537@ INDI
1 NAME Louise Hélène/HURAND/
2 GIVN Louise Hélène
2 SURN HURAND
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 DEC 2018
3 TIME 20:44
1 FAMS @16548@
1 BIRT
2 DATE 1841
2 _FNA NO
1 DEAT
2 DATE 1893
2 _FNA NO
1 FAMC @25106@
0 @16568@ INDI
1 NAME Didier Albert/SEGURET/
2 GIVN Didier Albert
2 SURN SEGURET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 8:45
1 BIRT
2 DATE 4 AUG 1939
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 1940
2 _FNA NO
1 FAMC @7111@
0 @16594@ INDI
1 NAME Michel François Louis Antonin/SABATIER/
2 GIVN Michel François Louis Antonin
2 SURN SABATIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 8:48
1 FAMS @16598@
1 BIRT
2 DATE 1948
2 _FNA NO
1 FAMC @16644@
0 @16600@ INDI
1 NAME Ève/SABATIER/
2 GIVN Ève
2 SURN SABATIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 17:44
1 FAMS @16870@
1 BIRT
2 DATE 4 JUN 1971
2 _FNA NO
1 FAMC @16598@
0 @16609@ INDI
1 NAME Ivan/SABATIER/
2 GIVN Ivan
2 SURN SABATIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 8:22
1 BIRT
2 DATE 22 OCT 1975
2 PLAC Wittlich,,,,,
3 MAP
4 LATI N49.983330
4 LONG E6.883330
2 _FNA NO
1 FAMC @16598@
0 @16623@ INDI
1 NAME Gilbert/SABATIER/
2 GIVN Gilbert
2 SURN SABATIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 8:28
1 FAMS @16644@
1 BIRT
2 DATE 1925
2 _FNA NO
1 DEAT
2 DATE 2017
2 _FNA NO
0 @16637@ INDI
1 NAME Hélène/HEROS/
2 GIVN Hélène
2 SURN HEROS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 8:28
1 FAMS @16644@
1 BIRT
2 DATE 1926
2 _FNA NO
1 DEAT
2 DATE 1974
2 _FNA NO
0 @16658@ INDI
1 NAME Marie-Andrée/PETITPAS/
2 GIVN Marie-Andrée
2 SURN PETITPAS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 8:33
1 FAMS @16660@
0 @16661@ INDI
1 NAME Benoît/SEGURET/
2 GIVN Benoît
2 SURN SEGURET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 8:33
1 BIRT
2 DATE 1970
2 _FNA NO
1 FAMC @16660@
0 @16669@ INDI
1 NAME Isabelle/SEGURET/
2 GIVN Isabelle
2 SURN SEGURET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 8:33
1 BIRT
2 DATE 1973
2 _FNA NO
1 FAMC @16660@
0 @16680@ INDI
1 NAME Françoise/MATHIEU/
2 GIVN Françoise
2 SURN MATHIEU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 8:47
1 FAMS @16684@
1 BIRT
2 DATE 1948
2 _FNA NO
0 @16685@ INDI
1 NAME Diane/SEGURET/
2 GIVN Diane
2 SURN SEGURET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 DEC 2018
3 TIME 22:03
1 FAMS @25169@
1 BIRT
2 DATE 28 JUN 1982
2 _FNA NO
1 FAMC @16684@
0 @16693@ INDI
1 NAME Bérénice Gisèle Thibaud/SEGURET/
2 GIVN Bérénice Gisèle Thibaud
2 SURN SEGURET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 8:39
1 BIRT
2 DATE 18 JUN 1985
2 _FNA NO
1 FAMC @16684@
0 @16706@ INDI
1 NAME Jean "Henri"/POUTIGNAT/
2 GIVN Jean "Henri"
2 SURN POUTIGNAT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 8:55
1 FAMS @16737@
1 BIRT
2 DATE 1875
2 _FNA NO
1 DEAT
2 DATE 1955
2 _FNA NO
0 @16722@ INDI
1 NAME Marguerite Marie Lucie/FILON/
2 GIVN Marguerite Marie Lucie
2 SURN FILON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 8:55
1 FAMS @16737@
1 BIRT
2 DATE 1883
2 _FNA NO
1 DEAT
2 DATE 1963
2 _FNA NO
0 @16772@ INDI
1 NAME Micheline/POUTIGNAT/
2 GIVN Micheline
2 SURN POUTIGNAT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 9:05
1 FAMS @16851@
1 BIRT
2 DATE 1913
2 _FNA NO
1 FAMC @16737@
0 @16791@ INDI
1 NAME Alain/POUTIGNAT/
2 GIVN Alain
2 SURN POUTIGNAT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 9:02
1 FAMS @16830@
1 BIRT
2 DATE 1920
2 _FNA NO
1 FAMC @16737@
0 @16803@ INDI
1 NAME Véronique/POUTIGNAT/
2 GIVN Véronique
2 SURN POUTIGNAT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 8:58
1 BIRT
2 DATE 1958
2 _FNA NO
1 FAMC @8429@
0 @16810@ INDI
1 NAME Nathalie/POUTIGNAT/
2 GIVN Nathalie
2 SURN POUTIGNAT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 8:58
1 BIRT
2 DATE 1959
2 _FNA NO
1 FAMC @8429@
0 @16817@ INDI
1 NAME Cyrille/POUTIGNAT/
2 GIVN Cyrille
2 SURN POUTIGNAT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 9:01
1 BIRT
2 DATE 1964
2 _FNA NO
1 NOTE TRISOMIE 21
1 FAMC @8429@
0 @16826@ INDI
1 NAME Cécile/RAPIN/
2 GIVN Cécile
2 SURN RAPIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 9:02
1 FAMS @16830@
1 BIRT
2 DATE 1915
2 _FNA NO
0 @16831@ INDI
1 NAME Pascal/POUTIGNAT/
2 GIVN Pascal
2 SURN POUTIGNAT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 9:02
1 BIRT
2 DATE 1950
2 _FNA NO
1 FAMC @16830@
0 @16839@ INDI
1 NAME Fabien/POUTIGNAT/
2 GIVN Fabien
2 SURN POUTIGNAT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 9:02
1 BIRT
2 DATE 1954
2 _FNA NO
1 FAMC @16830@
0 @16847@ INDI
1 NAME Christian/COURMES/
2 GIVN Christian
2 SURN COURMES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 9:05
1 FAMS @16851@
1 BIRT
2 DATE 1913
2 _FNA NO
0 @16852@ INDI
1 NAME Martin/COURMES/
2 GIVN Martin
2 SURN COURMES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 9:04
1 FAMC @16851@
0 @16857@ INDI
1 NAME Nils/COURMES/
2 GIVN Nils
2 SURN COURMES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 9:05
1 FAMC @16851@
0 @16864@ INDI
1 NAME Sébastien Henri/COLLIAT/
2 GIVN Sébastien Henri
2 SURN COLLIAT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 17:44
1 FAMS @16870@
1 BIRT
2 DATE 1969
2 _FNA NO
0 @16872@ INDI
1 NAME Quentin Henri Michel/COLLIAT/
2 GIVN Quentin Henri Michel
2 SURN COLLIAT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 17:42
1 BIRT
2 DATE 1 JUL 1999
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @16870@
0 @16887@ INDI
1 NAME Pauline Domitille Maguelonne/COLLIAT/
2 GIVN Pauline Domitille Maguelonne
2 SURN COLLIAT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 17:44
1 BIRT
2 DATE 22 FEB 2003
2 _FNA NO
1 FAMC @16870@
0 @16927@ INDI
1 NAME Emile Emmanuel/HERBILLON/
2 GIVN Emile Emmanuel
2 SURN HERBILLON
1 SEX M
1 OCCU Militaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 AUG 2019
3 TIME 7:18
1 FAMS @16945@
1 BIRT
2 DATE 13 OCT 1867
2 PLAC Versailles,78000,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.800000
4 LONG E2.133330
2 _FNA NO
1 DEAT
2 DATE 1956
2 _FNA NO
1 FAMC @30895@
0 @16943@ INDI
1 NAME Valentine/CHAZOTTE/
2 GIVN Valentine
2 SURN CHAZOTTE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 AUG 2019
3 TIME 7:18
1 FAMS @16945@
1 BIRT
2 DATE 1869
2 _FNA NO
1 FAMC @33297@
0 @16952@ INDI
1 NAME Jean/LE GRAND de MERCEY/
2 GIVN Jean
2 SURN LE GRAND de MERCEY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 18:28
1 FAMS @16956@
1 BIRT
2 DATE 1921
2 _FNA NO
1 DEAT
2 DATE 2011
2 _FNA NO
1 FAMC @17028@
0 @16957@ INDI
1 NAME Dominique/LE GRAND de MERCEY/
2 GIVN Dominique
2 SURN LE GRAND de MERCEY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:12
1 FAMS @16991@
1 FAMS @17284@
1 BIRT
2 DATE 1946
2 _FNA NO
1 FAMC @16956@
0 @16964@ INDI
1 NAME Olivier/LE GRAND de MERCEY/
2 GIVN Olivier
2 SURN LE GRAND de MERCEY
1 SEX M
1 OCCU Architecte
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:18
1 FAMS @16988@
1 BIRT
2 DATE 1949
2 _FNA NO
1 FAMC @16956@
0 @16971@ INDI
1 NAME Alix/LE GRAND de MERCEY/
2 GIVN Alix
2 SURN LE GRAND de MERCEY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 18:45
1 FAMS @16999@
1 BIRT
2 DATE 1950
2 _FNA NO
1 FAMC @16956@
0 @16978@ INDI
1 NAME Henri/LE GRAND de MERCEY/
2 GIVN Henri
2 SURN LE GRAND de MERCEY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:20
1 FAMS @17003@
1 BIRT
2 DATE 1956
2 _FNA NO
1 FAMC @16956@
0 @16986@ INDI
1 NAME Marie/THILLAYE du BOULLAY/
2 GIVN Marie
2 SURN THILLAYE du BOULLAY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:18
1 FAMS @16988@
1 BIRT
2 DATE 1955
2 _FNA NO
0 @16989@ INDI
1 NAME Caroline/de BAILLENCOURT/
2 GIVN Caroline
2 SURN de BAILLENCOURT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:13
1 FAMS @16991@
1 BIRT
2 DATE 1955
2 _FNA NO
0 @16997@ INDI
1 NAME Gonzague/MARTIN de BEAUCE/
2 GIVN Gonzague
2 SURN MARTIN de BEAUCE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 18:45
1 FAMS @16999@
1 BIRT
2 DATE 1944
2 _FNA NO
1 FAMC @17153@
0 @17001@ INDI
1 NAME Dorothée/WALLUT/
2 GIVN Dorothée
2 SURN WALLUT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:20
1 FAMS @17003@
1 BIRT
2 DATE 1960
2 _FNA NO
0 @17008@ INDI
1 NAME Robert Jean Charles Emile Etienne/LE GRAND de MERCEY/
2 GIVN Robert Jean Charles Emile Etienne
2 SURN LE GRAND de MERCEY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 18:28
1 FAMS @17028@
1 BIRT
2 DATE 1895
2 _FNA NO
1 DEAT
2 DATE 1983
2 _FNA NO
0 @17023@ INDI
1 NAME Josette Alix Marie Thérèse/FAVIER/
2 GIVN Josette Alix Marie Thérèse
2 SURN FAVIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 18:29
1 FAMS @17028@
1 BIRT
2 DATE 1895
2 _FNA NO
1 DEAT
2 DATE 1965
2 _FNA NO
0 @17087@ INDI
1 NAME Arlette/LE GRAND de MERCEY/
2 GIVN Arlette
2 SURN LE GRAND de MERCEY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 18:35
1 BIRT
2 DATE 1988
2 _FNA NO
1 FAMC @17003@
0 @17094@ INDI
1 NAME Marguerite/LE GRAND de MERCEY/
2 GIVN Marguerite
2 SURN LE GRAND de MERCEY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 18:36
1 BIRT
2 DATE 1990
2 _FNA NO
1 FAMC @17003@
0 @17101@ INDI
1 NAME Antoine/LE GRAND de MERCEY/
2 GIVN Antoine
2 SURN LE GRAND de MERCEY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 18:36
1 BIRT
2 DATE 1991
2 _FNA NO
1 FAMC @17003@
0 @17108@ INDI
1 NAME Valentine/LE GRAND de MERCEY/
2 GIVN Valentine
2 SURN LE GRAND de MERCEY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 18:36
1 BIRT
2 DATE 1994
2 _FNA NO
1 FAMC @17003@
0 @17116@ INDI
1 NAME Constantin/LE GRAND de MERCEY/
2 GIVN Constantin
2 SURN LE GRAND de MERCEY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 18:39
1 BIRT
2 DATE 2001
2 _FNA NO
1 FAMC @17003@
0 @17124@ INDI
1 NAME Goustantin/LE GRAND de MERCEY/
2 GIVN Goustantin
2 SURN LE GRAND de MERCEY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 18:39
1 BIRT
2 DATE 2001
2 _FNA NO
1 FAMC @17003@
0 @17140@ INDI
1 NAME Bertrand/MARTIN de BEAUCE/
2 GIVN Bertrand
2 SURN MARTIN de BEAUCE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 18:42
1 FAMS @17153@
1 BIRT
2 DATE 1904
2 _FNA NO
1 DEAT
2 DATE 1991
2 _FNA NO
0 @17151@ INDI
1 NAME /LE CLERC de la VERPILLIATRE/
2 GIVN
2 SURN LE CLERC de la VERPILLIATRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 18:42
1 FAMS @17153@
1 BIRT
2 DATE 1910
2 _FNA NO
0 @17155@ INDI
1 NAME Augustin/MARTIN de BEAUCE/
2 GIVN Augustin
2 SURN MARTIN de BEAUCE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 18:42
1 BIRT
2 DATE 1973
2 _FNA NO
1 FAMC @16999@
0 @17162@ INDI
1 NAME Nicolas/MARTIN de BEAUCE/
2 GIVN Nicolas
2 SURN MARTIN de BEAUCE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 18:54
1 FAMS @17190@
1 BIRT
2 DATE 28 MAY 1975
2 _FNA NO
1 FAMC @16999@
0 @17169@ INDI
1 NAME Philippe/MARTIN de BEAUCE/
2 GIVN Philippe
2 SURN MARTIN de BEAUCE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:00
1 FAMS @17234@
1 BIRT
2 DATE 1977
2 _FNA NO
1 FAMC @16999@
0 @17176@ INDI
1 NAME Sophie/MARTIN de BEAUCE/
2 GIVN Sophie
2 SURN MARTIN de BEAUCE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:03
1 FAMS @17257@
1 BIRT
2 DATE 1981
2 _FNA NO
1 FAMC @16999@
0 @17188@ INDI
1 NAME Céline/de MONTS de SAVASSE/
2 GIVN Céline
2 SURN de MONTS de SAVASSE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 18:55
1 FAMS @17190@
1 BIRT
2 DATE 7 MAR 1979
2 PLAC Toulouse,31000,Haute-Garonne,Occitanie,FRANCE,
3 MAP
4 LATI N43.604260
4 LONG E1.443670
2 _FNA NO
1 FAMC @17223@
0 @17192@ INDI
1 NAME Arthur/MARTIN de BEAUCE/
2 GIVN Arthur
2 SURN MARTIN de BEAUCE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 18:51
1 BIRT
2 DATE 8 MAR 2008
2 PLAC Toulouse,31000,Haute-Garonne,Occitanie,FRANCE,
3 MAP
4 LATI N43.604260
4 LONG E1.443670
2 _FNA NO
1 FAMC @17190@
0 @17199@ INDI
1 NAME Thimothée/MARTIN de BEAUCE/
2 GIVN Thimothée
2 SURN MARTIN de BEAUCE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 18:52
1 BIRT
2 DATE 17 JUL 2009
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @17190@
0 @17205@ INDI
1 NAME Diane/MARTIN de BEAUCE/
2 GIVN Diane
2 SURN MARTIN de BEAUCE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 18:52
1 BIRT
2 DATE 11 OCT 2013
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @17190@
0 @17212@ INDI
1 NAME Alain/de MONTS de SAVASSE/
2 GIVN Alain
2 SURN de MONTS de SAVASSE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 18:55
1 FAMS @17223@
0 @17218@ INDI
1 NAME Dominique Jeanne Marie Charlotte/LE JUGE de SEGRAIS/
2 GIVN Dominique Jeanne Marie Charlotte
2 SURN LE JUGE de SEGRAIS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 18:55
1 FAMS @17223@
0 @17230@ INDI
1 NAME Stéphanie/de FOURNIER LE RAY/
2 GIVN Stéphanie
2 SURN de FOURNIER LE RAY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:00
1 FAMS @17234@
1 BIRT
2 DATE 1980
2 _FNA NO
0 @17236@ INDI
1 NAME Amaury/MARTIN de BEAUCE/
2 GIVN Amaury
2 SURN MARTIN de BEAUCE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:00
1 BIRT
2 DATE 2008
2 _FNA NO
1 FAMC @17234@
0 @17244@ INDI
1 NAME Clotilde/MARTIN de BEAUCE/
2 GIVN Clotilde
2 SURN MARTIN de BEAUCE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:00
1 BIRT
2 DATE 2010
2 _FNA NO
1 FAMC @17234@
0 @17253@ INDI
1 NAME Damien/MAGNAUDET/
2 GIVN Damien
2 SURN MAGNAUDET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:03
1 FAMS @17257@
1 BIRT
2 DATE 1980
2 _FNA NO
0 @17259@ INDI
1 NAME Clément/MAGNAUDET/
2 GIVN Clément
2 SURN MAGNAUDET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:03
1 BIRT
2 DATE 2010
2 _FNA NO
1 FAMC @17257@
0 @17267@ INDI
1 NAME Oscar/MAGNAUDET/
2 GIVN Oscar
2 SURN MAGNAUDET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:03
1 BIRT
2 DATE 2012
2 _FNA NO
1 FAMC @17257@
0 @17280@ INDI
1 NAME Marie Alberte Henriette/de THOISY/
2 GIVN Marie Alberte Henriette
2 SURN de THOISY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:14
1 FAMS @17284@
1 BIRT
2 DATE 1950
2 _FNA NO
0 @17286@ INDI
1 NAME Pierre/LE GRAND de MERCEY/
2 GIVN Pierre
2 SURN LE GRAND de MERCEY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:11
1 BIRT
2 DATE 1971
2 _FNA NO
1 DEAT
2 DATE 1999
2 _FNA NO
1 FAMC @17284@
0 @17298@ INDI
1 NAME Raphaël/LE GRAND de MERCEY/
2 GIVN Raphaël
2 SURN LE GRAND de MERCEY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:11
1 BIRT
2 DATE 1973
2 _FNA NO
1 FAMC @17284@
0 @17307@ INDI
1 NAME Jean-Baptiste/LE GRAND de MERCEY/
2 GIVN Jean-Baptiste
2 SURN LE GRAND de MERCEY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:11
1 BIRT
2 DATE 1974
2 _FNA NO
1 FAMC @17284@
0 @17316@ INDI
1 NAME Colombe/LE GRAND de MERCEY/
2 GIVN Colombe
2 SURN LE GRAND de MERCEY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:12
1 BIRT
2 DATE 1977
2 _FNA NO
1 FAMC @17284@
0 @17339@ INDI
1 NAME Alexandre/LE GRAND de MERCEY/
2 GIVN Alexandre
2 SURN LE GRAND de MERCEY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:16
1 BIRT
2 DATE 1975
2 _FNA NO
1 FAMC @16988@
0 @17346@ INDI
1 NAME Grégoire/LE GRAND de MERCEY/
2 GIVN Grégoire
2 SURN LE GRAND de MERCEY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:16
1 BIRT
2 DATE 1976
2 _FNA NO
1 FAMC @16988@
0 @17353@ INDI
1 NAME Paul/LE GRAND de MERCEY/
2 GIVN Paul
2 SURN LE GRAND de MERCEY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:16
1 BIRT
2 DATE 1979
2 _FNA NO
1 FAMC @16988@
0 @17360@ INDI
1 NAME Robert/LE GRAND de MERCEY/
2 GIVN Robert
2 SURN LE GRAND de MERCEY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:17
1 BIRT
2 DATE 1985
2 _FNA NO
1 FAMC @16988@
0 @17367@ INDI
1 NAME Marc/LE GRAND de MERCEY/
2 GIVN Marc
2 SURN LE GRAND de MERCEY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:17
1 BIRT
2 DATE 1986
2 _FNA NO
1 FAMC @16988@
0 @17374@ INDI
1 NAME Jérôme/LE GRAND de MERCEY/
2 GIVN Jérôme
2 SURN LE GRAND de MERCEY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:17
1 BIRT
2 DATE 1988
2 _FNA NO
1 FAMC @16988@
0 @17381@ INDI
1 NAME Luc/LE GRAND de MERCEY/
2 GIVN Luc
2 SURN LE GRAND de MERCEY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:18
1 BIRT
2 DATE 1996
2 _FNA NO
1 FAMC @16988@
0 @17388@ INDI
1 NAME Violette/LE GRAND de MERCEY/
2 GIVN Violette
2 SURN LE GRAND de MERCEY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 MAY 2018
3 TIME 19:18
1 BIRT
2 DATE 1999
2 _FNA NO
1 FAMC @16988@
0 @17477@ INDI
1 NAME Anne-Marie/de GARGAN/
2 GIVN Anne-Marie
2 SURN de GARGAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 7:50
1 FAMS @17670@
1 BIRT
2 DATE 1866
2 PLAC Preisch,,,,,
2 _FNA NO
1 DEAT
2 DATE 1946
2 _FNA NO
1 FAMC @14966@
0 @17490@ INDI
1 NAME Pierre-Henri/de GARGAN/
2 GIVN Pierre-Henri
2 SURN de GARGAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 11:00
1 BIRT
2 DATE 1872
2 _FNA NO
1 DEAT
2 DATE 1873
2 _FNA NO
1 FAMC @14966@
0 @17546@ INDI
1 NAME Carmen/de WENDEL/
2 GIVN Carmen
2 SURN de WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2019
3 TIME 10:11
1 FAMS @17585@
1 BIRT
2 DATE 27 MAR 1870
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 24 OCT 1933
2 PLAC Châteauneuf-sur-Cher,18190,Cher,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N46.857780
4 LONG E2.317100
2 _FNA NO
1 FAMC @15432@
0 @17556@ INDI
1 NAME Sabine/de WENDEL/
2 GIVN Sabine
2 SURN de WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 15:47
1 FAMS @17594@
1 BIRT
2 DATE 1875
2 _FNA NO
1 DEAT
2 DATE 10 DEC 1941
2 PLAC Londres,,,,,
2 _FNA NO
1 FAMC @15432@
0 @17566@ INDI
1 NAME Guy René Pierre Alvaro/de WENDEL/
2 GIVN Guy René Pierre Alvaro
2 SURN de WENDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2019
3 TIME 10:13
1 FAMS @17603@
1 BIRT
2 DATE 1878
2 _FNA NO
1 DEAT
2 DATE 1955
2 _FNA NO
1 FAMC @15432@
0 @17578@ INDI
1 NAME Artus Marie Hippolyte Jean/de MAILLÉ de la TOUR LANDRY/
2 GIVN Artus Marie Hippolyte Jean
2 SURN de MAILLÉ de la TOUR LANDRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2019
3 TIME 10:11
1 FAMS @17585@
1 BIRT
2 DATE 1858
2 _FNA NO
1 DEAT
2 DATE 1926
2 _FNA NO
0 @17587@ INDI
1 NAME Louis/de LA PANOUSE/
2 GIVN Louis
2 SURN de LA PANOUSE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 12:14
1 FAMS @17594@
1 BIRT
2 DATE 1863
2 _FNA NO
1 DEAT
2 DATE 1945
2 _FNA NO
0 @17596@ INDI
1 NAME Catherine/ARGYROPOULO/
2 GIVN Catherine
2 SURN ARGYROPOULO
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2019
3 TIME 10:13
1 FAMS @17603@
1 BIRT
2 DATE 1880
2 _FNA NO
1 DEAT
2 DATE 1960
2 _FNA NO
0 @17605@ INDI
1 NAME Henriette/d'IRUMBERRY de SALABERRY/
2 GIVN Henriette
2 SURN d'IRUMBERRY de SALABERRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 17:51
1 FAMS @17612@
1 BIRT
2 DATE 1866
2 _FNA NO
1 DEAT
2 DATE 1944
2 _FNA NO
0 @17614@ INDI
1 NAME François/d'HANNONCELLES/
2 GIVN François
2 SURN d'HANNONCELLES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:02
1 FAMS @17621@
1 BIRT
2 DATE 1861
2 _FNA NO
1 DEAT
2 DATE 1940
2 _FNA NO
0 @17623@ INDI
1 NAME Louise/de LA BEGASSIERE/
2 GIVN Louise
2 SURN de LA BEGASSIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:12
1 FAMS @17630@
1 BIRT
2 DATE 1879
2 _FNA NO
1 DEAT
2 DATE 1963
2 _FNA NO
0 @17632@ INDI
1 NAME Jacques Fernand Louise Marie/de LA TOUR du PIN CHAMBLY de la CHARCE/
2 GIVN Jacques Fernand Louise Marie
2 SURN de LA TOUR du PIN CHAMBLY de la CHARCE
1 SEX M
1 TITL COMTE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 NOV 2019
3 TIME 7:53
1 FAMS @17639@
1 BIRT
2 DATE 9 DEC 1873
2 PLAC Saint-Germain-Lespinasse,42640,Loire,Auvergne-Rhône-Alpes,FRANCE,
3 MAP
4 LATI N46.101650
4 LONG E3.962040
2 _FNA NO
1 DEAT
2 DATE 5 OCT 1950
2 PLAC Saint-Germain-Lespinasse,42640,Loire,Auvergne-Rhône-Alpes,FRANCE,
3 MAP
4 LATI N46.101650
4 LONG E3.962040
2 _FNA NO
1 FAMC @36210@
0 @17641@ INDI
1 NAME Jeanne/de BRIEY/
2 GIVN Jeanne
2 SURN de BRIEY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:35
1 FAMS @17648@
1 BIRT
2 DATE 1886
2 _FNA NO
1 DEAT
2 DATE 1929
2 _FNA NO
0 @17649@ INDI
1 NAME Henry/de GARGAN/
2 GIVN Henry
2 SURN de GARGAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 12:27
1 BIRT
2 DATE 1874
2 _FNA NO
1 DEAT
2 DATE 1886
2 _FNA NO
1 FAMC @14966@
0 @17663@ INDI
1 NAME Norbert/LE GALLAIS/
2 GIVN Norbert
2 SURN LE GALLAIS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 7:50
1 FAMS @17670@
1 BIRT
2 DATE 1860
2 _FNA NO
1 DEAT
2 DATE 1934
2 _FNA NO
0 @17705@ INDI
1 NAME Claudia/DELGADO NATCHIGALL/
2 GIVN Claudia
2 SURN DELGADO NATCHIGALL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 12:48
1 FAMS @17707@
0 @17716@ INDI
1 NAME Didier/BELLENS/
2 GIVN Didier
2 SURN BELLENS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 13:46
1 FAMS @17720@
1 BIRT
2 DATE 1955
2 _FNA NO
0 @17724@ INDI
1 NAME Philippe/AYMER de la CHEVALERIE/
2 GIVN Philippe
2 SURN AYMER de la CHEVALERIE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 13:54
1 FAMS @17726@
0 @17727@ INDI
1 NAME Jean/AYMER de la CHEVALERIE/
2 GIVN Jean
2 SURN AYMER de la CHEVALERIE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 13:54
1 FAMC @17726@
0 @17732@ INDI
1 NAME Constance/AYMER de la CHEVALERIE/
2 GIVN Constance
2 SURN AYMER de la CHEVALERIE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 17:21
1 BIRT
2 DATE 11 DEC 2012
2 _FNA NO
1 FAMC @17726@
0 @17749@ INDI
1 NAME Hortense-Cordélia/de MOUSTIER/
2 GIVN Hortense-Cordélia
2 SURN de MOUSTIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:27
1 FAMS @17751@
1 BIRT
2 DATE 1968
2 _FNA NO
1 FAMC @19035@
0 @17754@ INDI
1 NAME Isabelle/MOLAS/
2 GIVN Isabelle
2 SURN MOLAS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 7 DEC 2019
3 TIME 17:52
1 FAMS @17758@
1 BIRT
2 DATE 1978
2 _FNA NO
0 @17784@ INDI
1 NAME Christine/de BOURBON BUSSET/
2 GIVN Christine
2 SURN de BOURBON BUSSET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 15:03
1 FAMS @17788@
1 BIRT
2 DATE 1973
2 _FNA NO
0 @17793@ INDI
1 NAME Albert/de L'ESPEE/
2 GIVN Albert
2 SURN de L'ESPEE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 17:32
1 FAMS @17831@
1 BIRT
2 DATE 1852
2 _FNA NO
1 DEAT
2 DATE 1918
2 _FNA NO
1 FAMC @15678@
0 @17803@ INDI
1 NAME Alice/de L'ESPEE/
2 GIVN Alice
2 SURN de L'ESPEE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 17:36
1 FAMS @17840@
1 BIRT
2 DATE 1853
2 _FNA NO
1 DEAT
2 DATE 1921
2 _FNA NO
1 FAMC @15678@
0 @17813@ INDI
1 NAME Édouard/de L'ESPEE/
2 GIVN Édouard
2 SURN de L'ESPEE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 15:40
1 FAMS @17849@
1 BIRT
2 DATE 1855
2 _FNA NO
1 DEAT
2 DATE 1910
2 _FNA NO
1 FAMC @15678@
0 @17824@ INDI
1 NAME Delphine/de BONGARS/
2 GIVN Delphine
2 SURN de BONGARS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 17:32
1 FAMS @17831@
1 BIRT
2 DATE 1863
2 _FNA NO
1 DEAT
2 DATE 1926
2 _FNA NO
0 @17833@ INDI
1 NAME Maurice/DU HALGOUËT/
2 GIVN Maurice
2 SURN DU HALGOUËT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 17:36
1 FAMS @17840@
1 BIRT
2 DATE 1847
2 _FNA NO
1 DEAT
2 DATE 1919
2 _FNA NO
0 @17842@ INDI
1 NAME Marguerite/de BEAUREPAIRE/
2 GIVN Marguerite
2 SURN de BEAUREPAIRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 15:41
1 FAMS @17849@
1 FAMS @37051@
1 BIRT
2 DATE 12 APR 1874
2 PLAC Saint-Germain-en-Laye,78100,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.900000
4 LONG E2.083330
2 _FNA NO
1 DEAT
2 DATE 8 MAY 1936
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @37028@
0 @17850@ INDI
1 NAME Paule/DU HALGOUËT/
2 GIVN Paule
2 SURN DU HALGOUËT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 17:37
1 FAMS @17868@
1 BIRT
2 DATE 1880
2 _FNA NO
1 DEAT
2 DATE 1918
2 _FNA NO
1 FAMC @17840@
0 @17861@ INDI
1 NAME Henri/LE BASTARD de VILLENEUVE/
2 GIVN Henri
2 SURN LE BASTARD de VILLENEUVE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 17:37
1 FAMS @17868@
1 BIRT
2 DATE 1876
2 _FNA NO
1 DEAT
2 DATE 1927
2 _FNA NO
0 @17871@ INDI
1 NAME Élisabeth/de GARGAN/
2 GIVN Élisabeth
2 SURN de GARGAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 17:54
1 FAMS @17925@
1 BIRT
2 DATE 1896
2 _FNA NO
1 DEAT
2 DATE 1958
2 _FNA NO
1 FAMC @17612@
0 @17881@ INDI
1 NAME Madeleine/de GARGAN/
2 GIVN Madeleine
2 SURN de GARGAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 17:56
1 FAMS @17935@
1 BIRT
2 DATE 1899
2 _FNA NO
1 DEAT
2 DATE 1987
2 _FNA NO
1 FAMC @17612@
0 @17891@ INDI
1 NAME Marie/de GARGAN/
2 GIVN Marie
2 SURN de GARGAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 17:57
1 FAMS @17950@
1 BIRT
2 DATE 1902
2 _FNA NO
1 DEAT
2 DATE 1989
2 _FNA NO
1 FAMC @17612@
0 @17918@ INDI
1 NAME Christian/d'ELBÉE/
2 GIVN Christian
2 SURN d'ELBÉE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 17:54
1 FAMS @17925@
1 BIRT
2 DATE 1886
2 _FNA NO
1 DEAT
2 DATE 1954
2 _FNA NO
0 @17928@ INDI
1 NAME Guy/de HAUTECLOQUE/
2 GIVN Guy
2 SURN de HAUTECLOQUE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 17:56
1 FAMS @17935@
1 BIRT
2 DATE 1892
2 _FNA NO
1 DEAT
2 DATE 1965
2 _FNA NO
0 @17939@ INDI
1 NAME René Clérel/de TOCQUEVILLE/
2 GIVN René Clérel
2 SURN de TOCQUEVILLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 17:57
1 FAMS @17950@
1 BIRT
2 DATE 1899
2 _FNA NO
1 DEAT
2 DATE 1989
2 _FNA NO
0 @17953@ INDI
1 NAME Jean/d'HANNONCELLES/
2 GIVN Jean
2 SURN d'HANNONCELLES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:01
1 BIRT
2 DATE 1894
2 _FNA NO
1 DEAT
2 DATE 1914
2 _FNA NO
1 FAMC @17621@
0 @17963@ INDI
1 NAME Émilie/d'HANNONCELLES/
2 GIVN Émilie
2 SURN d'HANNONCELLES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:01
1 BIRT
2 DATE 1895
2 _FNA NO
1 DEAT
2 DATE 1986
2 _FNA NO
1 FAMC @17621@
0 @17973@ INDI
1 NAME Joséphine/d'HANNONCELLES/
2 GIVN Joséphine
2 SURN d'HANNONCELLES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:04
1 FAMS @18001@
1 BIRT
2 DATE 1899
2 _FNA NO
1 DEAT
2 DATE 1996
2 _FNA NO
1 FAMC @17621@
0 @17983@ INDI
1 NAME Paul/d'HANNONCELLES/
2 GIVN Paul
2 SURN d'HANNONCELLES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:09
1 FAMS @18011@
1 BIRT
2 DATE 1904
2 _FNA NO
1 DEAT
2 DATE 1985
2 _FNA NO
1 FAMC @17621@
0 @17994@ INDI
1 NAME Henri/de CUGNAC/
2 GIVN Henri
2 SURN de CUGNAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:06
1 FAMS @18001@
1 BIRT
2 DATE 1895
2 _FNA NO
1 DEAT
2 DATE 1944
2 PLAC Mauthausen,,,,,
2 _FNA NO
2 CAUS DEPORTE
2 NOTE fait général à titre posthume
0 @18009@ INDI
1 NAME /de COUËT de LORRY/
2 GIVN
2 SURN de COUËT de LORRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:09
1 FAMS @18011@
1 DEAT
2 DATE 1986
2 _FNA NO
0 @18014@ INDI
1 NAME Marguerite/de GARGAN/
2 GIVN Marguerite
2 SURN de GARGAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:18
1 FAMS @18042@
1 BIRT
2 DATE 1899
2 _FNA NO
1 DEAT
2 DATE 1991
2 _FNA NO
1 FAMC @17630@
0 @18024@ INDI
1 NAME Antoinette/de GARGAN/
2 GIVN Antoinette
2 SURN de GARGAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:20
1 FAMS @18079@
1 BIRT
2 DATE 1904
2 _FNA NO
1 DEAT
2 DATE 1980
2 _FNA NO
1 FAMC @17630@
0 @18035@ INDI
1 NAME Joseph/de CATUELAN/
2 GIVN Joseph
2 SURN de CATUELAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:18
1 FAMS @18042@
1 BIRT
2 DATE 1897
2 _FNA NO
1 DEAT
2 DATE 1961
2 _FNA NO
0 @18044@ INDI
1 NAME Louis/de CATUELAN/
2 GIVN Louis
2 SURN de CATUELAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:16
1 BIRT
2 DATE 1924
2 _FNA NO
1 DEAT
2 DATE 2003
2 _FNA NO
1 FAMC @18042@
0 @18054@ INDI
1 NAME Pierre/de CATUELAN/
2 GIVN Pierre
2 SURN de CATUELAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:17
1 BIRT
2 DATE 1925
2 _FNA NO
1 FAMC @18042@
0 @18061@ INDI
1 NAME Jacques/de CATUELAN/
2 GIVN Jacques
2 SURN de CATUELAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:18
1 BIRT
2 DATE 1929
2 _FNA NO
1 DEAT
2 DATE 1996
2 _FNA NO
1 FAMC @18042@
0 @18072@ INDI
1 NAME Jean/de ROCHEBOUËT/
2 GIVN Jean
2 SURN de ROCHEBOUËT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:20
1 FAMS @18079@
1 BIRT
2 DATE 1897
2 _FNA NO
1 DEAT
2 DATE 1959
2 _FNA NO
0 @18083@ INDI
1 NAME Jeanne/de LA TOUR du PIN CHAMBLY de la CHARCE/
2 GIVN Jeanne
2 SURN de LA TOUR du PIN CHAMBLY de la CHARCE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 NOV 2019
3 TIME 7:52
1 BIRT
2 DATE 1907
2 _FNA NO
1 DEAT
2 DATE 1925
2 _FNA NO
1 FAMC @17639@
0 @18093@ INDI
1 NAME Charles/de LA TOUR du PIN CHAMBLY de la CHARCE/
2 GIVN Charles
2 SURN de LA TOUR du PIN CHAMBLY de la CHARCE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 NOV 2019
3 TIME 7:52
1 BIRT
2 DATE 1908
2 _FNA NO
1 DEAT
2 DATE 1988
2 _FNA NO
1 FAMC @17639@
0 @18103@ INDI
1 NAME Renée/de LA TOUR du PIN CHAMBLY de la CHARCE/
2 GIVN Renée
2 SURN de LA TOUR du PIN CHAMBLY de la CHARCE
1 SEX F
1 OCCU Religieuse
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 NOV 2019
3 TIME 7:53
1 BIRT
2 DATE 1910
2 _FNA NO
1 DEAT
2 DATE 1951
2 _FNA NO
1 FAMC @17639@
0 @18114@ INDI
1 NAME Marguerite/de LA TOUR du PIN CHAMBLY de la CHARCE/
2 GIVN Marguerite
2 SURN de LA TOUR du PIN CHAMBLY de la CHARCE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 NOV 2019
3 TIME 7:52
1 BIRT
2 DATE 1913
2 _FNA NO
1 DEAT
2 DATE 1913
2 _FNA NO
1 FAMC @17639@
0 @18124@ INDI
1 NAME Marie/de LA TOUR du PIN CHAMBLY de la CHARCE/
2 GIVN Marie
2 SURN de LA TOUR du PIN CHAMBLY de la CHARCE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 NOV 2019
3 TIME 7:53
1 BIRT
2 DATE 1909
2 _FNA NO
1 DEAT
2 DATE 2001
2 _FNA NO
1 FAMC @17639@
0 @18134@ INDI
1 NAME Jacques/de LA TOUR du PIN CHAMBLY de la CHARCE/
2 GIVN Jacques
2 SURN de LA TOUR du PIN CHAMBLY de la CHARCE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 NOV 2019
3 TIME 7:52
1 BIRT
2 DATE 1911
2 _FNA NO
1 DEAT
2 DATE 2006
2 _FNA NO
1 FAMC @17639@
0 @18144@ INDI
1 NAME Louis/de LA TOUR du PIN CHAMBLY de la CHARCE/
2 GIVN Louis
2 SURN de LA TOUR du PIN CHAMBLY de la CHARCE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 NOV 2019
3 TIME 7:52
1 BIRT
2 DATE 1914
2 _FNA NO
1 DEAT
2 DATE 2000
2 _FNA NO
1 FAMC @17639@
0 @18155@ INDI
1 NAME Geneviève/de GARGAN/
2 GIVN Geneviève
2 SURN de GARGAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:37
1 FAMS @18193@
1 BIRT
2 DATE 1915
2 _FNA NO
1 DEAT
2 DATE 2006
2 _FNA NO
1 FAMC @17648@
0 @18165@ INDI
1 NAME Françoise/de GARGAN/
2 GIVN Françoise
2 SURN de GARGAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:38
1 FAMS @18202@
1 BIRT
2 DATE 1916
2 _FNA NO
1 DEAT
2 DATE 1954
2 _FNA NO
1 FAMC @17648@
0 @18175@ INDI
1 NAME Madeleine/de GARGAN/
2 GIVN Madeleine
2 SURN de GARGAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:45
1 FAMS @18211@
1 BIRT
2 DATE 1928
2 _FNA NO
1 DEAT
2 DATE 2000
2 _FNA NO
1 FAMC @17648@
0 @18186@ INDI
1 NAME Robert/van ZELLER d'OOSTHOVE/
2 GIVN Robert
2 SURN van ZELLER d'OOSTHOVE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:37
1 FAMS @18193@
1 BIRT
2 DATE 1909
2 _FNA NO
1 DEAT
2 DATE 1975
2 _FNA NO
0 @18195@ INDI
1 NAME Jacques/de CIVILLE/
2 GIVN Jacques
2 SURN de CIVILLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:38
1 FAMS @18202@
1 BIRT
2 DATE 1913
2 _FNA NO
1 DEAT
2 DATE 1945
2 _FNA NO
0 @18204@ INDI
1 NAME Guy/CHARPENTIER/
2 GIVN Guy
2 SURN CHARPENTIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:45
1 FAMS @18211@
1 BIRT
2 DATE 1921
2 _FNA NO
1 DEAT
2 DATE 1991
2 _FNA NO
0 @18232@ INDI
1 NAME Marie-Isabelle/HUET/
2 GIVN Marie-Isabelle
2 SURN HUET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 9:57
1 FAMS @18236@
1 BIRT
2 DATE 1971
2 _FNA NO
0 @18252@ INDI
1 NAME Guillaume/AUBIN/
2 GIVN Guillaume
2 SURN AUBIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:56
1 FAMS @18256@
1 BIRT
2 DATE 1966
2 _FNA NO
0 @18258@ INDI
1 NAME /de LESQUEN du PLESSIS-CASSO/
2 GIVN
2 SURN de LESQUEN du PLESSIS-CASSO
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:57
1 FAMS @18260@
1 BIRT
2 DATE 1963
2 _FNA NO
0 @18264@ INDI
1 NAME Christine/von SEEFRIED auf BUTTENHEIM/
2 GIVN Christine
2 SURN von SEEFRIED auf BUTTENHEIM
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 18:59
1 FAMS @18268@
1 BIRT
2 DATE 1980
2 _FNA NO
0 @18270@ INDI
1 NAME François/SIMON/
2 GIVN François
2 SURN SIMON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:00
1 FAMS @18274@
1 BIRT
2 DATE 1980
2 _FNA NO
0 @18279@ INDI
1 NAME Cécile/BROCHARD de la ROCHEBROCHARD/
2 GIVN Cécile
2 SURN BROCHARD de la ROCHEBROCHARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:02
1 FAMS @18283@
1 BIRT
2 DATE 1975
2 _FNA NO
0 @18287@ INDI
1 NAME Foulques/de LA FOREST DIVONNE/
2 GIVN Foulques
2 SURN de LA FOREST DIVONNE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:03
1 FAMS @18291@
1 BIRT
2 DATE 1976
2 _FNA NO
0 @18295@ INDI
1 NAME Rodolphe/de TINGUY du POUËT/
2 GIVN Rodolphe
2 SURN de TINGUY du POUËT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:05
1 FAMS @18299@
1 BIRT
2 DATE 1973
2 _FNA NO
0 @18301@ INDI
1 NAME Augustin/ABORD de CHATILLON/
2 GIVN Augustin
2 SURN ABORD de CHATILLON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:06
1 BIRT
2 DATE 1991
2 _FNA NO
1 FAMC @14108@
0 @18312@ INDI
1 NAME Quitterie/de WENDEL/
2 GIVN Quitterie
2 SURN de WENDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:08
1 BIRT
2 DATE 1990
2 _FNA NO
1 FAMC @14146@
0 @18335@ INDI
1 NAME France/JANCENE/
2 GIVN France
2 SURN JANCENE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:21
1 FAMS @18339@
1 BIRT
2 DATE 1968
2 _FNA NO
0 @18345@ INDI
1 NAME Nicolas/DENJOY/
2 GIVN Nicolas
2 SURN DENJOY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:24
1 FAMS @18349@
1 BIRT
2 DATE 1968
2 _FNA NO
0 @18351@ INDI
1 NAME Charles/de YTURBE/
2 GIVN Charles
2 SURN de YTURBE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:25
1 FAMS @18355@
1 BIRT
2 DATE 1974
2 _FNA NO
0 @18363@ INDI
1 NAME Jean/de GONET/
2 GIVN Jean
2 SURN de GONET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:43
1 FAMS @18365@
0 @18367@ INDI
1 NAME Olivier/GASQUET/
2 GIVN Olivier
2 SURN GASQUET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:30
1 FAMS @18371@
1 BIRT
2 DATE 1957
2 _FNA NO
0 @18374@ INDI
1 NAME Mario/CARELLI/
2 GIVN Mario
2 SURN CARELLI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:31
1 FAMS @18381@
1 BIRT
2 DATE 1952
2 _FNA NO
1 DEAT
2 DATE 1994
2 _FNA NO
0 @18384@ INDI
1 NAME Bruno/BONAMY/
2 GIVN Bruno
2 SURN BONAMY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:31
1 FAMS @18388@
1 BIRT
2 DATE 1942
2 _FNA NO
0 @18389@ INDI
1 NAME Odile/LEFEBVRE/
2 GIVN Odile
2 SURN LEFEBVRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:35
1 FAMS @18393@
1 BIRT
2 DATE 1953
2 _FNA NO
0 @18422@ INDI
1 NAME Abel/SAINT-BRIS/
2 GIVN Abel
2 SURN SAINT-BRIS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:46
1 BIRT
2 DATE 1993
2 _FNA NO
1 FAMC @14309@
0 @18429@ INDI
1 NAME Oscar/SAINT-BRIS/
2 GIVN Oscar
2 SURN SAINT-BRIS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:46
1 BIRT
2 DATE 1997
2 _FNA NO
1 FAMC @14309@
0 @18443@ INDI
1 NAME Marie-Thérèse/de MONTALEMBERT/
2 GIVN Marie-Thérèse
2 SURN de MONTALEMBERT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 20:00
1 FAMS @18529@
1 BIRT
2 DATE 1925
2 _FNA NO
1 DEAT
2 DATE 1993
2 _FNA NO
1 FAMC @16263@
0 @18457@ INDI
1 NAME Éric/de PENNART/
2 GIVN Éric
2 SURN de PENNART
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:56
1 FAMS @18499@
1 BIRT
2 DATE 1950
2 _FNA NO
1 DEAT
2 DATE 1986
2 _FNA NO
1 FAMC @16272@
0 @18467@ INDI
1 NAME Geoffroy/de PENNART/
2 GIVN Geoffroy
2 SURN de PENNART
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:57
1 FAMS @18503@
1 FAMS @18508@
1 BIRT
2 DATE 1951
2 _FNA NO
1 FAMC @16272@
0 @18474@ INDI
1 NAME Christine/de PENNART/
2 GIVN Christine
2 SURN de PENNART
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:58
1 FAMS @18512@
1 BIRT
2 DATE 1954
2 _FNA NO
1 FAMC @16272@
0 @18481@ INDI
1 NAME Philippe/de PENNART/
2 GIVN Philippe
2 SURN de PENNART
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:58
1 FAMS @18515@
1 BIRT
2 DATE 1957
2 _FNA NO
1 FAMC @16272@
0 @18488@ INDI
1 NAME Henri/de PENNART/
2 GIVN Henri
2 SURN de PENNART
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:59
1 FAMS @18520@
1 BIRT
2 DATE 1964
2 _FNA NO
1 FAMC @16272@
0 @18497@ INDI
1 NAME Marie-Christine/COURTOIS-SUFFIT/
2 GIVN Marie-Christine
2 SURN COURTOIS-SUFFIT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:56
1 FAMS @18499@
0 @18501@ INDI
1 NAME Anne/de BOISFLEURY/
2 GIVN Anne
2 SURN de BOISFLEURY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:57
1 FAMS @18503@
0 @18506@ INDI
1 NAME Joëlle/GRAUX/
2 GIVN Joëlle
2 SURN GRAUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:57
1 FAMS @18508@
0 @18510@ INDI
1 NAME Roger/BELTON/
2 GIVN Roger
2 SURN BELTON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:58
1 FAMS @18512@
0 @18513@ INDI
1 NAME Sophie/CHEVALIER/
2 GIVN Sophie
2 SURN CHEVALIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:58
1 FAMS @18515@
0 @18518@ INDI
1 NAME Angélique/LABOURET/
2 GIVN Angélique
2 SURN LABOURET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 19:59
1 FAMS @18520@
0 @18522@ INDI
1 NAME Paul/GARELLI/
2 GIVN Paul
2 SURN GARELLI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 MAY 2018
3 TIME 20:00
1 FAMS @18529@
1 BIRT
2 DATE 1924
2 _FNA NO
1 DEAT
2 DATE 2006
2 _FNA NO
0 @18547@ INDI
1 NAME Charles François/de MITRY/
2 GIVN Charles François
2 SURN de MITRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 DEC 2018
3 TIME 11:59
1 FAMS @18578@
1 BIRT
2 DATE 1820
2 PLAC Nancy,54000,Meurthe-et-Moselle,Grand Est,FRANCE,
3 MAP
4 LATI N48.683330
4 LONG E6.200000
2 _FNA NO
1 DEAT
2 DATE 6 JAN 1901
2 PLAC Leménil-Mitry,54740,Meurthe-et-Moselle,Grand Est,FRANCE,
3 MAP
4 LATI N48.453560
4 LONG E6.252390
2 _FNA NO
1 FAMC @22940@
0 @18563@ INDI
1 NAME Marie Eugénie Berthe/BELGRAND/
2 GIVN Marie Eugénie Berthe
2 SURN BELGRAND
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 7:43
1 FAMS @18578@
1 BIRT
2 DATE 1835
2 _FNA NO
1 DEAT
2 DATE 1916
2 PLAC Nancy,54000,Meurthe-et-Moselle,Grand Est,FRANCE,
3 MAP
4 LATI N48.683330
4 LONG E6.200000
2 _FNA NO
1 FAMC @18602@
0 @18585@ INDI
1 NAME Claude Marie Antoine/BELGRAND/
2 GIVN Claude Marie Antoine
2 SURN BELGRAND
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 7:41
1 FAMS @18602@
0 @18599@ INDI
1 NAME Thérèse Célestine/VINCHON/
2 GIVN Thérèse Célestine
2 SURN VINCHON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 7:41
1 FAMS @18602@
0 @18608@ INDI
1 NAME Henri/de MITRY/
2 GIVN Henri
2 SURN de MITRY
1 SEX M
1 OCCU Général de division
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 JAN 2019
3 TIME 7:28
1 FAMS @18636@
1 BIRT
2 DATE 1857
2 _FNA NO
1 BURI
2 DATE 1924
2 PLAC Paris 07,,,,,INVALIDES
2 _FNA NO
1 DEAT
2 DATE 18 AUG 1924
2 _FNA NO
1 FAMC @18578@
0 @18629@ INDI
1 NAME Marguerite/DEVAY/
2 GIVN Marguerite
2 SURN DEVAY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 7:47
1 FAMS @18636@
1 BIRT
2 DATE 1860
2 _FNA NO
1 DEAT
2 DATE 1884
2 _FNA NO
0 @18638@ INDI
1 NAME Renée Marie/de MITRY/
2 GIVN Renée Marie
2 SURN de MITRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 7:48
1 FAMS @18658@
1 BIRT
2 DATE 1883
2 _FNA NO
1 DEAT
2 DATE 1930
2 _FNA NO
1 FAMC @18636@
0 @18654@ INDI
1 NAME Pierre/de RAVINEL/
2 GIVN Pierre
2 SURN de RAVINEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 7:48
1 FAMS @18658@
1 BIRT
2 DATE 1874
2 _FNA NO
0 @18676@ INDI
1 NAME Louis Marie Robert/de COURSON de la VILLENEUVE/
2 GIVN Louis Marie Robert
2 SURN de COURSON de la VILLENEUVE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 8:00
1 FAMS @18684@
1 BIRT
2 DATE 1889
2 _FNA NO
0 @18724@ INDI
1 NAME Charles/TYREL de POIX/
2 GIVN Charles
2 SURN TYREL de POIX
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 8:31
1 FAMS @18743@
1 BIRT
2 DATE 1882
2 _FNA NO
1 DEAT
2 DATE 1950
2 _FNA NO
1 FAMC @18759@
0 @18736@ INDI
1 NAME Germaine/NICOLI de PERALDI/
2 GIVN Germaine
2 SURN NICOLI de PERALDI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 8:25
1 FAMS @18743@
1 BIRT
2 DATE 1886
2 PLAC Ajaccio,20000,Corse-du-Sud,Corse,FRANCE,
3 MAP
4 LATI N41.927230
4 LONG E8.734620
2 _FNA NO
1 DEAT
2 DATE 1968
2 PLAC Ajaccio,20000,Corse-du-Sud,Corse,FRANCE,
3 MAP
4 LATI N41.927230
4 LONG E8.734620
2 _FNA NO
0 @18746@ INDI
1 NAME Louis/TYREL de POIX/
2 GIVN Louis
2 SURN TYREL de POIX
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 8:31
1 FAMS @18759@
1 BIRT
2 DATE 1857
2 _FNA NO
1 DEAT
2 DATE 1915
2 _FNA NO
0 @18757@ INDI
1 NAME Marguerite/KERVYN de LETENHOVE/
2 GIVN Marguerite
2 SURN KERVYN de LETENHOVE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 8:31
1 FAMS @18759@
0 @18771@ INDI
1 NAME Jacques/DELANGRE/
2 GIVN Jacques
2 SURN DELANGRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 8:40
1 FAMS @18775@
1 BIRT
2 DATE 1910
2 PLAC Douai,59500,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.366670
4 LONG E3.066670
2 _FNA NO
0 @18779@ INDI
1 NAME Louis/CARRELET de LOISY/
2 GIVN Louis
2 SURN CARRELET de LOISY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 8:43
1 FAMS @18783@
1 BIRT
2 DATE 1910
2 PLAC Mazingarbe,62670,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.471100
4 LONG E2.715440
2 _FNA NO
0 @18815@ INDI
1 NAME Amalric/LOMBARD de BUFFIERES de RAMBUTEAU/
2 GIVN Amalric
2 SURN LOMBARD de BUFFIERES de RAMBUTEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 DEC 2019
3 TIME 17:36
1 FAMS @18835@
1 BIRT
2 DATE 29 AUG 1890
2 PLAC Genève,1200,Genève,Région Lémanique,SUISSE,
3 MAP
4 LATI N46.202220
4 LONG E6.145690
2 _FNA NO
1 DEAT
2 DATE 13 DEC 1944
2 PLAC Buchenwald,,,,,
2 _FNA NO
1 FAMC @36720@
0 @18828@ INDI
1 NAME Amélie/de MAC-MAHON de MAGENTA/
2 GIVN Amélie
2 SURN de MAC-MAHON de MAGENTA
1 SEX F
1 OCCU ALTESSE ROYALE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 10:02
1 FAMS @18835@
1 BIRT
2 DATE 1900
2 PLAC Lunéville,54300,Meurthe-et-Moselle,Grand Est,FRANCE,
3 MAP
4 LATI N48.600000
4 LONG E6.500000
2 _FNA NO
1 DEAT
2 DATE 1987
2 PLAC Rambuteau,,,Charolles,,
2 _FNA NO
1 NOTE arrière-arrière-petite fille du roi LOUIS-PHILIPPE
1 FAMC @18857@
0 @18838@ INDI
1 NAME Patrice/de MAC-MAHON de MAGENTA/
2 GIVN Patrice
2 SURN de MAC-MAHON de MAGENTA
1 SEX M
1 OCCU Général
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 NOV 2019
3 TIME 12:00
1 FAMS @18857@
1 BIRT
2 DATE 1855
2 _FNA NO
1 DEAT
2 DATE 1927
2 _FNA NO
1 FAMC @18869@
0 @18850@ INDI
1 NAME Marguerite Françoise Louise/d'ORLEANS/
2 GIVN Marguerite Françoise Louise
2 SURN d'ORLEANS
1 SEX F
1 OCCU ALTESSE ROYALE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 NOV 2019
3 TIME 12:04
1 FAMS @18857@
1 BIRT
2 DATE 25 JAN 1869
2 PLAC Richmond upon Thames,,,,ANGLETERRE,
2 _FNA NO
1 DEAT
2 DATE 31 JAN 1940
2 PLAC Montcresson,45700,Loiret,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N47.905610
4 LONG E2.807960
2 _FNA NO
1 FAMC @36168@
0 @18861@ INDI
1 NAME Patrice/de MAC-MAHON de MAGENTA/
2 GIVN Patrice
2 SURN de MAC-MAHON de MAGENTA
1 SEX M
1 OCCU Maréchal
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 9:49
1 FAMS @18869@
1 BIRT
2 DATE 13 JUN 1808
2 PLAC Sully,71360,Saône-et-Loire,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.007380
4 LONG E4.473330
2 _FNA NO
1 DEAT
2 DATE 1893
2 _FNA NO
0 @18893@ INDI
1 NAME André/FRANÇOIS-PONCET/
2 GIVN André
2 SURN FRANÇOIS-PONCET
1 SEX M
1 OCCU Ambassadeur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 10:12
1 FAMS @18912@
1 BIRT
2 DATE 1887
2 _FNA NO
1 DEAT
2 DATE 1978
2 _FNA NO
0 @18905@ INDI
1 NAME Jacqueline/DILLAIS/
2 GIVN Jacqueline
2 SURN DILLAIS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 10:12
1 FAMS @18912@
1 BIRT
2 DATE 1892
2 _FNA NO
1 DEAT
2 DATE 1982
2 _FNA NO
0 @18934@ INDI
1 NAME Marquis/de VAULSERRE/
2 GIVN Marquis
2 SURN de VAULSERRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 10:50
1 FAMS @18949@
1 FAMS @18974@
0 @18942@ INDI
1 NAME Gabrielle/BAGUENAULT de PUCHESSE/
2 GIVN Gabrielle
2 SURN BAGUENAULT de PUCHESSE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 10:49
1 FAMS @18949@
1 FAMS @18990@
1 BIRT
2 DATE 1802
2 _FNA NO
1 DEAT
2 DATE 1886
2 _FNA NO
0 @18967@ INDI
1 NAME Hélène/de THELUSSON de SORCY/
2 GIVN Hélène
2 SURN de THELUSSON de SORCY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 10:50
1 FAMS @18974@
1 BIRT
2 DATE 1829
2 _FNA NO
1 DEAT
2 DATE 1910
2 _FNA NO
1 FAMC @18990@
0 @18983@ INDI
1 NAME Amable/de THELUSSON de SORCY/
2 GIVN Amable
2 SURN de THELUSSON de SORCY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 10:49
1 FAMS @18990@
1 BIRT
2 DATE 1793
2 _FNA NO
1 DEAT
2 DATE 1843
2 _FNA NO
0 @18999@ INDI
1 NAME Louis/de PERUSSE des CARS/
2 GIVN Louis
2 SURN de PERUSSE des CARS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:21
1 FAMS @19015@
1 BIRT
2 DATE 1909
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 1961
2 PLAC Augy,,,,,
2 _FNA NO
0 @19011@ INDI
1 NAME Marie-Louise/de BRIEY/
2 GIVN Marie-Louise
2 SURN de BRIEY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:21
1 FAMS @19015@
1 BIRT
2 DATE 1911
2 PLAC Ohain,1380,Brabant Wallon,Wallonie,BELGIQUE,
3 MAP
4 LATI N50.698850
4 LONG E4.469800
2 _FNA NO
0 @19023@ INDI
1 NAME Louis-Amedee/de MOUSTIER/
2 GIVN Louis-Amedee
2 SURN de MOUSTIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:26
1 FAMS @19035@
1 BIRT
2 DATE 1928
2 _FNA NO
0 @19031@ INDI
1 NAME Béatrice/DUTEY-HARISPE/
2 GIVN Béatrice
2 SURN DUTEY-HARISPE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:26
1 FAMS @19035@
1 BIRT
2 DATE 1944
2 _FNA NO
0 @19038@ INDI
1 NAME Marie/de MITRY/
2 GIVN Marie
2 SURN de MITRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:27
1 BIRT
2 DATE 1998
2 _FNA NO
1 FAMC @17751@
0 @19046@ INDI
1 NAME Cordélia/de MITRY/
2 GIVN Cordélia
2 SURN de MITRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:27
1 BIRT
2 DATE 2000
2 _FNA NO
1 FAMC @17751@
0 @19054@ INDI
1 NAME Apoline/de MITRY/
2 GIVN Apoline
2 SURN de MITRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:27
1 BIRT
2 DATE 2004
2 _FNA NO
1 FAMC @17751@
0 @19067@ INDI
1 NAME Edmond/COLLOT/
2 GIVN Edmond
2 SURN COLLOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:30
1 FAMS @19075@
0 @19073@ INDI
1 NAME Catherine/ALFONSI/
2 GIVN Catherine
2 SURN ALFONSI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:30
1 FAMS @19075@
0 @19079@ INDI
1 NAME Constantin/KYNDYNIS-KANARELIS/
2 GIVN Constantin
2 SURN KYNDYNIS-KANARELIS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:32
1 FAMS @19081@
0 @19085@ INDI
1 NAME Robert/d'IRUMBERRY de SALABERRY/
2 GIVN Robert
2 SURN d'IRUMBERRY de SALABERRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:45
1 FAMS @19103@
1 BIRT
2 DATE 1901
2 _FNA NO
1 DEAT
2 DATE 1993
2 _FNA NO
1 FAMC @19136@
0 @19096@ INDI
1 NAME Gilberte/BURRUS/
2 GIVN Gilberte
2 SURN BURRUS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:41
1 FAMS @19103@
1 BIRT
2 DATE 1911
2 _FNA NO
1 DEAT
2 DATE 1992
2 _FNA NO
1 FAMC @19126@
0 @19108@ INDI
1 NAME Henry/BURRUS/
2 GIVN Henry
2 SURN BURRUS
1 SEX M
1 OCCU MANUFACTURE DE TABAC
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 17:43
1 FAMS @19126@
1 BIRT
2 DATE 5 JUN 1882
2 PLAC Boncourt,2926,Jura,Espace Mittelland,SUISSE,
3 MAP
4 LATI N47.495600
4 LONG E7.014230
2 _FNA NO
1 DEAT
2 DATE 3 SEP 1957
2 PLAC Boncourt,2926,Jura,Espace Mittelland,SUISSE,
3 MAP
4 LATI N47.495600
4 LONG E7.014230
2 _FNA NO
2 NOTE maire de Boncourt
0 @19119@ INDI
1 NAME Jeanne/FRANÇOIS/
2 GIVN Jeanne
2 SURN FRANÇOIS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 17:43
1 FAMS @19126@
1 BIRT
2 DATE 1883
2 _FNA NO
1 DEAT
2 DATE 1979
2 _FNA NO
0 @19129@ INDI
1 NAME Bernard/d'IRUMBERRY de SALABERRY/
2 GIVN Bernard
2 SURN d'IRUMBERRY de SALABERRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:45
1 FAMS @19136@
1 BIRT
2 DATE 1865
2 _FNA NO
1 DEAT
2 DATE 1935
2 _FNA NO
0 @19140@ INDI
1 NAME François/d'HARDIVILLIERS/
2 GIVN François
2 SURN d'HARDIVILLIERS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:49
1 FAMS @19158@
1 BIRT
2 DATE 1925
2 _FNA NO
1 DEAT
2 DATE 1974
2 _FNA NO
0 @19151@ INDI
1 NAME Gabrielle/de CLERMONT-TONNERRE/
2 GIVN Gabrielle
2 SURN de CLERMONT-TONNERRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:49
1 FAMS @19158@
1 BIRT
2 DATE 1922
2 _FNA NO
1 DEAT
2 DATE 2004
2 _FNA NO
0 @19162@ INDI
1 NAME Philippe/JAPY/
2 GIVN Philippe
2 SURN JAPY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:53
1 FAMS @19166@
1 BIRT
2 DATE 1970
2 _FNA NO
0 @19168@ INDI
1 NAME Eugénie/JAPY/
2 GIVN Eugénie
2 SURN JAPY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:51
1 BIRT
2 DATE 1997
2 _FNA NO
1 FAMC @19166@
0 @19176@ INDI
1 NAME Prisca/JAPY/
2 GIVN Prisca
2 SURN JAPY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:52
1 BIRT
2 DATE 1999
2 _FNA NO
1 FAMC @19166@
0 @19184@ INDI
1 NAME Hermine/JAPY/
2 GIVN Hermine
2 SURN JAPY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:52
1 BIRT
2 DATE 2001
2 _FNA NO
1 FAMC @19166@
0 @19191@ INDI
1 NAME Aglaé/JAPY/
2 GIVN Aglaé
2 SURN JAPY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:52
1 BIRT
2 DATE 2005
2 _FNA NO
1 DEAT
2 DATE 2005
2 _FNA NO
1 FAMC @19166@
0 @19201@ INDI
1 NAME Zacharie/JAPY/
2 GIVN Zacharie
2 SURN JAPY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:53
1 BIRT
2 DATE 2006
2 _FNA NO
1 FAMC @19166@
0 @19209@ INDI
1 NAME Jean-Baptiste/VALAT/
2 GIVN Jean-Baptiste
2 SURN VALAT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:55
1 FAMS @19213@
1 BIRT
2 DATE 1968
2 _FNA NO
0 @19214@ INDI
1 NAME Alexandre/VALAT/
2 GIVN Alexandre
2 SURN VALAT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:55
1 BIRT
2 DATE 23 OCT 2005
2 _FNA NO
1 FAMC @19213@
0 @19221@ INDI
1 NAME Gabrielle/VALAT/
2 GIVN Gabrielle
2 SURN VALAT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:55
1 BIRT
2 DATE 2 MAR 2007
2 _FNA NO
1 FAMC @19213@
0 @19230@ INDI
1 NAME Grégoire/APOSTOLIDES/
2 GIVN Grégoire
2 SURN APOSTOLIDES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:58
1 FAMS @19234@
1 BIRT
2 DATE 1976
2 _FNA NO
0 @19235@ INDI
1 NAME Louise/APOSTOLIDES/
2 GIVN Louise
2 SURN APOSTOLIDES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:57
1 BIRT
2 DATE 2001
2 _FNA NO
1 FAMC @19234@
0 @19242@ INDI
1 NAME Agathe/APOSTOLIDES/
2 GIVN Agathe
2 SURN APOSTOLIDES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:57
1 BIRT
2 DATE 2003
2 _FNA NO
1 FAMC @19234@
0 @19249@ INDI
1 NAME Clémence/APOSTOLIDES/
2 GIVN Clémence
2 SURN APOSTOLIDES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:57
1 BIRT
2 DATE 2004
2 _FNA NO
1 FAMC @19234@
0 @19257@ INDI
1 NAME Priscille/APOSTOLIDES/
2 GIVN Priscille
2 SURN APOSTOLIDES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 12:58
1 BIRT
2 DATE 2007
2 _FNA NO
1 FAMC @19234@
0 @19265@ INDI
1 NAME William/EDEL/
2 GIVN William
2 SURN EDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 14:43
1 FAMS @19269@
1 BIRT
2 DATE 1975
2 _FNA NO
0 @19271@ INDI
1 NAME Maxime/EDEL/
2 GIVN Maxime
2 SURN EDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 14:43
1 BIRT
2 DATE 2004
2 _FNA NO
1 FAMC @19269@
0 @19278@ INDI
1 NAME Alice/EDEL/
2 GIVN Alice
2 SURN EDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 14:43
1 BIRT
2 DATE 2005
2 _FNA NO
1 FAMC @19269@
0 @19285@ INDI
1 NAME Cyprien/EDEL/
2 GIVN Cyprien
2 SURN EDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 14:43
1 BIRT
2 DATE 2007
2 _FNA NO
1 FAMC @19269@
0 @19294@ INDI
1 NAME Floriane/de LA VILLARMOIS/
2 GIVN Floriane
2 SURN de LA VILLARMOIS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 14:48
1 FAMS @19298@
1 BIRT
2 DATE 1981
2 _FNA NO
0 @19300@ INDI
1 NAME Cécile/de LUSSAC/
2 GIVN Cécile
2 SURN de LUSSAC
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 14:59
1 BIRT
2 DATE 1993
2 _FNA NO
1 FAMC @15220@
0 @19307@ INDI
1 NAME Clara/de LUSSAC/
2 GIVN Clara
2 SURN de LUSSAC
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 14:59
1 BIRT
2 DATE 1997
2 _FNA NO
1 FAMC @15220@
0 @19318@ INDI
1 NAME Olivia/BOSCAL de REALS de MORNAC/
2 GIVN Olivia
2 SURN BOSCAL de REALS de MORNAC
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 16:53
1 FAMC @16241@
0 @19323@ INDI
1 NAME Etienne/BOSCAL de REALS de MORNAC/
2 GIVN Etienne
2 SURN BOSCAL de REALS de MORNAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 16:53
1 BIRT
2 DATE 1995
2 _FNA NO
1 FAMC @16241@
0 @19342@ INDI
1 NAME René/de PANAFIEU/
2 GIVN René
2 SURN de PANAFIEU
1 SEX M
1 OCCU Ingénieur Mines
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 DEC 2018
3 TIME 11:08
1 FAMS @19359@
1 BIRT
2 DATE 14 FEB 1904
2 PLAC Pontivy,56300,Morbihan,Bretagne,FRANCE,
3 MAP
4 LATI N48.066670
4 LONG W2.983330
2 _FNA NO
1 DEAT
2 DATE 18 JUN 1967
2 PLAC Neuilly-sur-Seine,92200,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883330
4 LONG E2.266670
2 _FNA NO
1 NOTE Métier: P.D.G. de Total et de la Compagnie Française des Pétroles
2 CONT Ingénieur de mines.
2 CONT
1 FAMC @22880@
0 @19357@ INDI
1 NAME Marie-Antoinette/MATHIEU de VIENNE/
2 GIVN Marie-Antoinette
2 SURN MATHIEU de VIENNE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 DEC 2018
3 TIME 11:04
1 FAMS @19359@
1 BIRT
2 DATE 1913
2 _FNA NO
1 DEAT
2 DATE 1997
2 _FNA NO
1 FAMC @19378@
0 @19365@ INDI
1 NAME Henri Mathieu/de VIENNE/
2 GIVN Henri Mathieu
2 SURN de VIENNE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 17:01
1 FAMS @19378@
1 BIRT
2 DATE 1881
2 PLAC Versailles,78000,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.800000
4 LONG E2.133330
2 _FNA NO
0 @19376@ INDI
1 NAME Françoise/de CHOLET/
2 GIVN Françoise
2 SURN de CHOLET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 17:01
1 FAMS @19378@
0 @19385@ INDI
1 NAME Valéry/GISCARD d'ESTAING/
2 GIVN Valéry
2 SURN GISCARD d'ESTAING
1 SEX U
1 OCCU PRESIDENT DE LA REPUBLIQUE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 13:27
0 @19388@ INDI
1 NAME Marie-Laure/de PANAFIEU/
2 GIVN Marie-Laure
2 SURN de PANAFIEU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 17:11
1 FAMS @19428@
1 BIRT
2 DATE 1934
2 _FNA NO
1 FAMC @19359@
0 @19395@ INDI
1 NAME Philippe/de PANAFIEU/
2 GIVN Philippe
2 SURN de PANAFIEU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 17:12
1 FAMS @19434@
1 BIRT
2 DATE 1936
2 _FNA NO
1 FAMC @19359@
0 @19402@ INDI
1 NAME Jean/de PANAFIEU/
2 GIVN Jean
2 SURN de PANAFIEU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 17:13
1 FAMS @19440@
1 BIRT
2 DATE 1940
2 _FNA NO
1 FAMC @19359@
0 @19409@ INDI
1 NAME Christian/de PANAFIEU/
2 GIVN Christian
2 SURN de PANAFIEU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 17:14
1 FAMS @19446@
1 BIRT
2 DATE 1946
2 _FNA NO
1 FAMC @19359@
0 @19416@ INDI
1 NAME Arnaud/de PANAFIEU/
2 GIVN Arnaud
2 SURN de PANAFIEU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 17:14
1 FAMS @19452@
1 BIRT
2 DATE 1948
2 _FNA NO
1 FAMC @19359@
0 @19424@ INDI
1 NAME Antoine/de LA LOYERE/
2 GIVN Antoine
2 SURN de LA LOYERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 17:11
1 FAMS @19428@
1 BIRT
2 DATE 1933
2 _FNA NO
0 @19430@ INDI
1 NAME Véronique/BUJARD/
2 GIVN Véronique
2 SURN BUJARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 17:12
1 FAMS @19434@
1 BIRT
2 DATE 1948
2 _FNA NO
0 @19436@ INDI
1 NAME Béatrice/d'AUDIFFRET-PASQUIER/
2 GIVN Béatrice
2 SURN d'AUDIFFRET-PASQUIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 17:13
1 FAMS @19440@
1 BIRT
2 DATE 1946
2 _FNA NO
0 @19442@ INDI
1 NAME Sonia/de MOUSTIER/
2 GIVN Sonia
2 SURN de MOUSTIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 17:14
1 FAMS @19446@
1 BIRT
2 DATE 1951
2 _FNA NO
0 @19448@ INDI
1 NAME Sibylle/d'USSEL/
2 GIVN Sibylle
2 SURN d'USSEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 17:14
1 FAMS @19452@
1 BIRT
2 DATE 1954
2 _FNA NO
0 @19453@ INDI
1 NAME Angus/FULLER/
2 GIVN Angus
2 SURN FULLER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 17:16
1 FAMS @19461@
0 @19459@ INDI
1 NAME Sophie/WALLU/
2 GIVN Sophie
2 SURN WALLU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 MAY 2018
3 TIME 17:16
1 FAMS @19461@
0 @19480@ INDI
1 NAME Lamoral/TAXIS von BORDOGNA UND VALNIGRA/
2 GIVN Lamoral
2 SURN TAXIS von BORDOGNA UND VALNIGRA
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 MAY 2018
3 TIME 19:50
1 FAMS @19499@
1 BIRT
2 DATE 1900
2 _FNA NO
1 DEAT
2 DATE 1966
2 _FNA NO
0 @19492@ INDI
1 NAME Theresia/de SAXE-COBOURG ET GOTHA/
2 GIVN Theresia
2 SURN de SAXE-COBOURG ET GOTHA
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 MAY 2018
3 TIME 19:50
1 FAMS @19499@
1 BIRT
2 DATE 1902
2 _FNA NO
1 DEAT
2 DATE 1990
2 _FNA NO
0 @19507@ INDI
1 NAME Alexandre/CANTACUSENE/
2 GIVN Alexandre
2 SURN CANTACUSENE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 MAY 2018
3 TIME 19:59
1 FAMS @19518@
1 BIRT
2 DATE 1944
2 _FNA NO
0 @19515@ INDI
1 NAME Anne Michelle/RUSS/
2 GIVN Anne Michelle
2 SURN RUSS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 MAY 2018
3 TIME 19:59
1 FAMS @19518@
0 @19547@ INDI
1 NAME Claude/ALTMAYER/
2 GIVN Claude
2 SURN ALTMAYER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 JUL 2018
3 TIME 12:00
1 FAMS @19562@
1 BIRT
2 DATE 29 JUN 1924
2 _FNA NO
1 DEAT
2 DATE 2 JAN 2008
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @19558@ INDI
1 NAME Solange/de TRUCHIS de LAYS/
2 GIVN Solange
2 SURN de TRUCHIS de LAYS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 MAY 2018
3 TIME 20:20
1 FAMS @19562@
1 BIRT
2 DATE 1932
2 PLAC Tunis,,,,,
3 MAP
4 LATI N36.818970
4 LONG E10.165790
2 _FNA NO
0 @19567@ INDI
1 NAME Guy Marie Eugène/CHAVANNE/
2 GIVN Guy Marie Eugène
2 SURN CHAVANNE
1 SEX M
1 OCCU Professeur de médecine
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 18:30
1 FAMS @19578@
1 BIRT
2 DATE 7 FEB 1923
2 PLAC Saint Martin en Coailleux,,,,,Saint Martin en Coailleux
2 _FNA NO
2 NOTE Saint Martin en Coailleux
3 CONT Saint Julien en Jarez
1 FAMC @34459@
0 @19576@ INDI
1 NAME Hélène Louise Jeanne Marie/LOUISON/
2 GIVN Hélène Louise Jeanne Marie
2 SURN LOUISON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 18:05
1 FAMS @19578@
1 FAMC @34658@
0 @19584@ INDI
1 NAME Antoine Eugène/CHAVANNE/
2 GIVN Antoine Eugène
2 SURN CHAVANNE
1 SEX M
1 OCCU métallurgiste
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 17:55
1 FAMS @34459@
1 BIRT
2 DATE 1881
2 PLAC Montbrison,42600,Loire,Auvergne-Rhône-Alpes,FRANCE,
3 MAP
4 LATI N45.600000
4 LONG E4.050000
2 _FNA NO
1 DEAT
2 DATE 1947
2 _FNA NO
1 FAMC @34490@
0 @19594@ INDI
1 NAME Humbert/de VILLOUTREYS de BRIGNAC/
2 GIVN Humbert
2 SURN de VILLOUTREYS de BRIGNAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 MAY 2018
3 TIME 20:37
1 FAMS @19607@
1 BIRT
2 DATE 1928
2 _FNA NO
1 DEAT
2 DATE 2002
2 _FNA NO
1 FAMC @19618@
0 @19605@ INDI
1 NAME Jeanne/de VOGÜÉ/
2 GIVN Jeanne
2 SURN de VOGÜÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 MAY 2018
3 TIME 20:38
1 FAMS @19607@
1 FAMC @19629@
0 @19610@ INDI
1 NAME Pierre/de VILLOUTREYS de BRIGNAC/
2 GIVN Pierre
2 SURN de VILLOUTREYS de BRIGNAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 MAY 2018
3 TIME 20:37
1 FAMS @19618@
0 @19616@ INDI
1 NAME Christiane/BARDI de FOURTOU/
2 GIVN Christiane
2 SURN BARDI de FOURTOU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 MAY 2018
3 TIME 20:37
1 FAMS @19618@
0 @19621@ INDI
1 NAME Arnaud/de VOGÜÉ/
2 GIVN Arnaud
2 SURN de VOGÜÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 MAY 2018
3 TIME 20:38
1 FAMS @19629@
0 @19627@ INDI
1 NAME Solange/de MUN/
2 GIVN Solange
2 SURN de MUN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 MAY 2018
3 TIME 20:38
1 FAMS @19629@
0 @19644@ INDI
1 NAME Xxx/ADAM/
2 GIVN Xxx
2 SURN ADAM
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 JUL 2018
3 TIME 11:34
1 FAMC @9192@
0 @19649@ INDI
1 NAME Xy/ADAM/
2 GIVN Xy
2 SURN ADAM
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 JUL 2018
3 TIME 11:34
1 FAMC @9192@
0 @19661@ INDI
1 NAME Olga/BOUTTE/
2 GIVN Olga
2 SURN BOUTTE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JUL 2018
3 TIME 7:34
1 BIRT
2 DATE 3 JUL 2018
2 _FNA NO
1 FAMC @1579@
0 @19678@ INDI
1 NAME Dominique/REY/
2 GIVN Dominique
2 SURN REY
1 SEX M
1 OCCU EVEQUE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JUL 2018
3 TIME 11:33
0 @19685@ INDI
1 NAME Gilles/de BURE/
2 GIVN Gilles
2 SURN de BURE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 AUG 2018
3 TIME 14:51
1 FAMS @19687@
0 @19688@ INDI
1 NAME Basile/de BURE/
2 GIVN Basile
2 SURN de BURE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 AUG 2018
3 TIME 14:50
1 BIRT
2 DATE 5 JUL 1988
2 _FNA NO
1 FAMC @19687@
0 @19695@ INDI
1 NAME Xy/de BURE/
2 GIVN Xy
2 SURN de BURE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 AUG 2018
3 TIME 14:50
1 FAMC @19687@
0 @19701@ INDI
1 NAME Xy2/de BURE/
2 GIVN Xy2
2 SURN de BURE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 AUG 2018
3 TIME 14:51
1 FAMC @19687@
0 @19719@ INDI
1 NAME Guy/de LAMBERTERIE/
2 GIVN Guy
2 SURN de LAMBERTERIE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 AUG 2018
3 TIME 15:38
1 FAMS @19721@
1 FAMC @19727@
0 @19725@ INDI
1 NAME Ennemond/de LAMBERTERIE/
2 GIVN Ennemond
2 SURN de LAMBERTERIE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 AUG 2018
3 TIME 15:38
1 FAMS @19727@
0 @19739@ INDI
1 NAME Yannes/HANI/
2 GIVN Yannes
2 SURN HANI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 OCT 2018
3 TIME 7:56
1 BIRT
2 DATE 5 OCT 2018
2 PLAC Jerusalem,,,,,
2 _FNA NO
1 FAMC @3870@
0 @19754@ INDI
1 NAME Pierre-Paul Jean Silouane Marie/LOISEAU/
2 GIVN Pierre-Paul Jean Silouane Marie
2 SURN LOISEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 OCT 2018
3 TIME 12:36
1 BIRT
2 DATE 25 AUG 2018
3 TIME 7:41
2 PLAC Fécamp,76400,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.757870
4 LONG E0.374570
2 _FNA NO
2 NOTE  3,650 kg   52 cm.
1 FAMC @3198@
0 @19764@ INDI
1 NAME Lucas/PROST/
2 GIVN Lucas
2 SURN PROST
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 OCT 2018
3 TIME 8:21
1 FAMS @19768@
1 BIRT
2 DATE 1989
2 _FNA NO
0 @19795@ INDI
1 NAME Joseph/DORVAL/
2 GIVN Joseph
2 SURN DORVAL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 NOV 2018
3 TIME 18:31
1 BIRT
2 DATE 7 SEP 2018
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @10571@
0 @19841@ INDI
1 NAME François/LEGOUVÉ/
2 GIVN François
2 SURN LEGOUVÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2018
3 TIME 8:25
1 FAMS @19851@
1 BIRT
2 PLAC Mont-de-Marsan,40000,Landes,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N43.890220
4 LONG W0.497130
2 _FNA NO
0 @19849@ INDI
1 NAME Thérèse/de CABANNES/
2 GIVN Thérèse
2 SURN de CABANNES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2018
3 TIME 8:25
1 FAMS @19851@
0 @19868@ INDI
1 NAME Jean-Joseph/SUE/
2 GIVN Jean-Joseph
2 SURN SUE
1 SEX M
1 OCCU chirurgien
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 NOV 2018
3 TIME 18:08
1 FAMS @19871@
1 FAMS @19933@
1 FAMS @20220@
1 BIRT
2 DATE 28 JAN 1760
2 _FNA NO
1 DEAT
2 DATE 21 APR 1830
2 _FNA NO
1 NOTE  médecin et chirurgien français de l'époque napoléonienne
2 CONT fils de Jean-Joseph Sue père (1710-1792), professeur au Collège royal de chirurgie et à l'Académie royale de peinture et de sculpture1. Il s'oppose à l'usage de la guillotine durant la Révolution puis devient médecin en chef de l'hôpital de la Gard
2 CONC e en 1800. Il conserve ce poste jusqu'à ce que les circonstances mettent en évidence son inadaptation à la vie militaire (1812).
2 CONT Jean-Joseph Sue est également le médecin de Joséphine de Beauharnais et de Joseph Fouché. Après la Restauration, il soignera Louis XVIII.
2 CONT Il vit à Suresnes2
0 @19876@ INDI
1 NAME Pierre-Victurnien/VERGNIAUD/
2 GIVN Pierre-Victurnien
2 SURN VERGNIAUD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 NOV 2018
3 TIME 18:25
1 FAMS @19878@
1 BIRT
2 DATE 31 MAY 1753
2 PLAC Limoges,87000,Haute-Vienne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.831530
4 LONG E1.257800
2 _FNA NO
1 DEAT
2 DATE 31 OCT 1793
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
2 CAUS GUILLOTINE
2 NOTE condamné à mort par le Tribunal Révolutionnaire, fut exécuté avec les Girondins le 31 octobre 1793.
3 CONT Il laissa à sa fiancée une montre gravée à la conciergerie de sa main et conservée au musée de Besançon 23. Cette anecdote est rapportée par Lamartine dans son Histoire des Girondins, volume III, livre 47 chapitre XXIII p. 269 de l’édition de 1870/
3 CONC 71 :
3 CONT « Vergniaud tira sa montre, écrivit avec la pointe d’une épingle quelques initiales et la date du 30 octobre dans l’intérieur de la boîte d’or ; il glissa la montre dans la main de l’un des assistants (du bourreau) pour qu’on la remît à une jeune f
3 CONC ille qu’il aimait d’un amour de frère, et qu’il se proposait, dit-on, d’épouser plus tard ».
3 CONT Dans l’intérieur du boîtier de cette montre, conservée au musée de Besançon, on lit cette inscription, gravée dans les traits originaux de Vergniaud : « le 10 brum, An 2 1er 9 bre 1793. Vergniaud à Adèle ». Pierre Vallery-Radot, op.cit. p.194.
3 CONT Après l'exécution de Vergniaud avec les 21 Girondins, le 31 octobre 1793 (10 brumaire an II), son fidèle serviteur remit la montre à Adèle. Cette dernière, devenue madame Legouvé, la légua à monsieur de Jouy, membre de l'Académie française, qui en
3 CONC fit don à Charles Nodier, également académicien. A la mort de ce dernier la précieuse relique fut recueillie par sa fille madame Ménessier-Nodier qui l'offrit à son tour à Alexandre Bixio. C'est celui-ci qui en fit don au musée de Besançon. Le cons
3 CONC ervateur de ce musée autorisa le docteur Pierre Vallery-Radot à en faire une photographie. Le cliché est reproduit dans son ouvrage Chirurgiens d'autrefois.
3 CONT
3 CONT
3 CONT
1 NOTE Adélaïde Sauvan était fiancée à Pierre-Victurnien Vergniaud, l'avocat du parti des Girondins, guillotiné le 31 octobre 1793 à Paris. Le grand orateur de la Gironde lui légua sa montre sur laquelle il avait gravé, le matin de son exécution, leurs in
2 CONC itiales entrelacées.
2 CONT Charles Nodier hérita de la montre, qui la donna à Alexandre Bixio, la montre fut léguée au musée de Besançon, ville natale de Charles Nodier.
2 CONT
0 @19888@ INDI
1 NAME Marie-Joseph dit Eugène/SUE/
2 GIVN Marie-Joseph dit Eugène
2 SURN SUE
1 SEX M
1 OCCU Écrivain
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 NOV 2018
3 TIME 9:48
1 CHR
2 DATE 1804
2 _FNA NO
1 BIRT
2 DATE 26 JAN 1804
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
2 NOTE La date de naissance d’Eugène Sue varie selon ses biographes : le 17 janvier 1803 (selon Maurice Lachâtre), le 1er janvier 1803 (Alexandre Dumas), le 1er janvier 1801 (Eugène de Mirecourt), le 10 décembre 1804 (Paul Ginisty), le 8 février 1804 (Fra
3 CONC ncis Lacassin). Selon Jean-Louis Bory, Eugène Sue serait né le V pluviôse an XII de la République, soit le 26 janvier 1804, ce que confirme son acte de naissance conservé aux Archives de Paris. Dans une lettre inédite à son éditeur Maurice Lachâtre
3 CONC , Eugène Sue indique sa date de naissance en vue d'un article de dictionnaire. Il écrit : « Marie-Joseph-Eugène Suë né à Paris 17 Janvier 1804 an XII de la république ».
1 DEAT
2 DATE 3 AUG 1857
2 PLAC Annecy-le-Vieux,74940,Haute-Savoie,Auvergne-Rhône-Alpes,FRANCE,
3 MAP
4 LATI N45.919720
4 LONG E6.143930
2 _FNA NO
2 CAUS hémorragie cérébrale
2 NOTE mort en exil    (Duché de Savoie)C'est un autre proscrit républicain, le colonel Charras, qui assista à ses derniers instants et accomplit sa volonté d'être inhumé civilement « en libre-penseur ». Ses obsèques donnèrent lieu à un immense rassemblem
3 CONC ent, bien qu'elles aient eu lieu à six heures du matin, pour éviter tout rassemblement. Il fut enterré à Annecy, au cimetière de Loverchy, dans le carré des "dissidents" (non catholiques).
3 CONT
1 NOTE exerça  la médicine dans l’armée puis quitta le service en 1830 et se livra à la litérature
2 CONT Médecin dans la Marine, Sous-Chirurgien, Ecrivain, Romancier, Député, Membre fondateur du Jockey Club
1 FAMC @19933@
0 @19926@ INDI
1 NAME Marie-Sophie/TISON de RILLY/
2 GIVN Marie-Sophie
2 SURN TISON de RILLY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 NOV 2018
3 TIME 18:08
1 FAMS @19933@
1 BIRT
2 DATE 1775
2 _FNA NO
1 DEAT
2 DATE 14 FEB 1820
2 _FNA NO
0 @19938@ INDI
1 NAME Victorine/SUE/
2 GIVN Victorine
2 SURN SUE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 NOV 2018
3 TIME 11:27
1 BIRT
2 DATE 1810
2 _FNA NO
1 DEAT
2 DATE 1864
2 _FNA NO
0 @19952@ INDI
1 NAME Marie Anne/REDOT/
2 GIVN Marie Anne
2 SURN REDOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2018
3 TIME 11:32
1 FAMS @19955@
1 BIRT
2 DATE 1745
2 _FNA NO
1 DEAT
2 DATE 2 DEC 1804
2 PLAC Paris 09,,,,,
2 _FNA YES
2 NOTE OU 1826 ? ou 1808?
3 CONT selon Rosella GUIARD=«  Mon père [Dr Nicolas Guiard](né en 1789?) l'aimait passionnément et en avait gardé le plus grand souvenir. Il l'a perdue quand il avait 18 ans. « =1808
3 CONT
1 NOTE Anne Marie Redot fille d'un très habile serrurier d'art. Elle avait été lectrice de la princesse de Guéménée, puis avait eu un petit magasin de marchandises anglaises rue Saint-Honoré. La reine, les princesses, les grandes dames se fournissaient ch
2 CONC ez elle. C'était une femme de grande valeur morale.
1 FAMC @20283@
0 @19991@ INDI
1 NAME Flore/SUE/
2 GIVN Flore
2 SURN SUE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 8:06
1 FAMS @20030@
1 BIRT
2 DATE 1799
2 _FNA NO
2 NOTE Restée jusqu’à l’âge de neuf ans avec ma mère, et aimée comme une fille par mon père qu’elle adorait, elle fut brusquement, à la mort de ma mère, retirée de notre maison, et reléguée dans une petite institution du faubourg Saint-Antoine.
1 DEAT
2 DATE 1833
2 _FNA NO
1 NOTE =SOEUR d’Eugène Sue et demie-soeur d’ Ernest Legouvé qui ont donc la même sœur, mais ne sont pas parents
2 CONT Elle est l’arrière-arrière-arrière GM des Valléry-Radot de Seine-port
1 FAMC @19871@
0 @20010@ INDI
1 NAME Eugénie/de BEAUHARNAIS/
2 GIVN Eugénie
2 SURN de BEAUHARNAIS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 NOV 2018
3 TIME 12:18
0 @20014@ INDI
1 NAME Eugène/de BEAUHARNAIS/
2 GIVN Eugène
2 SURN de BEAUHARNAIS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 NOV 2018
3 TIME 12:19
0 @20022@ INDI
1 NAME Nicolas/GUIARD de LAGORGE/
2 GIVN Nicolas
2 SURN GUIARD de LAGORGE
1 SEX M
1 OCCU Médecin
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 8:04
1 FAMS @20030@
1 BIRT
2 DATE 14 JUL 1789
2 PLAC Avallon,89200,Yonne,Bourgogne-Franche-Comté,FRANCE,La Gorge
3 MAP
4 LATI N47.483330
4 LONG E3.900000
2 _FNA NO
2 NOTE La Gorge près d’ AVALLON
1 DEAT
2 DATE 18 MAR 1877
2 PLAC Paris 08,,,,,50 rue de miromesnil
2 _FNA NO
2 NOTE Faire-part de décès du Dr Guiard en 1877
3 CONT Mme Vallery-Radot (sa fille, veuve depuis l'année précédente de Vincent Félix), Monsieur et Madame Auguste Paultre (deuxième fille et gendre du défunt), Messieurs André, René et Maurice Vallery-Radot (ses petits-fils), Monsieur et Madame Ernest Pau
3 CONC ltre, M. Guillaume de Champs, capitaine..., chevalier de la Légion d'honneur, et Mme Guillaume de Champs, M. Antonin de Champs, Mme Dupetet de Brevon, M. Ernest Legouvé, de l'Académie française, officier de la Légion d'honneur (beau-frère du défunt
3 CONC )... Mme Guiard... Monsieur et Madame Émile Desvallières... Monsieur le docteur Robert Guiard (médecin militaire, participait à la mission Flatters, il fut massacré par les Touaregs en 1881)... Mesdemoiselles Lucile et Louise Sauvan, Mlle Jenny Sau
3 CONC van, Messieurs Maurice et Georges Desvallières (peintre, 1861-1950, arrière petit-fils de Gabriel Legouvé, ami de Gustave Moreau et de Maurice Denis, auteur des vitraux de l'Ossuaire de Douaumont), Mlle Georgina Desvallières.
3 CONT
1 NOTE Ses enfants et petits-enfants disaient qu’il était solide comme un chêne du Morvan, d’autres lui reprochaient son avarice.
2 CONT
1 FAMC @20952@
0 @20046@ INDI
1 NAME Rosella Marie-Rosalie/GUIARD/
2 GIVN Rosella Marie-Rosalie
2 SURN GUIARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 10:53
1 FAMS @20064@
1 BIRT
2 DATE 14 JAN 1826
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 11 FEB 1916
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @20030@
0 @20052@ INDI
1 NAME Vincent Félix Vallery/VALLERY-RADOT/
2 GIVN Vincent Félix Vallery
2 SURN VALLERY-RADOT
1 SEX M
1 OCCU Bibliothécaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 10:53
1 FAMS @20064@
1 BIRT
2 DATE 5 FEB 1814
2 PLAC Corbigny,58800,Nièvre,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.256780
4 LONG E3.682850
2 _FNA NO
1 CHR
2 DATE 10 FEB 1814
2 PLAC Avallon,89200,Yonne,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.483330
4 LONG E3.900000
2 _FNA NO
1 DEAT
2 DATE 31 AUG 1876
2 PLAC Avallon,89200,Yonne,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.483330
4 LONG E3.900000
2 _FNA NO
2 NOTE Vincent Félix Vallery mourut subitement dans l’escalier de l’hôtel Domecy place Saint-Lazare à Avallon, le 31 août 1876, il était en villégiature avec sa famille chez son père dans la maison toute proche de la rue de l’abbé Bocquillot.
3 CONT 31 août, mort subite de Vincent Félix à Avallon victime d’une rupture d’anévrisme.
3 CONT
3 CONT
1 BURI cimetière Montmartre
2 DATE 3 SEP 1876
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
2 NOTE  Son corps est ramené à Paris où il repose au cimetière Montmartre dans la tombe édifiée par son beau-père, le docteur Guiard, pour son épouse Flore Süe (1799-1833).
1 NOTE Bibliothécaire au Louvre
2 CONT après avoir été pensionnaire au collège Bourbon, actuel lycée Condorcet, se fixe à Paris au milieu du xixe siècle, devient bibliothécaire de la Bibliothèque royale du Louvre à la fin du règne de Louis-Philippe puis chef de cabinet de Charles de Fre
2 CONC ycinet, ministre de l'Agriculture et du Commerce, à la fin du Second Empire (1869). Il est promu cette même année officier de la Légion d'honneur et se fait connaître par des livres, essais et critiques littéraires.
2 CONT Le patronyme des Radot se transforme alors en Vallery-Radot (1852) et la famille s'intègre à la bourgeoisie parisienne dont les principaux représentants sont cités ci-dessous.
1 FAMC @20677@
0 @20074@ INDI
1 NAME René/VALLERY-RADOT/
2 GIVN René
2 SURN VALLERY-RADOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 15:30
1 FAMS @20093@
1 BIRT
2 DATE 1 OCT 1853
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 24 JAN 1933
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
2 NOTE  Décès à l'Hôpital Pasteur (Institut Pasteur), Paris.
1 NOTE  René Vallery-Radot, collaborateur au journal Le Temps et à la Revue des deux Mondes, secrétaire du Président du conseil de la IIIe République Charles de Freycinet et petit-neveu du romancier Eugène Sue et de Gabriel-Marie Legouvé, de l'Académie fr
2 CONC ançaise.
2 CONT 1879-1895 Aide Louis Pasteur à affronter ses détracteurs. Il prend des contacts avec la presse, et reçoit la mission de son beau-père de revoir lettres, réponses aux polémistes et discours. Veille sur la santé de ce dernier, et l'accompagne dans le
2 CONC s déplacements que lui imposent les nombreuses invitations envoyées de toutes les régions de France.
2 CONT Publie, en 1884, une biographie de L. Pasteur : Histoire d'un Savant par un ignorant. Participe à l'inauguration de l'Institut Pasteur, en 1888, ainsi qu'à la cérémonie du Jubilé à la Sorbonne, en 1892.
2 CONT 1895 A la mort de L. Pasteur, entreprend la rédaction d'une Vie de Pasteur (parue en 1900), traduite rapidement dans un grand nombre de pays.
2 CONT 1913 Après le décès de Mme Pasteur (survenu en 1910), publie un opuscule intitulé Madame Pasteur, où il trace le portrait de celle qui apparaît comme le modèle de la femme du savant.
2 CONT 1912 Edite, à tirage très limité, l'album Pasteur, dessinateur et pastelliste, où sont réunis des portraits de la mère, du père, des voisins, amis et parents de L. Pasteur.
2 CONT 1914-1918 Fonde la Société des amis des soldats aveugles, dont il devient le premier président.
2 CONT 1917-1933 Président du Conseil d'administration de l'Institut Pasteur (E. Roux en est alors le directeur et A. Calmette, le sous-directeur).
2 CONT 1922 Prend part aux manifestations célébrant le centième anniversaire de la naissance de L. Pasteur, à Paris, Dôle et Arbois.
2 CONT Fait paraître plusieurs ouvrages littéraires sur la Bourgogne, Mme de Sévigné, ainsi que des introductions à diverses publications : correspondance du Duc d'Aumale avec Cuvillier-Fleury, biographie de Planat de la Faye, biographie d'Hégésippe Morea
2 CONC u, souvenirs littéraires de son père, V.-F. Vallery-Radot.
2 CONT A la Société des Gens de lettres, veille au bon fonctionnement du Denier des veuves. Préside longtemps l'Office central des oeuvres de bienfaisance.
2 CONT Occupe les fonctions de commissaire aux comptes à la Société des amis des sciences.
1 FAMC @20064@
0 @20086@ INDI
1 NAME Marie-Louise/PASTEUR/
2 GIVN Marie-Louise
2 SURN PASTEUR
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 16:27
1 FAMS @20093@
1 BIRT
2 DATE 1858
2 _FNA NO
1 DEAT
2 DATE 9 SEP 1934
2 PLAC Marrault,89200,Yonne,,,
2 _FNA NO
1 FAMC @20115@
0 @20096@ INDI
1 NAME Louis/PASTEUR/
2 GIVN Louis
2 SURN PASTEUR
1 SEX M
1 OCCU Chimiste
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 16:12
1 FAMS @20115@
1 BIRT
2 DATE 27 DEC 1822
2 PLAC Dole,39100,Jura,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.100000
4 LONG E5.500000
2 _FNA NO
1 DEAT
2 DATE 28 SEP 1895
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 BURI
2 DATE 5 OCT 1895
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
2 NOTE FUNERAILLES NATIONALES
1 FAMC @31215@
0 @20108@ INDI
1 NAME Marie-Anne/LAURENT/
2 GIVN Marie-Anne
2 SURN LAURENT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 9:48
1 FAMS @20115@
1 BIRT
2 DATE 1826
2 _FNA NO
1 DEAT
2 DATE 1910
2 _FNA NO
0 @20118@ INDI
1 NAME Camille/VALLERY-RADOT/
2 GIVN Camille
2 SURN VALLERY-RADOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 NOV 2018
3 TIME 18:05
1 BIRT
2 DATE 17 JUL 1880
2 _FNA NO
1 DEAT
2 DATE 1927
2 _FNA NO
1 NOTE restée célibataire, est morte sans enfant
2 CONT
1 FAMC @20093@
0 @20126@ INDI
1 NAME Louis Joseph/PASTEUR VALLERY-RADOT/
2 GIVN Louis Joseph
2 SURN PASTEUR VALLERY-RADOT
1 SEX M
1 OCCU Médecin
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 9:37
1 FAMS @20209@
1 BIRT
2 DATE 13 MAY 1886
2 PLAC Paris 07,,,,,
2 _FNA NO
1 DEAT
2 DATE 9 OCT 1970
2 _FNA NO
2 CAUS ALZHEIMER
1 BURI
2 DATE 10 OCT 1970
2 PLAC Arbois,39600,Jura,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N46.903110
4 LONG E5.774540
2 _FNA NO
1 NOTE médecin et homme politique français, biographe de son grand-père Louis Pasteur et éditeur de ses œuvres complètes. Il est inhumé à Arbois (Jura).
2 CONT Il était petit-fils et petit neveu d'académiciens, Louis Pasteur et Ernest Legouvé.
2 CONT
1 FAMC @20093@
0 @20146@ INDI
1 NAME André Ernest/VALLERY-RADOT/
2 GIVN André Ernest
2 SURN VALLERY-RADOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 NOV 2018
3 TIME 20:33
1 FAMS @20172@
1 BIRT
2 DATE 1851
2 _FNA NO
1 DEAT
2 DATE 6 MAY 1922
2 PLAC Avallon,89200,Yonne,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.483330
4 LONG E3.900000
2 _FNA NO
2 NOTE La tombe 31 où reposent André Ernest Vallery-Radot mort le 6 mai 1922 et son épouse née François a été acquise le 10 mai 1922 par Georges et Robert Vallery-Radot. La concession est expirée depuis 1952 mais ne peut être réaffectée compte tenu de son
3 CONC  enclavement entre les deux précédentes.
3 CONT
1 BURI
2 DATE 10 MAY 1922
2 PLAC Avallon,89200,Yonne,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.483330
4 LONG E3.900000
2 _FNA NO
2 NOTE La tombe 31 où reposent André Ernest Vallery-Radot mort le 6 mai 1922 et son épouse née François a été acquise le 10 mai 1922 par Georges et Robert Vallery-Radot. La concession est expirée depuis 1952 mais ne peut être réaffectée compte tenu de son
3 CONC  enclavement entre les deux précédentes.
3 CONT
1 NOTE parait avoir été un personnage difficile au caractère ombrageux. Ce tempérament peut avoir pour origine le fait que ses parents l'avaient moins aimé que ses deux cadets, René et Maurice.
2 CONT Une brouille plus ancienne et plus terrible avait séparé André de sa mère qui habitait tout près de chez lui, 50 rue de Miromesnil. Ils ne se voyaient jamais. On ne connait pas le motif de cette brouille dont le point culminant fut une violente alt
2 CONC ercation entre la mère et son fils aîné à Marault. André quitta sa mère en lui disant : « Vous ne me reverrez jamais » et il tint parole. La brouille se répercuta aux enfants et la branche André ne saluait pas les branches René et Maurice à la sort
2 CONC ie de la messe les dimanches d’été à Saint-Lazare d’Avallon.
2 CONT On sait peu de chose de la vie d’André hormis qu’il vécut en rentier et que, non content de ne plus voir sa mère, il se brouilla avec son fils Robert qui était interdit de séjour chez lui. Le mariage de Robert avec Paule Dordet en 1909 en était la
2 CONC cause
2 CONT
2 CONT
1 FAMC @20064@
0 @20157@ INDI
1 NAME Maurice/VALLERY-RADOT/
2 GIVN Maurice
2 SURN VALLERY-RADOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 NOV 2018
3 TIME 18:15
1 FAMS @20182@
1 BIRT
2 DATE 1860
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 29 APR 1898
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,17 AVENUE CARNOT
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
2 CAUS CRISE CARDIAQUE
1 NOTE inspecteur des théâtres au ministère de l’Instruction Publique et des Beaux-arts
2 CONT La vie de Maurice avait coûté cher en croquant allègrement en dix ans de mariage la confortable dot de sa femme ainsi qu’une avance d’hoirie loin d’être négligeable obtenue de sa mère 76. Contrairement à son oncle Eugène Süe, aussi prodigue, il n’e
2 CONC ut pas la ressource des Mystères de Paris pour boucher le trou...
2 CONT Maurice Vallery-Radot acheva de se ruiner par des investissements dans des mines aux îles Cayo Cruz et Cayo Romano sur la côte nord de Cuba. Si ces îles existent, les mines sortaient de l’imagination des escrocs qui les vendirent à un crédule.
2 CONT
1 FAMC @20064@
0 @20168@ INDI
1 NAME Marie/FRANÇOIS/
2 GIVN Marie
2 SURN FRANÇOIS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 7:46
1 FAMS @20172@
1 BIRT
2 DATE 1860
2 _FNA NO
1 DEAT
2 DATE 1907
2 _FNA NO
1 FAMC @31059@
0 @20175@ INDI
1 NAME Alice/ANDRY/
2 GIVN Alice
2 SURN ANDRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 10:58
1 FAMS @20182@
1 BIRT
2 DATE 1864
2 _FNA NO
1 DEAT
2 DATE 1942
2 _FNA NO
1 FAMC @21580@
0 @20205@ INDI
1 NAME Jacqueline/GOHIERRE de LONGCHAMPS/
2 GIVN Jacqueline
2 SURN GOHIERRE de LONGCHAMPS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 9:34
1 FAMS @20209@
1 DEAT
2 DATE 1986
2 _FNA NO
0 @20215@ INDI
1 NAME Marie Edmée Françoise Rosella/de MILHAU/
2 GIVN Marie Edmée Françoise Rosella
2 SURN de MILHAU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 NOV 2018
3 TIME 17:47
1 FAMS @20220@
0 @20223@ INDI
1 NAME Joseph Dieudonné/SUE/
2 GIVN Joseph Dieudonné
2 SURN SUE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 NOV 2018
3 TIME 17:47
1 BIRT
2 DATE 1823
2 _FNA NO
1 FAMC @20220@
0 @20242@ INDI
1 NAME Victorine/SUE/
2 GIVN Victorine
2 SURN SUE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 NOV 2018
3 TIME 8:18
1 FAMS @20254@
1 BIRT
2 DATE 1810
2 _FNA YES
1 NOTE Victorine Süe, sœur cadette d’Eugène Süe,
1 FAMC @19933@
0 @20252@ INDI
1 NAME Marc/CAILLARD/
2 GIVN Marc
2 SURN CAILLARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 NOV 2018
3 TIME 8:17
1 FAMS @20254@
0 @20279@ INDI
1 NAME ?/REDOT/
2 GIVN ?
2 SURN REDOT
1 SEX M
1 OCCU serrurier d'art
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 NOV 2018
3 TIME 9:01
1 FAMS @20283@
0 @20295@ INDI
1 NAME Jean-Baptiste Balthasar/SAUVAN/
2 GIVN Jean-Baptiste Balthasar
2 SURN SAUVAN
1 SEX M
1 OCCU historien
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 NOV 2018
3 TIME 18:56
1 BIRT
2 DATE 1778
2 _FNA NO
1 FAMC @19955@
0 @20307@ INDI
1 NAME Emile/SAUVAN/
2 GIVN Emile
2 SURN SAUVAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 NOV 2018
3 TIME 9:58
1 BIRT
2 DATE 1779
2 _FNA NO
1 DEAT
2 DATE 1780
2 _FNA NO
1 FAMC @19955@
0 @20318@ INDI
1 NAME Philippe/SAUVAN/
2 GIVN Philippe
2 SURN SAUVAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 NOV 2018
3 TIME 10:00
1 BIRT
2 DATE 1781
2 _FNA NO
1 FAMC @19955@
0 @20326@ INDI
1 NAME Xy/SAUVAN/
2 GIVN Xy
2 SURN SAUVAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 NOV 2018
3 TIME 10:01
1 BIRT
2 DATE 1782
2 _FNA NO
1 FAMC @19955@
0 @20349@ INDI
1 NAME Xy/SAUVAN/
2 GIVN Xy
2 SURN SAUVAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 NOV 2018
3 TIME 12:47
1 BIRT
2 DATE 1774
2 _FNA NO
1 FAMC @19955@
0 @20427@ INDI
1 NAME Georges/VALLERY-RADOT/
2 GIVN Georges
2 SURN VALLERY-RADOT
1 SEX M
1 OCCU Écrivain rentier
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 NOV 2018
3 TIME 20:47
1 BIRT
2 DATE 1883
2 _FNA NO
2 NOTE la chance de côtoyer la personnalité exceptionnelle de son cadet, Robert, auprès duquel il vécut, célibataire, une partie de sa vie.
3 CONT
1 DEAT
2 DATE 1962
2 PLAC Seine-Port,77240,Seine-et-Marne,Île-de-France,FRANCE,
3 MAP
4 LATI N48.557430
4 LONG E2.553160
2 _FNA NO
1 NOTE Cultivé et raffiné, Georges ne publia qu’un seul livre, Le Reliquaire d’Images. Il avait 28 ans. Poème en prose, intimiste et délicat, cet ouvrage fait découvrir un homme d’une grande sensibilité au style soigné et dépouillé. Sa mère est souvent év
2 CONC oquée mais il ne dit pas un mot de son père.
1 FAMC @20172@
0 @20432@ INDI
1 NAME Robert/VALLERY-RADOT/
2 GIVN Robert
2 SURN VALLERY-RADOT
2 NICK irénée
1 SEX M
1 OCCU Écrivain Journaliste Prêtre
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 7:36
1 FAMS @21038@
1 BIRT
2 DATE 31 JUL 1885
2 PLAC Avallon,89200,Yonne,Bourgogne-Franche-Comté,FRANCE,Les Alleux
3 MAP
4 LATI N47.483330
4 LONG E3.900000
2 _FNA NO
1 DEAT
2 DATE 23 FEB 1970
2 PLAC Bricquebec,50260,Manche,Normandie,FRANCE,
3 MAP
4 LATI N49.470080
4 LONG W1.632540
2 _FNA NO
1 BURI
2 DATE 25 FEB 1970
2 PLAC Bricquebec,50260,Manche,Normandie,FRANCE,
3 MAP
4 LATI N49.470080
4 LONG W1.632540
2 _FNA NO
2 NOTE enterré dans le cimetière conventuel
3 CONT
1 NOTE Poète, ardent catholique, homme de lettres ami des plus grands, héros de la Première Guerre mondiale, polémiste, engagé politique, conférencier enflammé, banni et condamné, réhabilité, il termine sa vie en fils de Saint Bernard, moine cistercien à
2 CONC Bricquebec, après avoir été marié et élevé cinq enfants dont un fils prêtre et une fille moniale.
2 CONT La vie de Robert fut jalonnée de grandes amitiés. Parmi les grands de la littérature il y eut d’abord François Mauriac, puis Georges Bernanos que Robert lança en faisant publier Sous le Soleil de Satan.
2 CONT
1 FAMC @20172@
0 @20437@ INDI
1 NAME Jacques/VALLERY-RADOT/
2 GIVN Jacques
2 SURN VALLERY-RADOT
1 SEX M
1 OCCU Artiste Peintre
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 9:32
1 FAMS @20515@
1 FAMS @31124@
1 BIRT l'échauguette
2 DATE 8 OCT 1910
2 PLAC Avallon,89200,Yonne,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.483330
4 LONG E3.900000
2 _FNA NO
1 BURI
2 DATE JUL 2001
2 PLAC Seine-Port,77240,Seine-et-Marne,Île-de-France,FRANCE,
3 MAP
4 LATI N48.557430
4 LONG E2.553160
2 _FNA NO
1 DEAT
2 DATE 29 JUL 2001
2 PLAC Seine-Port,77240,Seine-et-Marne,Île-de-France,FRANCE,
3 MAP
4 LATI N48.557430
4 LONG E2.553160
2 _FNA NO
1 NOTE Après cinq ans d’études à l’atelier de Maurice Denis et de Georges Desvallières (membre de l’Académie des beaux-arts), il expose pour la première fois ses tableaux, principalement des huiles, à la galerie de Paris en 1934, il a alors 24 ans. Les ex
2 CONC positions vont se succéder par la suite à la galerie Castel, à la galerie Charpentier en 1946 et dans les années cinquante dans différentes galeries, Framont, rue des Saints Pères, Le Chapelain, rue du Faubourg Saint Honoré. Depuis 1983, il exposai
2 CONC t périodiquement ses œuvres à la galerie Denise Valtat, rue de la Boétie à Paris.
1 FAMC @21038@
0 @20442@ INDI
1 NAME François/VALLERY-RADOT/
2 GIVN François
2 SURN VALLERY-RADOT
1 SEX M
1 OCCU prêtre
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 7:36
1 BIRT
2 DATE 1911
2 PLAC Avallon,89200,Yonne,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.483330
4 LONG E3.900000
2 _FNA NO
1 DEAT
2 DATE 23 APR 2001
2 _FNA NO
1 NOTE père du Saint Esprit
2 CONT François Vallery-Radot passa deux ans au séminaire français de Rome peu de temps après la condamnation de l’Action Française, ce qui lui valut d’être en froid avec la hiérarchie compte tenu des positions de son père. Entré dans la congrégation des
2 CONC Pères du Saint- Esprit (c.s.sp. 59), il est ordonné avant la guerre dans la chapelle de Chevilly-Larue. Professeur de lettres classiques au collège Albert de Mun après la guerre, puis au Gabon et enfin au petit séminaire de la Drôme à Alès, il est
2 CONC enterré dans le cimetière du séminaire à Chevilly- Larue.
2 CONT
1 FAMC @21038@
0 @20446@ INDI
1 NAME Pierre/VALLERY-RADOT/
2 GIVN Pierre
2 SURN VALLERY-RADOT
1 SEX M
1 OCCU Médecin Pédiatre
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 11:22
1 FAMS @21326@
1 BIRT
2 DATE 1889
2 _FNA NO
1 DEAT
2 DATE 1975
2 _FNA NO
1 NOTE Interne des hôpitaux, chef de clinique à la faculté, il laisse le souvenir d’un médecin au diagnostic très sûr et d’un homme à l’humour caustique. Historien de la médecine, il a publié articles et ouvrages qui font autorité.
2 CONT
1 FAMC @20182@
0 @20451@ INDI
1 NAME Jean/VALLERY-RADOT/
2 GIVN Jean
2 SURN VALLERY-RADOT
1 SEX M
1 OCCU historien archéologue
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 12:46
1 FAMS @20467@
1 FAMS @21329@
1 BIRT
2 DATE 25 MAY 1890
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 9 NOV 1971
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 BURI
2 DATE 15 NOV 1971
2 PLAC Joigny,89300,Yonne,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.982880
4 LONG E3.400960
2 _FNA NO
1 NOTE Conservateur en chef du cabinet des Estampes à la Bibliothèque nationale = le 18ème à avoir exercé cette fonction de 1942 à 1961.
2 CONT
1 FAMC @20182@
0 @20456@ INDI
1 NAME Claude/VALLERY-RADOT/
2 GIVN Claude
2 SURN VALLERY-RADOT
1 SEX M
1 OCCU Médecin Allergologue
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 MAY 2019
3 TIME 18:33
1 FAMS @21633@
1 BIRT
2 DATE 22 JAN 1925
2 _FNA NO
1 DEAT
2 DATE 23 MAR 2019
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 NOTE a soigné le vice-président d'Irak, Sadam Hussein en1972
2 CONT
1 FAMC @21326@
0 @20461@ INDI
1 NAME Guy/VALLERY-RADOT/
2 GIVN Guy
2 SURN VALLERY-RADOT
1 SEX M
1 OCCU Commissaire de bord
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 11:57
1 FAMS @21777@
1 CHR
2 DATE 1932
2 _FNA NO
1 BIRT
2 DATE 12 FEB 1932
2 _FNA NO
1 FAMC @21326@
0 @20465@ INDI
1 NAME Maurice/VALLERY-RADOT/
2 GIVN Maurice
2 SURN VALLERY-RADOT
1 SEX M
1 OCCU conseiller d'Etat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 16:14
1 FAMS @21414@
1 BIRT
2 DATE 11 MAY 1919
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 BURI
2 DATE 2006
2 PLAC Joigny,89300,Yonne,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.982880
4 LONG E3.400960
2 _FNA NO
1 DEAT
2 DATE 19 MAY 2006
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 NOTE conseiller d'Etat, participant aux cabinets de plusieurs ministres de premier plan dont Edmond Michelet, Georges Bidault, André Colin et Paul Coste-Floret.
2 CONT président de l’Œuvre d’Orient et président de l’Académie d’agriculture
2 CONT
1 FAMC @20467@
0 @20470@ INDI
1 NAME Philippe/VALLERY-RADOT/
2 GIVN Philippe
2 SURN VALLERY-RADOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:24
1 FAMS @22649@
1 BIRT
2 DATE 4 AUG 1922
2 _FNA NO
1 DEAT
2 DATE 1987
2 _FNA NO
1 BURI
2 DATE 1987
2 PLAC Vaux,03190,Allier,Auvergne-Rhône-Alpes,FRANCE,
3 MAP
4 LATI N46.426480
4 LONG E2.598670
2 _FNA NO
1 NOTE sa vie. Il la consacra à restaurer trois immeubles exceptionnels à Joigny, Ligny-le-Chatel et Vaux. Plus inattendu fut l’intérêt qu’il porta à la statue de la Liberté à New-York dont il initia la remise en état. En marque de reconnaissance Ronald R
2 CONC eagan, président des Etats-Unis, le reçut à la Maison Blanche.
2 CONT Il possédait à Joigny la maison du bailli qui avait appartenu au début du XXe siècle à son grand oncle, l’abbé Pierre Vignot. Il fit l’acquisition en 1960 du château de Maulnes, près de Ligny-le-Chatel dans l’Yonne, à l’état de presque ruine. Il co
2 CONC ntribua à le sauver grâce à une subvention obtenue d’André Malraux, ministre de la Culture, rencontré en personne. Sa qualité de filleul de Louis Pasteur Vallery- Radot facilita sans doute les choses. Quant au château de Vaux dans l’Aube, il en res
2 CONC taura les communs et fut inhumé dans le parc en 1987.
2 CONT
1 FAMC @20467@
0 @20474@ INDI
1 NAME Marie/VALLERY-RADOT/
2 GIVN Marie
2 SURN VALLERY-RADOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 7:35
1 FAMS @20491@
1 BIRT
2 DATE 1913
2 _FNA NO
1 DEAT
2 DATE 2002
2 _FNA NO
1 BURI
2 DATE 2002
2 PLAC Avallon,89200,Yonne,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.483330
4 LONG E3.900000
2 _FNA NO
2 NOTE La tombe 32 (carré 1 du cimetière d'Avallon) où reposent Vincent et Lazare-André a été acquise le 5 décembre 1845, année de la mort de Vincent. La concession a été renouvelée les 30 septembre 1876, 8 novembre 1906, 1er octobre 1937 et 11 mars 1966,
3 CONC  à cette dernière date jusqu'en 2025 par Marie Vallery-Radot épouse de Max Brusset (source : registre du cimetière d'Avallon). La tombe 31 où reposent André Ernest Vallery-Radot mort le 6 mai 1922 et son épouse née François a été acquise le 10 mai
3 CONC 1922 par Georges et Robert Vallery-Radot. La concession est expirée depuis 1952 mais ne peut être réaffectée compte tenu de son enclavement entre les deux précédentes.
3 CONT La branche Eugène Radot (frère cadet de Vincent Félix Vallery), aujourd'hui éteinte, est située dans le carré 5 non loin de la chapelle Guiard où repose Christiane Manificat. Le long de cette chapelle se trouve la tombe de Marie Vallery-Radot (1913
3 CONC -2002), fille de Robert, et de son époux Max Brusset (1909-1992).
3 CONT
1 FAMC @21038@
0 @20484@ INDI
1 NAME Max/BRUSSET/
2 GIVN Max
2 SURN BRUSSET
1 SEX M
1 OCCU Député
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 NOV 2018
3 TIME 12:51
1 FAMS @20491@
1 BIRT
2 DATE 1909
2 _FNA NO
1 DEAT
2 DATE 1992
2 _FNA NO
1 NOTE directeur de cabinet de Georges Mandel, député et maire de Royan, propriétaire du château des Alleux à Avallon vendu par ses enfants en 2006
2 CONT
0 @20513@ INDI
1 NAME Jeanine/GUEPRATTE/
2 GIVN Jeanine
2 SURN GUEPRATTE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 11:58
1 FAMS @20515@
1 DEAT
2 DATE 1983
2 PLAC Seine-Port,77240,Seine-et-Marne,Île-de-France,FRANCE,
3 MAP
4 LATI N48.557430
4 LONG E2.553160
2 _FNA NO
0 @20516@ INDI
1 NAME Pascal/VALLERY-RADOT/
2 GIVN Pascal
2 SURN VALLERY-RADOT
1 SEX M
1 OCCU Chargé de mission au CSA
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 12:05
1 FAMS @21421@
1 BIRT
2 DATE 29 JUL 1951
2 _FNA NO
1 FAMC @20515@
0 @20521@ INDI
1 NAME Anne/VALLERY-RADOT/
2 GIVN Anne
2 SURN VALLERY-RADOT
1 SEX F
1 OCCU MODE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 12:06
1 FAMS @21446@
1 BIRT
2 DATE 3 SEP 1952
2 _FNA NO
1 FAMC @20515@
0 @20526@ INDI
1 NAME Bernard/VALLERY-RADOT/
2 GIVN Bernard
2 SURN VALLERY-RADOT
1 SEX M
1 OCCU GALERISTE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 12:11
1 FAMS @31155@
1 BIRT
2 DATE 12 NOV 1953
2 _FNA NO
1 DEAT
2 DATE 2002
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 NOTE galeries de tableaux à Beaumont en Auge et à Vallauris 
1 FAMC @20515@
0 @20531@ INDI
1 NAME Benoît/VALLERY-RADOT/
2 GIVN Benoît
2 SURN VALLERY-RADOT
1 SEX M
1 OCCU poete chanteur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 9:20
1 FAMS @31165@
1 BIRT CANCER
2 DATE 2 DEC 1954
2 _FNA NO
1 DEAT
2 DATE SEP 1994
2 _FNA NO
1 BURI
2 DATE 7 SEP 1994
2 _FNA NO
1 FAMC @20515@
0 @20536@ INDI
1 NAME Claire/VALLERY-RADOT/
2 GIVN Claire
2 SURN VALLERY-RADOT
1 SEX F
1 OCCU artiste peintre
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 12:14
1 FAMS @21460@
1 BIRT
2 DATE 25 JAN 1957
2 _FNA NO
1 FAMC @20515@
0 @20541@ INDI
1 NAME François/VALLERY-RADOT/
2 GIVN François
2 SURN VALLERY-RADOT
1 SEX M
1 OCCU libraire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 12:28
1 BIRT
2 DATE 17 MAR 1958
2 _FNA NO
1 FAMC @20515@
0 @20546@ INDI
1 NAME Sabine/VALLERY-RADOT/
2 GIVN Sabine
2 SURN VALLERY-RADOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 11:57
1 FAMS @21471@
1 BIRT
2 DATE 3 JUL 1959
2 PLAC Avallon,89200,Yonne,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.483330
4 LONG E3.900000
2 _FNA NO
1 FAMC @20515@
0 @20568@ INDI
1 NAME Maurice/DENIS/
2 GIVN Maurice
2 SURN DENIS
1 SEX M
1 OCCU Artiste Peintre
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 NOV 2018
3 TIME 7:51
0 @20618@ INDI
1 NAME Lazare-André Jean/RADOT/
2 GIVN Lazare-André Jean
2 SURN RADOT
1 SEX M
1 OCCU avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 6:57
1 FAMS @20677@
1 BIRT
2 DATE 1787
2 PLAC Corbigny,58800,Nièvre,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.256780
4 LONG E3.682850
2 _FNA NO
1 DEAT
2 DATE 30 JUN 1878
2 PLAC Avallon,89200,Yonne,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.483330
4 LONG E3.900000
2 _FNA NO
1 BURI
2 DATE 1 JUL 1878
2 PLAC Avallon,89200,Yonne,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.483330
4 LONG E3.900000
2 _FNA NO
2 NOTE tombe 32
3 CONT La tombe 32 (carré 1 du cimetière d'Avallon) où reposent Vincent et Lazare-André a été acquise le 5 décembre 1845, année de la mort de Vincent. La concession a été renouvelée les 30 septembre 1876, 8 novembre 1906, 1er octobre 1937 et 11 mars 1966,
3 CONC  à cette dernière date jusqu'en 2025 par Marie Vallery-Radot épouse de Max Brusset
3 CONT
1 FAMC @20707@
0 @20636@ INDI
1 NAME Eugène/RADOT/
2 GIVN Eugène
2 SURN RADOT
1 SEX M
1 OCCU avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 NOV 2018
3 TIME 10:44
1 FAMS @20666@
1 BIRT
2 DATE 30 JAN 1819
2 _FNA NO
1 DEAT
2 DATE 1897
2 _FNA NO
1 FAMC @20677@
0 @20655@ INDI
1 NAME Anna Françoise/PERREVE/
2 GIVN Anna Françoise
2 SURN PERREVE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 NOV 2018
3 TIME 10:33
1 FAMS @20666@
1 BIRT
2 DATE 1829
2 _FNA NO
1 DEAT
2 DATE 1914
2 _FNA NO
0 @20670@ INDI
1 NAME Sophie/HOUDAILLE/
2 GIVN Sophie
2 SURN HOUDAILLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 6:57
1 FAMS @20677@
1 BIRT
2 DATE 6 FEB 1792
2 PLAC Avallon,89200,Yonne,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.483330
4 LONG E3.900000
2 _FNA NO
1 DEAT
2 DATE 12 APR 1873
2 _FNA NO
1 BURI
2 DATE 15 APR 1873
2 PLAC Avallon,89200,Yonne,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.483330
4 LONG E3.900000
2 _FNA NO
2 NOTE tombe 30
3 CONT La tombe 30 où repose notamment Sophie Houdaille a été acquise par René Vallery-Radot le 3 octobre 1876. La concession étant expirée depuis 1936, elle a été renouvelée par Laurent et Vincent Vallery-Radot le 18 octobre 2013 jusqu'en 2026. Le docteu
3 CONC r Claude Vallery-Radot a tenu également à participer aux frais.
3 CONT
1 FAMC @20788@
0 @20691@ INDI
1 NAME Vincent/RADOT/
2 GIVN Vincent
2 SURN RADOT
1 SEX M
1 OCCU notaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 NOV 2018
3 TIME 11:15
1 FAMS @20707@
1 BIRT
2 DATE 18 MAR 1758
2 PLAC Michaugues,58420,Nièvre,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.267980
4 LONG E3.520830
2 _FNA NO
1 DEAT
2 DATE 16 JUL 1845
2 PLAC Avallon,89200,Yonne,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.483330
4 LONG E3.900000
2 _FNA NO
0 @20703@ INDI
1 NAME Michelle/BAUMIER/
2 GIVN Michelle
2 SURN BAUMIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 NOV 2018
3 TIME 11:10
1 FAMS @20707@
1 DEAT
2 DATE 29 SEP 1822
2 PLAC Corbigny,58800,Nièvre,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.256780
4 LONG E3.682850
2 _FNA NO
0 @20713@ INDI
1 NAME Anne/RADOT/
2 GIVN Anne
2 SURN RADOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 NOV 2018
3 TIME 11:11
1 BIRT
2 DATE 1783
2 _FNA NO
1 DEAT
2 DATE 1835
2 PLAC Corbigny,58800,Nièvre,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.256780
4 LONG E3.682850
2 _FNA NO
2 NOTE morte célibataire à 52 ans. Celle-ci, jusqu’à son décès, habitait avec son père dans la maison familiale de Corbigny.
3 CONT
1 FAMC @20707@
0 @20747@ INDI
1 NAME Vallery/HOUDAILLE/
2 GIVN Vallery
2 SURN HOUDAILLE
1 SEX M
1 OCCU notaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 NOV 2018
3 TIME 12:10
1 BIRT
2 DATE 1786
2 _FNA NO
1 DEAT
2 DATE 1841
2 PLAC Avallon,89200,Yonne,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.483330
4 LONG E3.900000
2 _FNA NO
1 FAMC @20788@
0 @20759@ INDI
1 NAME Nicolas Marie/HOUDAILLE/
2 GIVN Nicolas Marie
2 SURN HOUDAILLE
1 SEX M
1 OCCU notaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 6:52
1 FAMS @20788@
1 BIRT
2 DATE 16 MAR 1757
2 _FNA NO
1 DEAT
2 DATE 1848
2 PLAC Avallon,89200,Yonne,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.483330
4 LONG E3.900000
2 _FNA NO
1 NOTE Elisabeth Dordet, épouse d’Eugène Manificat, écrit de lui : « Marie-Nicolas Houdaille naquit à Avallon le 16 mars 1757 de Pierre Houdaille et de Marie- Françoise Maillot. Il fut reçu au Parlement de Dijon et épousa la fille d’un de ses confrères qu
2 CONC i habitait Avallon, Elisabeth Chargrasse.
1 FAMC @31004@
0 @20786@ INDI
1 NAME Elisabeth/CHARGRASSE/
2 GIVN Elisabeth
2 SURN CHARGRASSE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 NOV 2018
3 TIME 12:23
1 FAMS @20788@
0 @20800@ INDI
1 NAME Honorine/HOUDAILLE/
2 GIVN Honorine
2 SURN HOUDAILLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 7:23
1 FAMS @20808@
1 FAMS @31010@
1 FAMC @20788@
0 @20806@ INDI
1 NAME Claude/GUIARD/
2 GIVN Claude
2 SURN GUIARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 7:23
1 FAMS @20808@
0 @20826@ INDI
1 NAME Léon/DORDET/
2 GIVN Léon
2 SURN DORDET
1 SEX M
1 OCCU militaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 8:14
1 FAMS @21024@
1 BIRT
2 DATE 1849
2 _FNA NO
1 DEAT
2 DATE 1902
2 _FNA NO
0 @20830@ INDI
1 NAME Élisabeth/DORDET/
2 GIVN Élisabeth
2 SURN DORDET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 7:08
1 FAMS @20838@
1 FAMC @21024@
0 @20836@ INDI
1 NAME Eugène/MANIFICAT/
2 GIVN Eugène
2 SURN MANIFICAT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 NOV 2018
3 TIME 12:31
1 FAMS @20838@
0 @20840@ INDI
1 NAME Christiane/MANIFICAT/
2 GIVN Christiane
2 SURN MANIFICAT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2018
3 TIME 10:35
1 BIRT
2 DATE 1908
2 _FNA NO
1 DEAT
2 DATE 27 AUG 2010
2 PLAC Avallon,89200,Yonne,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.483330
4 LONG E3.900000
2 _FNA NO
1 NOTE célibataire, l'élégie de Bernanos avec qui elle eut un grave accident de moto.
2 CONT
1 FAMC @20838@
0 @20850@ INDI
1 NAME Suzanne/MANIFICAT/
2 GIVN Suzanne
2 SURN MANIFICAT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2018
3 TIME 10:29
1 FAMS @20858@
1 FAMC @20838@
0 @20856@ INDI
1 NAME Gérard/ESCARFAIL/
2 GIVN Gérard
2 SURN ESCARFAIL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2018
3 TIME 10:29
1 FAMS @20858@
0 @20883@ INDI
1 NAME Laurent/de GOUVION SAINT-CYR/
2 GIVN Laurent
2 SURN de GOUVION SAINT-CYR
1 SEX M
1 OCCU Politicien
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 NOV 2018
3 TIME 15:27
0 @20900@ INDI
1 NAME Ernestine/GUIARD/
2 GIVN Ernestine
2 SURN GUIARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 NOV 2018
3 TIME 12:44
1 FAMS @20910@
1 FAMC @20030@
0 @20906@ INDI
1 NAME Auguste/PAULTRE/
2 GIVN Auguste
2 SURN PAULTRE
1 SEX M
1 OCCU Agent de change
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 NOV 2018
3 TIME 12:44
1 FAMS @20910@
1 FAMC @21202@
0 @20923@ INDI
1 NAME Charles/de FREYCINET/
2 GIVN Charles
2 SURN de FREYCINET
1 SEX M
1 OCCU président du conseil
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 NOV 2018
3 TIME 19:19
0 @20932@ INDI
1 NAME Simon/GUIARD de LAGORGE/
2 GIVN Simon
2 SURN GUIARD de LAGORGE
1 SEX M
1 OCCU Juge
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2018
3 TIME 11:36
1 FAMS @20952@
1 BIRT
2 DATE 1759
2 _FNA NO
1 DEAT
2 DATE 1843
2 _FNA NO
1 NOTE « Simon Guiard juge pendant 46 ans à Quarré-les-Tombes, père de Nicolas Guiard qui acheta Marault ».
2 CONT
0 @20950@ INDI
1 NAME Madeleine/HOLLIER/
2 GIVN Madeleine
2 SURN HOLLIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2018
3 TIME 10:37
1 FAMS @20952@
1 FAMC @21087@
0 @20956@ INDI
1 NAME Claude Marie/GUIARD de LAGORGE/
2 GIVN Claude Marie
2 SURN GUIARD de LAGORGE
1 SEX M
1 OCCU Avoué
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 7:00
1 FAMS @31010@
1 BIRT
2 DATE 1785
2 _FNA NO
1 DEAT
2 DATE 1858
2 _FNA NO
1 FAMC @20952@
0 @20975@ INDI
1 NAME Pierrette Elisabeth Betsy/GUIARD de LAGORGE/
2 GIVN Pierrette Elisabeth Betsy
2 SURN GUIARD de LAGORGE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 7:00
1 FAMS @20998@
1 BIRT
2 DATE 1828
2 _FNA NO
1 FAMC @31010@
0 @20990@ INDI
1 NAME François Evariste Eugène/RAYNAUD/
2 GIVN François Evariste Eugène
2 SURN RAYNAUD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 NOV 2018
3 TIME 19:55
1 FAMS @20998@
1 BIRT
2 DATE 1819
2 _FNA NO
0 @21000@ INDI
1 NAME Marie Noémie Thérèse/RAYNAUD/
2 GIVN Marie Noémie Thérèse
2 SURN RAYNAUD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 7:14
1 FAMS @21024@
1 BIRT
2 DATE 1854
2 _FNA NO
1 DEAT
2 DATE 1928
2 _FNA NO
1 FAMC @20998@
0 @21033@ INDI
1 NAME Paule/DORDET/
2 GIVN Paule
2 SURN DORDET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 8:10
1 FAMS @21038@
1 DEAT
2 DATE 30 DEC 1940
2 PLAC Caen,14000,Calvados,Normandie,FRANCE,
3 MAP
4 LATI N49.185850
4 LONG W0.359120
2 _FNA NO
1 NOTE Robert Vallery-Radot avait épousé Paule Dordet le 19 Octobre 1909 qui descendait comme lui de Simon Guiard de Lagorge, juge de paix à Quarré les Tombes. La grand-mère de Paule était la sœur de Rosella Guiard, grand-mère de Robert. Epousant son cous
2 CONC in issu de germain, une dispense fut, à l'époque, nécessaire.
2 CONT
1 FAMC @21024@
0 @21039@ INDI
1 NAME Hélène/DORDET/
2 GIVN Hélène
2 SURN DORDET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 7:14
1 FAMS @21057@
1 BIRT
2 DATE 1890
2 _FNA NO
1 DEAT
2 DATE 1957
2 _FNA NO
1 FAMC @21024@
0 @21050@ INDI
1 NAME Pierre/ESCUDIER/
2 GIVN Pierre
2 SURN ESCUDIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 7:52
1 FAMS @21057@
1 BIRT
2 DATE 1883
2 _FNA NO
1 DEAT
2 DATE 1956
2 _FNA NO
1 FAMC @31092@
0 @21062@ INDI
1 NAME Élisabeth/ESCARFAIL/
2 GIVN Élisabeth
2 SURN ESCARFAIL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2018
3 TIME 10:28
1 FAMC @20858@
0 @21067@ INDI
1 NAME Jean-Pierre/ESCARFAIL/
2 GIVN Jean-Pierre
2 SURN ESCARFAIL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2018
3 TIME 10:30
1 FAMC @20858@
0 @21072@ INDI
1 NAME Jacques/ESCARFAIL/
2 GIVN Jacques
2 SURN ESCARFAIL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2018
3 TIME 10:29
1 FAMC @20858@
0 @21079@ INDI
1 NAME Philippe/HOLLIER/
2 GIVN Philippe
2 SURN HOLLIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2018
3 TIME 10:38
1 FAMS @21087@
1 FAMC @21092@
0 @21085@ INDI
1 NAME Françoise/MARQUET/
2 GIVN Françoise
2 SURN MARQUET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2018
3 TIME 10:37
1 FAMS @21087@
0 @21090@ INDI
1 NAME Marc/HOLLIER/
2 GIVN Marc
2 SURN HOLLIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2018
3 TIME 10:40
1 FAMS @21092@
1 NOTE change OLIER en HOLLIER
1 FAMC @21106@
0 @21097@ INDI
1 NAME Georges/OLIER/
2 GIVN Georges
2 SURN OLIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2018
3 TIME 10:41
1 FAMS @21106@
1 FAMC @21117@
0 @21104@ INDI
1 NAME Amare/COTHIN/
2 GIVN Amare
2 SURN COTHIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2018
3 TIME 10:40
1 FAMS @21106@
0 @21109@ INDI
1 NAME Jean-Jacques/OLIER/
2 GIVN Jean-Jacques
2 SURN OLIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2018
3 TIME 10:42
1 FAMS @21117@
1 FAMC @21128@
0 @21115@ INDI
1 NAME Marie/LEGRAS/
2 GIVN Marie
2 SURN LEGRAS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2018
3 TIME 10:41
1 FAMS @21117@
0 @21120@ INDI
1 NAME Nicolas/OLIER/
2 GIVN Nicolas
2 SURN OLIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2018
3 TIME 10:45
1 FAMS @21128@
1 FAMC @21140@
0 @21126@ INDI
1 NAME Marie/de TURIN/
2 GIVN Marie
2 SURN de TURIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2018
3 TIME 10:42
1 FAMS @21128@
0 @21131@ INDI
1 NAME François/OLIER/
2 GIVN François
2 SURN OLIER
1 SEX M
1 OCCU conseiller d'état
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2018
3 TIME 10:46
1 FAMS @21140@
0 @21138@ INDI
1 NAME Marie/DOLU/
2 GIVN Marie
2 SURN DOLU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2018
3 TIME 10:46
1 FAMS @21140@
0 @21143@ INDI
1 NAME Jeanne/OLIER/
2 GIVN Jeanne
2 SURN OLIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2018
3 TIME 10:49
1 FAMS @21165@
1 FAMC @21140@
0 @21148@ INDI
1 NAME Jean-Jacques/OLIER/
2 GIVN Jean-Jacques
2 SURN OLIER
1 SEX M
1 OCCU curé
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 15:46
1 BIRT
2 DATE 20 SEP 1608
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 2 APR 1657
2 _FNA NO
1 NOTE curé de Paris
2 CONT fondateur de saint sulpice
1 FAMC @21140@
0 @21163@ INDI
1 NAME Xy/d'AUBRAY/
2 GIVN Xy
2 SURN d'AUBRAY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2018
3 TIME 10:49
1 FAMS @21165@
0 @21166@ INDI
1 NAME Marie-Madeleine/d'AUBRAY/
2 GIVN Marie-Madeleine
2 SURN d'AUBRAY
1 SEX F
1 TITL MARQUISE DE BRINVILLIERS
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2018
3 TIME 10:57
1 BIRT
2 DATE 1630
2 _FNA NO
1 DEAT
2 DATE 1676
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
2 CAUS decapitée
1 NOTE L’empoisonneuse
2 CONT Etienne Vallery-Radot estime qu’il n’existe pas de preuve de la parenté Olier-Hollier en développant l’argumentation qui suit.
2 CONT Réfutation de la thèse Escudier sur la parenté avec les Olier et la marquise de Brinvilliers par Etienne Vallery-Radot
2 CONT Selon la thèse de M. Escudier, un Olier, descendant d’une grande famille parlementaire parisienne, dans l'espoir d'effacer le souvenir de son cousinage avec la Brinvilliers, aurait changé l'orthographe de son nom d'Olier en Hollier et émigré en pro
2 CONC vince. Si cette thèse paraît à première vue plausible, elle n’est toutefois pas sans faiblesse.
2 CONT Tout d’abord, Marie-Madeleine Dreux d’Aubray, marquise de Brinvilliers, épouse en 1651 d’Antoine Gobelin de Brinvilliers, exécutée en 1676, ne porte pas le nom des Olier, même si sa mère est effectivement une Olier. Elle ne pourrait donc être que l
2 CONC a cousine (au mieux germaine) de l'ancêtre des Hollier de cette époque. Enfin, le drame ne semble pas avoir affecté les carrières des Olier dont nous avons la trace : Jean-Baptiste Jacques Olier, marquis de Verneuil, conseiller du Roi et commis de
2 CONC Louvois (1662 - / 17.7.1711 - ), Michel Jean-Baptiste Olier de Verneuil (vers 1660 - / 1738 - ), abbé de Melleray, Jean-Jacques Olier, abbé d'Ayans ( - / < 1716 - ) et Charles Henri François Olier, marquis de Nointel (1635 - ), conseiller au parlem
2 CONC ent de Paris, ambassadeur de France auprès de la Porte (1670 - 1680).
2 CONT Toujours d’après la thèse d'Escudier, cet Olier serait un ancêtre maternel des Guiard, Marc (né vers 1700). Au vu de cette date de naissance, on peut estimer que son changement de nom d'Olier en Hollier n’aurait guère pu intervenir avant 1730 soit
2 CONC près de soixante ans après le drame. Marc se serait alors caché au fin fond des campagnes du Morvan alors que ses oncles ou cousins, l'ambassadeur, les abbés et le conseiller du Roi ci-dessus nommés, avaient en leur temps poursuivi paisiblement leu
2 CONC r brillante carrière : c'est invraisemblable.
2 CONT D’autant plus invraisemblable que le père et la mère de Marc, Georges et Anne, cités par Escudier, ont été bien identifiés comme Hollier et non Olier : ils se sont mariés à Vermenton (Yonne) le 15
2 CONT 214
2 CONT
2 CONT février 1694. Le père de Georges, Pierre Hollier, également identifié, paraît en tant que Journalier en 1706. Si changement de nom il y avait, ce ne pourrait être que le fait de ce Pierre, qui aurait fuit Paris pour la vallée de l’Yonne et la misèr
2 CONC e.
2 CONT D’un point de vue onomastique, on peut relever que Olier, patronyme méridional qui veut dire marchand d’huile (sous-entendu d’olive), ne se confond pas avec Hollier, patronyme du Nord qui signifie tenancier de maison close. Dès lors le choix suppos
2 CONC é du sobriquet Hollier apparaît surprenant, malgré l’homophonie. D'autant plus qu'il y avait à cette époque une méthode facile pour changer de nom et nos ancêtres y ont eu fréquemment recours : l'ajout d'un nom de terre en laissant tomber le patron
2 CONC yme. C'était d'ailleurs le cas des Olier qui se faisait appeler de Nointel ou de Verneuil.
2 CONT Notons enfin que le propre fils de la Brinvilliers, Claude Antoine Gobelin, utilisa cette méthode en se faisant appeler simplement Gobelin ou en troquant son nom de terre paternel (Brinvilliers) pour celui d’Offémont, du nom du fief de son grand-pè
2 CONC re maternel Dreux d’Aubray, dont il avait hérité.
2 CONT Jean Vallery-Radot avait en 1914 relevé son épitaphe à l’entrée du chœur de l'église de Saint-Crépin aux Bois (Oise), à même le sol (Voir sa correspondance de guerre):
2 CONT
2 CONT
1 FAMC @21165@
0 @21200@ INDI
1 NAME Ernest/PAULTRE/
2 GIVN Ernest
2 SURN PAULTRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2018
3 TIME 11:25
1 FAMS @21202@
0 @21205@ INDI
1 NAME Louise/SAUVAN/
2 GIVN Louise
2 SURN SAUVAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2018
3 TIME 11:32
1 FAMC @19955@
0 @21218@ INDI
1 NAME Héloïse/SEYDOUX/
2 GIVN Héloïse
2 SURN SEYDOUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 JUN 2019
3 TIME 19:12
1 BIRT
2 DATE 22 NOV 2018
2 PLAC Nouméa,98800,Nouvelle-Calédonie,,FRANCE,
3 MAP
4 LATI S22.276310
4 LONG E166.457200
2 _FNA NO
1 FAMC @3235@
0 @21238@ INDI
1 NAME Sabine/VALLERY-RADOT/
2 GIVN Sabine
2 SURN VALLERY-RADOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 7:34
1 FAMS @21321@
1 FAMC @21038@
0 @21245@ INDI
1 NAME Fortuné/FRANÇOIS/
2 GIVN Fortuné
2 SURN FRANÇOIS
1 SEX M
1 OCCU avoué
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 7:55
1 FAMS @31059@
0 @21272@ INDI
1 NAME Monique/VALLERY-RADOT/
2 GIVN Monique
2 SURN VALLERY-RADOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 NOV 2018
3 TIME 21:02
1 FAMC @21038@
0 @21291@ INDI
1 NAME François/MAURIAC/
2 GIVN François
2 SURN MAURIAC
1 SEX M
1 OCCU Écrivain
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 NOV 2018
3 TIME 11:53
0 @21301@ INDI
1 NAME Georges/BERNANOS/
2 GIVN Georges
2 SURN BERNANOS
1 SEX M
1 OCCU Écrivain
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 NOV 2018
3 TIME 12:04
0 @21320@ INDI
1 NAME Michel/MAILLARD/
2 GIVN Michel
2 SURN MAILLARD
1 SEX F
1 OCCU gouverneur de la France d'Outre-Mer
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 NOV 2018
3 TIME 12:53
1 FAMS @21321@
1 NOTE sans postérité
2 CONT
0 @21324@ INDI
1 NAME Yvonne/ENGERAND/
2 GIVN Yvonne
2 SURN ENGERAND
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 9:46
1 FAMS @21326@
0 @21327@ INDI
1 NAME Cécile/VIGNOT/
2 GIVN Cécile
2 SURN VIGNOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 12:46
1 FAMS @21329@
0 @21330@ INDI
1 NAME Xxx/PAULTRE/
2 GIVN Xxx
2 SURN PAULTRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 7:13
1 FAMC @20910@
0 @21360@ INDI
1 NAME Dominique/BRUSSET/
2 GIVN Dominique
2 SURN BRUSSET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 NOV 2018
3 TIME 14:56
1 FAMS @21394@
1 FAMC @20491@
0 @21365@ INDI
1 NAME Jacqueline/BRUSSET/
2 GIVN Jacqueline
2 SURN BRUSSET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 NOV 2018
3 TIME 12:56
1 FAMS @21400@
1 FAMC @20491@
0 @21370@ INDI
1 NAME Rémi/BRUSSET/
2 GIVN Rémi
2 SURN BRUSSET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 NOV 2018
3 TIME 12:51
1 FAMC @20491@
0 @21375@ INDI
1 NAME Thierry/BRUSSET/
2 GIVN Thierry
2 SURN BRUSSET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 NOV 2018
3 TIME 12:51
1 FAMC @20491@
0 @21380@ INDI
1 NAME Olivier/BRUSSET/
2 GIVN Olivier
2 SURN BRUSSET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 NOV 2018
3 TIME 12:51
1 FAMC @20491@
0 @21392@ INDI
1 NAME Henri/DOUGIER/
2 GIVN Henri
2 SURN DOUGIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 NOV 2018
3 TIME 14:56
1 FAMS @21394@
0 @21397@ INDI
1 NAME Dominique/de ROUX/
2 GIVN Dominique
2 SURN de ROUX
1 SEX M
1 OCCU Écrivain
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 NOV 2018
3 TIME 12:56
1 FAMS @21400@
0 @21402@ INDI
1 NAME Pierre-Guillaume/de ROUX/
2 GIVN Pierre-Guillaume
2 SURN de ROUX
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 14:11
1 FAMC @21400@
0 @21412@ INDI
1 NAME Catherine/de CHAUVERON/
2 GIVN Catherine
2 SURN de CHAUVERON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 16:14
1 FAMS @21414@
1 BIRT
2 DATE 12 MAR 1922
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @22198@
0 @21419@ INDI
1 NAME Dominique/CURT/
2 GIVN Dominique
2 SURN CURT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 12:05
1 FAMS @21421@
0 @21423@ INDI
1 NAME Marine/VALLERY-RADOT/
2 GIVN Marine
2 SURN VALLERY-RADOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 NOV 2018
3 TIME 14:37
1 BIRT
2 DATE 1984
2 _FNA NO
1 FAMC @21421@
0 @21430@ INDI
1 NAME Lorraine/VALLERY-RADOT/
2 GIVN Lorraine
2 SURN VALLERY-RADOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 NOV 2018
3 TIME 14:37
1 BIRT
2 DATE 1988
2 _FNA NO
1 FAMC @21421@
0 @21444@ INDI
1 NAME Raphaël/GIROUD/
2 GIVN Raphaël
2 SURN GIROUD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 12:08
1 FAMS @21446@
1 DEAT
2 _FNA NO
0 @21458@ INDI
1 NAME Emmanuel/ROUANET/
2 GIVN Emmanuel
2 SURN ROUANET
1 SEX M
1 OCCU journaliste
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 12:14
1 FAMS @21460@
0 @21469@ INDI
1 NAME Robert/JARCZYK/
2 GIVN Robert
2 SURN JARCZYK
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 12:59
1 FAMS @21471@
1 DEAT
2 _FNA NO
0 @21472@ INDI
1 NAME Jacques/JARCZYK/
2 GIVN Jacques
2 SURN JARCZYK
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 NOV 2018
3 TIME 14:44
1 FAMC @21471@
0 @21502@ INDI
1 NAME Désiré/NISARD/
2 GIVN Désiré
2 SURN NISARD
1 SEX M
1 OCCU directeur à l’Ecole normale
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 NOV 2018
3 TIME 15:19
0 @21536@ INDI
1 NAME Jean-Baptiste/PASTEUR/
2 GIVN Jean-Baptiste
2 SURN PASTEUR
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 15:51
1 BIRT
2 DATE 8 NOV 1851
2 _FNA NO
1 DEAT
2 DATE 17 OCT 1908
2 _FNA NO
1 NOTE Jean-Baptiste (1851-1908), le fils de Pasteur, bien que marié, n’a pas eu non plus de postérité.
2 CONT
1 FAMC @20115@
0 @21558@ INDI
1 NAME Édouard/ANDRY/
2 GIVN Édouard
2 SURN ANDRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 17:05
1 FAMS @21580@
1 BIRT
2 DATE 1836
2 _FNA NO
1 DEAT
2 DATE 1928
2 _FNA NO
1 FAMC @21590@
0 @21573@ INDI
1 NAME Thérèse/RENEAULT/
2 GIVN Thérèse
2 SURN RENEAULT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 17:05
1 FAMS @21580@
1 BIRT
2 DATE 1840
2 _FNA NO
1 DEAT
2 DATE 1904
2 _FNA NO
0 @21583@ INDI
1 NAME Victor/ANDRY/
2 GIVN Victor
2 SURN ANDRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 NOV 2018
3 TIME 18:18
1 FAMS @21590@
1 BIRT
2 DATE 1800
2 _FNA NO
1 DEAT
2 DATE 1880
2 _FNA NO
0 @21618@ INDI
1 NAME Nicole/VALLERY-RADOT/
2 GIVN Nicole
2 SURN VALLERY-RADOT
1 SEX F
1 OCCU Journaliste
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 JUN 2019
3 TIME 7:51
1 BIRT
2 DATE 8 FEB 1928
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 25 MAY 2019
2 PLAC Paris 16,,,,,
2 _FNA NO
2 NOTE
3 CONT Mme Maurice Vallery-Radot, Mme Philippe Vallery-Radot, ses belles-sœurs,
3 CONT es neveux, petits neveux et arrière-petits-neveux
3 CONT vous font part du rappel à Dieu le 25 mai 2019, à son domicile, en sa 92• année, munie  des sacrements de l'Église, de
3 CONT Mlle Nicole VALLERY-RADOT journaliste, chevalier  des Arts et des Lettres,
3 CONT La messe de funérailles  sera célébrée  le jeudi 6 juin 2019, à 15 heures, en l'église Saint-André  à Joigny (Yonne),
3 CONT suivie de l ïnhumation au cimetière de Joigny,
3 CONT Une messe sera dite  à son intention  le vendredi 21 juin, à 19 heures, en la chapelle Notre-Dame du-Salnt-Sacrement,
3 CONT 20, rue Cortambert,  Paris (16").
3 CONT 39, avenue d'Eylau, 75116 Paris.
3 CONT
1 BURI
2 DATE 6 JUN 2019
2 PLAC Joigny,89300,Yonne,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.982880
4 LONG E3.400960
2 _FNA NO
1 NOTE Deux grandes amitiés= Jeanne Picard (Mère Véronique) et Fernand Pouillon  Comment la rencontre d'une bénédictine, d'un architecte et d'une journaliste va faire surgir une abbaye. Tout commence avec l'abbé Bobin pour se poursuivre en Algérie et abou
2 CONC tir à Cotignac. Monastère La Font Saint-Joseph du Bessillon
2 CONT
1 FAMC @21329@
0 @21631@ INDI
1 NAME Nicole/de PARCEVAUX/
2 GIVN Nicole
2 SURN de PARCEVAUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 9:57
1 FAMS @21633@
0 @21634@ INDI
1 NAME Jacqueline/VALLERY-RADOT/
2 GIVN Jacqueline
2 SURN VALLERY-RADOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 NOV 2018
3 TIME 19:10
1 FAMS @21644@
1 BIRT
2 DATE 1926
2 _FNA NO
1 FAMC @21326@
0 @21642@ INDI
1 NAME Geoffroy/LE PELLETIER de GLATIGNY/
2 GIVN Geoffroy
2 SURN LE PELLETIER de GLATIGNY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 NOV 2018
3 TIME 19:10
1 FAMS @21644@
0 @21645@ INDI
1 NAME Véronique/LE PELLETIER de GLATIGNY/
2 GIVN Véronique
2 SURN LE PELLETIER de GLATIGNY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 NOV 2018
3 TIME 18:58
1 FAMC @21644@
0 @21650@ INDI
1 NAME Catherine/LE PELLETIER de GLATIGNY/
2 GIVN Catherine
2 SURN LE PELLETIER de GLATIGNY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 NOV 2018
3 TIME 19:02
1 FAMS @21661@
1 FAMC @21644@
0 @21658@ INDI
1 NAME Jean-Louis/FLAVIGNY/
2 GIVN Jean-Louis
2 SURN FLAVIGNY
1 SEX M
1 OCCU Architecte
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 NOV 2018
3 TIME 19:02
1 FAMS @21661@
0 @21662@ INDI
1 NAME Tristan/FLAVIGNY/
2 GIVN Tristan
2 SURN FLAVIGNY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 NOV 2018
3 TIME 19:01
1 BIRT
2 DATE 1974
2 _FNA NO
1 FAMC @21661@
0 @21669@ INDI
1 NAME Guillaume/FLAVIGNY/
2 GIVN Guillaume
2 SURN FLAVIGNY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 NOV 2018
3 TIME 19:01
1 BIRT
2 DATE 1976
2 _FNA NO
1 FAMC @21661@
0 @21677@ INDI
1 NAME Charles-Henri/FLAVIGNY/
2 GIVN Charles-Henri
2 SURN FLAVIGNY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 NOV 2018
3 TIME 19:02
1 BIRT
2 DATE 1979
2 _FNA NO
1 FAMC @21661@
0 @21685@ INDI
1 NAME Thiphaine/FLAVIGNY/
2 GIVN Thiphaine
2 SURN FLAVIGNY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 NOV 2018
3 TIME 19:02
1 BIRT
2 DATE 1982
2 _FNA NO
1 FAMC @21661@
0 @21692@ INDI
1 NAME Michel/LE PELLETIER de GLATIGNY/
2 GIVN Michel
2 SURN LE PELLETIER de GLATIGNY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 NOV 2018
3 TIME 19:08
1 FAMS @21701@
1 FAMC @21644@
0 @21699@ INDI
1 NAME Monica/von WALDERDOFF/
2 GIVN Monica
2 SURN von WALDERDOFF
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 NOV 2018
3 TIME 19:08
1 FAMS @21701@
0 @21702@ INDI
1 NAME Raphaël/LE PELLETIER de GLATIGNY/
2 GIVN Raphaël
2 SURN LE PELLETIER de GLATIGNY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 NOV 2018
3 TIME 19:06
1 BIRT
2 DATE 1981
2 _FNA NO
1 FAMC @21701@
0 @21709@ INDI
1 NAME Marie-Gabrielle/LE PELLETIER de GLATIGNY/
2 GIVN Marie-Gabrielle
2 SURN LE PELLETIER de GLATIGNY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 NOV 2018
3 TIME 19:06
1 BIRT
2 DATE 1983
2 _FNA NO
1 FAMC @21701@
0 @21716@ INDI
1 NAME Marguerite-Marie/LE PELLETIER de GLATIGNY/
2 GIVN Marguerite-Marie
2 SURN LE PELLETIER de GLATIGNY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 NOV 2018
3 TIME 19:07
1 BIRT
2 DATE 1985
2 _FNA NO
1 FAMC @21701@
0 @21723@ INDI
1 NAME Clarisse/LE PELLETIER de GLATIGNY/
2 GIVN Clarisse
2 SURN LE PELLETIER de GLATIGNY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 NOV 2018
3 TIME 19:08
1 BIRT
2 DATE 1987
2 _FNA NO
1 FAMC @21701@
0 @21731@ INDI
1 NAME Gaétane/LE PELLETIER de GLATIGNY/
2 GIVN Gaétane
2 SURN LE PELLETIER de GLATIGNY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 NOV 2018
3 TIME 19:08
1 BIRT
2 DATE 1991
2 _FNA NO
1 FAMC @21701@
0 @21738@ INDI
1 NAME François/LE PELLETIER de GLATIGNY/
2 GIVN François
2 SURN LE PELLETIER de GLATIGNY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 NOV 2018
3 TIME 19:10
1 FAMC @21644@
0 @21743@ INDI
1 NAME Colette/VALLERY-RADOT/
2 GIVN Colette
2 SURN VALLERY-RADOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 NOV 2018
3 TIME 19:13
1 FAMS @21753@
1 BIRT
2 DATE 1927
2 _FNA NO
1 FAMC @21326@
0 @21751@ INDI
1 NAME Jean-Michel/GRIMON/
2 GIVN Jean-Michel
2 SURN GRIMON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 NOV 2018
3 TIME 19:13
1 FAMS @21753@
0 @21756@ INDI
1 NAME Muriel/GRIMON/
2 GIVN Muriel
2 SURN GRIMON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 NOV 2018
3 TIME 19:13
1 BIRT
2 DATE 1956
2 _FNA NO
1 FAMC @21753@
0 @21764@ INDI
1 NAME Jean-Pascal/GRIMON/
2 GIVN Jean-Pascal
2 SURN GRIMON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 NOV 2018
3 TIME 19:13
1 BIRT
2 DATE 1963
2 _FNA NO
1 FAMC @21753@
0 @21775@ INDI
1 NAME Ann/ISMAY/
2 GIVN Ann
2 SURN ISMAY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 11:57
1 FAMS @21777@
1 BIRT
2 DATE 26 MAY 1934
2 _FNA NO
0 @21778@ INDI
1 NAME Ernest/SEILLIERE/
2 GIVN Ernest
2 SURN SEILLIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 8:51
1 BIRT
2 DATE 2015
2 _FNA YES
2 NOTE ?
1 FAMC @21780@
0 @21784@ INDI
1 NAME Ysaline/SEILLIERE/
2 GIVN Ysaline
2 SURN SEILLIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 8:47
1 BIRT
2 DATE 2017
2 _FNA NO
1 FAMC @21780@
0 @21791@ INDI
1 NAME Aymeric/SEILLIERE/
2 GIVN Aymeric
2 SURN SEILLIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 8:49
1 BIRT
2 DATE 27 NOV 2018
2 _FNA NO
1 FAMC @21780@
0 @21823@ INDI
1 NAME Xavier/VALLERY-RADOT/
2 GIVN Xavier
2 SURN VALLERY-RADOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 9:48
1 BIRT
2 DATE 1950
2 _FNA NO
1 FAMC @21633@
0 @21831@ INDI
1 NAME Loïc/VALLERY-RADOT/
2 GIVN Loïc
2 SURN VALLERY-RADOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 MAY 2019
3 TIME 18:30
1 FAMS @21864@
1 BIRT
2 DATE 1951
2 _FNA NO
1 FAMC @21633@
0 @21838@ INDI
1 NAME Bertrand/VALLERY-RADOT/
2 GIVN Bertrand
2 SURN VALLERY-RADOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 10:20
1 FAMS @21910@
1 BIRT
2 DATE 1953
2 _FNA NO
1 FAMC @21633@
0 @21845@ INDI
1 NAME Gilles/VALLERY-RADOT/
2 GIVN Gilles
2 SURN VALLERY-RADOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 10:26
1 FAMS @21938@
1 BIRT
2 DATE 1958
2 _FNA NO
1 FAMC @21633@
0 @21852@ INDI
1 NAME Marie/VALLERY-RADOT/
2 GIVN Marie
2 SURN VALLERY-RADOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 10:29
1 FAMS @21962@
1 BIRT
2 DATE 1958
2 _FNA NO
1 FAMC @21633@
0 @21862@ INDI
1 NAME Sylvia/FRAUD/
2 GIVN Sylvia
2 SURN FRAUD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 MAY 2019
3 TIME 18:30
1 FAMS @21864@
0 @21866@ INDI
1 NAME Yann/VALLERY-RADOT/
2 GIVN Yann
2 SURN VALLERY-RADOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 10:17
1 FAMS @21890@
1 BIRT
2 DATE 1980
2 _FNA NO
1 FAMC @21864@
0 @21873@ INDI
1 NAME Ariane/VALLERY-RADOT/
2 GIVN Ariane
2 SURN VALLERY-RADOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 10:18
1 FAMS @21906@
1 BIRT
2 DATE 1982
2 _FNA NO
1 FAMC @21864@
0 @21880@ INDI
1 NAME Cyril/VALLERY-RADOT/
2 GIVN Cyril
2 SURN VALLERY-RADOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 10:15
1 BIRT
2 DATE 1986
2 _FNA NO
1 FAMC @21864@
0 @21888@ INDI
1 NAME Alana/?/
2 GIVN Alana
2 SURN ?
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 10:17
1 FAMS @21890@
0 @21893@ INDI
1 NAME Callan Huey/VALLERY-RADOT/
2 GIVN Callan Huey
2 SURN VALLERY-RADOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 10:17
1 BIRT
2 DATE 7 OCT 2013
2 _FNA NO
1 FAMC @21890@
0 @21904@ INDI
1 NAME Jérôme/WURM/
2 GIVN Jérôme
2 SURN WURM
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 10:18
1 FAMS @21906@
0 @21908@ INDI
1 NAME Florence/MARTIN/
2 GIVN Florence
2 SURN MARTIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 10:20
1 FAMS @21910@
0 @21911@ INDI
1 NAME Frédéric/VALLERY-RADOT/
2 GIVN Frédéric
2 SURN VALLERY-RADOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 10:20
1 BIRT
2 DATE 1984
2 _FNA NO
1 FAMC @21910@
0 @21918@ INDI
1 NAME Alexis/VALLERY-RADOT/
2 GIVN Alexis
2 SURN VALLERY-RADOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 10:20
1 BIRT
2 DATE 1986
2 _FNA NO
1 FAMC @21910@
0 @21926@ INDI
1 NAME Lionel/VALLERY-RADOT/
2 GIVN Lionel
2 SURN VALLERY-RADOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 10:20
1 BIRT
2 DATE 1988
2 _FNA NO
1 FAMC @21910@
0 @21936@ INDI
1 NAME Nathalie/TURBET-DELOF/
2 GIVN Nathalie
2 SURN TURBET-DELOF
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 10:23
1 FAMS @21938@
0 @21939@ INDI
1 NAME Damien/TURBET-DELOF/
2 GIVN Damien
2 SURN TURBET-DELOF
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 10:22
1 BIRT
2 DATE 1988
2 _FNA NO
1 FAMC @21938@
0 @21947@ INDI
1 NAME Guilhem/TURBET-DELOF/
2 GIVN Guilhem
2 SURN TURBET-DELOF
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 10:23
1 BIRT
2 DATE 1993
2 _FNA NO
1 FAMC @21938@
0 @21959@ INDI
1 NAME Philippe/SABLAYROLLES/
2 GIVN Philippe
2 SURN SABLAYROLLES
1 SEX M
1 OCCU Militaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 10:29
1 FAMS @21962@
1 NOTE colonel de l’armée de terre, pilote d’hélicoptères
2 CONT
0 @21964@ INDI
1 NAME Laure/SABLAYROLLES/
2 GIVN Laure
2 SURN SABLAYROLLES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 10:32
1 FAMS @21988@
1 BIRT
2 DATE 1983
2 _FNA NO
1 FAMC @21962@
0 @21971@ INDI
1 NAME Aude/SABLAYROLLES/
2 GIVN Aude
2 SURN SABLAYROLLES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 10:34
1 FAMS @22018@
1 BIRT
2 DATE 1984
2 _FNA NO
1 FAMC @21962@
0 @21978@ INDI
1 NAME Benoît/SABLAYROLLES/
2 GIVN Benoît
2 SURN SABLAYROLLES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 10:29
1 BIRT
2 DATE 1988
2 _FNA NO
1 FAMC @21962@
0 @21986@ INDI
1 NAME Antoine/JOUOT/
2 GIVN Antoine
2 SURN JOUOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 10:32
1 FAMS @21988@
0 @21992@ INDI
1 NAME Solenne/JOUOT/
2 GIVN Solenne
2 SURN JOUOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 10:31
1 BIRT
2 DATE 9 SEP 2012
2 _FNA NO
1 FAMC @21988@
0 @21999@ INDI
1 NAME Maylis/JOUOT/
2 GIVN Maylis
2 SURN JOUOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 10:33
1 BIRT
2 DATE 1 AUG 2014
2 _FNA NO
1 FAMC @21988@
0 @22006@ INDI
1 NAME Clémence/JOUOT/
2 GIVN Clémence
2 SURN JOUOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 10:33
1 BIRT
2 DATE 1 AUG 2014
2 _FNA NO
1 FAMC @21988@
0 @22016@ INDI
1 NAME Emmanuel/PUVIS/
2 GIVN Emmanuel
2 SURN PUVIS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 10:34
1 FAMS @22018@
0 @22045@ INDI
1 NAME ?fils/VALLERY-RADOT/
2 GIVN ?fils
2 SURN VALLERY-RADOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 11:50
1 BIRT
2 DATE 1961
2 _FNA NO
1 DEAT
2 DATE 1961
2 _FNA NO
1 FAMC @21777@
0 @22058@ INDI
1 NAME Arnaud/VALLERY-RADOT/
2 GIVN Arnaud
2 SURN VALLERY-RADOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 11:59
1 FAMS @22083@
1 BIRT
2 DATE 21 APR 1965
2 _FNA NO
1 FAMC @21777@
0 @22065@ INDI
1 NAME Olivier/VALLERY-RADOT/
2 GIVN Olivier
2 SURN VALLERY-RADOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 12:01
1 FAMS @22098@
1 BIRT
2 DATE 30 MAY 1966
2 _FNA NO
1 FAMC @21777@
0 @22072@ INDI
1 NAME Michaël/VALLERY-RADOT/
2 GIVN Michaël
2 SURN VALLERY-RADOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 12:02
1 FAMS @22111@
1 BIRT
2 DATE 9 OCT 1969
2 _FNA NO
1 FAMC @21777@
0 @22079@ INDI
1 NAME Chloé/VALLERY-RADOT/
2 GIVN Chloé
2 SURN VALLERY-RADOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 11:58
1 BIRT
2 DATE 7 SEP 2004
2 _FNA NO
1 FAMC @22083@
0 @22087@ INDI
1 NAME Garance/VALLERY-RADOT/
2 GIVN Garance
2 SURN VALLERY-RADOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 12:00
1 BIRT
2 DATE 1 APR 2008
2 _FNA NO
1 FAMC @22083@
0 @22094@ INDI
1 NAME Maxime/VALLERY-RADOT/
2 GIVN Maxime
2 SURN VALLERY-RADOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 12:01
1 BIRT
2 DATE 27 OCT 1999
2 _FNA NO
1 FAMC @22098@
0 @22101@ INDI
1 NAME Antoine/VALLERY-RADOT/
2 GIVN Antoine
2 SURN VALLERY-RADOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 12:01
1 BIRT
2 DATE 18 MAR 2004
2 _FNA NO
1 FAMC @22098@
0 @22107@ INDI
1 NAME Nicolas/VALLERY-RADOT/
2 GIVN Nicolas
2 SURN VALLERY-RADOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 12:02
1 BIRT
2 DATE 13 FEB 1997
2 _FNA NO
1 FAMC @22111@
0 @22114@ INDI
1 NAME Jérôme/VALLERY-RADOT/
2 GIVN Jérôme
2 SURN VALLERY-RADOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 12:02
1 BIRT
2 DATE 14 OCT 1998
2 _FNA NO
1 FAMC @22111@
0 @22136@ INDI
1 NAME Pierre/de CHAUVERON/
2 GIVN Pierre
2 SURN de CHAUVERON
1 SEX M
1 OCCU Avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 15:14
1 FAMS @22198@
1 BIRT
2 DATE 1884
2 _FNA NO
1 DEAT
2 DATE 1972
2 _FNA NO
1 NOTE avocat à la Cour de Paris, ancien membre du conseil de l’Ordre, président du Front National Judiciaire 89, ancien collaborateur de Maurice Barrès, Raymond Poincaré et Alexandre Millerand, époux d’Antoinette Guilliet (1894-1976) 90.
2 CONT
0 @22141@ INDI
1 NAME Laurent/VALLERY-RADOT/
2 GIVN Laurent
2 SURN VALLERY-RADOT
1 SEX M
1 OCCU Avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 16:20
1 FAMS @22239@
1 BIRT
2 DATE 27 MAY 1946
2 _FNA NO
1 FAMC @21414@
0 @22152@ INDI
1 NAME Marie-Christine/VALLERY-RADOT/
2 GIVN Marie-Christine
2 SURN VALLERY-RADOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 11:16
1 FAMS @22686@
1 BIRT
2 DATE 7 JUN 1926
2 _FNA NO
1 NOTE ancienne élève de l’Ecole du Louvre, est une photographe de talent. Elle a été de nombreuses années animatrice au centre Richelieu et enseigne le yoga
1 FAMC @21329@
0 @22191@ INDI
1 NAME Antoinette/GUILLIET/
2 GIVN Antoinette
2 SURN GUILLIET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 15:14
1 FAMS @22198@
1 BIRT
2 DATE 1894
2 _FNA NO
1 DEAT
2 DATE 1976
2 _FNA NO
0 @22207@ INDI
1 NAME Jeanne-Marie/de CHAUVERON/
2 GIVN Jeanne-Marie
2 SURN de CHAUVERON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 15:14
1 FAMC @22198@
0 @22217@ INDI
1 NAME Catherine/VALLERY-RADOT/
2 GIVN Catherine
2 SURN VALLERY-RADOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 15:59
1 FAMS @22277@
1 BIRT
2 DATE 26 AUG 1973
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @22239@
0 @22235@ INDI
1 NAME Laurence/de MONICAULT/
2 GIVN Laurence
2 SURN de MONICAULT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 15:54
1 FAMS @22239@
1 BIRT
2 DATE 17 JUN 1947
2 _FNA NO
0 @22243@ INDI
1 NAME Sophie/VALLERY-RADOT/
2 GIVN Sophie
2 SURN VALLERY-RADOT
1 SEX F
1 OCCU AGREGEE D'HISTOIRE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 16:00
1 BIRT
2 DATE 7 OCT 1975
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @22239@
0 @22250@ INDI
1 NAME Élisabeth/VALLERY-RADOT/
2 GIVN Élisabeth
2 SURN VALLERY-RADOT
1 SEX F
1 OCCU Avocate
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 15:34
1 FAMS @22318@
1 BIRT
2 DATE 7 OCT 1975
2 _FNA NO
1 FAMC @22239@
0 @22257@ INDI
1 NAME Jean/VALLERY-RADOT/
2 GIVN Jean
2 SURN VALLERY-RADOT
1 SEX M
1 OCCU JURISTE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 15:53
1 BIRT
2 DATE 16 JUN 1980
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @22239@
0 @22264@ INDI
1 NAME Bruno/VALLERY-RADOT/
2 GIVN Bruno
2 SURN VALLERY-RADOT
1 SEX M
1 OCCU IMMOBILIER
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 15:54
1 BIRT
2 DATE 19 JAN 1984
2 _FNA NO
1 FAMC @22239@
0 @22272@ INDI
1 NAME Aymeric/DU TEILHET de LAMOTHE/
2 GIVN Aymeric
2 SURN DU TEILHET de LAMOTHE
1 SEX M
1 OCCU Entrepreneur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 15:59
1 FAMS @22277@
1 BIRT
2 DATE 1 OCT 1971
2 PLAC Orléans,45000,Loiret,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N47.902890
4 LONG E1.903890
2 _FNA NO
0 @22279@ INDI
1 NAME Hortense Catherine Anne/DU TEILHET de LAMOTHE/
2 GIVN Hortense Catherine Anne
2 SURN DU TEILHET de LAMOTHE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 15:58
1 BIRT
2 DATE 10 MAY 2002
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @22277@
0 @22292@ INDI
1 NAME Stanislas Martial Aymeric/DU TEILHET de LAMOTHE/
2 GIVN Stanislas Martial Aymeric
2 SURN DU TEILHET de LAMOTHE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 15:59
1 BIRT
2 DATE 18 DEC 2004
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @22277@
0 @22311@ INDI
1 NAME Nicolas/de MAISTRE/
2 GIVN Nicolas
2 SURN de MAISTRE
1 SEX M
1 OCCU commandant de l’armée de l’air, ingénieur des ponts et chaussées
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 16:07
1 FAMS @22318@
1 BIRT
2 DATE 13 NOV 1974
2 PLAC Lorient,56100,Morbihan,Bretagne,FRANCE,
3 MAP
4 LATI N47.750000
4 LONG W3.366670
2 _FNA NO
0 @22321@ INDI
1 NAME Marie Delphine Anne Laurence/de MAISTRE/
2 GIVN Marie Delphine Anne Laurence
2 SURN de MAISTRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 16:05
1 BIRT
2 DATE 22 MAY 1999
2 PLAC Puyricard,,,,,
2 _FNA NO
1 FAMC @22318@
0 @22337@ INDI
1 NAME Jacques Bruno Jean Laurent/de MAISTRE/
2 GIVN Jacques Bruno Jean Laurent
2 SURN de MAISTRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 16:06
1 BIRT
2 DATE 10 JAN 2001
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @22318@
0 @22353@ INDI
1 NAME Constance/de MAISTRE/
2 GIVN Constance
2 SURN de MAISTRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 16:06
1 BIRT
2 DATE 23 OCT 2004
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @22318@
0 @22361@ INDI
1 NAME Héloïse/de MAISTRE/
2 GIVN Héloïse
2 SURN de MAISTRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 16:07
1 BIRT
2 DATE 18 OCT 2007
2 PLAC Suresnes,92150,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.871430
4 LONG E2.229290
2 _FNA NO
1 FAMC @22318@
0 @22368@ INDI
1 NAME Mathilde/de MAISTRE/
2 GIVN Mathilde
2 SURN de MAISTRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 DEC 2018
3 TIME 16:07
1 BIRT
2 DATE 14 MAY 2009
2 PLAC Suresnes,92150,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.871430
4 LONG E2.229290
2 _FNA NO
1 FAMC @22318@
0 @22375@ INDI
1 NAME Étienne/VALLERY-RADOT/
2 GIVN Étienne
2 SURN VALLERY-RADOT
1 SEX M
1 OCCU PRESIDENT SCHOTT FRANCE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 8:54
1 FAMS @22426@
1 BIRT
2 DATE 25 OCT 1947
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @21414@
0 @22382@ INDI
1 NAME Vincent/VALLERY-RADOT/
2 GIVN Vincent
2 SURN VALLERY-RADOT
1 SEX M
1 OCCU PRESIDENT DE SOCIETE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 16:04
1 FAMS @22530@
1 BIRT
2 DATE 19 MAY 1950
2 _FNA NO
1 FAMC @21414@
0 @22389@ INDI
1 NAME Agathe/VALLERY-RADOT/
2 GIVN Agathe
2 SURN VALLERY-RADOT
1 SEX F
1 OCCU Psychologue
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 DEC 2019
3 TIME 17:49
1 FAMS @22568@
1 BIRT
2 DATE 11 FEB 1954
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @21414@
0 @22396@ INDI
1 NAME Emmanuelle/VALLERY-RADOT/
2 GIVN Emmanuelle
2 SURN VALLERY-RADOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:12
1 FAMS @22574@
1 BIRT
2 DATE 2 JAN 1957
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @21414@
0 @22403@ INDI
1 NAME Édouard/VALLERY-RADOT/
2 GIVN Édouard
2 SURN VALLERY-RADOT
1 SEX M
1 OCCU Enseignant
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 8:41
1 FAMS @22424@
1 BIRT
2 DATE 5 OCT 1964
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 NOTE ancien élève de l’Ecole Normale Supérieure, agrégé de l'Université, professeur au lycée Sainte Croix de Neuilly
1 FAMC @21414@
0 @22414@ INDI
1 NAME Marie/SALLERON/
2 GIVN Marie
2 SURN SALLERON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 8:54
1 FAMS @22426@
0 @22422@ INDI
1 NAME Juyan/LEE/
2 GIVN Juyan
2 SURN LEE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 8:41
1 FAMS @22424@
0 @22428@ INDI
1 NAME Flore/VALLERY-RADOT/
2 GIVN Flore
2 SURN VALLERY-RADOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 8:48
1 FAMS @22438@
1 FAMS @22459@
1 BIRT
2 DATE 22 APR 1976
2 _FNA NO
1 FAMC @22426@
0 @22436@ INDI
1 NAME Patrick/KEDEMOS/
2 GIVN Patrick
2 SURN KEDEMOS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 8:46
1 FAMS @22438@
0 @22441@ INDI
1 NAME Nilou/KEDEMOS/
2 GIVN Nilou
2 SURN KEDEMOS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 8:46
1 BIRT
2 DATE 2003
2 _FNA NO
1 FAMC @22438@
0 @22448@ INDI
1 NAME Alexandre/KEDEMOS/
2 GIVN Alexandre
2 SURN KEDEMOS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 8:46
1 BIRT
2 DATE 2005
2 _FNA NO
1 FAMC @22438@
0 @22457@ INDI
1 NAME Wendel/ADDISON/
2 GIVN Wendel
2 SURN ADDISON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 8:48
1 FAMS @22459@
0 @22462@ INDI
1 NAME Léonore/ADDISON/
2 GIVN Léonore
2 SURN ADDISON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 8:48
1 BIRT
2 DATE 7 OCT 2011
2 _FNA NO
1 FAMC @22459@
0 @22469@ INDI
1 NAME Louis/VALLERY-RADOT/
2 GIVN Louis
2 SURN VALLERY-RADOT
1 SEX M
1 OCCU Chirurgien Dentiste
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 8:52
1 FAMS @22483@
1 BIRT
2 DATE 30 DEC 1978
2 _FNA NO
1 FAMC @22426@
0 @22481@ INDI
1 NAME Marie-Noelle/FORTABAT/
2 GIVN Marie-Noelle
2 SURN FORTABAT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 8:52
1 FAMS @22483@
0 @22485@ INDI
1 NAME Antoine/VALLERY-RADOT/
2 GIVN Antoine
2 SURN VALLERY-RADOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 8:52
1 BIRT
2 DATE 6 OCT 2011
2 _FNA NO
1 FAMC @22483@
0 @22492@ INDI
1 NAME Claire/VALLERY-RADOT/
2 GIVN Claire
2 SURN VALLERY-RADOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 8:52
1 BIRT
2 DATE DEC 2012
2 _FNA NO
1 FAMC @22483@
0 @22499@ INDI
1 NAME Pierre/VALLERY-RADOT/
2 GIVN Pierre
2 SURN VALLERY-RADOT
1 SEX M
1 OCCU analyste financier
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 8:53
1 BIRT
2 DATE 6 OCT 1981
2 _FNA NO
1 FAMC @22426@
0 @22508@ INDI
1 NAME Marguerite/VALLERY-RADOT/
2 GIVN Marguerite
2 SURN VALLERY-RADOT
1 SEX F
1 OCCU Avocate
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 8:56
1 FAMS @22522@
1 BIRT
2 DATE 27 NOV 1982
2 _FNA NO
1 FAMC @22426@
0 @22517@ INDI
1 NAME Nicolas/AYNES/
2 GIVN Nicolas
2 SURN AYNES
1 SEX M
1 OCCU Avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 8:56
1 FAMS @22522@
1 BIRT
2 DATE 12 JAN 1983
2 _FNA NO
0 @22526@ INDI
1 NAME Catherine/MALAUZAT/
2 GIVN Catherine
2 SURN MALAUZAT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:02
1 FAMS @22530@
1 BIRT
2 DATE 14 MAY 1952
2 PLAC Bordeaux,33000,Gironde,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N44.840440
4 LONG W0.580500
2 _FNA NO
0 @22533@ INDI
1 NAME Thibault/VALLERY-RADOT/
2 GIVN Thibault
2 SURN VALLERY-RADOT
1 SEX M
1 OCCU Officier DE MARINE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:03
1 FAMS @22552@
1 BIRT
2 DATE 8 SEP 1986
2 _FNA NO
1 FAMC @22530@
0 @22541@ INDI
1 NAME Diane/VALLERY-RADOT/
2 GIVN Diane
2 SURN VALLERY-RADOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:02
1 BIRT
2 DATE 16 NOV 1988
2 _FNA NO
1 FAMC @22530@
0 @22548@ INDI
1 NAME Éloi/VALLERY-RADOT/
2 GIVN Éloi
2 SURN VALLERY-RADOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:02
1 BIRT
2 DATE 10 SEP 2012
2 _FNA NO
1 FAMC @22552@
0 @22555@ INDI
1 NAME Jeanne/VALLERY-RADOT/
2 GIVN Jeanne
2 SURN VALLERY-RADOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:03
1 BIRT
2 DATE 23 MAR 2013
2 _FNA NO
1 FAMC @22552@
0 @22564@ INDI
1 NAME Robert/GRETTON/
2 GIVN Robert
2 SURN GRETTON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 DEC 2019
3 TIME 17:49
1 FAMS @22568@
1 DEAT
2 DATE 2013
2 _FNA NO
0 @22572@ INDI
1 NAME Jean-Louis/LEMOINE/
2 GIVN Jean-Louis
2 SURN LEMOINE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:12
1 FAMS @22574@
0 @22576@ INDI
1 NAME Delphine/LEMOINE/
2 GIVN Delphine
2 SURN LEMOINE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:11
1 FAMS @22608@
1 BIRT
2 DATE 8 MAY 1979
2 _FNA NO
1 FAMC @22574@
0 @22584@ INDI
1 NAME Judith/LEMOINE/
2 GIVN Judith
2 SURN LEMOINE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:08
1 BIRT
2 DATE 18 DEC 1985
2 _FNA NO
1 FAMC @22574@
0 @22591@ INDI
1 NAME Guillaume/LEMOINE/
2 GIVN Guillaume
2 SURN LEMOINE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:08
1 BIRT
2 DATE 14 OCT 1987
2 _FNA NO
1 FAMC @22574@
0 @22598@ INDI
1 NAME Alexandre/LEMOINE/
2 GIVN Alexandre
2 SURN LEMOINE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:08
1 BIRT
2 DATE 5 OCT 1994
2 _FNA NO
1 FAMC @22574@
0 @22606@ INDI
1 NAME Édouard/PROTERY/
2 GIVN Édouard
2 SURN PROTERY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:11
1 FAMS @22608@
0 @22610@ INDI
1 NAME Auguste/PROTERY/
2 GIVN Auguste
2 SURN PROTERY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:10
1 BIRT
2 DATE 2009
2 _FNA NO
1 FAMC @22608@
0 @22617@ INDI
1 NAME Ernest/PROTERY/
2 GIVN Ernest
2 SURN PROTERY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:11
1 BIRT
2 DATE 2012
2 _FNA NO
1 FAMC @22608@
0 @22624@ INDI
1 NAME Camille/LEMOINE/
2 GIVN Camille
2 SURN LEMOINE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:13
1 FAMS @22634@
1 BIRT
2 DATE 26 AUG 1977
2 _FNA NO
1 FAMC @22574@
0 @22632@ INDI
1 NAME Axel/INGÉ/
2 GIVN Axel
2 SURN INGÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:13
1 FAMS @22634@
0 @22636@ INDI
1 NAME Thaïs/INGÉ/
2 GIVN Thaïs
2 SURN INGÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:13
1 BIRT
2 DATE DEC 2012
2 _FNA NO
1 FAMC @22634@
0 @22647@ INDI
1 NAME Véra/ROSA/
2 GIVN Véra
2 SURN ROSA
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:20
1 FAMS @22649@
0 @22651@ INDI
1 NAME Aude/VALLERY-RADOT/
2 GIVN Aude
2 SURN VALLERY-RADOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:19
1 BIRT
2 DATE 14 JUL 1964
2 _FNA NO
1 FAMC @22649@
0 @22658@ INDI
1 NAME Isabelle/VALLERY-RADOT/
2 GIVN Isabelle
2 SURN VALLERY-RADOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:20
1 BIRT
2 DATE 21 JUL 1971
2 _FNA NO
1 FAMC @22649@
0 @22679@ INDI
1 NAME Bernard/POULENC/
2 GIVN Bernard
2 SURN POULENC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:46
1 FAMS @22686@
1 BIRT
2 DATE 1926
2 _FNA NO
2 NOTE Camille Gaston Emile Poulenc
3 CONT (1864-1942) (1852-1948) (1855-1917)
3 CONT Les trois frères créent la SA Etablissement Poulenc Frères en 1900 qui deviendra en 1928 Rhône Poulenc dont Camille assurera la présidence.
3 CONT Etienne Poulenc (1895-1982)
3 CONT Philippe Poulenc
3 CONT Père Jérôme Poulenc (1926-1989)
3 CONT Francis Poulenc, le musicien (1999-1963)
1 DEAT
2 DATE 2012
2 _FNA NO
1 NOTE Bernard descend de la prestigieuse dynastie des Poulenc qui donna à la France l’un des plus beaux fleurons de son industrie pharmaceutique ainsi qu’un compositeur de renommée mondiale, Francis Poulenc. Les ascendants de Bernard figurent dans le tab
2 CONC leau- résumé de la page suivante.
2 CONT Après la mort en 1989 du père Jérôme, franciscain et frère jumeau de Bernard, ce dernier termina l’ouvrage commencé par Jérôme, Le Tympan de Vézelay, le Christ véritable Soleil du Monde, Sources Vives, 1996. Bernard a également rédigé une étude sur
2 CONC  sa famille, Camille Poulenc, 1864-1942, ses ancêtres, de l’artisanat à l’industrie, 1990.
0 @22689@ INDI
1 NAME Béatrice/POULENC/
2 GIVN Béatrice
2 SURN POULENC
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:40
1 FAMS @22719@
1 BIRT
2 DATE 16 DEC 1950
2 _FNA NO
1 FAMC @22686@
0 @22698@ INDI
1 NAME Blandine/POULENC/
2 GIVN Blandine
2 SURN POULENC
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:34
1 BIRT
2 DATE 7 NOV 1952
2 PLAC Sao Paulo,,,,,
2 _FNA NO
1 DEAT
2 DATE AUG 1996
2 PLAC Liban,,,,,
2 _FNA NO
1 FAMC @22686@
0 @22708@ INDI
1 NAME Jérôme/POULENC/
2 GIVN Jérôme
2 SURN POULENC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:35
1 BIRT
2 DATE 13 SEP 1954
2 PLAC Sao Paulo,,,,,
2 _FNA NO
1 FAMC @22686@
0 @22717@ INDI
1 NAME François/LEGRAND/
2 GIVN François
2 SURN LEGRAND
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:40
1 FAMS @22719@
0 @22721@ INDI
1 NAME Aude/LEGRAND/
2 GIVN Aude
2 SURN LEGRAND
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:39
1 BIRT
2 DATE 1976
2 _FNA NO
1 FAMC @22719@
0 @22728@ INDI
1 NAME Caroline/LEGRAND/
2 GIVN Caroline
2 SURN LEGRAND
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:39
1 BIRT
2 DATE 1978
2 _FNA NO
1 FAMC @22719@
0 @22735@ INDI
1 NAME Jean-Christophe/LEGRAND/
2 GIVN Jean-Christophe
2 SURN LEGRAND
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 9:40
1 BIRT
2 DATE 1980
2 _FNA NO
1 FAMC @22719@
0 @22796@ INDI
1 NAME Xxx/ANDRY/
2 GIVN Xxx
2 SURN ANDRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 17:08
1 FAMS @22810@
1 DEAT
2 DATE 1884
2 _FNA NO
1 FAMC @21580@
0 @22802@ INDI
1 NAME Armand/OLIVE/
2 GIVN Armand
2 SURN OLIVE
1 SEX M
1 OCCU Architecte
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 17:13
1 FAMS @22810@
1 BIRT
2 DATE 1853
2 _FNA NO
1 DEAT
2 DATE 1901
2 _FNA NO
2 NOTE En 1884, Armand perd sa jeune femme qui le laisse veuf avec un enfant d’un an, Agnès Olive, Armand se remarie et, apprenant l'infidélité de sa deuxième épouse, se suicide.
3 CONT
1 NOTE L’immeuble du 39 avenue d’Eylau fut bâti à l’initiative d’Édouard Andry en 1892. Il prit comme architecte son gendre Armand Olive (1853-1901) et le frère de celui-ci, également architecte, Gustave. Les deux frères étaient associés et diplômés de l’
2 CONC Ecole centrale pour Armand, de Polytechnique et des Ponts et chaussées pour Gustave.
2 CONT
0 @22814@ INDI
1 NAME Agnès/OLIVE/
2 GIVN Agnès
2 SURN OLIVE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 17:10
1 FAMS @22827@
1 BIRT
2 DATE 1883
2 _FNA NO
1 NOTE En 1884, Armand perd sa jeune femme qui le laisse veuf avec un enfant d’un an, Agnès Olive, . Il confie l’éducation de l’enfant à ses beaux-parents Édouard et Thérèse Andry avec qui elle habitera jusqu’à l’âge de sept ans.
1 FAMC @22810@
0 @22824@ INDI
1 NAME René/VIGIER/
2 GIVN René
2 SURN VIGIER
1 SEX M
1 OCCU avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2018
3 TIME 17:10
1 FAMS @22827@
0 @22832@ INDI
1 NAME Raphaëlle/de CHILLAZ/
2 GIVN Raphaëlle
2 SURN de CHILLAZ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 7 DEC 2018
3 TIME 18:31
1 BIRT
2 DATE 7 DEC 2018
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @10563@
0 @22853@ INDI
1 NAME Jacques Roger/de PANAFIEU/
2 GIVN Jacques Roger
2 SURN de PANAFIEU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 DEC 2018
3 TIME 11:11
1 FAMS @22880@
1 BIRT
2 DATE 1863
2 _FNA NO
1 DEAT
2 DATE 1951
2 _FNA NO
0 @22869@ INDI
1 NAME Jeanne Célestine/de PARIS de BOISROUVRAY/
2 GIVN Jeanne Célestine
2 SURN de PARIS de BOISROUVRAY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 DEC 2018
3 TIME 11:11
1 FAMS @22880@
1 BIRT
2 DATE 1867
2 _FNA NO
1 DEAT
2 DATE 1910
2 _FNA NO
0 @22884@ INDI
1 NAME François Marie Maurice/de PANAFIEU/
2 GIVN François Marie Maurice
2 SURN de PANAFIEU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 DEC 2018
3 TIME 11:11
1 BIRT
2 DATE 1903
2 _FNA NO
1 DEAT
2 DATE 1995
2 _FNA NO
1 FAMC @22880@
0 @22916@ INDI
1 NAME Oriane/?/
2 GIVN Oriane
2 SURN ?
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 DEC 2018
3 TIME 11:24
1 FAMS @22918@
0 @22920@ INDI
1 NAME Loredane/?/
2 GIVN Loredane
2 SURN ?
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 DEC 2018
3 TIME 11:25
1 FAMS @22922@
0 @22929@ INDI
1 NAME Jean Thomas/de MITRY/
2 GIVN Jean Thomas
2 SURN de MITRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 DEC 2018
3 TIME 11:54
1 FAMS @22940@
0 @22938@ INDI
1 NAME Philipine/de LAUZIERES/
2 GIVN Philipine
2 SURN de LAUZIERES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 DEC 2018
3 TIME 11:54
1 FAMS @22940@
0 @22946@ INDI
1 NAME Yves/?/
2 GIVN Yves
2 SURN ?
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2018
3 TIME 8:58
1 FAMS @22948@
0 @22949@ INDI
1 NAME Abel/MEURIOT/
2 GIVN Abel
2 SURN MEURIOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2018
3 TIME 8:58
1 BIRT
2 DATE 2018
2 _FNA NO
1 FAMC @22948@
0 @22956@ INDI
1 NAME Martin/LOUBERT-LALOGE/
2 GIVN Martin
2 SURN LOUBERT-LALOGE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 12:03
1 BIRT
2 DATE 13 DEC 2018
2 PLAC Marseille,13000,Bouches-du-Rhône,Provence-Alpes-Côte d'Azur,FRANCE,
3 MAP
4 LATI N43.296950
4 LONG E5.381070
2 _FNA NO
1 FAMC @4674@
0 @22966@ INDI
1 NAME Bruno/LE ROY/
2 GIVN Bruno
2 SURN LE ROY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 MAY 2019
3 TIME 18:27
1 FAMS @22968@
0 @23015@ INDI
1 NAME Antonio/AMBROSELLI/
2 GIVN Antonio
2 SURN AMBROSELLI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 AUG 2019
3 TIME 18:51
1 FAMS @33394@
1 BIRT
2 DATE 1700
2 PLAC Barrettali,20228,Haute-Corse,Corse,FRANCE,
3 MAP
4 LATI N42.876900
4 LONG E9.355400
2 _FNA NO
2 NOTE ou vers 1691?
1 DEAT
2 DATE 11 JUL 1775
2 PLAC Barrettali,20228,Haute-Corse,Corse,FRANCE,TORRA
3 MAP
4 LATI N42.876900
4 LONG E9.355400
2 _FNA NO
1 FAMC @33403@
0 @23023@ INDI
1 NAME N/AMBROSELLI/
2 GIVN N
2 SURN AMBROSELLI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 AUG 2019
3 TIME 18:36
1 BIRT
2 DATE 1735
2 _FNA NO
1 FAMC @33394@
0 @23030@ INDI
1 NAME Joseph/FILLASTRE/
2 GIVN Joseph
2 SURN FILLASTRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 JUN 2019
3 TIME 18:21
1 FAMS @23039@
1 NOTE ou FIASTRE?
0 @23037@ INDI
1 NAME Magdeleine/JAUFFRET/
2 GIVN Magdeleine
2 SURN JAUFFRET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 JUN 2019
3 TIME 18:21
1 FAMS @23039@
1 NOTE ou GONFRET Madeleine?
0 @23082@ INDI
1 NAME Paul/COBY/
2 GIVN Paul
2 SURN COBY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 12:37
1 FAMS @23103@
1 BIRT
2 DATE 1798
2 PLAC Pointe-à-Pitre,97110,Guadeloupe,Guadeloupe,FRANCE,
3 MAP
4 LATI N16.242200
4 LONG W61.534300
2 _FNA NO
1 DEAT
2 DATE 1834
2 PLAC Pointe-à-Pitre,97110,Guadeloupe,Guadeloupe,FRANCE,RUE TASCHER
3 MAP
4 LATI N16.242200
4 LONG W61.534300
2 _FNA NO
1 NOTE Négociant, boulanger, marchand orfèvre
1 FAMC @23153@
0 @23097@ INDI
1 NAME Louise Annette Hiettelée/BERTIN/
2 GIVN Louise Annette Hiettelée
2 SURN BERTIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 12:37
1 FAMS @23103@
1 BIRT
2 DATE 1801
2 _FNA NO
1 FAMC @33378@
0 @23110@ INDI
1 NAME Pierre Alexandre Clermont/COBY/
2 GIVN Pierre Alexandre Clermont
2 SURN COBY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 12:49
1 FAMS @23177@
1 BIRT
2 DATE 1827
2 PLAC Pointe-à-Pitre,97110,Guadeloupe,Guadeloupe,FRANCE,
3 MAP
4 LATI N16.242200
4 LONG W61.534300
2 _FNA NO
1 DEAT
2 DATE 13 MAR 1899
3 TIME 9:00
2 PLAC Paris 02,,Seine,Ile de France,FRANCE,67 RUE SAINTE ANNE
2 _FNA NO
2 NOTE Acte de décès n°183, AM de Paris 2e, année 1899
1 FAMC @23103@
0 @23136@ INDI
1 NAME Nn/COBY/
2 GIVN Nn
2 SURN COBY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 15:00
1 FAMS @23153@
0 @23142@ INDI
1 NAME Marie Anne/BEAUMANOIR/
2 GIVN Marie Anne
2 SURN BEAUMANOIR
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 15:00
1 FAMS @23153@
1 BIRT
2 DATE 1776
2 _FNA NO
1 DEAT
2 DATE 1843
2 _FNA NO
0 @23171@ INDI
1 NAME Henriette Clotilde/GIBASSIER/
2 GIVN Henriette Clotilde
2 SURN GIBASSIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 12:46
1 FAMS @23177@
1 BIRT
2 DATE 1823
2 _FNA NO
1 DEAT
2 DATE BEF 1899
2 _FNA NO
0 @23231@ INDI
1 NAME Pierre/BITARD/
2 GIVN Pierre
2 SURN BITARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 7:41
1 FAMS @23241@
1 BIRT
2 DATE 1805
2 _FNA NO
1 DEAT
2 DATE BEF 1888
2 _FNA NO
2 NOTE dcd au mariage de cécile
0 @23239@ INDI
1 NAME Cécile/EVRAT/
2 GIVN Cécile
2 SURN EVRAT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 7:43
1 FAMS @23241@
1 BIRT
2 DATE 1810
2 _FNA NO
1 DEAT
2 DATE AFT 1888
2 _FNA NO
2 NOTE vivante au mariage de cécile
0 @23255@ INDI
1 NAME Denis Louis/THURET/
2 GIVN Denis Louis
2 SURN THURET
1 SEX M
1 OCCU charretier
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 AUG 2019
3 TIME 7:02
1 FAMS @23275@
1 BIRT
2 DATE 17 JAN 1799
2 PLAC Crespières,78121,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883170
4 LONG E1.921510
2 _FNA NO
1 DEAT
2 DATE 22 NOV 1868
2 PLAC Crespières,78121,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883170
4 LONG E1.921510
2 _FNA NO
1 NOTE propriétaire cultivateur fermier - rentier
1 FAMC @23300@
0 @23271@ INDI
1 NAME Marie Anne Rosalie/CAILLOT/
2 GIVN Marie Anne Rosalie
2 SURN CAILLOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 AUG 2019
3 TIME 7:04
1 FAMS @23275@
1 BIRT
2 DATE 30 MAY 1804
2 PLAC Crespières,78121,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883170
4 LONG E1.921510
2 _FNA NO
1 DEAT
2 DATE 23 JUL 1884
2 PLAC Crespières,78121,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883170
4 LONG E1.921510
2 _FNA NO
1 FAMC @33129@
0 @23281@ INDI
1 NAME Jean Louis/THURET/
2 GIVN Jean Louis
2 SURN THURET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 DEC 2019
3 TIME 19:28
1 FAMS @23300@
1 BIRT
2 DATE 1762
2 PLAC Crespières,78121,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883170
4 LONG E1.921510
2 _FNA NO
1 DEAT
2 DATE 1825
2 PLAC Crespières,78121,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883170
4 LONG E1.921510
2 _FNA NO
1 FAMC @23353@
0 @23293@ INDI
1 NAME Marie-Louise/BELHOMME/
2 GIVN Marie-Louise
2 SURN BELHOMME
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 DEC 2019
3 TIME 19:28
1 FAMS @23300@
1 BIRT
2 DATE 1767
2 PLAC Plaisir,78370,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.823190
4 LONG E1.954100
2 _FNA NO
1 DEAT
2 DATE 1854
2 _FNA NO
1 FAMC @23327@
0 @23304@ INDI
1 NAME Louis Étienne/BELHOMME/
2 GIVN Louis Étienne
2 SURN BELHOMME
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 NOV 2019
3 TIME 7:28
1 FAMS @23327@
1 BIRT
2 DATE 6 AUG 1740
2 PLAC Crespières,78121,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883170
4 LONG E1.921510
2 _FNA NO
1 DEAT
2 DATE 23 NOV 1793
2 PLAC Crespières,78121,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883170
4 LONG E1.921510
2 _FNA NO
1 FAMC @36401@
0 @23316@ INDI
1 NAME Marie Barbe/CORIÇON/
2 GIVN Marie Barbe
2 SURN CORIÇON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 NOV 2019
3 TIME 7:28
1 FAMS @23327@
1 BIRT
2 DATE 1743
2 _FNA NO
1 DEAT
2 DATE 1830
2 _FNA NO
1 FAMC @36420@
0 @23331@ INDI
1 NAME Mathieu/THURET/
2 GIVN Mathieu
2 SURN THURET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 16:40
1 FAMS @23353@
1 BIRT
2 DATE 25 NOV 1728
2 PLAC Crespières,78121,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883170
4 LONG E1.921510
2 _FNA NO
1 DEAT
2 DATE 21 DEC 1807
2 PLAC Crespières,78121,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883170
4 LONG E1.921510
2 _FNA NO
1 FAMC @23377@
0 @23342@ INDI
1 NAME Marie Françoise/ROBILLARD/
2 GIVN Marie Françoise
2 SURN ROBILLARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 NOV 2019
3 TIME 7:09
1 FAMS @23353@
1 BIRT
2 DATE 11 OCT 1725
2 PLAC Crespières,78121,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883170
4 LONG E1.921510
2 _FNA YES
2 NOTE OU 1735?
1 DEAT
2 DATE 12 FEB 1803
2 PLAC Crespières,78121,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883170
4 LONG E1.921510
2 _FNA NO
1 FAMC @23411@
0 @23362@ INDI
1 NAME Louis/THURET/
2 GIVN Louis
2 SURN THURET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 17:05
1 FAMS @23377@
1 BIRT
2 DATE 1692
2 PLAC Bouafle,78410,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.964630
4 LONG E1.901200
2 _FNA NO
1 DEAT
2 DATE 3 NOV 1752
2 PLAC Crespières,78121,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883170
4 LONG E1.921510
2 _FNA NO
1 FAMC @23447@
0 @23373@ INDI
1 NAME Marguerite/ETEVE/
2 GIVN Marguerite
2 SURN ETEVE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 17:05
1 FAMS @23377@
1 BIRT
2 DATE 14 JAN 1700
2 PLAC Crespières,78121,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883170
4 LONG E1.921510
2 _FNA NO
1 DEAT
2 DATE 29 MAR 1748
2 PLAC Crespières,78121,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883170
4 LONG E1.921510
2 _FNA NO
1 FAMC @23468@
0 @23392@ INDI
1 NAME Louis/ROBILLARD/
2 GIVN Louis
2 SURN ROBILLARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 16:45
1 FAMS @23411@
1 BIRT
2 DATE 1689
2 _FNA NO
1 DEAT
2 DATE 1759
2 _FNA NO
0 @23404@ INDI
1 NAME Marie/MARY/
2 GIVN Marie
2 SURN MARY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 16:45
1 FAMS @23411@
1 BIRT
2 DATE 1696
2 _FNA NO
1 DEAT
2 DATE 1769
2 _FNA NO
0 @23430@ INDI
1 NAME Louis/THURET/
2 GIVN Louis
2 SURN THURET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 16:52
1 FAMS @23447@
1 BIRT
2 DATE 1660
2 _FNA NO
1 DEAT
2 DATE 1722
2 _FNA NO
0 @23440@ INDI
1 NAME Anne/LAURENT/
2 GIVN Anne
2 SURN LAURENT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 16:52
1 FAMS @23447@
1 BIRT
2 DATE 1665
2 _FNA NO
1 DEAT
2 DATE 1722
2 _FNA NO
0 @23450@ INDI
1 NAME Jacques/ETEVE/
2 GIVN Jacques
2 SURN ETEVE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 17:06
1 FAMS @23468@
1 BIRT
2 DATE 1667
2 _FNA NO
1 DEAT
2 DATE 1721
2 _FNA NO
1 FAMC @23485@
0 @23461@ INDI
1 NAME Anne/GAUTHIER/
2 GIVN Anne
2 SURN GAUTHIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 17:06
1 FAMS @23468@
1 BIRT
2 DATE 1669
2 _FNA NO
1 DEAT
2 DATE 1728
2 _FNA NO
1 FAMC @23503@
0 @23473@ INDI
1 NAME Nicolas/ETEVE/
2 GIVN Nicolas
2 SURN ETEVE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 16:57
1 FAMS @23485@
1 BIRT
2 DATE 1635
2 _FNA NO
0 @23481@ INDI
1 NAME Françoise/TESTARD/
2 GIVN Françoise
2 SURN TESTARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 16:57
1 FAMS @23485@
1 BIRT
2 DATE 1640
2 _FNA NO
0 @23488@ INDI
1 NAME Pierre/GAUTHIER/
2 GIVN Pierre
2 SURN GAUTHIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 17:03
1 FAMS @23503@
1 BIRT
2 DATE 1640
2 _FNA NO
0 @23496@ INDI
1 NAME Marguerite/LABBE/
2 GIVN Marguerite
2 SURN LABBE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 17:03
1 FAMS @23503@
1 BIRT
2 DATE 1636
2 _FNA NO
1 DEAT
2 DATE 1691
2 _FNA NO
0 @23509@ INDI
1 NAME Henry/GENET/
2 GIVN Henry
2 SURN GENET
1 SEX M
1 OCCU Trésorier PAYEUR Général
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 18:28
1 FAMS @23518@
0 @23516@ INDI
1 NAME Xxx/DOUENCE/
2 GIVN Xxx
2 SURN DOUENCE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 18:25
1 FAMS @23518@
1 FAMC @23524@
0 @23522@ INDI
1 NAME Marc/DOUENCE/
2 GIVN Marc
2 SURN DOUENCE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 18:22
1 FAMS @23524@
0 @23527@ INDI
1 NAME Claude/GENET/
2 GIVN Claude
2 SURN GENET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 18:24
1 FAMC @23518@
0 @23532@ INDI
1 NAME Bernard/GENET/
2 GIVN Bernard
2 SURN GENET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 18:24
1 FAMC @23518@
0 @23537@ INDI
1 NAME Xxx/GENET/
2 GIVN Xxx
2 SURN GENET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 18:24
1 FAMC @23518@
0 @23542@ INDI
1 NAME Patrick/GENET/
2 GIVN Patrick
2 SURN GENET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 18:25
1 FAMC @23518@
0 @23547@ INDI
1 NAME Jean-Marie/GENET/
2 GIVN Jean-Marie
2 SURN GENET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 18:25
1 FAMC @23518@
0 @23552@ INDI
1 NAME Bénédicte/GENET/
2 GIVN Bénédicte
2 SURN GENET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 18:25
1 FAMC @23518@
0 @23557@ INDI
1 NAME /GOURBILLON/
2 GIVN
2 SURN GOURBILLON
1 SEX M
1 OCCU Prêtre
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 15:28
0 @23565@ INDI
1 NAME Pierre/HAICAULT de la REGONTAIS/
2 GIVN Pierre
2 SURN HAICAULT de la REGONTAIS
1 SEX M
1 OCCU Général
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 18:48
1 FAMS @23579@
1 BIRT
2 DATE 1925
2 _FNA NO
1 DEAT
2 DATE 29 APR 2007
2 _FNA NO
0 @23577@ INDI
1 NAME Christiane/PENSEREAU/
2 GIVN Christiane
2 SURN PENSEREAU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 18:52
1 FAMS @23579@
1 FAMC @23592@
0 @23583@ INDI
1 NAME Léonce/PENSEREAU/
2 GIVN Léonce
2 SURN PENSEREAU
1 SEX M
1 OCCU COLONEL
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 18:52
1 FAMS @23592@
0 @23590@ INDI
1 NAME Denise/NAUDEAU/
2 GIVN Denise
2 SURN NAUDEAU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 18:52
1 FAMS @23592@
0 @23595@ INDI
1 NAME André/ACKER/
2 GIVN André
2 SURN ACKER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 18:58
1 FAMS @23597@
1 FAMC @23602@
0 @23600@ INDI
1 NAME Jean/ACKER/
2 GIVN Jean
2 SURN ACKER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 18:58
1 FAMS @23602@
0 @23610@ INDI
1 NAME Claude Bernard Jacques/GUERIN/
2 GIVN Claude Bernard Jacques
2 SURN GUERIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 19:08
1 FAMS @23625@
0 @23622@ INDI
1 NAME Geneviève Hélène/ROOZEBOOM/
2 GIVN Geneviève Hélène
2 SURN ROOZEBOOM
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 DEC 2018
3 TIME 19:08
1 FAMS @23625@
0 @23672@ INDI
1 NAME Antoine/de BAYSER/
2 GIVN Antoine
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 JUN 2019
3 TIME 13:22
1 BIRT
2 DATE 1939
2 _FNA NO
1 DEAT
2 DATE 24 JUN 2019
2 _FNA NO
1 BURI
2 DATE 3 JUL 2019
2 PLAC Herblay,95220,Val-d'Oise,Île-de-France,FRANCE,
3 MAP
4 LATI N48.989940
4 LONG E2.169900
2 _FNA NO
1 FAMC @11575@
0 @23691@ INDI
1 NAME Anne/de BAYSER/
2 GIVN Anne
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:34
1 FAMS @23709@
1 FAMS @32057@
1 BIRT
2 DATE 1945
2 _FNA NO
1 FAMC @11575@
0 @23707@ INDI
1 NAME Francis/LACROIX/
2 GIVN Francis
2 SURN LACROIX
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:34
1 FAMS @23709@
0 @23906@ INDI
1 NAME Fabienne/CHRISTIN/
2 GIVN Fabienne
2 SURN CHRISTIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 7:23
1 FAMS @23908@
0 @23930@ INDI
1 NAME Henri/LAGRANGE/
2 GIVN Henri
2 SURN LAGRANGE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 7:53
1 FAMS @23940@
1 FAMC @23960@
0 @23938@ INDI
1 NAME Anne-Marie/N/
2 GIVN Anne-Marie
2 SURN N
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 7:52
1 FAMS @23940@
0 @23946@ INDI
1 NAME Pierre-Felix/LAGRANGE/
2 GIVN Pierre-Felix
2 SURN LAGRANGE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 7:53
1 FAMS @23960@
1 BIRT
2 DATE 1857
2 _FNA NO
1 DEAT
2 DATE 1928
2 _FNA NO
0 @23957@ INDI
1 NAME Marie Amélie/WIDEMANN/
2 GIVN Marie Amélie
2 SURN WIDEMANN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 7:53
1 FAMS @23960@
0 @23966@ INDI
1 NAME Maurice Charles/LELIEPVRE/
2 GIVN Maurice Charles
2 SURN LELIEPVRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 17:57
1 FAMS @23981@
1 BIRT
2 DATE 1848
2 _FNA NO
1 DEAT
2 DATE 1897
2 _FNA NO
1 FAMC @24018@
0 @23977@ INDI
1 NAME Marie Victorine/LIEGEARD/
2 GIVN Marie Victorine
2 SURN LIEGEARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 18:02
1 FAMS @23981@
1 BIRT
2 DATE 1858
2 _FNA NO
1 DEAT
2 DATE 1914
2 _FNA NO
1 FAMC @24029@
0 @23985@ INDI
1 NAME Marie Thérèse/LELIEPVRE/
2 GIVN Marie Thérèse
2 SURN LELIEPVRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 8:56
1 FAMS @24150@
1 BIRT
2 DATE 1880
2 _FNA NO
1 DEAT
2 DATE 1946
2 _FNA NO
1 FAMC @23981@
0 @24000@ INDI
1 NAME Ernest-François/LELIEPVRE/
2 GIVN Ernest-François
2 SURN LELIEPVRE
1 SEX M
1 OCCU industriel
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:48
1 FAMS @24018@
1 FAMS @24287@
1 BIRT
2 DATE 25 MAR 1798
2 PLAC Amsterdam,,,,,
2 _FNA NO
1 DEAT
2 DATE 16 OCT 1856
2 PLAC Loos,59120,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.609820
4 LONG E3.018740
2 _FNA NO
1 FAMC @24104@
0 @24011@ INDI
1 NAME Élise/BERNARD/
2 GIVN Élise
2 SURN BERNARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 NOV 2019
3 TIME 11:32
1 FAMS @24018@
1 BIRT
2 DATE 1815
2 _FNA NO
1 DEAT
2 DATE 1853
2 _FNA NO
1 NOTE AGPM d’Adrienne LELIEPVRE PALADHILE
1 FAMC @24139@
0 @24021@ INDI
1 NAME Georges/LIEGEARD/
2 GIVN Georges
2 SURN LIEGEARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 8:12
1 FAMS @24029@
0 @24027@ INDI
1 NAME Sophie/LOUCHARD de BOISRENAUD/
2 GIVN Sophie
2 SURN LOUCHARD de BOISRENAUD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 8:12
1 FAMS @24029@
0 @24033@ INDI
1 NAME Alcide/PALADILHE/
2 GIVN Alcide
2 SURN PALADILHE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 8:27
1 FAMS @24052@
1 BIRT
2 DATE 1814
2 _FNA NO
1 DEAT
2 DATE 1877
2 _FNA NO
0 @24045@ INDI
1 NAME Félicité/CALON/
2 GIVN Félicité
2 SURN CALON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 8:27
1 FAMS @24052@
1 BIRT
2 DATE 15 MAR 1820
2 PLAC Nantes,44000,Loire-Atlantique,Pays de la Loire,FRANCE,
3 MAP
4 LATI N47.217250
4 LONG W1.553360
2 _FNA NO
1 DEAT
2 DATE 1907
2 _FNA NO
0 @24058@ INDI
1 NAME fille 1/PALADILHE/
2 GIVN fille 1
2 SURN PALADILHE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 8:24
1 FAMC @24052@
0 @24061@ INDI
1 NAME fille 2/PALADILHE/
2 GIVN fille 2
2 SURN PALADILHE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 8:24
1 FAMC @24052@
0 @24066@ INDI
1 NAME Jean-Baptiste François Dominique/LELIEPVRE/
2 GIVN Jean-Baptiste François Dominique
2 SURN LELIEPVRE
1 SEX M
1 OCCU écuyer
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:23
1 FAMS @24104@
1 BIRT
2 DATE 1766
2 _FNA NO
1 DEAT
2 DATE 1818
2 _FNA NO
1 FAMC @24351@
0 @24089@ INDI
1 NAME Octavie Judith Pauline/SERRET/
2 GIVN Octavie Judith Pauline
2 SURN SERRET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:23
1 FAMS @24104@
1 BIRT
2 DATE 1773
2 _FNA NO
1 DEAT
2 DATE 1849
2 _FNA NO
1 FAMC @24328@
0 @24111@ INDI
1 NAME Auguste Dominique Joseph/BERNARD/
2 GIVN Auguste Dominique Joseph
2 SURN BERNARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 NOV 2019
3 TIME 11:40
1 FAMS @24139@
1 BIRT
2 DATE 6 JUL 1772
2 PLAC Lille,59000,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.632970
4 LONG E3.058580
2 _FNA NO
1 DEAT
2 DATE 17 MAR 1859
2 PLAC Lille,59000,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.632970
4 LONG E3.058580
2 _FNA NO
0 @24132@ INDI
1 NAME Adélaïde/BEAUSSIER/
2 GIVN Adélaïde
2 SURN BEAUSSIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 NOV 2019
3 TIME 11:40
1 FAMS @24139@
1 BIRT
2 DATE 28 NOV 1787
2 PLAC Lille,59000,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.632970
4 LONG E3.058580
2 _FNA NO
1 DEAT
2 DATE 11 SEP 1859
2 PLAC Lambersart,59130,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.650000
4 LONG E3.033330
2 _FNA NO
0 @24143@ INDI
1 NAME André/DAUCHEZ/
2 GIVN André
2 SURN DAUCHEZ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 8:56
1 FAMS @24150@
1 BIRT
2 DATE 1870
2 _FNA NO
1 DEAT
2 DATE 1946
2 _FNA NO
0 @24152@ INDI
1 NAME Françoise/DAUCHEZ/
2 GIVN Françoise
2 SURN DAUCHEZ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 8:58
1 FAMS @24171@
1 BIRT
2 DATE 1899
2 _FNA NO
1 DEAT
2 DATE 1979
2 _FNA NO
1 FAMC @24150@
0 @24164@ INDI
1 NAME Pierre/HALLÉ/
2 GIVN Pierre
2 SURN HALLÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 8:58
1 FAMS @24171@
1 BIRT
2 DATE 1899
2 _FNA NO
1 DEAT
2 DATE 1985
2 _FNA NO
0 @24173@ INDI
1 NAME Francis/HALLÉ/
2 GIVN Francis
2 SURN HALLÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 9:02
1 FAMS @24181@
1 FAMC @24171@
0 @24179@ INDI
1 NAME Odile/GUEPIN/
2 GIVN Odile
2 SURN GUEPIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 9:02
1 FAMS @24181@
0 @24182@ INDI
1 NAME André/HALLÉ/
2 GIVN André
2 SURN HALLÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 9:03
1 FAMS @24206@
1 FAMC @24181@
0 @24188@ INDI
1 NAME Roman/HALLÉ/
2 GIVN Roman
2 SURN HALLÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 9:04
1 FAMS @24215@
1 FAMC @24181@
0 @24193@ INDI
1 NAME Isabelle/HALLÉ/
2 GIVN Isabelle
2 SURN HALLÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 9:05
1 FAMS @24230@
1 FAMC @24181@
0 @24198@ INDI
1 NAME Joëlle/HALLÉ/
2 GIVN Joëlle
2 SURN HALLÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 9:06
1 FAMS @24240@
1 FAMC @24181@
0 @24204@ INDI
1 NAME Juliette/BINOCHE/
2 GIVN Juliette
2 SURN BINOCHE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 9:03
1 FAMS @24206@
0 @24207@ INDI
1 NAME Raphaël/HALLÉ/
2 GIVN Raphaël
2 SURN HALLÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 9:03
1 FAMC @24206@
0 @24213@ INDI
1 NAME Catherine/VUILLEMIER/
2 GIVN Catherine
2 SURN VUILLEMIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 9:04
1 FAMS @24215@
0 @24217@ INDI
1 NAME Dava/HALLÉ/
2 GIVN Dava
2 SURN HALLÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 9:04
1 FAMC @24215@
0 @24222@ INDI
1 NAME Michaël/HALLÉ/
2 GIVN Michaël
2 SURN HALLÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 9:04
1 FAMC @24215@
0 @24228@ INDI
1 NAME Éric/BOULET/
2 GIVN Éric
2 SURN BOULET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 9:05
1 FAMS @24230@
0 @24232@ INDI
1 NAME Léa/BOULET/
2 GIVN Léa
2 SURN BOULET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 9:05
1 FAMC @24230@
0 @24238@ INDI
1 NAME Patrice/DELORME/
2 GIVN Patrice
2 SURN DELORME
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 9:06
1 FAMS @24240@
0 @24241@ INDI
1 NAME Gabrielle/DELORME/
2 GIVN Gabrielle
2 SURN DELORME
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 9:06
1 FAMC @24240@
0 @24285@ INDI
1 NAME Adèle/BERNARD/
2 GIVN Adèle
2 SURN BERNARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:48
1 FAMS @24287@
1 BIRT
2 DATE 1806
2 _FNA NO
1 DEAT
2 DATE 1837
2 _FNA NO
0 @24297@ INDI
1 NAME Paul Marie/LELIEPVRE/
2 GIVN Paul Marie
2 SURN LELIEPVRE
1 SEX M
1 OCCU Ingénieur civil des mines
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 11:26
1 FAMS @24371@
1 BIRT
2 DATE 1 APR 1842
2 PLAC Loos,59120,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.609820
4 LONG E3.018740
2 _FNA NO
1 DEAT
2 DATE 12 JAN 1901
2 PLAC Lille,59000,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.632970
4 LONG E3.058580
2 _FNA NO
1 NOTE Ingénieur civil des Mines, diplômé de l'École des Mines de Paris (1863), ingénieur à la société des Hauts-Fourneaux, forges et aciéries de Denain & Anzin, propriétaire
1 FAMC @24018@
0 @24308@ INDI
1 NAME Georges/SERRET/
2 GIVN Georges
2 SURN SERRET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 9:45
1 FAMS @24328@
1 BIRT
2 DATE 1739
2 _FNA NO
1 DEAT
2 DATE 1814
2 _FNA NO
0 @24321@ INDI
1 NAME Octavie/BECQUART/
2 GIVN Octavie
2 SURN BECQUART
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 9:45
1 FAMS @24328@
1 BIRT
2 DATE 1750
2 _FNA NO
1 DEAT
2 DATE 1833
2 _FNA NO
0 @24333@ INDI
1 NAME Nicolas/LELIEPVRE/
2 GIVN Nicolas
2 SURN LELIEPVRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 9:47
1 FAMS @24351@
1 BIRT
2 DATE 1737
2 _FNA NO
1 DEAT
2 DATE 1828
2 _FNA NO
0 @24347@ INDI
1 NAME Marie-Josephe/CANONNE/
2 GIVN Marie-Josephe
2 SURN CANONNE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 9:47
1 FAMS @24351@
1 BIRT
2 DATE 1745
2 _FNA NO
0 @24364@ INDI
1 NAME Berthe/EWBANK/
2 GIVN Berthe
2 SURN EWBANK
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 11:26
1 FAMS @24371@
1 BIRT
2 DATE 1847
2 _FNA NO
1 DEAT
2 DATE 1920
2 _FNA NO
0 @24373@ INDI
1 NAME André Édouard Charles/LELIEPVRE/
2 GIVN André Édouard Charles
2 SURN LELIEPVRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 11:20
1 FAMS @24413@
1 BIRT
2 DATE 1871
2 _FNA NO
1 DEAT
2 DATE 1955
2 _FNA NO
1 FAMC @24371@
0 @24383@ INDI
1 NAME Thérèse/LELIEPVRE/
2 GIVN Thérèse
2 SURN LELIEPVRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:06
1 FAMS @24443@
1 BIRT
2 DATE 1879
2 _FNA NO
1 DEAT
2 DATE 1966
2 _FNA NO
1 FAMC @24371@
0 @24406@ INDI
1 NAME Alice/ROQUEBERT/
2 GIVN Alice
2 SURN ROQUEBERT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 11:20
1 FAMS @24413@
1 BIRT
2 DATE 1877
2 _FNA NO
1 DEAT
2 DATE 1924
2 _FNA NO
0 @24415@ INDI
1 NAME Jacqueline/LELIEPVRE/
2 GIVN Jacqueline
2 SURN LELIEPVRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:03
1 FAMS @24433@
1 BIRT
2 DATE 1909
2 _FNA NO
1 DEAT
2 DATE 1994
2 _FNA NO
1 FAMC @24413@
0 @24426@ INDI
1 NAME Jean/BARAGNON/
2 GIVN Jean
2 SURN BARAGNON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:03
1 FAMS @24433@
1 BIRT
2 DATE 1901
2 _FNA NO
1 DEAT
2 DATE 1975
2 _FNA NO
0 @24436@ INDI
1 NAME André/LEHIDEUX/
2 GIVN André
2 SURN LEHIDEUX
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:06
1 FAMS @24443@
1 BIRT
2 DATE 1863
2 _FNA NO
1 DEAT
2 DATE 1924
2 _FNA NO
0 @24445@ INDI
1 NAME Denise/LEHIDEUX/
2 GIVN Denise
2 SURN LEHIDEUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:08
1 FAMS @24464@
1 BIRT
2 DATE 1901
2 _FNA NO
1 DEAT
2 DATE 1982
2 _FNA NO
1 FAMC @24443@
0 @24457@ INDI
1 NAME Sosthène/CLARET/
2 GIVN Sosthène
2 SURN CLARET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:08
1 FAMS @24464@
1 BIRT
2 DATE 1895
2 _FNA NO
1 DEAT
2 DATE 1957
2 _FNA NO
0 @24466@ INDI
1 NAME Edouard/CLARET/
2 GIVN Edouard
2 SURN CLARET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:09
1 FAMS @24474@
1 FAMC @24464@
0 @24472@ INDI
1 NAME Geneviève/JEANTEUR/
2 GIVN Geneviève
2 SURN JEANTEUR
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:09
1 FAMS @24474@
0 @24475@ INDI
1 NAME Sosthène/CLARET/
2 GIVN Sosthène
2 SURN CLARET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:09
1 FAMS @24483@
1 FAMC @24474@
0 @24481@ INDI
1 NAME Maguelonne/de BUCHET/
2 GIVN Maguelonne
2 SURN de BUCHET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:09
1 FAMS @24483@
0 @24485@ INDI
1 NAME Charles-Auguste/LELIEPVRE/
2 GIVN Charles-Auguste
2 SURN LELIEPVRE
1 SEX M
1 OCCU maître de forges
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 NOV 2019
3 TIME 19:30
1 FAMS @24508@
1 BIRT
2 DATE 3 MAY 1803
2 PLAC Valenciennes,59300,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.350000
4 LONG E3.533330
2 _FNA NO
2 NOTE 13 FLOREAL AN 11
1 DEAT
2 DATE 1869
2 _FNA NO
1 NOTE grand-oncle de tante Adrienne Paladhile
1 FAMC @24104@
0 @24501@ INDI
1 NAME Caroline/FÉVEZ/
2 GIVN Caroline
2 SURN FÉVEZ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 NOV 2019
3 TIME 19:30
1 FAMS @24508@
1 BIRT
2 DATE 24 APR 1818
2 _FNA NO
1 DEAT
2 DATE 1897
2 _FNA NO
0 @24511@ INDI
1 NAME Marie-Caroline/LELIEPVRE/
2 GIVN Marie-Caroline
2 SURN LELIEPVRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:28
1 FAMS @24539@
1 BIRT
2 DATE 1839
2 _FNA NO
1 DEAT
2 DATE 1876
2 _FNA NO
1 FAMC @24508@
0 @24521@ INDI
1 NAME Armand/LELIEPVRE/
2 GIVN Armand
2 SURN LELIEPVRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:39
1 FAMS @24589@
1 BIRT
2 DATE 1842
2 _FNA NO
1 DEAT
2 DATE 1901
2 _FNA NO
1 FAMC @24508@
0 @24532@ INDI
1 NAME Pierre/DUPONT/
2 GIVN Pierre
2 SURN DUPONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:28
1 FAMS @24539@
1 BIRT
2 DATE 1837
2 _FNA NO
1 DEAT
2 DATE 1902
2 _FNA NO
0 @24541@ INDI
1 NAME Henri/DUPONT/
2 GIVN Henri
2 SURN DUPONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:29
1 FAMS @24559@
1 BIRT
2 DATE 1865
2 _FNA NO
1 DEAT
2 DATE 1941
2 _FNA NO
1 FAMC @24539@
0 @24552@ INDI
1 NAME Alice/de QUILLACQ/
2 GIVN Alice
2 SURN de QUILLACQ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:29
1 FAMS @24559@
1 BIRT
2 DATE 1871
2 _FNA NO
1 DEAT
2 DATE 1937
2 _FNA NO
0 @24561@ INDI
1 NAME Roger/DUPONT/
2 GIVN Roger
2 SURN DUPONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:30
1 FAMS @24579@
1 BIRT
2 DATE 1903
2 _FNA NO
1 DEAT
2 DATE 1995
2 _FNA NO
1 FAMC @24559@
0 @24572@ INDI
1 NAME Thérèse/CORDONNIER/
2 GIVN Thérèse
2 SURN CORDONNIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:30
1 FAMS @24579@
1 BIRT
2 DATE 1911
2 _FNA NO
1 DEAT
2 DATE 2003
2 _FNA NO
0 @24582@ INDI
1 NAME Antoinette/BOURGUIGNON d'HERBIGNY/
2 GIVN Antoinette
2 SURN BOURGUIGNON d'HERBIGNY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:39
1 FAMS @24589@
1 BIRT
2 DATE 1844
2 _FNA NO
1 DEAT
2 DATE 1870
2 _FNA NO
0 @24592@ INDI
1 NAME Adrienne/LELIEPVRE/
2 GIVN Adrienne
2 SURN LELIEPVRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:45
1 FAMS @24620@
1 BIRT
2 DATE 24 NOV 1844
2 PLAC Loos,59120,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.609820
4 LONG E3.018740
2 _FNA NO
1 DEAT
2 DATE 3 JAN 1901
2 PLAC Lille,59000,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.632970
4 LONG E3.058580
2 _FNA NO
1 FAMC @24018@
0 @24602@ INDI
1 NAME Gustave Edouard/LELIEPVRE/
2 GIVN Gustave Edouard
2 SURN LELIEPVRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 16:44
1 FAMS @24655@
1 BIRT
2 DATE 29 MAR 1841
2 PLAC Loos,59120,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.609820
4 LONG E3.018740
2 _FNA NO
1 DEAT
2 DATE 19 OCT 1890
2 PLAC Valenciennes,59300,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.350000
4 LONG E3.533330
2 _FNA NO
1 FAMC @24018@
0 @24613@ INDI
1 NAME Georges/ALLARD/
2 GIVN Georges
2 SURN ALLARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:45
1 FAMS @24620@
1 BIRT
2 DATE 1837
2 _FNA NO
1 DEAT
2 DATE 1920
2 _FNA NO
0 @24623@ INDI
1 NAME Ernest/LELIEPVRE/
2 GIVN Ernest
2 SURN LELIEPVRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:46
1 BIRT
2 DATE 1826
2 _FNA NO
1 DEAT
2 DATE 1889
2 _FNA NO
1 FAMC @24287@
0 @24633@ INDI
1 NAME Albert/LELIEPVRE/
2 GIVN Albert
2 SURN LELIEPVRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:48
1 BIRT
2 DATE 1830
2 _FNA NO
1 DEAT
2 DATE 1849
2 _FNA NO
1 FAMC @24287@
0 @24648@ INDI
1 NAME Gabrielle Louise Marie/HOLLANDE/
2 GIVN Gabrielle Louise Marie
2 SURN HOLLANDE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 16:44
1 FAMS @24655@
1 BIRT
2 DATE 1845
2 _FNA NO
1 DEAT
2 DATE 1915
2 _FNA NO
0 @24656@ INDI
1 NAME Albert Charles Louis Marie Joseph/LELIEPVRE/
2 GIVN Albert Charles Louis Marie Joseph
2 SURN LELIEPVRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 16:52
1 BIRT
2 DATE 4 DEC 1866
2 PLAC Valenciennes,59300,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.350000
4 LONG E3.533330
2 _FNA NO
1 DEAT
2 DATE 23 AUG 1915
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @24655@
0 @24666@ INDI
1 NAME Jean/LELIEPVRE/
2 GIVN Jean
2 SURN LELIEPVRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 16:55
1 BIRT
2 DATE 22 MAY 1868
2 PLAC Valenciennes,59300,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.350000
4 LONG E3.533330
2 _FNA NO
1 DEAT
2 DATE 13 APR 1921
2 PLAC Angles-sur-l'Anglin,86260,Vienne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.695830
4 LONG E0.884900
2 _FNA NO
1 NOTE Célibataire
1 FAMC @24655@
0 @24676@ INDI
1 NAME Marguerite/LELIEPVRE/
2 GIVN Marguerite
2 SURN LELIEPVRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:54
1 BIRT
2 DATE 1870
2 _FNA NO
1 FAMC @24655@
0 @24683@ INDI
1 NAME Élisabeth Ernestine/LELIEPVRE/
2 GIVN Élisabeth Ernestine
2 SURN LELIEPVRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 16:48
1 FAMS @24872@
1 BIRT
2 DATE 1873
2 _FNA NO
1 DEAT
2 DATE 21 JUL 1927
2 PLAC Ghlin,7011,Hainaut,Wallonie,BELGIQUE,
3 MAP
4 LATI N50.473130
4 LONG E3.902890
2 _FNA NO
1 FAMC @24655@
0 @24690@ INDI
1 NAME Joseph/LELIEPVRE/
2 GIVN Joseph
2 SURN LELIEPVRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 10:55
1 BIRT
2 DATE 1875
2 _FNA NO
1 DEAT
2 DATE 1876
2 _FNA NO
1 FAMC @24655@
0 @24700@ INDI
1 NAME Simone/LELIEPVRE/
2 GIVN Simone
2 SURN LELIEPVRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 16:48
1 BIRT
2 DATE 1876
2 _FNA NO
1 DEAT
2 DATE 1953
2 _FNA NO
1 FAMC @24655@
0 @24710@ INDI
1 NAME Jacques Maurice Marie/LELIEPVRE/
2 GIVN Jacques Maurice Marie
2 SURN LELIEPVRE
1 SEX M
1 OCCU Secrétaire de direction
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 11:24
1 FAMS @24745@
1 BIRT
2 DATE 9 JUN 1877
2 PLAC Valenciennes,59300,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.350000
4 LONG E3.533330
2 _FNA NO
1 DEAT
2 DATE 29 JUN 1945
2 PLAC Paris 16,,,,,
2 _FNA NO
1 NOTE Secrétaire de la direction des forges & aciéries de Denain & Anzin
1 FAMC @24371@
0 @24734@ INDI
1 NAME Yvonne Marie/STOFFEL/
2 GIVN Yvonne Marie
2 SURN STOFFEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 11:24
1 FAMS @24745@
1 BIRT
2 DATE 26 FEB 1887
2 PLAC Paris 07,,,,,
2 _FNA NO
1 DEAT
2 DATE 7 JUL 1963
2 PLAC Paris 16,,,,,
2 _FNA NO
0 @24747@ INDI
1 NAME Claude/LELIEPVRE/
2 GIVN Claude
2 SURN LELIEPVRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 11:15
1 BIRT
2 DATE 1913
2 _FNA NO
1 DEAT
2 DATE 2006
2 _FNA NO
1 FAMC @24745@
0 @24760@ INDI
1 NAME Françoise/LELIEPVRE/
2 GIVN Françoise
2 SURN LELIEPVRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 11:15
1 BIRT
2 DATE 1915
2 _FNA NO
1 FAMC @24745@
0 @24770@ INDI
1 NAME Jacqueline Marie Madeleine/LELIEPVRE/
2 GIVN Jacqueline Marie Madeleine
2 SURN LELIEPVRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 11:16
1 BIRT
2 DATE 1917
2 _FNA NO
1 DEAT
2 DATE 2007
2 _FNA NO
1 FAMC @24745@
0 @24793@ INDI
1 NAME Anne Marie Renée/LELIEPVRE/
2 GIVN Anne Marie Renée
2 SURN LELIEPVRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 11:18
1 BIRT
2 DATE 1918
2 _FNA NO
1 FAMC @24745@
0 @24813@ INDI
1 NAME Marthe/LELIEPVRE/
2 GIVN Marthe
2 SURN LELIEPVRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 11:28
1 FAMS @24845@
1 BIRT
2 DATE 1870
2 _FNA NO
1 DEAT
2 DATE 1936
2 _FNA NO
1 FAMC @24371@
0 @24826@ INDI
1 NAME Pierre Henri Charles Joseph/HECQUET/
2 GIVN Pierre Henri Charles Joseph
2 SURN HECQUET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 11:28
1 FAMS @24845@
1 BIRT
2 DATE 1863
2 _FNA NO
1 DEAT
2 DATE 1928
2 _FNA NO
0 @24857@ INDI
1 NAME Albert Théodore Marie/PERIN/
2 GIVN Albert Théodore Marie
2 SURN PERIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 16:47
1 FAMS @24872@
1 BIRT
2 DATE 24 NOV 1871
2 PLAC Louvain-la-Neuve,1348,Brabant Wallon,Wallonie,BELGIQUE,
3 MAP
4 LATI N50.668290
4 LONG E4.614430
2 _FNA NO
1 DEAT
2 DATE 2 APR 1928
2 PLAC Ghlin,7011,Hainaut,Wallonie,BELGIQUE,
3 MAP
4 LATI N50.473130
4 LONG E3.902890
2 _FNA NO
0 @24919@ INDI
1 NAME Ernest Armand/LELIEPVRE/
2 GIVN Ernest Armand
2 SURN LELIEPVRE
1 SEX M
1 OCCU Directeur du Credit du Nord à Paris
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 17:46
1 FAMS @24953@
1 BIRT
2 DATE 5 JAN 1885
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
2 NOTE ou 15/01/1885?
1 DEAT
2 DATE 18 MAY 1945
2 _FNA NO
2 NOTE ou 18/08/1945?
1 FAMC @23981@
0 @24938@ INDI
1 NAME Madeleine Françoise Marie/LE VERDIER/
2 GIVN Madeleine Françoise Marie
2 SURN LE VERDIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 17:46
1 FAMS @24953@
1 BIRT
2 DATE 25 MAY 1886
2 PLAC Rouen,76000,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.443130
4 LONG E1.099320
2 _FNA NO
1 DEAT
2 DATE 20 DEC 1967
2 PLAC Paris 07,,,,,
2 _FNA NO
0 @24983@ INDI
1 NAME François Pierre Ernest/LELIEPVRE/
2 GIVN François Pierre Ernest
2 SURN LELIEPVRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 17:52
1 FAMS @25016@
1 FAMC @24953@
0 @24995@ INDI
1 NAME Marie-Jeanne/LELIEPVRE/
2 GIVN Marie-Jeanne
2 SURN LELIEPVRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 17:48
1 BIRT
2 DATE 25 AUG 1924
2 _FNA NO
1 FAMC @24953@
0 @25011@ INDI
1 NAME Jeanne France Marie-Thérèse Bernadette/FREMY/
2 GIVN Jeanne France Marie-Thérèse Bernadette
2 SURN FREMY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 17:52
1 FAMS @25016@
0 @25018@ INDI
1 NAME Claire/LELIEPVRE/
2 GIVN Claire
2 SURN LELIEPVRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 17:50
1 FAMC @25016@
0 @25028@ INDI
1 NAME Christian/LELIEPVRE/
2 GIVN Christian
2 SURN LELIEPVRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 17:51
1 FAMC @25016@
0 @25039@ INDI
1 NAME Jean-Damine/LELIEPVRE/
2 GIVN Jean-Damine
2 SURN LELIEPVRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 17:51
1 FAMC @25016@
0 @25049@ INDI
1 NAME Vincent/LELIEPVRE/
2 GIVN Vincent
2 SURN LELIEPVRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 17:52
1 FAMC @25016@
0 @25059@ INDI
1 NAME François/LELIEPVRE/
2 GIVN François
2 SURN LELIEPVRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 23 DEC 2018
3 TIME 18:01
1 BIRT
2 DATE 11 NOV 1889
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 6 SEP 1914
2 _FNA NO
2 CAUS mort pour la France
1 FAMC @23981@
0 @25082@ INDI
1 NAME Jean/ACCURSI/
2 GIVN Jean
2 SURN ACCURSI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 DEC 2018
3 TIME 20:42
1 FAMS @25092@
0 @25090@ INDI
1 NAME Élisa/HELLEVILLE/
2 GIVN Élisa
2 SURN HELLEVILLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 DEC 2018
3 TIME 20:42
1 FAMS @25092@
0 @25097@ INDI
1 NAME Louis Thomas/HURAND/
2 GIVN Louis Thomas
2 SURN HURAND
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 DEC 2018
3 TIME 20:49
1 FAMS @25106@
1 BIRT
2 DATE 1818
2 _FNA NO
1 DEAT
2 DATE 1901
2 _FNA NO
0 @25104@ INDI
1 NAME Hélène/MESTAYER/
2 GIVN Hélène
2 SURN MESTAYER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 DEC 2018
3 TIME 20:49
1 FAMS @25106@
0 @25155@ INDI
1 NAME Patrice/SEGURET/
2 GIVN Patrice
2 SURN SEGURET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 DEC 2018
3 TIME 21:55
1 FAMC @25157@
0 @25160@ INDI
1 NAME Caroline/SEGURET/
2 GIVN Caroline
2 SURN SEGURET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 DEC 2018
3 TIME 21:55
1 FAMC @25157@
0 @25167@ INDI
1 NAME Florian/BERGER/
2 GIVN Florian
2 SURN BERGER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 DEC 2018
3 TIME 22:03
1 FAMS @25169@
0 @25170@ INDI
1 NAME Violette/BERGER/
2 GIVN Violette
2 SURN BERGER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 DEC 2018
3 TIME 22:03
1 FAMC @25169@
0 @25177@ INDI
1 NAME Géraldine/DOAT/
2 GIVN Géraldine
2 SURN DOAT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 DEC 2018
3 TIME 22:06
1 FAMC @7107@
0 @25182@ INDI
1 NAME Loïc/DOAT/
2 GIVN Loïc
2 SURN DOAT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 DEC 2018
3 TIME 22:06
1 FAMC @7107@
0 @25194@ INDI
1 NAME Michèle/X/
2 GIVN Michèle
2 SURN X
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 DEC 2018
3 TIME 22:11
1 FAMS @25196@
0 @25197@ INDI
1 NAME Bruno/ACCURSI/
2 GIVN Bruno
2 SURN ACCURSI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 DEC 2018
3 TIME 22:10
1 FAMC @25196@
0 @25202@ INDI
1 NAME Sophie/ACCURSI/
2 GIVN Sophie
2 SURN ACCURSI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 DEC 2018
3 TIME 22:11
1 FAMC @25196@
0 @25368@ INDI
1 NAME Jean-Claude/FOURNIER-BOURDIER/
2 GIVN Jean-Claude
2 SURN FOURNIER-BOURDIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 11:06
1 FAMS @25377@
0 @25375@ INDI
1 NAME Marguerite/ROYER/
2 GIVN Marguerite
2 SURN ROYER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 11:06
1 FAMS @25377@
0 @25388@ INDI
1 NAME Jean Nicolas Isidore/AMSTEIN/
2 GIVN Jean Nicolas Isidore
2 SURN AMSTEIN
1 SEX M
1 OCCU Médecin
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 11:18
1 FAMS @25403@
1 FAMC @25418@
0 @25400@ INDI
1 NAME Jeanne-Marie Pétronille/JOBART/
2 GIVN Jeanne-Marie Pétronille
2 SURN JOBART
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 11:18
1 FAMS @25403@
0 @25407@ INDI
1 NAME Jean-Jacques/AMSTEIN/
2 GIVN Jean-Jacques
2 SURN AMSTEIN
1 SEX M
1 OCCU Chirurgien
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 11:11
1 FAMS @25418@
0 @25416@ INDI
1 NAME Marguerite/FAY/
2 GIVN Marguerite
2 SURN FAY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 11:11
1 FAMS @25418@
0 @25423@ INDI
1 NAME Jules/AMSTEIN/
2 GIVN Jules
2 SURN AMSTEIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 11:13
1 BIRT
2 DATE 1808
2 PLAC Charleville-Mézières,08000,Ardennes,Grand Est,FRANCE,
3 MAP
4 LATI N49.766670
4 LONG E4.716670
2 _FNA NO
1 FAMC @25403@
0 @25434@ INDI
1 NAME Aimé/AMSTEIN/
2 GIVN Aimé
2 SURN AMSTEIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 11:14
1 BIRT
2 DATE 19 MAY 1811
2 _FNA NO
1 FAMC @25403@
0 @25446@ INDI
1 NAME Léonie/AMSTEIN/
2 GIVN Léonie
2 SURN AMSTEIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 11:18
1 BIRT
2 DATE 1813
2 _FNA NO
1 FAMC @25403@
0 @25508@ INDI
1 NAME /MUAUX/
2 GIVN
2 SURN MUAUX
1 SEX U
1 OCCU Notaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 11:46
0 @25513@ INDI
1 NAME Juste Aimé/DELESALLE/
2 GIVN Juste Aimé
2 SURN DELESALLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 14:25
1 FAMS @25628@
1 BIRT
2 DATE 1802
2 _FNA NO
1 DEAT
2 DATE 17 APR 1893
2 PLAC Lille,59000,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.632970
4 LONG E3.058580
2 _FNA NO
1 FAMC @25589@
0 @25517@ INDI
1 NAME Simon Honoré/ROUSSEAU/
2 GIVN Simon Honoré
2 SURN ROUSSEAU
1 SEX M
1 OCCU Militaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 11:53
0 @25553@ INDI
1 NAME Hortense-Evelina/STEVENIN/
2 GIVN Hortense-Evelina
2 SURN STEVENIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 12:15
1 FAMS @25555@
0 @25577@ INDI
1 NAME Juste Joseph/DELESALLE/
2 GIVN Juste Joseph
2 SURN DELESALLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 15:43
1 FAMS @25589@
0 @25586@ INDI
1 NAME Adélaïde Thérèse/COLLE/
2 GIVN Adélaïde Thérèse
2 SURN COLLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 16:40
1 FAMS @25589@
1 DEAT
2 DATE 12 FEB 1820
2 PLAC Tourcoing,59200,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.723910
4 LONG E3.161170
2 _FNA NO
1 FAMC @25604@
0 @25594@ INDI
1 NAME François-Joseph/COLLE/
2 GIVN François-Joseph
2 SURN COLLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 12:29
1 FAMS @25604@
0 @25601@ INDI
1 NAME Marie-Josephe Thérèse/PEUVRON/
2 GIVN Marie-Josephe Thérèse
2 SURN PEUVRON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 12:29
1 FAMS @25604@
0 @25626@ INDI
1 NAME Henriette/HURET/
2 GIVN Henriette
2 SURN HURET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 13:13
1 FAMS @25628@
0 @25629@ INDI
1 NAME Antoine/DELESALLE/
2 GIVN Antoine
2 SURN DELESALLE
1 SEX M
1 OCCU Percepteur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 15:45
1 FAMS @30103@
1 FAMC @25589@
0 @25637@ INDI
1 NAME Henri/DELESALLE/
2 GIVN Henri
2 SURN DELESALLE
1 SEX M
1 OCCU PRINCIPAL DE COLLEGE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 13:15
1 FAMC @25589@
0 @25645@ INDI
1 NAME Adèle Flore/DELESALLE/
2 GIVN Adèle Flore
2 SURN DELESALLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 14:11
1 FAMS @25660@
1 BIRT
2 DATE 8 MAY 1800
2 PLAC Grammont,70110,Haute-Saône,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.514240
4 LONG E6.516600
2 _FNA NO
1 FAMC @25589@
0 @25658@ INDI
1 NAME Antoine/RENOUARD/
2 GIVN Antoine
2 SURN RENOUARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 14:11
1 FAMS @25660@
0 @25662@ INDI
1 NAME Jean Constant Edmond/RENOUARD/
2 GIVN Jean Constant Edmond
2 SURN RENOUARD
1 SEX M
1 OCCU militaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 14:22
1 FAMS @25688@
1 BIRT
2 DATE 23 APR 1836
2 _FNA NO
1 DEAT
2 DATE 16 FEB 1926
2 _FNA NO
1 FAMC @25660@
0 @25686@ INDI
1 NAME Marguerite/RENOUARD/
2 GIVN Marguerite
2 SURN RENOUARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 14:13
1 FAMS @25696@
1 FAMC @25688@
0 @25694@ INDI
1 NAME Alphonse/PATEL/
2 GIVN Alphonse
2 SURN PATEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 14:13
1 FAMS @25696@
0 @25699@ INDI
1 NAME Jean/RENOUARD/
2 GIVN Jean
2 SURN RENOUARD
1 SEX M
1 OCCU Militaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 14:19
1 DEAT
2 DATE 10 MAY 1915
2 PLAC Notre-Dame-de-Lorette,,,Artois,,
2 _FNA NO
2 CAUS mort pour la France
2 NOTE commandant 17°BCA
1 FAMC @25688@
0 @25712@ INDI
1 NAME Georges/RENOUARD/
2 GIVN Georges
2 SURN RENOUARD
1 SEX M
1 OCCU Militaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 15:49
1 DEAT
2 DATE 1 OCT 1918
2 PLAC Vitry-le-François,51300,Marne,Grand Est,FRANCE,
3 MAP
4 LATI N48.733330
4 LONG E4.583330
2 _FNA NO
2 CAUS mort pour la France
2 NOTE général commandant 22°division
1 FAMC @25688@
0 @25724@ INDI
1 NAME Etienne/RENOUARD/
2 GIVN Etienne
2 SURN RENOUARD
1 SEX M
1 OCCU militaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 15:49
1 DEAT
2 DATE 21 JAN 1915
2 PLAC Bois des Caures,,Meuse,,,
2 _FNA NO
2 CAUS mort pour la France
1 FAMC @25688@
0 @25738@ INDI
1 NAME Zoé/BEVIERE/
2 GIVN Zoé
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 15:57
1 FAMS @25751@
1 BIRT
2 DATE ABT 1829
2 _FNA NO
1 DEAT
2 DATE 1855
2 PLAC Valenciennes,59300,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.350000
4 LONG E3.533330
2 _FNA NO
1 FAMC @12798@
0 @25749@ INDI
1 NAME Victor/COURTOIS/
2 GIVN Victor
2 SURN COURTOIS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 14:28
1 FAMS @25751@
0 @25770@ INDI
1 NAME Louis-Augustin Philidore Porphyre/BEVIERE de SAINT FLEURY/
2 GIVN Louis-Augustin Philidore Porphyre
2 SURN BEVIERE de SAINT FLEURY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 15:33
1 FAMS @25797@
1 BIRT
2 DATE 2 DEC 1788
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 DEAT
2 DATE 6 JAN 1875
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @12816@
0 @25794@ INDI
1 NAME Augustine Josèphe/PRISSE/
2 GIVN Augustine Josèphe
2 SURN PRISSE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 15:33
1 FAMS @25797@
1 BIRT
2 DATE 1796
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 DEAT
2 DATE 20 JUL 1871
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 NOTE origine anglaise
0 @25851@ INDI
1 NAME Catherine Thérèse/DAYNEZ/
2 GIVN Catherine Thérèse
2 SURN DAYNEZ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 14:05
1 FAMS @25854@
1 BIRT
2 DATE 1733
2 _FNA NO
1 FAMC @25935@
0 @25888@ INDI
1 NAME Gilles Bruno/GOSSUIN/
2 GIVN Gilles Bruno
2 SURN GOSSUIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 13:05
1 FAMS @26880@
1 BIRT
2 DATE 9 MAR 1665
2 PLAC Étroeungt,59219,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.050000
4 LONG E3.933330
2 _FNA NO
1 DEAT
2 DATE 28 APR 1739
3 TIME 15:00
2 PLAC Étroeungt,59219,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.050000
4 LONG E3.933330
2 _FNA NO
1 FAMC @26206@
0 @25905@ INDI
1 NAME Anne-Joseph/GODEFROY/
2 GIVN Anne-Joseph
2 SURN GODEFROY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 12:55
1 FAMS @25907@
1 DEAT
2 DATE 1742
2 _FNA NO
1 NOTE OU GODEFROYD?
1 FAMC @26023@
0 @25924@ INDI
1 NAME Pierre François/DAYNEZ/
2 GIVN Pierre François
2 SURN DAYNEZ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 15:38
1 FAMS @25935@
1 FAMC @25967@
0 @25933@ INDI
1 NAME Marie-Thérèse/HANNEQUART/
2 GIVN Marie-Thérèse
2 SURN HANNEQUART
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 14:35
1 FAMS @25935@
1 DEAT
2 DATE 14 MAR 1792
2 _FNA NO
1 NOTE signe Thérèse HENCART-DAYNEZ
0 @25946@ INDI
1 NAME Pierre/DAYNEZ/
2 GIVN Pierre
2 SURN DAYNEZ
1 SEX M
1 OCCU Drapier
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 15:44
1 FAMS @25967@
1 BIRT
2 DATE 1660
2 _FNA NO
1 DEAT
2 DATE 12 MAR 1732
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
0 @25960@ INDI
1 NAME Marie-Catherine/DESSAINS/
2 GIVN Marie-Catherine
2 SURN DESSAINS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 15:46
1 FAMS @25967@
1 BIRT
2 DATE 1672
2 _FNA NO
1 DEAT
2 DATE 1748
2 _FNA NO
1 FAMC @25979@
0 @25971@ INDI
1 NAME Michel/DESSAINS/
2 GIVN Michel
2 SURN DESSAINS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 15:46
1 FAMS @25979@
0 @25977@ INDI
1 NAME Marie-Françoise/WATEAU/
2 GIVN Marie-Françoise
2 SURN WATEAU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JAN 2019
3 TIME 15:46
1 FAMS @25979@
0 @25989@ INDI
1 NAME Philippine Aldegonde Josèphe Auxilie/BEVIERE/
2 GIVN Philippine Aldegonde Josèphe Auxilie
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 18:49
1 FAMS @26010@
1 BIRT
2 DATE JUN 1753
2 _FNA NO
1 CHR
2 DATE 10 JUN 1753
2 _FNA NO
1 DEAT
2 DATE 3 MAY 1835
2 PLAC Douai,59500,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.366670
4 LONG E3.066670
2 _FNA NO
1 FAMC @12834@
0 @25995@ INDI
1 NAME Antoine François Constant/GOSSUIN/
2 GIVN Antoine François Constant
2 SURN GOSSUIN
1 SEX M
1 OCCU Avocat
1 TITL seigneur d' Angréau
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 13:00
1 NOTE député à la législative,député à la convention,député au conseil des 500, député au corps législatif , député à la chambre des 100 jours
1 FAMC @25907@
0 @26008@ INDI
1 NAME Guillaume Augustin/CANQUELAIN/
2 GIVN Guillaume Augustin
2 SURN CANQUELAIN
1 SEX M
1 OCCU Avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 14:57
1 FAMS @26010@
1 BIRT
2 DATE 22 OCT 1735
2 PLAC Le Quesnoy,59530,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.247970
4 LONG E3.636560
2 _FNA NO
1 DEAT
2 DATE 1 NOV 1812
2 PLAC Douai,59500,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.366670
4 LONG E3.066670
2 _FNA NO
1 FAMC @29230@
0 @26014@ INDI
1 NAME Nicolas/GODEFROY/
2 GIVN Nicolas
2 SURN GODEFROY
1 SEX M
1 OCCU Notaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 14:50
1 FAMS @26023@
1 BIRT
2 DATE 1663
2 _FNA NO
1 DEAT
2 DATE 1730
2 _FNA NO
2 NOTE ou 1731?
1 FAMC @29680@
0 @26021@ INDI
1 NAME Marie/DESQUENNES/
2 GIVN Marie
2 SURN DESQUENNES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 15:17
1 FAMS @26023@
1 FAMC @29784@
0 @26026@ INDI
1 NAME Charles-Joseph/GOSSUIN/
2 GIVN Charles-Joseph
2 SURN GOSSUIN
1 SEX M
1 OCCU Avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 19:33
1 FAMS @27126@
1 BIRT
2 DATE 16 MAY 1692
2 PLAC Étroeungt,59219,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.050000
4 LONG E3.933330
2 _FNA NO
1 DEAT
2 DATE 18 JAN 1752
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @26880@
0 @26032@ INDI
1 NAME Charles Louis Joseph/GOSSUIN/
2 GIVN Charles Louis Joseph
2 SURN GOSSUIN
1 SEX M
1 OCCU Avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 13:03
1 FAMC @25907@
0 @26135@ INDI
1 NAME Thomas/BEVIERE/
2 GIVN Thomas
2 SURN BEVIERE
1 SEX M
1 OCCU Avocat
1 TITL SEIGNEUR DE SAINT FLEURY
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 11:25
1 FAMS @26146@
1 BIRT
2 DATE 7 MAR 1670
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 DEAT
2 DATE 30 APR 1742
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 NOTE ancêtre missoffe
1 FAMC @26444@
0 @26143@ INDI
1 NAME Marie Catherine/BENOIT/
2 GIVN Marie Catherine
2 SURN BENOIT
1 SEX F
1 TITL SIEUR DE SAINT FLEURY
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 11:25
1 FAMS @26146@
1 BIRT
2 DATE 13 NOV 1683
2 _FNA NO
1 DEAT
2 DATE 27 SEP 1751
2 _FNA NO
1 FAMC @26454@
0 @26164@ INDI
1 NAME Marguerite/BROGNET/
2 GIVN Marguerite
2 SURN BROGNET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 14:18
1 FAMS @27298@
1 FAMS @28547@
1 FAMC @26491@
0 @26169@ INDI
1 NAME Charles/BROGNET/
2 GIVN Charles
2 SURN BROGNET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 16:34
1 FAMS @26177@
0 @26175@ INDI
1 NAME Jeanne/DUQUESNE/
2 GIVN Jeanne
2 SURN DUQUESNE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 16:34
1 FAMS @26177@
0 @26198@ INDI
1 NAME Jean/GOSSUIN/
2 GIVN Jean
2 SURN GOSSUIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 18:58
1 FAMS @26206@
1 BIRT
2 DATE 1620
2 _FNA NO
1 DEAT
2 DATE 1682
2 _FNA NO
1 FAMC @26217@
0 @26204@ INDI
1 NAME Antoinette/LESCAILLET/
2 GIVN Antoinette
2 SURN LESCAILLET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 18:58
1 FAMS @26206@
1 BIRT
2 DATE 8 JAN 1636
2 _FNA NO
0 @26209@ INDI
1 NAME Pierre/GOSSUIN/
2 GIVN Pierre
2 SURN GOSSUIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 JAN 2019
3 TIME 19:27
1 FAMS @26217@
0 @26215@ INDI
1 NAME Anne/de FLAVEGNIES/
2 GIVN Anne
2 SURN de FLAVEGNIES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 19:07
1 FAMS @26217@
1 BIRT
2 DATE 1602
2 _FNA NO
1 FAMC @27054@
0 @26224@ INDI
1 NAME Xy1/GARCIA de PARÈDES/
2 GIVN Xy1
2 SURN GARCIA de PARÈDES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 11:17
1 FAMC @12845@
0 @26229@ INDI
1 NAME Xy2/GARCIA de PARÈDES/
2 GIVN Xy2
2 SURN GARCIA de PARÈDES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 11:17
1 FAMC @12845@
0 @26235@ INDI
1 NAME Xy3/GARCIA de PARÈDES/
2 GIVN Xy3
2 SURN GARCIA de PARÈDES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 11:17
1 FAMC @12845@
0 @26241@ INDI
1 NAME Xy4/GARCIA de PARÈDES/
2 GIVN Xy4
2 SURN GARCIA de PARÈDES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 11:18
1 FAMC @12845@
0 @26248@ INDI
1 NAME Marie-Marguerite/GARCIA de PARÈDES/
2 GIVN Marie-Marguerite
2 SURN GARCIA de PARÈDES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 12:49
1 BIRT
2 DATE 1696
2 _FNA NO
1 DEAT
2 DATE 1776
2 _FNA NO
1 FAMC @12845@
0 @26258@ INDI
1 NAME Marie-Jeanne/GARCIA de PARÈDES/
2 GIVN Marie-Jeanne
2 SURN GARCIA de PARÈDES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 11:20
1 BIRT
2 DATE 1698
2 _FNA NO
1 DEAT
2 DATE 1772
2 _FNA NO
1 FAMC @12845@
0 @26270@ INDI
1 NAME Marie-Antoinette/GARCIA de PARÈDES/
2 GIVN Marie-Antoinette
2 SURN GARCIA de PARÈDES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 13:25
1 FAMS @26288@
1 FAMC @12845@
0 @26275@ INDI
1 NAME Marie-Josephe/GARCIA de PARÈDES/
2 GIVN Marie-Josephe
2 SURN GARCIA de PARÈDES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 11:25
1 FAMS @26312@
1 BIRT
2 DATE 1701
2 _FNA NO
1 DEAT
2 DATE 1785
2 _FNA NO
1 FAMC @12845@
0 @26281@ INDI
1 NAME François-Joseph/PILLOT/
2 GIVN François-Joseph
2 SURN PILLOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 17:24
1 FAMS @26288@
1 BIRT
2 DATE 1684
2 _FNA NO
1 DEAT
2 DATE 1759
2 _FNA NO
1 FAMC @29157@
0 @26297@ INDI
1 NAME Antoine François Joseph/DUMEES/
2 GIVN Antoine François Joseph
2 SURN DUMEES
1 SEX M
1 OCCU Avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 17:40
1 FAMS @26312@
1 BIRT
2 DATE 27 JUL 1722
2 PLAC Éclaibes,59330,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.202820
4 LONG E3.932320
2 _FNA NO
1 DEAT
2 DATE 27 FEB 1765
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @29164@
0 @26314@ INDI
1 NAME Marie-Jeanne Josèphe/BEVIERE/
2 GIVN Marie-Jeanne Josèphe
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 13:55
1 FAMS @26337@
1 BIRT
2 DATE 10 APR 1707
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 DEAT
2 DATE 3 JUL 1763
2 PLAC Maubeuge,59600,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.278750
4 LONG E3.972670
2 _FNA NO
1 FAMC @26146@
0 @26325@ INDI
1 NAME Antoine/BENOIT/
2 GIVN Antoine
2 SURN BENOIT
1 SEX M
1 OCCU échevin
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 11:12
1 FAMS @26454@
1 BIRT
2 DATE DEC 1639
2 _FNA NO
1 CHR
2 DATE 26 DEC 1639
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 DEAT
2 DATE ABT 1712
2 _FNA NO
2 NOTE partages succession 16/11/1712 % Me HALLET Christophe à Avesnes
1 FAMC @26466@
0 @26334@ INDI
1 NAME Nicolas Louis/PICQUERY/
2 GIVN Nicolas Louis
2 SURN PICQUERY
1 SEX M
1 OCCU Avocat
1 TITL seigneur de CUVILLERS
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 13:54
1 FAMS @26337@
1 BIRT
2 DATE 1703
2 PLAC Maubeuge,59600,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.278750
4 LONG E3.972670
2 _FNA NO
1 DEAT
2 DATE 30 SEP 1777
2 PLAC Maubeuge,59600,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.278750
4 LONG E3.972670
2 _FNA NO
1 FAMC @28707@
0 @26342@ INDI
1 NAME Catherine Françoise Irène/PICQUERY/
2 GIVN Catherine Françoise Irène
2 SURN PICQUERY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 12:16
1 FAMS @28320@
1 BIRT
2 DATE 31 AUG 1731
2 PLAC Maubeuge,59600,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.278750
4 LONG E3.972670
2 _FNA NO
1 DEAT
2 DATE 8 JAN 1773
2 PLAC Givet,08600,Ardennes,Grand Est,FRANCE,
3 MAP
4 LATI N50.137960
4 LONG E4.825450
2 _FNA NO
2 NOTE ou 1763?
1 FAMC @26337@
0 @26365@ INDI
1 NAME Louis François Joseph Jason/PICQUERY/
2 GIVN Louis François Joseph Jason
2 SURN PICQUERY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 14:33
1 FAMS @28998@
1 BIRT
2 DATE 25 AUG 1732
2 PLAC Maubeuge,59600,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.278750
4 LONG E3.972670
2 _FNA NO
1 FAMC @26337@
0 @26385@ INDI
1 NAME Antoine Benoit André Hermand/PICQUERY/
2 GIVN Antoine Benoit André Hermand
2 SURN PICQUERY
1 SEX M
1 OCCU Avocat
1 TITL seigneur de Wasronval
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 13:29
1 FAMS @29040@
1 BIRT
2 DATE 29 APR 1737
2 PLAC Maubeuge,59600,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.278750
4 LONG E3.972670
2 _FNA NO
1 FAMC @26337@
0 @26436@ INDI
1 NAME Jean/BEVIERE/
2 GIVN Jean
2 SURN BEVIERE
1 SEX M
1 OCCU Avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 JAN 2019
3 TIME 8:57
1 FAMS @26444@
1 BIRT
2 DATE 1615
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 DEAT
2 DATE 1680
2 _FNA NO
1 NOTE armoirie « d’azur à un griffon d’or » Armorial général volume des Flandres 1668
2 CONT ancêtre missoffe
1 FAMC @26707@
0 @26442@ INDI
1 NAME Jeanne/PESIN/
2 GIVN Jeanne
2 SURN PESIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 JAN 2019
3 TIME 17:54
1 FAMS @26444@
1 BIRT
2 DATE 17 AUG 1631
2 PLAC Valenciennes,59300,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.350000
4 LONG E3.533330
2 _FNA NO
1 DEAT
2 DATE 13 NOV 1712
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @26486@
0 @26452@ INDI
1 NAME Philippine/NOEL/
2 GIVN Philippine
2 SURN NOEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 11:12
1 FAMS @26454@
1 BIRT
2 DATE 16 DEC 1644
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @26538@
0 @26459@ INDI
1 NAME Jeanne/SCORION/
2 GIVN Jeanne
2 SURN SCORION
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 17:04
1 FAMS @26466@
1 FAMC @27298@
0 @26464@ INDI
1 NAME Antoine/BENOIT/
2 GIVN Antoine
2 SURN BENOIT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 17:04
1 FAMS @26466@
0 @26469@ INDI
1 NAME Antoine/SCORION/
2 GIVN Antoine
2 SURN SCORION
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 14:27
1 FAMS @28547@
0 @26479@ INDI
1 NAME Jean/PESIN/
2 GIVN Jean
2 SURN PESIN
1 SEX M
1 TITL SEIGNEUR DE SAINT FLEURY
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 14:44
1 FAMS @26486@
1 DEAT
2 PLAC Valenciennes,59300,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.350000
4 LONG E3.533330
2 _FNA NO
1 FAMC @26732@
0 @26484@ INDI
1 NAME Catherine/BROGNET/
2 GIVN Catherine
2 SURN BROGNET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 17:34
1 FAMS @26486@
1 FAMC @26491@
0 @26489@ INDI
1 NAME Jean/BROGNET/
2 GIVN Jean
2 SURN BROGNET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 16:52
1 FAMS @26491@
1 DEAT
2 DATE 14 JUN 1618
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @26510@
0 @26502@ INDI
1 NAME Nicolas/BROGNET/
2 GIVN Nicolas
2 SURN BROGNET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 17:28
1 FAMS @26510@
1 FAMC @26779@
0 @26508@ INDI
1 NAME Françoise/de FOREST/
2 GIVN Françoise
2 SURN de FOREST
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 16:42
1 FAMS @26510@
1 NOTE descendante de JESSE DE FOREST (1576-1624) qui émigre en 1623 et fondateur de la ville de NEW-YORK
0 @26530@ INDI
1 NAME Charles/NOEL/
2 GIVN Charles
2 SURN NOEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 16:12
1 FAMS @26538@
0 @26536@ INDI
1 NAME Hélène/CANIOT/
2 GIVN Hélène
2 SURN CANIOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 16:12
1 FAMS @26538@
0 @26545@ INDI
1 NAME Antoine/BENOIT/
2 GIVN Antoine
2 SURN BENOIT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 10:59
1 DEAT
2 DATE 4 JUN 1741
2 _FNA NO
1 FAMC @26454@
0 @26551@ INDI
1 NAME Marie-Hélène/BENOIT/
2 GIVN Marie-Hélène
2 SURN BENOIT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 11:05
1 DEAT
2 DATE 1746
2 _FNA NO
1 FAMC @26454@
0 @26557@ INDI
1 NAME Marie-Aldegonde/BENOIT/
2 GIVN Marie-Aldegonde
2 SURN BENOIT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 16:29
1 DEAT
2 DATE 1737
2 _FNA NO
1 FAMC @26454@
0 @26565@ INDI
1 NAME Antoine-Joseph/BEVIERE/
2 GIVN Antoine-Joseph
2 SURN BEVIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 16:32
1 BIRT
2 DATE 1 AUG 1703
2 _FNA NO
1 FAMC @26146@
0 @26574@ INDI
1 NAME Marie-Philippine/BEVIERE/
2 GIVN Marie-Philippine
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 12:10
1 BIRT
2 DATE 25 FEB 1705
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @26146@
0 @26582@ INDI
1 NAME Jean-Baptiste Joseph/BEVIERE/
2 GIVN Jean-Baptiste Joseph
2 SURN BEVIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 11:15
1 BIRT
2 DATE 19 DEC 1708
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 DEAT
2 DATE 3 MAY 1736
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 NOTE mort célibataire
1 FAMC @26146@
0 @26598@ INDI
1 NAME Jeanne Louise/BEVIERE/
2 GIVN Jeanne Louise
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 16:36
1 BIRT
2 DATE 1710
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 DEAT
2 DATE 19 MAY 1711
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @26146@
0 @26614@ INDI
1 NAME Thomas Louis/BEVIERE/
2 GIVN Thomas Louis
2 SURN BEVIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 11:16
1 BIRT
2 DATE 29 APR 1713
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 DEAT
2 DATE 18 APR 1739
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 NOTE mort célibataire
1 FAMC @26146@
0 @26630@ INDI
1 NAME Marie-Anne Antoinette/BEVIERE/
2 GIVN Marie-Anne Antoinette
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 11:17
1 BIRT
2 DATE 7 SEP 1714
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @26146@
0 @26641@ INDI
1 NAME Marie-Hélène Josèphe/BEVIERE/
2 GIVN Marie-Hélène Josèphe
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 11:17
1 BIRT
2 DATE 24 DEC 1715
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @26146@
0 @26652@ INDI
1 NAME Marie-Philippine Josèphe/BEVIERE/
2 GIVN Marie-Philippine Josèphe
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 11:18
1 BIRT
2 DATE 21 MAR 1717
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @26146@
0 @26663@ INDI
1 NAME Antoine François Joseph/BEVIERE/
2 GIVN Antoine François Joseph
2 SURN BEVIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 16:41
1 BIRT
2 DATE 13 JUN 1719
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @26146@
0 @26677@ INDI
1 NAME Christophe François Marie/BEVIERE/
2 GIVN Christophe François Marie
2 SURN BEVIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 16:42
1 BIRT
2 DATE 28 NOV 1720
2 _FNA NO
1 FAMC @26146@
0 @26699@ INDI
1 NAME Gilles/BEVIERE/
2 GIVN Gilles
2 SURN BEVIERE
1 SEX M
1 OCCU militaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 12:56
1 FAMS @26707@
1 FAMS @27405@
1 BIRT
2 DATE ABT 1590
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 DEAT
2 DATE 1643
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 NOTE sergent de la cour féodale de la terre et pairie d’Avesnes
2 CONT ancêtre missoffe
1 FAMC @30502@
0 @26705@ INDI
1 NAME Marie/LERMIGEAU/
2 GIVN Marie
2 SURN LERMIGEAU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 JAN 2019
3 TIME 19:58
1 FAMS @26707@
1 DEAT
2 DATE 1635
2 _FNA NO
1 NOTE ou LERMIZEAU ( 2 orthographes)
1 FAMC @27357@
0 @26720@ INDI
1 NAME Jean/PESIN/
2 GIVN Jean
2 SURN PESIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 17:08
1 FAMS @26732@
1 DEAT
2 DATE 15 SEP 1628
2 PLAC Valenciennes,59300,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.350000
4 LONG E3.533330
2 _FNA NO
0 @26728@ INDI
1 NAME Marie/MALLARD/
2 GIVN Marie
2 SURN MALLARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 17:08
1 FAMS @26732@
1 DEAT
2 DATE 29 MAY 1625
2 PLAC Valenciennes,59300,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.350000
4 LONG E3.533330
2 _FNA NO
0 @26735@ INDI
1 NAME Alexandre/PESIN/
2 GIVN Alexandre
2 SURN PESIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 14:57
1 FAMS @28263@
1 FAMC @26732@
0 @26740@ INDI
1 NAME Marie/PESIN/
2 GIVN Marie
2 SURN PESIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 17:09
1 FAMS @26748@
1 DEAT
2 DATE 17 AUG 1641
2 PLAC Valenciennes,59300,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.350000
4 LONG E3.533330
2 _FNA NO
1 FAMC @26732@
0 @26746@ INDI
1 NAME Jean/de LE WARDE/
2 GIVN Jean
2 SURN de LE WARDE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 17:09
1 FAMS @26748@
1 DEAT
2 DATE 21 SEP 1665
2 PLAC Valenciennes,59300,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.350000
4 LONG E3.533330
2 _FNA NO
0 @26764@ INDI
1 NAME Nicolas/BEVIERE/
2 GIVN Nicolas
2 SURN BEVIERE
1 SEX M
1 OCCU Chanoine
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 12:22
1 BIRT
2 DATE 1616
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 DEAT
2 DATE 23 MAY 1688
3 TIME 19:00
2 _FNA NO
1 FAMC @26707@
0 @26771@ INDI
1 NAME Antoine/BROGNET/
2 GIVN Antoine
2 SURN BROGNET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 17:28
1 FAMS @26779@
0 @26777@ INDI
1 NAME Marguerite/MARIN/
2 GIVN Marguerite
2 SURN MARIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 17:28
1 FAMS @26779@
0 @26782@ INDI
1 NAME Antoinette/PESIN/
2 GIVN Antoinette
2 SURN PESIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 15:56
1 FAMS @26800@
1 BIRT
2 DATE 14 SEP 1637
2 _FNA NO
1 FAMC @26486@
0 @26789@ INDI
1 NAME Marguerite/PESIN/
2 GIVN Marguerite
2 SURN PESIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 17:33
1 FAMS @26805@
1 BIRT
2 DATE 2 JUL 1634
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @26486@
0 @26798@ INDI
1 NAME Audo/de DARIES/
2 GIVN Audo
2 SURN de DARIES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 JAN 2019
3 TIME 8:51
1 FAMS @26800@
1 DEAT
2 DATE 3 NOV 1705
2 _FNA NO
1 NOTE ou d’ARIES?
0 @26803@ INDI
1 NAME Charles/JABIN/
2 GIVN Charles
2 SURN JABIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 17:33
1 FAMS @26805@
0 @26806@ INDI
1 NAME Jean/PESIN/
2 GIVN Jean
2 SURN PESIN
1 SEX M
1 OCCU Prêtre
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 17:34
1 FAMC @26486@
0 @26812@ INDI
1 NAME Robert/PESIN/
2 GIVN Robert
2 SURN PESIN
1 SEX M
1 OCCU Avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 15:28
1 DEAT
2 DATE 1679
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @26486@
0 @26818@ INDI
1 NAME Laurent/BEVIERE/
2 GIVN Laurent
2 SURN BEVIERE
1 SEX M
1 OCCU Religieux
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 17:36
1 FAMC @26444@
0 @26825@ INDI
1 NAME Jean/BEVIERE/
2 GIVN Jean
2 SURN BEVIERE
1 SEX M
1 OCCU Avocat Greffier
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 JAN 2019
3 TIME 17:58
1 FAMS @28340@
1 BIRT
2 DATE 1655
2 _FNA NO
1 DEAT
2 DATE 27 DEC 1698
2 _FNA NO
2 NOTE sans postérité
1 NOTE sans postérité
1 FAMC @26444@
0 @26830@ INDI
1 NAME Pierre/BEVIERE/
2 GIVN Pierre
2 SURN BEVIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 JAN 2019
3 TIME 18:05
1 BIRT
2 DATE 8 NOV 1663
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 DEAT
2 _FNA NO
2 NOTE ENTRE 1679 (hérite de Robert PESIN)
3 CONT ET 1696 (ne figure pas dans le testament de samère Jeanne PESIN )
1 NOTE ami intime de JB Colbert
2 CONT sans postérité
1 FAMC @26444@
0 @26835@ INDI
1 NAME Jacques François/BEVIERE/
2 GIVN Jacques François
2 SURN BEVIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 15:40
1 BIRT
2 DATE 5 DEC 1665
2 _FNA NO
1 DEAT
2 _FNA NO
2 NOTE JEUNE SANS POSTERITE
1 NOTE sans postérité
1 FAMC @26444@
0 @26842@ INDI
1 NAME Marie-Marguerite Jeanne/BEVIERE/
2 GIVN Marie-Marguerite Jeanne
2 SURN BEVIERE
1 SEX F
1 OCCU Religieuse
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 JAN 2019
3 TIME 17:40
1 BIRT
2 DATE 2 JUL 1654
2 _FNA NO
1 FAMC @26444@
0 @26850@ INDI
1 NAME Marie "Louise" Thérèse/BEVIERE/
2 GIVN Marie "Louise" Thérèse
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 JAN 2019
3 TIME 18:36
1 BIRT
2 DATE 4 AUG 1658
2 _FNA NO
1 DEAT
2 DATE 8 AUG 1728
2 _FNA NO
1 NOTE morte célibataire
1 FAMC @26444@
0 @26861@ INDI
1 NAME Jeanne-Françoise/BEVIERE/
2 GIVN Jeanne-Françoise
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 JAN 2019
3 TIME 19:00
1 FAMS @26879@
1 BIRT
2 DATE 24 MAY 1660
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 DEAT
2 DATE 1727
2 _FNA NO
1 FAMC @26444@
0 @26868@ INDI
1 NAME Marie-Françoise/BEVIERE/
2 GIVN Marie-Françoise
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 JAN 2019
3 TIME 19:38
1 FAMS @26880@
1 BIRT
2 DATE 5 JAN 1662
2 _FNA NO
1 DEAT
2 DATE 2 MAR 1740
2 PLAC Étroeungt,59219,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.050000
4 LONG E3.933330
2 _FNA NO
1 FAMC @26444@
0 @26876@ INDI
1 NAME Martin/GUISLIN/
2 GIVN Martin
2 SURN GUISLIN
1 SEX M
1 OCCU Écuyer Avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 JAN 2019
3 TIME 19:00
1 FAMS @26879@
0 @26881@ INDI
1 NAME Jeanne Christine Thérèse/BEVIERE/
2 GIVN Jeanne Christine Thérèse
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 13:14
1 FAMS @26898@
1 BIRT
2 DATE 11 APR 1668
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 DEAT
2 DATE 18 NOV 1747
2 _FNA NO
1 FAMC @26444@
0 @26895@ INDI
1 NAME Jean-Baptiste/d'ANDRÉ/
2 GIVN Jean-Baptiste
2 SURN d'ANDRÉ
1 SEX M
1 OCCU Écuyer
1 TITL seigneur de Masargues
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 13:49
1 FAMS @26898@
1 BIRT
2 DATE 25 JUN 1657
2 PLAC Aix-en-Provence,13100,Bouches-du-Rhône,Provence-Alpes-Côte d'Azur,FRANCE,
3 MAP
4 LATI N43.528300
4 LONG E5.449730
2 _FNA NO
1 DEAT
2 DATE AFT 1720
2 _FNA NO
2 NOTE le 26/05/1720 il s’opposa à la tentative de vente de « la Goulette » , héritée de Jean Bévière son oncle  , par la femme de son fils Nazet AMJ
1 NOTE joueur prodigue et débauché
1 FAMC @28453@
0 @26899@ INDI
1 NAME Marie-Jeanne/BEVIERE/
2 GIVN Marie-Jeanne
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 16:26
1 FAMS @26908@
1 BIRT
2 DATE 1673
2 _FNA NO
1 DEAT
2 DATE 19 MAR 1759
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @26444@
0 @26906@ INDI
1 NAME Jean-Baptiste/SCORION/
2 GIVN Jean-Baptiste
2 SURN SCORION
1 SEX M
1 OCCU Avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 16:26
1 FAMS @26908@
1 BIRT
2 DATE 4 FEB 1670
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 DEAT
2 DATE 13 DEC 1746
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @27290@
0 @26953@ INDI
1 NAME Nicolas Joseph/GUISLIN/
2 GIVN Nicolas Joseph
2 SURN GUISLIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 JAN 2019
3 TIME 18:56
1 BIRT
2 DATE 1693
2 _FNA NO
1 DEAT
2 DATE 14 MAR 1758
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @26879@
0 @26991@ INDI
1 NAME Jean-François/GOSSUIN/
2 GIVN Jean-François
2 SURN GOSSUIN
1 SEX M
1 OCCU Prêtre
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 JAN 2019
3 TIME 19:44
1 BIRT
2 DATE 3 MAY 1678
2 _FNA NO
2 NOTE OU 1668?
1 DEAT
2 DATE 1 JAN 1710
2 _FNA NO
1 FAMC @26206@
0 @27004@ INDI
1 NAME Philippe Joseph/GOSSUIN/
2 GIVN Philippe Joseph
2 SURN GOSSUIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 18:53
1 FAMC @26206@
0 @27012@ INDI
1 NAME Jacques-Antoine/GOSSUIN/
2 GIVN Jacques-Antoine
2 SURN GOSSUIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 18:54
1 FAMC @26206@
0 @27020@ INDI
1 NAME Anne-Philippine/GOSSUIN/
2 GIVN Anne-Philippine
2 SURN GOSSUIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 19:00
1 FAMS @27042@
1 BIRT
2 DATE 31 DEC 1653
2 _FNA NO
1 FAMC @26206@
0 @27027@ INDI
1 NAME Marie-Françoise/GOSSUIN/
2 GIVN Marie-Françoise
2 SURN GOSSUIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 18:59
1 FAMS @27037@
1 BIRT
2 DATE 16 FEB 1662
2 _FNA NO
1 FAMC @26206@
0 @27035@ INDI
1 NAME Louis/LEVESQUE de NAVARY/
2 GIVN Louis
2 SURN LEVESQUE de NAVARY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 18:59
1 FAMS @27037@
0 @27039@ INDI
1 NAME Joseph/de MARTELLY/
2 GIVN Joseph
2 SURN de MARTELLY
1 SEX M
1 OCCU Militaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 19:00
1 FAMS @27042@
0 @27046@ INDI
1 NAME Nicolas/de FLAVEGNIES/
2 GIVN Nicolas
2 SURN de FLAVEGNIES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 19:09
1 FAMS @27054@
1 FAMC @27065@
0 @27052@ INDI
1 NAME Marie/MAHIEU/
2 GIVN Marie
2 SURN MAHIEU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 19:07
1 FAMS @27054@
0 @27057@ INDI
1 NAME Antoine/de FLAVEGNIES/
2 GIVN Antoine
2 SURN de FLAVEGNIES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 19:09
1 FAMS @27065@
0 @27063@ INDI
1 NAME Anne/de CAIGNON/
2 GIVN Anne
2 SURN de CAIGNON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 19:09
1 FAMS @27065@
0 @27078@ INDI
1 NAME François-Joseph Eugène/GOSSUIN/
2 GIVN François-Joseph Eugène
2 SURN GOSSUIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 19:30
1 FAMS @27098@
1 BIRT
2 DATE 1735
2 _FNA NO
1 DEAT
2 DATE 17 SEP 1760
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @27126@
0 @27095@ INDI
1 NAME Marie-Marguerite Josèphe/PILLOT/
2 GIVN Marie-Marguerite Josèphe
2 SURN PILLOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 19:21
1 FAMS @27098@
0 @27099@ INDI
1 NAME Constant Joseph Cesar Eugène/GOSSUIN/
2 GIVN Constant Joseph Cesar Eugène
2 SURN GOSSUIN
1 SEX M
1 OCCU commissaire aux armées
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 9:41
1 BIRT
2 DATE 1758
2 _FNA NO
1 DEAT
2 DATE 1827
2 _FNA NO
1 NOTE dernier maire héréditaire d’AVESNES
2 CONT DEPUTÉ à l’ ASSEMBLEE LEGISLATIVE , à la CONVENTION, aU CONSEIL des 500
2 CONT CONVENTIONNEL
1 FAMC @27098@
0 @27119@ INDI
1 NAME Marie-Josephe/DANGOSSE de COUROUX/
2 GIVN Marie-Josephe
2 SURN DANGOSSE de COUROUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 12:51
1 FAMS @27126@
1 BIRT
2 DATE 1705
2 _FNA NO
1 DEAT
2 DATE 1740
2 _FNA NO
1 FAMC @28430@
0 @27133@ INDI
1 NAME Charles-Bruno Constant/GOSSUIN/
2 GIVN Charles-Bruno Constant
2 SURN GOSSUIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 7 JAN 2019
3 TIME 12:43
1 FAMC @27126@
0 @27138@ INDI
1 NAME Jean-Baptiste Joseph Constantin/GOSSUIN/
2 GIVN Jean-Baptiste Joseph Constantin
2 SURN GOSSUIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 14:15
1 FAMC @27126@
0 @27147@ INDI
1 NAME Bruno/GOSSUIN/
2 GIVN Bruno
2 SURN GOSSUIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 19:36
1 BIRT
2 DATE 9 JUN 1696
2 PLAC Étroeungt,59219,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.050000
4 LONG E3.933330
2 _FNA NO
1 FAMC @26880@
0 @27155@ INDI
1 NAME Thomas Philippe/GOSSUIN/
2 GIVN Thomas Philippe
2 SURN GOSSUIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 19:37
1 BIRT
2 DATE 24 OCT 1697
2 PLAC Étroeungt,59219,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.050000
4 LONG E3.933330
2 _FNA NO
1 FAMC @26880@
0 @27166@ INDI
1 NAME Marie-Jeanne/GOSSUIN/
2 GIVN Marie-Jeanne
2 SURN GOSSUIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 19:38
1 BIRT
2 DATE 6 MAY 1699
2 _FNA NO
1 FAMC @26880@
0 @27175@ INDI
1 NAME Marguerite-Françoise/GOSSUIN/
2 GIVN Marguerite-Françoise
2 SURN GOSSUIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 19:41
1 FAMS @27189@
1 BIRT
2 DATE 27 JAN 1701
2 _FNA NO
1 FAMC @26880@
0 @27185@ INDI
1 NAME Claude François Joseph/MOREILLE/
2 GIVN Claude François Joseph
2 SURN MOREILLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 19:41
1 FAMS @27189@
0 @27192@ INDI
1 NAME Louise-Sabine/MOREILLE/
2 GIVN Louise-Sabine
2 SURN MOREILLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 7 JAN 2019
3 TIME 12:14
1 BIRT
2 DATE 3 SEP 1702
2 _FNA NO
1 FAMC @27189@
0 @27201@ INDI
1 NAME Louise-Sabine/GOSSUIN/
2 GIVN Louise-Sabine
2 SURN GOSSUIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 13:02
1 FAMS @28436@
1 BIRT
2 DATE 3 SEP 1702
2 _FNA NO
1 FAMC @26880@
0 @27210@ INDI
1 NAME Françoise Gabrielle/GOSSUIN/
2 GIVN Françoise Gabrielle
2 SURN GOSSUIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 13:03
1 FAMS @28438@
1 BIRT
2 DATE 11 MAR 1704
2 _FNA NO
1 FAMC @26880@
0 @27223@ INDI
1 NAME Charles-Pierre/CHENUET de LAUNAY/
2 GIVN Charles-Pierre
2 SURN CHENUET de LAUNAY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 13:03
1 FAMS @28438@
0 @27227@ INDI
1 NAME Marie-Josephe/GOSSUIN/
2 GIVN Marie-Josephe
2 SURN GOSSUIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 19:47
1 BIRT
2 DATE 29 AUG 1706
2 _FNA NO
1 FAMC @26880@
0 @27253@ INDI
1 NAME Jean-Baptiste/d'ANDRÉ/
2 GIVN Jean-Baptiste
2 SURN d'ANDRÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 13:21
1 FAMS @28464@
1 FAMC @26898@
0 @27260@ INDI
1 NAME Pierre/d'ANDRÉ/
2 GIVN Pierre
2 SURN d'ANDRÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 13:18
1 DEAT
2 _FNA NO
2 CAUS EN SERVICE
1 FAMC @26898@
0 @27282@ INDI
1 NAME Nicaise/SCORION/
2 GIVN Nicaise
2 SURN SCORION
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 16:12
1 FAMS @27290@
1 DEAT
2 DATE 21 JAN 1706
2 _FNA NO
1 FAMC @28547@
0 @27288@ INDI
1 NAME Marie/MEURANT/
2 GIVN Marie
2 SURN MEURANT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 16:12
1 FAMS @27290@
1 FAMS @28539@
0 @27299@ INDI
1 NAME François/BROGNET/
2 GIVN François
2 SURN BROGNET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 20:12
1 FAMC @26491@
0 @27303@ INDI
1 NAME Pierre/BROGNET/
2 GIVN Pierre
2 SURN BROGNET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 JAN 2019
3 TIME 20:12
1 FAMC @26491@
0 @27319@ INDI
1 NAME Antoine/BEVIERE/
2 GIVN Antoine
2 SURN BEVIERE
1 SEX M
1 OCCU religieux
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 JAN 2019
3 TIME 20:10
1 BIRT
2 DATE 23 DEC 1639
2 _FNA NO
1 FAMC @26707@
0 @27330@ INDI
1 NAME Anne/BEVIERE/
2 GIVN Anne
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 JAN 2019
3 TIME 19:56
1 FAMS @27340@
1 BIRT
2 DATE 21 JUL 1634
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @26707@
0 @27338@ INDI
1 NAME Odo/de DARIES/
2 GIVN Odo
2 SURN de DARIES
1 SEX M
1 TITL seigneur de CAUPENE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 16:30
1 FAMS @27340@
0 @27349@ INDI
1 NAME Pierre/LERMIGEAU/
2 GIVN Pierre
2 SURN LERMIGEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 12:15
1 FAMS @27357@
0 @27355@ INDI
1 NAME Xxx/PAYEN/
2 GIVN Xxx
2 SURN PAYEN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 12:15
1 FAMS @27357@
1 FAMC @27362@
0 @27360@ INDI
1 NAME Jean/PAYEN/
2 GIVN Jean
2 SURN PAYEN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 JAN 2019
3 TIME 19:44
1 FAMS @27362@
0 @27366@ INDI
1 NAME Marguerite/BEVIERE/
2 GIVN Marguerite
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 JAN 2019
3 TIME 19:48
1 BIRT
2 DATE 4 MAY 1619
2 _FNA NO
1 FAMC @26707@
0 @27373@ INDI
1 NAME Catherine/BEVIERE/
2 GIVN Catherine
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 JAN 2019
3 TIME 9:20
1 BIRT
2 DATE 5 SEP 1629
2 _FNA NO
2 NOTE ou 1620?
1 FAMC @26707@
0 @27387@ INDI
1 NAME Pierre/BEVIERE/
2 GIVN Pierre
2 SURN BEVIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 JAN 2019
3 TIME 19:54
1 BIRT
2 DATE 19 MAY 1630
2 _FNA NO
1 FAMC @26707@
0 @27403@ INDI
1 NAME Marie/LE ROY/
2 GIVN Marie
2 SURN LE ROY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 12:09
1 FAMS @27405@
1 FAMS @28212@
0 @27407@ INDI
1 NAME Marie/BEVIERE/
2 GIVN Marie
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 JAN 2019
3 TIME 20:04
1 BIRT
2 DATE 8 NOV 1638
2 _FNA NO
1 FAMC @27405@
0 @27418@ INDI
1 NAME Élisabeth/BEVIERE/
2 GIVN Élisabeth
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 12:34
1 FAMS @28237@
1 FAMS @28244@
1 BIRT
2 DATE 28 NOV 1641
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @27405@
0 @27425@ INDI
1 NAME Marthe/de VOGÜÉ/
2 GIVN Marthe
2 SURN de VOGÜÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 9:30
1 FAMS @27434@
1 BIRT
2 DATE 1925
2 _FNA NO
1 DEAT
2 DATE 9 JAN 2019
2 _FNA NO
1 FAMC @27492@
0 @27432@ INDI
1 NAME Robert Paul Pierre/de LUPPÉ/
2 GIVN Robert Paul Pierre
2 SURN de LUPPÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 9:30
1 FAMS @27434@
1 BIRT
2 DATE 14 JUN 1922
2 _FNA NO
1 DEAT
2 DATE 21 FEB 2008
2 _FNA NO
1 FAMC @27514@
0 @27438@ INDI
1 NAME Caroline/de LUPPÉ/
2 GIVN Caroline
2 SURN de LUPPÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 9:27
1 FAMS @27520@
1 BIRT
2 DATE 1951
2 _FNA NO
1 FAMC @27434@
0 @27443@ INDI
1 NAME Olivier/de LUPPÉ/
2 GIVN Olivier
2 SURN de LUPPÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 8:30
1 FAMC @27434@
0 @27448@ INDI
1 NAME Delphine/de LUPPÉ/
2 GIVN Delphine
2 SURN de LUPPÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 9:17
1 FAMS @27456@
1 FAMC @27434@
0 @27454@ INDI
1 NAME Frédéric/de LACHEZE-MUREL/
2 GIVN Frédéric
2 SURN de LACHEZE-MUREL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 9:17
1 FAMS @27456@
0 @27468@ INDI
1 NAME Robert Jean/de VOGÜÉ/
2 GIVN Robert Jean
2 SURN de VOGÜÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2019
3 TIME 10:26
1 FAMS @27492@
1 BIRT
2 DATE 3 AUG 1896
2 PLAC Menetou-Salon,18510,Cher,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N47.231720
4 LONG E2.487150
2 _FNA NO
1 DEAT
2 DATE 17 OCT 1976
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @35888@
0 @27485@ INDI
1 NAME Ghislaine/EUDES d'EUDEVILLE/
2 GIVN Ghislaine
2 SURN EUDES d'EUDEVILLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 9:16
1 FAMS @27492@
1 BIRT
2 DATE 1905
2 _FNA NO
1 DEAT
2 DATE 1992
2 _FNA NO
0 @27496@ INDI
1 NAME Albert Marie Pierre/de LUPPÉ/
2 GIVN Albert Marie Pierre
2 SURN de LUPPÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 9:36
1 FAMS @27514@
1 BIRT
2 DATE 1893
2 _FNA NO
1 DEAT
2 DATE 1970
2 _FNA NO
0 @27507@ INDI
1 NAME Marthe Edmée Claire/de VOGÜÉ/
2 GIVN Marthe Edmée Claire
2 SURN de VOGÜÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 9:36
1 FAMS @27514@
1 BIRT
2 DATE 21 MAY 1901
2 PLAC Paris 07,,,,,
2 _FNA NO
1 DEAT
2 DATE 8 NOV 1963
2 PLAC Lamorlaye,60260,Oise,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.162460
4 LONG E2.446870
2 _FNA NO
0 @27518@ INDI
1 NAME Gilles/BERALDI/
2 GIVN Gilles
2 SURN BERALDI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 9:26
1 FAMS @27520@
0 @27521@ INDI
1 NAME Antoine/BERALDI/
2 GIVN Antoine
2 SURN BERALDI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 9:25
1 FAMC @27520@
0 @27526@ INDI
1 NAME Philippe/BERALDI/
2 GIVN Philippe
2 SURN BERALDI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 9:26
1 FAMC @27520@
0 @27531@ INDI
1 NAME François/BERALDI/
2 GIVN François
2 SURN BERALDI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 9:26
1 FAMC @27520@
0 @27563@ INDI
1 NAME Pierre Charles Jean/de LUPPÉ/
2 GIVN Pierre Charles Jean
2 SURN de LUPPÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 9:43
1 FAMS @27624@
1 BIRT
2 DATE 1921
2 _FNA NO
1 FAMC @27514@
0 @27580@ INDI
1 NAME Lucie Marthe Laure/de LUPPÉ/
2 GIVN Lucie Marthe Laure
2 SURN de LUPPÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 9:38
1 FAMS @27608@
1 BIRT
2 DATE 1924
2 _FNA NO
1 FAMC @27514@
0 @27598@ INDI
1 NAME Roland Paul Jean Marie/d'ANETHAN/
2 GIVN Roland Paul Jean Marie
2 SURN d'ANETHAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 9:38
1 FAMS @27608@
1 BIRT
2 DATE 1920
2 _FNA NO
0 @27612@ INDI
1 NAME Ghislaine Marie Aymardine Jeanne Édith/de NICOLAY/
2 GIVN Ghislaine Marie Aymardine Jeanne Édith
2 SURN de NICOLAY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 9:43
1 FAMS @27624@
1 BIRT
2 DATE 1922
2 _FNA NO
0 @27626@ INDI
1 NAME Christian Albert Marie Pierre Daniel/de LUPPÉ/
2 GIVN Christian Albert Marie Pierre Daniel
2 SURN de LUPPÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 9:42
1 FAMC @27624@
0 @27645@ INDI
1 NAME Isabelle Lucie Marie/de LUPPÉ/
2 GIVN Isabelle Lucie Marie
2 SURN de LUPPÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 9:43
1 FAMC @27624@
0 @27660@ INDI
1 NAME Anne Marthe Marie/de LUPPÉ/
2 GIVN Anne Marthe Marie
2 SURN de LUPPÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 9:43
1 FAMC @27624@
0 @27708@ INDI
1 NAME Pierre Paul Victurnien François/de LA ROCHEFOUCAULD/
2 GIVN Pierre Paul Victurnien François
2 SURN de LA ROCHEFOUCAULD
1 SEX M
1 TITL COMTE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:29
1 FAMS @27752@
1 BIRT
2 DATE 1887
2 _FNA NO
1 DEAT
2 DATE 1970
2 _FNA NO
1 FAMC @27954@
0 @27737@ INDI
1 NAME Henriette Marguerite Marie/de LA ROCHE/
2 GIVN Henriette Marguerite Marie
2 SURN de LA ROCHE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:31
1 FAMS @27752@
1 BIRT
2 DATE 1892
2 _FNA NO
1 DEAT
2 DATE 1980
2 _FNA NO
1 FAMC @27981@
0 @27760@ INDI
1 NAME Urbain/de LA ROCHEFOUCAULD/
2 GIVN Urbain
2 SURN de LA ROCHEFOUCAULD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 9:56
1 FAMC @18236@
0 @27765@ INDI
1 NAME Clémentine/de LA ROCHEFOUCAULD/
2 GIVN Clémentine
2 SURN de LA ROCHEFOUCAULD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 9:57
1 FAMC @18236@
0 @27770@ INDI
1 NAME Alexandre/de LA ROCHEFOUCAULD/
2 GIVN Alexandre
2 SURN de LA ROCHEFOUCAULD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 9:57
1 FAMC @18236@
0 @27775@ INDI
1 NAME Philippe/de LA ROCHEFOUCAULD/
2 GIVN Philippe
2 SURN de LA ROCHEFOUCAULD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 9:57
1 FAMC @18236@
0 @27780@ INDI
1 NAME Roger Alexandre Lucien François/de LA ROCHEFOUCAULD/
2 GIVN Roger Alexandre Lucien François
2 SURN de LA ROCHEFOUCAULD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:03
1 BIRT
2 DATE 1915
2 _FNA NO
1 DEAT
2 DATE 1970
2 _FNA NO
1 FAMC @27752@
0 @27810@ INDI
1 NAME Georges/de LA ROCHEFOUCAULD/
2 GIVN Georges
2 SURN de LA ROCHEFOUCAULD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2019
3 TIME 6:51
1 FAMS @36574@
1 BIRT
2 DATE 22 APR 1917
2 PLAC Saulzais-le-Potier,18360,Cher,Centre-Val de Loire,FRANCE,CHATEAU LA LANDE
3 MAP
4 LATI N46.599240
4 LONG E2.496380
2 _FNA NO
1 DEAT
2 DATE 7 JAN 2002
2 PLAC La Barre,,,,,
2 _FNA NO
1 FAMC @27752@
0 @27825@ INDI
1 NAME Marie Jeanne Louise Françoise/de LA ROCHEFOUCAULD/
2 GIVN Marie Jeanne Louise Françoise
2 SURN de LA ROCHEFOUCAULD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:05
1 BIRT
2 DATE 1920
2 _FNA NO
1 DEAT
2 DATE 1920
2 _FNA NO
1 FAMC @27752@
0 @27855@ INDI
1 NAME Bernard/de LA ROCHEFOUCAULD/
2 GIVN Bernard
2 SURN de LA ROCHEFOUCAULD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:06
1 BIRT
2 DATE 1922
2 _FNA NO
1 FAMC @27752@
0 @27867@ INDI
1 NAME Geoffroy/de LA ROCHEFOUCAULD/
2 GIVN Geoffroy
2 SURN de LA ROCHEFOUCAULD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:06
1 BIRT
2 DATE 1926
2 _FNA NO
1 FAMC @27752@
0 @27881@ INDI
1 NAME Virginie/LEMANISSIER/
2 GIVN Virginie
2 SURN LEMANISSIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:12
1 FAMS @27883@
0 @27886@ INDI
1 NAME Sixte/GEFFRIER/
2 GIVN Sixte
2 SURN GEFFRIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:13
1 FAMS @27888@
0 @27914@ INDI
1 NAME Alexandre Jules Paul Philippe François/de LA ROCHEFOUCAULD/
2 GIVN Alexandre Jules Paul Philippe François
2 SURN de LA ROCHEFOUCAULD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:29
1 FAMS @27954@
1 BIRT
2 DATE 1854
2 _FNA NO
1 DEAT
2 DATE 1930
2 _FNA NO
0 @27949@ INDI
1 NAME Jeanne Antoinette Virginie Victurnienne/de ROCHECHOUART de MORTEMART/
2 GIVN Jeanne Antoinette Virginie Victurnienne
2 SURN de ROCHECHOUART de MORTEMART
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:29
1 FAMS @27954@
0 @27963@ INDI
1 NAME Henri Jean Georges Marie/de LA ROCHE/
2 GIVN Henri Jean Georges Marie
2 SURN de LA ROCHE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:31
1 FAMS @27981@
0 @27977@ INDI
1 NAME Jeanne Louise Henriette/de PERTHUIS de LAILLEVAULT/
2 GIVN Jeanne Louise Henriette
2 SURN de PERTHUIS de LAILLEVAULT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:31
1 FAMS @27981@
0 @28008@ INDI
1 NAME Louis Armand/RITTER de ZAHONI/
2 GIVN Louis Armand
2 SURN RITTER de ZAHONI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:46
1 FAMS @28027@
1 BIRT
2 DATE 1906
2 _FNA NO
1 DEAT
2 DATE 1997
2 _FNA NO
0 @28021@ INDI
1 NAME Françoise de Paule Rose Marguerite Marie-Thérèse/LEFÈVRE d'ORMESSON/
2 GIVN Françoise de Paule Rose Marguerite Marie-Thérèse
2 SURN LEFÈVRE d'ORMESSON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:47
1 FAMS @28027@
1 BIRT
2 DATE 1914
2 _FNA NO
1 DEAT
2 DATE 2004
2 _FNA NO
0 @28131@ INDI
1 NAME Sébastien/BERNARD de MONTESSUS de BALLORE/
2 GIVN Sébastien
2 SURN BERNARD de MONTESSUS de BALLORE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:54
1 FAMS @28134@
0 @28136@ INDI
1 NAME Melchior/BERNARD de MONTESSUS de BALLORE/
2 GIVN Melchior
2 SURN BERNARD de MONTESSUS de BALLORE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:55
1 FAMC @28134@
0 @28143@ INDI
1 NAME Alexis/BERNARD de MONTESSUS de BALLORE/
2 GIVN Alexis
2 SURN BERNARD de MONTESSUS de BALLORE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:54
1 FAMC @28134@
0 @28154@ INDI
1 NAME Alain Jean Marie Joseph/MAURIAC/
2 GIVN Alain Jean Marie Joseph
2 SURN MAURIAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:59
1 FAMS @28172@
0 @28167@ INDI
1 NAME Monique Marie Thérèse Jacqueline/CAZENTRE/
2 GIVN Monique Marie Thérèse Jacqueline
2 SURN CAZENTRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 10:59
1 FAMS @28172@
0 @28185@ INDI
1 NAME Robin/MAURIAC/
2 GIVN Robin
2 SURN MAURIAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 11:04
1 FAMC @14030@
0 @28191@ INDI
1 NAME Paul/MAURIAC/
2 GIVN Paul
2 SURN MAURIAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 11:04
1 FAMC @14030@
0 @28198@ INDI
1 NAME Marin/MAURIAC/
2 GIVN Marin
2 SURN MAURIAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 11:04
1 FAMC @14030@
0 @28210@ INDI
1 NAME Dominique/CAMUS/
2 GIVN Dominique
2 SURN CAMUS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 12:09
1 FAMS @28212@
0 @28216@ INDI
1 NAME Jeanne/LERMIGEAU/
2 GIVN Jeanne
2 SURN LERMIGEAU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 12:16
1 FAMS @28224@
1 FAMC @27357@
0 @28222@ INDI
1 NAME Antoine/de FONTAINE/
2 GIVN Antoine
2 SURN de FONTAINE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 12:18
1 FAMS @28224@
1 DEAT
2 DATE 5 OCT 1669
2 _FNA NO
0 @28235@ INDI
1 NAME Balthazar/BOUDEAUX/
2 GIVN Balthazar
2 SURN BOUDEAUX
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 12:34
1 FAMS @28237@
0 @28240@ INDI
1 NAME Jean/TROUSSEAU/
2 GIVN Jean
2 SURN TROUSSEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 12:29
1 FAMS @28244@
1 DEAT
2 DATE 18 MAR 1695
2 _FNA NO
0 @28246@ INDI
1 NAME Pierre/TROUSSEAU/
2 GIVN Pierre
2 SURN TROUSSEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 12:29
1 BIRT
2 DATE 22 AUG 1678
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @28244@
0 @28253@ INDI
1 NAME Jean/BOUDEAUX/
2 GIVN Jean
2 SURN BOUDEAUX
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 12:34
1 FAMC @28237@
0 @28261@ INDI
1 NAME Jeanne/HARDY/
2 GIVN Jeanne
2 SURN HARDY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 14:42
1 FAMS @28263@
0 @28274@ INDI
1 NAME Robert/de DARIES/
2 GIVN Robert
2 SURN de DARIES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 15:55
1 FAMC @26800@
0 @28279@ INDI
1 NAME Charles Antoine/de DARIES/
2 GIVN Charles Antoine
2 SURN de DARIES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 17:22
1 FAMC @26800@
0 @28284@ INDI
1 NAME Pierre/de DARIES/
2 GIVN Pierre
2 SURN de DARIES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 JAN 2019
3 TIME 15:56
1 FAMC @26800@
0 @28301@ INDI
1 NAME Christophe François Joseph Florent/de MAURAIGE/
2 GIVN Christophe François Joseph Florent
2 SURN de MAURAIGE
1 SEX M
1 TITL seigneur de WARLU
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 14:39
1 FAMS @28320@
1 BIRT
2 DATE 8 NOV 1725
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 DEAT
2 DATE 3 DEC 1769
2 PLAC Givet,08600,Ardennes,Grand Est,FRANCE,
3 MAP
4 LATI N50.137960
4 LONG E4.825450
2 _FNA NO
2 NOTE OU Charlemont?
1 FAMC @29043@
0 @28338@ INDI
1 NAME Jeanne-Louise/?/
2 GIVN Jeanne-Louise
2 SURN ?
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 JAN 2019
3 TIME 17:48
1 FAMS @28340@
0 @28350@ INDI
1 NAME Gabrielle Anne Françoise/GUISLIN/
2 GIVN Gabrielle Anne Françoise
2 SURN GUISLIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 JAN 2019
3 TIME 18:53
1 FAMC @26879@
0 @28357@ INDI
1 NAME Charles Joseph Emmanuel/GUISLIN/
2 GIVN Charles Joseph Emmanuel
2 SURN GUISLIN
1 SEX M
1 OCCU Avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 JAN 2019
3 TIME 18:57
1 BIRT
2 DATE 1729
2 _FNA NO
1 DEAT
2 DATE 1790
2 _FNA NO
1 FAMC @28394@
0 @28389@ INDI
1 NAME Jean-Baptiste/GUISLIN/
2 GIVN Jean-Baptiste
2 SURN GUISLIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 JAN 2019
3 TIME 19:00
1 FAMS @28394@
1 FAMC @26879@
0 @28400@ INDI
1 NAME Marie-Joseph/GUISLIN/
2 GIVN Marie-Joseph
2 SURN GUISLIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 JAN 2019
3 TIME 19:00
1 FAMC @26879@
0 @28420@ INDI
1 NAME Philippe Charles/DANGOSSE de COUROUX/
2 GIVN Philippe Charles
2 SURN DANGOSSE de COUROUX
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 12:51
1 FAMS @28430@
0 @28428@ INDI
1 NAME Marie/DIESME/
2 GIVN Marie
2 SURN DIESME
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 17:21
1 FAMS @28430@
1 FAMC @29147@
0 @28433@ INDI
1 NAME Adrien Joseph/MARIN/
2 GIVN Adrien Joseph
2 SURN MARIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 13:02
1 FAMS @28436@
0 @28445@ INDI
1 NAME Balthazar/d'ANDRÉ/
2 GIVN Balthazar
2 SURN d'ANDRÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 13:10
1 FAMS @28453@
0 @28451@ INDI
1 NAME Claude/de SIGNY/
2 GIVN Claude
2 SURN de SIGNY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 13:10
1 FAMS @28453@
0 @28461@ INDI
1 NAME Anne-Marie Josèphe/NAZET/
2 GIVN Anne-Marie Josèphe
2 SURN NAZET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 13:21
1 FAMS @28464@
0 @28465@ INDI
1 NAME Jeanne Josèphe/d'ANDRÉ/
2 GIVN Jeanne Josèphe
2 SURN d'ANDRÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 7:01
1 FAMS @28486@
1 BIRT
2 DATE 1722
2 _FNA NO
1 DEAT
2 DATE 1752
2 _FNA NO
1 FAMC @28464@
0 @28483@ INDI
1 NAME François-Joseph/PILLOT/
2 GIVN François-Joseph
2 SURN PILLOT
1 SEX M
1 OCCU Directeur HOPITAL MILITAIRE D'AVESNES
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 7:01
1 FAMS @28486@
1 BIRT
2 DATE 1717
2 _FNA NO
1 DEAT
2 DATE 1769
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @26288@
0 @28493@ INDI
1 NAME Nicolas François/PILLOT/
2 GIVN Nicolas François
2 SURN PILLOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 13:41
1 BIRT
2 DATE 1719
2 _FNA NO
1 DEAT
2 DATE 1743
2 _FNA NO
1 FAMC @26288@
0 @28515@ INDI
1 NAME Jacques Marie Antoine/SCORION/
2 GIVN Jacques Marie Antoine
2 SURN SCORION
1 SEX M
1 OCCU avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 16:18
1 FAMS @28598@
1 BIRT
2 DATE 31 MAR 1668
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 DEAT
2 DATE 1735
2 _FNA NO
1 FAMC @27290@
0 @28526@ INDI
1 NAME Marie-Philippe/SCORION/
2 GIVN Marie-Philippe
2 SURN SCORION
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 13:58
1 FAMS @28536@
1 FAMC @27290@
0 @28533@ INDI
1 NAME Pierre-François/PRESEAU/
2 GIVN Pierre-François
2 SURN PRESEAU
1 SEX M
1 OCCU avocat
1 TITL SEIGNEUR DE POTEL
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 16:13
1 FAMS @28536@
0 @28538@ INDI
1 NAME /de SALLENGRE/
2 GIVN
2 SURN de SALLENGRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 14:01
1 FAMS @28539@
0 @28541@ INDI
1 NAME Charles-Eugène/de SALLENGRE/
2 GIVN Charles-Eugène
2 SURN de SALLENGRE
1 SEX M
1 OCCU curé de saint-hilaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 14:01
1 FAMC @28539@
0 @28560@ INDI
1 NAME Marie-Catherine/SCORION/
2 GIVN Marie-Catherine
2 SURN SCORION
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 16:12
1 FAMC @27290@
0 @28583@ INDI
1 NAME Jacques Marie Antoine/SCORION/
2 GIVN Jacques Marie Antoine
2 SURN SCORION
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 16:24
1 FAMS @28626@
1 BIRT
2 DATE 1700
2 _FNA NO
1 DEAT
2 DATE 1736
2 _FNA NO
1 FAMC @28598@
0 @28606@ INDI
1 NAME Louis-Philippe/SCORION/
2 GIVN Louis-Philippe
2 SURN SCORION
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 16:19
1 FAMS @28622@
1 BIRT
2 DATE 1700
2 _FNA NO
1 DEAT
2 DATE 1736
2 _FNA NO
1 FAMC @28598@
0 @28618@ INDI
1 NAME Marie Jacqueline Josèphe/FABRY/
2 GIVN Marie Jacqueline Josèphe
2 SURN FABRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 16:19
1 FAMS @28622@
0 @28624@ INDI
1 NAME Marguerite/BOUTRY/
2 GIVN Marguerite
2 SURN BOUTRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 16:24
1 FAMS @28626@
0 @28635@ INDI
1 NAME Jeanne/BROGNET/
2 GIVN Jeanne
2 SURN BROGNET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 16:56
1 FAMS @28646@
1 FAMC @26491@
0 @28640@ INDI
1 NAME Jean/de MAURAIGE/
2 GIVN Jean
2 SURN de MAURAIGE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 16:56
1 FAMS @28646@
1 BIRT
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 DEAT
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
0 @28647@ INDI
1 NAME Christophe François/de MAURAIGE/
2 GIVN Christophe François
2 SURN de MAURAIGE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 16:57
1 FAMS @28664@
1 BIRT
2 DATE 2 NOV 1638
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 DEAT
2 DATE 24 MAY 1717
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 FAMC @28646@
0 @28662@ INDI
1 NAME Marie-Agnes/GAUTHIER/
2 GIVN Marie-Agnes
2 SURN GAUTHIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 16:57
1 FAMS @28664@
0 @28673@ INDI
1 NAME Jean-François/BENOIT/
2 GIVN Jean-François
2 SURN BENOIT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 JAN 2019
3 TIME 17:30
1 FAMC @26454@
0 @28684@ INDI
1 NAME Marie-Philippine/BENOIT/
2 GIVN Marie-Philippine
2 SURN BENOIT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 11:12
1 FAMC @26454@
0 @28706@ INDI
1 NAME /PICQUERY/
2 GIVN
2 SURN PICQUERY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 11:41
1 FAMS @28707@
0 @28710@ INDI
1 NAME Marie-Josephe/PICQUERY/
2 GIVN Marie-Josephe
2 SURN PICQUERY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 11:45
1 FAMS @28718@
1 FAMC @28707@
0 @28714@ INDI
1 NAME Philippe François Théophile/HENNET/
2 GIVN Philippe François Théophile
2 SURN HENNET
1 SEX M
1 OCCU Député
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 11:46
1 FAMS @28718@
1 NOTE Député AUX ETATS GENERAUX
0 @28720@ INDI
1 NAME Philémon/HENNET/
2 GIVN Philémon
2 SURN HENNET
1 SEX M
1 OCCU Écuyer
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 11:44
1 BIRT
2 DATE 1701
2 _FNA NO
1 DEAT
2 DATE 1768
2 _FNA NO
1 FAMC @28718@
0 @28735@ INDI
1 NAME François Augustin Pompée/HENNET/
2 GIVN François Augustin Pompée
2 SURN HENNET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 9:11
1 BIRT
2 DATE 1728
2 _FNA NO
1 DEAT
2 DATE 1792
2 _FNA NO
1 FAMC @28718@
0 @28775@ INDI
1 NAME Christophe François Joseph/de MAURAIGE/
2 GIVN Christophe François Joseph
2 SURN de MAURAIGE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 14:39
1 FAMS @29043@
1 BIRT
2 DATE 1695
2 _FNA NO
1 DEAT
2 DATE 1725
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
0 @28799@ INDI
1 NAME Sabine Claire/de SAINT-THOMAS/
2 GIVN Sabine Claire
2 SURN de SAINT-THOMAS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 14:44
1 FAMS @29043@
1 BIRT
2 DATE 1698
2 _FNA NO
1 DEAT
2 DATE 1773
2 _FNA NO
1 FAMC @28826@
0 @28817@ INDI
1 NAME Pierre/de SAINT-THOMAS/
2 GIVN Pierre
2 SURN de SAINT-THOMAS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 11:57
1 FAMS @28826@
0 @28824@ INDI
1 NAME Ferdinande/PICQUERY/
2 GIVN Ferdinande
2 SURN PICQUERY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 11:57
1 FAMS @28826@
0 @28845@ INDI
1 NAME Christophe Athanase Joseph Philémon/de MAURAIGE/
2 GIVN Christophe Athanase Joseph Philémon
2 SURN de MAURAIGE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 12:05
1 BIRT
2 DATE 2 NOV 1756
2 PLAC Maubeuge,59600,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.278750
4 LONG E3.972670
2 _FNA NO
1 FAMC @28320@
0 @28866@ INDI
1 NAME Christophe Marie Maurice Venceslas/de MAURAIGE/
2 GIVN Christophe Marie Maurice Venceslas
2 SURN de MAURAIGE
1 SEX M
1 OCCU Militaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 12:08
1 BIRT
2 DATE 1758
2 _FNA NO
1 DEAT
2 DATE 1778
2 _FNA NO
1 FAMC @28320@
0 @28893@ INDI
1 NAME Christophe Philippe Berard/de MAURAIGE/
2 GIVN Christophe Philippe Berard
2 SURN de MAURAIGE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 12:10
1 BIRT
2 DATE 15 MAR 1759
2 PLAC Maubeuge,59600,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.278750
4 LONG E3.972670
2 _FNA NO
1 DEAT
2 DATE 1823
2 _FNA NO
1 FAMC @28320@
0 @28931@ INDI
1 NAME Christophe Joseph Philémon/de MAURAIGE/
2 GIVN Christophe Joseph Philémon
2 SURN de MAURAIGE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 12:13
1 BIRT
2 DATE 6 JAN 1762
2 PLAC Givet,08600,Ardennes,Grand Est,FRANCE,
3 MAP
4 LATI N50.137960
4 LONG E4.825450
2 _FNA NO
1 DEAT
2 DATE 7 DEC 1790
2 PLAC Anor,59186,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.989990
4 LONG E4.100490
2 _FNA NO
1 FAMC @28320@
0 @28957@ INDI
1 NAME Claire Laurenca Iraïs/de MAURAIGE/
2 GIVN Claire Laurenca Iraïs
2 SURN de MAURAIGE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 12:15
1 BIRT
2 DATE 20 SEP 1755
2 PLAC Maubeuge,59600,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.278750
4 LONG E3.972670
2 _FNA NO
1 FAMC @28320@
0 @28978@ INDI
1 NAME Marie-Christine Angeline Astérie/de MAURAIGE/
2 GIVN Marie-Christine Angeline Astérie
2 SURN de MAURAIGE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 12:16
1 FAMC @28320@
0 @28994@ INDI
1 NAME Marie-Adelaide Françoise Josèphe/DU BELLOY/
2 GIVN Marie-Adelaide Françoise Josèphe
2 SURN DU BELLOY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 12:20
1 FAMS @28998@
0 @29005@ INDI
1 NAME Marie-Joseph Enestine/BALICQ/
2 GIVN Marie-Joseph Enestine
2 SURN BALICQ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 13:29
1 FAMS @29040@
1 FAMC @29058@
0 @29012@ INDI
1 NAME Philippe Louis Joseph Athanase/BALICQ de SCIRY/
2 GIVN Philippe Louis Joseph Athanase
2 SURN BALICQ de SCIRY
1 SEX M
1 TITL seigneur de SCIRY
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 13:16
1 FAMS @29058@
1 BIRT
2 DATE 1715
2 _FNA NO
1 DEAT
2 DATE 1775
2 _FNA NO
0 @29055@ INDI
1 NAME Sabine Claire/de SAINT-THOMAS/
2 GIVN Sabine Claire
2 SURN de SAINT-THOMAS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 14:42
1 FAMS @29058@
0 @29068@ INDI
1 NAME Sébastien/GARCIA de PARÈDES/
2 GIVN Sébastien
2 SURN GARCIA de PARÈDES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 16:43
1 FAMS @29075@
1 DEAT
2 DATE 1653
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
0 @29073@ INDI
1 NAME Marguerite/BROGNET/
2 GIVN Marguerite
2 SURN BROGNET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 16:41
1 FAMS @29075@
1 FAMC @26177@
0 @29084@ INDI
1 NAME Guillaume Albert/GARCIA de PARÈDES/
2 GIVN Guillaume Albert
2 SURN GARCIA de PARÈDES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 16:39
1 BIRT
2 DATE 1644
2 _FNA NO
1 FAMC @29075@
0 @29095@ INDI
1 NAME Jean-Sébastien/GARCIA de PARÈDES/
2 GIVN Jean-Sébastien
2 SURN GARCIA de PARÈDES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 16:40
1 BIRT
2 DATE 1648
2 _FNA NO
1 FAMC @29075@
0 @29103@ INDI
1 NAME Antoine-François/GARCIA de PARÈDES/
2 GIVN Antoine-François
2 SURN GARCIA de PARÈDES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 16:44
1 FAMC @29075@
0 @29108@ INDI
1 NAME Marie-Anne/GARCIA de PARÈDES/
2 GIVN Marie-Anne
2 SURN GARCIA de PARÈDES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 16:41
1 FAMC @29075@
0 @29119@ INDI
1 NAME Gilles/PILLOT/
2 GIVN Gilles
2 SURN PILLOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 17:24
1 FAMS @29157@
1 FAMC @29136@
0 @29127@ INDI
1 NAME Jean/PILLOT/
2 GIVN Jean
2 SURN PILLOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 17:12
1 FAMS @29136@
0 @29134@ INDI
1 NAME Hiltrud/de MARTIGNY/
2 GIVN Hiltrud
2 SURN de MARTIGNY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 17:12
1 FAMS @29136@
0 @29139@ INDI
1 NAME François/DIESME/
2 GIVN François
2 SURN DIESME
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 17:13
1 FAMS @29147@
0 @29145@ INDI
1 NAME Anne/de HENIN/
2 GIVN Anne
2 SURN de HENIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 17:13
1 FAMS @29147@
0 @29155@ INDI
1 NAME Marie/DIESME/
2 GIVN Marie
2 SURN DIESME
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 17:24
1 FAMS @29157@
0 @29160@ INDI
1 NAME Louis/DUMEES/
2 GIVN Louis
2 SURN DUMEES
1 SEX M
1 TITL seigneur de LA MOTTE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 17:33
1 FAMS @29164@
1 BIRT
2 DATE 2 FEB 1687
2 _FNA NO
1 DEAT
2 DATE 30 SEP 1732
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
0 @29213@ INDI
1 NAME Guillaume/CANQUELAIN/
2 GIVN Guillaume
2 SURN CANQUELAIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 18:34
1 FAMS @29230@
1 BIRT
2 DATE 1710
2 _FNA NO
2 NOTE ?
1 DEAT
2 DATE 1757
2 _FNA NO
1 FAMC @29349@
0 @29228@ INDI
1 NAME Rose Elisabeth Dorothée/VERMEILLE/
2 GIVN Rose Elisabeth Dorothée
2 SURN VERMEILLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 18:10
1 FAMS @29230@
1 BIRT
2 DATE 1703
2 _FNA NO
1 DEAT
2 DATE 1752
2 _FNA NO
1 FAMC @29252@
0 @29234@ INDI
1 NAME François Augustin/VERMEILLE/
2 GIVN François Augustin
2 SURN VERMEILLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 18:08
1 FAMS @29252@
1 BIRT
2 DATE 1676
2 _FNA NO
1 DEAT
2 DATE 1720
2 _FNA NO
0 @29250@ INDI
1 NAME Marie-Françoise/LALOU/
2 GIVN Marie-Françoise
2 SURN LALOU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 18:08
1 FAMS @29252@
0 @29257@ INDI
1 NAME Charles-François Humbert/VERMEILLE/
2 GIVN Charles-François Humbert
2 SURN VERMEILLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 18:06
1 BIRT
2 DATE 15 AUG 1700
2 PLAC Vendegies-au-Bois,59218,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.178450
4 LONG E3.579400
2 _FNA NO
1 FAMC @29252@
0 @29272@ INDI
1 NAME Humbertine Augustine/VERMEILLE/
2 GIVN Humbertine Augustine
2 SURN VERMEILLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 18:07
1 BIRT
2 DATE 1697
2 _FNA NO
1 DEAT
2 DATE 1737
2 _FNA NO
1 FAMC @29252@
0 @29289@ INDI
1 NAME Anne-Françoise/VERMEILLE/
2 GIVN Anne-Françoise
2 SURN VERMEILLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 18:08
1 BIRT
2 DATE 1698
2 _FNA NO
1 DEAT
2 DATE 1778
2 _FNA NO
1 FAMC @29252@
0 @29319@ INDI
1 NAME Guillaume/CANQUELAIN/
2 GIVN Guillaume
2 SURN CANQUELAIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 18:14
1 BIRT
2 DATE 9 MAR 1648
2 _FNA NO
0 @29327@ INDI
1 NAME François Gabriel/CANQUELAIN/
2 GIVN François Gabriel
2 SURN CANQUELAIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 18:31
1 FAMS @29349@
1 BIRT
2 DATE 22 DEC 1681
2 _FNA NO
0 @29338@ INDI
1 NAME Jeanne Thérèse/MARISCAL/
2 GIVN Jeanne Thérèse
2 SURN MARISCAL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 18:31
1 FAMS @29349@
1 BIRT
2 DATE 1679
2 _FNA NO
1 DEAT
2 DATE 1755
2 _FNA NO
0 @29354@ INDI
1 NAME Marie-Françoise/CANQUELAIN/
2 GIVN Marie-Françoise
2 SURN CANQUELAIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 18:27
1 BIRT
2 DATE 1705
2 _FNA NO
1 DEAT
2 DATE 1724
2 _FNA NO
1 FAMC @29349@
0 @29366@ INDI
1 NAME Marie-Josephe/CANQUELAIN/
2 GIVN Marie-Josephe
2 SURN CANQUELAIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 18:28
1 BIRT
2 DATE 1709
2 _FNA NO
1 DEAT
2 DATE 1713
2 _FNA NO
1 FAMC @29349@
0 @29378@ INDI
1 NAME Jean-Baptiste/CANQUELAIN/
2 GIVN Jean-Baptiste
2 SURN CANQUELAIN
1 SEX M
1 OCCU Curé
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 18:29
1 BIRT
2 DATE 19 APR 1708
2 _FNA NO
1 DEAT
2 DATE 4 JUL 1766
2 PLAC Valenciennes,59300,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.350000
4 LONG E3.533330
2 _FNA NO
1 FAMC @29349@
0 @29391@ INDI
1 NAME François Gabriel/CANQUELAIN/
2 GIVN François Gabriel
2 SURN CANQUELAIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 18:31
1 BIRT
2 DATE 24 MAR 1712
2 _FNA NO
1 FAMC @29349@
0 @29408@ INDI
1 NAME Clothilde Auxilie/CANQUELAIN/
2 GIVN Clothilde Auxilie
2 SURN CANQUELAIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 18:48
1 BIRT
2 DATE 1796
2 _FNA NO
1 DEAT
2 DATE 1873
2 _FNA NO
1 FAMC @26010@
0 @29427@ INDI
1 NAME Guillaume Alexandre/CANQUELAIN/
2 GIVN Guillaume Alexandre
2 SURN CANQUELAIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 JAN 2019
3 TIME 18:49
1 BIRT
2 DATE 1786
2 _FNA NO
1 DEAT
2 DATE 1869
2 _FNA NO
1 FAMC @26010@
0 @29467@ INDI
1 NAME Philippe Louis Benoît/PICQUERY/
2 GIVN Philippe Louis Benoît
2 SURN PICQUERY
1 SEX M
1 OCCU Militaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 13:20
1 FAMS @29488@
1 BIRT
2 DATE 19 FEB 1764
2 PLAC Maubeuge,59600,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.278750
4 LONG E3.972670
2 _FNA NO
1 FAMC @29040@
0 @29484@ INDI
1 NAME Rose Sabine Adrienne/BALICQ/
2 GIVN Rose Sabine Adrienne
2 SURN BALICQ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 13:16
1 FAMS @29488@
0 @29492@ INDI
1 NAME Pierre Benoît Dassé/PICQUERY/
2 GIVN Pierre Benoît Dassé
2 SURN PICQUERY
1 SEX M
1 OCCU Militaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 13:24
1 BIRT
2 DATE 28 JAN 1767
2 PLAC Maubeuge,59600,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.278750
4 LONG E3.972670
2 _FNA NO
1 DEAT
2 DATE 31 JAN 1838
2 _FNA NO
1 FAMC @29040@
0 @29522@ INDI
1 NAME Xxx/PICQUERY/
2 GIVN Xxx
2 SURN PICQUERY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 13:24
0 @29530@ INDI
1 NAME Xxx/PICQUERY/
2 GIVN Xxx
2 SURN PICQUERY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 13:28
1 FAMS @29548@
1 FAMC @29040@
0 @29541@ INDI
1 NAME Bernard Nicolas/GUYOT de la COUR/
2 GIVN Bernard Nicolas
2 SURN GUYOT de la COUR
1 SEX M
1 OCCU Militaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 13:28
1 FAMS @29548@
1 BIRT
2 DATE 25 JAN 1771
2 _FNA NO
0 @29551@ INDI
1 NAME Xxx2/PICQUERY/
2 GIVN Xxx2
2 SURN PICQUERY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 13:24
1 FAMS @29566@
1 BIRT
2 DATE 13 AUG 1777
2 _FNA NO
1 FAMC @29040@
0 @29562@ INDI
1 NAME Etienne Grégoire/CAZENEUVE/
2 GIVN Etienne Grégoire
2 SURN CAZENEUVE
1 SEX M
1 OCCU Militaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 13:25
1 FAMS @29566@
0 @29605@ INDI
1 NAME Sabine Josèphe/GOSSUIN/
2 GIVN Sabine Josèphe
2 SURN GOSSUIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 12:52
1 FAMS @29629@
1 BIRT
2 DATE 1723
2 _FNA NO
1 DEAT
2 DATE 1760
2 _FNA NO
1 FAMC @25907@
0 @29616@ INDI
1 NAME Daniel Nicolas Louis Thadée Mathieu/O'FAREL/
2 GIVN Daniel Nicolas Louis Thadée Mathieu
2 SURN O'FAREL
1 SEX M
1 OCCU Écuyer
1 TITL seigneur de LISLÉE
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 14:32
1 FAMS @29629@
1 DEAT
2 DATE 13 APR 1816
2 PLAC Brunswick,,,,,
2 _FNA NO
0 @29639@ INDI
1 NAME Louise Marie-Françoise/GOSSUIN/
2 GIVN Louise Marie-Françoise
2 SURN GOSSUIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 12:45
1 FAMS @29658@
1 BIRT
2 DATE 1717
2 _FNA NO
1 DEAT
2 DATE 1756
2 _FNA NO
1 FAMC @25907@
0 @29650@ INDI
1 NAME Antoine-François/GODEFROY/
2 GIVN Antoine-François
2 SURN GODEFROY
1 SEX M
1 OCCU Avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 12:45
1 FAMS @29658@
1 BIRT
2 DATE 1721
2 _FNA NO
1 DEAT
2 DATE 1791
2 _FNA NO
1 FAMC @29669@
0 @29660@ INDI
1 NAME Thomas/GODEFROY/
2 GIVN Thomas
2 SURN GODEFROY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 14:54
1 FAMS @29669@
1 FAMC @29680@
0 @29667@ INDI
1 NAME Marie-Alexandrine/de ROCQUIGNIES/
2 GIVN Marie-Alexandrine
2 SURN de ROCQUIGNIES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 14:41
1 FAMS @29669@
0 @29672@ INDI
1 NAME Nicolas/GODEFROY/
2 GIVN Nicolas
2 SURN GODEFROY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 14:56
1 FAMS @29680@
0 @29678@ INDI
1 NAME Marguerite/SCULFORT/
2 GIVN Marguerite
2 SURN SCULFORT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 14:56
1 FAMS @29680@
0 @29684@ INDI
1 NAME Toussaint/GODEFROY/
2 GIVN Toussaint
2 SURN GODEFROY
1 SEX M
1 OCCU Gouverneur de CHIMAY
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 14:52
1 FAMC @29680@
0 @29690@ INDI
1 NAME Georges/GODEFROY/
2 GIVN Georges
2 SURN GODEFROY
1 SEX M
1 OCCU Notaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 14:53
1 FAMS @29698@
1 FAMC @29680@
0 @29696@ INDI
1 NAME Catherine/SCULFORT/
2 GIVN Catherine
2 SURN SCULFORT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 14:53
1 FAMS @29698@
0 @29702@ INDI
1 NAME Anne/GODEFROY/
2 GIVN Anne
2 SURN GODEFROY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 14:55
1 BIRT
2 DATE 1661
2 _FNA NO
1 FAMC @29680@
0 @29709@ INDI
1 NAME Marie-Catherine/GODEFROY/
2 GIVN Marie-Catherine
2 SURN GODEFROY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 14:56
1 BIRT
2 DATE 1664
2 _FNA NO
1 FAMC @29680@
0 @29716@ INDI
1 NAME François Louis/GODEFROY/
2 GIVN François Louis
2 SURN GODEFROY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 15:08
1 FAMS @29774@
1 BIRT
2 DATE 1752
2 _FNA NO
1 DEAT
2 DATE 1803
2 _FNA NO
1 FAMC @29658@
0 @29731@ INDI
1 NAME Constant Joseph/GODEFROY/
2 GIVN Constant Joseph
2 SURN GODEFROY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 15:10
1 FAMS @29778@
1 BIRT
2 DATE 1754
2 _FNA NO
1 DEAT
2 DATE 1803
2 _FNA NO
1 FAMC @29658@
0 @29746@ INDI
1 NAME Louise Constance/GODEFROY/
2 GIVN Louise Constance
2 SURN GODEFROY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 15:05
1 FAMS @29768@
1 FAMC @29658@
0 @29753@ INDI
1 NAME Théodore Joseph Louis/PILLOT/
2 GIVN Théodore Joseph Louis
2 SURN PILLOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 6:57
1 FAMS @29768@
1 BIRT
2 DATE 1754
2 _FNA NO
1 DEAT
2 DATE 1815
2 _FNA NO
1 FAMC @29818@
0 @29772@ INDI
1 NAME A.m.f.a/CHEVALLIER/
2 GIVN A.m.f.a
2 SURN CHEVALLIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 NOV 2019
3 TIME 18:42
1 FAMS @29774@
0 @29776@ INDI
1 NAME M.l.j.j/PILLOT/
2 GIVN M.l.j.j
2 SURN PILLOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 NOV 2019
3 TIME 18:42
1 FAMS @29778@
0 @29782@ INDI
1 NAME Brice/DESQUENNES/
2 GIVN Brice
2 SURN DESQUENNES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 JAN 2019
3 TIME 15:17
1 FAMS @29784@
0 @29803@ INDI
1 NAME Louis François Joseph/PILLOT/
2 GIVN Louis François Joseph
2 SURN PILLOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 7:01
1 FAMS @29818@
1 FAMC @28486@
0 @29815@ INDI
1 NAME Marie-Thérèse Théodore/JEANMAR/
2 GIVN Marie-Thérèse Théodore
2 SURN JEANMAR
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 6:57
1 FAMS @29818@
0 @29838@ INDI
1 NAME Marie-Louise/GOSSUIN/
2 GIVN Marie-Louise
2 SURN GOSSUIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 12:43
1 FAMS @29866@
1 BIRT
2 DATE 1716
2 _FNA NO
1 DEAT
2 DATE 1793
2 _FNA NO
1 FAMC @25907@
0 @29850@ INDI
1 NAME Charles François Joseph/JANMAR/
2 GIVN Charles François Joseph
2 SURN JANMAR
1 SEX M
1 OCCU Trésorier
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 12:43
1 FAMS @29866@
1 BIRT
2 DATE 1704
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 DEAT
2 DATE 1779
2 _FNA NO
0 @29868@ INDI
1 NAME Marie-Joseph Constance/GOSSUIN/
2 GIVN Marie-Joseph Constance
2 SURN GOSSUIN
1 SEX F
1 OCCU Religieuse
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 13:59
1 BIRT
2 DATE 1719
2 _FNA NO
1 DEAT
2 DATE 1808
2 _FNA NO
1 FAMC @25907@
0 @29885@ INDI
1 NAME Jeanne Joseph/GOSSUIN/
2 GIVN Jeanne Joseph
2 SURN GOSSUIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 12:50
1 FAMS @29906@
1 BIRT
2 DATE 1720
2 _FNA NO
1 DEAT
2 DATE 1800
2 _FNA NO
1 FAMC @25907@
0 @29902@ INDI
1 NAME Claude François/PERRENOT/
2 GIVN Claude François
2 SURN PERRENOT
1 SEX M
1 OCCU Écuyer
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 12:50
1 FAMS @29906@
0 @29927@ INDI
1 NAME Françoise Louise Joseph Félicité/GOSSUIN/
2 GIVN Françoise Louise Joseph Félicité
2 SURN GOSSUIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 12:54
1 BIRT
2 DATE 1723
2 _FNA NO
1 DEAT
2 DATE 1730
2 _FNA NO
1 FAMC @25907@
0 @29954@ INDI
1 NAME Anne-Louise Josèphe/GOSSUIN/
2 GIVN Anne-Louise Josèphe
2 SURN GOSSUIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 12:55
1 BIRT
2 DATE 1728
2 _FNA NO
1 DEAT
2 DATE 1759
2 _FNA NO
1 FAMC @25907@
0 @30017@ INDI
1 NAME Catherine/GOSSUIN/
2 GIVN Catherine
2 SURN GOSSUIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 15:38
1 FAMS @30028@
1 FAMC @25854@
0 @30025@ INDI
1 NAME Alexandre Hyacinthe/HAZARD/
2 GIVN Alexandre Hyacinthe
2 SURN HAZARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 15:38
1 FAMS @30028@
0 @30060@ INDI
1 NAME Léon Henri Alexandre/BEVIERE de SAINT FLEURY/
2 GIVN Léon Henri Alexandre
2 SURN BEVIERE de SAINT FLEURY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 15:33
1 FAMS @30078@
1 BIRT
2 DATE 2 MAR 1833
2 PLAC Valenciennes,59300,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.350000
4 LONG E3.533330
2 _FNA NO
1 FAMC @25797@
0 @30076@ INDI
1 NAME Jeanne/BEVIERE de SAINT FLEURY/
2 GIVN Jeanne
2 SURN BEVIERE de SAINT FLEURY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 15:35
1 FAMS @30088@
1 FAMC @30078@
0 @30085@ INDI
1 NAME Charles/DAIREAUX/
2 GIVN Charles
2 SURN DAIREAUX
1 SEX M
1 OCCU MAIRE DE COUTANCES
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 15:35
1 FAMS @30088@
0 @30090@ INDI
1 NAME Alexandre Joseph/HAZARD/
2 GIVN Alexandre Joseph
2 SURN HAZARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 15:39
1 FAMC @30028@
0 @30102@ INDI
1 NAME /MME VEUVE DUQUESNOY/
2 GIVN
2 SURN MME VEUVE DUQUESNOY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 15:45
1 FAMS @30103@
0 @30112@ INDI
1 NAME Paul/FOURNIER-BOURDIER/
2 GIVN Paul
2 SURN FOURNIER-BOURDIER
1 SEX M
1 OCCU PREFET
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 16:17
1 FAMS @30121@
1 FAMC @12771@
0 @30119@ INDI
1 NAME Marie/PAYOT/
2 GIVN Marie
2 SURN PAYOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 27 JAN 2019
3 TIME 16:17
1 FAMS @30121@
0 @30151@ INDI
1 NAME Jean/BEVIERE/
2 GIVN Jean
2 SURN BEVIERE
1 SEX M
1 OCCU Drapier
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 12:07
1 FAMS @30162@
1 BIRT
2 DATE ABT 1550
2 PLAC Montcornet,02340,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.695260
4 LONG E4.016670
2 _FNA NO
1 DEAT
2 DATE 1618
2 PLAC Montcornet,02340,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.695260
4 LONG E4.016670
2 _FNA NO
0 @30160@ INDI
1 NAME Barbe/DEVAUX/
2 GIVN Barbe
2 SURN DEVAUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 12:07
1 FAMS @30162@
0 @30164@ INDI
1 NAME Ambroise/BEVIERE/
2 GIVN Ambroise
2 SURN BEVIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:34
1 FAMS @30174@
1 BIRT
2 DATE 1571
2 _FNA NO
1 FAMC @30162@
0 @30172@ INDI
1 NAME Madeleine/VAUDIN/
2 GIVN Madeleine
2 SURN VAUDIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:34
1 FAMS @30174@
0 @30177@ INDI
1 NAME Xy1/BEVIERE/
2 GIVN Xy1
2 SURN BEVIERE
1 SEX M
1 OCCU religieux
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:06
1 BIRT
2 DATE 1597
2 _FNA NO
1 DEAT
2 PLAC Soissons,02200,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.381670
4 LONG E3.323610
2 _FNA NO
1 FAMC @30174@
0 @30187@ INDI
1 NAME Louis/BEVIERE/
2 GIVN Louis
2 SURN BEVIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:20
1 FAMS @30196@
1 BIRT
2 DATE 1599
2 _FNA NO
1 FAMC @30174@
0 @30194@ INDI
1 NAME Antoinette/VAUDIN/
2 GIVN Antoinette
2 SURN VAUDIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:20
1 FAMS @30196@
0 @30198@ INDI
1 NAME Anne/BEVIERE/
2 GIVN Anne
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:17
1 FAMS @30213@
1 BIRT
2 DATE 1657
2 _FNA NO
1 DEAT
2 DATE 18 OCT 1721
2 PLAC Aubenton,02500,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.837080
4 LONG E4.204470
2 _FNA NO
1 FAMC @30196@
0 @30209@ INDI
1 NAME Jean/DORMAY/
2 GIVN Jean
2 SURN DORMAY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:17
1 FAMS @30213@
1 DEAT
2 DATE 1692
2 PLAC Aubenton,02500,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.837080
4 LONG E4.204470
2 _FNA NO
0 @30215@ INDI
1 NAME Claude/BEVIERE/
2 GIVN Claude
2 SURN BEVIERE
1 SEX M
1 OCCU prieur de l'abbaye du mont saint martin de LAON
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:15
1 BIRT
2 DATE 1600
2 _FNA NO
1 DEAT
2 DATE 4 MAR 1694
2 PLAC Laon,02000,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.566670
4 LONG E3.616670
2 _FNA NO
1 FAMC @30174@
0 @30225@ INDI
1 NAME Jean-Louis/DORMAY/
2 GIVN Jean-Louis
2 SURN DORMAY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:17
1 FAMC @30213@
0 @30231@ INDI
1 NAME Marguerite/BEVIERE/
2 GIVN Marguerite
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:26
1 FAMS @30242@
1 BIRT
2 DATE 1654
2 PLAC Vervins,02140,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.833330
4 LONG E3.900000
2 _FNA NO
1 FAMC @30196@
0 @30239@ INDI
1 NAME Claude/CAPPE/
2 GIVN Claude
2 SURN CAPPE
1 SEX M
1 OCCU Avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:23
1 FAMS @30242@
0 @30244@ INDI
1 NAME Jean-Claude/CAPPE/
2 GIVN Jean-Claude
2 SURN CAPPE
1 SEX M
1 OCCU Avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:25
1 FAMS @30257@
1 BIRT
2 PLAC Laon,02000,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.566670
4 LONG E3.616670
2 _FNA NO
1 FAMC @30242@
0 @30253@ INDI
1 NAME Marie-Antoine Théodore/CAPPE/
2 GIVN Marie-Antoine Théodore
2 SURN CAPPE
1 SEX M
1 OCCU Avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:25
1 FAMC @30257@
0 @30262@ INDI
1 NAME Michel/BEVIERE/
2 GIVN Michel
2 SURN BEVIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:41
1 FAMS @30309@
1 BIRT
2 DATE ABT 1600
2 PLAC Vervins,02140,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.833330
4 LONG E3.900000
2 _FNA NO
1 FAMC @30162@
0 @30267@ INDI
1 NAME Pierre/BEVIERE/
2 GIVN Pierre
2 SURN BEVIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:27
1 FAMC @30162@
0 @30272@ INDI
1 NAME Gérard/BEVIERE/
2 GIVN Gérard
2 SURN BEVIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:28
1 FAMC @30162@
0 @30277@ INDI
1 NAME Jean/BEVIERE/
2 GIVN Jean
2 SURN BEVIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:28
1 FAMC @30162@
0 @30282@ INDI
1 NAME Martin/BEVIERE/
2 GIVN Martin
2 SURN BEVIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 12:23
1 FAMS @30420@
1 FAMC @30162@
0 @30287@ INDI
1 NAME Barbe/BEVIERE/
2 GIVN Barbe
2 SURN BEVIERE
1 SEX F
1 OCCU Religieuse
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:31
1 DEAT
2 DATE 20 MAY 1666
2 PLAC Soissons,02200,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.381670
4 LONG E3.323610
2 _FNA NO
1 FAMC @30174@
0 @30295@ INDI
1 NAME Marguerite/BEVIERE/
2 GIVN Marguerite
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:37
1 BIRT
2 DATE 1630
2 PLAC Vervins,02140,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.833330
4 LONG E3.900000
2 _FNA NO
1 FAMC @30174@
0 @30307@ INDI
1 NAME Jeanne/VAUDIN/
2 GIVN Jeanne
2 SURN VAUDIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:41
1 FAMS @30309@
0 @30310@ INDI
1 NAME Xy1/BEVIERE/
2 GIVN Xy1
2 SURN BEVIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:45
1 FAMS @30323@
1 FAMC @30309@
0 @30315@ INDI
1 NAME Charles/BEVIERE/
2 GIVN Charles
2 SURN BEVIERE
1 SEX M
1 OCCU Conseiller DU ROI
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:52
1 FAMS @30328@
1 BIRT
2 DATE 1653
2 PLAC Montcornet,02340,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.695260
4 LONG E4.016670
2 _FNA NO
1 DEAT
2 DATE 1713
2 PLAC Montcornet,02340,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.695260
4 LONG E4.016670
2 _FNA NO
1 FAMC @30323@
0 @30326@ INDI
1 NAME Catherine/LOREAU/
2 GIVN Catherine
2 SURN LOREAU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:52
1 FAMS @30328@
0 @30330@ INDI
1 NAME Ambroise/BEVIERE/
2 GIVN Ambroise
2 SURN BEVIERE
1 SEX M
1 OCCU Notaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:47
1 FAMS @30348@
1 BIRT
2 DATE 1643
2 _FNA NO
1 DEAT
2 DATE 1693
2 PLAC Montcornet,02340,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.695260
4 LONG E4.016670
2 _FNA NO
1 FAMC @30323@
0 @30340@ INDI
1 NAME Charles/BEVIERE/
2 GIVN Charles
2 SURN BEVIERE
1 SEX M
1 OCCU Notaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:47
1 BIRT
2 DATE 1673
2 PLAC Montcornet,02340,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.695260
4 LONG E4.016670
2 _FNA NO
1 DEAT
2 DATE 1745
2 PLAC Montcornet,02340,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.695260
4 LONG E4.016670
2 _FNA NO
1 FAMC @30348@
0 @30351@ INDI
1 NAME Pierre/BEVIERE/
2 GIVN Pierre
2 SURN BEVIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:54
1 FAMS @30358@
1 FAMC @30328@
0 @30356@ INDI
1 NAME Claude/BEVIERE/
2 GIVN Claude
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 11:56
1 FAMS @30358@
1 FAMC @30371@
0 @30360@ INDI
1 NAME François/BEVIERE/
2 GIVN François
2 SURN BEVIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 12:02
1 FAMS @30371@
1 BIRT
2 DATE 1643
2 _FNA NO
1 DEAT
2 DATE 1720
2 PLAC Chaourse,02340,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.704700
4 LONG E3.999110
2 _FNA NO
0 @30370@ INDI
1 NAME /?/
2 GIVN
2 SURN ?
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 12:02
1 FAMS @30371@
0 @30375@ INDI
1 NAME François Pierre/BEVIERE/
2 GIVN François Pierre
2 SURN BEVIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 12:00
1 BIRT
2 DATE 1685
2 PLAC Chaourse,02340,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.704700
4 LONG E3.999110
2 _FNA NO
1 DEAT
2 DATE 1753
2 PLAC La Ville-aux-Bois-lès-Dizy,02340,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.661840
4 LONG E3.992000
2 _FNA NO
1 FAMC @30371@
0 @30391@ INDI
1 NAME Louis-François/BEVIERE/
2 GIVN Louis-François
2 SURN BEVIERE
1 SEX M
1 OCCU Curé
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 12:03
1 BIRT
2 DATE 1692
2 _FNA NO
1 DEAT
2 PLAC Sissonne,02150,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.571070
4 LONG E3.893690
2 _FNA NO
1 FAMC @30371@
0 @30401@ INDI
1 NAME Nicole/BEVIERE/
2 GIVN Nicole
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 12:08
1 FAMS @30416@
1 DEAT
2 DATE 1680
2 PLAC Chaourse,02340,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.704700
4 LONG E3.999110
2 _FNA NO
1 FAMC @30162@
0 @30410@ INDI
1 NAME Nicolas/d'ENNEQUIN/
2 GIVN Nicolas
2 SURN d'ENNEQUIN
1 SEX M
1 OCCU Receveur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 12:08
1 FAMS @30416@
1 BIRT
2 PLAC Chaourse,02340,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.704700
4 LONG E3.999110
2 _FNA NO
0 @30418@ INDI
1 NAME Pierre/BEVIERE/
2 GIVN Pierre
2 SURN BEVIERE
1 SEX M
1 OCCU Avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 13:21
1 FAMS @30431@
1 BIRT
2 DATE ABT 1619
2 _FNA NO
1 NOTE ami des colbert
1 FAMC @30420@
0 @30423@ INDI
1 NAME Pierre/BEVIERE/
2 GIVN Pierre
2 SURN BEVIERE
1 SEX M
1 OCCU Curé
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 12:30
1 BIRT
2 DATE 1656
2 _FNA NO
1 DEAT
2 DATE 1751
2 PLAC Charleville-Mézières,08000,Ardennes,Grand Est,FRANCE,
3 MAP
4 LATI N49.766670
4 LONG E4.716670
2 _FNA NO
1 FAMC @30431@
0 @30434@ INDI
1 NAME Gérard/BEVIERE/
2 GIVN Gérard
2 SURN BEVIERE
1 SEX M
1 OCCU Curé
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 12:31
1 DEAT
2 PLAC Charleville-Mézières,08000,Ardennes,Grand Est,FRANCE,
3 MAP
4 LATI N49.766670
4 LONG E4.716670
2 _FNA NO
1 FAMC @30431@
0 @30441@ INDI
1 NAME Jean-Baptiste/BEVIERE/
2 GIVN Jean-Baptiste
2 SURN BEVIERE
1 SEX M
1 OCCU Marguillier
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 19:02
1 FAMS @30456@
1 BIRT
2 DATE 1666
2 _FNA NO
1 DEAT
2 DATE 11 JUL 1741
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,RUE DE LA ROQUETTE
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @30431@
0 @30452@ INDI
1 NAME Pierre Bonaventure/BEVIERE/
2 GIVN Pierre Bonaventure
2 SURN BEVIERE
1 SEX M
1 OCCU Avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 12:44
1 FAMS @30465@
1 FAMC @30456@
0 @30462@ INDI
1 NAME Geneviève Elisabeth/GEORGES/
2 GIVN Geneviève Elisabeth
2 SURN GEORGES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 12:44
1 FAMS @30465@
0 @30466@ INDI
1 NAME Louis/BEVIERE/
2 GIVN Louis
2 SURN BEVIERE
1 SEX M
1 OCCU Avocat au parlement de PARIS
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 12:40
1 FAMC @30456@
0 @30471@ INDI
1 NAME Jean-Baptiste/BEVIERE/
2 GIVN Jean-Baptiste
2 SURN BEVIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 19:51
1 FAMS @30579@
1 BIRT
2 DATE 20 OCT 1723
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 11 MAR 1807
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,RUE NEUVE SAINT MERRY
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 BURI
2 DATE 12 MAR 1807
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,PANTHEON
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @30465@
0 @30483@ INDI
1 NAME Jean/BEVIERE/
2 GIVN Jean
2 SURN BEVIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 12:57
1 FAMS @30505@
1 NOTE la branche BEVIERE d’Avesnes apparaît pour la première fois dans un acte de 1575 , en la personne de Jean de Bévières « héritier d’une maison grange et jardin » à Maroilles
0 @30494@ INDI
1 NAME Pierre/BEVIERE/
2 GIVN Pierre
2 SURN BEVIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 13:02
1 FAMS @30502@
1 NOTE la branche BEVIERE d’Avesnes apparaît pour la 2° fois dans un acte de 1590 , en la personne de PIERRE de Bévières  et dans un acte de mars 1599 où Colle de BEVIERE (veuve de Thomas du Bucquoy) vend des terres à Dompierre
1 FAMC @30505@
0 @30500@ INDI
1 NAME Barbe/MAHIN/
2 GIVN Barbe
2 SURN MAHIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 12:56
1 FAMS @30502@
0 @30524@ INDI
1 NAME Jean-Baptiste/COLBERT/
2 GIVN Jean-Baptiste
2 SURN COLBERT
1 SEX M
1 OCCU MINISTRE DE LOUIS XIV
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 13:20
1 BIRT
2 DATE 29 AUG 1619
2 PLAC Reims,51100,Marne,Grand Est,FRANCE,
3 MAP
4 LATI N49.250000
4 LONG E4.033330
2 _FNA NO
1 DEAT
2 DATE 6 SEP 1683
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 NOTE Jean-Baptiste Colbert né le 29 août 1619 à Reims, mort le 6 septembre 1683 à Paris, est un des principaux ministres de Louis XIV. Contrôleur général des finances de 1665 à 1683, secrétaire d'État de la Maison du roi et secrétaire d'État de la Marin
2 CONC e de 1669 à 1683.
2 CONT Il entre au service du roi à la mort de son protecteur Mazarin, incite Louis XIV à disgracier son rival Nicolas Fouquet. Inspirateur et promoteur d'une politique économique interventionniste et mercantiliste (ultérieurement désignée sous le vocable
2 CONC  de colbertisme), il favorise le développement du commerce et de l'industrie en France par la création de fabriques et monopoles royaux, étatiques. Il prépare le Code noir, relatif à l'administration de l'esclavage dans les colonies.
2 CONT Colbert s'est à l'évidence inspiré des écrits de Barthélemy de Laffemas, économiste et conseiller d'Henri IV1 : Laffemas avait en particulier développé le commerce colonial et l'industrie textile, les deux secteurs auxquels Colbert s'est particuliè
2 CONC rement consacré, avec la gestion des finances publiques, pour devenir à son tour l'éminence grise du royaume.
0 @30541@ INDI
1 NAME Armand François/BEVIERE/
2 GIVN Armand François
2 SURN BEVIERE
1 SEX M
1 OCCU Avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 19:13
1 FAMS @30558@
1 FAMC @30456@
0 @30552@ INDI
1 NAME Marie Catherine/DUFOUR/
2 GIVN Marie Catherine
2 SURN DUFOUR
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 19:13
1 FAMS @30558@
1 BIRT
2 DATE 27 JAN 1728
2 PLAC Paris 01,,,,,
2 _FNA NO
1 FAMC @30563@
0 @30559@ INDI
1 NAME Henry Simon/DUFOUR/
2 GIVN Henry Simon
2 SURN DUFOUR
1 SEX M
1 OCCU Architecte
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 19:11
1 FAMS @30563@
0 @30568@ INDI
1 NAME Louise Marie Pétronille/BEVIERE/
2 GIVN Louise Marie Pétronille
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 19:53
1 FAMS @30579@
1 BIRT
2 DATE ABT 1737
2 _FNA NO
1 DEAT
2 DATE 17 OCT 1805
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 BURI
2 DATE 17 OCT 1805
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,cimetierre sainte catherinr
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @30558@
0 @30591@ INDI
1 NAME Armand Pierre Marie/BEVIERE/
2 GIVN Armand Pierre Marie
2 SURN BEVIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 19:26
1 BIRT
2 DATE 1759
2 _FNA NO
1 DEAT
2 DATE 1818
2 _FNA NO
1 FAMC @30579@
0 @30613@ INDI
1 NAME Antoinette Madeleine/BEVIERE/
2 GIVN Antoinette Madeleine
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 19:31
1 FAMS @30658@
1 BIRT
2 DATE 29 JUL 1761
2 _FNA NO
1 FAMC @30579@
0 @30625@ INDI
1 NAME Pierre Jean/BEVIERE/
2 GIVN Pierre Jean
2 SURN BEVIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 19:28
1 BIRT
2 DATE 7 MAR 1763
2 _FNA NO
1 FAMC @30579@
0 @30637@ INDI
1 NAME Élisabeth Jeanne/BEVIERE/
2 GIVN Élisabeth Jeanne
2 SURN BEVIERE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 19:32
1 FAMS @30663@
1 BIRT
2 DATE 13 JUN 1764
2 _FNA NO
1 DEAT
2 DATE 31 OCT 1811
2 _FNA NO
1 FAMC @30579@
0 @30655@ INDI
1 NAME Claude Antoine/DELAMOTTE/
2 GIVN Claude Antoine
2 SURN DELAMOTTE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 19:31
1 FAMS @30658@
0 @30660@ INDI
1 NAME Claude André/BAUDIN/
2 GIVN Claude André
2 SURN BAUDIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 FEB 2019
3 TIME 19:32
1 FAMS @30663@
0 @30706@ INDI
1 NAME Antoine/ISORNI/
2 GIVN Antoine
2 SURN ISORNI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 FEB 2019
3 TIME 12:25
1 FAMS @30726@
1 BIRT
2 DATE 29 APR 1874
2 PLAC Locarno,6600,Tessin,Tessin,SUISSE,
3 MAP
4 LATI N46.170860
4 LONG E8.799530
2 _FNA NO
1 DEAT
2 DATE 23 NOV 1941
2 PLAC Paris 07,,,,,
2 _FNA NO
0 @30719@ INDI
1 NAME Marguerite/FEINE/
2 GIVN Marguerite
2 SURN FEINE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 FEB 2019
3 TIME 12:27
1 FAMS @30726@
1 BIRT
2 DATE 1876
2 _FNA NO
1 DEAT
2 DATE 1956
2 _FNA NO
1 FAMC @30776@
0 @30736@ INDI
1 NAME Philippe/ISORNI/
2 GIVN Philippe
2 SURN ISORNI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 FEB 2019
3 TIME 12:29
1 FAMS @30782@
1 FAMC @30726@
0 @30741@ INDI
1 NAME Hélène/ISORNI/
2 GIVN Hélène
2 SURN ISORNI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 FEB 2019
3 TIME 12:31
1 FAMS @30786@
1 FAMC @30726@
0 @30746@ INDI
1 NAME Jacques/ISORNI/
2 GIVN Jacques
2 SURN ISORNI
1 SEX M
1 OCCU Avocat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 FEB 2019
3 TIME 12:31
1 FAMS @30790@
1 FAMC @30726@
0 @30752@ INDI
1 NAME Jean/ISORNI/
2 GIVN Jean
2 SURN ISORNI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 FEB 2019
3 TIME 12:32
1 FAMS @30794@
1 FAMC @30726@
0 @30758@ INDI
1 NAME Alfred/FEINE/
2 GIVN Alfred
2 SURN FEINE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 FEB 2019
3 TIME 12:27
1 FAMS @30776@
1 BIRT
2 DATE 1837
2 _FNA NO
1 DEAT
2 DATE 1899
2 _FNA NO
0 @30769@ INDI
1 NAME Louise/CROQUEVILLE/
2 GIVN Louise
2 SURN CROQUEVILLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 FEB 2019
3 TIME 12:27
1 FAMS @30776@
1 BIRT
2 DATE 1840
2 _FNA NO
1 DEAT
2 DATE 1928
2 _FNA NO
0 @30780@ INDI
1 NAME Françoise/CODET/
2 GIVN Françoise
2 SURN CODET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 FEB 2019
3 TIME 12:29
1 FAMS @30782@
0 @30784@ INDI
1 NAME Pierre/GERAUDEL/
2 GIVN Pierre
2 SURN GERAUDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 FEB 2019
3 TIME 12:31
1 FAMS @30786@
0 @30788@ INDI
1 NAME Marie-Louise/BISINGER/
2 GIVN Marie-Louise
2 SURN BISINGER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 FEB 2019
3 TIME 12:31
1 FAMS @30790@
0 @30792@ INDI
1 NAME Jacqueline/MOREL/
2 GIVN Jacqueline
2 SURN MOREL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 FEB 2019
3 TIME 12:32
1 FAMS @30794@
0 @30795@ INDI
1 NAME Félix/GERMERIE/
2 GIVN Félix
2 SURN GERMERIE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 APR 2019
3 TIME 9:23
1 FAMS @30805@
0 @30801@ INDI
1 NAME Madeleine/BABIN/
2 GIVN Madeleine
2 SURN BABIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 APR 2019
3 TIME 9:23
1 FAMS @30805@
1 DEAT
2 DATE APR 2019
2 _FNA NO
0 @30808@ INDI
1 NAME Xxx/GERMERIE/
2 GIVN Xxx
2 SURN GERMERIE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 APR 2019
3 TIME 9:24
1 FAMS @30821@
1 FAMC @30805@
0 @30813@ INDI
1 NAME Xxx2/GERMERIE/
2 GIVN Xxx2
2 SURN GERMERIE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 APR 2019
3 TIME 9:25
1 FAMS @30825@
1 FAMC @30805@
0 @30819@ INDI
1 NAME Éric/de MONTGOLFIER/
2 GIVN Éric
2 SURN de MONTGOLFIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 APR 2019
3 TIME 9:24
1 FAMS @30821@
0 @30823@ INDI
1 NAME Jacques/CALVÉ/
2 GIVN Jacques
2 SURN CALVÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 APR 2019
3 TIME 9:25
1 FAMS @30825@
0 @30828@ INDI
1 NAME Fala/CHEN/
2 GIVN Fala
2 SURN CHEN
1 SEX F
1 OCCU actrice de cinéma
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 APR 2019
3 TIME 16:45
1 FAMS @30831@
0 @30835@ INDI
1 NAME Valériane/CLEMENCEAU/
2 GIVN Valériane
2 SURN CLEMENCEAU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 APR 2019
3 TIME 16:55
1 FAMS @30837@
1 FAMC @30846@
0 @30839@ INDI
1 NAME Christophe/CLEMENCEAU/
2 GIVN Christophe
2 SURN CLEMENCEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 APR 2019
3 TIME 16:55
1 FAMS @30846@
0 @30844@ INDI
1 NAME Valérie/?/
2 GIVN Valérie
2 SURN ?
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 APR 2019
3 TIME 16:55
1 FAMS @30846@
0 @30849@ INDI
1 NAME Jean/DUMONT/
2 GIVN Jean
2 SURN DUMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 APR 2019
3 TIME 16:57
1 FAMS @30851@
0 @30858@ INDI
1 NAME Anne/LE ROY/
2 GIVN Anne
2 SURN LE ROY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 MAY 2019
3 TIME 18:27
1 BIRT
2 DATE 10 APR 2019
2 PLAC Lausanne,1000,Vaud,Région Lémanique,SUISSE,
3 MAP
4 LATI N46.516000
4 LONG E6.632820
2 _FNA NO
1 FAMC @22968@
0 @30873@ INDI
1 NAME Emile Emmanuel/HERBILLON/
2 GIVN Emile Emmanuel
2 SURN HERBILLON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAY 2019
3 TIME 14:31
1 FAMS @30895@
1 BIRT
2 DATE 1825
2 _FNA NO
1 DEAT
2 DATE 1893
2 _FNA NO
1 FAMC @30919@
0 @30889@ INDI
1 NAME Marie Marguerite/MARTIN/
2 GIVN Marie Marguerite
2 SURN MARTIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAY 2019
3 TIME 14:28
1 FAMS @30895@
1 BIRT
2 DATE 1848
2 _FNA NO
0 @30900@ INDI
1 NAME Emile/HERBILLON/
2 GIVN Emile
2 SURN HERBILLON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAY 2019
3 TIME 14:31
1 FAMS @30919@
1 BIRT
2 DATE 1794
2 _FNA NO
1 DEAT
2 DATE 1866
2 _FNA NO
0 @30912@ INDI
1 NAME Alexandrine/BLEMONT/
2 GIVN Alexandrine
2 SURN BLEMONT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 MAY 2019
3 TIME 14:31
1 FAMS @30919@
1 BIRT
2 DATE 1791
2 _FNA NO
1 DEAT
2 DATE 1825
2 _FNA NO
0 @30926@ INDI
1 NAME Aubry/de NOGUÉ/
2 GIVN Aubry
2 SURN de NOGUÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 MAY 2019
3 TIME 12:45
1 FAMS @30928@
0 @30961@ INDI
1 NAME ?/?/
2 GIVN ?
2 SURN ?
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JUN 2019
3 TIME 13:35
1 FAMS @30965@
1 DEAT
2 DATE 2010
2 _FNA NO
0 @30966@ INDI
1 NAME Catherine/LECONTE/
2 GIVN Catherine
2 SURN LECONTE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JUN 2019
3 TIME 13:35
1 FAMC @30965@
0 @30971@ INDI
1 NAME Arnaud/de SEZE/
2 GIVN Arnaud
2 SURN de SEZE
1 SEX M
1 OCCU COLONEL
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:59
1 FAMS @32706@
1 BIRT
2 DATE 22 DEC 1907
2 PLAC Paris 06,,,,,
2 _FNA NO
1 DEAT
2 DATE 10 DEC 1987
2 PLAC Corcelles-les-Monts,21160,Côte-d'Or,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.294450
4 LONG E4.939110
2 _FNA NO
1 FAMC @32732@
0 @30976@ INDI
1 NAME Béatrice Marie Marguerite Henriette/de SEZE/
2 GIVN Béatrice Marie Marguerite Henriette
2 SURN de SEZE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:23
1 FAMS @30983@
1 FAMC @32706@
0 @30981@ INDI
1 NAME Roland/GUYON de MONTLIVAULT/
2 GIVN Roland
2 SURN GUYON de MONTLIVAULT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:23
1 FAMS @30983@
0 @30984@ INDI
1 NAME Henry/GUYON de MONTLIVAULT/
2 GIVN Henry
2 SURN GUYON de MONTLIVAULT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:23
1 DEAT
2 DATE 27 MAY 2019
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @30983@
0 @30995@ INDI
1 NAME Pierre/HOUDAILLE/
2 GIVN Pierre
2 SURN HOUDAILLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 6:52
1 FAMS @31004@
0 @31002@ INDI
1 NAME Marie-Françoise/MAILLOT/
2 GIVN Marie-Françoise
2 SURN MAILLOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 6:52
1 FAMS @31004@
0 @31021@ INDI
1 NAME Jean-Philippe/ESCUDIER/
2 GIVN Jean-Philippe
2 SURN ESCUDIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 8:19
1 BIRT
2 DATE 1914
2 _FNA NO
1 FAMC @21057@
0 @31057@ INDI
1 NAME Marie/GRACIEN/
2 GIVN Marie
2 SURN GRACIEN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 7:55
1 FAMS @31059@
1 FAMC @31073@
0 @31062@ INDI
1 NAME Christophe/GRACIEN/
2 GIVN Christophe
2 SURN GRACIEN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 7:55
1 FAMS @31073@
1 DEAT
2 DATE 1864
2 _FNA NO
0 @31071@ INDI
1 NAME Caroline/BERTAULT/
2 GIVN Caroline
2 SURN BERTAULT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 7:55
1 FAMS @31073@
0 @31076@ INDI
1 NAME Caroline/GRACIEN/
2 GIVN Caroline
2 SURN GRACIEN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 7:55
1 FAMS @31083@
1 FAMC @31073@
0 @31081@ INDI
1 NAME Philippe/ESCUDIER/
2 GIVN Philippe
2 SURN ESCUDIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 7:55
1 FAMS @31083@
0 @31084@ INDI
1 NAME Alphonse/ESCUDIER/
2 GIVN Alphonse
2 SURN ESCUDIER
1 SEX M
1 OCCU Militaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 8:20
1 FAMS @31092@
1 BIRT
2 DATE 1855
2 _FNA NO
1 DEAT
2 DATE 1933
2 _FNA NO
1 FAMC @31083@
0 @31090@ INDI
1 NAME Émilie/JAMES/
2 GIVN Émilie
2 SURN JAMES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 7:52
1 FAMS @31092@
0 @31122@ INDI
1 NAME ?/?/
2 GIVN ?
2 SURN ?
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 9:32
1 FAMS @31124@
0 @31153@ INDI
1 NAME Sophie/DELPECHE/
2 GIVN Sophie
2 SURN DELPECHE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 12:10
1 FAMS @31155@
0 @31163@ INDI
1 NAME Frantz/VALLERY-RADOT/
2 GIVN Frantz
2 SURN VALLERY-RADOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 12:12
1 FAMC @31165@
0 @31173@ INDI
1 NAME Frédéric/ROUANET/
2 GIVN Frédéric
2 SURN ROUANET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 12:14
1 FAMC @21460@
0 @31178@ INDI
1 NAME Thibaut/ROUANET/
2 GIVN Thibaut
2 SURN ROUANET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 JUN 2019
3 TIME 12:14
1 FAMC @21460@
0 @31205@ INDI
1 NAME Jean-Joseph/PASTEUR/
2 GIVN Jean-Joseph
2 SURN PASTEUR
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 9:39
1 FAMS @31215@
0 @31212@ INDI
1 NAME Jeanne Étiennette/ROQUI/
2 GIVN Jeanne Étiennette
2 SURN ROQUI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 9:39
1 FAMS @31215@
0 @31224@ INDI
1 NAME Jeanne/PASTEUR/
2 GIVN Jeanne
2 SURN PASTEUR
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2019
3 TIME 6:58
1 BIRT
2 DATE 2 APR 1850
2 _FNA NO
1 DEAT
2 DATE 10 SEP 1859
2 _FNA NO
2 CAUS TYPHOIDE
1 FAMC @20115@
0 @31234@ INDI
1 NAME Cécile/PASTEUR/
2 GIVN Cécile
2 SURN PASTEUR
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 9:47
1 BIRT
2 DATE 1853
2 _FNA NO
1 DEAT
2 DATE 1866
2 _FNA NO
1 FAMC @20115@
0 @31244@ INDI
1 NAME Camille/PASTEUR/
2 GIVN Camille
2 SURN PASTEUR
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 9:48
1 BIRT
2 DATE 1863
2 _FNA NO
1 DEAT
2 DATE 1865
2 _FNA NO
1 FAMC @20115@
0 @31310@ INDI
1 NAME Hervé/DESVALLIERES/
2 GIVN Hervé
2 SURN DESVALLIERES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 19:39
1 FAMC @9745@
0 @31315@ INDI
1 NAME Roland/DESVALLIERES/
2 GIVN Roland
2 SURN DESVALLIERES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 JUN 2019
3 TIME 19:40
1 FAMC @9745@
0 @31371@ INDI
1 NAME Joseph Louis Clément/BARTHELEMY de CAYLUS/
2 GIVN Joseph Louis Clément
2 SURN BARTHELEMY de CAYLUS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 19:35
1 FAMS @37096@
1 BIRT
2 DATE 1769
2 _FNA NO
2 NOTE ou 1759 ?
1 DEAT
2 DATE 1833
2 _FNA NO
0 @31419@ INDI
1 NAME Alcide Sylvain/BORD/
2 GIVN Alcide Sylvain
2 SURN BORD
1 SEX M
1 OCCU Entrepreneur TRAVAUX PUBLICS
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 8:42
1 FAMS @31476@
1 BIRT
2 DATE 21 FEB 1827
2 PLAC La Châtre,36400,Indre,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N46.583330
4 LONG E1.983330
2 _FNA NO
1 DEAT
2 DATE 13 OCT 1888
2 PLAC Saint-Nazaire,44600,Loire-Atlantique,Pays de la Loire,FRANCE,
3 MAP
4 LATI N47.283330
4 LONG W2.200000
2 _FNA NO
1 FAMC @31454@
0 @31435@ INDI
1 NAME Étienne/BORD/
2 GIVN Étienne
2 SURN BORD
1 SEX M
1 OCCU Menuisier
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 8:21
1 FAMS @31454@
1 BIRT
2 DATE 5 SEP 1798
2 PLAC La Châtre,36400,Indre,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N46.583330
4 LONG E1.983330
2 _FNA NO
1 DEAT
2 DATE 27 APR 1857
2 PLAC La Châtre,36400,Indre,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N46.583330
4 LONG E1.983330
2 _FNA NO
0 @31450@ INDI
1 NAME Jeanne/VAURY/
2 GIVN Jeanne
2 SURN VAURY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 8:21
1 FAMS @31454@
1 BIRT
2 DATE 1 JUN 1807
2 PLAC Aigurande,36140,Indre,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N46.433980
4 LONG E1.830260
2 _FNA NO
0 @31469@ INDI
1 NAME Marie-Joséphine/SAZERAT/
2 GIVN Marie-Joséphine
2 SURN SAZERAT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 8:42
1 FAMS @31476@
1 BIRT
2 DATE 3 JUN 1831
2 PLAC Nexon,87800,Haute-Vienne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.679620
4 LONG E1.185550
2 _FNA NO
1 DEAT
2 DATE 4 APR 1890
2 PLAC Nantes,44000,Loire-Atlantique,Pays de la Loire,FRANCE,
3 MAP
4 LATI N47.217250
4 LONG W1.553360
2 _FNA NO
0 @31480@ INDI
1 NAME Louis Jean Marie/BORD/
2 GIVN Louis Jean Marie
2 SURN BORD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 8:41
1 BIRT
2 DATE 21 MAY 1859
2 PLAC Limoges,87000,Haute-Vienne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.831530
4 LONG E1.257800
2 _FNA NO
1 DEAT
2 DATE 22 MAY 1859
2 PLAC Saint-Nazaire,44600,Loire-Atlantique,Pays de la Loire,FRANCE,
3 MAP
4 LATI N47.283330
4 LONG W2.200000
2 _FNA NO
1 FAMC @31476@
0 @31565@ INDI
1 NAME Peter Paul/MAC SWINEY/
2 GIVN Peter Paul
2 SURN MAC SWINEY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 17:12
1 FAMS @31588@
1 BIRT
2 DATE 1810
2 _FNA NO
1 DEAT
2 DATE 1884
2 _FNA NO
0 @31581@ INDI
1 NAME Anna/LALOR/
2 GIVN Anna
2 SURN LALOR
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 17:12
1 FAMS @31588@
1 BIRT
2 DATE 1815
2 _FNA NO
1 DEAT
2 DATE 1886
2 _FNA NO
0 @31591@ INDI
1 NAME Pierre François Désiré/LEFEBVRE/
2 GIVN Pierre François Désiré
2 SURN LEFEBVRE
1 SEX M
1 OCCU Horloger
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 AUG 2019
3 TIME 20:08
1 FAMS @33802@
1 BIRT
2 DATE 1807
2 _FNA NO
2 NOTE 61 ANS LE 10/04/1868
1 FAMC @31604@
0 @31596@ INDI
1 NAME Xy2/LEFEBVRE/
2 GIVN Xy2
2 SURN LEFEBVRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 17:21
1 FAMS @31604@
0 @31602@ INDI
1 NAME Euphémie/?/
2 GIVN Euphémie
2 SURN ?
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 17:31
1 FAMS @31604@
1 NOTE Euphémie LEFEBVRE, était la nurse d'Hortense et d'Eugène de BEAUHARNAIS. Venue de la Martinique avec leur mère, l'impératrice Joséphine, elle resta proche des deux enfants sous l'Empire.
2 CONT arrière grand mère de Marguerite Lefebvre. épouse de George Desvallieres.
2 CONT
0 @31610@ INDI
1 NAME Hortense/de BEAUHARNAIS/
2 GIVN Hortense
2 SURN de BEAUHARNAIS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 17:25
0 @31613@ INDI
1 NAME Xxx/LEFEBVRE/
2 GIVN Xxx
2 SURN LEFEBVRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 20:47
1 FAMS @31619@
1 FAMC @33728@
0 @31618@ INDI
1 NAME /LECOEUR/
2 GIVN
2 SURN LECOEUR
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 17:34
1 FAMS @31619@
0 @31620@ INDI
1 NAME Marguerite/LECOEUR/
2 GIVN Marguerite
2 SURN LECOEUR
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 17:36
1 NOTE Marguerite Lecoeur, nièce de ladite Marguerite Desvallieres;
2 CONT Avait le Beau et fin col en dentelle de L'impératrice Joséphine (attribué à).  offert par Hortense à Euphémie
2 CONT =elle l'avait offert en 1978 à Bertrand et Brigitte lssaverdens
2 CONT vendu en 2018 en SdV de Bordeaux
1 FAMC @31619@
0 @31629@ INDI
1 NAME Alfred Henri/LEMAIGRE-DUBREUIL/
2 GIVN Alfred Henri
2 SURN LEMAIGRE-DUBREUIL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 17:47
1 FAMS @31653@
1 BIRT
2 DATE 9 APR 1833
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @31641@ INDI
1 NAME Marie Rose Louise Jeanne Edmée/POUYAT/
2 GIVN Marie Rose Louise Jeanne Edmée
2 SURN POUYAT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 17:47
1 FAMS @31653@
1 BIRT
2 DATE 12 OCT 1840
2 PLAC Limoges,87000,Haute-Vienne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.831530
4 LONG E1.257800
2 _FNA NO
0 @31660@ INDI
1 NAME Marie/SAINT-MARC GIRARDIN/
2 GIVN Marie
2 SURN SAINT-MARC GIRARDIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 17:50
1 BIRT
2 DATE 1902
2 _FNA NO
1 FAMC @10079@
0 @31668@ INDI
1 NAME Madeleine/SAINT-MARC GIRARDIN/
2 GIVN Madeleine
2 SURN SAINT-MARC GIRARDIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:06
1 FAMS @31699@
1 BIRT
2 DATE 1903
2 _FNA NO
1 FAMC @10079@
0 @31676@ INDI
1 NAME Yvonne/SAINT-MARC GIRARDIN/
2 GIVN Yvonne
2 SURN SAINT-MARC GIRARDIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 17:52
1 BIRT
2 DATE 1904
2 _FNA NO
1 FAMC @10079@
0 @31684@ INDI
1 NAME Barthélémy/SAINT-MARC GIRARDIN/
2 GIVN Barthélémy
2 SURN SAINT-MARC GIRARDIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:01
1 FAMS @31703@
1 FAMC @10079@
0 @31690@ INDI
1 NAME Odette/SAINT-MARC GIRARDIN/
2 GIVN Odette
2 SURN SAINT-MARC GIRARDIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 17:59
1 FAMS @31707@
1 FAMC @10079@
0 @31697@ INDI
1 NAME Étienne/FOUGERAS-LAVERGNOLLE/
2 GIVN Étienne
2 SURN FOUGERAS-LAVERGNOLLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:06
1 FAMS @31699@
0 @31701@ INDI
1 NAME Marguerite/BRIOIS/
2 GIVN Marguerite
2 SURN BRIOIS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:01
1 FAMS @31703@
0 @31705@ INDI
1 NAME Henri/BARRET/
2 GIVN Henri
2 SURN BARRET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 17:59
1 FAMS @31707@
0 @31708@ INDI
1 NAME Marie-France/SAINT-MARC GIRARDIN/
2 GIVN Marie-France
2 SURN SAINT-MARC GIRARDIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:01
1 FAMC @31703@
0 @31713@ INDI
1 NAME Yvonne/FOUGERAS-LAVERGNOLLE/
2 GIVN Yvonne
2 SURN FOUGERAS-LAVERGNOLLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:07
1 FAMS @31771@
1 FAMC @31699@
0 @31718@ INDI
1 NAME Germaine/FOUGERAS-LAVERGNOLLE/
2 GIVN Germaine
2 SURN FOUGERAS-LAVERGNOLLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:08
1 FAMS @31774@
1 FAMC @31699@
0 @31723@ INDI
1 NAME Monique/FOUGERAS-LAVERGNOLLE/
2 GIVN Monique
2 SURN FOUGERAS-LAVERGNOLLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:09
1 FAMS @31778@
1 FAMC @31699@
0 @31728@ INDI
1 NAME Thérèse/FOUGERAS-LAVERGNOLLE/
2 GIVN Thérèse
2 SURN FOUGERAS-LAVERGNOLLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:10
1 FAMS @31782@
1 FAMC @31699@
0 @31733@ INDI
1 NAME Marie/FOUGERAS-LAVERGNOLLE/
2 GIVN Marie
2 SURN FOUGERAS-LAVERGNOLLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:12
1 FAMS @31786@
1 FAMS @31790@
1 FAMC @31699@
0 @31738@ INDI
1 NAME Bernadette/FOUGERAS-LAVERGNOLLE/
2 GIVN Bernadette
2 SURN FOUGERAS-LAVERGNOLLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:13
1 FAMS @31794@
1 FAMC @31699@
0 @31743@ INDI
1 NAME François/FOUGERAS-LAVERGNOLLE/
2 GIVN François
2 SURN FOUGERAS-LAVERGNOLLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:05
1 FAMC @31699@
0 @31748@ INDI
1 NAME Pierre/FOUGERAS-LAVERGNOLLE/
2 GIVN Pierre
2 SURN FOUGERAS-LAVERGNOLLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:15
1 FAMS @31798@
1 FAMS @31803@
1 FAMC @31699@
0 @31753@ INDI
1 NAME Françoise/FOUGERAS-LAVERGNOLLE/
2 GIVN Françoise
2 SURN FOUGERAS-LAVERGNOLLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:06
1 FAMC @31699@
0 @31758@ INDI
1 NAME Henri/FOUGERAS-LAVERGNOLLE/
2 GIVN Henri
2 SURN FOUGERAS-LAVERGNOLLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:15
1 FAMS @31807@
1 FAMC @31699@
0 @31763@ INDI
1 NAME Jean/FOUGERAS-LAVERGNOLLE/
2 GIVN Jean
2 SURN FOUGERAS-LAVERGNOLLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:16
1 FAMS @31810@
1 FAMC @31699@
0 @31769@ INDI
1 NAME Guy/TERRIER de la CHAISE/
2 GIVN Guy
2 SURN TERRIER de la CHAISE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:07
1 FAMS @31771@
0 @31772@ INDI
1 NAME Léon/MAILLOT/
2 GIVN Léon
2 SURN MAILLOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:08
1 FAMS @31774@
0 @31776@ INDI
1 NAME Bernard/MOSNIER-THOUMAS/
2 GIVN Bernard
2 SURN MOSNIER-THOUMAS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:09
1 FAMS @31778@
0 @31780@ INDI
1 NAME Jacques/THIRION/
2 GIVN Jacques
2 SURN THIRION
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:10
1 FAMS @31782@
0 @31784@ INDI
1 NAME Daniel/BOBÉ/
2 GIVN Daniel
2 SURN BOBÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:11
1 FAMS @31786@
0 @31788@ INDI
1 NAME Alain/MORAULT/
2 GIVN Alain
2 SURN MORAULT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:12
1 FAMS @31790@
0 @31792@ INDI
1 NAME Maurice/DESNOS/
2 GIVN Maurice
2 SURN DESNOS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:13
1 FAMS @31794@
0 @31796@ INDI
1 NAME Michèle/SERVANT/
2 GIVN Michèle
2 SURN SERVANT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:14
1 FAMS @31798@
0 @31801@ INDI
1 NAME Martine/COTON/
2 GIVN Martine
2 SURN COTON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:15
1 FAMS @31803@
0 @31805@ INDI
1 NAME Mauricette/MARTIN/
2 GIVN Mauricette
2 SURN MARTIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:15
1 FAMS @31807@
0 @31808@ INDI
1 NAME Nicole/BLONDEL/
2 GIVN Nicole
2 SURN BLONDEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 10 JUN 2019
3 TIME 18:16
1 FAMS @31810@
0 @31843@ INDI
1 NAME Pierre/LEBON/
2 GIVN Pierre
2 SURN LEBON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 JUN 2019
3 TIME 18:40
1 FAMS @31861@
1 BIRT
2 DATE 1886
2 _FNA NO
1 DEAT
2 DATE 1937
2 _FNA NO
0 @31854@ INDI
1 NAME Antoinette/DELORE/
2 GIVN Antoinette
2 SURN DELORE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 JUN 2019
3 TIME 18:40
1 FAMS @31861@
1 BIRT
2 DATE 1893
2 _FNA NO
1 DEAT
2 DATE 1980
2 _FNA NO
0 @31870@ INDI
1 NAME Maud/LEBON/
2 GIVN Maud
2 SURN LEBON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 JUN 2019
3 TIME 18:41
1 FAMS @31888@
1 BIRT
2 DATE 1914
2 _FNA NO
1 DEAT
2 DATE 2003
2 _FNA NO
1 FAMC @31861@
0 @31881@ INDI
1 NAME Georges/SALET/
2 GIVN Georges
2 SURN SALET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 JUN 2019
3 TIME 18:41
1 FAMS @31888@
1 BIRT
2 DATE 1907
2 _FNA NO
1 DEAT
2 DATE 2002
2 _FNA NO
0 @31927@ INDI
1 NAME Léon Willebaud Étienne Marie Joseph/WIBAUX/
2 GIVN Léon Willebaud Étienne Marie Joseph
2 SURN WIBAUX
1 SEX M
1 OCCU négociant en laine
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 DEC 2019
3 TIME 17:39
1 FAMS @31944@
1 BIRT
2 DATE 1885
2 _FNA NO
1 DEAT
2 DATE 1957
2 _FNA NO
0 @31942@ INDI
1 NAME Rose-Anne/FERLIÉ/
2 GIVN Rose-Anne
2 SURN FERLIÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 DEC 2019
3 TIME 17:41
1 FAMS @31944@
1 BIRT
2 DATE 10 MAR 1885
2 _FNA NO
1 DEAT
2 DATE 1980
2 _FNA NO
1 FAMC @36746@
0 @31952@ INDI
1 NAME René Gustave/de BAYSER/
2 GIVN René Gustave
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 NOV 2019
3 TIME 12:10
1 FAMS @31975@
1 BIRT
2 DATE 13 FEB 1871
2 _FNA NO
1 DEAT
2 DATE 1951
2 _FNA NO
1 FAMC @36296@
0 @31968@ INDI
1 NAME Henriette/FAUVILLE/
2 GIVN Henriette
2 SURN FAUVILLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 NOV 2019
3 TIME 12:10
1 FAMS @31975@
1 BIRT
2 DATE 16 FEB 1880
2 PLAC Neuville-sur-Escaut,59293,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.300000
4 LONG E3.350000
2 _FNA NO
1 DEAT
2 DATE 1965
2 _FNA NO
1 FAMC @36273@
0 @31978@ INDI
1 NAME Françoise/WIBAUX/
2 GIVN Françoise
2 SURN WIBAUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 JUN 2019
3 TIME 19:11
1 FAMS @32019@
1 BIRT
2 DATE 1910
2 _FNA NO
1 FAMC @31944@
0 @31985@ INDI
1 NAME Geneviève/WIBAUX/
2 GIVN Geneviève
2 SURN WIBAUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 JUN 2019
3 TIME 19:09
1 FAMS @32014@
1 BIRT
2 DATE 1911
2 _FNA NO
1 FAMC @31944@
0 @31992@ INDI
1 NAME Simone/WIBAUX/
2 GIVN Simone
2 SURN WIBAUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 JUN 2019
3 TIME 19:13
1 FAMS @32028@
1 FAMS @32032@
1 BIRT
2 DATE 1915
2 _FNA NO
1 DEAT
2 DATE 1972
2 _FNA NO
1 FAMC @31944@
0 @32002@ INDI
1 NAME Bernard/WIBAUX/
2 GIVN Bernard
2 SURN WIBAUX
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 JUN 2019
3 TIME 19:07
1 FAMC @31944@
0 @32007@ INDI
1 NAME Guy/de BAYSER/
2 GIVN Guy
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 JUN 2019
3 TIME 19:09
1 FAMS @32014@
1 BIRT
2 DATE 1899
2 _FNA NO
1 DEAT
2 DATE 1952
2 _FNA NO
0 @32017@ INDI
1 NAME Jean/BUCHERE de L'EPINOIS/
2 GIVN Jean
2 SURN BUCHERE de L'EPINOIS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 JUN 2019
3 TIME 19:11
1 FAMS @32019@
0 @32021@ INDI
1 NAME Leon/THESEE/
2 GIVN Leon
2 SURN THESEE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 JUN 2019
3 TIME 19:12
1 FAMS @32028@
1 BIRT
2 DATE 1908
2 _FNA NO
1 DEAT
2 DATE 1986
2 _FNA NO
0 @32030@ INDI
1 NAME René/MALAQUIN/
2 GIVN René
2 SURN MALAQUIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 JUN 2019
3 TIME 19:13
1 FAMS @32032@
0 @32051@ INDI
1 NAME Béatrice/PALUEL-MARMONT/
2 GIVN Béatrice
2 SURN PALUEL-MARMONT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 SEP 2019
3 TIME 18:05
1 FAMS @34296@
0 @32055@ INDI
1 NAME Jacques/NAHMIAS/
2 GIVN Jacques
2 SURN NAHMIAS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 JUN 2019
3 TIME 13:21
1 FAMS @32057@
0 @32067@ INDI
1 NAME Casimir Victor Alexandre/de BALTHASAR de GACHEO/
2 GIVN Casimir Victor Alexandre
2 SURN de BALTHASAR de GACHEO
1 SEX M
1 OCCU artiste peintre
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 NOV 2019
3 TIME 19:07
1 BIRT
2 DATE 4 NOV 1811
2 PLAC Hayange,57700,Moselle,Grand Est,FRANCE,
3 MAP
4 LATI N49.334280
4 LONG E6.052680
2 _FNA NO
1 DEAT
2 DATE 9 FEB 1875
3 TIME 24:00
2 PLAC Paris 09,,,,,
2 _FNA NO
2 AGE 63
1 NOTE Casimir Victor Alexandre de BALTHASAR de GACHEO
2 CONT A peint « la pêche miraculeuse «  commandée par le comte de TREMAUVILLE pour l’église de saint pierre en port en 1851
2 CONT Élève de Paul Delaroche, ses tableaux d'histoire et ses portraits sont régulièrement aux Salon de Paris, de 1833 à 18682. La galerie de Versailles possède le portrait de Marie-Louise-Gabrielle de Savoie, reine d'Espagne, que le peintre exécute d'ap
2 CONC rès un tableau de Menendez, ainsi que celui du financier Law2. Il passe les dix dernières années de sa vie à Toul 2. Dans cette ville il est chargé de la restauration des peintures sur verre de la cathédrale2. Il meurt en 1875 2.
1 FAMC @32084@
0 @32082@ INDI
1 NAME Xy/de BALTHASAR de GACHEO/
2 GIVN Xy
2 SURN de BALTHASAR de GACHEO
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 JUN 2019
3 TIME 13:18
1 FAMS @32084@
1 FAMC @16131@
0 @32112@ INDI
1 NAME Ange/AMBROSELLI/
2 GIVN Ange
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 JUL 2019
3 TIME 11:01
1 BIRT CESARIENNE 4040g
2 DATE 30 JUN 2019
3 TIME 15:55
2 PLAC Amsterdam,,,,,
2 _FNA NO
1 NOTE 212° APE
1 FAMC @405@
0 @32123@ INDI
1 NAME Romain/LECLAIR/
2 GIVN Romain
2 SURN LECLAIR
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JUL 2019
3 TIME 15:44
1 BIRT
2 DATE 25 JAN 1985
2 _FNA NO
1 FAMC @10430@
0 @32128@ INDI
1 NAME Adèle/HERTOGHE/
2 GIVN Adèle
2 SURN HERTOGHE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 JUL 2019
3 TIME 11:01
1 BIRT
2 DATE 11 JUN 2019
2 _FNA NO
1 FAMC @1806@
0 @32136@ INDI
1 NAME Charlotte/MUNT/
2 GIVN Charlotte
2 SURN MUNT
1 SEX F
1 OCCU aide-soignante
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 JUN 2019
3 TIME 18:59
1 FAMS @32140@
0 @32141@ INDI
1 NAME Madeleine/AMBROSELLI/
2 GIVN Madeleine
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 JUN 2019
3 TIME 18:59
1 BIRT
2 DATE JUL 2018
2 _FNA NO
1 FAMC @32140@
0 @32149@ INDI
1 NAME Alexie/TOQUÉ D’ABZAC/
2 GIVN Alexie
2 SURN TOQUÉ D’ABZAC
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 AUG 2019
3 TIME 8:53
1 FAMS @32151@
0 @32153@ INDI
1 NAME Rita/ACKER/
2 GIVN Rita
2 SURN ACKER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 JUN 2019
3 TIME 19:07
1 BIRT
2 DATE JUL 2018
2 _FNA NO
1 FAMC @32151@
0 @32162@ INDI
1 NAME Théodore/CHARIGNON/
2 GIVN Théodore
2 SURN CHARIGNON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 JUN 2019
3 TIME 19:16
1 BIRT
2 DATE 25 JAN 1996
2 _FNA NO
1 FAMC @2926@
0 @32174@ INDI
1 NAME Raphaël/CHARIGNON/
2 GIVN Raphaël
2 SURN CHARIGNON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 JUN 2019
3 TIME 19:16
1 BIRT
2 DATE 6 FEB 1998
2 _FNA NO
1 FAMC @2926@
0 @32186@ INDI
1 NAME Salomé/CHARIGNON/
2 GIVN Salomé
2 SURN CHARIGNON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 30 JUN 2019
3 TIME 19:17
1 BIRT
2 DATE 25 SEP 2004
2 _FNA NO
1 FAMC @2926@
0 @32205@ INDI
1 NAME Ange/AMBROSELLI/
2 GIVN Ange
2 SURN AMBROSELLI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 JUL 2019
3 TIME 10:57
1 BIRT
2 DATE 1 JUN 2017
2 _FNA NO
1 FAMC @3621@
0 @32229@ INDI
1 NAME Baptiste/COLLONNIER/
2 GIVN Baptiste
2 SURN COLLONNIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 JUL 2019
3 TIME 7:36
1 BIRT
2 DATE 18 JUN 2014
2 _FNA NO
1 FAMC @32257@
0 @32237@ INDI
1 NAME Hugo/COLLONNIER/
2 GIVN Hugo
2 SURN COLLONNIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 AUG 2019
3 TIME 8:54
1 BIRT
2 DATE 2016
2 _FNA NO
1 FAMC @32257@
0 @32241@ INDI
1 NAME Jules/COLLONNIER/
2 GIVN Jules
2 SURN COLLONNIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 AUG 2019
3 TIME 8:54
1 BIRT
2 DATE 4 JUL 2019
2 _FNA NO
1 FAMC @32257@
0 @32248@ INDI
1 NAME Maïlys/COLLONNIER/
2 GIVN Maïlys
2 SURN COLLONNIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 9 AUG 2019
3 TIME 8:55
1 BIRT
2 DATE 4 JUL 2019
2 _FNA NO
1 FAMC @32257@
0 @32255@ INDI
1 NAME Dorothée/MAUNOURY/
2 GIVN Dorothée
2 SURN MAUNOURY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JUL 2019
3 TIME 8:58
1 FAMS @32257@
0 @32279@ INDI
1 NAME Nadège/?/
2 GIVN Nadège
2 SURN ?
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 JUL 2019
3 TIME 9:05
1 FAMS @32281@
0 @32282@ INDI
1 NAME Xy1/COLLONNIER/
2 GIVN Xy1
2 SURN COLLONNIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 JUL 2019
3 TIME 7:39
1 FAMC @32281@
0 @32287@ INDI
1 NAME Xy2/COLLONNIER/
2 GIVN Xy2
2 SURN COLLONNIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 JUL 2019
3 TIME 7:39
1 FAMC @32281@
0 @32309@ INDI
1 NAME Geoffroy Jules Marie Gabriel/de MONTALEMBERT de CERS/
2 GIVN Geoffroy Jules Marie Gabriel
2 SURN de MONTALEMBERT de CERS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 JUL 2019
3 TIME 16:49
1 FAMS @32327@
1 BIRT
2 DATE 16 SEP 1850
2 PLAC Versailles,78000,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.800000
4 LONG E2.133330
2 _FNA NO
1 DEAT
2 DATE 16 OCT 1926
2 _FNA NO
0 @32325@ INDI
1 NAME Marie-Thérèse/de MAURES de MALARTIC de BRIGODE/
2 GIVN Marie-Thérèse
2 SURN de MAURES de MALARTIC de BRIGODE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 JUL 2019
3 TIME 16:48
1 FAMS @32327@
1 BIRT
2 DATE 22 SEP 1857
2 PLAC Arques-la-Bataille,76880,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.881220
4 LONG E1.128750
2 _FNA NO
1 DEAT
2 DATE 27 APR 1913
2 _FNA NO
1 FAMC @32365@
0 @32341@ INDI
1 NAME Odon Charles/de MAURES de MALARTIC de BRIGODE/
2 GIVN Odon Charles
2 SURN de MAURES de MALARTIC de BRIGODE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 JUL 2019
3 TIME 15:59
1 FAMS @32365@
1 BIRT
2 DATE 1810
2 _FNA NO
1 DEAT
2 DATE 1876
2 _FNA NO
0 @32358@ INDI
1 NAME Marie-Augustine/de CLERCY/
2 GIVN Marie-Augustine
2 SURN de CLERCY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 JUL 2019
3 TIME 16:20
1 FAMS @32365@
1 BIRT
2 DATE 1822
2 _FNA NO
1 DEAT
2 DATE 1867
2 _FNA NO
1 FAMC @32418@
0 @32370@ INDI
1 NAME Raymond/de MAURES de MALARTIC de BRIGODE/
2 GIVN Raymond
2 SURN de MAURES de MALARTIC de BRIGODE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 JUL 2019
3 TIME 15:57
1 BIRT
2 DATE 1845
2 _FNA NO
1 DEAT
2 DATE 1865
2 _FNA NO
1 FAMC @32365@
0 @32384@ INDI
1 NAME Gontran/de MAURES de MALARTIC de BRIGODE/
2 GIVN Gontran
2 SURN de MAURES de MALARTIC de BRIGODE
1 SEX M
1 OCCU Militaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 JUL 2019
3 TIME 16:03
1 BIRT
2 DATE 1847
2 _FNA NO
1 DEAT
2 DATE 1871
2 _FNA NO
2 NOTE TYPHOIDE durant guerre de 1870-1871
1 FAMC @32365@
0 @32399@ INDI
1 NAME Eugène/de CLERCY/
2 GIVN Eugène
2 SURN de CLERCY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 JUL 2019
3 TIME 16:21
1 FAMS @32418@
1 BIRT
2 DATE 1788
2 _FNA NO
1 DEAT
2 DATE 1875
2 _FNA NO
0 @32411@ INDI
1 NAME Clémentine/de CAUMONT/
2 GIVN Clémentine
2 SURN de CAUMONT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 JUL 2019
3 TIME 16:21
1 FAMS @32418@
1 BIRT
2 DATE 1792
2 PLAC Prison,,,,,
2 _FNA NO
1 DEAT
2 DATE 1873
2 _FNA NO
0 @32422@ INDI
1 NAME Humbert/de CLERCY/
2 GIVN Humbert
2 SURN de CLERCY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 JUL 2019
3 TIME 16:26
1 FAMS @32435@
1 FAMC @32418@
0 @32427@ INDI
1 NAME Henri/de CLERCY/
2 GIVN Henri
2 SURN de CLERCY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 JUL 2019
3 TIME 16:21
1 FAMC @32418@
0 @32433@ INDI
1 NAME Noémie/de BRIGODE/
2 GIVN Noémie
2 SURN de BRIGODE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 JUL 2019
3 TIME 16:27
1 FAMS @32435@
0 @32454@ INDI
1 NAME Jean/de MONTALEMBERT de CERS/
2 GIVN Jean
2 SURN de MONTALEMBERT de CERS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 17:29
1 BIRT
2 DATE 8 SEP 1932
2 _FNA NO
1 DEAT
2 DATE 12 OCT 1932
2 _FNA NO
1 FAMC @13906@
0 @32468@ INDI
1 NAME Guy/de MONTALEMBERT de CERS/
2 GIVN Guy
2 SURN de MONTALEMBERT de CERS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 17:29
1 BIRT
2 DATE 21 OCT 1934
2 _FNA NO
1 DEAT
2 DATE 20 NOV 1934
2 _FNA NO
1 FAMC @13906@
0 @32482@ INDI
1 NAME Geoffroy/de MONTALEMBERT de CERS/
2 GIVN Geoffroy
2 SURN de MONTALEMBERT de CERS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 17:30
1 BIRT
2 DATE 30 NOV 1937
2 _FNA NO
1 DEAT
2 DATE 22 DEC 1937
2 _FNA NO
1 FAMC @13906@
0 @32496@ INDI
1 NAME Marie-France/de MONTALEMBERT de CERS/
2 GIVN Marie-France
2 SURN de MONTALEMBERT de CERS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 17:33
1 BIRT
2 DATE 28 MAY 1940
2 _FNA NO
1 DEAT
2 DATE 29 MAY 1941
2 _FNA NO
1 FAMC @13906@
0 @32510@ INDI
1 NAME François-Xavier/de MONTALEMBERT de CERS/
2 GIVN François-Xavier
2 SURN de MONTALEMBERT de CERS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 JUL 2019
3 TIME 17:03
1 BIRT
2 DATE 26 SEP 1946
2 _FNA NO
1 DEAT
2 DATE 12 JAN 1957
2 _FNA NO
1 FAMC @16263@
0 @32572@ INDI
1 NAME Boris/VIAN/
2 GIVN Boris
2 SURN VIAN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 20 JUL 2019
3 TIME 13:32
0 @32581@ INDI
1 NAME Thibault/de LA CELLE-LESBRE/
2 GIVN Thibault
2 SURN de LA CELLE-LESBRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 13:32
1 FAMS @32913@
1 BIRT
2 DATE 13 APR 1978
2 _FNA NO
1 FAMC @9137@
0 @32591@ INDI
1 NAME Arnaud/de LA CELLE-LESBRE/
2 GIVN Arnaud
2 SURN de LA CELLE-LESBRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 18:57
1 FAMS @33439@
1 BIRT
2 DATE 1983
2 _FNA NO
1 FAMC @9137@
0 @32596@ INDI
1 NAME Joséphine/de LA CELLE-LESBRE/
2 GIVN Joséphine
2 SURN de LA CELLE-LESBRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 18:56
1 FAMS @32606@
1 BIRT
2 DATE 1988
2 _FNA NO
1 FAMC @9137@
0 @32602@ INDI
1 NAME Adrien/ROSTAND/
2 GIVN Adrien
2 SURN ROSTAND
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 13:35
1 FAMS @32606@
1 BIRT
2 DATE 1980
2 _FNA NO
0 @32608@ INDI
1 NAME Foulques/ROSTAND/
2 GIVN Foulques
2 SURN ROSTAND
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 JUL 2019
3 TIME 11:51
1 BIRT
2 DATE DEC 2015
2 _FNA NO
1 FAMC @32606@
0 @32620@ INDI
1 NAME Bartimée/de FROIDEFOND des FARGES/
2 GIVN Bartimée
2 SURN de FROIDEFOND des FARGES
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 22 NOV 2019
3 TIME 15:07
1 BIRT
2 DATE 8 JUL 2019
2 _FNA NO
1 CHR
2 DATE 20 AUG 2019
2 _FNA NO
1 FAMC @3541@
0 @32654@ INDI
1 NAME Hugues Jean Marie/de LA CELLE/
2 GIVN Hugues Jean Marie
2 SURN de LA CELLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 OCT 2019
3 TIME 20:03
1 FAMS @32665@
1 BIRT
2 DATE 26 OCT 1919
2 PLAC Montendre,17130,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.284690
4 LONG W0.406270
2 _FNA NO
2 NOTE ou 26/10/1919?
1 DEAT
2 DATE 24 APR 1986
2 PLAC Bourges,18000,Cher,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N47.083330
4 LONG E2.400000
2 _FNA NO
1 FAMC @32678@
0 @32663@ INDI
1 NAME Roseline/de MONTEYNARD/
2 GIVN Roseline
2 SURN de MONTEYNARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 OCT 2019
3 TIME 19:57
1 FAMS @32665@
1 BIRT
2 DATE 1920
2 _FNA NO
1 DEAT
2 DATE 2002
2 _FNA NO
1 FAMC @32689@
0 @32670@ INDI
1 NAME Roland/de LA CELLE/
2 GIVN Roland
2 SURN de LA CELLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 OCT 2019
3 TIME 18:22
1 FAMS @32678@
1 BIRT
2 DATE 25 NOV 1881
2 _FNA NO
1 DEAT
2 DATE 16 APR 1956
2 PLAC Besson,03210,Allier,Auvergne-Rhône-Alpes,FRANCE,
3 MAP
4 LATI N46.470860
4 LONG E3.264000
2 _FNA NO
1 FAMC @35598@
0 @32676@ INDI
1 NAME Élisabeth/PELTEREAU-VILLENEUVE/
2 GIVN Élisabeth
2 SURN PELTEREAU-VILLENEUVE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 19:58
1 FAMS @32678@
1 BIRT
2 DATE 13 SEP 1887
2 _FNA NO
1 DEAT
2 DATE 11 MAY 1983
2 _FNA NO
1 FAMC @33536@
0 @32682@ INDI
1 NAME Pierre-Henri Marie Antoine/de MONTEYNARD/
2 GIVN Pierre-Henri Marie Antoine
2 SURN de MONTEYNARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 SEP 2019
3 TIME 8:59
1 FAMS @32689@
1 BIRT
2 DATE 1898
2 _FNA NO
1 DEAT
2 DATE 1979
2 _FNA NO
1 FAMC @32957@
0 @32687@ INDI
1 NAME Nicole Inès/LESBRE/
2 GIVN Nicole Inès
2 SURN LESBRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 SEP 2019
3 TIME 8:59
1 FAMS @32689@
1 BIRT
2 DATE 5 MAR 1898
2 PLAC Luisant,28600,Eure-et-Loir,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N48.429500
4 LONG E1.473830
2 _FNA NO
1 DEAT
2 DATE 18 OCT 1968
2 PLAC Neuilly-sur-Seine,92200,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883330
4 LONG E2.266670
2 _FNA NO
1 FAMC @33609@
0 @32692@ INDI
1 NAME Jeanne-Marie/de LA CELLE/
2 GIVN Jeanne-Marie
2 SURN de LA CELLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 20:01
1 BIRT
2 DATE 1950
2 _FNA NO
1 FAMC @32665@
0 @32697@ INDI
1 NAME Philippe/de LA CELLE/
2 GIVN Philippe
2 SURN de LA CELLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 20:01
1 FAMS @33511@
1 BIRT
2 DATE 1953
2 _FNA NO
1 FAMC @32665@
0 @32704@ INDI
1 NAME Jacqueline/DARCY/
2 GIVN Jacqueline
2 SURN DARCY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 SEP 2019
3 TIME 12:17
1 FAMS @32706@
1 BIRT
2 DATE 11 MAR 1919
2 _FNA NO
1 DEAT
2 DATE 3 NOV 2017
2 _FNA NO
1 FAMC @32773@
0 @32724@ INDI
1 NAME Daniel/de SEZE/
2 GIVN Daniel
2 SURN de SEZE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 SEP 2019
3 TIME 12:22
1 FAMS @32732@
1 BIRT
2 DATE 1868
2 _FNA NO
1 DEAT
2 DATE 1959
2 _FNA NO
0 @32730@ INDI
1 NAME Madeleine Bertrande Henriette Jeanne Marie/de SARRIEU/
2 GIVN Madeleine Bertrande Henriette Jeanne Marie
2 SURN de SARRIEU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 SEP 2019
3 TIME 12:22
1 FAMS @32732@
1 BIRT
2 DATE 1876
2 _FNA NO
1 DEAT
2 DATE 1958
2 _FNA NO
0 @32736@ INDI
1 NAME Anmaury-Daniel/de SEZE/
2 GIVN Anmaury-Daniel
2 SURN de SEZE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:18
1 FAMS @32778@
1 FAMC @32706@
0 @32747@ INDI
1 NAME Lorraine/GUYON de MONTLIVAULT/
2 GIVN Lorraine
2 SURN GUYON de MONTLIVAULT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:30
1 FAMS @32851@
1 FAMS @32869@
1 FAMC @30983@
0 @32755@ INDI
1 NAME Maurice/DARCY/
2 GIVN Maurice
2 SURN DARCY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 11:53
1 FAMS @32773@
1 BIRT
2 DATE 1893
2 _FNA NO
1 DEAT
2 DATE 1940
2 _FNA NO
1 BURI
2 DATE 1940
2 PLAC Paris 16,,,,,cimetierre de passy
2 _FNA NO
1 FAMC @37132@
0 @32766@ INDI
1 NAME Jeanne/de CHABAUD-la-TOUR/
2 GIVN Jeanne
2 SURN de CHABAUD-la-TOUR
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 11:53
1 FAMS @32773@
1 BIRT
2 DATE 1894
2 _FNA NO
1 DEAT
2 DATE 1981
2 _FNA NO
0 @32777@ INDI
1 NAME /d'ESTUTT d'ASSAY/
2 GIVN
2 SURN d'ESTUTT d'ASSAY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:18
1 FAMS @32778@
0 @32779@ INDI
1 NAME Ghislain/de SEZE/
2 GIVN Ghislain
2 SURN de SEZE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:20
1 FAMS @32790@
1 FAMC @32778@
0 @32783@ INDI
1 NAME François-Xavier/de SEZE/
2 GIVN François-Xavier
2 SURN de SEZE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:18
1 FAMC @32778@
0 @32788@ INDI
1 NAME Amélie/de LA HAYE-JOUSSELIN/
2 GIVN Amélie
2 SURN de LA HAYE-JOUSSELIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:20
1 FAMS @32790@
0 @32791@ INDI
1 NAME Laure/de SEZE/
2 GIVN Laure
2 SURN de SEZE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:19
1 FAMC @32790@
0 @32796@ INDI
1 NAME Laetitia/de SEZE/
2 GIVN Laetitia
2 SURN de SEZE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:20
1 FAMC @32790@
0 @32801@ INDI
1 NAME Alexandre/de SEZE/
2 GIVN Alexandre
2 SURN de SEZE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:20
1 FAMC @32790@
0 @32806@ INDI
1 NAME Claire/GUYON de MONTLIVAULT/
2 GIVN Claire
2 SURN GUYON de MONTLIVAULT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:32
1 FAMS @32879@
1 FAMC @30983@
0 @32814@ INDI
1 NAME Florence/GUYON de MONTLIVAULT/
2 GIVN Florence
2 SURN GUYON de MONTLIVAULT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:33
1 FAMS @32904@
1 FAMC @30983@
0 @32822@ INDI
1 NAME Marie-Adelaide/GUYON de MONTLIVAULT/
2 GIVN Marie-Adelaide
2 SURN GUYON de MONTLIVAULT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:34
1 FAMS @32908@
1 FAMC @30983@
0 @32831@ INDI
1 NAME Marie-Astrid/GUYON de MONTLIVAULT/
2 GIVN Marie-Astrid
2 SURN GUYON de MONTLIVAULT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:24
1 FAMC @30983@
0 @32840@ INDI
1 NAME Aude/de SEZE/
2 GIVN Aude
2 SURN de SEZE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:24
1 FAMC @32706@
0 @32847@ INDI
1 NAME Arnaud Marie Bertrand/BRÉARD de BOISANGER/
2 GIVN Arnaud Marie Bertrand
2 SURN BRÉARD de BOISANGER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:28
1 FAMS @32851@
0 @32852@ INDI
1 NAME Victoria/BRÉARD de BOISANGER/
2 GIVN Victoria
2 SURN BRÉARD de BOISANGER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:28
1 FAMC @32851@
0 @32859@ INDI
1 NAME Alexandra/BRÉARD de BOISANGER/
2 GIVN Alexandra
2 SURN BRÉARD de BOISANGER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:28
1 FAMC @32851@
0 @32867@ INDI
1 NAME Francis/CHAUVEAU/
2 GIVN Francis
2 SURN CHAUVEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:30
1 FAMS @32869@
0 @32870@ INDI
1 NAME Paul/CHAUVEAU/
2 GIVN Paul
2 SURN CHAUVEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:30
1 FAMC @32869@
0 @32877@ INDI
1 NAME Eno/POLO/
2 GIVN Eno
2 SURN POLO
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:32
1 FAMS @32879@
0 @32882@ INDI
1 NAME Emmanuela Marei Adélaïde/POLO/
2 GIVN Emmanuela Marei Adélaïde
2 SURN POLO
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:31
1 FAMC @32879@
0 @32892@ INDI
1 NAME Sofia Marie-Astrid Yann/POLO/
2 GIVN Sofia Marie-Astrid Yann
2 SURN POLO
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:32
1 FAMC @32879@
0 @32902@ INDI
1 NAME Florian/de HAUT de SIGY/
2 GIVN Florian
2 SURN de HAUT de SIGY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:33
1 FAMS @32904@
0 @32906@ INDI
1 NAME Nicolas/JACQUOT/
2 GIVN Nicolas
2 SURN JACQUOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:34
1 FAMS @32908@
0 @32911@ INDI
1 NAME Constance/DESCHAMPS/
2 GIVN Constance
2 SURN DESCHAMPS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 13:32
1 FAMS @32913@
0 @32938@ INDI
1 NAME Henri Marie Juste/de MONTEYNARD/
2 GIVN Henri Marie Juste
2 SURN de MONTEYNARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:52
1 FAMS @32957@
1 BIRT
2 DATE 1851
2 _FNA NO
1 FAMC @32994@
0 @32955@ INDI
1 NAME Jeanne Marie Léonie/de LA FONTAINE/
2 GIVN Jeanne Marie Léonie
2 SURN de LA FONTAINE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:49
1 FAMS @32957@
1 BIRT
2 DATE 1865
2 _FNA NO
0 @32975@ INDI
1 NAME Henri Just/de MONTEYNARD/
2 GIVN Henri Just
2 SURN de MONTEYNARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:52
1 FAMS @32994@
1 BIRT
2 DATE 1819
2 _FNA NO
0 @32988@ INDI
1 NAME Marie Joséphine/MERTIAN/
2 GIVN Marie Joséphine
2 SURN MERTIAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:52
1 FAMS @32994@
1 BIRT
2 DATE 1824
2 _FNA NO
0 @33000@ INDI
1 NAME Louise/de LA CELLE-LESBRE/
2 GIVN Louise
2 SURN de LA CELLE-LESBRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:53
1 FAMC @32913@
0 @33005@ INDI
1 NAME Isaure/de LA CELLE-LESBRE/
2 GIVN Isaure
2 SURN de LA CELLE-LESBRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 AUG 2019
3 TIME 7:53
1 FAMC @32913@
0 @33053@ INDI
1 NAME Cécile/THURET/
2 GIVN Cécile
2 SURN THURET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 10:25
1 FAMS @33060@
1 BIRT
2 DATE 13 OCT 1862
2 PLAC Nevers,58000,Nièvre,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N46.989560
4 LONG E3.159000
2 _FNA NO
1 DEAT
2 DATE 15 JAN 1946
3 TIME 19:00
2 PLAC Paris 17,,,,,8 RUE EDOUARD DETAILLE
2 _FNA NO
1 FAMC @16463@
0 @33058@ INDI
1 NAME Louis/BONNARD/
2 GIVN Louis
2 SURN BONNARD
1 SEX M
1 OCCU docteur en droit
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 8:39
1 FAMS @33060@
1 BIRT
2 DATE 16 OCT 1856
3 TIME 23:45
2 PLAC Chartres,28000,Eure-et-Loir,Centre-Val de Loire,FRANCE,11BIS RUE DES GRENETS
3 MAP
4 LATI N48.446850
4 LONG E1.489250
2 _FNA NO
1 DEAT
2 DATE 12 JAN 1932
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,8 RUE EDOUARD DETAILLE
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
2 NOTE 8 RUE EDOUARD DETAILLE
1 NOTE avoué près la cour d’appel de Paris
1 FAMC @36862@
0 @33069@ INDI
1 NAME Louise Désirée Marie Rosalie/THURET/
2 GIVN Louise Désirée Marie Rosalie
2 SURN THURET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 11:50
1 FAMS @33096@
1 BIRT
2 DATE 23 OCT 1830
2 PLAC Crespières,78121,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883170
4 LONG E1.921510
2 _FNA NO
1 FAMC @23275@
0 @33090@ INDI
1 NAME Ambroise Gervais/LEROYER/
2 GIVN Ambroise Gervais
2 SURN LEROYER
1 SEX M
1 OCCU marchand de vin
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 11:50
1 FAMS @33096@
1 BIRT
2 DATE 7 SEP 1817
2 PLAC Carrouges,61320,Orne,Normandie,FRANCE,
3 MAP
4 LATI N48.567420
4 LONG W0.145640
2 _FNA NO
1 DEAT
2 DATE 7 DEC 1869
3 TIME 17:00
2 PLAC Paris 03,,,,,46 RUE DE TURENNE
2 _FNA NO
1 NOTE Ambroise sous le le serment le jour de son mariage déclare ne pas connaître les dates de décès de ses aïeux et aïeulles
1 FAMC @36947@
0 @33104@ INDI
1 NAME Louis Pierre/CAILLOT/
2 GIVN Louis Pierre
2 SURN CAILLOT
1 SEX M
1 OCCU Cultivateur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 NOV 2019
3 TIME 12:00
1 FAMS @33129@
1 BIRT
2 DATE 1772
2 _FNA NO
1 DEAT
2 DATE 1849
2 _FNA NO
1 FAMC @33156@
0 @33122@ INDI
1 NAME Rosalie Geneviève/GAUTIER/
2 GIVN Rosalie Geneviève
2 SURN GAUTIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 AUG 2019
3 TIME 21:12
1 FAMS @33129@
1 BIRT
2 DATE 1777
2 _FNA NO
1 DEAT
2 DATE 1832
2 _FNA NO
1 FAMC @33187@
0 @33134@ INDI
1 NAME Jean/CAILLOT/
2 GIVN Jean
2 SURN CAILLOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 AUG 2019
3 TIME 21:09
1 FAMS @33156@
0 @33141@ INDI
1 NAME Marie Louise Catherine/GILLERON/
2 GIVN Marie Louise Catherine
2 SURN GILLERON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 AUG 2019
3 TIME 21:09
1 FAMS @33156@
1 BIRT
2 DATE 1750
2 _FNA NO
1 DEAT
2 DATE 1825
2 _FNA NO
0 @33164@ INDI
1 NAME Jean Baptiste/GAUTIER/
2 GIVN Jean Baptiste
2 SURN GAUTIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 AUG 2019
3 TIME 21:12
1 FAMS @33187@
1 DEAT
2 DATE 1811
2 _FNA NO
0 @33176@ INDI
1 NAME Marie Nicole/AUGER/
2 GIVN Marie Nicole
2 SURN AUGER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 3 AUG 2019
3 TIME 21:12
1 FAMS @33187@
1 BIRT
2 DATE 1741
2 _FNA NO
1 DEAT
2 DATE 1811
2 _FNA NO
0 @33222@ INDI
1 NAME Augustine/LEROYER/
2 GIVN Augustine
2 SURN LEROYER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 AUG 2019
3 TIME 6:44
1 BIRT
2 DATE 1852
2 _FNA NO
1 FAMC @33096@
0 @33233@ INDI
1 NAME Louis Emile/LEROYER/
2 GIVN Louis Emile
2 SURN LEROYER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 AUG 2019
3 TIME 6:45
1 BIRT
2 DATE 1856
2 _FNA NO
1 DEAT
2 DATE 1856
2 _FNA NO
1 FAMC @33096@
0 @33282@ INDI
1 NAME Martial/CHAZOTTE/
2 GIVN Martial
2 SURN CHAZOTTE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 AUG 2019
3 TIME 7:15
1 FAMS @33297@
1 BIRT
2 DATE 1823
2 _FNA NO
1 DEAT
2 DATE 1885
2 _FNA NO
0 @33293@ INDI
1 NAME Berthe/ROBELIN/
2 GIVN Berthe
2 SURN ROBELIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 AUG 2019
3 TIME 7:15
1 FAMS @33297@
1 BIRT
2 DATE 1837
2 _FNA NO
0 @33314@ INDI
1 NAME Hermine/SABATIER/
2 GIVN Hermine
2 SURN SABATIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 AUG 2019
3 TIME 8:10
1 BIRT
2 _FNA NO
1 FAMC @7101@
0 @33321@ INDI
1 NAME Aude/SABATIER/
2 GIVN Aude
2 SURN SABATIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 AUG 2019
3 TIME 8:10
1 BIRT
2 _FNA NO
1 FAMC @7101@
0 @33334@ INDI
1 NAME Henri Charles/TORCHON/
2 GIVN Henri Charles
2 SURN TORCHON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 AUG 2019
3 TIME 8:24
1 FAMS @33354@
1 BIRT
2 DATE 1918
2 _FNA NO
1 DEAT
2 DATE 2007
2 _FNA NO
0 @33352@ INDI
1 NAME Hélène/AMIEUX/
2 GIVN Hélène
2 SURN AMIEUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 AUG 2019
3 TIME 8:24
1 FAMS @33354@
0 @33359@ INDI
1 NAME Pierre Léon/BERTIN/
2 GIVN Pierre Léon
2 SURN BERTIN
1 SEX M
1 OCCU Pêcheur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 DEC 2019
3 TIME 21:36
1 FAMS @33378@
1 BIRT
2 DATE 1758
2 PLAC Le Robert,97231,Martinique,Martinique,FRANCE,
3 MAP
4 LATI N14.677510
4 LONG W60.942280
2 _FNA NO
2 NOTE °1758 – Le Robert, Saint-Pierre – Quartier de la nouvelle ville, Pointe-à-Pitre
3 CONT
1 FAMC @36772@
0 @33371@ INDI
1 NAME Fanny/CHARLOTTE/
2 GIVN Fanny
2 SURN CHARLOTTE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 AUG 2019
3 TIME 17:40
1 FAMS @33378@
1 BIRT
2 DATE 1781
2 _FNA NO
1 DEAT
2 DATE 1821
2 _FNA NO
0 @33390@ INDI
1 NAME Domenica/?/
2 GIVN Domenica
2 SURN ?
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 AUG 2019
3 TIME 18:36
1 FAMS @33394@
1 BIRT
2 DATE ABT 1700
2 _FNA NO
0 @33399@ INDI
1 NAME Giovanni/AMBROSELLI/
2 GIVN Giovanni
2 SURN AMBROSELLI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 SEP 2019
3 TIME 17:42
1 FAMS @33403@
1 BIRT
2 DATE ABT 1660
2 PLAC Barrettali,20228,Haute-Corse,Corse,FRANCE,
3 MAP
4 LATI N42.876900
4 LONG E9.355400
2 _FNA NO
0 @33410@ INDI
1 NAME Maria Madalena/MATTEI/
2 GIVN Maria Madalena
2 SURN MATTEI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 5 AUG 2019
3 TIME 18:49
1 FAMS @33416@
1 BIRT
2 DATE ABT 1771
2 _FNA NO
0 @33427@ INDI
1 NAME Samuel/ACKER/
2 GIVN Samuel
2 SURN ACKER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 AUG 2019
3 TIME 7:36
1 BIRT
2 DATE 13 AUG 2019
3 TIME 1:00
2 _FNA NO
1 FAMC @1588@
0 @33437@ INDI
1 NAME Bertille/TRESCA/
2 GIVN Bertille
2 SURN TRESCA
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 13:30
1 FAMS @33439@
0 @33440@ INDI
1 NAME Victoire/de LA CELLE-LESBRE/
2 GIVN Victoire
2 SURN de LA CELLE-LESBRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 13:30
1 BIRT
2 DATE APR 2019
2 _FNA NO
1 FAMC @33439@
0 @33447@ INDI
1 NAME Ombeline/de LA CELLE-LESBRE/
2 GIVN Ombeline
2 SURN de LA CELLE-LESBRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 13:30
1 BIRT
2 DATE JUN 2014
2 _FNA NO
1 FAMC @33439@
0 @33454@ INDI
1 NAME Jean/de LA CELLE-LESBRE/
2 GIVN Jean
2 SURN de LA CELLE-LESBRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 13:33
1 BIRT
2 DATE FEB 2012
2 _FNA NO
1 FAMC @32913@
0 @33462@ INDI
1 NAME Juliette/ROSTAND/
2 GIVN Juliette
2 SURN ROSTAND
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 13:35
1 BIRT
2 DATE 18 AUG 2019
2 _FNA NO
1 FAMC @32606@
0 @33469@ INDI
1 NAME ?/?/
2 GIVN ?
2 SURN ?
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 18:31
1 FAMS @33471@
0 @33473@ INDI
1 NAME Rose-May/DUMONT/
2 GIVN Rose-May
2 SURN DUMONT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 18:31
1 BIRT
2 DATE JUN 2019
2 _FNA NO
1 FAMC @33471@
0 @33509@ INDI
1 NAME Sylvie/FOUREZ/
2 GIVN Sylvie
2 SURN FOUREZ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 20:01
1 FAMS @33511@
0 @33526@ INDI
1 NAME Emile Elie/PELTEREAU-VILLENEUVE/
2 GIVN Emile Elie
2 SURN PELTEREAU-VILLENEUVE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 19:04
1 FAMS @33536@
0 @33534@ INDI
1 NAME Magdeleine/THABAUD-DESHOULIATRES/
2 GIVN Magdeleine
2 SURN THABAUD-DESHOULIATRES
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 19:04
1 FAMS @33536@
0 @33539@ INDI
1 NAME Louis/de LA CELLE/
2 GIVN Louis
2 SURN de LA CELLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 19:59
1 BIRT
2 DATE 1921
2 _FNA NO
1 FAMC @32678@
0 @33546@ INDI
1 NAME Olivier/de LA CELLE/
2 GIVN Olivier
2 SURN de LA CELLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 19:59
1 BIRT
2 DATE 1922
2 _FNA NO
1 DEAT
2 DATE 2009
2 _FNA NO
1 FAMC @32678@
0 @33556@ INDI
1 NAME Bérengère/de LA CELLE/
2 GIVN Bérengère
2 SURN de LA CELLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 20:00
1 BIRT
2 DATE 1924
2 _FNA NO
1 FAMC @32678@
0 @33563@ INDI
1 NAME Colette/de LA CELLE/
2 GIVN Colette
2 SURN de LA CELLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 20:00
1 BIRT
2 DATE 1926
2 _FNA NO
1 FAMC @32678@
0 @33584@ INDI
1 NAME James Pierre Nestor/LESBRE/
2 GIVN James Pierre Nestor
2 SURN LESBRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 19:50
1 FAMS @33609@
1 BIRT
2 DATE 9 JAN 1851
2 PLAC Broût-Vernet,03110,Allier,Auvergne-Rhône-Alpes,FRANCE,
3 MAP
4 LATI N46.187560
4 LONG E3.273240
2 _FNA NO
1 DEAT
2 DATE 22 MAR 1908
2 PLAC Paris 08,,,,,
2 _FNA NO
0 @33594@ INDI
1 NAME Louise Marie Marguerite/MAUNOURY/
2 GIVN Louise Marie Marguerite
2 SURN MAUNOURY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 19:50
1 FAMS @33609@
1 BIRT
2 DATE 1866
2 _FNA NO
1 DEAT
2 DATE 1953
2 _FNA NO
0 @33643@ INDI
1 NAME Madeleine Élisa Berthe/LESBRE/
2 GIVN Madeleine Élisa Berthe
2 SURN LESBRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 19:56
1 FAMS @33683@
1 BIRT
2 DATE 13 SEP 1894
2 PLAC Luisant,28600,Eure-et-Loir,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N48.429500
4 LONG E1.473830
2 _FNA NO
1 DEAT
2 DATE 29 AUG 1971
2 PLAC Ségry,36100,Indre,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N46.890920
4 LONG E2.081890
2 _FNA NO
1 NOTE Madeleine a adopté Xavier de la Celle et lui a donné une partie de son nom
1 FAMC @33609@
0 @33681@ INDI
1 NAME Jean/PROUDHON/
2 GIVN Jean
2 SURN PROUDHON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 AUG 2019
3 TIME 19:58
1 FAMS @33683@
1 BIRT
2 DATE 1885
2 _FNA NO
1 DEAT
2 DATE 1966
2 _FNA NO
0 @33720@ INDI
1 NAME Radegonde Honorine Élisa/CHAVIGNY/
2 GIVN Radegonde Honorine Élisa
2 SURN CHAVIGNY
1 SEX F
1 OCCU Couturière
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 OCT 2019
3 TIME 8:32
1 FAMS @33728@
1 FAMS @33847@
1 BIRT
2 DATE 16 MAY 1837
2 PLAC Poitiers,86000,Vienne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.583330
4 LONG E0.333330
2 _FNA NO
1 DEAT
2 DATE 1911
2 _FNA NO
1 FAMC @33836@
0 @33774@ INDI
1 NAME Léon/HENRY-LEPAUTE/
2 GIVN Léon
2 SURN HENRY-LEPAUTE
1 SEX M
1 OCCU Ingénieur
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 AUG 2019
3 TIME 17:39
1 FAMS @33980@
1 BIRT
2 DATE 1839
2 _FNA NO
1 DEAT
2 DATE 5 JUL 1909
2 PLAC Le Mesnil-le-Roi,78600,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.938250
4 LONG E2.125540
2 _FNA NO
1 FAMC @33947@
0 @33778@ INDI
1 NAME Paul/HENRY-LEPAUTE/
2 GIVN Paul
2 SURN HENRY-LEPAUTE
1 SEX M
1 OCCU Horloger
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 AUG 2019
3 TIME 16:20
1 BIRT
2 DATE 1843
2 _FNA NO
1 FAMC @33947@
0 @33796@ INDI
1 NAME Marie Augustine Sabine/MEDARD/
2 GIVN Marie Augustine Sabine
2 SURN MEDARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 SEP 2019
3 TIME 16:18
1 FAMS @33802@
1 BIRT
2 DATE 12 AUG 1804
2 PLAC Paris 10,,,,,
2 _FNA YES
1 DEAT
2 DATE 8 FEB 1894
3 TIME 2:00
2 PLAC Paris 09,,,,,26 RUE VIGNON
2 _FNA NO
1 FAMC @34096@
0 @33820@ INDI
1 NAME Pierre/CHAVIGNY/
2 GIVN Pierre
2 SURN CHAVIGNY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 OCT 2019
3 TIME 18:49
1 FAMS @33836@
1 BIRT
2 DATE 28 JUL 1798
2 PLAC Poitiers,86000,Vienne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.583330
4 LONG E0.333330
2 _FNA NO
2 NOTE onze thermidor an six
1 DEAT
2 DATE 12 OCT 1841
3 TIME 11:00
2 PLAC Poitiers,86000,Vienne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.583330
4 LONG E0.333330
2 _FNA NO
1 FAMC @34851@
0 @33830@ INDI
1 NAME Marie Anne/HUBLET/
2 GIVN Marie Anne
2 SURN HUBLET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 OCT 2019
3 TIME 18:49
1 FAMS @33836@
1 BIRT
2 DATE 17 NOV 1808
2 PLAC Poitiers,86000,Vienne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.583330
4 LONG E0.333330
2 _FNA NO
2 NOTE 58 ans le 12/02/1868 ?
1 FAMC @34944@
0 @33843@ INDI
1 NAME Théodore/NADAUD/
2 GIVN Théodore
2 SURN NADAUD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 AUG 2019
3 TIME 8:48
1 FAMS @33847@
1 DEAT
2 DATE 1 JAN 1862
2 PLAC Paris 10,,,,,
2 _FNA NO
0 @33849@ INDI
1 NAME Augustin Michel Adam/HENRY-LEPAUTE/
2 GIVN Augustin Michel Adam
2 SURN HENRY-LEPAUTE
1 SEX M
1 OCCU horloger constructeur de phare
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 AUG 2019
3 TIME 16:12
1 FAMS @33947@
1 BIRT
2 DATE 13 MAY 1800
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 7 NOV 1885
2 PLAC Le Mesnil-le-Roi,78600,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.938250
4 LONG E2.125540
2 _FNA NO
1 NOTE Né Augustin Michel Adam Henry de Pierre Henry, horloger du Roi, descendant de la famille d'horlogers Lepaute par sa mère, et Gabrielle Prévost, il épouse une de ses cousines, Anaïs Lepaute, en 1834. Élève de Gustave Eiffel, il devient l'horloger de
2 CONC  Louis-Philippe et de Napoléon III. C'est ce dernier qui l'autorise en 1851 à porter, ainsi que ses descendants, le nom de Henry-Lepaute1.
2 CONT En 1823, il s'associe avec Augustin Fresnel, directeur des Phares et balises et se spécialise dans la construction d’horloges et de phares monumentaux tels le phare de Valsörarna en Finlande, équipés de mécanismes rotatifs d'optique.
2 CONT Son entreprise devient en 1837 fournisseur exclusif des horloges des réseaux de chemins de fer du Nord, d’Orléans, de l’Est et de l’Ouest. Plus de 1 300 phares dans le monde seront équipés du système Henry-Lepaute. Deux fils poursuivront sa double
2 CONC activité : Édouard Léon et Paul Joseph.
2 CONT Décédé en 1885, il est inhumé au cimetière de Le Mesnil-le-Roi où l'on peut encore voir sa tombe2.
0 @33855@ INDI
1 NAME Emile/MELON de PRADOU/
2 GIVN Emile
2 SURN MELON de PRADOU
1 SEX M
1 OCCU négociant juge tribunal de commerce
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 AUG 2019
3 TIME 9:03
0 @33861@ INDI
1 NAME Jean-Baptiste/CHENÉ/
2 GIVN Jean-Baptiste
2 SURN CHENÉ
1 SEX M
1 OCCU négociant
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 AUG 2019
3 TIME 9:07
0 @33866@ INDI
1 NAME Joseph François/DELAMAIRE/
2 GIVN Joseph François
2 SURN DELAMAIRE
1 SEX M
1 OCCU négociant
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 AUG 2019
3 TIME 9:10
0 @33945@ INDI
1 NAME Anaïs/LEPAUTE/
2 GIVN Anaïs
2 SURN LEPAUTE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 AUG 2019
3 TIME 16:12
1 FAMS @33947@
0 @33969@ INDI
1 NAME Pauline Marie/DURAND/
2 GIVN Pauline Marie
2 SURN DURAND
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 AUG 2019
3 TIME 17:39
1 FAMS @33980@
1 BIRT
2 DATE 1846
2 _FNA NO
1 DEAT
2 DATE 1929
2 _FNA NO
0 @33989@ INDI
1 NAME Hortense/HENRY-LEPAUTE/
2 GIVN Hortense
2 SURN HENRY-LEPAUTE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 AUG 2019
3 TIME 17:38
1 BIRT
2 DATE 1868
2 _FNA NO
1 DEAT
2 DATE 1945
2 _FNA NO
1 FAMC @33980@
0 @34000@ INDI
1 NAME André/HENRY-LEPAUTE/
2 GIVN André
2 SURN HENRY-LEPAUTE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 AUG 2019
3 TIME 17:39
1 BIRT
2 DATE 1871
2 _FNA NO
1 DEAT
2 DATE 1908
2 _FNA NO
1 FAMC @33980@
0 @34011@ INDI
1 NAME Jeanne/HENRY-LEPAUTE/
2 GIVN Jeanne
2 SURN HENRY-LEPAUTE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 AUG 2019
3 TIME 17:39
1 BIRT
2 DATE 1873
2 _FNA NO
1 DEAT
2 DATE 1959
2 _FNA NO
1 FAMC @33980@
0 @34071@ INDI
1 NAME Jean François Louis Gabriel/MEDARD/
2 GIVN Jean François Louis Gabriel
2 SURN MEDARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 SEP 2019
3 TIME 16:03
1 FAMS @34096@
0 @34092@ INDI
1 NAME Françoise Augustine Clémentine/DUPAIN/
2 GIVN Françoise Augustine Clémentine
2 SURN DUPAIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 SEP 2019
3 TIME 16:03
1 FAMS @34096@
0 @34110@ INDI
1 NAME Romane/de SAINT SIMON/
2 GIVN Romane
2 SURN de SAINT SIMON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 12:52
1 BIRT
2 DATE 9 JAN 2018
2 _FNA NO
1 FAMC @5330@
0 @34117@ INDI
1 NAME Gaspard/de SAINT SIMON/
2 GIVN Gaspard
2 SURN de SAINT SIMON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 12:52
1 BIRT
2 DATE 9 JAN 2018
2 _FNA NO
1 FAMC @5330@
0 @34127@ INDI
1 NAME Sacha/MISSOFFE/
2 GIVN Sacha
2 SURN MISSOFFE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 12:59
1 BIRT
2 DATE 23 NOV 2018
2 _FNA NO
1 FAMC @10850@
0 @34134@ INDI
1 NAME Otis/GERMERIE/
2 GIVN Otis
2 SURN GERMERIE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 13:02
1 BIRT
2 DATE 7 MAR 2019
2 _FNA NO
1 FAMC @7284@
0 @34141@ INDI
1 NAME Anaïs/GRANIER/
2 GIVN Anaïs
2 SURN GRANIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 13:03
1 BIRT
2 DATE 5 APR 2019
2 _FNA NO
1 FAMC @4480@
0 @34148@ INDI
1 NAME Gaston/HUBERT/
2 GIVN Gaston
2 SURN HUBERT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 13:05
1 BIRT
2 DATE 16 JUN 2019
2 _FNA NO
1 FAMC @5243@
0 @34155@ INDI
1 NAME Oscar/SAINT OLIVE/
2 GIVN Oscar
2 SURN SAINT OLIVE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 13:07
1 BIRT
2 DATE 19 AUG 2019
2 _FNA NO
1 FAMC @10862@
0 @34185@ INDI
1 NAME Jacques/CHATIN/
2 GIVN Jacques
2 SURN CHATIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 19:16
1 FAMS @34193@
0 @34191@ INDI
1 NAME Jacqueline/FAYE/
2 GIVN Jacqueline
2 SURN FAYE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 19:16
1 FAMS @34193@
1 FAMC @34198@
0 @34196@ INDI
1 NAME Robert/FAYE/
2 GIVN Robert
2 SURN FAYE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 19:15
1 FAMS @34198@
1 FAMC @34203@
0 @34201@ INDI
1 NAME Auguste/FAYE/
2 GIVN Auguste
2 SURN FAYE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 19:15
1 FAMS @34203@
0 @34206@ INDI
1 NAME Anne/CHATIN/
2 GIVN Anne
2 SURN CHATIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 19:17
1 FAMS @34214@
1 FAMC @34193@
0 @34212@ INDI
1 NAME Xy/PONIATOVSKI/
2 GIVN Xy
2 SURN PONIATOVSKI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 19:17
1 FAMS @34214@
0 @34217@ INDI
1 NAME Reno/?/
2 GIVN Reno
2 SURN ?
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 19:39
1 FAMS @34219@
0 @34248@ INDI
1 NAME Loïc/?/
2 GIVN Loïc
2 SURN ?
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 9:42
1 FAMS @34250@
0 @34271@ INDI
1 NAME Brune/TRAPENARD/
2 GIVN Brune
2 SURN TRAPENARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 9:24
1 BIRT
2 DATE 13 OCT 2016
2 _FNA NO
1 FAMC @6462@
0 @34281@ INDI
1 NAME Lucie/?/
2 GIVN Lucie
2 SURN ?
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 SEP 2019
3 TIME 20:38
1 FAMS @34283@
0 @34294@ INDI
1 NAME François/ENAUD ROBIN de MORHÉRY/
2 GIVN François
2 SURN ENAUD ROBIN de MORHÉRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 SEP 2019
3 TIME 18:05
1 FAMS @34296@
0 @34297@ INDI
1 NAME Béatrice/PALUEL-MARMONT/
2 GIVN Béatrice
2 SURN PALUEL-MARMONT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 SEP 2019
3 TIME 18:27
1 BIRT
2 DATE 1942
2 _FNA NO
1 FAMC @1232@
0 @34304@ INDI
1 NAME Jean-Marie/PALUEL-MARMONT/
2 GIVN Jean-Marie
2 SURN PALUEL-MARMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 SEP 2019
3 TIME 18:28
1 BIRT
2 DATE 1943
2 _FNA NO
1 FAMC @1232@
0 @34311@ INDI
1 NAME Christophe/PALUEL-MARMONT/
2 GIVN Christophe
2 SURN PALUEL-MARMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 SEP 2019
3 TIME 18:29
1 BIRT
2 DATE 1948
2 _FNA NO
1 FAMC @1232@
0 @34318@ INDI
1 NAME Claudia Claude/PALUEL-MARMONT/
2 GIVN Claudia Claude
2 SURN PALUEL-MARMONT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 SEP 2019
3 TIME 18:29
1 BIRT
2 DATE 1950
2 _FNA NO
1 FAMC @1232@
0 @34329@ INDI
1 NAME Diego/CISNEROS-GARNICA/
2 GIVN Diego
2 SURN CISNEROS-GARNICA
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 SEP 2019
3 TIME 18:49
1 BIRT
2 DATE 15 SEP 2019
2 _FNA NO
1 FAMC @3489@
0 @34345@ INDI
1 NAME Charles/de SEZE/
2 GIVN Charles
2 SURN de SEZE
1 SEX M
1 OCCU Prêtre jésuite
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 SEP 2019
3 TIME 12:22
1 FAMC @32732@
0 @34360@ INDI
1 NAME Paul/CURY/
2 GIVN Paul
2 SURN CURY
1 SEX M
1 OCCU Enseignant
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2019
3 TIME 11:22
1 FAMS @34372@
1 BIRT
2 DATE 1837
2 _FNA NO
2 NOTE ou 1838?
1 DEAT
2 DATE 1911
2 _FNA NO
1 NOTE professeur à l’école lavoisier
1 FAMC @35430@
0 @34366@ INDI
1 NAME Anna Maria/RICH/
2 GIVN Anna Maria
2 SURN RICH
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2019
3 TIME 11:22
1 FAMS @34372@
1 BIRT
2 DATE 1840
2 _FNA NO
1 DEAT
2 DATE 1920
2 _FNA NO
0 @34383@ INDI
1 NAME Georges Alexandre/CURY/
2 GIVN Georges Alexandre
2 SURN CURY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 SEP 2019
3 TIME 11:43
1 BIRT
2 DATE 1866
2 _FNA NO
1 FAMC @34372@
0 @34394@ INDI
1 NAME Léon/CURY/
2 GIVN Léon
2 SURN CURY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 16 SEP 2019
3 TIME 11:43
1 BIRT
2 DATE 1867
2 _FNA NO
1 FAMC @34372@
0 @34437@ INDI
1 NAME Hélie/CHAVANNE/
2 GIVN Hélie
2 SURN CHAVANNE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 14:02
1 BIRT
2 DATE 23 SEP 2019
2 PLAC Paris 15,,,,,
2 _FNA NO
1 FAMC @11334@
0 @34452@ INDI
1 NAME Marie/SANTI/
2 GIVN Marie
2 SURN SANTI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 17:55
1 FAMS @34459@
1 BIRT
2 DATE 7 FEB 1889
2 PLAC Porto Rico,,,,,
2 _FNA NO
1 DEAT
2 DATE AUG 1965
2 _FNA NO
0 @34474@ INDI
1 NAME Irénée/CHAVANNE/
2 GIVN Irénée
2 SURN CHAVANNE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 17:30
1 FAMS @34490@
1 BIRT
2 DATE 1852
2 _FNA NO
1 DEAT
2 DATE 1933
2 _FNA NO
0 @34486@ INDI
1 NAME Marie/PITIOT/
2 GIVN Marie
2 SURN PITIOT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 17:30
1 FAMS @34490@
1 BIRT
2 DATE 1854
2 _FNA NO
0 @34501@ INDI
1 NAME Janie/CHAVANNE/
2 GIVN Janie
2 SURN CHAVANNE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 17:56
1 FAMS @34513@
1 NOTE ou Jany
1 FAMC @19578@
0 @34511@ INDI
1 NAME Jean-François/RICHARD/
2 GIVN Jean-François
2 SURN RICHARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 17:39
1 FAMS @34513@
0 @34514@ INDI
1 NAME Olivia/RICHARD/
2 GIVN Olivia
2 SURN RICHARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 17:50
1 FAMS @34524@
1 BIRT
2 DATE 1980
2 _FNA NO
1 FAMC @34513@
0 @34520@ INDI
1 NAME Pierre Jacques Henri/POUGET/
2 GIVN Pierre Jacques Henri
2 SURN POUGET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 17:50
1 FAMS @34524@
1 BIRT
2 DATE 1976
2 _FNA NO
0 @34526@ INDI
1 NAME Maya/POUGET/
2 GIVN Maya
2 SURN POUGET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 17:50
1 BIRT
2 DATE 2001
2 _FNA NO
1 FAMC @34524@
0 @34533@ INDI
1 NAME Violette/POUGET/
2 GIVN Violette
2 SURN POUGET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 17:41
1 FAMC @34524@
0 @34573@ INDI
1 NAME Pierre Louis "Pilou"/CHAVANNE/
2 GIVN Pierre Louis "Pilou"
2 SURN CHAVANNE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 18:02
1 FAMS @34633@
1 FAMC @34459@
0 @34583@ INDI
1 NAME Remi/CHAVANNE/
2 GIVN Remi
2 SURN CHAVANNE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 17:58
1 FAMS @34622@
1 FAMC @19578@
0 @34593@ INDI
1 NAME Bruno/CHAVANNE/
2 GIVN Bruno
2 SURN CHAVANNE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 18:01
1 FAMS @34626@
1 FAMC @19578@
0 @34604@ INDI
1 NAME Éric/CHAVANNE/
2 GIVN Éric
2 SURN CHAVANNE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 17:57
1 FAMS @34617@
1 FAMC @19578@
0 @34615@ INDI
1 NAME Valérie/LISART/
2 GIVN Valérie
2 SURN LISART
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 17:57
1 FAMS @34617@
0 @34620@ INDI
1 NAME Danièle/BERTAUX/
2 GIVN Danièle
2 SURN BERTAUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 17:58
1 FAMS @34622@
0 @34624@ INDI
1 NAME Élise/de WECK/
2 GIVN Élise
2 SURN de WECK
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 18:01
1 FAMS @34626@
0 @34631@ INDI
1 NAME Yvonne/BRUN/
2 GIVN Yvonne
2 SURN BRUN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 18:02
1 FAMS @34633@
0 @34634@ INDI
1 NAME Marcel/LOUISON/
2 GIVN Marcel
2 SURN LOUISON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 18:05
1 FAMS @34658@
1 BIRT
2 DATE 1891
2 _FNA NO
1 DEAT
2 DATE 1955
2 _FNA NO
0 @34647@ INDI
1 NAME Jeanne Camille/BRUN/
2 GIVN Jeanne Camille
2 SURN BRUN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 18:13
1 FAMS @34658@
1 BIRT
2 DATE 1897
2 PLAC Saint-Chamond,42400,Loire,Auvergne-Rhône-Alpes,FRANCE,
3 MAP
4 LATI N45.473650
4 LONG E4.525410
2 _FNA NO
1 DEAT
2 DATE 1928
2 _FNA NO
1 FAMC @34684@
0 @34665@ INDI
1 NAME Louis/BRUN/
2 GIVN Louis
2 SURN BRUN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 18:11
1 FAMS @34684@
1 BIRT
2 DATE 1858
2 _FNA NO
1 DEAT
2 DATE 1930
2 _FNA NO
0 @34677@ INDI
1 NAME Mathilde/GERMAIN de MONTAUZAN/
2 GIVN Mathilde
2 SURN GERMAIN de MONTAUZAN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 SEP 2019
3 TIME 18:11
1 FAMS @34684@
1 BIRT
2 DATE 1864
2 _FNA NO
1 DEAT
2 DATE 1948
2 _FNA NO
0 @34720@ INDI
1 NAME Eléonore/de LA CELLE-LESBRE/
2 GIVN Eléonore
2 SURN de LA CELLE-LESBRE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 26 SEP 2019
3 TIME 14:28
1 BIRT
2 DATE 26 SEP 2019
3 TIME 6:15
2 PLAC Rouen,76000,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.443130
4 LONG E1.099320
2 _FNA NO
1 FAMC @241@
0 @34735@ INDI
1 NAME Charles/FRANCIERE/
2 GIVN Charles
2 SURN FRANCIERE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 8:02
1 FAMS @34737@
1 BIRT
2 DATE 27 FEB 1936
2 _FNA NO
0 @34791@ INDI
1 NAME Théodore/CARTILLIER-BOND/
2 GIVN Théodore
2 SURN CARTILLIER-BOND
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 11:12
1 BIRT
2 DATE 29 MAY 2014
2 _FNA NO
1 FAMC @5345@
0 @34804@ INDI
1 NAME Aude/FERTÉ/
2 GIVN Aude
2 SURN FERTÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 11:57
1 FAMS @34808@
1 BIRT
2 DATE 9 JUL 1982
2 _FNA NO
0 @34811@ INDI
1 NAME Joseph/MISSOFFE/
2 GIVN Joseph
2 SURN MISSOFFE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 6 OCT 2019
3 TIME 11:57
1 BIRT
2 DATE 23 APR 2016
2 _FNA NO
1 FAMC @34808@
0 @34841@ INDI
1 NAME Etienne/CHAVIGNY/
2 GIVN Etienne
2 SURN CHAVIGNY
1 SEX M
1 OCCU magistrat
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 OCT 2019
3 TIME 17:30
1 FAMS @34851@
1 BIRT
2 DATE 12 JAN 1768
2 PLAC Saint-Dyé-sur-Loire,41500,Loir-et-Cher,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N47.655950
4 LONG E1.488470
2 _FNA NO
1 NOTE chapellier?
1 FAMC @34874@
0 @34849@ INDI
1 NAME Anne/ROY/
2 GIVN Anne
2 SURN ROY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 OCT 2019
3 TIME 18:21
1 FAMS @34851@
1 BIRT
2 DATE 18 APR 1772
2 PLAC Poitiers,86000,Vienne,Nouvelle-Aquitaine,FRANCE,paroisse de Sainte Radegonde
3 MAP
4 LATI N46.583330
4 LONG E0.333330
2 _FNA NO
2 NOTE reg 257
1 FAMC @34891@
0 @34861@ INDI
1 NAME Etienne/CHAVIGNY/
2 GIVN Etienne
2 SURN CHAVIGNY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 OCT 2019
3 TIME 9:24
1 FAMS @34874@
0 @34872@ INDI
1 NAME Marie/MASSON/
2 GIVN Marie
2 SURN MASSON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 OCT 2019
3 TIME 9:24
1 FAMS @34874@
0 @34883@ INDI
1 NAME Pierre/ROY/
2 GIVN Pierre
2 SURN ROY
1 SEX M
1 OCCU Menuisier
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 OCT 2019
3 TIME 18:16
1 FAMS @34891@
0 @34889@ INDI
1 NAME Radegonde/BEAUPRÉ/
2 GIVN Radegonde
2 SURN BEAUPRÉ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 OCT 2019
3 TIME 9:44
1 FAMS @34891@
0 @34897@ INDI
1 NAME Pierre/ROY/
2 GIVN Pierre
2 SURN ROY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 12 OCT 2019
3 TIME 9:44
1 FAMC @34891@
0 @34921@ INDI
1 NAME René André/HUBLET/
2 GIVN René André
2 SURN HUBLET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 OCT 2019
3 TIME 17:42
1 FAMS @34944@
1 BIRT
2 DATE 10 APR 1781
2 PLAC Poitiers,86000,Vienne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.583330
4 LONG E0.333330
2 _FNA NO
1 DEAT
2 DATE 23 NOV 1859
2 PLAC Poitiers,86000,Vienne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.583330
4 LONG E0.333330
2 _FNA NO
1 FAMC @34961@
0 @34938@ INDI
1 NAME Elisabeth Marie/GIRAULT/
2 GIVN Elisabeth Marie
2 SURN GIRAULT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 OCT 2019
3 TIME 17:42
1 FAMS @34944@
1 BIRT
2 DATE 27 NOV 1785
2 PLAC Poitiers,86000,Vienne,Nouvelle-Aquitaine,FRANCE,Sainte radegonde
3 MAP
4 LATI N46.583330
4 LONG E0.333330
2 _FNA NO
0 @34949@ INDI
1 NAME André/HUBLET/
2 GIVN André
2 SURN HUBLET
1 SEX M
1 OCCU sergetier
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 OCT 2019
3 TIME 17:00
1 FAMS @34961@
1 BIRT
2 DATE 1750
2 PLAC Les Aubiers,79250,Deux-Sèvres,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.954280
4 LONG W0.589280
2 _FNA NO
1 FAMC @34999@
0 @34957@ INDI
1 NAME Anne/DESCHAMPS/
2 GIVN Anne
2 SURN DESCHAMPS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 OCT 2019
3 TIME 18:02
1 FAMS @34961@
1 BIRT
2 DATE 1755
2 PLAC Poitiers,86000,Vienne,Nouvelle-Aquitaine,FRANCE,saint germain
3 MAP
4 LATI N46.583330
4 LONG E0.333330
2 _FNA NO
1 DEAT
2 DATE 18 APR 1820
2 _FNA NO
1 FAMC @35012@
0 @34988@ INDI
1 NAME André/HUBLET/
2 GIVN André
2 SURN HUBLET
1 SEX M
1 OCCU mercier
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 OCT 2019
3 TIME 16:51
1 FAMS @34999@
0 @34997@ INDI
1 NAME Jacquette/RAYMON/
2 GIVN Jacquette
2 SURN RAYMON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 OCT 2019
3 TIME 16:55
1 FAMS @34999@
1 DEAT
2 DATE BEF 3 FEB 1778
2 _FNA NO
0 @35004@ INDI
1 NAME Jacques/DESCHAMPS/
2 GIVN Jacques
2 SURN DESCHAMPS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 OCT 2019
3 TIME 16:54
1 FAMS @35012@
1 DEAT
2 DATE BEF 3 FEB 1778
2 _FNA NO
0 @35010@ INDI
1 NAME Jeanne/CHAPRON/
2 GIVN Jeanne
2 SURN CHAPRON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 OCT 2019
3 TIME 16:53
1 FAMS @35012@
0 @35031@ INDI
1 NAME Geneviève/HUBLET/
2 GIVN Geneviève
2 SURN HUBLET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 13 OCT 2019
3 TIME 17:55
1 DEAT
2 DATE 16 SEP 1787
2 PLAC Poitiers,86000,Vienne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.583330
4 LONG E0.333330
2 _FNA NO
1 FAMC @34944@
0 @35103@ INDI
1 NAME Stéphane/MUGUET/
2 GIVN Stéphane
2 SURN MUGUET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:13
1 FAMS @35105@
0 @35106@ INDI
1 NAME Marie/MUGUET/
2 GIVN Marie
2 SURN MUGUET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:12
1 FAMC @35105@
0 @35111@ INDI
1 NAME Raphaël/MUGUET/
2 GIVN Raphaël
2 SURN MUGUET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:13
1 FAMC @35105@
0 @35117@ INDI
1 NAME Camille/BOUFFET/
2 GIVN Camille
2 SURN BOUFFET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:14
1 FAMS @35119@
0 @35120@ INDI
1 NAME Grégoire/de BAYSER/
2 GIVN Grégoire
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:14
1 FAMC @35119@
0 @35125@ INDI
1 NAME Violaine/de BAYSER/
2 GIVN Violaine
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:14
1 FAMC @35119@
0 @35131@ INDI
1 NAME Côme/de BAYSER/
2 GIVN Côme
2 SURN de BAYSER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:14
1 FAMC @35119@
0 @35136@ INDI
1 NAME Olivia/de BAYSER/
2 GIVN Olivia
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:14
1 FAMC @35119@
0 @35142@ INDI
1 NAME Nicolas/BÜRKI/
2 GIVN Nicolas
2 SURN BÜRKI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:16
1 FAMS @35144@
0 @35145@ INDI
1 NAME Basile/BÜRKI/
2 GIVN Basile
2 SURN BÜRKI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:16
1 FAMC @35144@
0 @35150@ INDI
1 NAME Oscar/BÜRKI/
2 GIVN Oscar
2 SURN BÜRKI
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:16
1 FAMC @35144@
0 @35155@ INDI
1 NAME Ange/BÜRKI/
2 GIVN Ange
2 SURN BÜRKI
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:16
1 FAMC @35144@
0 @35160@ INDI
1 NAME Paul/BOUFFET/
2 GIVN Paul
2 SURN BOUFFET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:18
1 FAMS @35162@
0 @35163@ INDI
1 NAME Louis/BOUFFET/
2 GIVN Louis
2 SURN BOUFFET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:17
1 FAMC @35162@
0 @35168@ INDI
1 NAME Benoît/BOUFFET/
2 GIVN Benoît
2 SURN BOUFFET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:17
1 FAMC @35162@
0 @35173@ INDI
1 NAME Charlotte/BOUFFET/
2 GIVN Charlotte
2 SURN BOUFFET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:18
1 FAMC @35162@
0 @35178@ INDI
1 NAME Julien/BOUFFET/
2 GIVN Julien
2 SURN BOUFFET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:18
1 FAMC @35162@
0 @35185@ INDI
1 NAME Erwan/VARILLON/
2 GIVN Erwan
2 SURN VARILLON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:19
1 FAMS @35187@
0 @35188@ INDI
1 NAME Valentine/VARILLON/
2 GIVN Valentine
2 SURN VARILLON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:19
1 FAMC @35187@
0 @35193@ INDI
1 NAME Agathe/VARILLON/
2 GIVN Agathe
2 SURN VARILLON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:19
1 FAMC @35187@
0 @35198@ INDI
1 NAME Baptiste/VARILLON/
2 GIVN Baptiste
2 SURN VARILLON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:19
1 FAMC @35187@
0 @35204@ INDI
1 NAME Colombe/SEYDOUX/
2 GIVN Colombe
2 SURN SEYDOUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:21
1 FAMC @35203@
0 @35209@ INDI
1 NAME Gabrielle/SEYDOUX/
2 GIVN Gabrielle
2 SURN SEYDOUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:21
1 FAMC @35203@
0 @35225@ INDI
1 NAME Victoire/DUMONT/
2 GIVN Victoire
2 SURN DUMONT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:29
1 FAMC @4135@
0 @35230@ INDI
1 NAME Émilie/DUMONT/
2 GIVN Émilie
2 SURN DUMONT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:29
1 FAMC @4135@
0 @35235@ INDI
1 NAME Jean Gabriel/DUMONT/
2 GIVN Jean Gabriel
2 SURN DUMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:30
1 FAMC @4135@
0 @35242@ INDI
1 NAME Hubert/DUMONT/
2 GIVN Hubert
2 SURN DUMONT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:30
1 FAMC @4135@
0 @35247@ INDI
1 NAME Émilie/de BAYSER/
2 GIVN Émilie
2 SURN de BAYSER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 OCT 2019
3 TIME 19:31
1 FAMC @7895@
0 @35303@ INDI
1 NAME Étienne/HUBLET/
2 GIVN Étienne
2 SURN HUBLET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 19 OCT 2019
3 TIME 18:32
1 BIRT
2 DATE 26 AUG 1810
3 TIME 9:00
2 PLAC Poitiers,86000,Vienne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.583330
4 LONG E0.333330
2 _FNA NO
1 DEAT
2 DATE 12 NOV 1811
3 TIME 2:00
2 PLAC Poitiers,86000,Vienne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.583330
4 LONG E0.333330
2 _FNA NO
1 FAMC @34944@
0 @35358@ INDI
1 NAME Renée/SALANCON/
2 GIVN Renée
2 SURN SALANCON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 8:10
1 FAMS @35365@
1 BIRT
2 DATE 1894
2 _FNA NO
1 DEAT
2 DATE 1981
2 _FNA NO
0 @35366@ INDI
1 NAME Gilbert/CURY/
2 GIVN Gilbert
2 SURN CURY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 8:11
1 FAMS @35378@
1 BIRT
2 DATE 1927
2 _FNA NO
1 FAMC @35365@
0 @35374@ INDI
1 NAME Paulette/FOURNIER/
2 GIVN Paulette
2 SURN FOURNIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 8:11
1 FAMS @35378@
1 BIRT
2 DATE 1923
2 _FNA NO
0 @35385@ INDI
1 NAME Claude/LABAT/
2 GIVN Claude
2 SURN LABAT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 8:17
1 FAMS @35392@
1 BIRT
2 DATE 1871
2 _FNA NO
1 DEAT
2 DATE 1961
2 _FNA NO
0 @35395@ INDI
1 NAME Blanche Elisabeth/LABAT/
2 GIVN Blanche Elisabeth
2 SURN LABAT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 8:17
1 BIRT
2 DATE 1897
2 _FNA NO
1 DEAT
2 DATE 1992
2 _FNA NO
1 FAMC @35392@
0 @35415@ INDI
1 NAME Jacques/CURY/
2 GIVN Jacques
2 SURN CURY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 8:38
1 FAMS @35430@
1 BIRT
2 DATE 1808
2 _FNA NO
1 DEAT
2 DATE 1864
2 _FNA NO
0 @35426@ INDI
1 NAME Françoise/BOURGOIN/
2 GIVN Françoise
2 SURN BOURGOIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 8:38
1 FAMS @35430@
1 DEAT
2 DATE 1857
2 _FNA NO
0 @35435@ INDI
1 NAME Pio/BOUYER/
2 GIVN Pio
2 SURN BOUYER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 14:27
1 BIRT
2 DATE 2019
2 _FNA NO
1 FAMC @2150@
0 @35443@ INDI
1 NAME Xx1/BOUYER/
2 GIVN Xx1
2 SURN BOUYER
1 SEX U
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 14:28
1 BIRT
2 DATE 2017
2 _FNA NO
1 FAMC @2150@
0 @35450@ INDI
1 NAME Henri/BECHET/
2 GIVN Henri
2 SURN BECHET
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 17:59
1 FAMC @11955@
0 @35455@ INDI
1 NAME Régis/ESPRIT BOUHIER de L'ECLUSE/
2 GIVN Régis
2 SURN ESPRIT BOUHIER de L'ECLUSE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 OCT 2019
3 TIME 17:25
1 FAMS @35467@
1 CHR
2 _FNA NO
1 FAMC @35478@
0 @35465@ INDI
1 NAME Béatrice/RÉGLADE/
2 GIVN Béatrice
2 SURN RÉGLADE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 20:16
1 FAMS @35467@
1 BIRT
2 DATE 1957
2 _FNA NO
0 @35470@ INDI
1 NAME Robert/ESPRIT BOUHIER de L'ECLUSE/
2 GIVN Robert
2 SURN ESPRIT BOUHIER de L'ECLUSE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 20:25
1 FAMS @35478@
1 BIRT
2 DATE 14 AUG 1923
2 PLAC Neung-sur-Beuvron,41210,Loir-et-Cher,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N47.534860
4 LONG E1.805140
2 _FNA NO
1 FAMC @35541@
0 @35476@ INDI
1 NAME Marie-Thérèse/FOURNEL/
2 GIVN Marie-Thérèse
2 SURN FOURNEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 20:26
1 FAMS @35478@
1 BIRT
2 DATE 1925
2 _FNA NO
0 @35481@ INDI
1 NAME Brigitte/ESPRIT BOUHIER de L'ECLUSE/
2 GIVN Brigitte
2 SURN ESPRIT BOUHIER de L'ECLUSE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 20:01
1 FAMC @35478@
0 @35486@ INDI
1 NAME Chantal/ESPRIT BOUHIER de L'ECLUSE/
2 GIVN Chantal
2 SURN ESPRIT BOUHIER de L'ECLUSE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 20:01
1 FAMC @35478@
0 @35491@ INDI
1 NAME Ghislaine/ESPRIT BOUHIER de L'ECLUSE/
2 GIVN Ghislaine
2 SURN ESPRIT BOUHIER de L'ECLUSE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 20:02
1 FAMC @35478@
0 @35496@ INDI
1 NAME Anne-Marie/ESPRIT BOUHIER de L'ECLUSE/
2 GIVN Anne-Marie
2 SURN ESPRIT BOUHIER de L'ECLUSE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 20:02
1 FAMC @35478@
0 @35505@ INDI
1 NAME Marie Adrien Paul/ESPRIT BOUHIER de L'ECLUSE/
2 GIVN Marie Adrien Paul
2 SURN ESPRIT BOUHIER de L'ECLUSE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 20:21
1 FAMS @35541@
1 BIRT
2 DATE 1883
2 _FNA NO
1 DEAT
2 DATE 1968
2 _FNA NO
0 @35526@ INDI
1 NAME Jeanne Marie Henriette/GEOFFRE de CHABRIGNAC/
2 GIVN Jeanne Marie Henriette
2 SURN GEOFFRE de CHABRIGNAC
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 28 OCT 2019
3 TIME 20:21
1 FAMS @35541@
1 BIRT
2 DATE 1884
2 _FNA NO
1 DEAT
2 DATE 1966
2 _FNA NO
0 @35574@ INDI
1 NAME Eugène Gaston/de LA CELLE/
2 GIVN Eugène Gaston
2 SURN de LA CELLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 OCT 2019
3 TIME 18:24
1 FAMS @35598@
1 BIRT
2 DATE 1838
2 _FNA NO
1 DEAT
2 DATE 1914
2 _FNA NO
0 @35590@ INDI
1 NAME Céline Jeanne Isabelle/LORIN de REURE/
2 GIVN Céline Jeanne Isabelle
2 SURN LORIN de REURE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 OCT 2019
3 TIME 18:30
1 FAMS @35598@
1 BIRT
2 DATE 1851
2 _FNA NO
1 DEAT
2 DATE 1934
2 _FNA NO
0 @35604@ INDI
1 NAME Albéric/de LA CELLE/
2 GIVN Albéric
2 SURN de LA CELLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 29 OCT 2019
3 TIME 18:24
1 BIRT
2 DATE 1880
2 _FNA NO
1 FAMC @35598@
0 @35641@ INDI
1 NAME Marc/ARSENE-HENRY/
2 GIVN Marc
2 SURN ARSENE-HENRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 NOV 2019
3 TIME 19:31
1 FAMS @35659@
1 BIRT
2 DATE 7 NOV 1896
2 _FNA NO
1 BURI
2 DATE JUN 1983
2 PLAC Marsac-sur-l'Isle,24430,Dordogne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.183330
4 LONG E0.666670
2 _FNA NO
1 DEAT
2 DATE 26 JUN 1983
2 _FNA NO
0 @35652@ INDI
1 NAME Marie-Antoinette/LAFARGUE de GRANGENEUVE/
2 GIVN Marie-Antoinette
2 SURN LAFARGUE de GRANGENEUVE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 NOV 2019
3 TIME 19:31
1 FAMS @35659@
1 BIRT
2 DATE 1897
2 _FNA NO
1 DEAT
2 DATE 1994
2 _FNA NO
0 @35663@ INDI
1 NAME Xavier "Bob"/ARSENE-HENRY/
2 GIVN Xavier "Bob"
2 SURN ARSENE-HENRY
1 SEX M
1 OCCU Architecte
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 NOV 2019
3 TIME 19:36
1 FAMS @35725@
1 BIRT
2 DATE 1919
2 _FNA NO
1 DEAT
2 DATE 2009
2 _FNA NO
1 FAMC @35659@
0 @35679@ INDI
1 NAME Michel/ARSENE-HENRY/
2 GIVN Michel
2 SURN ARSENE-HENRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 NOV 2019
3 TIME 19:24
1 BIRT
2 DATE 1920
2 _FNA NO
1 FAMC @35659@
0 @35686@ INDI
1 NAME Élisabeth/ARSENE-HENRY/
2 GIVN Élisabeth
2 SURN ARSENE-HENRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 NOV 2019
3 TIME 19:32
1 FAMS @35721@
1 BIRT
2 DATE 1926
2 _FNA NO
1 FAMC @35659@
0 @35693@ INDI
1 NAME Thérèse/ARSENE-HENRY/
2 GIVN Thérèse
2 SURN ARSENE-HENRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 NOV 2019
3 TIME 19:24
1 FAMC @35659@
0 @35699@ INDI
1 NAME Chantal/ARSENE-HENRY/
2 GIVN Chantal
2 SURN ARSENE-HENRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 NOV 2019
3 TIME 19:28
1 FAMC @11414@
0 @35719@ INDI
1 NAME Jacques/CHABOT/
2 GIVN Jacques
2 SURN CHABOT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 NOV 2019
3 TIME 19:32
1 FAMS @35721@
0 @35723@ INDI
1 NAME Frédérique/CHABRIAT/
2 GIVN Frédérique
2 SURN CHABRIAT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 NOV 2019
3 TIME 19:36
1 FAMS @35725@
0 @35726@ INDI
1 NAME Luc/ARSENE-HENRY/
2 GIVN Luc
2 SURN ARSENE-HENRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 NOV 2019
3 TIME 19:35
1 FAMC @35725@
0 @35732@ INDI
1 NAME Tristan/ARSENE-HENRY/
2 GIVN Tristan
2 SURN ARSENE-HENRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 NOV 2019
3 TIME 19:35
1 FAMC @35725@
0 @35738@ INDI
1 NAME Xx1/ARSENE-HENRY/
2 GIVN Xx1
2 SURN ARSENE-HENRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 1 NOV 2019
3 TIME 19:36
1 FAMC @35725@
0 @35772@ INDI
1 NAME Jacquelin Jean Marie Robert/de MAILLÉ de la TOUR LANDRY/
2 GIVN Jacquelin Jean Marie Robert
2 SURN de MAILLÉ de la TOUR LANDRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2019
3 TIME 10:02
1 FAMC @17585@
0 @35786@ INDI
1 NAME Gilles Marie Armand/de MAILLÉ de la TOUR LANDRY/
2 GIVN Gilles Marie Armand
2 SURN de MAILLÉ de la TOUR LANDRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2019
3 TIME 10:02
1 FAMC @17585@
0 @35801@ INDI
1 NAME Jeanne Marie Solange Charlotte Consuelo/de MAILLÉ de la TOUR LANDRY/
2 GIVN Jeanne Marie Solange Charlotte Consuelo
2 SURN de MAILLÉ de la TOUR LANDRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2019
3 TIME 10:09
1 FAMC @17585@
0 @35820@ INDI
1 NAME Jeanne/de MAILLÉ de la TOUR LANDRY/
2 GIVN Jeanne
2 SURN de MAILLÉ de la TOUR LANDRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2019
3 TIME 10:10
1 FAMC @17585@
0 @35828@ INDI
1 NAME Roland/de MAILLÉ de la TOUR LANDRY/
2 GIVN Roland
2 SURN de MAILLÉ de la TOUR LANDRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2019
3 TIME 10:11
1 FAMC @17585@
0 @35836@ INDI
1 NAME Robert/de MAILLÉ de la TOUR LANDRY/
2 GIVN Robert
2 SURN de MAILLÉ de la TOUR LANDRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2019
3 TIME 10:11
1 FAMC @17585@
0 @35844@ INDI
1 NAME Carmen/de MAILLÉ de la TOUR LANDRY/
2 GIVN Carmen
2 SURN de MAILLÉ de la TOUR LANDRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2019
3 TIME 10:11
1 FAMC @17585@
0 @35857@ INDI
1 NAME Charles/de WENDEL/
2 GIVN Charles
2 SURN de WENDEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2019
3 TIME 10:14
1 FAMC @15432@
0 @35873@ INDI
1 NAME Louis Antoine Melchior/de VOGÜÉ/
2 GIVN Louis Antoine Melchior
2 SURN de VOGÜÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2019
3 TIME 10:26
1 FAMS @35888@
0 @35884@ INDI
1 NAME Louise Marie Charlotte/d'ARENBERG/
2 GIVN Louise Marie Charlotte
2 SURN d'ARENBERG
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 4 NOV 2019
3 TIME 10:26
1 FAMS @35888@
0 @36143@ INDI
1 NAME Robert Philippe Louis Eugène Ferdinand/d'ORLEANS/
2 GIVN Robert Philippe Louis Eugène Ferdinand
2 SURN d'ORLEANS
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 NOV 2019
3 TIME 12:09
1 FAMS @36168@
0 @36162@ INDI
1 NAME Françoise Marie Amélie/d'ORLEANS/
2 GIVN Françoise Marie Amélie
2 SURN d'ORLEANS
1 SEX F
1 TITL Duchesse de Chartres
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 17 NOV 2019
3 TIME 12:10
1 FAMS @36168@
1 BIRT
2 DATE 14 AUG 1844
2 PLAC Neuilly-sur-Seine,92200,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883330
4 LONG E2.266670
2 _FNA NO
1 DEAT
2 DATE 28 OCT 1863
2 PLAC Vineuil-Saint-Firmin,60500,Oise,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.200240
4 LONG E2.495670
2 _FNA NO
0 @36190@ INDI
1 NAME Marie/MICHON de VOUGY/
2 GIVN Marie
2 SURN MICHON de VOUGY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 NOV 2019
3 TIME 7:51
1 FAMS @36210@
0 @36202@ INDI
1 NAME Marie Joseph Jean Aymar/de LA TOUR du PIN CHAMBLY de la CHARCE/
2 GIVN Marie Joseph Jean Aymar
2 SURN de LA TOUR du PIN CHAMBLY de la CHARCE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 18 NOV 2019
3 TIME 7:51
1 FAMS @36210@
0 @36257@ INDI
1 NAME Célestin Louis/FAUVILLE/
2 GIVN Célestin Louis
2 SURN FAUVILLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 NOV 2019
3 TIME 12:05
1 FAMS @36273@
1 DEAT
2 DATE 5 MAY 1891
2 PLAC Neuville-sur-Escaut,59293,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.300000
4 LONG E3.350000
2 _FNA NO
0 @36267@ INDI
1 NAME Léontine/KINGÔ/
2 GIVN Léontine
2 SURN KINGÔ
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 NOV 2019
3 TIME 12:07
1 FAMS @36273@
1 BIRT
2 DATE 1837
2 _FNA NO
2 NOTE 61 ANS EN 1898
0 @36277@ INDI
1 NAME Gustave Louis Henri/de BAYSER/
2 GIVN Gustave Louis Henri
2 SURN de BAYSER
1 SEX M
1 OCCU négociant
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 NOV 2019
3 TIME 12:01
1 FAMS @36296@
1 BIRT
2 DATE 1842
2 _FNA NO
0 @36290@ INDI
1 NAME Marie Amélie/FÉRON/
2 GIVN Marie Amélie
2 SURN FÉRON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 NOV 2019
3 TIME 12:02
1 FAMS @36296@
1 BIRT
2 DATE 1846
2 _FNA NO
1 NOTE ou PERON?
0 @36337@ INDI
1 NAME Louis Auguste Silvain Isidore/CORTE/
2 GIVN Louis Auguste Silvain Isidore
2 SURN CORTE
1 SEX M
1 OCCU Médecin
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 NOV 2019
3 TIME 15:16
1 BIRT
2 DATE CAL 1849
2 _FNA NO
0 @36354@ INDI
1 NAME Ernest Jules/PELLERIN/
2 GIVN Ernest Jules
2 SURN PELLERIN
1 SEX M
1 OCCU rentier
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 24 NOV 2019
3 TIME 15:19
1 BIRT
2 DATE CAL 1833
2 _FNA NO
0 @36386@ INDI
1 NAME Étienne/BELHOMME/
2 GIVN Étienne
2 SURN BELHOMME
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 NOV 2019
3 TIME 7:32
1 FAMS @36401@
1 BIRT
2 DATE 17 OCT 1690
2 PLAC Aulnay-sur-Mauldre,78126,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.929500
4 LONG E1.841130
2 _FNA NO
1 DEAT
2 DATE 1749
2 _FNA NO
1 FAMC @36441@
0 @36395@ INDI
1 NAME Catherine Michel/VASSEUR/
2 GIVN Catherine Michel
2 SURN VASSEUR
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 NOV 2019
3 TIME 7:34
1 FAMS @36401@
1 BIRT
2 DATE 1 JUL 1698
2 PLAC Crespières,78121,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883170
4 LONG E1.921510
2 _FNA NO
1 DEAT
2 DATE 1754
2 _FNA NO
1 FAMC @36458@
0 @36407@ INDI
1 NAME Michel/CORIÇON/
2 GIVN Michel
2 SURN CORIÇON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 NOV 2019
3 TIME 7:23
1 FAMS @36420@
1 BIRT
2 DATE 1710
2 _FNA NO
1 DEAT
2 DATE 1765
2 _FNA NO
0 @36416@ INDI
1 NAME /PLISSON/
2 GIVN
2 SURN PLISSON
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 NOV 2019
3 TIME 7:23
1 FAMS @36420@
1 DEAT
2 DATE 1765
2 _FNA NO
0 @36428@ INDI
1 NAME Pierre/BELHOMME/
2 GIVN Pierre
2 SURN BELHOMME
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 NOV 2019
3 TIME 7:39
1 FAMS @36441@
1 BIRT
2 DATE 9 FEB 1667
2 PLAC Aulnay-sur-Mauldre,78126,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.929500
4 LONG E1.841130
2 _FNA NO
1 DEAT
2 DATE 30 MAR 1754
2 PLAC Aulnay-sur-Mauldre,78126,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.929500
4 LONG E1.841130
2 _FNA NO
1 FAMC @36478@
0 @36436@ INDI
1 NAME Elisabeth/SOUHARD/
2 GIVN Elisabeth
2 SURN SOUHARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 NOV 2019
3 TIME 8:37
1 FAMS @36441@
1 BIRT
2 DATE 1663
2 _FNA NO
1 FAMC @36496@
0 @36445@ INDI
1 NAME Charles/VASSEUR/
2 GIVN Charles
2 SURN VASSEUR
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 NOV 2019
3 TIME 7:34
1 FAMS @36458@
1 BIRT
2 DATE 1654
2 _FNA NO
1 DEAT
2 DATE 1721
2 _FNA NO
0 @36454@ INDI
1 NAME Marguerite/MARTIN/
2 GIVN Marguerite
2 SURN MARTIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 NOV 2019
3 TIME 7:34
1 FAMS @36458@
1 BIRT
2 DATE 1663
2 _FNA NO
0 @36465@ INDI
1 NAME Robert/BELHOMME/
2 GIVN Robert
2 SURN BELHOMME
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 NOV 2019
3 TIME 8:42
1 FAMS @36478@
1 BIRT
2 DATE 1619
2 PLAC Aulnay-sur-Mauldre,78126,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.929500
4 LONG E1.841130
2 _FNA NO
1 DEAT
2 DATE 10 DEC 1669
2 PLAC Aulnay-sur-Mauldre,78126,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.929500
4 LONG E1.841130
2 _FNA NO
1 FAMC @36513@
0 @36473@ INDI
1 NAME Françoise/JUHEL/
2 GIVN Françoise
2 SURN JUHEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 NOV 2019
3 TIME 7:40
1 FAMS @36478@
1 BIRT
2 DATE 21 SEP 1639
2 PLAC Aulnay-sur-Mauldre,78126,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.929500
4 LONG E1.841130
2 _FNA NO
0 @36483@ INDI
1 NAME Pierre/SOUHARD/
2 GIVN Pierre
2 SURN SOUHARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 NOV 2019
3 TIME 8:37
1 FAMS @36496@
1 BIRT
2 DATE 1614
2 _FNA NO
1 DEAT
2 DATE 1677
2 _FNA NO
0 @36491@ INDI
1 NAME Elisabeth/LAMY/
2 GIVN Elisabeth
2 SURN LAMY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 NOV 2019
3 TIME 8:37
1 FAMS @36496@
1 BIRT
2 DATE 1622
2 _FNA NO
1 DEAT
2 DATE 1691
2 _FNA NO
0 @36500@ INDI
1 NAME Pierre/BELHOMME/
2 GIVN Pierre
2 SURN BELHOMME
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 NOV 2019
3 TIME 8:45
1 FAMS @36513@
1 DEAT
2 DATE BEF 1643
2 _FNA NO
0 @36508@ INDI
1 NAME Marie/DUPUY/
2 GIVN Marie
2 SURN DUPUY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 NOV 2019
3 TIME 8:47
1 FAMS @36513@
1 BIRT
2 DATE 1596
2 _FNA NO
1 DEAT
2 DATE 30 OCT 1662
2 PLAC Aulnay-sur-Mauldre,78126,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.929500
4 LONG E1.841130
2 _FNA NO
1 FAMC @36533@
0 @36519@ INDI
1 NAME Scipion/DUPUY/
2 GIVN Scipion
2 SURN DUPUY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 NOV 2019
3 TIME 8:49
1 FAMS @36533@
1 DEAT
2 DATE 24 FEB 1632
2 PLAC Maule,78580,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.910560
4 LONG E1.852640
2 _FNA NO
0 @36528@ INDI
1 NAME Marie/GROULT/
2 GIVN Marie
2 SURN GROULT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 NOV 2019
3 TIME 8:49
1 FAMS @36533@
1 DEAT
2 DATE 22 DEC 1645
2 PLAC Maule,78580,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.910560
4 LONG E1.852640
2 _FNA NO
0 @36557@ INDI
1 NAME Louis Gautier/BOURELIER/
2 GIVN Louis Gautier
2 SURN BOURELIER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 25 NOV 2019
3 TIME 12:02
1 BIRT
2 DATE CAL 1776
2 _FNA NO
0 @36571@ INDI
1 NAME Madeleine/de NICOLAY/
2 GIVN Madeleine
2 SURN de NICOLAY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2019
3 TIME 6:51
1 FAMS @36574@
0 @36584@ INDI
1 NAME Jean/MASSON/
2 GIVN Jean
2 SURN MASSON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2019
3 TIME 8:42
0 @36588@ INDI
1 NAME Charles/MURAT/
2 GIVN Charles
2 SURN MURAT
1 SEX M
1 OCCU COLONEL
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2019
3 TIME 8:43
0 @36597@ INDI
1 NAME Isabelle/MURAT/
2 GIVN Isabelle
2 SURN MURAT
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2019
3 TIME 8:45
0 @36606@ INDI
1 NAME Anders/LARSON/
2 GIVN Anders
2 SURN LARSON
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 2 DEC 2019
3 TIME 8:52
0 @36629@ INDI
1 NAME Jean-Baptiste Prosper/LEDUC/
2 GIVN Jean-Baptiste Prosper
2 SURN LEDUC
1 SEX M
1 OCCU Capitaine
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 12:47
1 FAMS @36995@
1 BIRT
2 DATE CAL 1849
2 _FNA NO
0 @36653@ INDI
1 NAME Colombe/de MITRY/
2 GIVN Colombe
2 SURN de MITRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 7 DEC 2019
3 TIME 17:51
1 FAMC @17758@
0 @36661@ INDI
1 NAME Pierre/de MITRY/
2 GIVN Pierre
2 SURN de MITRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 7 DEC 2019
3 TIME 17:52
1 FAMC @17758@
0 @36669@ INDI
1 NAME Simon/de MITRY/
2 GIVN Simon
2 SURN de MITRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 7 DEC 2019
3 TIME 17:52
1 BIRT
2 DATE 17 SEP 2019
2 _FNA NO
1 FAMC @17758@
0 @36700@ INDI
1 NAME Louis Marie Édouard Amalric/LOMBARD de BUFFIERES de RAMBUTEAU/
2 GIVN Louis Marie Édouard Amalric
2 SURN LOMBARD de BUFFIERES de RAMBUTEAU
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 DEC 2019
3 TIME 17:36
1 FAMS @36720@
0 @36714@ INDI
1 NAME Marie Marguerite/POURROY de L'AUBERIVIERE de QUINSONAS/
2 GIVN Marie Marguerite
2 SURN POURROY de L'AUBERIVIERE de QUINSONAS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 DEC 2019
3 TIME 17:36
1 FAMS @36720@
0 @36726@ INDI
1 NAME Julien Cyrille Joseph/FERLIÉ/
2 GIVN Julien Cyrille Joseph
2 SURN FERLIÉ
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 DEC 2019
3 TIME 17:41
1 FAMS @36746@
0 @36738@ INDI
1 NAME Marie Joseph Émilie Clémence/MARÉCHAL/
2 GIVN Marie Joseph Émilie Clémence
2 SURN MARÉCHAL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 DEC 2019
3 TIME 17:41
1 FAMS @36746@
0 @36759@ INDI
1 NAME Pierre/BERTIN/
2 GIVN Pierre
2 SURN BERTIN
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 DEC 2019
3 TIME 21:36
1 FAMS @36772@
1 DEAT
2 DATE 1810
2 _FNA NO
0 @36768@ INDI
1 NAME Marie-Françoise/N/
2 GIVN Marie-Françoise
2 SURN N
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 8 DEC 2019
3 TIME 21:36
1 FAMS @36772@
0 @36781@ INDI
1 NAME Eléonore/BROUILLAUD/
2 GIVN Eléonore
2 SURN BROUILLAUD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 DEC 2019
3 TIME 15:01
1 FAMC @11371@
0 @36789@ INDI
1 NAME Iris/BROUILLAUD/
2 GIVN Iris
2 SURN BROUILLAUD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 11 DEC 2019
3 TIME 14:59
1 FAMC @11371@
0 @36806@ INDI
1 NAME Paul/DUBUC/
2 GIVN Paul
2 SURN DUBUC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 DEC 2019
3 TIME 19:17
1 FAMS @36809@
0 @36813@ INDI
1 NAME Camille/AMBROSELLI DUBUC/
2 GIVN Camille
2 SURN AMBROSELLI DUBUC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 14 DEC 2019
3 TIME 19:18
1 BIRT
2 DATE 10 DEC 2019
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @36809@
0 @36849@ INDI
1 NAME Pierre/BONNARD/
2 GIVN Pierre
2 SURN BONNARD
1 SEX M
1 OCCU Notaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 8:20
1 FAMS @36862@
1 BIRT
2 DATE 3 MAR 1813
2 PLAC Paris 10,,,,,
2 _FNA NO
0 @36857@ INDI
1 NAME Marie-Amélie/CHOPPIN/
2 GIVN Marie-Amélie
2 SURN CHOPPIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 8:20
1 FAMS @36862@
1 BIRT
2 DATE 8 JAN 1828
2 PLAC Chartres,28000,Eure-et-Loir,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N48.446850
4 LONG E1.489250
2 _FNA NO
1 DEAT
2 DATE 7 JUN 1886
2 PLAC Chartres,28000,Eure-et-Loir,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N48.446850
4 LONG E1.489250
2 _FNA NO
0 @36868@ INDI
1 NAME Pierre/BONNARD/
2 GIVN Pierre
2 SURN BONNARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 8:14
1 BIRT
2 DATE 1858
2 _FNA NO
1 DEAT
2 DATE 1860
2 _FNA NO
1 FAMC @33060@
0 @36876@ INDI
1 NAME Amélie Suzanne/BONNARD/
2 GIVN Amélie Suzanne
2 SURN BONNARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 8:15
1 BIRT
2 DATE 1888
2 _FNA NO
1 DEAT
2 DATE 1894
2 _FNA NO
1 FAMC @33060@
0 @36890@ INDI
1 NAME Paul/BONNARD/
2 GIVN Paul
2 SURN BONNARD
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 8:19
1 BIRT
2 DATE 1848
2 _FNA NO
1 DEAT
2 DATE 1849
2 _FNA NO
1 FAMC @36862@
0 @36898@ INDI
1 NAME Angélique Anne Marie/BONNARD/
2 GIVN Angélique Anne Marie
2 SURN BONNARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 8:22
1 FAMS @36913@
1 BIRT
2 DATE 1851
2 _FNA NO
1 DEAT
2 DATE 1890
2 _FNA NO
1 FAMC @36862@
0 @36910@ INDI
1 NAME André Arthur/VEDIE/
2 GIVN André Arthur
2 SURN VEDIE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 8:22
1 FAMS @36913@
1 BIRT
2 DATE 1844
2 _FNA NO
1 DEAT
2 DATE 1922
2 _FNA NO
0 @36931@ INDI
1 NAME Charles Jean/LEROYER/
2 GIVN Charles Jean
2 SURN LEROYER
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 11:26
1 FAMS @36947@
1 DEAT
2 PLAC Carrouges,61320,Orne,Normandie,FRANCE,
3 MAP
4 LATI N48.567420
4 LONG W0.145640
2 _FNA NO
0 @36942@ INDI
1 NAME Jeanne Marguerite/LEROYER/
2 GIVN Jeanne Marguerite
2 SURN LEROYER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 11:26
1 FAMS @36947@
1 DEAT
2 PLAC Carrouges,61320,Orne,Normandie,FRANCE,
3 MAP
4 LATI N48.567420
4 LONG W0.145640
2 _FNA NO
0 @36981@ INDI
1 NAME Xx1/COBY/
2 GIVN Xx1
2 SURN COBY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 12:47
1 FAMS @36995@
1 FAMC @23177@
0 @37013@ INDI
1 NAME Eugène Marie/de BEAUREPAIRE/
2 GIVN Eugène Marie
2 SURN de BEAUREPAIRE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 15:38
1 FAMS @37028@
0 @37023@ INDI
1 NAME Juliette/CORNUAU/
2 GIVN Juliette
2 SURN CORNUAU
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 15:38
1 FAMS @37028@
0 @37032@ INDI
1 NAME Jean/de L'ESPEE/
2 GIVN Jean
2 SURN de L'ESPEE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 15:40
1 FAMC @17849@
0 @37040@ INDI
1 NAME Roland/de L'ESPEE/
2 GIVN Roland
2 SURN de L'ESPEE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 15:40
1 FAMC @17849@
0 @37048@ INDI
1 NAME Anne Marie Timoleon François/de COSSÉ BRISSAC/
2 GIVN Anne Marie Timoleon François
2 SURN de COSSÉ BRISSAC
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 15:41
1 FAMS @37051@
0 @37089@ INDI
1 NAME Jeanne Marie Madeleine/POURQUIER/
2 GIVN Jeanne Marie Madeleine
2 SURN POURQUIER
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 15 DEC 2019
3 TIME 19:35
1 FAMS @37096@
0 @37115@ INDI
1 NAME Jean Hugues Henri/DARCY/
2 GIVN Jean Hugues Henri
2 SURN DARCY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 12:06
1 FAMS @37132@
1 BIRT
2 DATE 7 AUG 1868
2 PLAC Versailles,78000,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.800000
4 LONG E2.133330
2 _FNA NO
1 DEAT
2 DATE 27 APR 1906
2 PLAC Versailles,78000,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.800000
4 LONG E2.133330
2 _FNA NO
1 FAMC @37219@
0 @37127@ INDI
1 NAME Madeleine Geneviève Jeanne Marie/FRANQUET de FRANQUEVILLE/
2 GIVN Madeleine Geneviève Jeanne Marie
2 SURN FRANQUET de FRANQUEVILLE
2 NICK MIMINE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 14:31
1 FAMS @37132@
1 BIRT
2 DATE 22 DEC 1871
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 20 APR 1957
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 FAMC @37241@
0 @37138@ INDI
1 NAME Henri/DARCY/
2 GIVN Henri
2 SURN DARCY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 8:42
1 BIRT
2 DATE 1895
2 _FNA NO
1 DEAT
2 DATE 1916
2 _FNA NO
1 FAMC @37132@
0 @37148@ INDI
1 NAME Gérard/DARCY/
2 GIVN Gérard
2 SURN DARCY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 8:42
1 BIRT
2 DATE 1898
2 _FNA NO
1 DEAT
2 DATE 1918
2 _FNA NO
1 FAMC @37132@
0 @37158@ INDI
1 NAME François/DARCY/
2 GIVN François
2 SURN DARCY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 11:54
1 FAMS @37415@
1 BIRT
2 DATE 1900
2 _FNA NO
1 DEAT
2 DATE 1929
2 _FNA NO
1 FAMC @37132@
0 @37168@ INDI
1 NAME Jeanne/DARCY/
2 GIVN Jeanne
2 SURN DARCY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 11:56
1 FAMS @37423@
1 BIRT
2 DATE 1906
2 _FNA NO
1 DEAT
2 DATE 1977
2 _FNA NO
1 FAMC @37132@
0 @37178@ INDI
1 NAME Robert Camille Hugues Maurice/DARCY/
2 GIVN Robert Camille Hugues Maurice
2 SURN DARCY
1 SEX M
1 OCCU Militaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 14:31
1 FAMS @37191@
1 BIRT
2 DATE 10 FEB 1904
2 PLAC Paris 16,,,,,
2 _FNA NO
1 BURI
2 DATE 1983
2 PLAC Époisses,21460,Côte-d'Or,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.506660
4 LONG E4.174280
2 _FNA NO
1 DEAT
2 DATE 17 FEB 1983
2 PLAC Bourbilly￼ ￼ ,,,,,
3 MAP
4 LATI N47.458041
4 LONG E4.245529
2 _FNA NO
2 NOTE château de Bourbilly - Epoisses,Côte d'Or,France
1 FAMC @37132@
0 @37188@ INDI
1 NAME Bernadette/VINOCH d'HESPEL/
2 GIVN Bernadette
2 SURN VINOCH d'HESPEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 12:21
1 FAMS @37191@
1 BIRT
2 DATE 4 JAN 1909
2 PLAC Fournes-en-Weppes,59134,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.584970
4 LONG E2.887930
2 _FNA NO
1 DEAT
2 DATE 1995
2 _FNA NO
0 @37196@ INDI
1 NAME Chantal/DARCY/
2 GIVN Chantal
2 SURN DARCY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 8:47
1 FAMC @37191@
0 @37205@ INDI
1 NAME Henri/DARCY/
2 GIVN Henri
2 SURN DARCY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 12:27
1 FAMS @37219@
1 BIRT
2 DATE 21 OCT 1840
2 PLAC Troyes,10000,Aube,Grand Est,FRANCE,
3 MAP
4 LATI N48.300000
4 LONG E4.083330
2 _FNA NO
1 DEAT
2 DATE 25 DEC 1926
2 PLAC Sèvres,92310,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.822920
4 LONG E2.217570
2 _FNA NO
1 FAMC @37509@
0 @37215@ INDI
1 NAME Adèle Élisabeth/HÉLY d'OISSEL/
2 GIVN Adèle Élisabeth
2 SURN HÉLY d'OISSEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 11:58
1 FAMS @37219@
1 BIRT
2 DATE 28 SEP 1843
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 13 DEC 1925
2 PLAC Sèvres,92310,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.822920
4 LONG E2.217570
2 _FNA NO
2 NOTE chateau de Brimborion
1 FAMC @37267@
0 @37224@ INDI
1 NAME Amable Charles/FRANQUET de FRANQUEVILLE/
2 GIVN Amable Charles
2 SURN FRANQUET de FRANQUEVILLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 14:35
1 FAMS @37241@
1 BIRT
2 DATE 1840
2 _FNA NO
1 DEAT
2 DATE 1919
2 _FNA NO
1 FAMC @37612@
0 @37234@ INDI
1 NAME Marie Sophie Mathilde Eugénie/SCHAEFFER-ERARD/
2 GIVN Marie Sophie Mathilde Eugénie
2 SURN SCHAEFFER-ERARD
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 12:11
1 FAMS @37241@
1 BIRT
2 DATE 12 AUG 1844
2 PLAC Strasbourg,67000,Bas-Rhin,Grand Est,FRANCE,
3 MAP
4 LATI N48.583420
4 LONG E7.742960
2 _FNA NO
1 DEAT
2 DATE 29 JAN 1900
2 PLAC Paris 16,,,,,
2 _FNA NO
0 @37249@ INDI
1 NAME Antoine Pierre/HÉLY d'OISSEL/
2 GIVN Antoine Pierre
2 SURN HÉLY d'OISSEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 9:09
1 FAMS @37267@
1 BIRT
2 DATE 1806
2 _FNA NO
1 DEAT
2 DATE 1868
2 _FNA NO
1 FAMC @37295@
0 @37260@ INDI
1 NAME Sophie Mélanie/FIRINO/
2 GIVN Sophie Mélanie
2 SURN FIRINO
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 9:09
1 FAMS @37267@
1 BIRT
2 DATE 1816
2 _FNA NO
1 DEAT
2 DATE 1874
2 _FNA NO
1 FAMC @37316@
0 @37276@ INDI
1 NAME Abdon Patrocle Frédéric/HÉLY d'OISSEL/
2 GIVN Abdon Patrocle Frédéric
2 SURN HÉLY d'OISSEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 9:16
1 FAMS @37295@
1 BIRT
2 DATE 2 APR 1777
2 PLAC Rouen,76000,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.443130
4 LONG E1.099320
2 _FNA NO
1 DEAT
2 DATE 29 JAN 1833
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 NOTE Conseiller d'Etat en 1803, Baron d'empire, VP de Saint-Gobin
0 @37291@ INDI
1 NAME Adélaïde/BROCHANT de VILLIERS/
2 GIVN Adélaïde
2 SURN BROCHANT de VILLIERS
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 9:28
1 FAMS @37295@
1 BIRT
2 DATE 1783
2 _FNA NO
1 DEAT
2 DATE 17 MAY 1853
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @37299@ INDI
1 NAME Jean-Baptiste André/FIRINO/
2 GIVN Jean-Baptiste André
2 SURN FIRINO
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 9:04
1 FAMS @37316@
1 BIRT
2 DATE 1779
2 _FNA NO
1 DEAT
2 DATE 1868
2 _FNA NO
0 @37310@ INDI
1 NAME Sophie Caroline/ROGUIN/
2 GIVN Sophie Caroline
2 SURN ROGUIN
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 9:04
1 FAMS @37316@
1 BIRT
2 DATE 1796
2 _FNA NO
1 DEAT
2 DATE 1865
2 _FNA NO
0 @37322@ INDI
1 NAME Jeanne/DARCY/
2 GIVN Jeanne
2 SURN DARCY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 9:05
1 BIRT
2 DATE 1866
2 _FNA NO
1 DEAT
2 DATE 1944
2 _FNA NO
1 FAMC @37219@
0 @37331@ INDI
1 NAME Pierre/DARCY/
2 GIVN Pierre
2 SURN DARCY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 9:06
1 BIRT
2 DATE 1870
2 _FNA NO
1 DEAT
2 DATE 1918
2 _FNA NO
1 FAMC @37219@
0 @37340@ INDI
1 NAME Valentine/DARCY/
2 GIVN Valentine
2 SURN DARCY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 9:06
1 BIRT
2 DATE 1872
2 _FNA NO
1 DEAT
2 DATE 1958
2 _FNA NO
1 FAMC @37219@
0 @37349@ INDI
1 NAME Paul/DARCY/
2 GIVN Paul
2 SURN DARCY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 9:07
1 BIRT
2 DATE 1879
2 _FNA NO
1 DEAT
2 DATE 1970
2 _FNA NO
1 FAMC @37219@
0 @37358@ INDI
1 NAME Paul Frédéric/HÉLY d'OISSEL/
2 GIVN Paul Frédéric
2 SURN HÉLY d'OISSEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 9:08
1 BIRT
2 DATE 1840
2 _FNA NO
1 DEAT
2 DATE 1898
2 _FNA NO
1 FAMC @37267@
0 @37370@ INDI
1 NAME Étienne Emile/HÉLY d'OISSEL/
2 GIVN Étienne Emile
2 SURN HÉLY d'OISSEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 9:09
1 BIRT
2 DATE 1844
2 _FNA NO
1 DEAT
2 DATE 1915
2 _FNA NO
1 FAMC @37267@
0 @37384@ INDI
1 NAME Frédéric Victor/HÉLY d'OISSEL/
2 GIVN Frédéric Victor
2 SURN HÉLY d'OISSEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 9:13
1 BIRT
2 DATE 1803
2 _FNA NO
1 DEAT
2 DATE 1883
2 _FNA NO
1 FAMC @37295@
0 @37396@ INDI
1 NAME Arthur/HÉLY d'OISSEL/
2 GIVN Arthur
2 SURN HÉLY d'OISSEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 9:14
1 BIRT
2 DATE 1816
2 _FNA NO
1 DEAT
2 DATE 1864
2 _FNA NO
1 FAMC @37295@
0 @37412@ INDI
1 NAME Claire/BENOIST d'AZY/
2 GIVN Claire
2 SURN BENOIST d'AZY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 11:54
1 FAMS @37415@
1 BIRT
2 DATE 1900
2 _FNA NO
1 DEAT
2 DATE 1956
2 _FNA NO
0 @37420@ INDI
1 NAME Guy/de SCHOUTHEETE de TERVARENT/
2 GIVN Guy
2 SURN de SCHOUTHEETE de TERVARENT
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 11:56
1 FAMS @37423@
1 BIRT
2 DATE 1891
2 _FNA NO
0 @37447@ INDI
1 NAME Sabine/FRANQUET de FRANQUEVILLE/
2 GIVN Sabine
2 SURN FRANQUET de FRANQUEVILLE
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 12:15
1 FAMS @37463@
1 BIRT
2 DATE 15 AUG 1877
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 DEAT
2 DATE 16 DEC 1941
2 PLAC Boussu,7300,Hainaut,Wallonie,BELGIQUE,
3 MAP
4 LATI N50.434170
4 LONG E3.794400
2 _FNA NO
1 FAMC @37241@
0 @37460@ INDI
1 NAME Robert/d'URSEL/
2 GIVN Robert
2 SURN d'URSEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 12:15
1 FAMS @37463@
1 BIRT
2 DATE 1873
2 _FNA NO
1 DEAT
2 DATE 1955
2 _FNA NO
0 @37468@ INDI
1 NAME Henri Charles François/d'URSEL/
2 GIVN Henri Charles François
2 SURN d'URSEL
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 12:15
1 BIRT
2 DATE 1900
2 _FNA NO
1 DEAT
2 DATE 1974
2 _FNA NO
1 FAMC @37463@
0 @37480@ INDI
1 NAME Hedwige/d'URSEL/
2 GIVN Hedwige
2 SURN d'URSEL
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 12:15
1 BIRT
2 DATE 1902
2 _FNA NO
1 DEAT
2 DATE 1987
2 _FNA NO
1 FAMC @37463@
0 @37496@ INDI
1 NAME Hugues Iena/DARCY/
2 GIVN Hugues Iena
2 SURN DARCY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 12:31
1 FAMS @37509@
1 BIRT
2 DATE 29 OCT 1807
2 PLAC Arcis-sur-Aube,10700,Aube,Grand Est,FRANCE,
3 MAP
4 LATI N48.533870
4 LONG E4.140850
2 _FNA NO
1 DEAT
2 DATE 4 JUN 1880
2 PLAC Corcelles-les-Monts,21160,Côte-d'Or,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.294450
4 LONG E4.939110
2 _FNA NO
1 NOTE Sous-préfet de l'arrondissement de Sens, préfet (Tarn et Garonne fév. oct. 1839) Aube (1839 - 1843) Gard (1843 - 1848) Rhône (1849) Moselle (1849)
2 CONT sous-secrétaire d’État au ministère de l’Intérieur
1 FAMC @37539@
0 @37504@ INDI
1 NAME Eugénie/VUITRY/
2 GIVN Eugénie
2 SURN VUITRY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 12:36
1 FAMS @37509@
1 BIRT
2 DATE 1815
2 _FNA NO
1 DEAT
2 DATE 1906
2 _FNA NO
1 FAMC @37572@
0 @37518@ INDI
1 NAME Joseph François Jacques Lazare/DARCY/
2 GIVN Joseph François Jacques Lazare
2 SURN DARCY
1 SEX M
1 OCCU Collecteur d'impots
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 13:53
1 FAMS @37539@
1 BIRT
2 DATE 8 AUG 1774
2 PLAC Mirebeau-sur-Bèze,21310,Côte-d'Or,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.398280
4 LONG E5.320930
2 _FNA NO
1 DEAT
2 DATE 22 MAY 1817
2 PLAC Dijon,21000,Côte-d'Or,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.316670
4 LONG E5.016670
2 _FNA NO
0 @37533@ INDI
1 NAME Agathe Angélique/SERDET/
2 GIVN Agathe Angélique
2 SURN SERDET
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 13:51
1 FAMS @37539@
0 @37547@ INDI
1 NAME Henry/DARCY/
2 GIVN Henry
2 SURN DARCY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 13:50
1 FAMS @37580@
1 BIRT
2 DATE 1803
2 _FNA NO
1 DEAT
2 DATE 1858
2 _FNA NO
1 FAMC @37539@
0 @37559@ INDI
1 NAME Paul/VUITRY/
2 GIVN Paul
2 SURN VUITRY
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 12:36
1 FAMS @37572@
1 BIRT
2 DATE 1786
2 _FNA NO
1 DEAT
2 DATE 1879
2 _FNA NO
0 @37567@ INDI
1 NAME Amable Louise/HARDY/
2 GIVN Amable Louise
2 SURN HARDY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 12:36
1 FAMS @37572@
1 BIRT
2 DATE 1791
2 _FNA NO
1 DEAT
2 DATE 1817
2 _FNA NO
0 @37577@ INDI
1 NAME Henriette/CAREY/
2 GIVN Henriette
2 SURN CAREY
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 13:50
1 FAMS @37580@
0 @37597@ INDI
1 NAME Ernest/FRANQUET de FRANQUEVILLE/
2 GIVN Ernest
2 SURN FRANQUET de FRANQUEVILLE
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 14:36
1 FAMS @37612@
0 @37607@ INDI
1 NAME Cécile Belle/de CAUX/
2 GIVN Cécile Belle
2 SURN de CAUX
1 SEX F
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 14:38
1 FAMS @37612@
1 FAMC @37626@
0 @37622@ INDI
1 NAME X1/de CAUX/
2 GIVN X1
2 SURN de CAUX
1 SEX M
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 14:39
1 FAMS @37626@
1 FAMC @37636@
0 @37631@ INDI
1 NAME Antoine Joseph/de CAUX/
2 GIVN Antoine Joseph
2 SURN de CAUX
1 SEX M
1 OCCU Notaire
1 _FIL LEGITIMATE_CHILD
1 CHAN
2 DATE 21 DEC 2019
3 TIME 14:43
1 FAMS @37636@
1 NOTE notaire à PARIS
0 @81@ FAM
1 HUSB @56@
1 WIFE @73@
1 _UST MARRIED
1 CHIL @85@
1 CHIL @106@
1 CHIL @125@
1 CHIL @143@
1 CHIL @168@
1 CHIL @188@
1 NOTE RENCONTRE avec JEAN-MARIE AMBROSELLI à Saint-Pierre-en-Port   en 1960
1 MARC
2 DATE 10 JUN 1974
2 PLAC Paris 08,,,,,Maitre Michel LE MEUNIER
2 _FNA NO
2 NOTE Marié à la mairie de SAINT-PIERRE-EN-PORT (76540) le 22 Juin 1974 sous le régime de la séparation de biens pure et simple défini par les articles 1536 et suivants du Code civil aux termes du contrat de mariage reçu par Maitre Michel LE MEUNIER, not
3 CONC aire à PARIS, le 10 juin 1974.
3 CONT Ce régime matrimonial n'a pas fait l'objet de modification.
3 CONT
1 MARR
2 DATE 22 JUN 1974
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
0 @221@ FAM
1 HUSB @85@
1 WIFE @216@
1 _UST MARRIED
1 CHIL @255@
1 CHIL @267@
1 CHIL @278@
1 CHIL @289@
1 MARR
2 DATE 23 JUN 2001
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
0 @231@ FAM
1 HUSB @106@
1 WIFE @225@
1 _UST MARRIED
1 CHIL @300@
1 CHIL @311@
1 CHIL @323@
1 CHIL @336@
1 MARR
2 DATE 25 MAY 2002
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
0 @241@ FAM
1 HUSB @235@
1 WIFE @125@
1 _UST MARRIED
1 CHIL @347@
1 CHIL @357@
1 CHIL @367@
1 CHIL @377@
1 CHIL @8933@
1 CHIL @34720@
1 MARR NOTRE-DAME
2 DATE 5 JAN 2008
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @251@ FAM
1 HUSB @246@
1 WIFE @143@
1 _UST MARRIED
1 CHIL @387@
1 CHIL @7170@
1 MARR
2 DATE 31 DEC 2015
2 PLAC Marseille,13000,Bouches-du-Rhône,Provence-Alpes-Côte d'Azur,FRANCE,
3 MAP
4 LATI N43.296950
4 LONG E5.381070
2 _FNA NO
0 @405@ FAM
1 HUSB @168@
1 WIFE @399@
1 _UST MARRIED
1 CHIL @32112@
1 MARR
2 DATE 7 MAY 2016
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
2 NOTE mairie du 9°+ suivi d'un déjeuner 14 rue saint marc à Paris 02
1 MARR
2 TYPE Religious marriage
2 DATE 22 JUL 2016
2 PLAC Le Petit Andely,,,,,
2 _FNA NO
2 NOTE 17h30 pour un cocKtail suivi d'un diner
3 CONT à la Grange de Dourgoult, Marquency (eure).
0 @451@ FAM
1 HUSB @412@
1 WIFE @447@
1 _UST MARRIED
1 CHIL @687@
1 CHIL @703@
1 CHIL @715@
1 CHIL @726@
1 CHIL @742@
1 CHIL @773@
1 CHIL @800@
1 CHIL @822@
1 CHIL @845@
1 CHIL @864@
1 CHIL @883@
1 CHIL @56@
1 CHIL @902@
1 CHIL @919@
1 CHIL @938@
1 NOTE Élève en 1929 de George Desvallières,
2 CONT il deviendra son gendre en épousant sa fille,
2 CONT France Desvallières en 1932.
2 CONT
1 MARC
2 DATE 3 OCT 1932
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
2 NOTE Me RIVIERE ET VIDECOQ
1 MARR
2 DATE 6 OCT 1932
2 PLAC Seine-Port,77240,Seine-et-Marne,Île-de-France,FRANCE,
3 MAP
4 LATI N48.557430
4 LONG E2.553160
2 _FNA NO
2 NOTE contrat de mariage reçu par Mes RIVIERE et VIDECOQ notaires à PARIS le 3 octobre 1932
0 @489@ FAM
1 HUSB @467@
1 WIFE @486@
1 _UST MARRIED
1 CHIL @2279@
1 CHIL @669@
1 CHIL @412@
1 MARR
2 DATE 7 JUN 1898
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
2 NOTE Contrat de mariage (avec "Germaine" Laurence THURET) - Girardin - Paris, 75000, Paris 6/6/1898
3 CONT Girardin, Marie Gustave Albert Dates d'existence : 16 juillet 1896 - 20 mai 1931 Fonction : notaire à paris Étude : Etude notariale CVI
0 @4264@ FAM
1 HUSB @4263@
1 WIFE @486@
1 _UST MARRIED
1 CHIL @4266@
1 CHIL @4272@
1 MARR
2 DATE 25 APR 1889
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @568@ FAM
1 HUSB @547@
1 WIFE @564@
1 _UST MARRIED
1 CHIL @467@
1 CHIL @2190@
1 MARR
2 DATE 25 AUG 1868
2 PLAC Paris 09,,,,,
2 _FNA NO
0 @2178@ FAM
1 HUSB @576@
1 WIFE @2175@
1 _UST MARRIED
1 CHIL @547@
1 MARR
2 DATE 28 JAN 1832
2 PLAC Marseille,13000,Bouches-du-Rhône,Provence-Alpes-Côte d'Azur,FRANCE,
3 MAP
4 LATI N43.296950
4 LONG E5.381070
2 _FNA NO
2 NOTE 	•	Union: Marseille (13) - AD13 - TD14 (mariages 1823-1832), registre 2
0 @2233@ FAM
1 HUSB @584@
1 WIFE @2231@
1 _UST MARRIED
1 CHIL @2209@
0 @33416@ FAM
1 HUSB @584@
1 WIFE @33410@
1 _UST MARRIED
0 @682@ FAM
1 HUSB @680@
1 WIFE @669@
1 _UST MARRIED
1 CHIL @2264@
1 CHIL @2269@
1 CHIL @2274@
1 MARR saint philippe du roule
2 DATE 10 OCT 1925
2 PLAC Paris 8,,,,,
2 _FNA NO
2 NOTE témoins= Bonnard + André Cury
0 @962@ FAM
1 HUSB @703@
1 WIFE @959@
1 _UST MARRIED
1 CHIL @1241@
1 CHIL @1252@
1 CHIL @1262@
1 CHIL @1273@
1 MARC
2 DATE 30 JUL 1964
2 PLAC Bordeaux,33000,Gironde,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N44.840440
4 LONG W0.580500
2 _FNA NO
2 NOTE Marié à la mairie de UZESTE (33730) le 31 Julllet 1964 sous le régime de la communauté de biens réduite aux acquêts aux termes du contrat de mariage reçu par Maitre Jean YAIGRE, notaire à BORDEAUX, le 30 juillet 1964.
3 CONT Ce régime matrimonial n'a pas lait l'objet de modification.
1 MARR
2 DATE 31 JUL 1964
2 PLAC Uzeste,33730,Gironde,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N44.442300
4 LONG W0.325510
2 _FNA NO
1 MARR
2 TYPE Religious marriage
2 DATE 1 AUG 1964
2 _FNA NO
0 @978@ FAM
1 HUSB @715@
1 WIFE @976@
1 _UST MARRIED
1 CHIL @1358@
1 CHIL @1401@
1 CHIL @1445@
1 CHIL @1459@
1 MARR
2 DATE 16 SEP 1961
2 PLAC Seine-Port,77240,Seine-et-Marne,Île-de-France,FRANCE,
3 MAP
4 LATI N48.557430
4 LONG E2.553160
2 _FNA NO
0 @992@ FAM
1 HUSB @990@
1 WIFE @726@
1 _UST MARRIED
1 CHIL @1661@
1 CHIL @1672@
1 CHIL @1683@
1 CHIL @1693@
1 CHIL @1704@
1 CHIL @1715@
1 CHIL @1725@
1 CHIL @1736@
1 CHIL @1746@
1 CHIL @1756@
1 CHIL @1767@
1 MARR
2 DATE 16 AUG 1962
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
1 MARC
2 DATE 16 AUG 1962
2 _FNA NO
2 NOTE Mariée à la mairie de SAINT-PIERRE-EN-PORT (76540) le 16 août 1962 sous le régime de la communauté de biens meubles et acquêts à défaut de contrat de mariage préalable.
3 CONT Ce régime matrimonial n'a pas fait robjet de modification.
0 @1002@ FAM
1 HUSB @1000@
1 WIFE @742@
1 _UST MARRIED
1 CHIL @2333@
1 CHIL @2435@
1 CHIL @2347@
1 CHIL @2358@
1 CHIL @2369@
1 CHIL @2380@
1 CHIL @2391@
1 CHIL @2402@
1 CHIL @2413@
1 CHIL @2424@
1 MARC
2 DATE 4 AUG 1961
2 PLAC Valmont,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.743600
4 LONG E0.512930
2 _FNA NO
2 NOTE Marié à la mairie de SAINT-PIERRE-EN-PORT (76540) le 5 août 1961 initialement sous le régime de la séparation de biens pure et simple aux termes du contrat de manage reçu par Maitre RETOUT, nota!re à VALMONT (Seine Maritime), le 4 août 1961,
1 MARR
2 DATE 5 AUG 1961
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
1 MARC
2 DATE 25 APR 2006
2 PLAC Paris 09,,,,,
2 _FNA NO
2 NOTE sous le régime de !a communauté universelle en vertu aux termes d'un acte reçu par Maître CORPECHOT, notaire à PARIS (9eme), le 25 avril 2006, homologué suivant Jugement rendu par le tribunal de grande instance de NANTERRE (Hauts de Seine) le 27 fé
3 CONC vrier 2007.
3 CONT Ce régime malrimonlal n'a pas fait fobjet d'autre modification depuis.
3 CONT Ledit changement de régime matrimonial a été enregistré au répertoire civil sous le numéro 06/0081B, tel qu'indlqué sur l'acte de naissance de Madame Sabine BAIRD SMlTH. ainsi qu'il résulte de la copie certifiée conforme ci-annexée reçue du Tribuna
3 CONC l de Grande Instance de LIMOGES du courrier audit tribunal du 16 octobre 2006 de Maître DRAGO avocat visant l'homologation dudit changement de régime matrimonial et comportant ledit numéro au répertoire civil.
3 CONT
0 @1007@ FAM
1 HUSB @773@
1 WIFE @1005@
1 _UST MARRIED
1 CHIL @2730@
1 CHIL @2740@
1 CHIL @2750@
1 CHIL @2761@
1 CHIL @2772@
1 CHIL @2782@
1 MARR
2 DATE 12 JUL 1968
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
0 @1019@ FAM
1 HUSB @1017@
1 WIFE @822@
1 _UST MARRIED
1 CHIL @2932@
1 CHIL @2943@
1 CHIL @2954@
1 CHIL @2965@
1 CHIL @2976@
1 MARR
2 DATE 20 JUN 1970
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
1 MARC
2 DATE 20 JUN 1970
2 _FNA NO
2 NOTE initialement sous le régime de la communauté de biens réduite aux acquéts à défaut de contrat de mariage préalable
1 MARC
2 DATE 9 FEB 2011
2 PLAC Rambouillet,78120,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.650000
4 LONG E1.833330
2 _FNA NO
2 NOTE sous le régime de la communauté universelle en vertu d'un acte de changement de régime matrimonial reçu par Maftre BELLE-CROIX, notaire à RAMBOUILLET (Yvelines), le 9 février 2011, contre laquel aucune opposition n'a été formulée, ainsi qu'il résul
3 CONC ta de l'acte de non  opposition établi par Maitre Bemerd BELLE-CROIX, le 9 décembre 2011.
3 CONT Ce régime matrimonial n'a pas fait l'objet de modification
0 @1028@ FAM
1 HUSB @1026@
1 WIFE @845@
1 _UST MARRIED
1 CHIL @3103@
1 CHIL @3113@
1 CHIL @3123@
1 CHIL @3132@
1 CHIL @3142@
1 CHIL @3151@
1 CHIL @3160@
1 MARR
2 DATE 19 JUL 1969
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
2 NOTE
0 @1038@ FAM
1 HUSB @864@
1 WIFE @1036@
1 _UST MARRIED
1 CHIL @3376@
1 CHIL @3387@
1 CHIL @3399@
1 CHIL @3409@
1 CHIL @3422@
1 CHIL @3432@
1 CHIL @3442@
1 CHIL @3452@
1 CHIL @3461@
1 CHIL @3472@
1 MARC
2 DATE 23 SEP 1975
2 _FNA NO
2 NOTE Marié a la mairie de PARIS 16ÈME ARRONDISSEMENT (75016) le 29 septembre 1975 sous le régime de la séparation de biens pure et simple défini par les articles 1536 el suivants du Code civil aux termes du contrat de mariage reçu par Maitre Jean AGIER,
3 CONC  notaire à PARIS, le 23 septembre 1975.
3 CONT Ce régime matrimonial n'a pas fait l'objet de modification.
1 MARR
2 DATE 29 SEP 1975
2 PLAC Paris 16,,,,,
2 _FNA NO
0 @1060@ FAM
1 HUSB @1058@
1 WIFE @883@
1 _UST MARRIED
1 CHIL @3717@
1 CHIL @3727@
1 CHIL @3739@
1 CHIL @3751@
1 CHIL @3761@
1 CHIL @3771@
1 CHIL @3781@
1 CHIL @3791@
1 CHIL @3801@
1 CHIL @3814@
1 MARR
2 DATE 3 JUL 1976
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
1 MARC
2 DATE 3 JUL 1976
2 _FNA NO
2 NOTE Mariée à la mairie de SAINT-PIERRE-EN-PORT (76540) le 3 Juillet 1976 sous le régime de la communauté d'acquêts à défaut de contrat de mariage préalable.
3 CONT Ce régime matrimonial n'a pas fait l'objet de modification.
3 CONT
0 @1046@ FAM
1 HUSB @1044@
1 WIFE @902@
1 _UST MARRIED
1 CHIL @3960@
1 CHIL @3970@
1 CHIL @3979@
1 CHIL @3989@
1 CHIL @3999@
1 CHIL @4008@
1 CHIL @4017@
1 CHIL @4026@
1 CHIL @4036@
1 CHIL @4046@
1 CHIL @4055@
1 CHIL @4065@
1 MARC
2 DATE 1971
2 _FNA NO
2 NOTE Mariée à la mairie de SAINT-PIERRE-EN-PORT (76540) le 19 juin 1971 sous le régime de la communauté d'acquêts à défaut de contrat de mariage préalable.
3 CONT Ce régime matrimonial n'a pas fait l'objet de modification.
3 CONT
1 MARR
2 DATE 19 JUN 1971
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
0 @1069@ FAM
1 HUSB @1067@
1 WIFE @919@
1 _UST MARRIED
1 CHIL @1515@
1 CHIL @1524@
1 CHIL @1533@
1 CHIL @1543@
1 CHIL @1552@
1 CHIL @1561@
1 MARR
2 DATE 21 JUN 1980
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
1 MARC
2 DATE 21 JUN 1980
2 _FNA NO
2 NOTE Mariée à la mairie de SAfNT-PiERRE-EN-PORT (76540) le 21 juin 1980 sous le régime de la communeutë d'acquêts à défaut de contrat de mariage préalable.
3 CONT Ce régime matrimonial n'a pas fait f'objet de modification.
0 @1078@ FAM
1 HUSB @938@
1 WIFE @1076@
1 _UST DIVORCED
1 CHIL @1478@
1 CHIL @1488@
1 CHIL @1498@
1 MARR
2 DATE 28 DEC 1985
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
1 MARR
2 TYPE Religious marriage
2 DATE 22 JUN 1986
2 _FNA NO
2 NOTE annulé par Eglise de ROME 2000 et 2001
0 @23908@ FAM
1 HUSB @938@
1 WIFE @23906@
1 _UST MARRIED
1 EVEN fiancé
2 TYPE Autre union officielle
2 DATE 25 DEC 2013
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
0 @1138@ FAM
1 HUSB @1121@
1 WIFE @1090@
1 _UST MARRIED
1 CHIL @1147@
1 CHIL @4279@
1 CHIL @4292@
1 CHIL @73@
1 CHIL @4305@
1 CHIL @4317@
1 CHIL @4330@
1 CHIL @4343@
1 NOTE rencontre des 4 missoffe à une  soirée amenée par Henri de W.
1 MARR
2 DATE 16 FEB 1948
2 PLAC Paris 09,,,,,
2 _FNA NO
2 NOTE Mariés sous le régime de la séparation de biens pure et simple aux termes de leur contrat de mariage reçu par Maître CREMERY, notaire à PARIS, le 13 février 1948
3 CONT
0 @1166@ FAM
1 HUSB @1162@
1 WIFE @1147@
1 _UST MARRIED
1 CHIL @1169@
1 CHIL @1177@
1 CHIL @1184@
1 CHIL @1191@
1 MARR
2 DATE 12 FEB 1970
2 PLAC Paris 16,,,,,
2 _FNA NO
0 @10473@ FAM
1 HUSB @1169@
1 WIFE @10469@
1 _UST MARRIED
1 CHIL @10476@
1 CHIL @10483@
1 CHIL @10490@
1 CHIL @10498@
1 MARR
2 DATE 8 JUL 1995
2 PLAC Noirmoutier-en-l'Île,85330,Vendée,Pays de la Loire,FRANCE,
3 MAP
4 LATI N46.997330
4 LONG W2.273740
2 _FNA NO
0 @10511@ FAM
1 HUSB @10507@
1 WIFE @1177@
1 _UST MARRIED
1 CHIL @10513@
1 CHIL @10521@
1 MARR
2 DATE 29 APR 2000
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @10534@ FAM
1 HUSB @1184@
1 WIFE @10530@
1 _UST MARRIED
1 CHIL @10537@
1 CHIL @10545@
1 CHIL @10552@
1 MARR
2 DATE 4 JUN 2005
2 _FNA NO
0 @10563@ FAM
1 HUSB @10561@
1 WIFE @1191@
1 _UST MARRIED
1 CHIL @22832@
1 MARR
2 DATE 15 JUL 2017
2 PLAC Noirmoutier-en-l'Île,85330,Vendée,Pays de la Loire,FRANCE,
3 MAP
4 LATI N46.997330
4 LONG W2.273740
2 _FNA NO
0 @1232@ FAM
1 HUSB @1223@
1 WIFE @1230@
1 _UST MARRIED
1 CHIL @34297@
1 CHIL @34304@
1 CHIL @1005@
1 CHIL @34311@
1 CHIL @34318@
1 MARR
2 DATE 2 AUG 1939
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,CHATEAU DE TREMAUVILLE
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
0 @1289@ FAM
1 HUSB @1285@
1 WIFE @1241@
1 _UST MARRIED
1 CHIL @1291@
1 CHIL @1299@
1 CHIL @1307@
1 CHIL @1315@
1 MARR
2 DATE 12 SEP 1992
2 PLAC Bazas,33430,Gironde,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N44.432020
4 LONG W0.213270
2 _FNA NO
2 NOTE PEYRE-OURTICQ
0 @1328@ FAM
1 HUSB @1324@
1 WIFE @1252@
1 _UST MARRIED
1 CHIL @1331@
1 CHIL @1338@
1 MARR
2 DATE 10 JUL 2010
2 PLAC Saint-Symphorien 35630,,,,,
2 _FNA NO
0 @1350@ FAM
1 HUSB @1348@
1 WIFE @1273@
1 _UST MARRIED
1 CHIL @1351@
1 CHIL @1354@
0 @1373@ FAM
1 WIFE @1358@
1 _UST MARRIED
1 CHIL @1369@
0 @1382@ FAM
1 HUSB @1378@
1 WIFE @1358@
1 _UST DIVORCED
1 CHIL @1385@
1 CHIL @1393@
1 MARR
2 DATE 27 JUN 1992
2 PLAC Seine-Port,77240,Seine-et-Marne,Île-de-France,FRANCE,
3 MAP
4 LATI N48.557430
4 LONG E2.553160
2 _FNA NO
0 @1433@ FAM
1 HUSB @1432@
1 WIFE @1401@
1 _UST DIVORCED
1 CHIL @1412@
1 CHIL @1424@
1 CHIL @1438@
1 MARR
2 DATE 24 JUN 1995
2 PLAC Seine-Port,77240,Seine-et-Marne,Île-de-France,FRANCE,
3 MAP
4 LATI N48.557430
4 LONG E2.553160
2 _FNA NO
0 @1641@ FAM
1 HUSB @1459@
1 WIFE @1639@
1 _UST MARRIED
1 CHIL @1642@
1 CHIL @1650@
1 MARR
2 DATE 15 JUL 2007
2 PLAC Naples,,,,,
2 _FNA NO
0 @36809@ FAM
1 HUSB @36806@
1 WIFE @1478@
1 _UST MARRIED
1 CHIL @36813@
0 @32140@ FAM
1 HUSB @1488@
1 WIFE @32136@
1 _UST MARRIED
1 CHIL @32141@
0 @1574@ FAM
1 HUSB @1572@
1 WIFE @1515@
1 _UST MARRIED
1 CHIL @1590@
1 CHIL @1598@
1 CHIL @1606@
1 CHIL @10434@
1 MARR
2 DATE 18 APR 2009
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
0 @1579@ FAM
1 HUSB @1577@
1 WIFE @1524@
1 _UST MARRIED
1 CHIL @1614@
1 CHIL @1621@
1 CHIL @1629@
1 CHIL @19661@
1 MARR
2 DATE 12 SEP 2009
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
0 @1588@ FAM
1 HUSB @1533@
1 WIFE @1584@
1 _UST MARRIED
1 CHIL @33427@
1 MARR
2 DATE MAY 2017
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
1 MARR
2 TYPE Religious marriage
2 DATE 29 JUL 2017
2 PLAC Espagne,,,,,
2 _FNA NO
0 @32151@ FAM
1 HUSB @1552@
1 WIFE @32149@
1 _UST MARRIED
1 CHIL @32153@
1 MARR
2 DATE 21 SEP 2019
3 TIME 15:00
2 PLAC Mauzens-et-Miremont,24260,Dordogne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N44.993820
4 LONG E0.919350
2 _FNA NO
0 @2113@ FAM
1 HUSB @2109@
1 WIFE @1661@
1 _UST MARRIED
1 CHIL @2116@
1 CHIL @2123@
1 CHIL @2130@
1 CHIL @2138@
1 MARR
2 DATE 18 MAY 1985
2 PLAC Malleville-les-Grès,76450,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.829710
4 LONG E0.607030
2 _FNA NO
0 @2072@ FAM
1 HUSB @1672@
1 WIFE @2068@
1 _UST DIVORCED
1 CHIL @2076@
1 CHIL @2087@
1 CHIL @2095@
1 MARR
2 DATE 4 OCT 1997
2 _FNA NO
0 @2104@ FAM
1 HUSB @1672@
1 WIFE @2103@
1 _UST COHABITATION
1 CHIL @2105@
0 @2024@ FAM
1 HUSB @2020@
1 WIFE @1683@
1 _UST MARRIED
1 CHIL @2029@
1 CHIL @2036@
1 CHIL @2044@
1 CHIL @2052@
1 CHIL @2060@
1 MARR
2 DATE 30 APR 1994
2 _FNA NO
0 @1986@ FAM
1 HUSB @1693@
1 WIFE @1982@
1 _UST MARRIED
1 CHIL @1989@
1 CHIL @1996@
1 CHIL @2003@
1 CHIL @2011@
1 MARR
2 DATE 28 AUG 1993
2 _FNA NO
0 @1949@ FAM
1 HUSB @1945@
1 WIFE @1704@
1 _UST MARRIED
1 CHIL @1952@
1 CHIL @1959@
1 CHIL @1967@
1 CHIL @1974@
1 MARR
2 DATE 24 JUN 1995
2 PLAC Malleville-les-Grès,76450,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.829710
4 LONG E0.607030
2 _FNA NO
0 @1901@ FAM
1 HUSB @1715@
1 WIFE @1897@
1 _UST MARRIED
1 CHIL @1904@
1 CHIL @1912@
1 CHIL @1920@
1 CHIL @1928@
1 CHIL @1936@
1 MARR
2 DATE 6 JUL 1996
2 PLAC Malleville-les-Grès,76450,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.829710
4 LONG E0.607030
2 _FNA NO
0 @1877@ FAM
1 HUSB @1725@
1 WIFE @1870@
1 _UST MARRIED
1 CHIL @1880@
1 CHIL @1888@
1 MARR
2 DATE 10 OCT 1998
2 _FNA NO
0 @1844@ FAM
1 HUSB @1736@
1 WIFE @1840@
1 _UST MARRIED
1 CHIL @1847@
1 CHIL @1854@
1 CHIL @1862@
1 MARR
2 DATE 17 JUN 2006
2 PLAC Malleville-les-Grès,76450,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.829710
4 LONG E0.607030
2 _FNA NO
0 @1806@ FAM
1 HUSB @1802@
1 WIFE @1746@
1 _UST MARRIED
1 CHIL @1809@
1 CHIL @1817@
1 CHIL @1824@
1 CHIL @1832@
1 CHIL @32128@
1 MARR
2 DATE 4 SEP 2004
2 PLAC Malleville-les-Grès,76450,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.829710
4 LONG E0.607030
2 _FNA NO
0 @1781@ FAM
1 HUSB @1767@
1 WIFE @1779@
1 _UST MARRIED
1 CHIL @1784@
1 CHIL @1792@
1 CHIL @10304@
1 MARR
2 DATE 18 SEP 2009
2 _FNA NO
0 @2150@ FAM
1 HUSB @2148@
1 WIFE @2116@
1 _UST MARRIED
1 CHIL @35443@
1 CHIL @35435@
1 MARR
2 DATE 20 SEP 2014
2 PLAC Pigna,20228,Haute-Corse,Corse,FRANCE,
3 MAP
4 LATI N42.599240
4 LONG E8.902830
2 _FNA NO
0 @22948@ FAM
1 HUSB @22946@
1 WIFE @2123@
1 _UST MARRIED
1 CHIL @22949@
0 @2208@ FAM
1 HUSB @2206@
1 WIFE @2190@
1 _UST MARRIED
1 CHIL @2247@
1 CHIL @2237@
1 CHIL @2242@
1 MARR
2 DATE 16 APR 1898
3 TIME 16:30
2 PLAC Paris 08,,,,,
2 _FNA NO
0 @2221@ FAM
1 HUSB @2209@
1 WIFE @2218@
1 _UST MARRIED
1 CHIL @576@
1 CHIL @11184@
1 MARR cote AC GG108 BM
2 DATE 4 APR 1791
2 PLAC Marseille,13000,Bouches-du-Rhône,Provence-Alpes-Côte d'Azur,FRANCE,NOTRE-DAME DES ACCOULES
3 MAP
4 LATI N43.296950
4 LONG E5.381070
2 _FNA NO
2 NOTE Commune :
3 CONT Bouches du Rhône
3 CONT Département :
3 CONT 13
3 CONT Type d'acte :
3 CONT V
3 CONT Date :
3 CONT 04/04/1791
3 CONT Texte libre :
3 CONT Contrats Divers
3 CONT Type d'acte :
3 CONT V
3 CONT Nom intervenant 1 :
3 CONT AMBROSELLI
3 CONT Prenoms intervenant 1 :
3 CONT Antoine
3 CONT Nom intervenant 2 :
3 CONT FILLASTRE
3 CONT Prénoms épouse :
3 CONT Agnes Marie
3 CONT Note :
3 CONT Marseille - Accoules
3 CONT
0 @11386@ FAM
1 HUSB @11385@
1 WIFE @2237@
1 _UST MARRIED
1 CHIL @11397@
1 CHIL @11387@
1 CHIL @11392@
0 @35365@ FAM
1 HUSB @2247@
1 WIFE @35358@
1 _UST MARRIED
1 CHIL @35366@
0 @11216@ FAM
1 HUSB @11213@
1 WIFE @2264@
1 _UST MARRIED
1 CHIL @11217@
1 CHIL @11222@
0 @5108@ FAM
1 HUSB @4847@
1 WIFE @2269@
1 _UST MARRIED
1 CHIL @5111@
1 CHIL @5118@
1 CHIL @5125@
1 CHIL @5133@
1 CHIL @5140@
1 CHIL @5150@
1 CHIL @5158@
1 NOTE Jacqueline BECHET est cousine de JEAN-MARIE AMBROSELLI et tante d’ELISABETH MISSOFFE
2 CONT Jacqueline a connu Philippe à Casablanca vers 1946
1 MARR INVALIDES
2 DATE 1 JUL 1947
2 PLAC Paris 07,,,,,
2 _FNA NO
0 @11230@ FAM
1 HUSB @11228@
1 WIFE @2274@
1 _UST MARRIED
1 CHIL @11231@
1 CHIL @11237@
1 CHIL @11242@
1 MARR
2 DATE 19 OCT 1954
2 _FNA NO
0 @11249@ FAM
1 HUSB @11247@
1 WIFE @2274@
1 _UST MARRIED
0 @2297@ FAM
1 HUSB @2295@
1 WIFE @2279@
1 _UST MARRIED
1 CHIL @2298@
1 CHIL @2310@
1 CHIL @2305@
1 CHIL @2324@
1 MARR
2 DATE 9 JAN 1922
2 PLAC Paris 8,,,,,
2 _FNA NO
2 NOTE SAINT PHILIPPE DU ROULE
0 @16956@ FAM
1 HUSB @16952@
1 WIFE @2298@
1 _UST MARRIED
1 CHIL @16957@
1 CHIL @16964@
1 CHIL @16971@
1 CHIL @16978@
0 @2705@ FAM
1 HUSB @2333@
1 WIFE @2701@
1 _UST MARRIED
1 CHIL @2709@
1 CHIL @2716@
1 CHIL @2723@
1 MARR
2 DATE 15 JUL 1989
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
0 @2661@ FAM
1 HUSB @2657@
1 WIFE @2347@
1 _UST MARRIED
1 CHIL @2663@
1 CHIL @2670@
1 CHIL @2678@
1 CHIL @2686@
1 CHIL @2693@
1 MARR
2 DATE 11 AUG 1984
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
0 @2632@ FAM
1 HUSB @2628@
1 WIFE @2358@
1 _UST DIVORCED
1 CHIL @2634@
1 CHIL @2642@
1 CHIL @2649@
1 MARR
2 DATE 4 JUL 1987
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
0 @2603@ FAM
1 HUSB @2369@
1 WIFE @2599@
1 _UST MARRIED
1 CHIL @2605@
1 CHIL @2613@
1 CHIL @2620@
1 MARR
2 DATE 22 APR 1995
2 _FNA NO
0 @2571@ FAM
1 HUSB @2567@
1 WIFE @2380@
1 _UST MARRIED
1 CHIL @2574@
1 CHIL @2582@
1 CHIL @2590@
1 MARR
2 DATE 5 OCT 2002
2 _FNA NO
0 @2538@ FAM
1 HUSB @2534@
1 WIFE @2391@
1 _UST MARRIED
1 CHIL @2540@
1 CHIL @2548@
1 CHIL @2556@
0 @2506@ FAM
1 HUSB @2504@
1 WIFE @2402@
1 _UST DIVORCED
1 CHIL @2508@
1 CHIL @2516@
0 @2524@ FAM
1 HUSB @2523@
1 WIFE @2402@
1 _UST MARRIED
1 CHIL @2526@
0 @2481@ FAM
1 HUSB @2413@
1 WIFE @2479@
1 _UST MARRIED
1 CHIL @2488@
1 CHIL @2496@
0 @2452@ FAM
1 HUSB @2450@
1 WIFE @2424@
1 _UST MARRIED
1 CHIL @2454@
1 CHIL @2462@
0 @22968@ FAM
1 HUSB @22966@
1 WIFE @2678@
1 _UST MARRIED
1 CHIL @30858@
1 MARR
2 DATE SEP 2018
2 PLAC Évian-les-Bains,74500,Haute-Savoie,Auvergne-Rhône-Alpes,FRANCE,
3 MAP
4 LATI N46.401110
4 LONG E6.587930
2 _FNA NO
0 @2926@ FAM
1 HUSB @2922@
1 WIFE @2730@
1 _UST MARRIED
1 CHIL @32162@
1 CHIL @32174@
1 CHIL @32186@
1 MARR
2 DATE 6 OCT 1994
2 PLAC Paris 09,,,,,
2 _FNA NO
1 MARR
2 TYPE Religious marriage
2 DATE 8 OCT 1994
2 _FNA NO
0 @2899@ FAM
1 HUSB @2895@
1 WIFE @2740@
1 _UST MARRIED
1 CHIL @2902@
1 CHIL @2913@
0 @2870@ FAM
1 HUSB @2866@
1 WIFE @2750@
1 _UST MARRIED
1 CHIL @2872@
1 CHIL @2880@
1 CHIL @2887@
1 MARR
2 DATE 1 JUL 2011
2 PLAC Paris 10,,,,,
2 _FNA NO
2 NOTE sous le régime de la séparation de biens aux termes du contrat de mariage reçu par Maître BOTTIER, notaire à PARIS 16ÈME ARRONDISSEMENT (75016), le 26 mai 2011. 
3 CONT
0 @2855@ FAM
1 HUSB @2761@
1 WIFE @2853@
1 _UST SEPARATED
1 CHIL @2856@
0 @2828@ FAM
1 HUSB @2772@
1 WIFE @2826@
1 _UST MARRIED
1 CHIL @2830@
1 CHIL @2837@
1 CHIL @2844@
1 MARR
2 DATE 3 JUN 2006
2 PLAC Meudon,92190,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.813810
4 LONG E2.235000
2 _FNA NO
1 MARR
2 TYPE Religious marriage
2 DATE 15 JUL 2006
2 _FNA NO
0 @2796@ FAM
1 HUSB @2794@
1 WIFE @2782@
1 _UST MARRIED
1 CHIL @2799@
1 CHIL @2809@
1 NOTE pacte civil de solidarité avec Monsieur Nicolas Pierre-Marie Jean-Jacques DESLANDES, enregistré au enregistré au Consulat Général de France à Sydney (Australie) le 16 février 2015 sous le n°XXSYD.2015.0006. 
2 CONT
1 EVEN
2 TYPE Autre union officielle
2 DATE 16 FEB 2015
2 PLAC Sydney,,,,,
2 _FNA NO
2 NOTE pacte civil de solidarité avec Monsieur Nicolas Pierre-Marie Jean-Jacques DESLANDES, enregistré au enregistré au Consulat Général de France à Sydney (Australie) le 16 février 2015 sous le n°XXSYD.2015.0006. 
3 CONT
0 @3067@ FAM
1 HUSB @2943@
1 WIFE @3062@
1 _UST MARRIED
1 CHIL @3069@
1 CHIL @3077@
1 CHIL @3085@
1 MARR
2 DATE 28 JUL 2001
2 PLAC Varengeville-sur-Mer,76119,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.904750
4 LONG E0.994800
2 _FNA NO
0 @3031@ FAM
1 HUSB @3029@
1 WIFE @2954@
1 _UST MARRIED
1 CHIL @3034@
1 CHIL @3043@
1 CHIL @3051@
1 MARR
2 DATE 21 JUN 2008
2 _FNA NO
0 @3011@ FAM
1 HUSB @3009@
1 WIFE @2965@
1 _UST MARRIED
1 CHIL @3014@
1 CHIL @3021@
1 MARR
2 DATE 30 AUG 2008
2 _FNA NO
0 @2990@ FAM
1 HUSB @2976@
1 WIFE @2988@
1 _UST MARRIED
1 CHIL @2993@
1 CHIL @3000@
1 MARR
2 DATE 16 JUN 2017
2 _FNA NO
2 NOTE ou Mariage: 31 mai 2013 - Manhattan, New York, New York, United States
3 CONT ?
0 @3358@ FAM
1 HUSB @3103@
1 WIFE @3354@
1 _UST MARRIED
1 CHIL @3361@
1 MARR
2 DATE 8 MAY 1999
2 _FNA NO
0 @3320@ FAM
1 HUSB @3316@
1 WIFE @3113@
1 _UST MARRIED
1 CHIL @3322@
1 CHIL @3329@
1 CHIL @3337@
1 CHIL @3345@
1 MARR
2 DATE 6 AUG 2005
2 PLAC Ancretteville-sur-Mer,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.788420
4 LONG E0.508420
2 _FNA NO
0 @3265@ FAM
1 HUSB @3261@
1 WIFE @3123@
1 _UST MARRIED
1 CHIL @3267@
1 CHIL @3275@
1 CHIL @3283@
1 CHIL @3290@
1 CHIL @3298@
1 CHIL @3306@
1 MARR
2 DATE 14 AUG 2004
2 PLAC Ancretteville-sur-Mer,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.788420
4 LONG E0.508420
2 _FNA NO
0 @3235@ FAM
1 HUSB @3132@
1 WIFE @3231@
1 _UST MARRIED
1 CHIL @3237@
1 CHIL @3245@
1 CHIL @3253@
1 CHIL @21218@
1 MARR
2 DATE 10 AUG 2002
2 PLAC Ancretteville-sur-Mer,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.788420
4 LONG E0.508420
2 _FNA NO
0 @35203@ FAM
1 HUSB @3132@
1 WIFE @7919@
1 _UST MARRIED
1 CHIL @35204@
1 CHIL @35209@
0 @3198@ FAM
1 HUSB @3194@
1 WIFE @3142@
1 _UST MARRIED
1 CHIL @3201@
1 CHIL @3209@
1 CHIL @3217@
1 CHIL @3224@
1 CHIL @19754@
1 MARR
2 DATE 27 JUL 2006
2 PLAC Ancretteville-sur-Mer,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.788420
4 LONG E0.508420
2 _FNA NO
0 @3175@ FAM
1 HUSB @3171@
1 WIFE @3160@
1 _UST MARRIED
1 CHIL @3178@
1 CHIL @3186@
1 MARR
2 DATE 11 JUL 2009
2 _FNA NO
0 @3637@ FAM
1 HUSB @3633@
1 WIFE @3376@
1 _UST DIVORCED
1 CHIL @3640@
1 CHIL @3648@
1 CHIL @3656@
1 CHIL @3664@
1 CHIL @3673@
1 CHIL @3691@
1 CHIL @3684@
1 CHIL @3699@
1 CHIL @3706@
1 MARR
2 DATE 23 MAY 1998
2 _FNA NO
0 @3621@ FAM
1 HUSB @3399@
1 WIFE @3619@
1 _UST MARRIED
1 CHIL @3624@
1 CHIL @32205@
1 MARR
2 DATE 15 MAR 2014
2 _FNA NO
0 @3581@ FAM
1 HUSB @3409@
1 WIFE @3579@
1 _UST MARRIED
1 CHIL @3583@
1 CHIL @3592@
1 CHIL @3600@
1 CHIL @3609@
1 MARR
2 DATE 27 MAY 2006
2 _FNA NO
0 @3541@ FAM
1 HUSB @3537@
1 WIFE @3422@
1 _UST MARRIED
1 CHIL @3543@
1 CHIL @3551@
1 CHIL @3559@
1 CHIL @3567@
1 CHIL @32620@
1 MARR
2 DATE 23 AUG 2003
2 PLAC Riville,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.725470
4 LONG E0.562160
2 _FNA NO
0 @3513@ FAM
1 HUSB @3511@
1 WIFE @3432@
1 _UST MARRIED
1 CHIL @3515@
1 CHIL @3529@
1 CHIL @3522@
1 MARR
2 DATE 31 JUL 2010
2 PLAC Riville,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.725470
4 LONG E0.562160
2 _FNA NO
0 @3489@ FAM
1 HUSB @3487@
1 WIFE @3442@
1 _UST MARRIED
1 CHIL @3494@
1 CHIL @3501@
1 CHIL @34329@
1 MARR
2 DATE 30 DEC 2011
2 PLAC Riville,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.725470
4 LONG E0.562160
2 _FNA NO
0 @3483@ FAM
1 HUSB @3452@
1 WIFE @3482@
1 _UST MARRIED
1 MARR
2 DATE 8 JUL 2017
2 _FNA NO
0 @3921@ FAM
1 HUSB @3917@
1 WIFE @3717@
1 _UST MARRIED
1 CHIL @3924@
1 CHIL @3932@
1 CHIL @3940@
1 CHIL @3949@
1 MARR
2 DATE 25 OCT 2003
2 _FNA NO
0 @3893@ FAM
1 HUSB @3889@
1 WIFE @3751@
1 _UST MARRIED
1 CHIL @3894@
1 CHIL @3902@
1 CHIL @3909@
0 @3870@ FAM
1 HUSB @3868@
1 WIFE @3761@
1 _UST MARRIED
1 CHIL @3873@
1 CHIL @3880@
1 CHIL @19739@
1 MARR
2 DATE 30 JUN 2012
2 _FNA NO
0 @3858@ FAM
1 HUSB @3771@
1 WIFE @3854@
1 _UST COHABITATION
1 CHIL @3859@
0 @3826@ FAM
1 HUSB @3824@
1 WIFE @3781@
1 _UST MARRIED
1 CHIL @3829@
1 CHIL @3837@
1 CHIL @3845@
1 MARR
2 DATE 22 AUG 2009
2 _FNA NO
0 @4215@ FAM
1 HUSB @4209@
1 WIFE @3960@
1 _UST MARRIED
1 CHIL @4217@
1 CHIL @4225@
1 CHIL @4232@
1 CHIL @4239@
1 CHIL @4246@
1 CHIL @4254@
1 MARR
2 DATE 16 JUL 1994
2 PLAC Brueil-en-Vexin,78440,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N49.033330
4 LONG E1.816670
2 _FNA NO
0 @4175@ FAM
1 HUSB @3970@
1 WIFE @4171@
1 _UST MARRIED
1 CHIL @4178@
1 CHIL @4186@
1 CHIL @4193@
1 CHIL @4201@
1 CHIL @10391@
1 MARR
2 DATE 3 JUL 2004
2 _FNA NO
0 @4155@ FAM
1 HUSB @3979@
1 WIFE @4153@
1 _UST MARRIED
1 CHIL @4156@
1 CHIL @4163@
0 @4135@ FAM
1 HUSB @3999@
1 WIFE @4131@
1 _UST DIVORCED
1 CHIL @4138@
1 CHIL @4145@
1 CHIL @35225@
1 CHIL @35230@
1 CHIL @35235@
1 CHIL @35242@
1 MARR
2 DATE 6 JUL 2002
2 _FNA NO
0 @33471@ FAM
1 HUSB @3999@
1 WIFE @33469@
1 _UST MARRIED
1 CHIL @33473@
0 @4121@ FAM
1 HUSB @4008@
1 WIFE @4119@
1 _UST MARRIED
1 CHIL @4123@
1 MARR
2 DATE 4 JUL 2010
2 _FNA NO
0 @4102@ FAM
1 HUSB @4017@
1 WIFE @4098@
1 _UST MARRIED
1 CHIL @4104@
1 CHIL @4111@
1 MARR
2 DATE 4 JUL 2009
2 _FNA NO
0 @4081@ FAM
1 HUSB @4026@
1 WIFE @4077@
1 _UST MARRIED
1 CHIL @4083@
1 CHIL @4091@
1 MARR
2 DATE 9 JUL 2011
2 _FNA NO
0 @30837@ FAM
1 HUSB @4046@
1 WIFE @30835@
1 _UST MARRIED
1 MARR
2 DATE 15 JUN 2019
2 PLAC Brueil-en-Vexin,78440,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N49.033330
4 LONG E1.816670
2 _FNA NO
0 @6993@ FAM
1 HUSB @6991@
1 WIFE @4266@
1 _UST MARRIED
1 CHIL @7047@
1 CHIL @7035@
1 CHIL @7042@
1 MARR
2 DATE 28 MAY 1912
2 PLAC Paris 08,,,,,saint philippe du roule
2 _FNA NO
0 @7007@ FAM
1 HUSB @4272@
1 WIFE @7005@
1 _UST MARRIED
1 CHIL @7009@
1 CHIL @7015@
1 CHIL @16434@
1 MARR
2 DATE 25 OCT 1917
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,NOTRE DAME DE LORETTE
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @4719@ FAM
1 HUSB @4713@
1 WIFE @4279@
1 _UST MARRIED
1 CHIL @4721@
1 CHIL @4732@
1 CHIL @4743@
1 CHIL @4753@
1 MARR
2 DATE 19 SEP 1981
2 PLAC Paris 09,,,,,
2 _FNA NO
0 @4626@ FAM
1 HUSB @4622@
1 WIFE @4292@
1 _UST MARRIED
1 CHIL @4632@
1 CHIL @4641@
1 CHIL @4650@
1 CHIL @4659@
1 MARR
2 DATE 16 SEP 1978
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
0 @4529@ FAM
1 HUSB @4305@
1 WIFE @4525@
1 _UST MARRIED
1 CHIL @4531@
1 CHIL @4539@
1 CHIL @4547@
1 CHIL @4555@
1 MARR
2 DATE 23 JUN 1979
2 PLAC La Ferté-Saint-Aubin,45240,Loiret,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N47.716950
4 LONG E1.939040
2 _FNA NO
0 @4571@ FAM
1 HUSB @4305@
1 WIFE @4568@
1 _UST MARRIED
1 CHIL @4574@
1 MARR
2 DATE 19 DEC 2014
2 PLAC Paris 09,,,,,
2 _FNA NO
0 @4433@ FAM
1 HUSB @4429@
1 WIFE @4317@
1 _UST MARRIED
1 CHIL @4435@
1 CHIL @4443@
1 CHIL @4451@
1 CHIL @4458@
1 CHIL @4466@
1 MARR
2 DATE 28 JUN 1980
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
0 @4397@ FAM
1 HUSB @4393@
1 WIFE @4330@
1 _UST MARRIED
1 CHIL @4400@
1 CHIL @4407@
1 CHIL @4414@
1 CHIL @4421@
1 MARR
2 DATE 28 JUN 1986
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
0 @4360@ FAM
1 HUSB @4343@
1 WIFE @4356@
1 _UST MARRIED
1 CHIL @4362@
1 CHIL @4369@
1 CHIL @4377@
1 CHIL @4384@
1 NOTE ont les arrière-arrière-grand-parents   communs  =
2 CONT HENRI DE WENDEL (1844-1926) et BERTHE DE VAULSERRE
1 MARR
2 DATE 6 JUN 1997
2 PLAC Ognon,60810,Oise,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.237290
4 LONG E2.644130
2 _FNA NO
1 MARR
2 TYPE Religious marriage
2 DATE 7 JUN 1997
2 PLAC Ognon,60810,Oise,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.237290
4 LONG E2.644130
2 _FNA NO
0 @4502@ FAM
1 HUSB @4435@
1 WIFE @4498@
1 _UST MARRIED
1 CHIL @4506@
1 CHIL @4514@
1 MARR
2 DATE 22 JUN 2013
2 _FNA NO
0 @4480@ FAM
1 HUSB @4476@
1 WIFE @4443@
1 _UST MARRIED
1 CHIL @4482@
1 CHIL @4490@
1 CHIL @34141@
1 MARR
2 DATE 24 SEP 2011
2 PLAC L'Île-d'Yeu,85350,Vendée,Pays de la Loire,FRANCE,
3 MAP
4 LATI N46.725150
4 LONG W2.349360
2 _FNA NO
0 @11334@ FAM
1 HUSB @4451@
1 WIFE @11332@
1 _UST MARRIED
1 CHIL @34437@
1 MARR
2 DATE 21 JUL 2018
2 PLAC Cruscades,11200,Aude,Occitanie,FRANCE,
3 MAP
4 LATI N43.190760
4 LONG E2.816360
2 _FNA NO
0 @4586@ FAM
1 HUSB @4531@
1 WIFE @4584@
1 _UST MARRIED
1 CHIL @4589@
1 MARR EGLISE SAINT AMAND
2 DATE 20 SEP 2014
2 PLAC Thomery,77810,Seine-et-Marne,Île-de-France,FRANCE,
3 MAP
4 LATI N48.407230
4 LONG E2.788520
2 _FNA NO
0 @4603@ FAM
1 HUSB @4599@
1 WIFE @4539@
1 _UST MARRIED
1 CHIL @4605@
1 CHIL @4613@
1 MARR
2 DATE 25 MAY 2013
2 PLAC Pibrac,31820,Haute-Garonne,Occitanie,FRANCE,
3 MAP
4 LATI N43.620420
4 LONG E1.285400
2 _FNA NO
0 @6887@ FAM
1 HUSB @6885@
1 WIFE @4547@
1 _UST COHABITATION
1 EVEN
2 TYPE Autre union officielle
2 DATE 30 JUN 2017
2 PLAC L'Île-d'Yeu,85350,Vendée,Pays de la Loire,FRANCE,
3 MAP
4 LATI N46.725150
4 LONG W2.349360
2 _FNA NO
1 MARR
2 DATE 14 APR 2018
2 PLAC Boulogne-Billancourt,92100,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.833330
4 LONG E2.250000
2 _FNA NO
1 MARR ND DU PORT PORT-JOINVILLE
2 TYPE Religious marriage
2 DATE 30 JUN 2018
2 PLAC L'Île-d'Yeu,85350,Vendée,Pays de la Loire,FRANCE,
3 MAP
4 LATI N46.725150
4 LONG W2.349360
2 _FNA NO
0 @4674@ FAM
1 HUSB @4632@
1 WIFE @4670@
1 _UST MARRIED
1 CHIL @4676@
1 CHIL @22956@
1 MARR
2 DATE 24 NOV 2011
2 _FNA NO
0 @4688@ FAM
1 HUSB @4684@
1 WIFE @4641@
1 _UST MARRIED
1 CHIL @4690@
1 CHIL @4697@
1 CHIL @8945@
1 MARR
2 DATE 23 MAR 2013
2 _FNA NO
0 @19768@ FAM
1 HUSB @19764@
1 WIFE @4659@
1 _UST MARRIED
1 MARR
2 DATE 6 OCT 2018
2 PLAC Montmerle-sur-Saône,01090,Ain,Auvergne-Rhône-Alpes,FRANCE,
3 MAP
4 LATI N46.083330
4 LONG E4.766670
2 _FNA NO
2 NOTE réception château de Luponnas à Vonnas
0 @4769@ FAM
1 HUSB @4765@
1 WIFE @4721@
1 _UST MARRIED
1 CHIL @4771@
1 CHIL @4778@
1 CHIL @4785@
1 MARR
2 DATE 12 MAY 2012
2 _FNA NO
0 @10571@ FAM
1 HUSB @4732@
1 WIFE @10567@
1 _UST MARRIED
1 CHIL @10574@
1 CHIL @10581@
1 CHIL @19795@
1 MARR
2 DATE 7 JUL 2012
2 _FNA NO
0 @4812@ FAM
1 HUSB @4792@
1 WIFE @4805@
1 _UST MARRIED
1 CHIL @4818@
1 CHIL @1121@
1 CHIL @4829@
1 CHIL @4836@
1 CHIL @4847@
1 CHIL @4857@
1 CHIL @4867@
1 CHIL @4874@
1 MARR saint françois de sales
2 DATE 4 JAN 1917
2 PLAC Paris 17,,,,,
2 _FNA NO
0 @5728@ FAM
1 HUSB @4818@
1 WIFE @5724@
1 _UST MARRIED
1 CHIL @5731@
1 CHIL @5738@
1 CHIL @5745@
1 CHIL @5753@
1 CHIL @5761@
1 MARR
2 DATE 10 MAR 1948
2 _FNA NO
0 @5640@ FAM
1 HUSB @4829@
1 WIFE @5636@
1 _UST DIVORCED
1 CHIL @5643@
1 CHIL @5651@
1 CHIL @5658@
1 MARR
2 DATE 30 JUL 1953
2 _FNA NO
0 @5009@ FAM
1 HUSB @4867@
1 WIFE @5005@
1 _UST MARRIED
1 CHIL @5011@
1 CHIL @5018@
1 CHIL @5024@
1 MARR MAXIM'S
2 DATE 14 SEP 1972
2 PLAC Paris 08,,,,,
2 _FNA NO
0 @13523@ FAM
1 HUSB @4867@
1 WIFE @13517@
1 _UST DIVORCED
1 MARR
2 DATE 20 FEB 1970
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @4893@ FAM
1 HUSB @4886@
1 WIFE @4874@
1 _UST MARRIED
1 CHIL @4920@
1 CHIL @4912@
1 CHIL @4904@
1 CHIL @4896@
1 MARR
2 DATE 26 SEP 1959
2 _FNA NO
0 @4985@ FAM
1 HUSB @4981@
1 WIFE @4896@
1 _UST MARRIED
1 CHIL @4988@
1 CHIL @4996@
1 MARR
2 DATE 11 OCT 1997
2 _FNA NO
0 @4956@ FAM
1 HUSB @4952@
1 WIFE @4904@
1 _UST MARRIED
1 CHIL @4958@
1 MARR
2 DATE 1 SEP 1984
2 _FNA NO
0 @4970@ FAM
1 HUSB @4966@
1 WIFE @4904@
1 _UST MARRIED
1 CHIL @4972@
1 MARR
2 DATE 28 MAR 2004
2 _FNA NO
0 @4933@ FAM
1 HUSB @4920@
1 WIFE @4929@
1 _UST DIVORCED
1 MARR
2 DATE 3 SEP 1994
2 _FNA NO
0 @4941@ FAM
1 HUSB @4920@
1 WIFE @4937@
1 _UST MARRIED
1 CHIL @4943@
1 MARR
2 DATE 23 DEC 2007
2 _FNA NO
0 @5082@ FAM
1 HUSB @5011@
1 WIFE @5078@
1 _UST MARRIED
1 CHIL @5085@
1 CHIL @5093@
1 CHIL @5101@
1 MARR
2 DATE 15 JUN 2002
2 _FNA NO
0 @5057@ FAM
1 HUSB @5018@
1 WIFE @5053@
1 _UST MARRIED
1 CHIL @5060@
1 CHIL @5068@
1 MARR
2 DATE 23 MAR 2013
2 _FNA NO
0 @5035@ FAM
1 HUSB @5031@
1 WIFE @5024@
1 _UST MARRIED
1 CHIL @5038@
1 CHIL @5045@
1 MARR
2 DATE 21 JUL 2007
2 PLAC Genève,1200,Genève,Région Lémanique,SUISSE,
3 MAP
4 LATI N46.202220
4 LONG E6.145690
2 _FNA NO
0 @5510@ FAM
1 HUSB @5503@
1 WIFE @5111@
1 _UST MARRIED
1 CHIL @5512@
1 CHIL @5520@
1 CHIL @5527@
1 CHIL @5534@
1 MARR
2 DATE 3 AUG 1968
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
0 @5457@ FAM
1 HUSB @5118@
1 WIFE @5453@
1 _UST MARRIED
1 CHIL @5459@
1 CHIL @5466@
1 CHIL @5473@
1 MARR
2 DATE 9 SEP 1978
2 _FNA NO
0 @5286@ FAM
1 HUSB @5282@
1 WIFE @5125@
1 _UST DIVORCED
1 CHIL @5289@
1 CHIL @5296@
1 MARR
2 DATE 6 JUL 1974
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
0 @5307@ FAM
1 WIFE @5125@
1 _UST MARRIED
1 CHIL @5303@
0 @5318@ FAM
1 HUSB @5311@
1 WIFE @5125@
1 _UST COHABITATION
1 CHIL @5320@
1 MARR
2 DATE 26 JAN 1996
2 _FNA NO
0 @5214@ FAM
1 HUSB @5133@
1 WIFE @5210@
1 _UST MARRIED
1 CHIL @5216@
1 CHIL @5224@
1 CHIL @5231@
1 MARR
2 DATE 24 JUN 1978
2 _FNA NO
0 @5199@ FAM
1 HUSB @5150@
1 WIFE @5195@
1 _UST MARRIED
1 CHIL @5201@
1 MARR
2 DATE 26 JUL 1996
2 _FNA NO
0 @5170@ FAM
1 HUSB @5166@
1 WIFE @5158@
1 _UST MARRIED
1 CHIL @5172@
1 CHIL @5180@
1 CHIL @5187@
1 MARR
2 DATE 11 JUN 1988
2 _FNA NO
0 @5264@ FAM
1 HUSB @5262@
1 WIFE @5216@
1 _UST MARRIED
1 CHIL @5266@
1 CHIL @5273@
1 CHIL @10840@
1 MARR
2 DATE 3 JUN 2011
2 _FNA NO
0 @5243@ FAM
1 HUSB @5241@
1 WIFE @5224@
1 _UST MARRIED
1 CHIL @5245@
1 CHIL @5253@
1 CHIL @10852@
1 CHIL @34148@
1 MARR
2 DATE 13 SEP 2014
2 _FNA NO
0 @10850@ FAM
1 HUSB @5231@
1 WIFE @10848@
1 _UST MARRIED
1 CHIL @34127@
1 MARR
2 DATE 1 JUL 2017
2 _FNA NO
0 @5345@ FAM
1 HUSB @5341@
1 WIFE @5289@
1 _UST MARRIED
1 CHIL @5348@
1 CHIL @5356@
1 CHIL @5363@
1 CHIL @34791@
1 MARR
2 DATE 15 NOV 2008
2 PLAC Nantes,44000,Loire-Atlantique,Pays de la Loire,FRANCE,
3 MAP
4 LATI N47.217250
4 LONG W1.553360
2 _FNA NO
0 @5330@ FAM
1 HUSB @5328@
1 WIFE @5296@
1 _UST MARRIED
1 CHIL @5333@
1 CHIL @34110@
1 CHIL @34117@
1 MARR
2 DATE 4 MAY 2013
2 _FNA NO
0 @5486@ FAM
1 HUSB @5459@
1 WIFE @5482@
1 _UST MARRIED
1 CHIL @5489@
1 MARR
2 DATE 6 SEP 2008
2 PLAC Les Baux-de-Provence,13520,Bouches-du-Rhône,Provence-Alpes-Côte d'Azur,FRANCE,
3 MAP
4 LATI N43.744250
4 LONG E4.796070
2 _FNA NO
0 @5499@ FAM
1 HUSB @5466@
1 WIFE @5497@
1 _UST COMMON_LAW
1 MARR
2 DATE MAR 2013
2 _FNA NO
0 @34808@ FAM
1 HUSB @5473@
1 WIFE @34804@
1 _UST MARRIED
1 CHIL @34811@
1 MARR
2 DATE 12 JUL 2015
2 _FNA NO
0 @5612@ FAM
1 HUSB @5608@
1 WIFE @5512@
1 _UST MARRIED
1 CHIL @5614@
1 CHIL @5621@
1 CHIL @5628@
1 MARR
2 DATE 20 JUL 1991
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
0 @5583@ FAM
1 HUSB @5579@
1 WIFE @5520@
1 _UST MARRIED
1 CHIL @5585@
1 CHIL @5592@
1 CHIL @5600@
1 MARR
2 DATE 19 JUL 1997
2 _FNA NO
0 @5546@ FAM
1 HUSB @5527@
1 WIFE @5542@
1 _UST MARRIED
1 CHIL @5548@
1 CHIL @5555@
1 CHIL @5562@
1 CHIL @5569@
1 MARR
2 DATE 21 AUG 1999
2 _FNA NO
0 @10862@ FAM
1 HUSB @5534@
1 WIFE @10860@
1 _UST MARRIED
1 CHIL @10864@
1 CHIL @34155@
1 MARR
2 DATE 15 APR 2017
2 _FNA NO
0 @5700@ FAM
1 HUSB @5643@
1 WIFE @5696@
1 _UST MARRIED
1 CHIL @5702@
1 CHIL @5709@
1 CHIL @5716@
1 MARR
2 DATE 26 JUN 1985
2 _FNA NO
0 @5670@ FAM
1 HUSB @5666@
1 WIFE @5651@
1 _UST DIVORCED
1 CHIL @5672@
1 CHIL @5679@
1 CHIL @5686@
1 MARR
2 DATE 29 JAN 1983
2 _FNA NO
0 @34283@ FAM
1 HUSB @5672@
1 WIFE @34281@
1 _UST MARRIED
0 @5774@ FAM
1 HUSB @5731@
1 WIFE @5770@
1 _UST DIVORCED
1 CHIL @5776@
1 CHIL @5783@
1 MARR
2 DATE 28 SEP 1973
2 _FNA NO
0 @5810@ FAM
1 HUSB @5731@
1 WIFE @5806@
1 _UST MARRIED
1 CHIL @5813@
1 CHIL @5820@
1 CHIL @5828@
1 MARR
2 DATE 18 SEP 1987
2 _FNA NO
0 @5876@ FAM
1 HUSB @5874@
1 WIFE @5738@
1 _UST MARRIED
1 CHIL @5879@
1 CHIL @5885@
1 MARR
2 DATE 6 MAR 1971
2 _FNA NO
0 @5942@ FAM
1 HUSB @5938@
1 WIFE @5745@
1 _UST MARRIED
1 CHIL @5945@
1 CHIL @5952@
1 CHIL @5959@
1 CHIL @5966@
1 MARR
2 DATE 24 JUN 1972
2 _FNA NO
0 @6036@ FAM
1 HUSB @6029@
1 WIFE @5753@
1 _UST MARRIED
1 CHIL @6039@
1 CHIL @6046@
1 CHIL @6053@
1 MARR
2 DATE 28 JUN 1975
2 _FNA NO
0 @10890@ FAM
1 HUSB @10886@
1 WIFE @5761@
1 _UST MARRIED
1 CHIL @10893@
1 CHIL @10900@
1 CHIL @10908@
1 MARR
2 DATE 10 SEP 1983
2 _FNA NO
0 @5796@ FAM
1 HUSB @5776@
1 WIFE @5792@
1 _UST MARRIED
1 CHIL @5835@
1 CHIL @5843@
1 CHIL @5850@
1 CHIL @5858@
1 CHIL @5866@
1 MARR
2 DATE 15 SEP 2001
2 _FNA NO
0 @5803@ FAM
1 HUSB @5783@
1 WIFE @5799@
1 _UST MARRIED
1 CHIL @7255@
1 CHIL @7258@
1 MARR
2 DATE 2 AUG 2003
2 _FNA NO
0 @34219@ FAM
1 HUSB @34217@
1 WIFE @5828@
1 _UST MARRIED
1 MARR
2 DATE AUG 2019
2 _FNA NO
0 @5920@ FAM
1 HUSB @5879@
1 WIFE @5916@
1 _UST MARRIED
1 CHIL @5930@
1 CHIL @5922@
1 MARR
2 DATE 30 APR 1994
2 _FNA NO
0 @5896@ FAM
1 HUSB @5885@
1 WIFE @5892@
1 _UST DIVORCED
1 CHIL @5899@
1 CHIL @5907@
1 MARR
2 DATE 5 JUL 1998
2 _FNA NO
0 @7271@ FAM
1 HUSB @5885@
1 WIFE @7269@
1 _UST COHABITATION
0 @34250@ FAM
1 HUSB @34248@
1 WIFE @5922@
1 _UST MARRIED
1 NOTE Elle a emménagé avec Loic (qui est train de passer l’agrégation d’histoire) à Barbes
0 @6011@ FAM
1 HUSB @5945@
1 WIFE @6007@
1 _UST MARRIED
1 CHIL @6014@
1 CHIL @6021@
1 MARR
2 DATE 2 SEP 2003
2 _FNA NO
0 @5978@ FAM
1 HUSB @5952@
1 WIFE @5974@
1 _UST MARRIED
1 CHIL @5980@
1 CHIL @5988@
1 CHIL @5996@
1 MARR
2 DATE 12 JUN 2005
2 _FNA NO
0 @6087@ FAM
1 HUSB @6083@
1 WIFE @6039@
1 _UST MARRIED
1 CHIL @6089@
1 CHIL @6096@
1 CHIL @7274@
1 MARR
2 DATE 16 SEP 2006
2 PLAC Lorgerie,,Mayenne,,,
2 _FNA NO
0 @6065@ FAM
1 HUSB @6061@
1 WIFE @6046@
1 _UST MARRIED
1 CHIL @6068@
1 CHIL @6075@
1 MARR
2 DATE 26 JUN 2004
2 _FNA NO
0 @7284@ FAM
1 HUSB @6053@
1 WIFE @7282@
1 _UST COHABITATION
1 CHIL @10878@
1 CHIL @34134@
1 MARR
2 DATE 15 NOV 2015
2 _FNA NO
0 @6123@ FAM
1 HUSB @6103@
1 WIFE @6114@
1 _UST MARRIED
1 CHIL @6168@
1 CHIL @6178@
1 CHIL @6188@
1 CHIL @4792@
1 CHIL @6199@
1 CHIL @6209@
1 MARR
2 DATE 29 SEP 1884
2 PLAC Amiens,80000,Somme,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.900000
4 LONG E2.300000
2 _FNA NO
2 NOTE son père Victor n’assiste pas au mariage
3 CONT elle ne reverra guère plus son père après
0 @6146@ FAM
1 HUSB @6128@
1 WIFE @6139@
1 _UST MARRIED
1 CHIL @6103@
1 CHIL @6150@
1 MARR
2 DATE 22 JUN 1857
2 PLAC Nuaillé-sur-Boutonne,17470,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.013040
4 LONG W0.435040
2 _FNA NO
0 @6165@ FAM
1 HUSB @6161@
1 WIFE @6150@
1 _UST MARRIED
1 CHIL @6251@
1 CHIL @6262@
1 CHIL @6272@
1 CHIL @6283@
1 MARR
2 DATE 7 APR 1885
2 PLAC Nuaillé-sur-Boutonne,17470,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.013040
4 LONG W0.435040
2 _FNA NO
0 @6248@ FAM
1 HUSB @6168@
1 WIFE @6244@
1 _UST MARRIED
1 CHIL @6350@
1 CHIL @6360@
1 CHIL @6371@
1 MARR
2 DATE 19 OCT 1910
2 PLAC Châlons-en-Champagne,51000,Marne,Grand Est,FRANCE,
3 MAP
4 LATI N48.953930
4 LONG E4.367240
2 _FNA NO
0 @6240@ FAM
1 HUSB @6178@
1 WIFE @6233@
1 _UST MARRIED
1 MARR
2 DATE 27 MAR 1940
2 _FNA NO
0 @6229@ FAM
1 HUSB @6221@
1 WIFE @6199@
1 _UST MARRIED
1 MARR saint françois de sales
2 DATE 12 OCT 1916
2 PLAC Paris 07,,,,,
2 _FNA NO
0 @6345@ FAM
1 HUSB @6251@
1 WIFE @6338@
1 _UST MARRIED
1 CHIL @10685@
1 CHIL @10691@
1 MARR
2 DATE 20 JUL 1916
2 PLAC Villefranche-de-Rouergue,12200,Aveyron,Occitanie,FRANCE,
3 MAP
4 LATI N44.350000
4 LONG E2.050000
2 _FNA NO
0 @6334@ FAM
1 HUSB @6262@
1 WIFE @6327@
1 _UST MARRIED
1 CHIL @13653@
1 CHIL @13660@
1 MARR
2 DATE AUG 1921
2 _FNA NO
0 @13634@ FAM
1 HUSB @6262@
1 WIFE @13623@
1 _UST MARRIED
1 MARR
2 DATE 1920
2 _FNA NO
0 @6313@ FAM
1 HUSB @6272@
1 WIFE @6311@
1 _UST DIVORCED
1 MARR
2 DATE 1925
2 _FNA NO
0 @6323@ FAM
1 HUSB @6272@
1 WIFE @6316@
1 _UST MARRIED
1 CHIL @13681@
1 MARR
2 DATE 21 JUL 1927
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @13679@ FAM
1 HUSB @6272@
1 WIFE @13677@
1 _UST MARRIED
1 MARR
2 DATE 1930
2 _FNA NO
0 @6301@ FAM
1 HUSB @6283@
1 WIFE @6294@
1 _UST MARRIED
1 CHIL @13720@
1 CHIL @13732@
1 MARR
2 DATE 12 OCT 1920
2 PLAC Biarritz,64200,Pyrénées-Atlantiques,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N43.480120
4 LONG W1.555580
2 _FNA NO
0 @6309@ FAM
1 HUSB @6283@
1 WIFE @6305@
1 _UST COHABITATION
1 CHIL @13756@
1 CHIL @13764@
0 @6397@ FAM
1 HUSB @6360@
1 WIFE @6393@
1 _UST MARRIED
1 CHIL @6399@
1 CHIL @6406@
1 MARR
2 DATE 10 OCT 1946
2 _FNA NO
0 @6389@ FAM
1 HUSB @6371@
1 WIFE @6382@
1 _UST MARRIED
1 CHIL @6503@
1 CHIL @6511@
1 CHIL @6519@
1 CHIL @6526@
1 CHIL @6533@
1 CHIL @6540@
1 MARR
2 DATE 11 APR 1939
2 PLAC Nice,06000,Alpes-Maritimes,Provence-Alpes-Côte d'Azur,FRANCE,
3 MAP
4 LATI N43.703130
4 LONG E7.266080
2 _FNA NO
0 @12283@ FAM
1 HUSB @12276@
1 WIFE @6393@
1 _UST MARRIED
0 @6478@ FAM
1 HUSB @6399@
1 WIFE @6474@
1 _UST MARRIED
1 CHIL @6481@
1 CHIL @6488@
1 CHIL @6496@
1 MARR
2 DATE 9 SEP 1976
2 _FNA NO
0 @6418@ FAM
1 HUSB @6414@
1 WIFE @6406@
1 _UST MARRIED
1 CHIL @6420@
1 CHIL @6427@
1 CHIL @6434@
1 CHIL @6442@
1 MARR
2 DATE 14 DEC 1972
2 _FNA NO
0 @6462@ FAM
1 HUSB @6420@
1 WIFE @6458@
1 _UST MARRIED
1 CHIL @6465@
1 CHIL @7208@
1 CHIL @34271@
1 MARR
2 DATE 20 JUL 2001
2 _FNA NO
0 @6455@ FAM
1 HUSB @6427@
1 WIFE @6451@
1 _UST DIVORCED
1 MARR
2 DATE 12 SEP 2009
2 _FNA NO
0 @10964@ FAM
1 HUSB @6427@
1 WIFE @10960@
1 _UST MARRIED
1 CHIL @10965@
1 MARR
2 DATE 7 MAY 2016
2 _FNA NO
0 @7217@ FAM
1 HUSB @7215@
1 WIFE @6442@
1 _UST COHABITATION
1 CHIL @7219@
1 CHIL @10977@
1 MARR
2 DATE 21 SEP 2013
2 _FNA NO
0 @7188@ FAM
1 HUSB @7186@
1 WIFE @6488@
1 _UST COHABITATION
1 CHIL @7190@
1 CHIL @10940@
1 EVEN
2 TYPE Autre union officielle
2 DATE 6 JUN 2014
2 _FNA NO
0 @7199@ FAM
1 HUSB @7197@
1 WIFE @6496@
1 _UST COHABITATION
1 CHIL @7200@
1 CHIL @10950@
1 MARR
2 DATE 8 APR 2015
2 _FNA NO
0 @34737@ FAM
1 HUSB @34735@
1 WIFE @6503@
1 _UST MARRIED
1 MARR
2 DATE 23 SEP 1976
2 _FNA NO
0 @6750@ FAM
1 HUSB @6746@
1 WIFE @6511@
1 _UST MARRIED
1 CHIL @6773@
1 CHIL @6766@
1 CHIL @6759@
1 CHIL @6752@
1 MARR
2 DATE 28 APR 1961
2 PLAC Paris 07,,,,,ECOLE MILITAIRE
2 _FNA NO
0 @6661@ FAM
1 HUSB @6519@
1 WIFE @6657@
1 _UST MARRIED
1 CHIL @6663@
1 CHIL @6670@
1 CHIL @6677@
1 MARR
2 DATE 14 SEP 1973
2 _FNA NO
0 @6586@ FAM
1 HUSB @6526@
1 WIFE @6582@
1 _UST MARRIED
1 CHIL @6602@
1 CHIL @6595@
1 CHIL @6588@
1 MARR
2 DATE 28 NOV 1975
2 _FNA NO
0 @6554@ FAM
1 HUSB @6533@
1 WIFE @6550@
1 _UST MARRIED
1 CHIL @6556@
1 CHIL @6566@
1 CHIL @6574@
1 MARR
2 DATE 25 MAY 1985
2 _FNA NO
0 @7253@ FAM
1 HUSB @7251@
1 WIFE @6588@
1 _UST COHABITATION
0 @6638@ FAM
1 HUSB @6595@
1 WIFE @6634@
1 _UST MARRIED
1 CHIL @6641@
1 CHIL @6649@
1 MARR
2 DATE 29 JUN 2013
2 _FNA NO
0 @6615@ FAM
1 HUSB @6602@
1 WIFE @6611@
1 _UST MARRIED
1 CHIL @6618@
1 CHIL @6626@
1 MARR
2 DATE 5 JUL 2003
2 _FNA NO
0 @6729@ FAM
1 HUSB @6663@
1 WIFE @6725@
1 _UST MARRIED
1 CHIL @6731@
1 CHIL @6738@
1 CHIL @7239@
1 MARR
2 DATE 1 OCT 2005
2 _FNA NO
0 @6692@ FAM
1 HUSB @6670@
1 WIFE @6688@
1 _UST MARRIED
1 CHIL @6694@
1 CHIL @6701@
1 CHIL @6709@
1 CHIL @6717@
1 MARR
2 DATE 10 JUL 2004
2 _FNA NO
0 @6785@ FAM
1 HUSB @6781@
1 WIFE @6752@
1 _UST MARRIED
1 CHIL @6787@
1 CHIL @6795@
1 CHIL @6802@
1 MARR
2 DATE 21 MAY 2004
2 _FNA NO
0 @6814@ FAM
1 HUSB @6759@
1 WIFE @6810@
1 _UST MARRIED
1 CHIL @6816@
1 CHIL @7234@
1 MARR
2 DATE 22 MAY 1999
2 _FNA NO
0 @6827@ FAM
1 HUSB @6825@
1 WIFE @6766@
1 _UST MARRIED
1 CHIL @6830@
1 CHIL @6838@
1 MARR
2 DATE 17 JUN 2006
2 PLAC Paris 05,,,,,
2 _FNA NO
0 @19687@ FAM
1 HUSB @19685@
1 WIFE @6766@
1 _UST MARRIED
1 CHIL @19688@
1 CHIL @19695@
1 CHIL @19701@
0 @6850@ FAM
1 HUSB @6846@
1 WIFE @6773@
1 _UST MARRIED
1 CHIL @6852@
1 CHIL @6860@
1 CHIL @6868@
1 CHIL @6876@
1 MARR
2 DATE 6 MAY 1989
2 _FNA NO
0 @6937@ FAM
1 HUSB @6913@
1 WIFE @6922@
1 _UST MARRIED
1 CHIL @564@
0 @16500@ FAM
1 HUSB @6995@
1 WIFE @16493@
1 _UST MARRIED
1 CHIL @6991@
0 @7022@ FAM
1 HUSB @7009@
1 WIFE @7020@
1 _UST MARRIED
1 CHIL @7023@
1 CHIL @7029@
0 @7136@ FAM
1 HUSB @7134@
1 WIFE @7015@
1 _UST MARRIED
1 CHIL @7138@
1 CHIL @7144@
1 CHIL @7149@
1 CHIL @7156@
0 @7056@ FAM
1 HUSB @7054@
1 WIFE @7035@
1 _UST MARRIED
1 CHIL @7057@
1 CHIL @7063@
1 CHIL @7069@
1 CHIL @7081@
1 CHIL @7075@
0 @25157@ FAM
1 HUSB @7042@
1 _UST MARRIED
1 CHIL @25155@
1 CHIL @25160@
0 @7111@ FAM
1 HUSB @7047@
1 WIFE @7109@
1 _UST MARRIED
1 CHIL @16568@
1 CHIL @7113@
1 CHIL @7123@
1 CHIL @7118@
1 CHIL @7127@
1 MARR
2 DATE 9 JUN 1938
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @7101@ FAM
1 HUSB @7063@
1 WIFE @7099@
1 _UST MARRIED
1 CHIL @33314@
1 CHIL @33321@
0 @7107@ FAM
1 HUSB @7106@
1 WIFE @7075@
1 _UST MARRIED
1 CHIL @25177@
1 CHIL @25182@
0 @16660@ FAM
1 HUSB @7113@
1 WIFE @16658@
1 _UST MARRIED
1 CHIL @16661@
1 CHIL @16669@
0 @16684@ FAM
1 HUSB @7118@
1 WIFE @16680@
1 _UST MARRIED
1 CHIL @16685@
1 CHIL @16693@
0 @16598@ FAM
1 HUSB @16594@
1 WIFE @7127@
1 _UST MARRIED
1 CHIL @16600@
1 CHIL @16609@
1 MARR
2 DATE 2 JAN 1971
2 PLAC Ambert,63600,Puy-de-Dôme,Auvergne-Rhône-Alpes,FRANCE,
3 MAP
4 LATI N45.550000
4 LONG E3.750000
2 _FNA NO
0 @7315@ FAM
1 HUSB @7294@
1 WIFE @7311@
1 _UST MARRIED
1 CHIL @7355@
1 CHIL @7361@
1 CHIL @7366@
1 CHIL @7372@
1 CHIL @7377@
1 CHIL @447@
1 MARC
2 DATE 25 JAN 1890
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
2 NOTE Me GALIN et Me MERLIN
1 MARR
2 DATE 5 FEB 1890
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 MARR
2 TYPE Religious marriage
2 DATE 6 FEB 1890
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @7341@ FAM
1 HUSB @7333@
1 WIFE @7339@
1 _UST MARRIED
1 CHIL @7450@
1 CHIL @7294@
1 CHIL @7426@
1 MARC Maître DUMAS pred CHALAIN
2 DATE 26 MAY 1856
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 MARR
2 DATE 28 MAY 1856
2 PLAC Paris 02,,,,,
2 _FNA NO
0 @7403@ FAM
1 HUSB @7345@
1 WIFE @7401@
1 _UST MARRIED
1 CHIL @7339@
1 CHIL @8150@
1 NOTE Georgina Mackensie   rencontrera Ernest LEGOUVE par Lucile SAUVAN sa tante ,DIRECTRICE D'ETABLISSEMENT DE JEUNES FILLES dont fait partie Georgina
1 MARR
2 DATE 18 FEB 1834
2 _FNA NO
0 @7638@ FAM
1 HUSB @7361@
1 WIFE @7631@
1 _UST MARRIED
1 CHIL @7662@
1 CHIL @7675@
1 CHIL @7650@
1 CHIL @7656@
1 CHIL @7669@
1 MARC
2 DATE 27 APR 1920
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
2 NOTE Me DESTREM et THERET
1 MARR
2 DATE 12 MAY 1920
2 _FNA YES
2 NOTE OU 1922?
0 @7697@ FAM
1 HUSB @7690@
1 WIFE @7377@
1 _UST MARRIED
1 CHIL @7700@
1 CHIL @976@
1 CHIL @7708@
1 CHIL @7713@
1 MARR
2 DATE 1930
2 _FNA NO
1 MARC
2 DATE 9 OCT 1930
2 _FNA NO
2 NOTE Me RIVIERE ET FONTANA
0 @8145@ FAM
1 HUSB @7406@
1 WIFE @8138@
1 _UST MARRIED
1 CHIL @7345@
1 MARR SAINT ROCH
2 DATE 4 FEB 1803
2 PLAC Paris 01,,,,,
2 _FNA NO
2 NOTE La jeune femme à laquelle était dédié le poème du Mérite des femmes, Adèle Sauvan, mariée une première fois au célèbre chirurgien Sue, était la fille de Jean-Baptiste Sauvan, « contrôleur du mobilier des châteaux du duc d’Orléans, tant à Paris que
3 CONC dans les provinces ». La mère du poète descendait de Nicolas-René Quarente Carmeline, chirurgien ordinaire du prince de Condé, et dont il est question dans les lettres de Racine à son fils. Mais c’est la ligne des Legouvé qui est la plus curieuse à
3 CONC  suivre, et c’est là que j’ai fait, je ne dirai pas une découverte, le mot serait trop fort, mais une constatation qui m’a réjoui, qui m’a donné la certitude d’une origine que je soupçonnais, que je pressentais, et qui éclaire tant de choses.
3 CONT
0 @7437@ FAM
1 HUSB @7433@
1 WIFE @7426@
1 _UST MARRIED
1 CHIL @8001@
1 CHIL @8011@
1 MARR
2 DATE 1887
2 _FNA NO
0 @7594@ FAM
1 HUSB @7450@
1 WIFE @7583@
1 _UST MARRIED
1 MARR
2 DATE 25 OCT 1892
2 PLAC Paris 09,,,,,
2 _FNA NO
2 NOTE demeurant 33 rue de la rochefoucauld 75009
0 @7479@ FAM
1 HUSB @7459@
1 WIFE @7476@
1 _UST MARRIED
1 CHIL @7492@
1 CHIL @7333@
1 CHIL @7502@
1 CHIL @7513@
1 CHIL @7534@
1 CHIL @7544@
1 MARR
2 DATE 22 OCT 1818
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @9406@ FAM
1 HUSB @9399@
1 WIFE @7492@
1 _UST MARRIED
1 CHIL @9407@
1 CHIL @9417@
1 CHIL @9428@
1 CHIL @9438@
0 @9549@ FAM
1 HUSB @7513@
1 WIFE @9547@
1 _UST MARRIED
1 CHIL @9551@
1 CHIL @9565@
1 MARR
2 DATE 14 NOV 1867
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @9586@ FAM
1 HUSB @7544@
1 WIFE @9579@
1 _UST MARRIED
1 CHIL @9589@
1 CHIL @9607@
1 CHIL @9600@
1 CHIL @9613@
0 @7576@ FAM
1 HUSB @7555@
1 WIFE @7572@
1 _UST MARRIED
1 CHIL @7459@
1 NOTE épouse : Madeleine (ou Hélène) de Fourquier d'après l'écrivain Jean des Vallières (Au soleil de la Cavalerie-1961)
2 CONT ou épouse : Suzanne Rouveau  (selon Bruno de Corta)
2 CONT ou Dorothée ROUVEAU née en 1760=Minutes et répertoires du notaire Louis André CLAIRET, 17 mai 1782 - 25 février 1806 (étude LXVI) 23-06-1788
2 CONT Contrat de mariage entre Claude François Lefebvre Desvallières et Dorothée Rouveau.
2 CONT
1 MARR
2 DATE 1790
2 _FNA NO
2 NOTE Contrat de mariage entre Claude François Lefebvre Desvallières et Dorothée Rouveau 23-06-1788 notaire Louis André CLAIRET
0 @7722@ FAM
1 HUSB @7720@
1 WIFE @7650@
1 _UST MARRIED
1 CHIL @7811@
1 CHIL @7819@
1 CHIL @7826@
1 CHIL @7833@
1 CHIL @7841@
1 CHIL @7849@
1 CHIL @7856@
1 CHIL @7864@
0 @7738@ FAM
1 HUSB @7733@
1 WIFE @7656@
1 _UST MARRIED
1 CHIL @7739@
1 CHIL @7747@
1 CHIL @7756@
0 @7731@ FAM
1 HUSB @7729@
1 WIFE @7662@
1 _UST MARRIED
1 CHIL @11017@
1 MARR
2 DATE 5 JUL 1951
2 PLAC Paris 02,,,,,
2 _FNA NO
0 @7727@ FAM
1 HUSB @7675@
1 WIFE @7725@
1 _UST MARRIED
1 MARR
2 DATE 25 JUL 1958
2 PLAC Saint-Sylvestre,87240,Haute-Vienne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.995290
4 LONG E1.377530
2 _FNA NO
0 @8374@ FAM
1 HUSB @8373@
1 WIFE @7713@
1 _UST MARRIED
1 CHIL @11094@
1 CHIL @11097@
1 MARR
2 DATE 11 OCT 1976
2 PLAC Sanroque,,,,ESPAGNE,
2 _FNA NO
2 NOTE CADIX
0 @7767@ FAM
1 HUSB @7739@
1 WIFE @7765@
1 _UST MARRIED
1 CHIL @7769@
1 CHIL @7774@
0 @7783@ FAM
1 HUSB @7781@
1 WIFE @7747@
1 _UST MARRIED
1 CHIL @7785@
1 CHIL @7791@
0 @7799@ FAM
1 HUSB @7756@
1 WIFE @7797@
1 _UST MARRIED
1 CHIL @7801@
1 CHIL @7806@
0 @7875@ FAM
1 HUSB @7811@
1 WIFE @7873@
1 _UST MARRIED
1 CHIL @7877@
1 CHIL @7883@
1 CHIL @7888@
0 @7895@ FAM
1 HUSB @7893@
1 WIFE @7819@
1 _UST MARRIED
1 CHIL @4131@
1 CHIL @4098@
1 CHIL @7898@
1 CHIL @7903@
1 CHIL @7909@
1 CHIL @7914@
1 CHIL @7919@
1 CHIL @7930@
1 CHIL @7936@
1 CHIL @7941@
1 CHIL @7946@
1 CHIL @35247@
1 MARR
2 DATE 14 MAR 1970
2 PLAC Bonnelles,78830,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.618160
4 LONG E2.029220
2 _FNA NO
0 @7956@ FAM
1 HUSB @7952@
1 WIFE @7826@
1 _UST MARRIED
0 @7960@ FAM
1 HUSB @7958@
1 WIFE @7833@
1 _UST MARRIED
0 @7966@ FAM
1 HUSB @7841@
1 WIFE @7964@
1 _UST MARRIED
1 CHIL @7967@
0 @7976@ FAM
1 HUSB @7849@
1 WIFE @7974@
1 _UST MARRIED
1 CHIL @7977@
1 CHIL @7982@
0 @7991@ FAM
1 HUSB @7989@
1 WIFE @7864@
1 _UST MARRIED
0 @35105@ FAM
1 HUSB @35103@
1 WIFE @7898@
1 _UST MARRIED
1 CHIL @35106@
1 CHIL @35111@
0 @35119@ FAM
1 HUSB @7903@
1 WIFE @35117@
1 _UST MARRIED
1 CHIL @35120@
1 CHIL @35125@
1 CHIL @35131@
1 CHIL @35136@
0 @35144@ FAM
1 HUSB @35142@
1 WIFE @7914@
1 _UST MARRIED
1 CHIL @35145@
1 CHIL @35150@
1 CHIL @35155@
0 @35162@ FAM
1 HUSB @35160@
1 WIFE @7930@
1 _UST MARRIED
1 CHIL @35163@
1 CHIL @35168@
1 CHIL @35173@
1 CHIL @35178@
0 @35187@ FAM
1 HUSB @35185@
1 WIFE @7936@
1 _UST MARRIED
1 CHIL @35188@
1 CHIL @35193@
1 CHIL @35198@
0 @8027@ FAM
1 HUSB @8011@
1 WIFE @8023@
1 _UST MARRIED
1 CHIL @8029@
1 CHIL @8034@
1 CHIL @8040@
1 MARR
2 DATE 21 FEB 1914
2 PLAC Ancretteville-sur-Mer,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.788420
4 LONG E0.508420
2 _FNA NO
2 NOTE ou 14 janvier 1914
0 @8057@ FAM
1 HUSB @8040@
1 WIFE @8055@
1 _UST MARRIED
0 @8114@ FAM
1 HUSB @8112@
1 WIFE @8105@
1 _UST MARRIED
1 CHIL @7406@
1 MARR SAINT MERRY
2 DATE 19 SEP 1763
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @8264@ FAM
1 HUSB @8262@
1 WIFE @8105@
1 _UST MARRIED
1 MARR SAINT PHILIPPE DU ROULE
2 DATE 17 MAY 1785
2 PLAC Paris 08,,,,,
2 _FNA NO
0 @19871@ FAM
1 HUSB @19868@
1 WIFE @8138@
1 _UST DIVORCED
1 CHIL @19991@
1 MARR
2 DATE 28 DEC 1794
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @19878@ FAM
1 HUSB @19876@
1 WIFE @8138@
1 _UST ENGAGEMENT
1 EVEN fiancé
2 TYPE Autre union officielle
2 DATE 1793
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @8429@ FAM
1 HUSB @8422@
1 WIFE @8427@
1 _UST MARRIED
1 CHIL @16803@
1 CHIL @16810@
1 CHIL @2628@
1 CHIL @16817@
0 @8539@ FAM
1 HUSB @8527@
1 WIFE @8536@
1 _UST MARRIED
1 CHIL @2175@
0 @8821@ FAM
1 HUSB @8604@
1 WIFE @8810@
1 _UST MARRIED
1 CHIL @6128@
0 @8628@ FAM
1 HUSB @8616@
1 WIFE @8625@
1 _UST MARRIED
1 CHIL @11785@
1 CHIL @11795@
1 CHIL @8637@
1 CHIL @8604@
1 CHIL @11775@
1 CHIL @11801@
1 CHIL @11807@
1 CHIL @11813@
1 CHIL @11819@
0 @13049@ FAM
1 HUSB @8616@
1 WIFE @13043@
1 _UST MARRIED
1 CHIL @13052@
1 MARR
2 DATE 26 JUL 1791
2 PLAC Saint-Denis-du-Pin,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.983330
4 LONG W0.516670
2 _FNA NO
1 MARR
2 DATE 1795
2 _FNA NO
0 @8680@ FAM
1 HUSB @8675@
1 WIFE @8670@
1 _UST MARRIED
1 CHIL @11656@
1 CHIL @11667@
1 CHIL @11677@
1 CHIL @11687@
1 MARR
2 DATE 4 FEB 1823
2 PLAC Migré,17330,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.074000
4 LONG W0.557390
2 _FNA NO
0 @8718@ FAM
1 HUSB @8699@
1 WIFE @8710@
1 _UST MARRIED
1 CHIL @13254@
1 CHIL @13276@
1 CHIL @6139@
0 @8800@ FAM
1 HUSB @8787@
1 WIFE @8796@
1 _UST MARRIED
1 CHIL @6161@
0 @8858@ FAM
1 HUSB @8825@
1 WIFE @8854@
1 _UST MARRIED
1 CHIL @8810@
1 CHIL @8907@
1 MARC
2 DATE 5 NOV 1792
2 PLAC La Rochelle,17000,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.166670
4 LONG W1.150000
2 _FNA NO
2 NOTE Me DAVIAUD
0 @8880@ FAM
1 HUSB @8864@
1 WIFE @8877@
1 _UST MARRIED
1 CHIL @8825@
0 @8924@ FAM
1 HUSB @8918@
1 WIFE @8907@
1 _UST MARRIED
0 @8996@ FAM
1 HUSB @8984@
1 WIFE @8992@
1 _UST MARRIED
1 CHIL @8999@
1 CHIL @216@
1 CHIL @9004@
0 @32281@ FAM
1 HUSB @8999@
1 WIFE @32279@
1 _UST DIVORCED
1 CHIL @32282@
1 CHIL @32287@
0 @32257@ FAM
1 HUSB @9004@
1 WIFE @32255@
1 _UST MARRIED
1 CHIL @32229@
1 CHIL @32237@
1 CHIL @32241@
1 CHIL @32248@
1 MARR
2 DATE 29 SEP 2012
2 _FNA NO
0 @9023@ FAM
1 HUSB @9016@
1 WIFE @9021@
1 _UST MARRIED
1 CHIL @225@
1 CHIL @9026@
1 CHIL @9031@
1 CHIL @9036@
1 CHIL @9041@
0 @10178@ FAM
1 HUSB @10176@
1 WIFE @9119@
1 _UST MARRIED
0 @9137@ FAM
1 HUSB @9126@
1 WIFE @9135@
1 _UST MARRIED
1 CHIL @235@
1 CHIL @32581@
1 CHIL @32591@
1 CHIL @32596@
1 MARR
2 DATE 10 APR 1976
2 PLAC Corcelles-les-Monts,21160,Côte-d'Or,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.294450
4 LONG E4.939110
2 _FNA NO
0 @9192@ FAM
1 HUSB @9183@
1 WIFE @9190@
1 _UST MARRIED
1 CHIL @6885@
1 CHIL @19644@
1 CHIL @19649@
0 @9199@ FAM
1 HUSB @9196@
1 _UST MARRIED
1 CHIL @9183@
0 @9318@ FAM
1 HUSB @9208@
1 WIFE @9316@
1 _UST MARRIED
1 CHIL @7401@
0 @9324@ FAM
1 HUSB @9322@
1 WIFE @9316@
1 _UST MARRIED
1 CHIL @9325@
1 CHIL @9331@
0 @19955@ FAM
1 HUSB @9341@
1 WIFE @19952@
1 _UST MARRIED
1 CHIL @20349@
1 CHIL @8138@
1 CHIL @20295@
1 CHIL @20307@
1 CHIL @20318@
1 CHIL @20326@
1 CHIL @9347@
1 CHIL @21205@
1 MARR NOTRE-DAME
2 DATE 20 APR 1771
2 PLAC Versailles,78000,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.800000
4 LONG E2.133330
2 _FNA NO
0 @33728@ FAM
1 HUSB @9377@
1 WIFE @33720@
1 _UST MARRIED
1 CHIL @7311@
1 CHIL @9382@
1 CHIL @31613@
1 MARC
2 DATE 10 FEB 1868
2 PLAC Paris 01,,,,,Maitre THOUARD
2 _FNA NO
1 MARR
2 DATE 18 FEB 1868
2 PLAC Paris 02,75002,Seine,Ile de France,FRANCE,
2 _FNA NO
2 NOTE Commune :
3 CONT Paris
3 CONT Arrondissement :
3 CONT 2e
3 CONT Type acte :
3 CONT Registre des mariages
3 CONT Cote registre :
3 CONT AD075EC_V4E_00178
3 CONT Numero acte :
3 CONT 121
3 CONT Jour :
3 CONT 18
3 CONT Mois :
3 CONT 02
3 CONT Année :
3 CONT 1868
3 CONT Nom époux :
3 CONT LEFEBVRE
3 CONT Prénom époux :
3 CONT Joseph Emile
3 CONT Origine époux :
3 CONT Paris 2e
3 CONT Nom père époux :
3 CONT LEFEBVRE
3 CONT Prénom père époux :
3 CONT Pierre François Désiré
3 CONT Nom mère époux :
3 CONT MEDARD
3 CONT Prénom mère époux :
3 CONT Marie Sabine
3 CONT Nom épouse :
3 CONT CHAVIGNY
3 CONT Prénom épouse :
3 CONT Radegonde Honorine Elisa
3 CONT Origine épouse :
3 CONT Vienne
3 CONT Nom père épouse :
3 CONT CHAVIGNY
3 CONT Prénom père épouse :
3 CONT Pierre
3 CONT Nom mère épouse :
3 CONT HUBLET
3 CONT Prénom mère épouse :
3 CONT Marie Anne
3 CONT
0 @9390@ FAM
1 HUSB @9388@
1 WIFE @9382@
1 _UST MARRIED
1 CHIL @10006@
1 CHIL @10011@
1 MARR
2 DATE 19 NOV 1903
2 PLAC Seine-Port,77240,Seine-et-Marne,Île-de-France,FRANCE,
3 MAP
4 LATI N48.557430
4 LONG E2.553160
2 _FNA NO
0 @9515@ FAM
1 HUSB @9508@
1 WIFE @9417@
1 _UST MARRIED
1 CHIL @9516@
1 CHIL @9526@
1 CHIL @9536@
0 @9487@ FAM
1 HUSB @9428@
1 WIFE @9480@
1 _UST MARRIED
1 CHIL @9488@
1 CHIL @9499@
0 @9453@ FAM
1 HUSB @9438@
1 WIFE @9449@
1 _UST MARRIED
1 CHIL @9454@
1 CHIL @9461@
1 NOTE cousin germain de sa belle-sœur Dolorès Lefebvre-Desvallières.
2 CONT L'union figure dans une notice de l'Annuaire de la noblesse de France et d'Europe de 1886.
1 MARR
2 DATE 15 NOV 1884
2 PLAC Paris 09,,,,,
2 _FNA NO
1 MARR
2 TYPE Religious marriage
2 DATE 17 NOV 1884
2 PLAC Paris 09,,,,,NOTRE DAME DE LORETTE
2 _FNA NO
0 @9471@ FAM
1 HUSB @9469@
1 WIFE @9461@
1 _UST MARRIED
1 CHIL @9472@
1 MARR
2 DATE 1914
2 _FNA NO
0 @9686@ FAM
1 HUSB @9551@
1 WIFE @9536@
1 _UST MARRIED
1 CHIL @9687@
1 CHIL @9698@
1 CHIL @9708@
1 MARR
2 DATE 1893
2 _FNA NO
0 @9627@ FAM
1 HUSB @9620@
1 WIFE @9589@
1 _UST MARRIED
1 CHIL @9628@
1 CHIL @9638@
0 @9656@ FAM
1 HUSB @9654@
1 WIFE @9600@
1 _UST MARRIED
1 CHIL @9657@
1 CHIL @9662@
1 CHIL @9667@
1 CHIL @9672@
0 @9679@ FAM
1 HUSB @9607@
1 _UST MARRIED
1 CHIL @9677@
1 CHIL @9682@
0 @9745@ FAM
1 HUSB @9687@
1 WIFE @9738@
1 _UST MARRIED
1 CHIL @31310@
1 CHIL @31315@
0 @9736@ FAM
1 HUSB @9729@
1 WIFE @9698@
1 _UST MARRIED
1 MARR
2 DATE 8 FEB 1922
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @9727@ FAM
1 HUSB @9708@
1 WIFE @9720@
1 _UST MARRIED
0 @9803@ FAM
1 HUSB @9787@
1 WIFE @9801@
1 _UST MARRIED
1 CHIL @9804@
1 CHIL @9811@
0 @9839@ FAM
1 HUSB @9831@
1 WIFE @9804@
1 _UST MARRIED
0 @9824@ FAM
1 HUSB @9820@
1 WIFE @9811@
1 _UST MARRIED
1 CHIL @9825@
0 @10079@ FAM
1 HUSB @9853@
1 WIFE @10077@
1 _UST MARRIED
1 CHIL @7631@
1 CHIL @31660@
1 CHIL @31668@
1 CHIL @31676@
1 CHIL @31684@
1 CHIL @31690@
0 @9871@ FAM
1 HUSB @9858@
1 WIFE @9864@
1 _UST MARRIED
1 CHIL @9853@
1 CHIL @10059@
0 @9875@ FAM
1 HUSB @9874@
1 _UST MARRIED
1 CHIL @9878@
1 CHIL @9864@
0 @9894@ FAM
1 HUSB @9878@
1 _UST MARRIED
1 CHIL @9887@
0 @10000@ FAM
1 HUSB @9983@
1 WIFE @9997@
1 _UST MARRIED
0 @10056@ FAM
1 HUSB @10053@
1 _UST MARRIED
1 CHIL @9858@
0 @10101@ FAM
1 HUSB @10085@
1 WIFE @10099@
1 _UST MARRIED
0 @11012@ FAM
1 HUSB @10105@
1 WIFE @11010@
1 _UST MARRIED
1 MARR
2 DATE APR 1890
2 _FNA NO
0 @10137@ FAM
1 HUSB @10118@
1 WIFE @10130@
1 _UST MARRIED
1 CHIL @10140@
1 CHIL @10150@
1 CHIL @10160@
1 MARR
2 DATE 1895
2 _FNA NO
0 @10225@ FAM
1 HUSB @10217@
1 WIFE @10223@
1 _UST MARRIED
1 CHIL @8112@
0 @10252@ FAM
1 HUSB @10242@
1 WIFE @10249@
1 _UST MARRIED
1 CHIL @8105@
0 @10270@ FAM
1 HUSB @10260@
1 WIFE @10268@
1 _UST MARRIED
1 CHIL @8262@
1 MARR
2 DATE 18 JUN 1754
2 PLAC Machecoul,44270,Loire-Atlantique,Pays de la Loire,FRANCE,
3 MAP
4 LATI N46.993580
4 LONG W1.823520
2 _FNA NO
0 @10430@ FAM
1 HUSB @10421@
1 WIFE @10428@
1 _UST MARRIED
1 CHIL @32123@
1 CHIL @399@
0 @10604@ FAM
1 HUSB @10591@
1 WIFE @10602@
1 _UST MARRIED
1 CHIL @10507@
0 @10705@ FAM
1 HUSB @10703@
1 WIFE @10691@
1 _UST MARRIED
1 CHIL @10706@
0 @10736@ FAM
1 HUSB @10728@
1 WIFE @10734@
1 _UST MARRIED
1 CHIL @6114@
1 CHIL @10784@
1 CHIL @10789@
1 MARR
2 DATE 6 SEP 1858
2 PLAC Charleville-Mézières,08000,Ardennes,Grand Est,FRANCE,
3 MAP
4 LATI N49.766670
4 LONG E4.716670
2 _FNA NO
0 @25555@ FAM
1 HUSB @10728@
1 WIFE @25553@
1 _UST MARRIED
1 CHIL @12756@
1 MARR
2 DATE 3 FEB 1879
2 PLAC Nîmes,30000,Gard,Occitanie,FRANCE,
3 MAP
4 LATI N43.833330
4 LONG E4.350000
2 _FNA NO
0 @12771@ FAM
1 HUSB @10740@
1 WIFE @12769@
1 _UST MARRIED
1 CHIL @10734@
1 CHIL @30112@
1 MARR
2 DATE 22 DEC 1830
2 PLAC Charleville-Mézières,08000,Ardennes,Grand Est,FRANCE,
3 MAP
4 LATI N49.766670
4 LONG E4.716670
2 _FNA NO
0 @11744@ FAM
1 HUSB @10755@
1 WIFE @11737@
1 _UST MARRIED
0 @10920@ FAM
1 HUSB @10900@
1 WIFE @10916@
1 _UST MARRIED
1 CHIL @10923@
1 CHIL @10930@
1 MARR
2 DATE 28 APR 2012
2 _FNA NO
0 @11025@ FAM
1 HUSB @11023@
1 WIFE @11017@
1 _UST MARRIED
1 CHIL @11026@
0 @11037@ FAM
1 HUSB @11026@
1 WIFE @11033@
1 _UST MARRIED
1 CHIL @11038@
0 @11209@ FAM
1 HUSB @11207@
1 _UST MARRIED
1 CHIL @4584@
0 @11371@ FAM
1 HUSB @11217@
1 WIFE @11368@
1 _UST MARRIED
1 CHIL @11372@
1 CHIL @11377@
1 CHIL @36781@
1 CHIL @36789@
0 @11353@ FAM
1 HUSB @11222@
1 WIFE @11351@
1 _UST MARRIED
1 CHIL @11355@
1 CHIL @11360@
0 @11382@ FAM
1 HUSB @11381@
1 WIFE @11231@
1 _UST MARRIED
0 @11253@ FAM
1 HUSB @11251@
1 WIFE @11242@
1 _UST MARRIED
1 CHIL @11254@
0 @30965@ FAM
1 HUSB @11247@
1 WIFE @30961@
1 _UST MARRIED
1 CHIL @30966@
0 @11267@ FAM
1 HUSB @11265@
1 WIFE @11254@
1 _UST MARRIED
1 CHIL @11269@
1 CHIL @11275@
0 @11287@ FAM
1 HUSB @11280@
1 WIFE @11285@
1 _UST MARRIED
1 CHIL @11265@
0 @11955@ FAM
1 HUSB @11300@
1 WIFE @11954@
1 _UST MARRIED
1 CHIL @680@
1 CHIL @35450@
0 @11325@ FAM
1 HUSB @11323@
1 _UST MARRIED
1 CHIL @9190@
0 @11338@ FAM
1 HUSB @11336@
1 _UST MARRIED
1 CHIL @11332@
0 @11403@ FAM
1 HUSB @11392@
1 WIFE @11401@
1 _UST MARRIED
1 CHIL @11404@
0 @11414@ FAM
1 HUSB @11412@
1 WIFE @11397@
1 _UST MARRIED
1 CHIL @11415@
1 CHIL @11421@
1 CHIL @11427@
1 CHIL @11433@
1 CHIL @11438@
1 CHIL @35699@
0 @11444@ FAM
1 HUSB @11433@
1 WIFE @11442@
1 _UST MARRIED
1 CHIL @11446@
1 MARR
2 DATE 15 SEP 2013
2 PLAC Saint-Pierre-en-Port,76540,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.805760
4 LONG E0.497440
2 _FNA NO
0 @11467@ FAM
1 HUSB @11451@
1 WIFE @11460@
1 _UST MARRIED
1 CHIL @1090@
1 CHIL @11474@
1 CHIL @11489@
1 CHIL @11482@
1 CHIL @12174@
1 CHIL @14858@
1 CHIL @11496@
1 CHIL @11508@
1 CHIL @11522@
1 CHIL @11515@
1 NOTE François de Wendel (1778-1825) et Joséphine de FISCHER de DICOURT sont les arrière-arrière-grand-parents communs de marguerite de wendel et d’emmanuel de mitry
1 MARR
2 DATE 2 MAR 1926
2 PLAC Paris 09,,,,,LA TRINITE
2 _FNA NO
0 @11537@ FAM
1 HUSB @11535@
1 WIFE @11474@
1 _UST MARRIED
1 CHIL @11538@
1 CHIL @11545@
1 CHIL @14772@
1 CHIL @11552@
1 CHIL @11559@
1 CHIL @14785@
1 MARR
2 DATE 2 JUL 1954
2 PLAC Paris 09,,,,,TRINITE
2 _FNA NO
0 @14810@ FAM
1 HUSB @14806@
1 WIFE @11489@
1 _UST MARRIED
1 CHIL @13837@
1 MARR
2 DATE 1968
2 _FNA NO
0 @13821@ FAM
1 HUSB @13820@
1 WIFE @11496@
1 _UST DIVORCED
1 CHIL @13823@
1 MARR
2 DATE 1963
2 _FNA NO
0 @14880@ FAM
1 HUSB @11508@
1 WIFE @14878@
1 _UST DIVORCED
1 CHIL @14881@
1 MARR
2 DATE 1962
2 _FNA NO
0 @14891@ FAM
1 HUSB @11508@
1 WIFE @14889@
1 _UST MARRIED
1 CHIL @14893@
1 MARR
2 DATE 1973
2 _FNA NO
0 @14921@ FAM
1 HUSB @14919@
1 WIFE @11515@
1 _UST MARRIED
1 CHIL @14922@
1 CHIL @14929@
1 CHIL @14937@
0 @14901@ FAM
1 HUSB @11531@
1 WIFE @11522@
1 _UST MARRIED
1 CHIL @14902@
1 CHIL @14909@
0 @19081@ FAM
1 HUSB @19079@
1 WIFE @11522@
1 _UST DIVORCED
1 MARR
2 DATE 1969
2 _FNA NO
0 @14737@ FAM
1 HUSB @11538@
1 WIFE @14735@
1 _UST MARRIED
1 CHIL @14738@
1 CHIL @14745@
1 MARR
2 DATE 1979
2 _FNA NO
0 @14756@ FAM
1 HUSB @11545@
1 WIFE @14754@
1 _UST MARRIED
1 CHIL @14757@
1 CHIL @14764@
0 @14783@ FAM
1 HUSB @11552@
1 WIFE @14781@
1 _UST MARRIED
0 @11575@ FAM
1 HUSB @11566@
1 WIFE @11573@
1 _UST MARRIED
1 CHIL @23672@
1 CHIL @990@
1 CHIL @7893@
1 CHIL @23691@
1 CHIL @1017@
1 MARR
2 DATE 4 JUN 1935
2 PLAC Paris 08,,,,,
2 _FNA NO
0 @11629@ FAM
1 HUSB @11615@
1 WIFE @11627@
1 _UST MARRIED
1 CHIL @8675@
0 @11648@ FAM
1 HUSB @11636@
1 WIFE @11644@
1 _UST MARRIED
1 CHIL @8670@
0 @11709@ FAM
1 HUSB @11703@
1 WIFE @11687@
1 _UST MARRIED
1 MARR
2 DATE 11 NOV 1850
2 _FNA NO
0 @12932@ FAM
1 HUSB @12928@
1 WIFE @11795@
1 _UST MARRIED
1 MARR
2 DATE 16 JUL 1822
2 PLAC Saint-Denis-du-Pin,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.983330
4 LONG W0.516670
2 _FNA NO
0 @13020@ FAM
1 HUSB @11813@
1 WIFE @13015@
1 _UST MARRIED
1 MARR
2 PLAC Saint-Denis-du-Pin,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.983330
4 LONG W0.516670
2 _FNA NO
0 @11863@ FAM
1 HUSB @11857@
1 WIFE @11861@
1 _UST MARRIED
1 CHIL @5005@
0 @11879@ FAM
1 HUSB @11866@
1 WIFE @11876@
1 _UST MARRIED
1 CHIL @11857@
0 @11883@ FAM
1 HUSB @11881@
1 _UST MARRIED
1 CHIL @11876@
0 @11886@ FAM
1 HUSB @11885@
1 _UST MARRIED
1 CHIL @11881@
1 CHIL @11888@
0 @11892@ FAM
1 HUSB @11891@
1 _UST MARRIED
1 CHIL @11861@
1 CHIL @11894@
0 @11907@ FAM
1 HUSB @11898@
1 WIFE @11894@
1 _UST MARRIED
0 @11909@ FAM
1 HUSB @11908@
1 _UST MARRIED
1 CHIL @11300@
1 CHIL @11922@
0 @11920@ FAM
1 HUSB @11912@
1 _UST MARRIED
1 CHIL @11908@
0 @11931@ FAM
1 HUSB @11922@
1 WIFE @11927@
1 _UST MARRIED
1 CHIL @11932@
1 CHIL @11936@
1 CHIL @11940@
0 @11952@ FAM
1 HUSB @11922@
1 WIFE @11948@
1 _UST MARRIED
0 @11968@ FAM
1 HUSB @11957@
1 _UST MARRIED
1 CHIL @11954@
0 @11984@ FAM
1 HUSB @11975@
1 WIFE @11982@
1 _UST MARRIED
1 CHIL @5636@
1 CHIL @11988@
1 CHIL @11994@
1 CHIL @12000@
0 @12031@ FAM
1 HUSB @12013@
1 WIFE @12024@
1 _UST MARRIED
1 CHIL @11975@
0 @12100@ FAM
1 HUSB @12038@
1 WIFE @12096@
1 _UST MARRIED
1 CHIL @12102@
1 MARR
2 DATE 11 MAR 1927
2 PLAC Paris 08,,,,,
2 _FNA NO
2 NOTE ou 21? OU 22?
1 MARR
2 TYPE Religious marriage
2 DATE 22 MAR 1927
2 PLAC Paris 16,,,,,SAINT PIERRE DE CHAILLOT
2 _FNA NO
0 @12070@ FAM
1 HUSB @12052@
1 WIFE @12063@
1 _UST MARRIED
1 CHIL @11982@
0 @12153@ FAM
1 HUSB @12138@
1 WIFE @12151@
1 _UST MARRIED
1 CHIL @11460@
1 CHIL @13856@
1 CHIL @13863@
1 CHIL @14162@
0 @12158@ FAM
1 HUSB @12156@
1 _UST MARRIED
1 CHIL @12151@
0 @12164@ FAM
1 HUSB @12161@
1 _UST MARRIED
1 CHIL @12156@
0 @12170@ FAM
1 HUSB @12167@
1 _UST MARRIED
1 CHIL @12161@
0 @12186@ FAM
1 HUSB @12182@
1 WIFE @12174@
1 _UST MARRIED
1 CHIL @13845@
1 CHIL @13828@
1 CHIL @13842@
1 MARR
2 DATE APR 1959
2 PLAC Paris 09,,,,,TRINITE
2 _FNA NO
0 @12203@ FAM
1 HUSB @12194@
1 WIFE @12201@
1 _UST MARRIED
1 CHIL @15028@
1 CHIL @15038@
1 CHIL @15048@
1 CHIL @15058@
1 CHIL @11451@
1 CHIL @15068@
1 MARR
2 DATE 7 JAN 1883
2 PLAC Luxembourg,,,Luxembourg,LUXEMBOURG,
3 MAP
4 LATI N49.611670
4 LONG E6.130000
2 _FNA NO
0 @12208@ FAM
1 HUSB @12206@
1 _UST MARRIED
1 CHIL @12211@
0 @14966@ FAM
1 HUSB @12206@
1 WIFE @14959@
1 _UST MARRIED
1 CHIL @12201@
1 CHIL @14977@
1 CHIL @14987@
1 CHIL @17477@
1 CHIL @14997@
1 CHIL @17490@
1 CHIL @17649@
1 CHIL @15008@
1 CHIL @15018@
1 MARR
2 DATE 1859
2 _FNA NO
0 @12226@ FAM
1 HUSB @12221@
1 WIFE @12215@
1 _UST MARRIED
0 @12252@ FAM
1 HUSB @12237@
1 WIFE @12249@
1 _UST MARRIED
1 CHIL @5724@
1 CHIL @12255@
0 @12262@ FAM
1 HUSB @12255@
1 WIFE @12260@
1 _UST MARRIED
0 @12272@ FAM
1 HUSB @12264@
1 WIFE @12270@
1 _UST MARRIED
1 CHIL @6393@
0 @12295@ FAM
1 HUSB @12291@
1 _UST MARRIED
1 CHIL @12264@
0 @12316@ FAM
1 HUSB @12300@
1 WIFE @12313@
1 _UST MARRIED
1 CHIL @6382@
0 @12341@ FAM
1 HUSB @12321@
1 WIFE @12339@
1 _UST MARRIED
1 CHIL @6221@
0 @12363@ FAM
1 HUSB @12344@
1 WIFE @12356@
1 _UST MARRIED
1 CHIL @12321@
0 @12383@ FAM
1 HUSB @12367@
1 WIFE @12380@
1 _UST MARRIED
1 CHIL @12344@
0 @12393@ FAM
1 HUSB @12386@
1 _UST MARRIED
1 CHIL @12367@
0 @12427@ FAM
1 HUSB @12398@
1 WIFE @12416@
1 _UST MARRIED
1 CHIL @4805@
0 @12412@ FAM
1 HUSB @12405@
1 WIFE @12410@
1 _UST MARRIED
1 CHIL @12398@
0 @12440@ FAM
1 HUSB @12438@
1 WIFE @12416@
1 _UST MARRIED
1 MARR
2 DATE 1899
2 _FNA NO
0 @12460@ FAM
1 HUSB @12451@
1 WIFE @12458@
1 _UST MARRIED
1 CHIL @12416@
0 @12492@ FAM
1 HUSB @12467@
1 WIFE @12481@
1 _UST DIVORCED
1 CHIL @12519@
1 CHIL @12530@
1 CHIL @6233@
0 @12555@ FAM
1 HUSB @12543@
1 WIFE @12481@
1 _UST MARRIED
0 @12583@ FAM
1 HUSB @12557@
1 WIFE @12572@
1 _UST MARRIED
1 CHIL @12481@
0 @12621@ FAM
1 HUSB @12588@
1 WIFE @12614@
1 _UST MARRIED
1 CHIL @12467@
1 MARR
2 DATE 1849
2 PLAC Castres,02680,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.803610
4 LONG E3.239090
2 _FNA NO
0 @12674@ FAM
1 HUSB @12630@
1 WIFE @12659@
1 _UST MARRIED
1 CHIL @6244@
0 @12698@ FAM
1 HUSB @12681@
1 WIFE @12694@
1 _UST MARRIED
1 CHIL @12659@
0 @12714@ FAM
1 HUSB @12703@
1 _UST MARRIED
1 CHIL @12694@
0 @12798@ FAM
1 HUSB @12782@
1 WIFE @12796@
1 _UST MARRIED
1 CHIL @25738@
1 CHIL @10728@
1 MARR
2 DATE 17 JUL 1827
3 TIME 11:00
2 PLAC Tourcoing,59200,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.723910
4 LONG E3.161170
2 _FNA NO
0 @12816@ FAM
1 HUSB @12802@
1 WIFE @12809@
1 _UST MARRIED
1 CHIL @12782@
1 CHIL @25770@
1 NOTE COUSINS GERMAINS
2 CONT « dispense de PIE VI de 3 et 4 degrés
2 CONT 8 ENFANTS DONT 2 MORTS-NES= NE RESTENT QUE 2 VIVANTS
1 MARC
2 DATE 27 JUL 1778
2 _FNA NO
2 NOTE maître PRISSETTE Nicolas Joseph
1 MARR
2 DATE 11 AUG 1778
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 MARR REMARIAGE AVEC 1°FEMME
2 DATE 2 AUG 1805
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
0 @12834@ FAM
1 HUSB @12820@
1 WIFE @12832@
1 _UST MARRIED
1 CHIL @25989@
1 CHIL @12802@
1 MARR
2 DATE 7 AUG 1752
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
0 @12845@ FAM
1 HUSB @12837@
1 WIFE @12843@
1 _UST MARRIED
1 CHIL @26248@
1 CHIL @26258@
1 CHIL @26275@
1 CHIL @12832@
1 CHIL @26224@
1 CHIL @26229@
1 CHIL @26235@
1 CHIL @26241@
1 CHIL @26270@
1 MARR
2 DATE 25 OCT 1694
2 PLAC Étroeungt,59219,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.050000
4 LONG E3.933330
2 _FNA NO
0 @25907@ FAM
1 HUSB @12854@
1 WIFE @25905@
1 _UST MARRIED
1 CHIL @29838@
1 CHIL @29639@
1 CHIL @29868@
1 CHIL @29885@
1 CHIL @29605@
1 CHIL @29927@
1 CHIL @29954@
1 CHIL @12859@
1 CHIL @25995@
1 CHIL @26032@
1 NOTE 14 ENFANTS= 7XX+7XY
1 MARR
2 DATE 1715
2 _FNA NO
0 @25854@ FAM
1 HUSB @12859@
1 WIFE @25851@
1 _UST MARRIED
1 CHIL @12809@
1 CHIL @30017@
1 MARR
2 DATE 20 OCT 1755
2 PLAC Caudry,59540,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.124900
4 LONG E3.411860
2 _FNA NO
0 @12950@ FAM
1 HUSB @12935@
1 WIFE @12947@
1 _UST MARRIED
1 CHIL @12928@
0 @13112@ FAM
1 HUSB @12935@
1 WIFE @13105@
1 _UST MARRIED
1 CHIL @11840@
0 @13099@ FAM
1 HUSB @13086@
1 WIFE @13097@
1 _UST MARRIED
1 CHIL @13114@
1 CHIL @8625@
1 CHIL @13105@
0 @13127@ FAM
1 HUSB @13124@
1 WIFE @13114@
1 _UST MARRIED
0 @13165@ FAM
1 HUSB @13155@
1 WIFE @13161@
1 _UST MARRIED
1 CHIL @8616@
1 MARR
2 DATE 31 MAR 1761
2 PLAC Saint-Pierre-de-l'Île,17330,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.032500
4 LONG W0.430300
2 _FNA NO
0 @13181@ FAM
1 HUSB @13173@
1 WIFE @13179@
1 _UST MARRIED
1 CHIL @13161@
0 @13194@ FAM
1 HUSB @13185@
1 WIFE @13192@
1 _UST MARRIED
1 CHIL @13155@
1 MARR
2 DATE 10 JAN 1724
2 _FNA NO
0 @13209@ FAM
1 HUSB @13198@
1 WIFE @13205@
1 _UST MARRIED
1 CHIL @13185@
0 @13222@ FAM
1 HUSB @13212@
1 WIFE @13220@
1 _UST MARRIED
1 CHIL @13205@
0 @13239@ FAM
1 HUSB @13226@
1 WIFE @13237@
1 _UST MARRIED
1 CHIL @13198@
1 MARR
2 DATE 1636
2 PLAC Saint-Jean-d'Angély,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.950000
4 LONG W0.516670
2 _FNA NO
0 @13251@ FAM
1 HUSB @13243@
1 WIFE @13249@
1 _UST MARRIED
1 CHIL @13226@
0 @13288@ FAM
1 HUSB @13276@
1 WIFE @13286@
1 _UST MARRIED
1 CHIL @13295@
1 CHIL @13306@
1 CHIL @13336@
1 CHIL @13344@
1 MARR
2 DATE 21 FEB 1854
2 PLAC Saint-Denis-du-Pin,17400,Charente-Maritime,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.983330
4 LONG W0.516670
2 _FNA NO
0 @13324@ FAM
1 HUSB @13322@
1 WIFE @13306@
1 _UST MARRIED
1 MARR
2 DATE 21 JUN 1880
2 _FNA NO
0 @13333@ FAM
1 HUSB @13326@
1 WIFE @13331@
1 _UST MARRIED
1 CHIL @13322@
0 @13369@ FAM
1 HUSB @13351@
1 WIFE @13362@
1 _UST MARRIED
1 CHIL @8699@
0 @13389@ FAM
1 HUSB @13372@
1 WIFE @13382@
1 _UST MARRIED
1 CHIL @13362@
0 @13402@ FAM
1 HUSB @13392@
1 WIFE @13400@
1 _UST MARRIED
1 CHIL @13372@
0 @13418@ FAM
1 HUSB @13405@
1 WIFE @13416@
1 _UST MARRIED
1 CHIL @13382@
0 @13437@ FAM
1 HUSB @13422@
1 WIFE @13430@
1 _UST MARRIED
1 CHIL @13351@
0 @13452@ FAM
1 HUSB @13440@
1 WIFE @13448@
1 _UST MARRIED
1 CHIL @13422@
0 @13460@ FAM
1 HUSB @13455@
1 _UST MARRIED
1 CHIL @13448@
0 @13480@ FAM
1 HUSB @13466@
1 WIFE @13478@
1 _UST MARRIED
1 CHIL @8710@
0 @13493@ FAM
1 HUSB @13485@
1 WIFE @13491@
1 _UST MARRIED
1 CHIL @13466@
0 @13511@ FAM
1 HUSB @13504@
1 WIFE @13509@
1 _UST MARRIED
1 CHIL @13478@
0 @13581@ FAM
1 HUSB @13566@
1 WIFE @13577@
1 _UST MARRIED
1 CHIL @6338@
0 @13590@ FAM
1 HUSB @13588@
1 _UST MARRIED
1 CHIL @13577@
0 @13598@ FAM
1 HUSB @13596@
1 _UST MARRIED
1 CHIL @13588@
1 CHIL @13603@
0 @13611@ FAM
1 HUSB @13603@
1 _UST MARRIED
1 CHIL @13608@
0 @13649@ FAM
1 HUSB @13640@
1 WIFE @13646@
1 _UST MARRIED
1 CHIL @6327@
0 @13717@ FAM
1 HUSB @13709@
1 WIFE @13716@
1 _UST MARRIED
1 CHIL @6294@
0 @13753@ FAM
1 HUSB @13744@
1 WIFE @13751@
1 _UST MARRIED
1 CHIL @6305@
0 @13779@ FAM
1 HUSB @13771@
1 WIFE @13777@
1 _UST MARRIED
1 CHIL @13744@
0 @17707@ FAM
1 HUSB @13823@
1 WIFE @17705@
1 _UST MARRIED
0 @14830@ FAM
1 HUSB @13828@
1 WIFE @14828@
1 _UST MARRIED
1 CHIL @14831@
0 @17726@ FAM
1 HUSB @17724@
1 WIFE @13837@
1 _UST MARRIED
1 CHIL @17732@
1 CHIL @17727@
0 @17720@ FAM
1 HUSB @17716@
1 WIFE @13842@
1 _UST MARRIED
0 @14846@ FAM
1 HUSB @13845@
1 WIFE @14844@
1 _UST MARRIED
1 CHIL @14847@
0 @13906@ FAM
1 HUSB @13898@
1 WIFE @13856@
1 _UST MARRIED
1 CHIL @13907@
1 CHIL @32454@
1 CHIL @32468@
1 CHIL @32482@
1 CHIL @32496@
1 CHIL @13914@
1 MARR
2 DATE 6 APR 1929
2 PLAC Paris 09,,,,,
2 _FNA NO
0 @14042@ FAM
1 HUSB @13863@
1 WIFE @14040@
1 _UST MARRIED
1 CHIL @14043@
1 CHIL @14050@
1 CHIL @14058@
1 MARR
2 DATE 1947
2 _FNA NO
0 @13892@ FAM
1 HUSB @13873@
1 WIFE @13885@
1 _UST MARRIED
1 CHIL @12138@
1 CHIL @14172@
1 CHIL @14182@
1 MARR
2 DATE 1872
2 _FNA NO
0 @16263@ FAM
1 HUSB @13898@
1 WIFE @16261@
1 _UST MARRIED
1 CHIL @18443@
1 CHIL @16264@
1 CHIL @32510@
1 MARR
2 DATE 15 OCT 1923
2 PLAC Châteauneuf-sur-Cher,18190,Cher,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N46.857780
4 LONG E2.317100
2 _FNA NO
0 @13929@ FAM
1 HUSB @13925@
1 WIFE @13907@
1 _UST MARRIED
1 CHIL @13930@
1 CHIL @13938@
1 CHIL @13945@
1 CHIL @13953@
1 MARR
2 DATE 19 JUL 1951
2 PLAC Ermenouville,76740,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.798100
4 LONG E0.787040
2 _FNA NO
0 @13963@ FAM
1 HUSB @13930@
1 WIFE @13961@
1 _UST MARRIED
1 CHIL @13965@
1 CHIL @13972@
1 CHIL @13979@
1 CHIL @13986@
1 MARR
2 DATE 1975
2 _FNA NO
0 @13997@ FAM
1 HUSB @13995@
1 WIFE @13938@
1 _UST MARRIED
1 CHIL @13998@
1 CHIL @14005@
1 CHIL @14012@
1 CHIL @14019@
1 MARR
2 DATE 1975
2 _FNA NO
0 @14030@ FAM
1 HUSB @14028@
1 WIFE @13945@
1 _UST MARRIED
1 CHIL @14031@
1 CHIL @28185@
1 CHIL @28191@
1 CHIL @28198@
1 MARR
2 DATE 1988
2 _FNA NO
0 @18236@ FAM
1 HUSB @13953@
1 WIFE @18232@
1 _UST MARRIED
1 CHIL @27760@
1 CHIL @27765@
1 CHIL @27770@
1 CHIL @27775@
1 MARR
2 DATE 1994
2 _FNA NO
0 @27883@ FAM
1 HUSB @13965@
1 WIFE @27881@
1 _UST MARRIED
0 @27888@ FAM
1 HUSB @27886@
1 WIFE @13972@
1 _UST MARRIED
0 @28134@ FAM
1 HUSB @28131@
1 WIFE @13998@
1 _UST MARRIED
1 CHIL @28136@
1 CHIL @28143@
0 @14068@ FAM
1 HUSB @14043@
1 WIFE @14066@
1 _UST MARRIED
1 CHIL @14069@
1 CHIL @14076@
1 CHIL @14084@
1 CHIL @14091@
1 CHIL @14098@
1 MARR
2 DATE 1970
2 _FNA NO
0 @14108@ FAM
1 HUSB @14106@
1 WIFE @14050@
1 _UST MARRIED
1 CHIL @14109@
1 CHIL @14117@
1 CHIL @14124@
1 CHIL @14135@
1 CHIL @18301@
0 @14146@ FAM
1 HUSB @14058@
1 WIFE @14144@
1 _UST MARRIED
1 CHIL @14148@
1 CHIL @14155@
1 CHIL @18312@
0 @18256@ FAM
1 HUSB @18252@
1 WIFE @14069@
1 _UST MARRIED
0 @18260@ FAM
1 HUSB @18258@
1 WIFE @14076@
1 _UST MARRIED
1 MARR
2 DATE 1998
2 _FNA NO
0 @18268@ FAM
1 HUSB @14084@
1 WIFE @18264@
1 _UST MARRIED
1 MARR
2 DATE 2005
2 _FNA NO
0 @18274@ FAM
1 HUSB @18270@
1 WIFE @14091@
1 _UST MARRIED
1 MARR
2 DATE 2006
2 _FNA NO
0 @18283@ FAM
1 HUSB @14109@
1 WIFE @18279@
1 _UST MARRIED
1 MARR
2 DATE 1999
2 _FNA NO
0 @18291@ FAM
1 HUSB @18287@
1 WIFE @14117@
1 _UST MARRIED
1 MARR
2 DATE 2000
2 _FNA NO
0 @18299@ FAM
1 HUSB @18295@
1 WIFE @14124@
1 _UST MARRIED
1 MARR
2 DATE 2000
2 _FNA NO
0 @14200@ FAM
1 HUSB @14182@
1 WIFE @14193@
1 _UST MARRIED
1 CHIL @14201@
1 CHIL @14208@
1 CHIL @14219@
1 CHIL @14227@
0 @14236@ FAM
1 HUSB @14234@
1 WIFE @14201@
1 _UST MARRIED
1 CHIL @14237@
1 CHIL @14245@
1 CHIL @14252@
1 CHIL @14260@
1 MARR
2 DATE 1926
2 _FNA NO
0 @14540@ FAM
1 HUSB @14538@
1 WIFE @14219@
1 _UST MARRIED
1 CHIL @14541@
1 CHIL @14548@
1 CHIL @14561@
1 CHIL @14555@
0 @14571@ FAM
1 HUSB @14569@
1 WIFE @14227@
1 _UST MARRIED
1 CHIL @14572@
1 CHIL @14579@
1 MARR
2 DATE 1942
2 _FNA NO
0 @14275@ FAM
1 HUSB @14268@
1 WIFE @14237@
1 _UST MARRIED
1 CHIL @14276@
1 CHIL @14283@
1 CHIL @14291@
0 @14390@ FAM
1 HUSB @14383@
1 WIFE @14245@
1 _UST MARRIED
1 CHIL @14392@
1 CHIL @14399@
1 CHIL @14406@
1 CHIL @14413@
1 CHIL @14421@
1 CHIL @14428@
0 @14373@ FAM
1 HUSB @14371@
1 WIFE @14252@
1 _UST MARRIED
1 CHIL @14375@
1 MARR
2 DATE 1963
2 _FNA NO
0 @14330@ FAM
1 HUSB @14260@
1 WIFE @14328@
1 _UST MARRIED
1 CHIL @14331@
1 CHIL @4356@
1 CHIL @14349@
1 CHIL @14356@
1 CHIL @14363@
1 MARR
2 DATE 1971
2 _FNA NO
0 @14309@ FAM
1 HUSB @14307@
1 WIFE @14276@
1 _UST MARRIED
1 CHIL @14311@
1 CHIL @14318@
1 CHIL @18422@
1 CHIL @18429@
0 @18365@ FAM
1 HUSB @18363@
1 WIFE @14276@
1 _UST DIVORCED
1 CHIL @14299@
1 MARR
2 DATE 1976
2 _FNA NO
0 @18371@ FAM
1 HUSB @18367@
1 WIFE @14283@
1 _UST MARRIED
0 @18381@ FAM
1 HUSB @18374@
1 WIFE @14291@
1 _UST MARRIED
1 MARR
2 DATE 1985
2 _FNA NO
0 @18388@ FAM
1 HUSB @18384@
1 WIFE @14291@
1 _UST MARRIED
0 @18349@ FAM
1 HUSB @18345@
1 WIFE @14331@
1 _UST MARRIED
0 @21780@ FAM
1 HUSB @14349@
1 _UST MARRIED
1 CHIL @21778@
1 CHIL @21784@
1 CHIL @21791@
0 @18355@ FAM
1 HUSB @18351@
1 WIFE @14356@
1 _UST MARRIED
1 MARR
2 DATE 2005
2 _FNA NO
0 @18339@ FAM
1 HUSB @14375@
1 WIFE @18335@
1 _UST MARRIED
0 @14439@ FAM
1 HUSB @14437@
1 WIFE @14392@
1 _UST MARRIED
0 @14446@ FAM
1 WIFE @14399@
1 _UST MARRIED
1 CHIL @14442@
1 CHIL @14449@
0 @14459@ FAM
1 HUSB @14406@
1 WIFE @14457@
1 _UST MARRIED
1 CHIL @14460@
1 CHIL @14467@
1 CHIL @14474@
1 CHIL @14482@
0 @14493@ FAM
1 HUSB @14413@
1 WIFE @14491@
1 _UST MARRIED
1 CHIL @14494@
1 CHIL @14501@
0 @14511@ FAM
1 HUSB @14509@
1 WIFE @14421@
1 _UST MARRIED
1 CHIL @14512@
1 CHIL @14519@
1 CHIL @14526@
0 @14536@ FAM
1 HUSB @14428@
1 WIFE @14534@
1 _UST MARRIED
0 @14647@ FAM
1 HUSB @14645@
1 WIFE @14541@
1 _UST MARRIED
1 CHIL @14648@
1 CHIL @14655@
0 @14665@ FAM
1 HUSB @14663@
1 WIFE @14548@
1 _UST MARRIED
1 CHIL @14666@
1 CHIL @14673@
1 CHIL @14680@
1 CHIL @14687@
0 @14698@ FAM
1 HUSB @14555@
1 WIFE @14696@
1 _UST MARRIED
0 @18393@ FAM
1 HUSB @14561@
1 WIFE @18389@
1 _UST MARRIED
1 CHIL @14699@
1 CHIL @14706@
1 CHIL @14713@
1 CHIL @14719@
1 CHIL @14725@
1 MARR
2 DATE 1975
2 _FNA NO
0 @14589@ FAM
1 HUSB @14572@
1 WIFE @14587@
1 _UST MARRIED
1 CHIL @14590@
1 CHIL @14597@
1 CHIL @14604@
1 CHIL @14611@
0 @14622@ FAM
1 HUSB @14620@
1 WIFE @14579@
1 _UST MARRIED
1 CHIL @14623@
1 CHIL @14630@
1 CHIL @14637@
1 MARR
2 DATE 1982
2 _FNA NO
0 @14795@ FAM
1 HUSB @14785@
1 WIFE @14793@
1 _UST MARRIED
1 CHIL @14796@
0 @14870@ FAM
1 HUSB @14868@
1 WIFE @14858@
1 _UST MARRIED
1 MARR
2 DATE 1977
2 _FNA NO
0 @17751@ FAM
1 HUSB @14881@
1 WIFE @17749@
1 _UST MARRIED
1 CHIL @19038@
1 CHIL @19046@
1 CHIL @19054@
1 MARR
2 DATE 1994
2 _FNA NO
0 @17758@ FAM
1 HUSB @14893@
1 WIFE @17754@
1 _UST MARRIED
1 CHIL @36669@
1 CHIL @36653@
1 CHIL @36661@
0 @22918@ FAM
1 HUSB @14922@
1 WIFE @22916@
1 _UST MARRIED
0 @30831@ FAM
1 HUSB @14922@
1 WIFE @30828@
1 _UST MARRIED
1 MARR
2 DATE 10 MAY 2019
2 _FNA NO
0 @22922@ FAM
1 HUSB @14929@
1 WIFE @22920@
1 _UST MARRIED
0 @30928@ FAM
1 HUSB @30926@
1 WIFE @14937@
1 _UST MARRIED
1 MARR
2 DATE 7 SEP 2019
2 PLAC Chevillon,89120,Yonne,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.920740
4 LONG E3.175580
2 _FNA NO
0 @17612@ FAM
1 HUSB @14977@
1 WIFE @17605@
1 _UST MARRIED
1 CHIL @17871@
1 CHIL @17881@
1 CHIL @17891@
1 CHIL @12215@
1 MARR
2 DATE 1891
2 _FNA NO
0 @17621@ FAM
1 HUSB @17614@
1 WIFE @14987@
1 _UST MARRIED
1 CHIL @17953@
1 CHIL @17963@
1 CHIL @17973@
1 CHIL @17983@
1 MARR
2 DATE 1893
2 _FNA NO
0 @17630@ FAM
1 HUSB @14997@
1 WIFE @17623@
1 _UST MARRIED
1 CHIL @18014@
1 CHIL @18024@
1 MARR
2 DATE 1898
2 _FNA NO
0 @17639@ FAM
1 HUSB @17632@
1 WIFE @15008@
1 _UST MARRIED
1 CHIL @18083@
1 CHIL @18093@
1 CHIL @18124@
1 CHIL @18103@
1 CHIL @18134@
1 CHIL @18114@
1 CHIL @18144@
0 @17648@ FAM
1 HUSB @15018@
1 WIFE @17641@
1 _UST MARRIED
1 CHIL @18155@
1 CHIL @18165@
1 CHIL @18175@
1 MARR
2 DATE 1912
2 _FNA NO
0 @15113@ FAM
1 HUSB @15106@
1 WIFE @15028@
1 _UST MARRIED
1 CHIL @15115@
1 CHIL @15125@
1 CHIL @15135@
1 MARR
2 DATE 19 JAN 1910
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @15103@ FAM
1 HUSB @15096@
1 WIFE @15048@
1 _UST MARRIED
1 MARR
2 DATE 21 JUL 1913
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @15087@ FAM
1 HUSB @15080@
1 WIFE @15058@
1 _UST MARRIED
1 CHIL @15088@
1 MARR
2 DATE 12 DEC 1913
2 _FNA NO
0 @18684@ FAM
1 HUSB @18676@
1 WIFE @15058@
1 _UST MARRIED
1 MARR
2 DATE 10 AUG 1927
2 _FNA NO
0 @16231@ FAM
1 HUSB @16229@
1 WIFE @15088@
1 _UST MARRIED
1 CHIL @16232@
1 MARR
2 DATE 1960
2 _FNA NO
0 @18775@ FAM
1 HUSB @18771@
1 WIFE @15088@
1 _UST DIVORCED
1 MARR
2 DATE 18 FEB 1936
2 _FNA NO
0 @18783@ FAM
1 HUSB @18779@
1 WIFE @15088@
1 _UST DIVORCED
1 MARR
2 DATE 4 NOV 1946
2 _FNA NO
0 @15150@ FAM
1 HUSB @15115@
1 WIFE @15146@
1 _UST MARRIED
0 @15154@ FAM
1 HUSB @15125@
1 WIFE @15152@
1 _UST MARRIED
1 CHIL @15155@
1 CHIL @15162@
1 CHIL @15169@
0 @15179@ FAM
1 HUSB @15135@
1 WIFE @15177@
1 _UST MARRIED
1 CHIL @15180@
1 CHIL @15188@
1 CHIL @15196@
1 CHIL @15203@
1 CHIL @15210@
0 @15347@ FAM
1 HUSB @15155@
1 WIFE @15345@
1 _UST MARRIED
1 CHIL @15350@
1 CHIL @15358@
1 CHIL @15365@
1 CHIL @15373@
1 MARR
2 DATE 1971
2 _FNA NO
0 @15329@ FAM
1 HUSB @15327@
1 WIFE @15162@
1 _UST MARRIED
1 CHIL @15330@
1 CHIL @15337@
0 @15289@ FAM
1 HUSB @15287@
1 WIFE @15169@
1 _UST MARRIED
1 CHIL @15290@
1 CHIL @15311@
1 CHIL @15304@
1 CHIL @15297@
1 CHIL @15319@
0 @15264@ FAM
1 HUSB @15262@
1 WIFE @15180@
1 _UST MARRIED
1 CHIL @15265@
1 CHIL @15272@
1 CHIL @15279@
0 @15239@ FAM
1 HUSB @15188@
1 WIFE @15237@
1 _UST MARRIED
1 CHIL @15240@
1 CHIL @15247@
1 CHIL @15254@
0 @15220@ FAM
1 HUSB @15203@
1 WIFE @15218@
1 _UST MARRIED
1 CHIL @15222@
1 CHIL @15229@
1 CHIL @19300@
1 CHIL @19307@
0 @17788@ FAM
1 HUSB @15210@
1 WIFE @17784@
1 _UST MARRIED
1 MARR
2 DATE 2003
2 _FNA NO
0 @19298@ FAM
1 HUSB @15240@
1 WIFE @19294@
1 _UST MARRIED
1 MARR
2 DATE 2006
2 _FNA NO
0 @19269@ FAM
1 HUSB @19265@
1 WIFE @15290@
1 _UST MARRIED
1 CHIL @19271@
1 CHIL @19278@
1 CHIL @19285@
1 MARR
2 DATE 2002
2 _FNA NO
0 @19166@ FAM
1 HUSB @19162@
1 WIFE @15350@
1 _UST MARRIED
1 CHIL @19168@
1 CHIL @19176@
1 CHIL @19184@
1 CHIL @19191@
1 CHIL @19201@
1 MARR
2 DATE 1996
2 _FNA NO
0 @19213@ FAM
1 HUSB @19209@
1 WIFE @15358@
1 _UST MARRIED
1 CHIL @19214@
1 CHIL @19221@
0 @19234@ FAM
1 HUSB @19230@
1 WIFE @15365@
1 _UST MARRIED
1 CHIL @19235@
1 CHIL @19242@
1 CHIL @19249@
1 CHIL @19257@
0 @15394@ FAM
1 HUSB @15380@
1 WIFE @15391@
1 _UST MARRIED
1 CHIL @13873@
1 CHIL @15396@
1 CHIL @15405@
1 MARR
2 DATE 1843
2 _FNA NO
0 @15432@ FAM
1 HUSB @15396@
1 WIFE @15425@
1 _UST MARRIED
1 CHIL @17546@
1 CHIL @17556@
1 CHIL @17566@
1 CHIL @35857@
1 MARR
2 DATE 1869
2 _FNA NO
0 @15422@ FAM
1 HUSB @15415@
1 WIFE @15405@
1 _UST MARRIED
0 @15447@ FAM
1 HUSB @15433@
1 WIFE @15444@
1 _UST MARRIED
1 CHIL @15450@
1 CHIL @15461@
1 CHIL @15380@
1 CHIL @15471@
1 NOTE François de Wendel (1778-1825) et Joséphine de FISCHER de DICOURT ont les arrière-grand-parents (FdW) et arrière-arrière-grand-parents (JdeFdD) communs  =Jean-Martin W + Anne-Marguerite MEYER
0 @15605@ FAM
1 HUSB @15598@
1 WIFE @15450@
1 _UST MARRIED
1 CHIL @15606@
1 CHIL @15617@
1 CHIL @15628@
1 CHIL @12206@
1 CHIL @15643@
1 CHIL @15654@
1 MARR
2 DATE 1826
2 _FNA NO
0 @15542@ FAM
1 HUSB @15461@
1 WIFE @15531@
1 _UST MARRIED
1 CHIL @15545@
1 CHIL @15556@
1 CHIL @15567@
1 CHIL @15578@
0 @15488@ FAM
1 HUSB @15481@
1 WIFE @15471@
1 _UST MARRIED
1 CHIL @15489@
0 @15515@ FAM
1 HUSB @15489@
1 WIFE @15500@
1 _UST MARRIED
1 CHIL @15516@
0 @15597@ FAM
1 HUSB @15590@
1 WIFE @15545@
1 _UST MARRIED
1 MARR
2 DATE 1853
2 _FNA NO
0 @15673@ FAM
1 HUSB @15606@
1 WIFE @15666@
1 _UST MARRIED
1 MARR
2 DATE 1857
2 _FNA NO
0 @15678@ FAM
1 HUSB @15675@
1 WIFE @15617@
1 _UST MARRIED
1 CHIL @17793@
1 CHIL @17803@
1 CHIL @17813@
1 MARR
2 DATE 1851
2 _FNA NO
0 @15704@ FAM
1 HUSB @15686@
1 WIFE @15697@
1 _UST MARRIED
1 CHIL @15707@
1 CHIL @15718@
1 CHIL @15433@
0 @15733@ FAM
1 HUSB @15718@
1 WIFE @15731@
1 _UST MARRIED
0 @15752@ FAM
1 HUSB @15734@
1 WIFE @15745@
1 _UST MARRIED
1 CHIL @15769@
1 CHIL @15686@
1 CHIL @15779@
1 CHIL @15791@
1 CHIL @15801@
1 CHIL @15811@
1 CHIL @15818@
0 @15837@ FAM
1 HUSB @15757@
1 WIFE @15830@
1 _UST MARRIED
1 CHIL @15973@
1 CHIL @15984@
1 CHIL @15995@
1 CHIL @16006@
1 CHIL @15734@
1 CHIL @16016@
1 CHIL @16023@
1 CHIL @16033@
1 CHIL @16041@
1 CHIL @16051@
1 CHIL @16061@
1 CHIL @16071@
1 CHIL @16091@
1 CHIL @16102@
1 CHIL @16113@
0 @16131@ FAM
1 HUSB @16124@
1 WIFE @15779@
1 _UST MARRIED
1 CHIL @32082@
0 @16135@ FAM
1 HUSB @16133@
1 WIFE @15791@
1 _UST MARRIED
0 @16139@ FAM
1 HUSB @15811@
1 WIFE @16137@
1 _UST MARRIED
1 CHIL @16140@
0 @16380@ FAM
1 HUSB @16377@
1 WIFE @15818@
1 _UST MARRIED
0 @15853@ FAM
1 HUSB @15840@
1 WIFE @15851@
1 _UST MARRIED
0 @15859@ FAM
1 HUSB @15840@
1 WIFE @15855@
1 _UST MARRIED
1 CHIL @15893@
1 CHIL @15757@
1 CHIL @15903@
1 CHIL @15914@
1 CHIL @15925@
1 CHIL @15938@
1 CHIL @15932@
1 CHIL @15945@
0 @15877@ FAM
1 HUSB @15865@
1 WIFE @15876@
1 _UST MARRIED
1 CHIL @15840@
0 @15890@ FAM
1 HUSB @15880@
1 WIFE @15888@
1 _UST MARRIED
1 CHIL @15865@
0 @15971@ FAM
1 HUSB @15914@
1 WIFE @15969@
1 _UST MARRIED
0 @15967@ FAM
1 HUSB @15965@
1 WIFE @15932@
1 _UST MARRIED
0 @15963@ FAM
1 HUSB @15956@
1 WIFE @15938@
1 _UST MARRIED
0 @15952@ FAM
1 HUSB @15951@
1 WIFE @15945@
1 _UST MARRIED
0 @16224@ FAM
1 HUSB @16212@
1 WIFE @15995@
1 _UST MARRIED
1 CHIL @16204@
1 CHIL @16345@
1 MARR
2 DATE 1721
2 _FNA NO
0 @16399@ FAM
1 HUSB @16393@
1 WIFE @16006@
1 _UST MARRIED
1 NOTE RAMEAU ETEINT EN 1794
1 MARR
2 DATE 1722
2 _FNA NO
0 @16385@ FAM
1 HUSB @16382@
1 WIFE @16061@
1 _UST MARRIED
0 @16153@ FAM
1 HUSB @16140@
1 WIFE @16151@
1 _UST MARRIED
1 CHIL @16155@
0 @16188@ FAM
1 HUSB @16167@
1 WIFE @16177@
1 _UST MARRIED
1 CHIL @15444@
0 @16206@ FAM
1 HUSB @16192@
1 WIFE @16204@
1 _UST MARRIED
1 CHIL @16177@
0 @16241@ FAM
1 HUSB @16240@
1 WIFE @16232@
1 _UST MARRIED
1 CHIL @16242@
1 CHIL @16248@
1 CHIL @16254@
1 CHIL @19323@
1 CHIL @19318@
0 @16272@ FAM
1 HUSB @16270@
1 WIFE @16264@
1 _UST MARRIED
1 CHIL @18457@
1 CHIL @18467@
1 CHIL @18474@
1 CHIL @18481@
1 CHIL @18488@
1 MARR
2 DATE 1949
2 _FNA NO
0 @16373@ FAM
1 HUSB @16370@
1 WIFE @16345@
1 _UST MARRIED
0 @25196@ FAM
1 HUSB @16434@
1 WIFE @25194@
1 _UST MARRIED
1 CHIL @25197@
1 CHIL @25202@
0 @16463@ FAM
1 HUSB @16453@
1 WIFE @16461@
1 _UST MARRIED
1 CHIL @33053@
1 CHIL @486@
1 MARC
2 DATE 21 APR 1854
2 PLAC Bourges,18000,Cher,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N47.083330
4 LONG E2.400000
2 _FNA NO
2 NOTE Contrat de mariage (avec Emilie BITTARD) - Paultre cité au M - Bourges, 18033, Centre, France
1 MARR
2 DATE 22 AUG 1854
2 PLAC La Charité-sur-Loire,58400,Nièvre,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.183330
4 LONG E3.016670
2 _FNA NO
2 NOTE acte numérisé - AD 58 La Charité-sur-Loire 1849-1859 vue 667 acte N° 38
0 @16548@ FAM
1 HUSB @16521@
1 WIFE @16537@
1 _UST MARRIED
1 CHIL @4263@
0 @16870@ FAM
1 HUSB @16864@
1 WIFE @16600@
1 _UST MARRIED
1 CHIL @16872@
1 CHIL @16887@
1 MARR
2 DATE 1 OCT 1996
2 PLAC Paris 13,,,,,
2 _FNA NO
0 @16644@ FAM
1 HUSB @16623@
1 WIFE @16637@
1 _UST MARRIED
1 CHIL @16594@
0 @25169@ FAM
1 HUSB @25167@
1 WIFE @16685@
1 _UST MARRIED
1 CHIL @25170@
0 @16737@ FAM
1 HUSB @16706@
1 WIFE @16722@
1 _UST MARRIED
1 CHIL @16772@
1 CHIL @7109@
1 CHIL @8422@
1 CHIL @16791@
0 @16851@ FAM
1 HUSB @16847@
1 WIFE @16772@
1 _UST MARRIED
1 CHIL @16852@
1 CHIL @16857@
0 @16830@ FAM
1 HUSB @16791@
1 WIFE @16826@
1 _UST MARRIED
1 CHIL @16831@
1 CHIL @16839@
0 @16945@ FAM
1 HUSB @16927@
1 WIFE @16943@
1 _UST MARRIED
1 CHIL @2295@
1 MARR
2 DATE 25 OCT 1896
2 PLAC Neuilly-sur-Seine,92200,Hauts-de-Seine,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883330
4 LONG E2.266670
2 _FNA NO
0 @16991@ FAM
1 HUSB @16957@
1 WIFE @16989@
1 _UST MARRIED
1 MARR
2 DATE 2003
2 _FNA NO
0 @17284@ FAM
1 HUSB @16957@
1 WIFE @17280@
1 _UST DIVORCED
1 CHIL @17286@
1 CHIL @17298@
1 CHIL @17307@
1 CHIL @17316@
1 MARR
2 DATE 1970
2 _FNA NO
0 @16988@ FAM
1 HUSB @16964@
1 WIFE @16986@
1 _UST MARRIED
1 CHIL @17339@
1 CHIL @17346@
1 CHIL @17353@
1 CHIL @17360@
1 CHIL @17367@
1 CHIL @17374@
1 CHIL @17381@
1 CHIL @17388@
1 MARR
2 DATE 1974
2 _FNA NO
0 @16999@ FAM
1 HUSB @16997@
1 WIFE @16971@
1 _UST MARRIED
1 CHIL @17155@
1 CHIL @17162@
1 CHIL @17169@
1 CHIL @17176@
1 MARR
2 DATE 22 JUN 1972
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @17003@ FAM
1 HUSB @16978@
1 WIFE @17001@
1 _UST MARRIED
1 CHIL @17087@
1 CHIL @17094@
1 CHIL @17101@
1 CHIL @17108@
1 CHIL @17116@
1 CHIL @17124@
1 MARR
2 DATE 1987
2 _FNA NO
0 @17028@ FAM
1 HUSB @17008@
1 WIFE @17023@
1 _UST MARRIED
1 CHIL @16952@
0 @17153@ FAM
1 HUSB @17140@
1 WIFE @17151@
1 _UST MARRIED
1 CHIL @16997@
0 @17190@ FAM
1 HUSB @17162@
1 WIFE @17188@
1 _UST MARRIED
1 CHIL @17192@
1 CHIL @17199@
1 CHIL @17205@
1 MARR
2 DATE 11 SEP 2004
2 PLAC Périgueux,24000,Dordogne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.183330
4 LONG E0.716670
2 _FNA NO
0 @17234@ FAM
1 HUSB @17169@
1 WIFE @17230@
1 _UST MARRIED
1 CHIL @17236@
1 CHIL @17244@
1 MARR
2 DATE 2006
2 _FNA NO
0 @17257@ FAM
1 HUSB @17253@
1 WIFE @17176@
1 _UST MARRIED
1 CHIL @17259@
1 CHIL @17267@
1 MARR
2 DATE 2009
2 _FNA NO
0 @17223@ FAM
1 HUSB @17212@
1 WIFE @17218@
1 _UST MARRIED
1 CHIL @17188@
0 @17670@ FAM
1 HUSB @17663@
1 WIFE @17477@
1 _UST MARRIED
1 MARR
2 DATE 1912
2 PLAC Luxembourg,,,Luxembourg,LUXEMBOURG,
3 MAP
4 LATI N49.611670
4 LONG E6.130000
2 _FNA NO
0 @17585@ FAM
1 HUSB @17578@
1 WIFE @17546@
1 _UST MARRIED
1 CHIL @16261@
1 CHIL @35772@
1 CHIL @35786@
1 CHIL @35801@
1 CHIL @35820@
1 CHIL @35828@
1 CHIL @35836@
1 CHIL @35844@
0 @17594@ FAM
1 HUSB @17587@
1 WIFE @17556@
1 _UST MARRIED
0 @17603@ FAM
1 HUSB @17566@
1 WIFE @17596@
1 _UST MARRIED
0 @17831@ FAM
1 HUSB @17793@
1 WIFE @17824@
1 _UST MARRIED
0 @17840@ FAM
1 HUSB @17833@
1 WIFE @17803@
1 _UST MARRIED
1 CHIL @17850@
0 @17849@ FAM
1 HUSB @17813@
1 WIFE @17842@
1 _UST MARRIED
1 CHIL @37032@
1 CHIL @37040@
0 @37051@ FAM
1 HUSB @37048@
1 WIFE @17842@
1 _UST MARRIED
0 @17868@ FAM
1 HUSB @17861@
1 WIFE @17850@
1 _UST MARRIED
0 @17925@ FAM
1 HUSB @17918@
1 WIFE @17871@
1 _UST MARRIED
1 MARR
2 DATE 1918
2 _FNA NO
0 @17935@ FAM
1 HUSB @17928@
1 WIFE @17881@
1 _UST MARRIED
1 MARR
2 DATE 1920
2 _FNA NO
0 @17950@ FAM
1 HUSB @17939@
1 WIFE @17891@
1 _UST MARRIED
1 MARR
2 DATE 1926
2 _FNA NO
0 @18001@ FAM
1 HUSB @17994@
1 WIFE @17973@
1 _UST MARRIED
1 MARR
2 DATE 1924
2 _FNA NO
0 @18011@ FAM
1 HUSB @17983@
1 WIFE @18009@
1 _UST MARRIED
1 MARR
2 DATE 1924
2 _FNA NO
0 @18042@ FAM
1 HUSB @18035@
1 WIFE @18014@
1 _UST MARRIED
1 CHIL @18044@
1 CHIL @18054@
1 CHIL @18061@
1 MARR
2 DATE 1922
2 _FNA NO
0 @18079@ FAM
1 HUSB @18072@
1 WIFE @18024@
1 _UST MARRIED
1 MARR
2 DATE 1924
2 _FNA NO
0 @18193@ FAM
1 HUSB @18186@
1 WIFE @18155@
1 _UST MARRIED
0 @18202@ FAM
1 HUSB @18195@
1 WIFE @18165@
1 _UST MARRIED
0 @18211@ FAM
1 HUSB @18204@
1 WIFE @18175@
1 _UST MARRIED
0 @18529@ FAM
1 HUSB @18522@
1 WIFE @18443@
1 _UST MARRIED
1 MARR
2 DATE 1951
2 _FNA NO
0 @18499@ FAM
1 HUSB @18457@
1 WIFE @18497@
1 _UST DIVORCED
0 @18503@ FAM
1 HUSB @18467@
1 WIFE @18501@
1 _UST DIVORCED
0 @18508@ FAM
1 HUSB @18467@
1 WIFE @18506@
1 _UST MARRIED
0 @18512@ FAM
1 HUSB @18510@
1 WIFE @18474@
1 _UST MARRIED
0 @18515@ FAM
1 HUSB @18481@
1 WIFE @18513@
1 _UST MARRIED
0 @18520@ FAM
1 HUSB @18488@
1 WIFE @18518@
1 _UST MARRIED
0 @18578@ FAM
1 HUSB @18547@
1 WIFE @18563@
1 _UST MARRIED
1 CHIL @12194@
1 CHIL @18608@
0 @18602@ FAM
1 HUSB @18585@
1 WIFE @18599@
1 _UST MARRIED
1 CHIL @18563@
0 @18636@ FAM
1 HUSB @18608@
1 WIFE @18629@
1 _UST MARRIED
1 CHIL @18638@
1 MARR
2 DATE 1882
2 _FNA NO
0 @18658@ FAM
1 HUSB @18654@
1 WIFE @18638@
1 _UST MARRIED
1 MARR
2 DATE 1907
2 _FNA NO
0 @18743@ FAM
1 HUSB @18724@
1 WIFE @18736@
1 _UST MARRIED
1 CHIL @15146@
0 @18759@ FAM
1 HUSB @18746@
1 WIFE @18757@
1 _UST MARRIED
1 CHIL @18724@
1 CHIL @12237@
0 @18835@ FAM
1 HUSB @18815@
1 WIFE @18828@
1 _UST MARRIED
1 CHIL @11535@
0 @18857@ FAM
1 HUSB @18838@
1 WIFE @18850@
1 _UST MARRIED
1 CHIL @18828@
0 @18869@ FAM
1 HUSB @18861@
1 _UST MARRIED
1 CHIL @18838@
0 @18912@ FAM
1 HUSB @18893@
1 WIFE @18905@
1 _UST MARRIED
1 CHIL @12182@
0 @18949@ FAM
1 HUSB @18934@
1 WIFE @18942@
1 _UST MARRIED
0 @18974@ FAM
1 HUSB @18934@
1 WIFE @18967@
1 _UST MARRIED
1 CHIL @13885@
0 @18990@ FAM
1 HUSB @18983@
1 WIFE @18942@
1 _UST MARRIED
1 CHIL @18967@
0 @19015@ FAM
1 HUSB @18999@
1 WIFE @19011@
1 _UST MARRIED
1 CHIL @14878@
0 @19035@ FAM
1 HUSB @19023@
1 WIFE @19031@
1 _UST MARRIED
1 CHIL @17749@
0 @19075@ FAM
1 HUSB @19067@
1 WIFE @19073@
1 _UST MARRIED
1 CHIL @14889@
0 @19103@ FAM
1 HUSB @19085@
1 WIFE @19096@
1 _UST MARRIED
1 CHIL @11531@
1 MARR
2 DATE 1931
2 _FNA NO
0 @19126@ FAM
1 HUSB @19108@
1 WIFE @19119@
1 _UST MARRIED
1 CHIL @19096@
1 MARR
2 DATE 22 OCT 1907
2 PLAC Boncourt,2926,Jura,Espace Mittelland,SUISSE,
3 MAP
4 LATI N47.495600
4 LONG E7.014230
2 _FNA NO
0 @19136@ FAM
1 HUSB @19129@
1 _UST MARRIED
1 CHIL @19085@
0 @19158@ FAM
1 HUSB @19140@
1 WIFE @19151@
1 _UST MARRIED
1 CHIL @15345@
0 @19359@ FAM
1 HUSB @19342@
1 WIFE @19357@
1 _UST MARRIED
1 CHIL @19388@
1 CHIL @19395@
1 CHIL @19402@
1 CHIL @1162@
1 CHIL @19409@
1 CHIL @19416@
1 MARR
2 DATE 1934
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @19378@ FAM
1 HUSB @19365@
1 WIFE @19376@
1 _UST MARRIED
1 CHIL @19357@
0 @19428@ FAM
1 HUSB @19424@
1 WIFE @19388@
1 _UST MARRIED
0 @19434@ FAM
1 HUSB @19395@
1 WIFE @19430@
1 _UST MARRIED
0 @19440@ FAM
1 HUSB @19402@
1 WIFE @19436@
1 _UST MARRIED
0 @19446@ FAM
1 HUSB @19409@
1 WIFE @19442@
1 _UST MARRIED
0 @19452@ FAM
1 HUSB @19416@
1 WIFE @19448@
1 _UST MARRIED
0 @19461@ FAM
1 HUSB @19453@
1 WIFE @19459@
1 _UST MARRIED
1 CHIL @10469@
0 @19499@ FAM
1 HUSB @19480@
1 WIFE @19492@
1 _UST MARRIED
1 CHIL @10591@
0 @19518@ FAM
1 HUSB @19507@
1 WIFE @19515@
1 _UST MARRIED
1 CHIL @10530@
0 @19562@ FAM
1 HUSB @19547@
1 WIFE @19558@
1 _UST MARRIED
1 CHIL @4525@
0 @19578@ FAM
1 HUSB @19567@
1 WIFE @19576@
1 _UST MARRIED
1 CHIL @4429@
1 CHIL @34501@
1 CHIL @34583@
1 CHIL @34593@
1 CHIL @34604@
0 @34459@ FAM
1 HUSB @19584@
1 WIFE @34452@
1 _UST MARRIED
1 CHIL @19567@
1 CHIL @34573@
1 MARR
2 DATE 25 JUL 1910
2 PLAC Bastia,20200,Haute-Corse,Corse,FRANCE,
3 MAP
4 LATI N42.702780
4 LONG E9.450000
2 _FNA NO
0 @19607@ FAM
1 HUSB @19594@
1 WIFE @19605@
1 _UST MARRIED
1 CHIL @4393@
0 @19618@ FAM
1 HUSB @19610@
1 WIFE @19616@
1 _UST MARRIED
1 CHIL @19594@
0 @19629@ FAM
1 HUSB @19621@
1 WIFE @19627@
1 _UST MARRIED
1 CHIL @19605@
0 @19721@ FAM
1 HUSB @19719@
1 _UST MARRIED
1 CHIL @6746@
0 @19727@ FAM
1 HUSB @19725@
1 _UST MARRIED
1 CHIL @19719@
0 @19851@ FAM
1 HUSB @19841@
1 WIFE @19849@
1 _UST MARRIED
1 CHIL @10217@
0 @19933@ FAM
1 HUSB @19868@
1 WIFE @19926@
1 _UST MARRIED
1 CHIL @19888@
1 CHIL @20242@
1 MARR
2 DATE 1803
2 _FNA NO
0 @20220@ FAM
1 HUSB @19868@
1 WIFE @20215@
1 _UST MARRIED
1 CHIL @20223@
1 MARR
2 DATE 6 DEC 1820
2 _FNA NO
0 @20030@ FAM
1 HUSB @20022@
1 WIFE @19991@
1 _UST MARRIED
1 CHIL @20046@
1 CHIL @20900@
1 MARR
2 DATE 21 DEC 1820
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
2 NOTE Nicolas envoyait à son frère aîné Claude (ascendant des familles Escarfail, Manificat et Escudier, voir supra note 46). Ces lettres ont été communiquées par Mme Escarfail, née Suzanne Dordet, au Dr Pierre Vallery-Radot, qui les a reproduites dans s
3 CONC on livre, Chirurgiens d'autrefois (pages 199 et suivantes). En voici des extraits :
3 CONT «Paris, 30 juillet 1820 Cher ami, Je reprends la plume pour te donner le second bulletin de ma campagne amoureuse, je vais aussi me saisir du pinceau et te faire le portrait de ma belle. Hier, j'ai dîné chez M. Süe et ses trois enfants [Flore 21 an
3 CONC s la fiancée, Eugène 16 ans, le futur romancier et Victoire 10 ans]. Je t'assure que d'abord, j'ai éprouvé un peu de gêne et même un peu d'anxiété, qui tenait ma langue captive. Quoi qu'il en soit, ce malaise a bientôt été dissipé par les propos fl
3 CONC atteurs que M. Süe m'a adressés : « Je suis content, m'a-t-il dit, des renseignements qui m'ont été donnés sur vous, vos moyens, votre caractère et votre activité, me font concevoir la douce espérance que ma fille sera très heureuse avec vous. Croy
3 CONC ez bien que tous mes efforts vont tendre à faire rejaillir sur vous les avantages que me procurent une grande place, une longue pratique et une brillante clientèle. J'espère trouver en vous un second fils et un ami, j'espère que vous serez le conse
3 CONC il et l'appui de mon Eugène ».
3 CONT La description que le Dr Süe fait de sa situation à son futur gendre est sans fard ; il ne cherche pas à dissimuler la satisfaction que lui inspirent sa personne et sa réussite. La modestie est un sentiment qu'il ignore, à l'inverse de sa fille don
3 CONC t le fiancé décrit la retenue :
3 CONT «Paris, 3 août 1820 Mon cher ami, je suis allé hier passé la journée à la campagne de Monsieur Süe [il s'agit du château de Bouqueval] où j'ai été on ne peut mieux accueilli. Cette campagne se compose d'un château couvert en ardoises, auquel on arr
3 CONC ive par une belle avenue : 60 arpents de plantations de toutes espèces, de jardins anglais, de larges pelouses l'environnement et sont entourées de murs garnis d'espaliers... Mademoiselle Flore, qui a accepté mon bras, m'a fait voir tout cela avec
3 CONC beaucoup de complaisance et de modestie. Rentrés dans la maison, j'ai fait avec elle une partie de billard que j'ai gagnée. J'ai demandé, pour prix de ma victoire, que Melle Flore voulût bien me permettre de l'embrasser. Le père trouvant ma demande
3 CONC  juste, et la demoiselle ne s'y refusant part, j'ai joui de mon triomphe".
3 CONT
0 @20064@ FAM
1 HUSB @20052@
1 WIFE @20046@
1 _UST MARRIED
1 CHIL @20146@
1 CHIL @20074@
1 CHIL @20157@
1 NOTE Laurent Gouvion Saint-Cyr avait épousé Marie de Montalivet en 1846. Après le mariage de son ami, Vincent Félix ressentit la solitude et disait :  « Nous autres célibataires, nous n'existons qu'à demi. La moitié de notre âme se promène quelque part
2 CONC où nous n'avons pas l'esprit d'aller, et voilà pourquoi nous ne sommes pas heureux. - Je vous rendrai heureux, lui répondit M. Legouvé, comptez sur moi ».
2 CONT Legouvé, quoique plus âgé, était son ancien camarade au collège Bourbon. René Vallery-Radot poursuit son récit : « Au bout de quelques semaines, mon père parla dans ses lettres à son père du beau-frère de M. Legouvé, le Docteur Guiard... Plusieurs
2 CONC fois le docteur eut un paragraphe spécial. Mon père se plaisait à peindre cet esprit bien gaulois, cette bonne et joyeuse figure. Après le portrait du docteur, le profil de sa fille aînée était tracé discrètement ; mon père esquissait « ce regard t
2 CONC endre et vif tour à tour, ce sourire plein de charme, très fin et très doux ». Puis c'était un mot d'elle qu'il citait, un mot d'une délicatesse extrême, un trait d'une parfaite bonté. Mon père passait son temps à remercier M. Legouvé. Mme Legouvé
2 CONC écoutait tout cela en souriant et devinait sans peine ce que pensait ma mère. Le mariage fut bientôt fait ».
2 CONT Rosella, fille du docteur Nicolas Guiard et de Flore Süe, était la nièce du romancier Eugène Süe (1804-1857) et de l’académicien Ernest Legouvé (1807-1903).
2 CONT
1 MARR
2 DATE 14 JUN 1848
2 PLAC Paris 08,,,,,saint philippe du roule
2 _FNA NO
2 NOTE Le mariage de Vincent Félix Vallery en 1848 avec la petite-fille du Dr Jean-Joseph Süe (1760-1830), de l'Académie de médecine, fait entrer les Vallery-Radot dans les familles Süe et Sauvan.
0 @20093@ FAM
1 HUSB @20074@
1 WIFE @20086@
1 _UST MARRIED
1 CHIL @20118@
1 CHIL @20126@
1 NOTE Dans son beau livre, Madame Pasteur, Agnès Desquand, éclaire d'un jour nouveau les sentiments que se portaient Marie-Louise et René avant leur mariage. Dans un chapitre intitulé un Fiancé indécis elle montre, à partir d'une correspondance retrouvée
2 CONC  à Dole, que Marie-Louise était beaucoup plus éprise de René que l'inverse. C'est Pasteur lui-même qui a poussé les feux en contribuant à emporter la décision de René. (Agnès Descamps, Madame Pasteur, Éditions DMO, 2013, p. 198 s.)
2 CONT
1 MARR
2 DATE 4 NOV 1879
2 PLAC Paris 05,,,,,saint-jacques du haut pas
2 _FNA NO
0 @20115@ FAM
1 HUSB @20096@
1 WIFE @20108@
1 _UST MARRIED
1 CHIL @31224@
1 CHIL @21536@
1 CHIL @31234@
1 CHIL @20086@
1 CHIL @31244@
1 MARR
2 DATE 1849
2 PLAC Strasbourg,67000,Bas-Rhin,Grand Est,FRANCE,
3 MAP
4 LATI N48.583420
4 LONG E7.742960
2 _FNA NO
0 @20209@ FAM
1 HUSB @20126@
1 WIFE @20205@
1 _UST MARRIED
1 NOTE Louis Pasteur Vallery-Radot n’a pas eu d’enfant de sorte que la branche René est éteinte
2 CONT
1 MARR
2 DATE 1937
2 _FNA NO
0 @20172@ FAM
1 HUSB @20146@
1 WIFE @20168@
1 _UST MARRIED
1 CHIL @20427@
1 CHIL @20432@
1 NOTE On connait mal la vie de ce ménage qui eut deux fils, Georges et Robert. Marie mourut en 1907 et André se brouilla en 1909 avec son fils Robert dont il désapprouva le mariage.
1 MARR
2 DATE 1883
2 _FNA NO
0 @20182@ FAM
1 HUSB @20157@
1 WIFE @20175@
1 _UST MARRIED
1 CHIL @20446@
1 CHIL @20451@
1 NOTE La vie de Maurice avait coûté cher en croquant allègrement en dix ans de mariage la confortable dot de sa femme ainsi qu’une avance d’hoirie loin d’être négligeable obtenue de sa mère 76. Contrairement à son oncle Eugène Süe, aussi prodigue, il n’e
2 CONC ut pas la ressource des Mystères de Paris pour boucher le trou...
1 MARR
2 DATE 20 JUN 1888
2 _FNA NO
0 @20254@ FAM
1 HUSB @20252@
1 WIFE @20242@
1 _UST MARRIED
0 @20283@ FAM
1 HUSB @20279@
1 _UST MARRIED
1 CHIL @19952@
0 @21038@ FAM
1 HUSB @20432@
1 WIFE @21033@
1 _UST MARRIED
1 CHIL @20437@
1 CHIL @20442@
1 CHIL @20474@
1 CHIL @21238@
1 CHIL @21272@
1 NOTE sa jeune femme devenue folle d'angoisse durant la Première Guerre où il se battit au Vauquois [la butte du Vauquois, dans la Meuse, fut, en 1915, l'objet d'une bataille meurtrière]... Et il en est revenu indemne pour la voir disparaître lentement d
2 CONC ans les ténèbres commençantes qui devaient s'épaissir d'année en année. (Mauriac)
2 CONT
1 MARR
2 DATE 28 OCT 1909
2 _FNA NO
0 @20515@ FAM
1 HUSB @20437@
1 WIFE @20513@
1 _UST MARRIED
1 CHIL @20516@
1 CHIL @20521@
1 CHIL @20526@
1 CHIL @20531@
1 CHIL @20536@
1 CHIL @20541@
1 CHIL @20546@
1 MARR
2 DATE 1950
2 PLAC Paris 07,,,,,
2 _FNA NO
0 @31124@ FAM
1 HUSB @20437@
1 WIFE @31122@
1 _UST DIVORCED
1 MARR
2 DATE 1938
2 PLAC Marrault,89200,Yonne,,,Les Alleux
2 _FNA NO
0 @21326@ FAM
1 HUSB @20446@
1 WIFE @21324@
1 _UST MARRIED
1 CHIL @20456@
1 CHIL @21634@
1 CHIL @21743@
1 CHIL @20461@
1 MARR
2 DATE 1923
2 _FNA NO
0 @20467@ FAM
1 HUSB @20451@
1 _UST MARRIED
1 CHIL @20465@
1 CHIL @20470@
0 @21329@ FAM
1 HUSB @20451@
1 WIFE @21327@
1 _UST MARRIED
1 CHIL @22152@
1 CHIL @21618@
1 MARR
2 DATE 1916
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,Saint thomas d'aquin
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @21633@ FAM
1 HUSB @20456@
1 WIFE @21631@
1 _UST MARRIED
1 CHIL @21823@
1 CHIL @21831@
1 CHIL @21838@
1 CHIL @21845@
1 CHIL @21852@
1 NOTE Se connaissaient depuis de longues années car elle allait en classe avec ses sœurs à La Tour et venait jouer avec son train électrique.
2 CONT
1 MARR
2 DATE 13 JUL 1949
2 _FNA NO
0 @21777@ FAM
1 HUSB @20461@
1 WIFE @21775@
1 _UST MARRIED
1 CHIL @22045@
1 CHIL @22058@
1 CHIL @22065@
1 CHIL @22072@
1 NOTE En septembre 1959 le commissaire principal du Liberté reçut une recommandation de la Cunard line pour une certaine Ann Bruce Ismay. Guy avait reçu aussi une recommandation pour cette même personne du directeur de Baccarat USA et du directeur de Nei
2 CONC man Marcus à Dallas, fameux grand magasin de luxe texan, dont elle était la secrétaire. « Irait-t-elle à la table du commissaire principal ou à la mienne ? Pour elle, je fis ce que je ne faisais jamais : je l’épousai un an plus tard et ne le regret
2 CONC ta jamais. « 
2 CONT
0 @21414@ FAM
1 HUSB @20465@
1 WIFE @21412@
1 _UST MARRIED
1 CHIL @22141@
1 CHIL @22375@
1 CHIL @22382@
1 CHIL @22389@
1 CHIL @22396@
1 CHIL @22403@
1 MARR
2 DATE 26 JUL 1945
2 PLAC Auxerre,89000,Yonne,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.800000
4 LONG E3.566670
2 _FNA NO
0 @22649@ FAM
1 HUSB @20470@
1 WIFE @22647@
1 _UST MARRIED
1 CHIL @22651@
1 CHIL @22658@
1 MARR
2 DATE JUL 1963
2 _FNA NO
0 @20491@ FAM
1 HUSB @20484@
1 WIFE @20474@
1 _UST MARRIED
1 CHIL @21360@
1 CHIL @21365@
1 CHIL @21370@
1 CHIL @21375@
1 CHIL @21380@
0 @21421@ FAM
1 HUSB @20516@
1 WIFE @21419@
1 _UST DIVORCED
1 CHIL @21423@
1 CHIL @21430@
1 MARR
2 DATE 1984
2 _FNA NO
0 @21446@ FAM
1 HUSB @21444@
1 WIFE @20521@
1 _UST MARRIED
0 @31155@ FAM
1 HUSB @20526@
1 WIFE @31153@
1 _UST MARRIED
0 @31165@ FAM
1 HUSB @20531@
1 _UST MARRIED
1 CHIL @31163@
0 @21460@ FAM
1 HUSB @21458@
1 WIFE @20536@
1 _UST MARRIED
1 CHIL @31173@
1 CHIL @31178@
0 @21471@ FAM
1 HUSB @21469@
1 WIFE @20546@
1 _UST MARRIED
1 CHIL @21472@
0 @20677@ FAM
1 HUSB @20618@
1 WIFE @20670@
1 _UST MARRIED
1 CHIL @20052@
1 CHIL @20636@
1 MARR
2 DATE 1813
2 _FNA NO
0 @20666@ FAM
1 HUSB @20636@
1 WIFE @20655@
1 _UST MARRIED
0 @20707@ FAM
1 HUSB @20691@
1 WIFE @20703@
1 _UST MARRIED
1 CHIL @20713@
1 CHIL @20618@
1 MARR
2 DATE 22 APR 1782
2 PLAC Corbigny,58800,Nièvre,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.256780
4 LONG E3.682850
2 _FNA NO
0 @20788@ FAM
1 HUSB @20759@
1 WIFE @20786@
1 _UST MARRIED
1 CHIL @20747@
1 CHIL @20670@
1 CHIL @20800@
1 MARR
2 DATE OCT 1785
2 _FNA NO
0 @20808@ FAM
1 HUSB @20806@
1 WIFE @20800@
1 _UST MARRIED
1 MARR
2 DATE 1812
2 _FNA NO
0 @31010@ FAM
1 HUSB @20956@
1 WIFE @20800@
1 _UST MARRIED
1 CHIL @20975@
0 @21024@ FAM
1 HUSB @20826@
1 WIFE @21000@
1 _UST MARRIED
1 CHIL @21039@
1 CHIL @20830@
1 CHIL @21033@
1 MARR
2 DATE 1884
2 _FNA NO
0 @20838@ FAM
1 HUSB @20836@
1 WIFE @20830@
1 _UST MARRIED
1 CHIL @20840@
1 CHIL @20850@
1 MARR
2 DATE 1906
2 _FNA NO
0 @20858@ FAM
1 HUSB @20856@
1 WIFE @20850@
1 _UST MARRIED
1 CHIL @21062@
1 CHIL @21067@
1 CHIL @21072@
1 MARR
2 DATE 1932
2 _FNA NO
0 @20910@ FAM
1 HUSB @20906@
1 WIFE @20900@
1 _UST MARRIED
1 CHIL @21330@
0 @20952@ FAM
1 HUSB @20932@
1 WIFE @20950@
1 _UST MARRIED
1 CHIL @20956@
1 CHIL @20022@
0 @20998@ FAM
1 HUSB @20990@
1 WIFE @20975@
1 _UST MARRIED
1 CHIL @21000@
1 MARR
2 DATE 1849
2 _FNA NO
0 @21057@ FAM
1 HUSB @21050@
1 WIFE @21039@
1 _UST MARRIED
1 CHIL @31021@
0 @21087@ FAM
1 HUSB @21079@
1 WIFE @21085@
1 _UST MARRIED
1 CHIL @20950@
0 @21092@ FAM
1 HUSB @21090@
1 _UST MARRIED
1 CHIL @21079@
0 @21106@ FAM
1 HUSB @21097@
1 WIFE @21104@
1 _UST MARRIED
1 CHIL @21090@
0 @21117@ FAM
1 HUSB @21109@
1 WIFE @21115@
1 _UST MARRIED
1 CHIL @21097@
0 @21128@ FAM
1 HUSB @21120@
1 WIFE @21126@
1 _UST MARRIED
1 CHIL @21109@
0 @21140@ FAM
1 HUSB @21131@
1 WIFE @21138@
1 _UST MARRIED
1 CHIL @21148@
1 CHIL @21120@
1 CHIL @21143@
0 @21165@ FAM
1 HUSB @21163@
1 WIFE @21143@
1 _UST MARRIED
1 CHIL @21166@
0 @21202@ FAM
1 HUSB @21200@
1 _UST MARRIED
1 CHIL @20906@
0 @21321@ FAM
1 HUSB @21238@
1 WIFE @21320@
1 _UST MARRIED
1 NOTE sans postérité
0 @31059@ FAM
1 HUSB @21245@
1 WIFE @31057@
1 _UST MARRIED
1 CHIL @20168@
0 @21394@ FAM
1 HUSB @21392@
1 WIFE @21360@
1 _UST MARRIED
1 MARR
2 DATE 1964
2 PLAC Épône,78680,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.954760
4 LONG E1.822330
2 _FNA NO
0 @21400@ FAM
1 HUSB @21397@
1 WIFE @21365@
1 _UST MARRIED
1 CHIL @21402@
0 @21580@ FAM
1 HUSB @21558@
1 WIFE @21573@
1 _UST MARRIED
1 CHIL @20175@
1 CHIL @22796@
0 @21590@ FAM
1 HUSB @21583@
1 _UST MARRIED
1 CHIL @21558@
0 @21644@ FAM
1 HUSB @21642@
1 WIFE @21634@
1 _UST MARRIED
1 CHIL @21645@
1 CHIL @21650@
1 CHIL @21692@
1 CHIL @21738@
0 @21661@ FAM
1 HUSB @21658@
1 WIFE @21650@
1 _UST MARRIED
1 CHIL @21662@
1 CHIL @21669@
1 CHIL @21677@
1 CHIL @21685@
0 @21701@ FAM
1 HUSB @21692@
1 WIFE @21699@
1 _UST MARRIED
1 CHIL @21702@
1 CHIL @21709@
1 CHIL @21716@
1 CHIL @21723@
1 CHIL @21731@
0 @21753@ FAM
1 HUSB @21751@
1 WIFE @21743@
1 _UST MARRIED
1 CHIL @21756@
1 CHIL @21764@
1 MARR
2 DATE 1954
2 _FNA NO
0 @21864@ FAM
1 HUSB @21831@
1 WIFE @21862@
1 _UST MARRIED
1 CHIL @21866@
1 CHIL @21873@
1 CHIL @21880@
0 @21910@ FAM
1 HUSB @21838@
1 WIFE @21908@
1 _UST MARRIED
1 CHIL @21911@
1 CHIL @21918@
1 CHIL @21926@
0 @21938@ FAM
1 HUSB @21936@
1 WIFE @21845@
1 _UST MARRIED
1 CHIL @21939@
1 CHIL @21947@
0 @21962@ FAM
1 HUSB @21959@
1 WIFE @21852@
1 _UST MARRIED
1 CHIL @21964@
1 CHIL @21971@
1 CHIL @21978@
0 @21890@ FAM
1 HUSB @21866@
1 WIFE @21888@
1 _UST MARRIED
1 CHIL @21893@
0 @21906@ FAM
1 HUSB @21904@
1 WIFE @21873@
1 _UST MARRIED
1 MARR
2 DATE NOV 2010
2 _FNA NO
0 @21988@ FAM
1 HUSB @21986@
1 WIFE @21964@
1 _UST MARRIED
1 CHIL @21992@
1 CHIL @21999@
1 CHIL @22006@
1 MARR
2 DATE 14 NOV 2009
2 _FNA NO
0 @22018@ FAM
1 HUSB @22016@
1 WIFE @21971@
1 _UST MARRIED
1 MARR
2 DATE 13 MAY 2010
2 _FNA NO
0 @22083@ FAM
1 HUSB @22058@
1 _UST MARRIED
1 CHIL @22079@
1 CHIL @22087@
0 @22098@ FAM
1 HUSB @22065@
1 _UST MARRIED
1 CHIL @22094@
1 CHIL @22101@
0 @22111@ FAM
1 HUSB @22072@
1 _UST MARRIED
1 CHIL @22107@
1 CHIL @22114@
0 @22198@ FAM
1 HUSB @22136@
1 WIFE @22191@
1 _UST MARRIED
1 CHIL @21412@
1 CHIL @22207@
0 @22239@ FAM
1 HUSB @22141@
1 WIFE @22235@
1 _UST MARRIED
1 CHIL @22217@
1 CHIL @22243@
1 CHIL @22250@
1 CHIL @22257@
1 CHIL @22264@
1 MARR
2 DATE 7 OCT 1972
2 PLAC Cour-sur-Loire,41500,Loir-et-Cher,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N47.650620
4 LONG E1.423780
2 _FNA NO
0 @22686@ FAM
1 HUSB @22679@
1 WIFE @22152@
1 _UST MARRIED
1 CHIL @22689@
1 CHIL @22698@
1 CHIL @22708@
1 MARR
2 DATE 3 SEP 1949
2 PLAC Joigny,89300,Yonne,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.982880
4 LONG E3.400960
2 _FNA NO
0 @22277@ FAM
1 HUSB @22272@
1 WIFE @22217@
1 _UST MARRIED
1 CHIL @22279@
1 CHIL @22292@
1 MARR
2 DATE 7 JUL 2001
2 _FNA NO
0 @22318@ FAM
1 HUSB @22311@
1 WIFE @22250@
1 _UST MARRIED
1 CHIL @22321@
1 CHIL @22337@
1 CHIL @22353@
1 CHIL @22361@
1 CHIL @22368@
1 MARR
2 DATE 14 AUG 1998
2 PLAC Cour-sur-Loire,41500,Loir-et-Cher,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N47.650620
4 LONG E1.423780
2 _FNA NO
0 @22426@ FAM
1 HUSB @22375@
1 WIFE @22414@
1 _UST MARRIED
1 CHIL @22428@
1 CHIL @22469@
1 CHIL @22499@
1 CHIL @22508@
1 MARR
2 DATE 1974
2 PLAC Quincampoix,76230,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.527100
4 LONG E1.188480
2 _FNA NO
0 @22530@ FAM
1 HUSB @22382@
1 WIFE @22526@
1 _UST MARRIED
1 CHIL @22533@
1 CHIL @22541@
1 MARR
2 DATE 22 JUN 1985
2 PLAC Saint Matthieu,,Haute-Vienne,,,
2 _FNA NO
0 @22568@ FAM
1 HUSB @22564@
1 WIFE @22389@
1 _UST MARRIED
1 MARR
2 DATE 28 OCT 1993
2 PLAC Londres,,,,,
2 _FNA NO
2 NOTE islington greater london
0 @22574@ FAM
1 HUSB @22572@
1 WIFE @22396@
1 _UST MARRIED
1 CHIL @22624@
1 CHIL @22576@
1 CHIL @22584@
1 CHIL @22591@
1 CHIL @22598@
1 MARR
2 DATE 1976
2 PLAC Saint-Denis-sur-Ouanne,89120,Yonne,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.822140
4 LONG E3.130270
2 _FNA NO
0 @22424@ FAM
1 HUSB @22403@
1 WIFE @22422@
1 _UST MARRIED
1 MARR
2 DATE 27 OCT 2012
2 PLAC Saint-Germain-en-Laye,78100,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.900000
4 LONG E2.083330
2 _FNA NO
0 @22438@ FAM
1 HUSB @22436@
1 WIFE @22428@
1 _UST MARRIED
1 CHIL @22441@
1 CHIL @22448@
1 MARR
2 DATE 15 JUL 2000
2 _FNA NO
0 @22459@ FAM
1 HUSB @22457@
1 WIFE @22428@
1 _UST MARRIED
1 CHIL @22462@
1 MARR
2 DATE 2010
2 PLAC Australie,,,,,
2 _FNA NO
0 @22483@ FAM
1 HUSB @22469@
1 WIFE @22481@
1 _UST MARRIED
1 CHIL @22485@
1 CHIL @22492@
1 MARR
2 DATE NOV 2010
2 _FNA NO
0 @22522@ FAM
1 HUSB @22517@
1 WIFE @22508@
1 _UST MARRIED
1 MARR
2 DATE 29 JUN 2012
2 _FNA NO
0 @22552@ FAM
1 HUSB @22533@
1 _UST MARRIED
1 CHIL @22548@
1 CHIL @22555@
0 @22608@ FAM
1 HUSB @22606@
1 WIFE @22576@
1 _UST MARRIED
1 CHIL @22610@
1 CHIL @22617@
1 MARR
2 DATE 29 JUL 2006
2 PLAC Saint-Denis-sur-Ouanne,89120,Yonne,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.822140
4 LONG E3.130270
2 _FNA NO
0 @22634@ FAM
1 HUSB @22632@
1 WIFE @22624@
1 _UST MARRIED
1 CHIL @22636@
1 MARR
2 DATE 2011
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @22719@ FAM
1 HUSB @22717@
1 WIFE @22689@
1 _UST MARRIED
1 CHIL @22721@
1 CHIL @22728@
1 CHIL @22735@
1 MARR
2 DATE 30 JUN 1973
2 PLAC Contremoulins,76400,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.725850
4 LONG E0.427760
2 _FNA NO
0 @22810@ FAM
1 HUSB @22802@
1 WIFE @22796@
1 _UST MARRIED
1 CHIL @22814@
0 @22827@ FAM
1 HUSB @22824@
1 WIFE @22814@
1 _UST MARRIED
1 MARR
2 DATE 1907
2 _FNA NO
0 @22880@ FAM
1 HUSB @22853@
1 WIFE @22869@
1 _UST MARRIED
1 CHIL @22884@
1 CHIL @19342@
0 @22940@ FAM
1 HUSB @22929@
1 WIFE @22938@
1 _UST MARRIED
1 CHIL @18547@
0 @33394@ FAM
1 HUSB @23015@
1 WIFE @33390@
1 _UST MARRIED
1 CHIL @584@
1 CHIL @23023@
0 @23039@ FAM
1 HUSB @23030@
1 WIFE @23037@
1 _UST MARRIED
1 CHIL @2218@
0 @23103@ FAM
1 HUSB @23082@
1 WIFE @23097@
1 _UST MARRIED
1 CHIL @6922@
1 CHIL @23110@
1 MARR
2 DATE 3 MAY 1819
2 PLAC Pointe-à-Pitre,97110,Guadeloupe,Guadeloupe,FRANCE,
3 MAP
4 LATI N16.242200
4 LONG W61.534300
2 _FNA NO
0 @23177@ FAM
1 HUSB @23110@
1 WIFE @23171@
1 _UST MARRIED
1 CHIL @36981@
0 @23153@ FAM
1 HUSB @23136@
1 WIFE @23142@
1 _UST MARRIED
1 CHIL @23082@
0 @23241@ FAM
1 HUSB @23231@
1 WIFE @23239@
1 _UST MARRIED
1 CHIL @16461@
0 @23275@ FAM
1 HUSB @23255@
1 WIFE @23271@
1 _UST MARRIED
1 CHIL @16453@
1 CHIL @33069@
1 MARR
2 DATE 28 JAN 1826
2 PLAC Crespières,78121,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883170
4 LONG E1.921510
2 _FNA NO
0 @23300@ FAM
1 HUSB @23281@
1 WIFE @23293@
1 _UST MARRIED
1 CHIL @23255@
1 MARR
2 DATE 31 JUL 1792
2 PLAC Crespières,78121,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883170
4 LONG E1.921510
2 _FNA NO
0 @23327@ FAM
1 HUSB @23304@
1 WIFE @23316@
1 _UST MARRIED
1 CHIL @23293@
1 MARR
2 DATE 19 NOV 1765
2 PLAC Plaisir,78370,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.823190
4 LONG E1.954100
2 _FNA NO
0 @23353@ FAM
1 HUSB @23331@
1 WIFE @23342@
1 _UST MARRIED
1 CHIL @23281@
0 @23377@ FAM
1 HUSB @23362@
1 WIFE @23373@
1 _UST MARRIED
1 CHIL @23331@
1 MARR
2 DATE 20 JUL 1722
2 PLAC Crespières,78121,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883170
4 LONG E1.921510
2 _FNA NO
0 @23411@ FAM
1 HUSB @23392@
1 WIFE @23404@
1 _UST MARRIED
1 CHIL @23342@
0 @23447@ FAM
1 HUSB @23430@
1 WIFE @23440@
1 _UST MARRIED
1 CHIL @23362@
0 @23468@ FAM
1 HUSB @23450@
1 WIFE @23461@
1 _UST MARRIED
1 CHIL @23373@
1 MARR
2 DATE 30 APR 1696
2 PLAC Crespières,78121,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883170
4 LONG E1.921510
2 _FNA NO
0 @23485@ FAM
1 HUSB @23473@
1 WIFE @23481@
1 _UST MARRIED
1 CHIL @23450@
0 @23503@ FAM
1 HUSB @23488@
1 WIFE @23496@
1 _UST MARRIED
1 CHIL @23461@
0 @23518@ FAM
1 HUSB @23509@
1 WIFE @23516@
1 _UST MARRIED
1 CHIL @959@
1 CHIL @23527@
1 CHIL @23532@
1 CHIL @23537@
1 CHIL @23542@
1 CHIL @23547@
1 CHIL @23552@
0 @23524@ FAM
1 HUSB @23522@
1 _UST MARRIED
1 CHIL @23516@
0 @23579@ FAM
1 HUSB @23565@
1 WIFE @23577@
1 _UST MARRIED
1 CHIL @1036@
0 @23592@ FAM
1 HUSB @23583@
1 WIFE @23590@
1 _UST MARRIED
1 CHIL @23577@
0 @23597@ FAM
1 HUSB @23595@
1 _UST MARRIED
1 CHIL @1067@
0 @23602@ FAM
1 HUSB @23600@
1 _UST MARRIED
1 CHIL @23595@
0 @23625@ FAM
1 HUSB @23610@
1 WIFE @23622@
1 _UST MARRIED
1 CHIL @3633@
0 @23709@ FAM
1 HUSB @23707@
1 WIFE @23691@
1 _UST MARRIED
1 MARR
2 DATE 19 NOV 1966
2 PLAC Montsoué,40500,Landes,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N43.732820
4 LONG W0.507760
2 _FNA NO
0 @32057@ FAM
1 HUSB @32055@
1 WIFE @23691@
1 _UST MARRIED
0 @23940@ FAM
1 HUSB @23930@
1 WIFE @23938@
1 _UST MARRIED
1 CHIL @8055@
0 @23960@ FAM
1 HUSB @23946@
1 WIFE @23957@
1 _UST MARRIED
1 CHIL @23930@
0 @23981@ FAM
1 HUSB @23966@
1 WIFE @23977@
1 _UST MARRIED
1 CHIL @23985@
1 CHIL @8023@
1 CHIL @24919@
1 CHIL @25059@
1 MARR
2 DATE 1878
2 _FNA NO
0 @24150@ FAM
1 HUSB @24143@
1 WIFE @23985@
1 _UST MARRIED
1 CHIL @24152@
1 MARR
2 DATE 1898
2 _FNA NO
0 @24018@ FAM
1 HUSB @24000@
1 WIFE @24011@
1 _UST MARRIED
1 CHIL @24602@
1 CHIL @24297@
1 CHIL @24592@
1 CHIL @23966@
1 NOTE 4 enfants
1 MARR
2 DATE 19 JUN 1839
2 PLAC Lambersart,59130,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.650000
4 LONG E3.033330
2 _FNA NO
0 @24287@ FAM
1 HUSB @24000@
1 WIFE @24285@
1 _UST MARRIED
1 CHIL @24623@
1 CHIL @24633@
1 NOTE 2 fils
1 MARR
2 DATE 4 OCT 1824
2 PLAC Lille,59000,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.632970
4 LONG E3.058580
2 _FNA NO
0 @24029@ FAM
1 HUSB @24021@
1 WIFE @24027@
1 _UST MARRIED
1 CHIL @23977@
0 @24052@ FAM
1 HUSB @24033@
1 WIFE @24045@
1 _UST MARRIED
1 CHIL @7433@
1 CHIL @24058@
1 CHIL @24061@
1 MARR
2 DATE 28 JAN 1839
2 PLAC Montpellier,34000,Hérault,Occitanie,FRANCE,
3 MAP
4 LATI N43.610920
4 LONG E3.877230
2 _FNA NO
0 @24104@ FAM
1 HUSB @24066@
1 WIFE @24089@
1 _UST MARRIED
1 CHIL @24000@
1 CHIL @24485@
1 MARR
2 DATE 1809
2 _FNA NO
0 @24139@ FAM
1 HUSB @24111@
1 WIFE @24132@
1 _UST MARRIED
1 CHIL @24011@
1 NOTE 7 enfants
2 CONT Justine Clemence (née Bernard)
2 CONT Sophie Félicité Caroline (née Bernard)
2 CONT Adele Agustine Clotilde (née Bernard)
2 CONT Gustave Alexandre Bernard
2 CONT Élise Bernard
2 CONT Elisabeth Clotilde Caroline (née Bernard)
2 CONT Alfred Bernard
2 CONT
1 MARR
2 DATE 2 SEP 1805
2 PLAC Lille,59000,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.632970
4 LONG E3.058580
2 _FNA NO
0 @24171@ FAM
1 HUSB @24164@
1 WIFE @24152@
1 _UST MARRIED
1 CHIL @24173@
1 MARR
2 DATE 1922
2 _FNA NO
0 @24181@ FAM
1 HUSB @24173@
1 WIFE @24179@
1 _UST MARRIED
1 CHIL @24182@
1 CHIL @24188@
1 CHIL @24193@
1 CHIL @24198@
0 @24206@ FAM
1 HUSB @24182@
1 WIFE @24204@
1 _UST MARRIED
1 CHIL @24207@
0 @24215@ FAM
1 HUSB @24188@
1 WIFE @24213@
1 _UST MARRIED
1 CHIL @24217@
1 CHIL @24222@
0 @24230@ FAM
1 HUSB @24228@
1 WIFE @24193@
1 _UST MARRIED
1 CHIL @24232@
0 @24240@ FAM
1 HUSB @24238@
1 WIFE @24198@
1 _UST MARRIED
1 CHIL @24241@
0 @24371@ FAM
1 HUSB @24297@
1 WIFE @24364@
1 _UST MARRIED
1 CHIL @24813@
1 CHIL @24373@
1 CHIL @24710@
1 CHIL @24383@
1 MARR
2 DATE 3 AUG 1869
2 PLAC Valenciennes,59300,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.350000
4 LONG E3.533330
2 _FNA NO
0 @24328@ FAM
1 HUSB @24308@
1 WIFE @24321@
1 _UST MARRIED
1 CHIL @24089@
0 @24351@ FAM
1 HUSB @24333@
1 WIFE @24347@
1 _UST MARRIED
1 CHIL @24066@
0 @24413@ FAM
1 HUSB @24373@
1 WIFE @24406@
1 _UST MARRIED
1 CHIL @24415@
1 MARR
2 DATE 1899
2 _FNA NO
0 @24443@ FAM
1 HUSB @24436@
1 WIFE @24383@
1 _UST MARRIED
1 CHIL @24445@
1 MARR
2 DATE 1899
2 _FNA NO
0 @24433@ FAM
1 HUSB @24426@
1 WIFE @24415@
1 _UST MARRIED
1 MARR
2 DATE 1933
2 _FNA NO
0 @24464@ FAM
1 HUSB @24457@
1 WIFE @24445@
1 _UST MARRIED
1 CHIL @24466@
1 MARR
2 DATE 1927
2 _FNA NO
0 @24474@ FAM
1 HUSB @24466@
1 WIFE @24472@
1 _UST MARRIED
1 CHIL @24475@
0 @24483@ FAM
1 HUSB @24475@
1 WIFE @24481@
1 _UST MARRIED
0 @24508@ FAM
1 HUSB @24485@
1 WIFE @24501@
1 _UST MARRIED
1 CHIL @24511@
1 CHIL @24521@
1 MARR
2 DATE 21 NOV 1838
2 PLAC Loos,59120,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.609820
4 LONG E3.018740
2 _FNA NO
0 @24539@ FAM
1 HUSB @24532@
1 WIFE @24511@
1 _UST MARRIED
1 CHIL @24541@
1 MARR
2 DATE 1860
2 _FNA NO
0 @24589@ FAM
1 HUSB @24521@
1 WIFE @24582@
1 _UST MARRIED
1 NOTE 2 fils
1 MARR
2 DATE 1869
2 _FNA NO
0 @24559@ FAM
1 HUSB @24541@
1 WIFE @24552@
1 _UST MARRIED
1 CHIL @24561@
1 MARR
2 DATE 1890
2 _FNA NO
0 @24579@ FAM
1 HUSB @24561@
1 WIFE @24572@
1 _UST MARRIED
1 MARR
2 DATE 1935
2 _FNA NO
0 @24620@ FAM
1 HUSB @24613@
1 WIFE @24592@
1 _UST MARRIED
1 NOTE 7 enfants
1 MARR
2 DATE 3 FEB 1865
2 _FNA NO
0 @24655@ FAM
1 HUSB @24602@
1 WIFE @24648@
1 _UST MARRIED
1 CHIL @24656@
1 CHIL @24666@
1 CHIL @24676@
1 CHIL @24683@
1 CHIL @24690@
1 CHIL @24700@
0 @24872@ FAM
1 HUSB @24857@
1 WIFE @24683@
1 _UST MARRIED
1 MARR
2 DATE 27 MAR 1895
2 _FNA NO
0 @24745@ FAM
1 HUSB @24710@
1 WIFE @24734@
1 _UST MARRIED
1 CHIL @24747@
1 CHIL @24760@
1 CHIL @24770@
1 CHIL @24793@
1 MARR
2 DATE 16 DEC 1916
2 PLAC Paris 07,,,,,
2 _FNA NO
2 NOTE OU 1912?
0 @24845@ FAM
1 HUSB @24826@
1 WIFE @24813@
1 _UST MARRIED
1 MARR
2 DATE 30 JUN 1896
2 PLAC Malo-les-Bains,,,,,
2 _FNA NO
0 @24953@ FAM
1 HUSB @24919@
1 WIFE @24938@
1 _UST MARRIED
1 CHIL @24995@
1 CHIL @24983@
1 MARR
2 DATE 9 AUG 1921
2 PLAC Belmesnil,76590,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.770820
4 LONG E1.050120
2 _FNA NO
0 @25016@ FAM
1 HUSB @24983@
1 WIFE @25011@
1 _UST MARRIED
1 CHIL @25018@
1 CHIL @25028@
1 CHIL @25039@
1 CHIL @25049@
0 @25092@ FAM
1 HUSB @25082@
1 WIFE @25090@
1 _UST MARRIED
1 CHIL @16521@
0 @25106@ FAM
1 HUSB @25097@
1 WIFE @25104@
1 _UST MARRIED
1 CHIL @16537@
0 @25377@ FAM
1 HUSB @25368@
1 WIFE @25375@
1 _UST MARRIED
1 CHIL @10740@
0 @25403@ FAM
1 HUSB @25388@
1 WIFE @25400@
1 _UST MARRIED
1 CHIL @25423@
1 CHIL @12769@
1 CHIL @25434@
1 CHIL @25446@
0 @25418@ FAM
1 HUSB @25407@
1 WIFE @25416@
1 _UST MARRIED
1 CHIL @25388@
0 @25628@ FAM
1 HUSB @25513@
1 WIFE @25626@
1 _UST MARRIED
0 @25589@ FAM
1 HUSB @25577@
1 WIFE @25586@
1 _UST MARRIED
1 CHIL @12796@
1 CHIL @25645@
1 CHIL @25513@
1 CHIL @25629@
1 CHIL @25637@
0 @25604@ FAM
1 HUSB @25594@
1 WIFE @25601@
1 _UST MARRIED
1 CHIL @25586@
0 @30103@ FAM
1 HUSB @25629@
1 WIFE @30102@
1 _UST MARRIED
0 @25660@ FAM
1 HUSB @25658@
1 WIFE @25645@
1 _UST MARRIED
1 CHIL @25662@
0 @25688@ FAM
1 HUSB @25662@
1 _UST MARRIED
1 CHIL @25686@
1 CHIL @25699@
1 CHIL @25712@
1 CHIL @25724@
0 @25696@ FAM
1 HUSB @25694@
1 WIFE @25686@
1 _UST MARRIED
0 @25751@ FAM
1 HUSB @25749@
1 WIFE @25738@
1 _UST MARRIED
0 @25797@ FAM
1 HUSB @25770@
1 WIFE @25794@
1 _UST MARRIED
1 CHIL @30060@
1 NOTE 6 ENFANTS
2 CONT 1 SEUL AVEC DESCENDANCE= LE DERNIER
1 MARR
2 DATE 1 DEC 1813
2 _FNA NO
0 @26880@ FAM
1 HUSB @25888@
1 WIFE @26868@
1 _UST MARRIED
1 CHIL @26026@
1 CHIL @12854@
1 CHIL @27147@
1 CHIL @27155@
1 CHIL @27166@
1 CHIL @27175@
1 CHIL @27201@
1 CHIL @27210@
1 CHIL @27227@
1 MARR
2 DATE 19 JUN 1690
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
0 @25935@ FAM
1 HUSB @25924@
1 WIFE @25933@
1 _UST MARRIED
1 CHIL @25851@
1 MARR
2 DATE 7 JUN 1733
2 _FNA NO
0 @25967@ FAM
1 HUSB @25946@
1 WIFE @25960@
1 _UST MARRIED
1 CHIL @25924@
0 @25979@ FAM
1 HUSB @25971@
1 WIFE @25977@
1 _UST MARRIED
1 CHIL @25960@
0 @26010@ FAM
1 HUSB @26008@
1 WIFE @25989@
1 _UST MARRIED
1 CHIL @29427@
1 CHIL @29408@
1 MARR
2 DATE 5 SEP 1778
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
0 @26023@ FAM
1 HUSB @26014@
1 WIFE @26021@
1 _UST MARRIED
1 CHIL @25905@
0 @27126@ FAM
1 HUSB @26026@
1 WIFE @27119@
1 _UST MARRIED
1 CHIL @27078@
1 CHIL @27133@
1 CHIL @27138@
1 MARR
2 DATE 22 JUN 1726
2 _FNA NO
0 @26146@ FAM
1 HUSB @26135@
1 WIFE @26143@
1 _UST MARRIED
1 CHIL @26565@
1 CHIL @26574@
1 CHIL @26314@
1 CHIL @26582@
1 CHIL @26598@
1 CHIL @26614@
1 CHIL @26630@
1 CHIL @26641@
1 CHIL @26652@
1 CHIL @26663@
1 CHIL @26677@
1 CHIL @12820@
1 NOTE dispense du pape Clément VI pour 4° degré consanguinité , mêlé de 3°
2 CONT 12 ENFANTS dont 2 seuls reproduits= MJJ BEVIERE-PICQUERY et PMJ BEVIERE
1 MARC MAITRE DIESME
2 DATE 28 OCT 1701
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 MARR
2 DATE 3 NOV 1701
2 _FNA NO
0 @27298@ FAM
1 WIFE @26164@
1 _UST MARRIED
1 CHIL @26459@
0 @28547@ FAM
1 HUSB @26469@
1 WIFE @26164@
1 _UST MARRIED
1 CHIL @27282@
0 @26177@ FAM
1 HUSB @26169@
1 WIFE @26175@
1 _UST MARRIED
1 CHIL @29073@
0 @26206@ FAM
1 HUSB @26198@
1 WIFE @26204@
1 _UST MARRIED
1 CHIL @27020@
1 CHIL @27027@
1 CHIL @25888@
1 CHIL @12843@
1 CHIL @26991@
1 CHIL @27004@
1 CHIL @27012@
0 @26217@ FAM
1 HUSB @26209@
1 WIFE @26215@
1 _UST MARRIED
1 CHIL @26198@
0 @26288@ FAM
1 HUSB @26281@
1 WIFE @26270@
1 _UST MARRIED
1 CHIL @28483@
1 CHIL @28493@
1 MARR
2 DATE 1 JUN 1713
2 _FNA NO
0 @26312@ FAM
1 HUSB @26297@
1 WIFE @26275@
1 _UST MARRIED
1 MARR
2 DATE 28 DEC 1755
2 _FNA NO
0 @26337@ FAM
1 HUSB @26334@
1 WIFE @26314@
1 _UST MARRIED
1 CHIL @26342@
1 CHIL @26365@
1 CHIL @26385@
1 MARR
2 DATE 13 JUN 1730
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
0 @26454@ FAM
1 HUSB @26325@
1 WIFE @26452@
1 _UST MARRIED
1 CHIL @26143@
1 CHIL @26545@
1 CHIL @26551@
1 CHIL @26557@
1 CHIL @28673@
1 CHIL @28684@
1 MARR
2 DATE 27 NOV 1664
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
0 @28320@ FAM
1 HUSB @28301@
1 WIFE @26342@
1 _UST MARRIED
1 CHIL @28957@
1 CHIL @28845@
1 CHIL @28866@
1 CHIL @28893@
1 CHIL @28931@
1 CHIL @28978@
1 MARR
2 DATE 22 DEC 1764
2 PLAC Maubeuge,59600,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.278750
4 LONG E3.972670
2 _FNA NO
0 @28998@ FAM
1 HUSB @26365@
1 WIFE @28994@
1 _UST MARRIED
0 @29040@ FAM
1 HUSB @26385@
1 WIFE @29005@
1 _UST MARRIED
1 CHIL @29467@
1 CHIL @29492@
1 CHIL @29551@
1 CHIL @29530@
1 MARR
2 DATE 7 FEB 1762
2 PLAC Maubeuge,59600,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.278750
4 LONG E3.972670
2 _FNA NO
0 @26444@ FAM
1 HUSB @26436@
1 WIFE @26442@
1 _UST MARRIED
1 CHIL @26842@
1 CHIL @26825@
1 CHIL @26850@
1 CHIL @26861@
1 CHIL @26868@
1 CHIL @26830@
1 CHIL @26835@
1 CHIL @26881@
1 CHIL @26135@
1 CHIL @26899@
1 CHIL @26818@
1 MARR
2 DATE 1653
2 _FNA NO
0 @26466@ FAM
1 HUSB @26464@
1 WIFE @26459@
1 _UST MARRIED
1 CHIL @26325@
0 @26486@ FAM
1 HUSB @26479@
1 WIFE @26484@
1 _UST MARRIED
1 CHIL @26442@
1 CHIL @26789@
1 CHIL @26782@
1 CHIL @26806@
1 CHIL @26812@
0 @26491@ FAM
1 HUSB @26489@
1 _UST MARRIED
1 CHIL @26164@
1 CHIL @26484@
1 CHIL @27299@
1 CHIL @27303@
1 CHIL @28635@
0 @26510@ FAM
1 HUSB @26502@
1 WIFE @26508@
1 _UST MARRIED
1 CHIL @26489@
0 @26538@ FAM
1 HUSB @26530@
1 WIFE @26536@
1 _UST MARRIED
1 CHIL @26452@
0 @26707@ FAM
1 HUSB @26699@
1 WIFE @26705@
1 _UST MARRIED
1 CHIL @26436@
1 CHIL @26764@
1 CHIL @27366@
1 CHIL @27373@
1 CHIL @27387@
1 CHIL @27330@
1 CHIL @27319@
1 MARR
2 DATE 1614
2 _FNA NO
0 @27405@ FAM
1 HUSB @26699@
1 WIFE @27403@
1 _UST MARRIED
1 CHIL @27407@
1 CHIL @27418@
1 MARR
2 DATE 1637
2 _FNA NO
0 @26732@ FAM
1 HUSB @26720@
1 WIFE @26728@
1 _UST MARRIED
1 CHIL @26479@
1 CHIL @26735@
1 CHIL @26740@
0 @28263@ FAM
1 HUSB @26735@
1 WIFE @28261@
1 _UST MARRIED
1 MARR
2 DATE 5 MAR 1630
2 PLAC Valenciennes,59300,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.350000
4 LONG E3.533330
2 _FNA NO
0 @26748@ FAM
1 HUSB @26746@
1 WIFE @26740@
1 _UST MARRIED
0 @26779@ FAM
1 HUSB @26771@
1 WIFE @26777@
1 _UST MARRIED
1 CHIL @26502@
0 @26800@ FAM
1 HUSB @26798@
1 WIFE @26782@
1 _UST MARRIED
1 CHIL @28274@
1 CHIL @28279@
1 CHIL @28284@
1 MARR
2 DATE 26 JAN 1670
2 _FNA NO
0 @26805@ FAM
1 HUSB @26803@
1 WIFE @26789@
1 _UST MARRIED
0 @28340@ FAM
1 HUSB @26825@
1 WIFE @28338@
1 _UST MARRIED
0 @26879@ FAM
1 HUSB @26876@
1 WIFE @26861@
1 _UST MARRIED
1 CHIL @26953@
1 CHIL @28350@
1 CHIL @28389@
1 CHIL @28400@
1 MARR
2 DATE 25 NOV 1679
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
0 @26898@ FAM
1 HUSB @26895@
1 WIFE @26881@
1 _UST MARRIED
1 CHIL @27253@
1 CHIL @27260@
1 MARC MAITRE DIESME
2 DATE 25 MAY 1689
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
1 MARR
2 DATE 5 JUN 1689
2 _FNA NO
0 @26908@ FAM
1 HUSB @26906@
1 WIFE @26899@
1 _UST MARRIED
1 NOTE cousins 2 bans et 3 degrés
2 CONT PAS D’ENFANTS
1 MARR
2 DATE 19 NOV 1713
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
0 @27042@ FAM
1 HUSB @27039@
1 WIFE @27020@
1 _UST MARRIED
0 @27037@ FAM
1 HUSB @27035@
1 WIFE @27027@
1 _UST MARRIED
0 @27054@ FAM
1 HUSB @27046@
1 WIFE @27052@
1 _UST MARRIED
1 CHIL @26215@
0 @27065@ FAM
1 HUSB @27057@
1 WIFE @27063@
1 _UST MARRIED
1 CHIL @27046@
0 @27098@ FAM
1 HUSB @27078@
1 WIFE @27095@
1 _UST MARRIED
1 CHIL @27099@
0 @27189@ FAM
1 HUSB @27185@
1 WIFE @27175@
1 _UST MARRIED
1 CHIL @27192@
1 MARR
2 DATE 15 JUN 1730
2 _FNA NO
0 @28436@ FAM
1 HUSB @28433@
1 WIFE @27201@
1 _UST MARRIED
1 MARR
2 DATE 5 MAY 1740
2 _FNA NO
0 @28438@ FAM
1 HUSB @27223@
1 WIFE @27210@
1 _UST MARRIED
1 MARR
2 DATE 29 APR 1737
2 _FNA NO
0 @28464@ FAM
1 HUSB @27253@
1 WIFE @28461@
1 _UST MARRIED
1 CHIL @28465@
0 @27290@ FAM
1 HUSB @27282@
1 WIFE @27288@
1 _UST MARRIED
1 CHIL @28515@
1 CHIL @26906@
1 CHIL @28526@
1 CHIL @28560@
0 @28539@ FAM
1 HUSB @28538@
1 WIFE @27288@
1 _UST MARRIED
1 CHIL @28541@
0 @27340@ FAM
1 HUSB @27338@
1 WIFE @27330@
1 _UST MARRIED
0 @27357@ FAM
1 HUSB @27349@
1 WIFE @27355@
1 _UST MARRIED
1 CHIL @26705@
1 CHIL @28216@
0 @27362@ FAM
1 HUSB @27360@
1 _UST MARRIED
1 CHIL @27355@
0 @28212@ FAM
1 HUSB @28210@
1 WIFE @27403@
1 _UST MARRIED
1 MARR
2 DATE 1645
2 _FNA NO
2 NOTE VERS
0 @28237@ FAM
1 HUSB @28235@
1 WIFE @27418@
1 _UST MARRIED
1 CHIL @28253@
1 MARR
2 DATE 3 MAY 1666
2 _FNA NO
0 @28244@ FAM
1 HUSB @28240@
1 WIFE @27418@
1 _UST MARRIED
1 CHIL @28246@
1 MARR
2 DATE 25 OCT 1676
2 _FNA NO
0 @27434@ FAM
1 HUSB @27432@
1 WIFE @27425@
1 _UST MARRIED
1 CHIL @27438@
1 CHIL @13961@
1 CHIL @27443@
1 CHIL @27448@
1 MARR
2 DATE 23 MAY 1949
2 PLAC Mareuil-le-Port,51700,Marne,Grand Est,FRANCE,
3 MAP
4 LATI N49.081790
4 LONG E3.760110
2 _FNA NO
0 @27520@ FAM
1 HUSB @27518@
1 WIFE @27438@
1 _UST MARRIED
1 CHIL @27521@
1 CHIL @27526@
1 CHIL @27531@
0 @27456@ FAM
1 HUSB @27454@
1 WIFE @27448@
1 _UST MARRIED
0 @27492@ FAM
1 HUSB @27468@
1 WIFE @27485@
1 _UST MARRIED
1 CHIL @27425@
0 @27514@ FAM
1 HUSB @27496@
1 WIFE @27507@
1 _UST MARRIED
1 CHIL @27563@
1 CHIL @27432@
1 CHIL @27580@
1 MARR
2 DATE 18 FEB 1920
2 PLAC Paris 07,,,,,
2 _FNA NO
0 @27624@ FAM
1 HUSB @27563@
1 WIFE @27612@
1 _UST MARRIED
1 CHIL @27626@
1 CHIL @27645@
1 CHIL @27660@
1 MARR
2 DATE 1944
2 _FNA NO
0 @27608@ FAM
1 HUSB @27598@
1 WIFE @27580@
1 _UST MARRIED
0 @27752@ FAM
1 HUSB @27708@
1 WIFE @27737@
1 _UST MARRIED
1 CHIL @27780@
1 CHIL @27810@
1 CHIL @27825@
1 CHIL @27855@
1 CHIL @13925@
1 CHIL @27867@
0 @36574@ FAM
1 HUSB @27810@
1 WIFE @36571@
1 _UST MARRIED
0 @27954@ FAM
1 HUSB @27914@
1 WIFE @27949@
1 _UST MARRIED
1 CHIL @27708@
0 @27981@ FAM
1 HUSB @27963@
1 WIFE @27977@
1 _UST MARRIED
1 CHIL @27737@
0 @28027@ FAM
1 HUSB @28008@
1 WIFE @28021@
1 _UST MARRIED
1 CHIL @13995@
0 @28172@ FAM
1 HUSB @28154@
1 WIFE @28167@
1 _UST MARRIED
1 CHIL @14028@
0 @28224@ FAM
1 HUSB @28222@
1 WIFE @28216@
1 _UST MARRIED
0 @28394@ FAM
1 HUSB @28389@
1 _UST MARRIED
1 CHIL @28357@
0 @28430@ FAM
1 HUSB @28420@
1 WIFE @28428@
1 _UST MARRIED
1 CHIL @27119@
0 @28453@ FAM
1 HUSB @28445@
1 WIFE @28451@
1 _UST MARRIED
1 CHIL @26895@
0 @28486@ FAM
1 HUSB @28483@
1 WIFE @28465@
1 _UST MARRIED
1 CHIL @29803@
1 MARR
2 DATE 7 JAN 1740
2 _FNA NO
0 @28598@ FAM
1 HUSB @28515@
1 _UST MARRIED
1 CHIL @28583@
1 CHIL @28606@
0 @28536@ FAM
1 HUSB @28533@
1 WIFE @28526@
1 _UST MARRIED
0 @28626@ FAM
1 HUSB @28583@
1 WIFE @28624@
1 _UST MARRIED
0 @28622@ FAM
1 HUSB @28606@
1 WIFE @28618@
1 _UST MARRIED
0 @28646@ FAM
1 HUSB @28640@
1 WIFE @28635@
1 _UST MARRIED
1 CHIL @28647@
0 @28664@ FAM
1 HUSB @28647@
1 WIFE @28662@
1 _UST MARRIED
1 MARR
2 DATE 10 JUL 1665
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
0 @28707@ FAM
1 HUSB @28706@
1 _UST MARRIED
1 CHIL @26334@
1 CHIL @28710@
0 @28718@ FAM
1 HUSB @28714@
1 WIFE @28710@
1 _UST MARRIED
1 CHIL @28720@
1 CHIL @28735@
0 @29043@ FAM
1 HUSB @28775@
1 WIFE @28799@
1 _UST MARRIED
1 CHIL @28301@
0 @28826@ FAM
1 HUSB @28817@
1 WIFE @28824@
1 _UST MARRIED
1 CHIL @28799@
0 @29058@ FAM
1 HUSB @29012@
1 WIFE @29055@
1 _UST MARRIED
1 CHIL @29005@
0 @29075@ FAM
1 HUSB @29068@
1 WIFE @29073@
1 _UST MARRIED
1 CHIL @29084@
1 CHIL @12837@
1 CHIL @29095@
1 CHIL @29103@
1 CHIL @29108@
1 MARR
2 DATE ABT 1643
2 PLAC Avesnes,62650,Pas-de-Calais,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.548450
4 LONG E1.972560
2 _FNA NO
0 @29157@ FAM
1 HUSB @29119@
1 WIFE @29155@
1 _UST MARRIED
1 CHIL @26281@
0 @29136@ FAM
1 HUSB @29127@
1 WIFE @29134@
1 _UST MARRIED
1 CHIL @29119@
0 @29147@ FAM
1 HUSB @29139@
1 WIFE @29145@
1 _UST MARRIED
1 CHIL @28428@
0 @29164@ FAM
1 HUSB @29160@
1 _UST MARRIED
1 CHIL @26297@
0 @29230@ FAM
1 HUSB @29213@
1 WIFE @29228@
1 _UST MARRIED
1 CHIL @26008@
1 MARR
2 DATE 11 SEP 1731
2 _FNA NO
0 @29252@ FAM
1 HUSB @29234@
1 WIFE @29250@
1 _UST MARRIED
1 CHIL @29272@
1 CHIL @29289@
1 CHIL @29257@
1 CHIL @29228@
0 @29349@ FAM
1 HUSB @29327@
1 WIFE @29338@
1 _UST MARRIED
1 CHIL @29354@
1 CHIL @29378@
1 CHIL @29366@
1 CHIL @29213@
1 CHIL @29391@
1 MARR
2 DATE 28 OCT 1703
2 _FNA NO
0 @29488@ FAM
1 HUSB @29467@
1 WIFE @29484@
1 _UST MARRIED
1 NOTE COUSINS
1 MARR
2 DATE 18 JAN 1810
2 _FNA NO
0 @29548@ FAM
1 HUSB @29541@
1 WIFE @29530@
1 _UST MARRIED
0 @29566@ FAM
1 HUSB @29562@
1 WIFE @29551@
1 _UST MARRIED
0 @29629@ FAM
1 HUSB @29616@
1 WIFE @29605@
1 _UST MARRIED
1 MARR
2 DATE 8 MAY 1757
2 _FNA NO
0 @29658@ FAM
1 HUSB @29650@
1 WIFE @29639@
1 _UST MARRIED
1 CHIL @29716@
1 CHIL @29731@
1 CHIL @29746@
1 NOTE COUSINS
1 MARR
2 DATE 19 APR 1751
2 _FNA NO
0 @29669@ FAM
1 HUSB @29660@
1 WIFE @29667@
1 _UST MARRIED
1 CHIL @29650@
0 @29680@ FAM
1 HUSB @29672@
1 WIFE @29678@
1 _UST MARRIED
1 CHIL @29702@
1 CHIL @26014@
1 CHIL @29709@
1 CHIL @29660@
1 CHIL @29684@
1 CHIL @29690@
0 @29698@ FAM
1 HUSB @29690@
1 WIFE @29696@
1 _UST MARRIED
0 @29774@ FAM
1 HUSB @29716@
1 WIFE @29772@
1 _UST MARRIED
0 @29778@ FAM
1 HUSB @29731@
1 WIFE @29776@
1 _UST MARRIED
1 MARR
2 DATE 16 APR 1782
2 _FNA NO
0 @29768@ FAM
1 HUSB @29753@
1 WIFE @29746@
1 _UST MARRIED
1 MARR
2 DATE 9 FEB 1778
2 _FNA NO
0 @29784@ FAM
1 HUSB @29782@
1 _UST MARRIED
1 CHIL @26021@
0 @29818@ FAM
1 HUSB @29803@
1 WIFE @29815@
1 _UST MARRIED
1 CHIL @29753@
0 @29866@ FAM
1 HUSB @29850@
1 WIFE @29838@
1 _UST MARRIED
0 @29906@ FAM
1 HUSB @29902@
1 WIFE @29885@
1 _UST MARRIED
0 @30028@ FAM
1 HUSB @30025@
1 WIFE @30017@
1 _UST MARRIED
1 CHIL @30090@
0 @30078@ FAM
1 HUSB @30060@
1 _UST MARRIED
1 CHIL @30076@
0 @30088@ FAM
1 HUSB @30085@
1 WIFE @30076@
1 _UST MARRIED
1 MARR
2 DATE 29 JUN 1880
2 PLAC Coutances,50200,Manche,Normandie,FRANCE,
3 MAP
4 LATI N49.050000
4 LONG W1.433330
2 _FNA NO
0 @30121@ FAM
1 HUSB @30112@
1 WIFE @30119@
1 _UST MARRIED
0 @30162@ FAM
1 HUSB @30151@
1 WIFE @30160@
1 _UST MARRIED
1 CHIL @30164@
1 CHIL @30262@
1 CHIL @30267@
1 CHIL @30272@
1 CHIL @30277@
1 CHIL @30282@
1 CHIL @30401@
1 NOTE 9 ENFANTS = 6XY+3XX
1 MARR
2 DATE ABT 1570
2 _FNA NO
0 @30174@ FAM
1 HUSB @30164@
1 WIFE @30172@
1 _UST MARRIED
1 CHIL @30177@
1 CHIL @30187@
1 CHIL @30215@
1 CHIL @30295@
1 CHIL @30287@
1 NOTE 9 ENFANTS MAIS SANS POSTERITE
1 MARR
2 DATE 1596
2 _FNA NO
0 @30196@ FAM
1 HUSB @30187@
1 WIFE @30194@
1 _UST MARRIED
1 CHIL @30231@
1 CHIL @30198@
0 @30213@ FAM
1 HUSB @30209@
1 WIFE @30198@
1 _UST MARRIED
1 CHIL @30225@
0 @30242@ FAM
1 HUSB @30239@
1 WIFE @30231@
1 _UST MARRIED
1 CHIL @30244@
1 MARR
2 DATE 18 DEC 1679
2 PLAC Laon,02000,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.566670
4 LONG E3.616670
2 _FNA NO
0 @30257@ FAM
1 HUSB @30244@
1 _UST MARRIED
1 CHIL @30253@
0 @30309@ FAM
1 HUSB @30262@
1 WIFE @30307@
1 _UST MARRIED
1 CHIL @30310@
0 @30420@ FAM
1 HUSB @30282@
1 _UST MARRIED
1 CHIL @30418@
0 @30323@ FAM
1 HUSB @30310@
1 _UST MARRIED
1 CHIL @30330@
1 CHIL @30315@
0 @30328@ FAM
1 HUSB @30315@
1 WIFE @30326@
1 _UST MARRIED
1 CHIL @30351@
1 NOTE 6 ENFANTS
0 @30348@ FAM
1 HUSB @30330@
1 _UST MARRIED
1 CHIL @30340@
0 @30358@ FAM
1 HUSB @30351@
1 WIFE @30356@
1 _UST MARRIED
0 @30371@ FAM
1 HUSB @30360@
1 WIFE @30370@
1 _UST MARRIED
1 CHIL @30375@
1 CHIL @30391@
1 CHIL @30356@
1 NOTE 16 ENFANTS DONT
0 @30416@ FAM
1 HUSB @30410@
1 WIFE @30401@
1 _UST MARRIED
1 MARR
2 PLAC Chaourse,02340,Aisne,Hauts-de-France,FRANCE,
3 MAP
4 LATI N49.704700
4 LONG E3.999110
2 _FNA NO
0 @30431@ FAM
1 HUSB @30418@
1 _UST MARRIED
1 CHIL @30423@
1 CHIL @30441@
1 CHIL @30434@
0 @30456@ FAM
1 HUSB @30441@
1 _UST MARRIED
1 CHIL @30452@
1 CHIL @30466@
1 CHIL @30541@
0 @30465@ FAM
1 HUSB @30452@
1 WIFE @30462@
1 _UST MARRIED
1 CHIL @30471@
0 @30579@ FAM
1 HUSB @30471@
1 WIFE @30568@
1 _UST MARRIED
1 CHIL @30591@
1 CHIL @30613@
1 CHIL @30625@
1 CHIL @30637@
1 NOTE autorisée 24/08/1757 par le pape BENOITXIV
1 MARR
2 DATE JAN 1758
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,SAINT GERMAIN L'AUXERROIS
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
1 MARC Maître QUINQUET ET ROBINEAU
2 DATE 15 JAN 1758
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @30505@ FAM
1 HUSB @30483@
1 _UST MARRIED
1 CHIL @30494@
0 @30502@ FAM
1 HUSB @30494@
1 WIFE @30500@
1 _UST MARRIED
1 CHIL @26699@
0 @30558@ FAM
1 HUSB @30541@
1 WIFE @30552@
1 _UST MARRIED
1 CHIL @30568@
0 @30563@ FAM
1 HUSB @30559@
1 _UST MARRIED
1 CHIL @30552@
0 @30658@ FAM
1 HUSB @30655@
1 WIFE @30613@
1 _UST MARRIED
0 @30663@ FAM
1 HUSB @30660@
1 WIFE @30637@
1 _UST MARRIED
0 @30726@ FAM
1 HUSB @30706@
1 WIFE @30719@
1 _UST MARRIED
1 CHIL @7690@
1 CHIL @30736@
1 CHIL @30741@
1 CHIL @30746@
1 CHIL @30752@
1 MARR
2 DATE 15 MAR 1907
2 PLAC Paris 07,,,,,
2 _FNA NO
0 @30782@ FAM
1 HUSB @30736@
1 WIFE @30780@
1 _UST MARRIED
0 @30786@ FAM
1 HUSB @30784@
1 WIFE @30741@
1 _UST MARRIED
0 @30790@ FAM
1 HUSB @30746@
1 WIFE @30788@
1 _UST MARRIED
0 @30794@ FAM
1 HUSB @30752@
1 WIFE @30792@
1 _UST MARRIED
0 @30776@ FAM
1 HUSB @30758@
1 WIFE @30769@
1 _UST MARRIED
1 CHIL @30719@
0 @30805@ FAM
1 HUSB @30795@
1 WIFE @30801@
1 _UST MARRIED
1 CHIL @6029@
1 CHIL @30808@
1 CHIL @30813@
0 @30821@ FAM
1 HUSB @30819@
1 WIFE @30808@
1 _UST MARRIED
0 @30825@ FAM
1 HUSB @30823@
1 WIFE @30813@
1 _UST MARRIED
0 @30846@ FAM
1 HUSB @30839@
1 WIFE @30844@
1 _UST MARRIED
1 CHIL @30835@
0 @30851@ FAM
1 HUSB @30849@
1 _UST MARRIED
1 CHIL @1044@
0 @30895@ FAM
1 HUSB @30873@
1 WIFE @30889@
1 _UST MARRIED
1 CHIL @16927@
0 @30919@ FAM
1 HUSB @30900@
1 WIFE @30912@
1 _UST MARRIED
1 CHIL @30873@
0 @32706@ FAM
1 HUSB @30971@
1 WIFE @32704@
1 _UST MARRIED
1 CHIL @9135@
1 CHIL @30976@
1 CHIL @32736@
1 CHIL @32840@
1 MARR
2 DATE 15 JUN 1945
2 PLAC Paris 07,,,,,
2 _FNA NO
0 @30983@ FAM
1 HUSB @30981@
1 WIFE @30976@
1 _UST MARRIED
1 CHIL @30984@
1 CHIL @32747@
1 CHIL @32806@
1 CHIL @32814@
1 CHIL @32822@
1 CHIL @32831@
0 @31004@ FAM
1 HUSB @30995@
1 WIFE @31002@
1 _UST MARRIED
1 CHIL @20759@
0 @31073@ FAM
1 HUSB @31062@
1 WIFE @31071@
1 _UST MARRIED
1 CHIL @31057@
1 CHIL @31076@
0 @31083@ FAM
1 HUSB @31081@
1 WIFE @31076@
1 _UST MARRIED
1 CHIL @31084@
0 @31092@ FAM
1 HUSB @31084@
1 WIFE @31090@
1 _UST MARRIED
1 CHIL @21050@
0 @31215@ FAM
1 HUSB @31205@
1 WIFE @31212@
1 _UST MARRIED
1 CHIL @20096@
0 @37096@ FAM
1 HUSB @31371@
1 WIFE @37089@
1 _UST MARRIED
1 CHIL @7476@
0 @31476@ FAM
1 HUSB @31419@
1 WIFE @31469@
1 _UST MARRIED
1 CHIL @9620@
1 CHIL @31480@
1 CHIL @9449@
1 MARR
2 DATE 24 FEB 1851
2 PLAC Limoges,87000,Haute-Vienne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.831530
4 LONG E1.257800
2 _FNA NO
0 @31454@ FAM
1 HUSB @31435@
1 WIFE @31450@
1 _UST MARRIED
1 CHIL @31419@
0 @31588@ FAM
1 HUSB @31565@
1 WIFE @31581@
1 _UST MARRIED
1 CHIL @9547@
0 @33802@ FAM
1 HUSB @31591@
1 WIFE @33796@
1 _UST MARRIED
1 CHIL @9377@
1 MARC
2 DATE 16 NOV 1835
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,Maitre BOURLIER
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
2 NOTE Nom :
3 CONT LEFEBVRE Pierre François Désiré
3 CONT Profession :
3 CONT Commis en horlogerie
3 CONT Adresse domicile ou atelier :
3 CONT Demeurant rue Saint Honoré, n° 247
3 CONT Nom conjoint :
3 CONT Epoux de MEDARD Marie Augustine Sabine, rue de Choiseul, n° 2 ter
3 CONT Description de lacte et personnes citees :
3 CONT Cité le 16/11/1835, lors du contrat de mariage dudit chez maître BOURLIER, notaire à Paris
3 CONT Cote / Source :
3 CONT Archives de Paris/Collection Mayet/Actes notariés/LEBRUN Georges Claude
3 CONT Code fichier :
3 CONT 6C100
3 CONT
1 MARR archives_AD075ER_V3E_M_00602_00515_C
2 DATE 20 NOV 1835
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,archives_AD075ER_V3E_M_00602_00515_C
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
2 NOTE archives_AD075ER_V3E_M_00602_00515_C
1 MARR
2 TYPE Religious marriage
2 DATE 21 NOV 1835
2 PLAC Paris 01,,,,,Eglise Saint-Roch
2 _FNA NO
0 @31604@ FAM
1 HUSB @31596@
1 WIFE @31602@
1 _UST MARRIED
1 CHIL @31591@
0 @31619@ FAM
1 HUSB @31618@
1 WIFE @31613@
1 _UST MARRIED
1 CHIL @31620@
0 @31653@ FAM
1 HUSB @31629@
1 WIFE @31641@
1 _UST MARRIED
1 CHIL @10077@
0 @31699@ FAM
1 HUSB @31697@
1 WIFE @31668@
1 _UST MARRIED
1 CHIL @31713@
1 CHIL @31718@
1 CHIL @31723@
1 CHIL @31728@
1 CHIL @31733@
1 CHIL @31738@
1 CHIL @31743@
1 CHIL @31748@
1 CHIL @31753@
1 CHIL @31758@
1 CHIL @31763@
0 @31703@ FAM
1 HUSB @31684@
1 WIFE @31701@
1 _UST MARRIED
1 CHIL @31708@
0 @31707@ FAM
1 HUSB @31705@
1 WIFE @31690@
1 _UST MARRIED
0 @31771@ FAM
1 HUSB @31769@
1 WIFE @31713@
1 _UST MARRIED
0 @31774@ FAM
1 HUSB @31772@
1 WIFE @31718@
1 _UST MARRIED
0 @31778@ FAM
1 HUSB @31776@
1 WIFE @31723@
1 _UST MARRIED
0 @31782@ FAM
1 HUSB @31780@
1 WIFE @31728@
1 _UST MARRIED
0 @31786@ FAM
1 HUSB @31784@
1 WIFE @31733@
1 _UST MARRIED
0 @31790@ FAM
1 HUSB @31788@
1 WIFE @31733@
1 _UST MARRIED
0 @31794@ FAM
1 HUSB @31792@
1 WIFE @31738@
1 _UST MARRIED
0 @31798@ FAM
1 HUSB @31748@
1 WIFE @31796@
1 _UST MARRIED
0 @31803@ FAM
1 HUSB @31748@
1 WIFE @31801@
1 _UST MARRIED
0 @31807@ FAM
1 HUSB @31758@
1 WIFE @31805@
1 _UST MARRIED
0 @31810@ FAM
1 HUSB @31763@
1 WIFE @31808@
1 _UST MARRIED
0 @31861@ FAM
1 HUSB @31843@
1 WIFE @31854@
1 _UST MARRIED
1 CHIL @31870@
1 CHIL @1230@
0 @31888@ FAM
1 HUSB @31881@
1 WIFE @31870@
1 _UST MARRIED
0 @31944@ FAM
1 HUSB @31927@
1 WIFE @31942@
1 _UST MARRIED
1 CHIL @11573@
1 CHIL @31978@
1 CHIL @31985@
1 CHIL @31992@
1 CHIL @32002@
1 MARR
2 DATE 10 APR 1907
2 _FNA NO
0 @31975@ FAM
1 HUSB @31952@
1 WIFE @31968@
1 _UST MARRIED
1 CHIL @11566@
1 MARC
2 DATE 25 JUL 1898
2 PLAC Bouchain,59111,Nord,Hauts-de-France,FRANCE,Maitre VARLOT
3 MAP
4 LATI N50.285190
4 LONG E3.314910
2 _FNA NO
1 MARR
2 DATE 26 JUL 1898
2 PLAC Neuville-sur-Escaut,59293,Nord,Hauts-de-France,FRANCE,
3 MAP
4 LATI N50.300000
4 LONG E3.350000
2 _FNA NO
0 @32019@ FAM
1 HUSB @32017@
1 WIFE @31978@
1 _UST MARRIED
0 @32014@ FAM
1 HUSB @32007@
1 WIFE @31985@
1 _UST MARRIED
1 MARR
2 DATE 18 NOV 1928
2 _FNA NO
0 @32028@ FAM
1 HUSB @32021@
1 WIFE @31992@
1 _UST MARRIED
0 @32032@ FAM
1 HUSB @32030@
1 WIFE @31992@
1 _UST MARRIED
0 @34296@ FAM
1 HUSB @34294@
1 WIFE @32051@
1 _UST MARRIED
0 @32084@ FAM
1 HUSB @32082@
1 _UST MARRIED
1 CHIL @32067@
0 @32327@ FAM
1 HUSB @32309@
1 WIFE @32325@
1 _UST MARRIED
1 CHIL @13898@
1 MARR
2 DATE 5 APR 1877
2 _FNA NO
0 @32365@ FAM
1 HUSB @32341@
1 WIFE @32358@
1 _UST MARRIED
1 CHIL @32370@
1 CHIL @32384@
1 CHIL @32325@
1 MARR
2 DATE 30 MAY 1843
2 _FNA NO
0 @32418@ FAM
1 HUSB @32399@
1 WIFE @32411@
1 _UST MARRIED
1 CHIL @32358@
1 CHIL @32422@
1 CHIL @32427@
1 MARR
2 DATE 1810
2 _FNA NO
0 @32435@ FAM
1 HUSB @32422@
1 WIFE @32433@
1 _UST MARRIED
0 @32913@ FAM
1 HUSB @32581@
1 WIFE @32911@
1 _UST MARRIED
1 CHIL @33454@
1 CHIL @33000@
1 CHIL @33005@
0 @33439@ FAM
1 HUSB @32591@
1 WIFE @33437@
1 _UST MARRIED
1 CHIL @33447@
1 CHIL @33440@
0 @32606@ FAM
1 HUSB @32602@
1 WIFE @32596@
1 _UST MARRIED
1 CHIL @32608@
1 CHIL @33462@
1 MARR
2 DATE JUL 2014
2 _FNA NO
0 @32665@ FAM
1 HUSB @32654@
1 WIFE @32663@
1 _UST MARRIED
1 CHIL @9126@
1 CHIL @32692@
1 CHIL @32697@
0 @32678@ FAM
1 HUSB @32670@
1 WIFE @32676@
1 _UST MARRIED
1 CHIL @32654@
1 CHIL @33539@
1 CHIL @33546@
1 CHIL @33556@
1 CHIL @33563@
0 @32689@ FAM
1 HUSB @32682@
1 WIFE @32687@
1 _UST MARRIED
1 CHIL @32663@
1 MARR
2 DATE 3 DEC 1921
2 PLAC Paris 08,,,,,
2 _FNA NO
0 @33511@ FAM
1 HUSB @32697@
1 WIFE @33509@
1 _UST MARRIED
1 MARR
2 DATE 4 JUL 1987
2 PLAC Indre,44610,Loire-Atlantique,Pays de la Loire,FRANCE,
3 MAP
4 LATI N47.200000
4 LONG W1.666670
2 _FNA NO
0 @32732@ FAM
1 HUSB @32724@
1 WIFE @32730@
1 _UST MARRIED
1 CHIL @30971@
1 CHIL @34345@
0 @32778@ FAM
1 HUSB @32736@
1 WIFE @32777@
1 _UST MARRIED
1 CHIL @32779@
1 CHIL @32783@
0 @32851@ FAM
1 HUSB @32847@
1 WIFE @32747@
1 _UST MARRIED
1 CHIL @32852@
1 CHIL @32859@
0 @32869@ FAM
1 HUSB @32867@
1 WIFE @32747@
1 _UST MARRIED
1 CHIL @32870@
0 @32773@ FAM
1 HUSB @32755@
1 WIFE @32766@
1 _UST MARRIED
1 CHIL @32704@
1 MARR
2 DATE 7 MAY 1918
2 PLAC Paris 07,,,,,
2 _FNA NO
0 @32790@ FAM
1 HUSB @32779@
1 WIFE @32788@
1 _UST MARRIED
1 CHIL @32791@
1 CHIL @32796@
1 CHIL @32801@
0 @32879@ FAM
1 HUSB @32877@
1 WIFE @32806@
1 _UST MARRIED
1 CHIL @32882@
1 CHIL @32892@
0 @32904@ FAM
1 HUSB @32902@
1 WIFE @32814@
1 _UST MARRIED
0 @32908@ FAM
1 HUSB @32906@
1 WIFE @32822@
1 _UST MARRIED
0 @32957@ FAM
1 HUSB @32938@
1 WIFE @32955@
1 _UST MARRIED
1 CHIL @32682@
0 @32994@ FAM
1 HUSB @32975@
1 WIFE @32988@
1 _UST MARRIED
1 CHIL @32938@
0 @33060@ FAM
1 HUSB @33058@
1 WIFE @33053@
1 _UST MARRIED
1 CHIL @36868@
1 CHIL @36876@
1 MARR
2 TYPE Religious marriage
2 DATE 17 JAN 1888
2 PLAC Paris 08,,,,,SAINT AUGUSTIN
2 _FNA NO
0 @33096@ FAM
1 HUSB @33090@
1 WIFE @33069@
1 _UST MARRIED
1 CHIL @33222@
1 CHIL @33233@
1 MARC
2 DATE 24 DEC 1850
2 PLAC Thoiry,78770,Yvelines,Île-de-France,FRANCE,Maitre PETIT
3 MAP
4 LATI N48.867150
4 LONG E1.797600
2 _FNA NO
1 MARR
2 DATE 14 JAN 1851
2 PLAC Crespières,78121,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883170
4 LONG E1.921510
2 _FNA NO
0 @33129@ FAM
1 HUSB @33104@
1 WIFE @33122@
1 _UST MARRIED
1 CHIL @23271@
0 @33156@ FAM
1 HUSB @33134@
1 WIFE @33141@
1 _UST MARRIED
1 CHIL @33104@
0 @33187@ FAM
1 HUSB @33164@
1 WIFE @33176@
1 _UST MARRIED
1 CHIL @33122@
0 @33297@ FAM
1 HUSB @33282@
1 WIFE @33293@
1 _UST MARRIED
1 CHIL @16943@
0 @33354@ FAM
1 HUSB @33334@
1 WIFE @33352@
1 _UST MARRIED
1 CHIL @7099@
0 @33378@ FAM
1 HUSB @33359@
1 WIFE @33371@
1 _UST MARRIED
1 CHIL @23097@
1 MARR
2 DATE 28 DEC 1808
2 PLAC Pointe-à-Pitre,97110,Guadeloupe,Guadeloupe,FRANCE,
3 MAP
4 LATI N16.242200
4 LONG W61.534300
2 _FNA NO
0 @33403@ FAM
1 HUSB @33399@
1 _UST MARRIED
1 CHIL @23015@
0 @33536@ FAM
1 HUSB @33526@
1 WIFE @33534@
1 _UST MARRIED
1 CHIL @32676@
0 @33609@ FAM
1 HUSB @33584@
1 WIFE @33594@
1 _UST MARRIED
1 CHIL @33643@
1 CHIL @32687@
1 MARR
2 DATE 17 OCT 1893
2 PLAC Luisant,28600,Eure-et-Loir,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N48.429500
4 LONG E1.473830
2 _FNA NO
0 @33683@ FAM
1 HUSB @33681@
1 WIFE @33643@
1 _UST MARRIED
1 MARR
2 DATE 8 JAN 1910
2 PLAC Paris 08,,,,,
2 _FNA NO
0 @33847@ FAM
1 HUSB @33843@
1 WIFE @33720@
1 _UST MARRIED
0 @33980@ FAM
1 HUSB @33774@
1 WIFE @33969@
1 _UST MARRIED
1 CHIL @33989@
1 CHIL @10130@
1 CHIL @34000@
1 CHIL @34011@
1 MARR
2 DATE 10 JUL 1867
2 PLAC Le Havre,76600,Seine-Maritime,Normandie,FRANCE,
3 MAP
4 LATI N49.493800
4 LONG E0.107670
2 _FNA NO
0 @33836@ FAM
1 HUSB @33820@
1 WIFE @33830@
1 _UST MARRIED
1 CHIL @33720@
1 MARR
2 DATE 9 AUG 1836
2 PLAC Poitiers,86000,Vienne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.583330
4 LONG E0.333330
2 _FNA YES
0 @33947@ FAM
1 HUSB @33849@
1 WIFE @33945@
1 _UST MARRIED
1 CHIL @33774@
1 CHIL @33778@
1 MARR
2 DATE 1834
2 _FNA NO
0 @34096@ FAM
1 HUSB @34071@
1 WIFE @34092@
1 _UST MARRIED
1 CHIL @33796@
0 @34193@ FAM
1 HUSB @34185@
1 WIFE @34191@
1 _UST MARRIED
1 CHIL @5453@
1 CHIL @34206@
0 @34198@ FAM
1 HUSB @34196@
1 _UST MARRIED
1 CHIL @34191@
0 @34203@ FAM
1 HUSB @34201@
1 _UST MARRIED
1 CHIL @34196@
0 @34214@ FAM
1 HUSB @34212@
1 WIFE @34206@
1 _UST MARRIED
0 @34372@ FAM
1 HUSB @34360@
1 WIFE @34366@
1 _UST MARRIED
1 CHIL @34383@
1 CHIL @34394@
1 CHIL @2206@
0 @34490@ FAM
1 HUSB @34474@
1 WIFE @34486@
1 _UST MARRIED
1 CHIL @19584@
0 @34513@ FAM
1 HUSB @34511@
1 WIFE @34501@
1 _UST MARRIED
1 CHIL @34514@
0 @34524@ FAM
1 HUSB @34520@
1 WIFE @34514@
1 _UST MARRIED
1 CHIL @34526@
1 CHIL @34533@
1 MARR
2 DATE 2001
2 _FNA NO
0 @34633@ FAM
1 HUSB @34573@
1 WIFE @34631@
1 _UST MARRIED
0 @34622@ FAM
1 HUSB @34583@
1 WIFE @34620@
1 _UST MARRIED
0 @34626@ FAM
1 HUSB @34593@
1 WIFE @34624@
1 _UST MARRIED
1 NOTE ont eu des triplés
0 @34617@ FAM
1 HUSB @34604@
1 WIFE @34615@
1 _UST MARRIED
0 @34658@ FAM
1 HUSB @34634@
1 WIFE @34647@
1 _UST MARRIED
1 CHIL @19576@
0 @34684@ FAM
1 HUSB @34665@
1 WIFE @34677@
1 _UST MARRIED
1 CHIL @34647@
0 @34851@ FAM
1 HUSB @34841@
1 WIFE @34849@
1 _UST MARRIED
1 CHIL @33820@
1 MARR
2 DATE 10 OCT 1797
2 PLAC Poitiers,86000,Vienne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.583330
4 LONG E0.333330
2 _FNA NO
2 NOTE 19 VENDEMIAIRE AN 6
0 @34874@ FAM
1 HUSB @34861@
1 WIFE @34872@
1 _UST MARRIED
1 CHIL @34841@
0 @34891@ FAM
1 HUSB @34883@
1 WIFE @34889@
1 _UST MARRIED
1 CHIL @34849@
1 CHIL @34897@
0 @34944@ FAM
1 HUSB @34921@
1 WIFE @34938@
1 _UST MARRIED
1 CHIL @33830@
1 CHIL @35303@
1 CHIL @35031@
1 MARR
2 DATE 6 JUN 1804
2 PLAC Poitiers,86000,Vienne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N46.583330
4 LONG E0.333330
2 _FNA NO
2 NOTE 17 PRAIRIAL AN 12
0 @34961@ FAM
1 HUSB @34949@
1 WIFE @34957@
1 _UST MARRIED
1 CHIL @34921@
1 MARR
2 DATE 3 FEB 1778
2 PLAC Poitiers,86000,Vienne,Nouvelle-Aquitaine,FRANCE,saint germain
3 MAP
4 LATI N46.583330
4 LONG E0.333330
2 _FNA NO
0 @34999@ FAM
1 HUSB @34988@
1 WIFE @34997@
1 _UST MARRIED
1 CHIL @34949@
0 @35012@ FAM
1 HUSB @35004@
1 WIFE @35010@
1 _UST MARRIED
1 CHIL @34957@
0 @35378@ FAM
1 HUSB @35366@
1 WIFE @35374@
1 _UST MARRIED
0 @35392@ FAM
1 HUSB @35385@
1 _UST MARRIED
1 CHIL @35395@
1 CHIL @11385@
0 @35430@ FAM
1 HUSB @35415@
1 WIFE @35426@
1 _UST MARRIED
1 CHIL @34360@
0 @35467@ FAM
1 HUSB @35455@
1 WIFE @35465@
1 _UST MARRIED
1 CHIL @4765@
1 MARR
2 DATE 21 JUN 1980
2 _FNA NO
0 @35478@ FAM
1 HUSB @35470@
1 WIFE @35476@
1 _UST MARRIED
1 CHIL @35455@
1 CHIL @35481@
1 CHIL @35486@
1 CHIL @35491@
1 CHIL @35496@
1 MARR
2 DATE 25 JUL 1946
2 PLAC Nouvoitou,35410,Ille-et-Vilaine,Bretagne,FRANCE,
3 MAP
4 LATI N48.041050
4 LONG W1.547140
2 _FNA NO
0 @35541@ FAM
1 HUSB @35505@
1 WIFE @35526@
1 _UST MARRIED
1 CHIL @35470@
0 @35598@ FAM
1 HUSB @35574@
1 WIFE @35590@
1 _UST MARRIED
1 CHIL @35604@
1 CHIL @32670@
1 MARR
2 DATE 22 JAN 1879
2 _FNA NO
0 @35659@ FAM
1 HUSB @35641@
1 WIFE @35652@
1 _UST MARRIED
1 CHIL @35663@
1 CHIL @35679@
1 CHIL @11412@
1 CHIL @35686@
1 CHIL @35693@
1 MARR
2 DATE 1919
2 PLAC Marsac-sur-l'Isle,24430,Dordogne,Nouvelle-Aquitaine,FRANCE,
3 MAP
4 LATI N45.183330
4 LONG E0.666670
2 _FNA NO
0 @35725@ FAM
1 HUSB @35663@
1 WIFE @35723@
1 _UST MARRIED
1 CHIL @35726@
1 CHIL @35732@
1 CHIL @35738@
0 @35721@ FAM
1 HUSB @35719@
1 WIFE @35686@
1 _UST MARRIED
0 @35888@ FAM
1 HUSB @35873@
1 WIFE @35884@
1 _UST MARRIED
1 CHIL @27468@
0 @36168@ FAM
1 HUSB @36143@
1 WIFE @36162@
1 _UST MARRIED
1 CHIL @18850@
1 MARR
2 DATE 11 JUN 1863
2 PLAC Kingston upon Thames,,,,,
2 _FNA NO
0 @36210@ FAM
1 HUSB @36202@
1 WIFE @36190@
1 _UST MARRIED
1 CHIL @17632@
0 @36273@ FAM
1 HUSB @36257@
1 WIFE @36267@
1 _UST MARRIED
1 CHIL @31968@
0 @36296@ FAM
1 HUSB @36277@
1 WIFE @36290@
1 _UST MARRIED
1 CHIL @31952@
0 @36401@ FAM
1 HUSB @36386@
1 WIFE @36395@
1 _UST MARRIED
1 CHIL @23304@
1 MARR
2 DATE 28 JUL 1721
2 PLAC Crespières,78121,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.883170
4 LONG E1.921510
2 _FNA NO
0 @36420@ FAM
1 HUSB @36407@
1 WIFE @36416@
1 _UST MARRIED
1 CHIL @23316@
0 @36441@ FAM
1 HUSB @36428@
1 WIFE @36436@
1 _UST MARRIED
1 CHIL @36386@
1 MARR
2 DATE 1690
2 _FNA NO
0 @36458@ FAM
1 HUSB @36445@
1 WIFE @36454@
1 _UST MARRIED
1 CHIL @36395@
1 MARR
2 DATE 1680
2 _FNA NO
0 @36478@ FAM
1 HUSB @36465@
1 WIFE @36473@
1 _UST MARRIED
1 CHIL @36428@
1 MARR
2 DATE 21 JUN 1659
2 PLAC Aulnay-sur-Mauldre,78126,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.929500
4 LONG E1.841130
2 _FNA NO
0 @36496@ FAM
1 HUSB @36483@
1 WIFE @36491@
1 _UST MARRIED
1 CHIL @36436@
0 @36513@ FAM
1 HUSB @36500@
1 WIFE @36508@
1 _UST MARRIED
1 CHIL @36465@
1 MARR
2 DATE 30 JAN 1614
2 PLAC Maule,78580,Yvelines,Île-de-France,FRANCE,
3 MAP
4 LATI N48.910560
4 LONG E1.852640
2 _FNA NO
0 @36533@ FAM
1 HUSB @36519@
1 WIFE @36528@
1 _UST MARRIED
1 CHIL @36508@
0 @36995@ FAM
1 HUSB @36629@
1 WIFE @36981@
1 _UST MARRIED
0 @36720@ FAM
1 HUSB @36700@
1 WIFE @36714@
1 _UST MARRIED
1 CHIL @18815@
0 @36746@ FAM
1 HUSB @36726@
1 WIFE @36738@
1 _UST MARRIED
1 CHIL @31942@
0 @36772@ FAM
1 HUSB @36759@
1 WIFE @36768@
1 _UST MARRIED
1 CHIL @33359@
0 @36862@ FAM
1 HUSB @36849@
1 WIFE @36857@
1 _UST MARRIED
1 CHIL @36890@
1 CHIL @36898@
1 CHIL @33058@
1 MARR
2 DATE 9 FEB 1847
2 PLAC Chartres,28000,Eure-et-Loir,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N48.446850
4 LONG E1.489250
2 _FNA NO
0 @36913@ FAM
1 HUSB @36910@
1 WIFE @36898@
1 _UST MARRIED
1 MARR
2 DATE 18 MAY 1874
2 PLAC Chartres,28000,Eure-et-Loir,Centre-Val de Loire,FRANCE,
3 MAP
4 LATI N48.446850
4 LONG E1.489250
2 _FNA NO
0 @36947@ FAM
1 HUSB @36931@
1 WIFE @36942@
1 _UST MARRIED
1 CHIL @33090@
0 @37028@ FAM
1 HUSB @37013@
1 WIFE @37023@
1 _UST MARRIED
1 CHIL @17842@
0 @37132@ FAM
1 HUSB @37115@
1 WIFE @37127@
1 _UST MARRIED
1 CHIL @32755@
1 CHIL @37138@
1 CHIL @37148@
1 CHIL @37158@
1 CHIL @37178@
1 CHIL @37168@
1 MARR
2 DATE 18 JUN 1892
2 PLAC Paris 16,,,,,
2 _FNA NO
0 @37415@ FAM
1 HUSB @37158@
1 WIFE @37412@
1 _UST MARRIED
1 MARR
2 DATE 1921
2 _FNA NO
0 @37423@ FAM
1 HUSB @37420@
1 WIFE @37168@
1 _UST MARRIED
1 MARR
2 DATE 15 OCT 1926
2 PLAC Paris 07,,,,,
2 _FNA NO
0 @37191@ FAM
1 HUSB @37178@
1 WIFE @37188@
1 _UST MARRIED
1 CHIL @37196@
1 MARR
2 DATE 25 NOV 1930
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
2 NOTE St Louis des Invalides le 27 novembre 1930
0 @37219@ FAM
1 HUSB @37205@
1 WIFE @37215@
1 _UST MARRIED
1 CHIL @37322@
1 CHIL @37115@
1 CHIL @37331@
1 CHIL @37340@
1 CHIL @37349@
1 MARR
2 DATE 6 JUL 1865
2 PLAC Paris 07,,,,,SAINTE CLOTHILDE
2 _FNA NO
0 @37241@ FAM
1 HUSB @37224@
1 WIFE @37234@
1 _UST MARRIED
1 CHIL @37127@
1 CHIL @37447@
1 MARR
2 DATE 12 NOV 1864
2 PLAC Paris 16,,,,,
2 _FNA NO
0 @37267@ FAM
1 HUSB @37249@
1 WIFE @37260@
1 _UST MARRIED
1 CHIL @37358@
1 CHIL @37215@
1 CHIL @37370@
0 @37295@ FAM
1 HUSB @37276@
1 WIFE @37291@
1 _UST MARRIED
1 CHIL @37384@
1 CHIL @37249@
1 CHIL @37396@
0 @37316@ FAM
1 HUSB @37299@
1 WIFE @37310@
1 _UST MARRIED
1 CHIL @37260@
0 @37463@ FAM
1 HUSB @37460@
1 WIFE @37447@
1 _UST MARRIED
1 CHIL @37468@
1 CHIL @37480@
1 MARR
2 DATE 12 APR 1898
2 PLAC Paris,75000,Paris,Île-de-France,FRANCE,
3 MAP
4 LATI N48.853410
4 LONG E2.348800
2 _FNA NO
0 @37509@ FAM
1 HUSB @37496@
1 WIFE @37504@
1 _UST MARRIED
1 CHIL @37205@
1 MARR
2 DATE 7 JUN 1836
2 PLAC Sens,89100,Yonne,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N48.200000
4 LONG E3.283330
2 _FNA NO
0 @37539@ FAM
1 HUSB @37518@
1 WIFE @37533@
1 _UST MARRIED
1 CHIL @37547@
1 CHIL @37496@
1 MARR
2 DATE 28 MAY 1802
2 PLAC Dijon,21000,Côte-d'Or,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.316670
4 LONG E5.016670
2 _FNA NO
0 @37580@ FAM
1 HUSB @37547@
1 WIFE @37577@
1 _UST MARRIED
1 MARR
2 DATE 29 DEC 1828
2 PLAC Dijon,21000,Côte-d'Or,Bourgogne-Franche-Comté,FRANCE,
3 MAP
4 LATI N47.316670
4 LONG E5.016670
2 _FNA NO
0 @37572@ FAM
1 HUSB @37559@
1 WIFE @37567@
1 _UST MARRIED
1 CHIL @37504@
0 @37612@ FAM
1 HUSB @37597@
1 WIFE @37607@
1 _UST MARRIED
1 CHIL @37224@
0 @37626@ FAM
1 HUSB @37622@
1 _UST MARRIED
1 CHIL @37607@
0 @37636@ FAM
1 HUSB @37631@
1 _UST MARRIED
1 CHIL @37622@
0 TRLR`
